import { VolumeManager, VolumeResult } from "react-native-volume-manager";
import { log } from "../Log";

export function setVolume(volume: number) {
  VolumeManager.setVolume(volume, { showUI: false }).catch(err => {
    log.errorCaught("Unexpected error setting volume", err);
  });
}

export async function getVolume(): Promise<number> {
  const volume = await VolumeManager.getVolume();
  return getVolumeFromResult(volume);
}

export function subscribeToVolumeChanges(handler: (volume: number) => void): () => void {
  const listener = VolumeManager.addVolumeListener(volume => {
    handler(getVolumeFromResult(volume));
  });

  return listener.remove;
}

function getVolumeFromResult(volume: number | VolumeResult): number {
  if (typeof volume === "number") {
    return volume;
  }

  return volume.volume;
}
