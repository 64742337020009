import { Platform, StyleSheet, View } from "react-native";
import { range } from "@eatbetter/common-shared";
import { Spacer } from "./Spacer";
import { globalStyleColors } from "./GlobalStyles";
import { BlurOverlay } from "./BlurOverlay/BlurOverlay";

type TabViewProgressStyle = "default" | "overlay";

export const TabViewProgress = (props: { count: number; currentIndex: number; style?: TabViewProgressStyle }) => {
  const style = props.style ?? "default";

  return (
    <View style={[styles.container, props.style === "overlay" ? styles.overlayed : {}]}>
      <View style={[styles.innerContainer, Platform.OS === "web" ? { backgroundColor: "white" } : {}]}>
        {/* Web doesn't respect overflow: hidden when any child in the subtree has position: absolute (annoying). So we
        just use a solid white background on the innerContainer for web to ensure we get the right oval shape. */}
        {props.style === "overlay" && Platform.OS !== "web" && <BlurOverlay zIndex={0} style="chrome" />}
        {range(props.count - 1).map(i => {
          return (
            <View key={i} style={{ flexDirection: "row" }}>
              {i !== 0 && <Spacer horizontal={8} unit="pixels" />}
              <TabViewProgressCircle filled={i === props.currentIndex} style={style} />
            </View>
          );
        })}
      </View>
    </View>
  );
};

const TabViewProgressCircle = (props: { style: TabViewProgressStyle; filled: boolean }) => {
  const filledColor = globalStyleColors.colorAccentCool;
  const emptyColor = "rgba(0,0,0,0.2)";

  const backgroundColor = props.filled ? filledColor : emptyColor;

  return <View style={[styles.progressCircle, { backgroundColor }]} />;
};

const styles = StyleSheet.create({
  container: {
    alignSelf: "center",
    borderRadius: 12,
  },
  innerContainer: {
    flexDirection: "row",
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 12,
    overflow: "hidden",
  },
  overlayed: {
    shadowOffset: { height: 0, width: 0 },
    shadowColor: "black",
    shadowOpacity: 0.4,
    shadowRadius: 6,
  },
  progressCircle: {
    width: 6,
    height: 6,
    borderRadius: 6,
  },
});
