import { isEqual } from "lodash";

export function deepEquals<T>(a: T, b: T): boolean {
  return isEqual(a, b);
}

export function getChangedFields<T extends object>(a: T, b: T): Array<keyof T> {
  const aKeys = Object.keys(a) as Array<keyof T>;
  const bKeys = Object.keys(b) as Array<keyof T>;
  const keys = new Set<keyof T>([...aKeys, ...bKeys]);

  return [...keys].flatMap(key => {
    return deepEquals(a[key], b[key]) ? [] : key;
  });
}
