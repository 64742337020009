import { ConfigEnvironment, PlatformDependentCurrentEnvironmentApi } from "@eatbetter/ui-shared";
import { isLocalHost } from "../components/AdminNav";

export const adminDeps: PlatformDependentCurrentEnvironmentApi = {
  configEnvironment(): ConfigEnvironment {
    if (window.location.hostname === "localhost") {
      return "local";
    } else if (window.location.hostname === "dev.localhost") {
      return "dev";
    } else if (window.location.hostname.toLowerCase().endsWith("mooklab-dev.link")) {
      return "dev";
    } else if (window.location.hostname.toLowerCase() === "admin.deglaze.app") {
      return "prod";
    } else {
      throw new Error("Unknown domain");
    }
  },
  debugBuild(): boolean {
    return isLocalHost();
  },
  linkBaseUrl(): string {
    return `${window.location.protocol}//${window.location.host}`;
  },
};
