import { UserRecipeId } from "@eatbetter/recipes-shared";
import { navTree, ReportSocialMediaCaptionIssueScreenProps } from "../navigation/NavTree";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "../lib/redux/Redux";
import { HeaderProps } from "../components/ScreenHeaders";
import { View } from "react-native";
import { Haptics } from "../components/Haptics";
import { TBody, TextProps } from "../components/Typography";
import { Spacer } from "../components/Spacer";
import { switchReturn } from "@eatbetter/common-shared";
import { useRecipeDescription } from "../lib/recipes/RecipesSelectors";
import { TextInput, TextInputProps } from "../components/TextInput";
import React from "react";
import { globalStyleColors, globalStyleConstants } from "../components/GlobalStyles";
import { reportIssue } from "../lib/system/SystemThunks";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { useResponsiveDimensions } from "../components/Responsive";
import { ScrollViewWithSubmit } from "./ScrollViewWithSubmit";

const strings = {
  screenTitleWrittenRecipe: "Report Issue: Recipe Text in Caption",
  screenTitleRecipeLink: "Report Issue: Recipe Link in Caption",
  writtenRecipeHeadline: "Please select the recipe from\nthe caption text below",
  linkHeadline: "Please select the recipe link from\nthe caption text below",
  submit: "Submit",
  wrongCaptionLink: "I see a different caption",
  wrongCaptionFormLabel: "Please paste the caption below so that we can fix it for you",
  wrongCaptionFormPlaceholder: "Paste the caption you see here...",
  wrongCaptionScreenTitle: "Report Issue: Wrong Caption",
};

export const ReportSocialMediaCaptionIssueScreen = withScreenContainer<ReportSocialMediaCaptionIssueScreenProps>(
  "ReportSocialMediaCaptionIssueScreen",
  props => {
    const screen = useScreen();
    const dispatch = useDispatch();

    const caption = useRecipeDescription(props.recipeId) ?? "";

    const [captionSelection, setCaptionSelection] = useState<NonNullable<TextInputProps["selection"]>>({
      start: 0,
      end: Math.min(caption.length, 30),
    });
    const [waiting, setWaiting] = useState(false);

    const onChangeCaptionSelection = useCallback<NonNullable<TextInputProps["onSelectionChange"]>>(
      ({ nativeEvent: { selection } }) => {
        // This accomplishes two things: 1) Addresses a weird bug where after initial render, the selection goes away if
        // onSelectionChange is set on TextInput - it overrides the initial value and sets selection start/end to the
        // end of the string. 2) Prevents the text selection UI from going away if you single tap elsewhere on the
        // text - i.e. makes you select something.
        if (selection.start === selection.end) {
          return;
        }

        setCaptionSelection(selection);
      },
      [setCaptionSelection]
    );

    const submit = useCallback(
      async (userProvidedAlternateCaption?: string) => {
        try {
          await dispatch(
            reportIssue(
              {
                type: "socialMediaRecipeMissingIssue",
                issue: props.issueType === "recipeInCaption" ? "writtenRecipeInCaption" : "recipeLinkInCaption",
                recipeId: props.recipeId,
                caption,
                userSelectedCaptionText: userProvidedAlternateCaption
                  ? undefined
                  : caption.substring(captionSelection.start, captionSelection.end),
                userProvidedAlternateCaption,
              },
              setWaiting
            )
          );
        } catch (err) {
          displayUnexpectedErrorAndLog("ReportSocialMediaCaptionIssueScreen: error caught in submit()", err, {
            caption,
            captionSelection,
            recipeId: props.recipeId,
            issueType: props.issueType,
            userProvidedAlternateCaption,
          });
          // Don't nav back on error
          return;
        }
        Haptics.feedback("operationSucceeded");
        screen.nav.pop(2);
      },
      [dispatch, caption, props.recipeId, props.issueType, setWaiting, screen.nav.pop]
    );

    const onPressWrongCaption = useCallback(() => {
      screen.nav.modal(navTree.get.screens.surveyOtherOptionForm, {
        onSubmit: submit,
        label: strings.wrongCaptionFormLabel,
        screenTitle: strings.wrongCaptionScreenTitle,
        textInputPlaceholder: strings.wrongCaptionFormPlaceholder,
      });
    }, [screen.nav.modal, submit]);

    const header = useMemo<HeaderProps>(() => {
      return {
        type: "default",
        title: props.issueType === "linkInCaption" ? strings.screenTitleRecipeLink : strings.screenTitleWrittenRecipe,
      };
    }, [props.issueType]);

    const headline = switchReturn(props.issueType, {
      linkInCaption: strings.linkHeadline,
      recipeInCaption: strings.writtenRecipeHeadline,
    });

    const { defaultFontScale } = useResponsiveDimensions();

    const commonTextProps: Partial<TextProps> = {
      align: "center",
      enableFontScaling: "upOnly",
      scale: defaultFontScale,
    };

    return (
      <ScreenView header={header} scrollView={false} paddingVertical={false}>
        <ScrollViewWithSubmit onPressSubmit={submit} submitText={strings.submit} waiting={waiting}>
          <Spacer vertical={1} />
          <TBody {...commonTextProps} fontWeight="medium">
            {headline}
          </TBody>
          <Spacer vertical={2} />
          <View style={{ maxHeight: "50%" }}>
            <TextSelect value={caption} selection={captionSelection} onSelectionChange={onChangeCaptionSelection} />
          </View>
          <Spacer vertical={2} />
          <TBody {...commonTextProps} onPress={onPressWrongCaption} color={globalStyleColors.colorAccentCool} underline>
            {strings.wrongCaptionLink}
          </TBody>
        </ScrollViewWithSubmit>
      </ScreenView>
    );
  },
  {
    serializer: {
      recipeId: s => s,
      issueType: s => s,
    },
    parser: {
      recipeId: s => s as UserRecipeId,
      issueType: s => s as ReportSocialMediaCaptionIssueScreenProps["issueType"],
    },
  }
);

const TextSelect = React.memo(
  (props: {
    value: string;
    selection: TextInputProps["selection"];
    onSelectionChange: TextInputProps["onSelectionChange"];
  }) => {
    const { defaultFontScale } = useResponsiveDimensions();

    return (
      <View
        style={{
          padding: globalStyleConstants.unitSize,
          borderRadius: globalStyleConstants.defaultBorderRadius,
          backgroundColor: globalStyleColors.colorGreyLight,
          borderWidth: 1,
          borderColor: globalStyleColors.colorGreyDark,
        }}
      >
        <TextInput
          value={props.value}
          editable={false}
          selection={props.selection}
          onSelectionChange={props.onSelectionChange}
          textColor={globalStyleColors.black}
          multiline
          autoFocus
          backgroundColor="transparent"
          noBorder
          fontScale={defaultFontScale}
        />
      </View>
    );
  }
);
