import { NativeModules, Platform } from "react-native";
import { EpochMs, UrlString } from "@eatbetter/common-shared";
import { CookingTimerId } from "./cooking/CookingSessionsSlice";
const { LiveActivities } = NativeModules;

export class LiveActivityApi {
  async startCookingTimerLiveActivity(args: {
    timerId: CookingTimerId;
    recipeTitle: string;
    endTime: EpochMs;
    tapUrl: UrlString;
  }): Promise<void> {
    if (!isSupportedPlatform()) {
      return Promise.resolve();
    }

    return LiveActivities.startCookingTimerLiveActivity(args);
  }

  async updateCookingTimerLiveActivity(args: { timerId: CookingTimerId; endTime: EpochMs }): Promise<void> {
    if (!isSupportedPlatform()) {
      return Promise.resolve();
    }

    return LiveActivities.updateCookingTimerLiveActivity(args);
  }

  async endCookingTimerLiveActivity(args: { timerId: CookingTimerId }): Promise<void> {
    if (!isSupportedPlatform()) {
      return Promise.resolve();
    }

    return LiveActivities.endCookingTimerLiveActivity(args);
  }
}

function isSupportedPlatform() {
  return Platform.OS === "ios";
}
