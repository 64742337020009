import { useEffect, useState } from "react";
import { withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { TBody } from "../components/Typography";
import { log } from "../Log";
import { getVolume, subscribeToVolumeChanges } from "../lib/Volume";

export const useDeviceVolume = () => {
  const [volume, setVolume] = useState<number | undefined>();

  useEffect(() => {
    const unsubscribeVolume = subscribeToVolumeChanges(result => {
      setVolume(result);
    });

    // this does not work
    // const vl = SystemSettings.addVolumeListener(v => {
    //   setVolumeFromResult(v.value);
    // });

    getVolume()
      .then(v => {
        setVolume(v);
      })
      .catch(err => {
        log.errorCaught("Unexpected error calling getVolume", err);
      });

    return unsubscribeVolume;
  }, []);

  const volumePercent = Math.ceil((volume ?? 0) * 100);

  return { volumePercent, muted: true };
};

export const TimerVolumeScreen = withNonNavigableScreenContainer("TimerVolumeScreen", () => {
  const { volumePercent, muted } = useDeviceVolume();

  return (
    <ScreenView
      header={{
        type: "native",
        title: "Can you hear me?",
      }}
    >
      <TBody>Turn it up!!</TBody>
      <TBody>Volume is {volumePercent}%</TBody>
      <TBody>Muted: {muted.toString()}</TBody>
    </ScreenView>
  );
});
