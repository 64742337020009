import { ExternalSharedBookRecipeDetailScreenProps } from "../navigation/NavTree";
import { withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { useEffect } from "react";
import { useDispatch } from "../lib/redux/Redux";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { reportShareBookPhotoRecipeDetailsViewedOnWeb } from "../lib/analytics/AnalyticsEvents";
import { RestrictedBookRecipeScreenComponent } from "../components/recipes/RestrictedBookRecipeScreenComponent";

export const ExternalSharedBookPhotoRecipeDetailScreen = withNonNavigableScreenContainer(
  "ExternalSharedBookPhotoRecipeDetailScreen",
  (props: ExternalSharedBookRecipeDetailScreenProps) => {
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(
        analyticsEvent(
          reportShareBookPhotoRecipeDetailsViewedOnWeb({
            recipeId: props.recipeId,
            recipeInfo: props.recipe,
          })
        )
      );
    }, []);

    return <RestrictedBookRecipeScreenComponent recipe={props.recipe} />;
  }
);
