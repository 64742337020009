import { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { isUsernameAvailable } from "./SystemThunks";
import { log } from "../../Log";
import { useDispatch } from "../redux/Redux";
import {
  isValidName,
  isValidUsername,
  maxBioLength,
  minNameLength,
  minUsernameLength,
  UsernameAvailableResult,
} from "@eatbetter/users-shared";
import { UrlString, charLength, toBasicLatin } from "@eatbetter/common-shared";

export const useUsernameInput = (initial: string) => {
  const [username, setUsername] = useState(initial);
  const [usernameIsInvalid, setUsernameIsInvalid] = useState(false);

  const debouncedUsernameValid = useRef(
    debounce((un: string, setInvalid: typeof setUsernameIsInvalid) => {
      setInvalid(!isValidUsername(un));
    }, 1500)
  ).current;

  const onChange = useCallback(
    (u: string) => {
      const updated = toBasicLatin(u).toLowerCase().trim();
      setUsername(updated);
      debouncedUsernameValid(u, setUsernameIsInvalid);
      if (u.length >= minUsernameLength) {
        setUsernameIsInvalid(!isValidUsername(u));
      }
    },
    [debouncedUsernameValid]
  );

  return {
    username,
    setUsername,
    usernameIsInvalid,
    setUsernameIsInvalid,
    onChange,
  };
};

export const useNameInput = (initial: string) => {
  const [name, setName] = useState(initial);
  const [nameIsInvalid, setNameIsInvalid] = useState(false);

  const debouncedNameValid = useRef(
    debounce((n: string, setInvalid: typeof setNameIsInvalid) => {
      setInvalid(!isValidName(n));
    }, 1500)
  ).current;

  const onChange = useCallback(
    (value: string) => {
      setName(value);

      // set immediate if we are >= min length and the debounce
      // will prevent the error from displaying immediately solely for
      // the input being too short.
      debouncedNameValid(value, setNameIsInvalid);
      if (value.length >= minNameLength) {
        setNameIsInvalid(!isValidName(value));
      }
    },
    [setName, setNameIsInvalid, debouncedNameValid]
  );

  return {
    name,
    setName,
    nameIsInvalid,
    setNameIsInvalid,
    onChange,
  };
};

export const useBioInput = (initial: string) => {
  const [bio, setBio] = useState(initial);
  const [bioIsInvalid, setBioIsInvalid] = useState(false);

  const onChange = useCallback(
    (value: string) => {
      setBio(value);
      if (charLength(value) > maxBioLength) {
        setBioIsInvalid(true);
        return;
      }
      setBioIsInvalid(false);
    },
    [setBio, setBioIsInvalid]
  );

  return { bio, setBio, bioIsInvalid, setBioIsInvalid, onChange, maxBioLength };
};

export const useLinkInput = (initial: UrlString) => {
  const [link, setLink] = useState(initial);
  const [linkIsInvalid, setLinkIsInvalid] = useState(false);

  const onChange = useCallback(
    (value: string) => {
      setLink(value.toLowerCase() as UrlString);
      setLinkIsInvalid(false);
    },
    [setLink, setLinkIsInvalid]
  );

  return { link, setLink, linkIsInvalid, setLinkIsInvalid, onChange };
};

export const useUsernameAvailability = (
  username: string
): "pending" | "available" | "unavailable" | "invalid" | undefined => {
  const dispatch = useDispatch();
  const [usernameAvailability, setUsernameAvailability] = useState<Record<string, UsernameAvailableResult>>({});

  const debouncedFetchUsernameAvailability = useRef(
    debounce((un: string, setAvailability: typeof setUsernameAvailability) => {
      dispatch(isUsernameAvailable(un))
        .then(res => {
          setAvailability(existing => {
            return {
              ...existing,
              [res.username]: res,
            };
          });
        })
        .catch(err => {
          log.errorCaught("Error thrown from isUsernameAvailble thunk", err, { username });
        });
    }, 500)
  ).current;

  useEffect(() => {
    if (isValidUsername(username) && !usernameAvailability[username]) {
      debouncedFetchUsernameAvailability(username, setUsernameAvailability);
    }
  }, [username, usernameAvailability, setUsernameAvailability]);

  const availabilityInfo = usernameAvailability[username];
  // because of the debounce, usernameIsInvalid won't reflect actual validity immediately
  return availabilityInfo?.result ?? (isValidUsername(username) ? "pending" : undefined);
};
