import { AdminRecipeFeedType, RecipeSourcePhotoType, UserRecipe } from "@eatbetter/recipes-shared";
import { useAdminRecipeFeed } from "../lib/recipes/UseAdminRecipeFeed.ts";
import { FlatList, useWindowDimensions, View } from "react-native";
import { CurrentEnvironment, Photo, Pressable, Spacer, TBody, TSecondary } from "@eatbetter/ui-shared";
import { getUrlForAsset, PhotoRef } from "@eatbetter/photos-shared";
import { Link } from "react-router-dom";
import { AdminRecipeDocumentsScreenNav } from "../screens/AdminRecipeDocumentsScreen.tsx";
import { AdminUsersScreenIdNav } from "../screens/AdminUsersScreen.tsx";
import { AdminRecipesScreenIdNav } from "../screens/AdminRecipesScreen.tsx";
import { AdminBooksScreenIdNav } from "../screens/AdminBooksScreen.tsx";
import { Divider } from "antd";
import { defaultTimeProvider, hoursBetween } from "@eatbetter/common-shared";

export const AdminRecipeFeed = (props: { feed: AdminRecipeFeedType }) => {
  const { items, fetchNext } = useAdminRecipeFeed(props.feed);
  const window = useWindowDimensions();

  return (
    <View style={{ height: window.height * 0.8 }}>
      <FlatList data={items} renderItem={renderItem} keyExtractor={i => i.id} onEndReached={fetchNext} />
    </View>
  );
};

function renderItem(item: { item: UserRecipe }) {
  const recipe = item.item;
  if (recipe.source.type === "userPhoto") {
    return <UserPhotoRecipe recipe={recipe} />;
  }
  return null;
}

const UserPhotoRecipe = (props: { recipe: UserRecipe }) => {
  const { recipe } = props;
  if (recipe.source.type !== "userPhoto") {
    throw new Error(`Expecting user photo recipe. Got ${props.recipe.source.type} for ${props.recipe.id}`);
  }

  const bookRecipe = !!recipe.source.photoTypes?.some(pt => pt === "book");
  const bookResolved = !!recipe.source.bookId;
  const color = bookRecipe ? (bookResolved ? "green" : "red") : "black";
  let recipeType: string;
  const photos = recipe.source.photos;
  const types = recipe.source.photoTypes ?? [];
  if (types.includes("book")) {
    recipeType = "Book Photo Recipe";
  } else if (types.includes("magazineOrNewspaper")) {
    recipeType = "Magazine/Newspaper Photo Recipe";
  } else if (types.includes("screenshot")) {
    recipeType = "Screenshot Photo Recipe";
  } else {
    recipeType = "Other Photo Recipe";
  }

  const ingredients = recipe.ingredients.sections.reduce((cnt, section) => cnt + section.items.length, 0);
  const instructions = recipe.instructions.sections.reduce((cnt, section) => cnt + section.items.length, 0);
  const hoursAgo = hoursBetween(defaultTimeProvider(), recipe.created).toFixed(1);

  return (
    <View>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <TBody fontWeight="medium" color={color}>
          {recipeType}{" "}
        </TBody>
        <Link to={AdminRecipesScreenIdNav.getPath(recipe.id)}>{recipe.id}</Link>
        <TBody> added by </TBody>
        <Link to={AdminUsersScreenIdNav.getPath(recipe.userId)}>{recipe.userId}</Link>
      </View>
      <Spacer vertical={1} />
      <View style={{ flexDirection: "row" }}>
        {photos.map((photo, idx) => {
          return (
            <>
              <SourcePhoto photo={photo} type={types[idx]!} key={idx} />
              <Spacer horizontal={1} />
            </>
          );
        })}
        <Spacer horizontal={1} />
        <View style={{ alignItems: "center", justifyContent: "center" }}>
          {!!recipe.book && (
            <View style={{ justifyContent: "center", alignItems: "center", width: 250 }}>
              <Photo source={recipe.book.photo} style="thumbnailLarge" sourceSize="w288" resizeMode="contain" />
              <Spacer vertical={1} />
              <Link to={AdminBooksScreenIdNav.getPath(recipe.book.id)} style={{ textAlign: "center" }}>
                {recipe.book.name}
              </Link>
              <Spacer vertical={1} />
            </View>
          )}
          <Link to={AdminRecipeDocumentsScreenNav.getPath(recipe.id)} target="_blank">
            Docs
          </Link>
        </View>
      </View>
      <Spacer vertical={1} />
      <TBody>
        Status is {recipe.status} with {ingredients} ingredients and {instructions} instructions. Added {hoursAgo} hours
        ago.
      </TBody>
      <Spacer vertical={1} />
      <Divider />
      <Spacer vertical={1} />
    </View>
  );
};

const SourcePhoto = (props: { photo: PhotoRef; type: RecipeSourcePhotoType }) => {
  return (
    <View style={{ alignItems: "center", justifyContent: "center" }}>
      <Pressable
        onPress={() =>
          window.open(getUrlForAsset(CurrentEnvironment.configEnvironment(), props.photo, "original"), "_blank")
        }
        style={{ height: 150, width: 150 }}
      >
        <Photo source={props.photo} sourceSize="w1290" resizeMode="contain" style="flexed" />
      </Pressable>
      <Spacer vertical={1} />
      <TSecondary fontWeight="medium">{props.type}</TSecondary>
    </View>
  );
};
