import { AdminScreenView } from "../components/AdminScreenView";
import React, { useCallback, useEffect, useState } from "react";
import { AdminDispatch, useDispatch } from "../lib/AdminRedux";
import {
  addIngredient,
  deleteIngredient,
  loadIngredients,
  updateIngredient,
} from "../lib/data-manager/DataManagerThunks";
import {
  ButtonRectangle,
  ContainerPadded,
  globalStyleConstants,
  log,
  Pressable,
  Spacer,
  TBody,
  TextInput,
  THeading1,
} from "@eatbetter/ui-shared";
import {
  useIngredient,
  useIngredientHistory,
  useIngredientIds,
  useIngredientPhraseFilter,
} from "../lib/data-manager/DataManagerSelectors";
import { HistoryItem, ingredientsFiltered } from "../lib/data-manager/DataManagerSlice";
import { Dimensions, FlatList, View } from "react-native";
import { debounce } from "lodash";
import { DisambiguationItemWithPhrases, ItemWithPhrases, ShoppableItemWithPhrases } from "@eatbetter/items-data";
import { RawCategory } from "@eatbetter/items-server";
import { DisambiguationItemId, ShoppableItemId } from "@eatbetter/items-shared";
import { IngredientEditControl } from "../components/IngredientEditControl";
import { displayExpectedError } from "@eatbetter/ui-shared";
import { switchReturn } from "@eatbetter/common-shared";

const doSearch = debounce((dispatch: AdminDispatch, query: string) => {
  dispatch(ingredientsFiltered({ query }));
});

interface EditItemInfo {
  editId?: ShoppableItemId | DisambiguationItemId;
  item: ItemWithPhrases;
}

export const AdminIngredientScreenNav = {
  getPath: () => "/ingredients",
};

export const AdminIngredientScreen = () => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const [itemToEdit, setItemToEdit] = useState<EditItemInfo | undefined>(undefined);
  const ingredientIds = useIngredientIds();
  const history = useIngredientHistory();

  useEffect(() => {
    dispatch(loadIngredients()).catch(err => log.errorCaught("Error calling loadIngredients", err));
  }, []);

  useEffect(() => {
    doSearch(dispatch, query);
  }, [query]);

  const onNewShoppable = () => {
    const newShoppable: ShoppableItemWithPhrases = {
      type: "shoppable",
      id: "s_" as ShoppableItemId,
      category: "" as RawCategory,
      phrases: [{ s: "" }],
    };

    setItemToEdit({ item: newShoppable });
  };

  const onNewDisambiguation = () => {
    const newDisambiguation: DisambiguationItemWithPhrases = {
      type: "disambiguation",
      id: "d_" as DisambiguationItemId,
      phrases: [{ s: "" }],
      items: [
        { itemId: "" as ShoppableItemId, keywords: [] },
        { itemId: "" as ShoppableItemId, keywords: [] },
      ],
    };

    setItemToEdit({ item: newDisambiguation });
  };

  const onSave = useCallback(
    async (i: ItemWithPhrases) => {
      if (!itemToEdit) {
        log.error("onSave called but itemToEdit is not defined");
        return;
      }

      try {
        if (itemToEdit.editId) {
          await dispatch(updateIngredient(itemToEdit.editId, i));
        } else {
          await dispatch(addIngredient(i));
        }
        setItemToEdit(undefined);
      } catch (err) {
        displayExpectedError(`${err}`);
      }
    },
    [itemToEdit]
  );

  const onCancel = useCallback(() => {
    setItemToEdit(undefined);
  }, [setItemToEdit]);

  const onDelete = useCallback(async () => {
    if (itemToEdit?.editId) {
      try {
        await dispatch(deleteIngredient(itemToEdit.editId));
        setItemToEdit(undefined);
      } catch (err) {
        displayExpectedError(`${err}`);
      }
    }
  }, [itemToEdit]);

  const searchResultOnClick = useCallback(
    (i: ItemWithPhrases) => {
      setItemToEdit({ editId: i.id, item: i });
    },
    [setItemToEdit]
  );

  const renderSearchResult = (item: { item: string }) => {
    return <IngredientSearchResult id={item.item} onClick={searchResultOnClick} key={item.item} />;
  };

  const screenHeight = Dimensions.get("window").height;

  return (
    <AdminScreenView>
      <View style={{ flexDirection: "row", minHeight: screenHeight }}>
        {/*Search */}
        <View style={{ borderRightWidth: 0.1, paddingRight: globalStyleConstants.unitSize }}>
          <View style={{ flexDirection: "row", zIndex: 1000, alignItems: "center" }}>
            <View style={{ width: 400 }}>
              <TextInput value={query} onChangeText={setQuery} placeholderText="Search" />
            </View>
          </View>
          <Spacer vertical={1} />
          <FlatList data={ingredientIds} renderItem={renderSearchResult} keyExtractor={i => i} />
        </View>

        <Spacer horizontal={2} />

        {/*Form*/}
        <View style={{ flex: 1 }}>
          {!itemToEdit && (
            <View>
              <View style={{ flexDirection: "row" }}>
                <ButtonRectangle title="New Shoppable Ingredient" onPress={onNewShoppable} type="submit" />
                <Spacer horizontal={1} />
                <ButtonRectangle title="New Disambiguation Item" onPress={onNewDisambiguation} type="submit" />
              </View>
              <ContainerPadded all={1}>
                <Spacer vertical={3} />
                {history.length > 0 && <THeading1 fontWeight="medium">History</THeading1>}
                {history.map(h => {
                  return <History key={h.id} item={h} onEditItem={setItemToEdit} />;
                })}
              </ContainerPadded>
            </View>
          )}
          {!!itemToEdit && (
            <View style={{ maxWidth: 800 }}>
              <IngredientEditControl
                key={itemToEdit.item.id}
                initialItem={itemToEdit.item}
                onSave={onSave}
                onDelete={itemToEdit.editId ? onDelete : undefined}
                onCancel={onCancel}
              />
            </View>
          )}
        </View>
      </View>
    </AdminScreenView>
  );
};

interface HistoryProps {
  item: HistoryItem;
  onEditItem: (editItemInfo: EditItemInfo) => void;
}

const History = React.memo((props: HistoryProps) => {
  const current = useIngredient(props.item.id);
  const onPressOld = () => {
    // in the case an item was deleted, current will be undefined and we should treat it as a new item by
    // not passing edit ID
    props.onEditItem({ editId: current ? props.item.id : undefined, item: props.item.originalItem });
  };

  const onPressCurrent = () => {
    if (current) {
      props.onEditItem({ editId: props.item.id, item: current });
    }
  };

  const firstLinkText = switchReturn(props.item.op, {
    add: "View original",
    edit: "View old version",
    delete: "View deleted",
  });

  const secondLinkText = switchReturn(props.item.op, {
    add: "View current",
    edit: "View current",
    delete: undefined,
  });

  return (
    <ContainerPadded top={1}>
      <TBody>{props.item.actionDescription}</TBody>
      <Spacer horizontal={1} />
      <View style={{ flexDirection: "row" }}>
        <Pressable onPress={onPressOld}>{firstLinkText}</Pressable>
        {!!current && !!secondLinkText && (
          <>
            <Spacer horizontal={1} />
            <Pressable onPress={onPressCurrent}>{secondLinkText}</Pressable>
          </>
        )}
      </View>
    </ContainerPadded>
  );
});

const IngredientSearchResult = React.memo((props: { id: string; onClick: (i: ItemWithPhrases) => void }) => {
  const ingredient = useIngredient(props.id);
  const phraseFilter = useIngredientPhraseFilter();

  if (!ingredient) {
    throw new Error(`Didn't find ingredient for ID ${props.id}`);
  }

  // the phrase filter will report id/category matches, but we already display
  // those by default, so just filter those out
  const category = ingredient.type === "shoppable" ? ingredient.category : undefined;
  const phrases = phraseFilter(ingredient).filter(p => p !== ingredient.id && p !== category);

  return (
    <Pressable onPress={() => props.onClick(ingredient)}>
      <View>
        <TBody fontWeight="heavy">
          {ingredient.type} {ingredient.id}
        </TBody>
        {ingredient.type === "shoppable" && <TBody>{ingredient.category}</TBody>}
        {ingredient.type === "disambiguation" && <TBody>{ingredient.items.length} items</TBody>}
        {phrases.map(p => {
          return <TBody key={p}>{p}</TBody>;
        })}
        <Spacer vertical={1} />
      </View>
    </Pressable>
  );
});
