import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { useCallback, useMemo, useState } from "react";
import { ProfileEditFieldScreenComponent } from "../components/social/ProfileEditFieldScreenComponent";
import { useDispatch } from "../lib/redux/Redux";
import { updateUserProfile } from "../lib/system/SystemThunks";
import { useNameInput } from "../lib/system/UserProfileHooks";
import { bottomThrow } from "@eatbetter/common-shared";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { useRegisteredUser } from "../lib/system/SystemSelectors";

const strings = {
  screenHeader: "Name",
  label: "Name",
  nameInvalid: "Name should be 3 to 30 characters.",
};

export const ProfileEditNameScreen = withNonNavigableScreenContainer("ProfileEditNameScreen", () => {
  const screen = useScreen();
  const dispatch = useDispatch();
  const persistedName = useRegisteredUser()?.name ?? "";
  const nameInput = useNameInput(persistedName);
  const isDirty = useMemo(() => persistedName !== nameInput.name, [persistedName, nameInput.name]);

  const [waiting, setWaiting] = useState(false);

  const onSave = useCallback(async () => {
    if (!isDirty) {
      return;
    }

    setWaiting(true);
    try {
      const result = await dispatch(updateUserProfile({ name: nameInput.name }));
      setWaiting(false);

      switch (result) {
        case "success":
          break;
        case "nameInvalid":
          nameInput.setNameIsInvalid(true);
          return;
        case "usernameInvalid":
        case "usernameUnavailable":
        case "bioInvalid":
        case "linksInvalid":
          break;
        default:
          bottomThrow(result);
      }

      screen.nav.goBack();
    } catch (err) {
      displayUnexpectedErrorAndLog("Unexpected error in ProfileEditNameScreen.onSubmit", err);
      setWaiting(false);
      return;
    }
  }, [isDirty, dispatch, nameInput.name, nameInput.setNameIsInvalid, setWaiting, screen.nav.goBack]);

  return (
    <ProfileEditFieldScreenComponent
      screenHeader={strings.screenHeader}
      label={strings.label}
      value={nameInput.name}
      onChangeText={nameInput.onChange}
      isDirty={isDirty}
      onSubmit={onSave}
      showClearButton={false}
      validationState={
        isDirty ? (nameInput.nameIsInvalid ? "invalid" : nameInput.name.length > 2 ? "valid" : "none") : undefined
      }
      showIsValidIcon={false}
      errorMessage={nameInput.nameIsInvalid ? strings.nameInvalid : undefined}
      waiting={waiting}
      isPostMetadata
    />
  );
});
