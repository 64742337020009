import { UrlString, switchReturn } from "@eatbetter/common-shared";
import { EntityDisplay } from "@eatbetter/photos-shared";
import { KnownAuthorId, KnownPublisherId } from "@eatbetter/recipes-shared";
import React from "react";
import { View } from "react-native";
import { globalStyleConstants } from "../GlobalStyles";
import { Spacer } from "../Spacer";
import { FollowEntityButton } from "../Buttons";
import { Separator } from "../Separator";
import { TBody, TSecondary } from "../Typography";
import { EntityUrlLink } from "./ProfileLinks";
import { ProfileLinkTappedContext } from "../../lib/analytics/AnalyticsEvents";
import { getEntityDisplayText } from "@eatbetter/posts-shared";

const strings = {
  subhead: "Follow this collection to personalize your search results and get notified about new recipes.",
};

interface KnownEntityCardProps {
  context: "knownAuthor" | "knownPublisher";
  id: KnownAuthorId | KnownPublisherId;
  profileInfo: EntityDisplay;
  externalLink?: UrlString;
}

export const KnownEntityCard = React.memo((props: KnownEntityCardProps) => {
  const analyticsContext = switchReturn<string, ProfileLinkTappedContext>(props.context, {
    knownAuthor: "knownAuthorPage",
    knownPublisher: "knownPublisherPage",
  });

  const headerText = getEntityDisplayText({ type: props.context, ...props.profileInfo }).headline;

  return (
    <View
      style={{
        paddingHorizontal: globalStyleConstants.defaultPadding,
        paddingTop: 1.5 * globalStyleConstants.unitSize,
        paddingBottom: globalStyleConstants.unitSize,
      }}
    >
      <KnownEntityHeader headerText={headerText} subHeaderText={strings.subhead} />
      {!!props.externalLink && (
        <>
          <Spacer vertical={1} />
          <EntityUrlLink url={props.externalLink} index={0} analyticsContext={analyticsContext} entityId={props.id} />
        </>
      )}
      <Spacer vertical={2} />
      <FollowEntityButton entityId={props.id} context="otherUserProfile" />
      <Spacer vertical={2} />
      <Separator orientation="row" />
    </View>
  );
});

const KnownEntityHeader = React.memo((props: { headerText: string; subHeaderText: string }) => {
  return (
    <View>
      <TBody fontWeight="medium">{props.headerText}</TBody>
      <Spacer vertical={0.25} />
      <TSecondary opacity="medium">{props.subHeaderText}</TSecondary>
    </View>
  );
});
