import { NavApi, useScreen } from "../../navigation/ScreenContainer";
import { AnonymousRegistrationScreenProps, navTree } from "../../navigation/NavTree";
import { RootState } from "../redux/RootReducer";
import { DependencyList, useCallback } from "react";
import { useIsAnonymousUser } from "../system/SystemSelectors";
import { log } from "../../Log";

export function checkAnonymousUserAndLogError(state: RootState, op: string): boolean {
  if (isAnonUser(state)) {
    log.error(`Found anon user in checkAnonymousUserAndLogError for op ${op}`);
    return true;
  }

  return false;
}

/**
 * This should be used when the registration screen should show *before* something is dispatched. If there is a central
 * thunk (for liking a post, for example), navToAnonymousSignInIfAnon should be used so that if we add the like action
 * anywhere else, it will automatically work.
 * For things like comments, where we want to trigger the screen on tapping reply, starting to write a comment, etc.
 * we should use this since there is no thunk dispatched.
 * @param userVisibleActionDescription
 * @param callback
 * @param deps
 */
export const useAnonymousRedirectCallback = <T extends (...args: any[]) => any>(
  userVisibleActionDescription: string,
  callback: T,
  deps: DependencyList
): T => {
  const isAnonymous = useIsAnonymousUser();
  const cb = useCallback(callback, deps);
  const { nav } = useScreen();

  if (isAnonymous) {
    return (() => navToAnonymousSignin(nav, { mode: "action", userVisibleActionDescription })) as T;
  }

  return cb;
};

export function navToAnonymousSigninIfAnon(
  state: RootState,
  nav: NavApi,
  userVisibleActionDescription: string
): boolean {
  if (isAnonUser(state)) {
    navToAnonymousSignin(nav, { mode: "action", userVisibleActionDescription });
    return true;
  }

  return false;
}

export function navToAnonymousSignin(nav: NavApi, props: AnonymousRegistrationScreenProps) {
  nav.modal(navTree.get.screens.anonymousRegistration, props);
}

function isAnonUser(state: RootState): boolean {
  return state.system.authedUser.data?.isRegistered === false;
}
