import {
  ContainerFlexCentered,
  AppErrorBoundary,
  setCurrentEnvironmentImpl,
  globalStyleColors,
} from "@eatbetter/ui-shared";
import { Provider } from "react-redux";
import { createReduxStore } from "./lib/AdminRedux";
import { AdminNav } from "./components/AdminNav";
import { Button, StyleSheet, View } from "react-native";
import { PropsWithChildren, useEffect, useState } from "react";
import { adminDeps } from "./lib/AdminDeps";
import { onSignIn, subscribeToAuth } from "./lib/AdminAuth";
import { useSystemTheme } from "./lib/system/AdminSystemSelectors";

setCurrentEnvironmentImpl(adminDeps);

const store = createReduxStore();

export default function AdminApp() {
  const [signedIn, setSignedIn] = useState(false);

  useEffect(() => {
    return subscribeToAuth({
      onSignIn: () => setSignedIn(true),
      onSignOut: () => setSignedIn(false),
    });
  }, []);

  return (
    <AppErrorBoundary componentName="AdminApp">
      <Provider store={store}>
        <AdminContainer>
          {signedIn && <AdminNav />}
          {!signedIn && (
            <ContainerFlexCentered>
              <Button onPress={onSignIn} title="Sign In" />
            </ContainerFlexCentered>
          )}
        </AdminContainer>
      </Provider>
    </AppErrorBoundary>
  );
}

const AdminContainer = (props: PropsWithChildren<{}>) => {
  const theme = useSystemTheme();
  const backgroundColor = theme === "light" ? "#FAF3DD" : "darkgrey";

  return (
    <View style={[styles.container, { backgroundColor }]}>
      {globalCss}
      {props.children}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    minHeight: "100%",
    minWidth: "100%",
    overflow: "scroll",
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
  },
});

const globalCss = (
  <style type="text/css">{`
  a {
    color: ${globalStyleColors.colorTextLink}; 
    text-decoration: none;
  }
`}</style>
);
