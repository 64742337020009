import { defaultTimeProvider, DurationMs } from "@eatbetter/common-shared";
import { useCallback, useEffect, useRef } from "react";
import { useAppFocused } from "../system/SystemSelectors";

export type SlideTimeTrackerCompletedHandler = (args: { durations: DurationMs[]; backActions: number }) => void;

export function useSlideTimeTracker(index: number, onCompleted: SlideTimeTrackerCompletedHandler) {
  const timeTracker = useRef<SlideTimeTracker>();

  useEffect(() => {
    timeTracker.current = new SlideTimeTracker();

    return () => {
      const results = timeTracker.current?.getDurationsAndBackActions();
      if (results) {
        onCompleted(results);
      }
    };
  }, []);

  useEffect(() => {
    timeTracker.current?.indexChanged(index);
  }, [index]);

  const appFocused = useAppFocused();

  useEffect(() => {
    if (appFocused) {
      timeTracker.current?.appForegrounded();
    } else {
      timeTracker.current?.appBackgrounded();
    }
  }, [appFocused]);

  const complete = useCallback(() => {
    timeTracker.current?.complete();
  }, []);

  return complete;
}

class SlideTimeTracker {
  private currentIndex = 0;
  private backgrounded = false;
  private start = defaultTimeProvider();
  private backActions = 0;
  private readonly durations: number[][] = [[]];

  indexChanged(idx: number) {
    if (idx < 0 || idx === this.currentIndex) {
      return;
    }

    if (idx < this.currentIndex) {
      this.backActions++;
    }

    // there is a mismatch in the initialization here and the push of duration below.
    // this is because 0 is initialized in the initial setter (and zero is the default start index)
    // but we need the array for the new index to exist so we can push in appBackgrounded
    if (!this.durations[idx]) {
      this.durations[idx] = [];
    }

    const duration = defaultTimeProvider() - this.start;
    this.durations[this.currentIndex]?.push(duration);

    this.currentIndex = idx;
    this.start = defaultTimeProvider();
  }

  complete() {
    const duration = defaultTimeProvider() - this.start;
    this.durations[this.currentIndex]?.push(duration);
  }

  appBackgrounded() {
    if (!this.backgrounded) {
      const duration = defaultTimeProvider() - this.start;
      this.durations[this.currentIndex]?.push(duration);
      this.backgrounded = true;
    }
  }

  appForegrounded() {
    if (this.backgrounded) {
      this.start = defaultTimeProvider();
      this.backgrounded = false;
    }
  }

  getDurationsAndBackActions(): { durations: DurationMs[]; backActions: number } {
    const durations: DurationMs[] = [];
    for (let i = 0; i < this.durations.length; i++) {
      const durationsForIndex = this.durations[i];
      if (durationsForIndex) {
        durations.push(durationsForIndex.reduce((a, b) => a + b) as DurationMs);
      } else {
        durations.push(0 as DurationMs);
      }
    }

    return { durations, backActions: this.backActions };
  }
}
