import { CookingSessionId } from "@eatbetter/cooking-shared";
import { RecipeInstructions } from "@eatbetter/recipes-shared";
import { NativeAudioApi } from "./NativeAudio";
import * as Speech from "expo-speech";
import { log } from "../Log";
import { defaultTimeProvider, emptyToUndefined, EpochMs, secondsBetween, TimeProvider } from "@eatbetter/common-shared";

export interface NowPlayingInfo {
  sessionId: CookingSessionId;
  instructions: RecipeInstructions;
  sectionIndex: number;
  instructionIndex: number;
  title: string;
  author?: string;
  book?: string;
  publisher?: string;
  photo?: string;
}

type AudioNextPreviousHandler = (args: {
  cookingSessionId: CookingSessionId;
  instructionIndex: number;
  sectionIndex: number;
}) => void;

export class AudioCookingSessionManager {
  private sessionStarted = false;
  private readonly nativeAudioApi: NativeAudioApi;
  private readonly speechPlayer: SpeechPlayer;
  private currentInfo: NowPlayingInfo | undefined;
  private nextPreviousHandler: AudioNextPreviousHandler | undefined;
  private autoNextMode = false;

  constructor(nativeAudioApi: NativeAudioApi = new NativeAudioApi()) {
    this.nativeAudioApi = nativeAudioApi;
    this.speechPlayer = new SpeechPlayer({
      beforeSpeaking: this.nativeAudioApi.startSpeech,
      afterSpeaking: this.nativeAudioApi.endSpeech,
      onDone: this.onDone,
    });
  }

  setAutoNextMode = (enabled: boolean): void => {
    this.autoNextMode = enabled;
  };

  async startSession(args: { nextPreviousHandler?: AudioNextPreviousHandler }) {
    log.logRemote("Audio session started");
    this.nextPreviousHandler = args.nextPreviousHandler;
    await this.nativeAudioApi.startAudioInstructions({
      onNext: () => {
        log.logRemote("Audio session command center next");
        this.next();
      },
      onPrevious: () => {
        log.logRemote("Audio session command center previous");
        this.previous();
      },
      onPause: () => {
        log.logRemote("Audio session command center pause");
        this.pause();
      },
      onPlay: () => {
        log.logRemote("Audio session command center play");
        this.resume();
      },
      onInterrupted: () => {
        log.logRemote("Audio session onInterrupted");
        this.autoNextMode = false;
        this.speechPlayer
          .stopAndClearQueue()
          .catch(err => log.errorCaught("Error calling stopAndClearQueue in onInterrupted", err));
      },
    });

    this.sessionStarted = true;
  }

  async endSession() {
    if (!this.sessionStarted) {
      return;
    }

    log.logRemote("Audio session ended");
    await this.speechPlayer.stopAndClearQueue();
    await this.nativeAudioApi.endAudioInstructions();
    this.nextPreviousHandler = undefined;

    this.sessionStarted = false;
  }

  playInstruction(info: NowPlayingInfo) {
    try {
      this.currentInfo = info;

      const section = info.instructions.sections[info.sectionIndex];
      const instruction = section?.items[info.instructionIndex];

      if (!instruction) {
        throw new Error(`Invalid info. No instruction for indices. Info: ${JSON.stringify(info)}`);
      }

      log.logRemote("Audio playInstruction called");

      const toSpeak: string[] = [`Step ${info.instructionIndex + 1}. -- ${instruction.text}`];

      if (info.instructionIndex === 0 && emptyToUndefined(section.title)) {
        toSpeak.unshift(section.title ?? "");
      }

      const hasNext =
        info.instructionIndex < section.items.length - 1 || info.sectionIndex < info.instructions.sections.length - 1;

      this.nativeAudioApi
        .setAudioInstructionsNowPlaying({
          title: info.title,
          hasNext,
          instruction: instruction.text,
          photoUrl: info.photo,
          author: info.author,
          bookOrPublisher: info.publisher ?? info.book,
        })
        .catch(err => log.errorCaught("Error calling setAudioInstructionsNowPlaying", err, { info }));

      this.speechPlayer.speak(toSpeak).catch(err => log.errorCaught("Error calling SpeechPlayer.speak", err));
    } catch (err) {
      log.errorCaught("Error in AudioCookingSessionManager.playInstruction", err);
    }
  }

  resume = () => {
    try {
      log.info("AudioCookingSessionManager.onPlay");
      if (this.speechPlayer.paused()) {
        this.speechPlayer.play().catch(err => log.errorCaught("Error calling speechPlayer.play", err));
      } else if (this.currentInfo) {
        this.playInstruction(this.currentInfo);
      }
    } catch (err) {
      log.errorCaught("Error in AudioCookingSessionManager.resume", err);
    }
  };

  pause = () => {
    try {
      log.info("AudioCookingSessionManager.onPause");
      this.speechPlayer.pause().catch(err => log.errorCaught("Error calling speechPlayer.pause", err));
    } catch (err) {
      log.errorCaught("Error in AudioCookingSessionManager.pause", err);
    }
  };

  next = () => {
    try {
      log.info("AudioCookingSessionManager.onNext");
      const info = this.currentInfo;

      if (!info) {
        return;
      }

      const currentSection = info.instructions.sections[info.sectionIndex];
      if (!currentSection) {
        return;
      }

      let sectionIndex = info.sectionIndex;
      let instructionIndex = info.instructionIndex + 1;

      if (info.instructionIndex === currentSection.items.length - 1) {
        sectionIndex += 1;
        instructionIndex = 0;
      }

      if (
        info.instructions.sections[sectionIndex] &&
        info.instructions.sections[sectionIndex]?.items[instructionIndex]
      ) {
        const newInfo = {
          ...info,
          instructionIndex,
          sectionIndex,
        };

        this.nextPreviousHandler?.({
          cookingSessionId: newInfo.sessionId,
          sectionIndex: newInfo.sectionIndex,
          instructionIndex: newInfo.instructionIndex,
        });

        this.playInstruction(newInfo);
      }
    } catch (err) {
      log.errorCaught("Error in AudioCookingSessionManager.next", err);
    }
  };

  previous = () => {
    try {
      log.info("AudioCookingSessionManager.onPrevious");
      const info = this.currentInfo;

      if (!info) {
        return;
      }

      if (this.speechPlayer.secondsIntoCurrentInstruction() > 3) {
        this.playInstruction(info);
        return;
      }

      const currentSection = info.instructions.sections[info.sectionIndex];
      if (!currentSection) {
        return;
      }

      let sectionIndex = info.sectionIndex;
      let instructionIndex = info.instructionIndex - 1;

      if (info.instructionIndex === 0) {
        sectionIndex -= 1;
        instructionIndex = (info.instructions.sections[sectionIndex]?.items.length ?? 0) - 1;
      }

      if (
        info.instructions.sections[sectionIndex] &&
        info.instructions.sections[sectionIndex]?.items[instructionIndex]
      ) {
        const newInfo = {
          ...info,
          instructionIndex,
          sectionIndex,
        };

        this.nextPreviousHandler?.({
          cookingSessionId: newInfo.sessionId,
          sectionIndex: newInfo.sectionIndex,
          instructionIndex: newInfo.instructionIndex,
        });
        this.playInstruction(newInfo);
      } else if (this.currentInfo) {
        // if there isn't a previous instruction, replay the current one
        this.playInstruction(this.currentInfo);
      }
    } catch (err) {
      log.errorCaught("Error in AudioCookingSessionManager.previous", err);
    }
  };

  private onDone = () => {
    try {
      log.logRemote(`Audio onDone. autoNextMode: ${this.autoNextMode}`);
      if (this.autoNextMode) {
        this.next();
      }
    } catch (err) {
      log.errorCaught("Error in AudioCookingSessionManager.onDone", err);
    }
  };
}

class SpeechPlayer {
  private beforeSpeaking: (() => Promise<void>) | undefined;
  private afterSpeaking: (() => Promise<void>) | undefined;
  private onDone: (() => void) | undefined;
  private readonly timeProvider: TimeProvider;
  private isPaused = false;

  // keep track of if the current utterance was stopped so we can determine correct
  // behavior in handleOnDone
  private wasStopped = false;
  // track how far we are into the current instruction
  // when pausing, we set duration so far and clear started
  private started: EpochMs | undefined;
  private durationSoFar: number = 0;

  constructor(args: {
    beforeSpeaking: (() => Promise<void>) | undefined;
    afterSpeaking: (() => Promise<void>) | undefined;
    timeProvider?: TimeProvider;
    onDone?: () => void;
  }) {
    this.beforeSpeaking = args.beforeSpeaking;
    this.afterSpeaking = args.afterSpeaking;
    this.onDone = args.onDone;
    this.timeProvider = args.timeProvider ?? defaultTimeProvider;
  }

  /**
   * An array is used because the library inserts a small delay between strings.
   * @param toSpeak
   */
  async speak(toSpeak: string[]): Promise<void> {
    await this.stop();

    await this.beforeSpeaking?.();
    this.started = this.timeProvider();
    this.durationSoFar = 0;

    log.info(`Audio: speak for ${toSpeak[toSpeak.length - 1]?.substring(0, 20)}`);

    this.wasStopped = false;
    toSpeak.forEach((s, idx) =>
      Speech.speak(s, {
        rate: 1,
        onDone: idx === toSpeak.length - 1 ? this.handleOnDone : undefined,
        onError: err => {
          log.errorCaught("Speech onError", err);
          this.afterSpeaking?.().catch(err =>
            log.errorCaught("Error calling afterSpeaking on Speech.speak error handler", err)
          );
        },
      })
    );

    this.isPaused = false;
  }

  /**
   * Returns the number of seconds the current instruction has been playing, if it is playing
   */
  secondsIntoCurrentInstruction(): number {
    if (this.started) {
      return this.durationSoFar + secondsBetween(this.started, this.timeProvider());
    }

    return this.durationSoFar;
  }

  paused(): boolean {
    return this.isPaused;
  }

  async play(): Promise<void> {
    await this.beforeSpeaking?.();
    this.started = this.timeProvider();
    await Speech.resume();
    this.isPaused = false;
  }

  async pause(): Promise<void> {
    if (this.started) {
      this.durationSoFar += secondsBetween(this.started, this.timeProvider());
      this.isPaused = true;
    }

    this.started = undefined;
    await Speech.pause();
    await this.afterSpeaking?.();
  }

  async stopAndClearQueue(): Promise<void> {
    // We were seeing an error when deactivating the audio session after calling stop
    // adding this pause call here and the second stop call seemed to address it.
    // I'm assuming there is a bug in the underlying library, but not sure
    await this.stop();
    await this.afterSpeaking?.();
  }

  private async stop(): Promise<void> {
    this.wasStopped = true;
    await Speech.stop();
    this.started = undefined;
    this.durationSoFar = 0;
  }

  private handleOnDone = () => {
    log.info(`Audio: JS handleOnDone. wasStopped: ${this.wasStopped}`);
    if (this.wasStopped) {
      log.info("Utterance was stopped. Skipping onDone handler.");
      return;
    }

    this.afterSpeaking?.().catch(err => log.errorCaught("Error calling afterSpeaking in handleOnDone", err));
    this.isPaused = false;
    this.onDone?.();
  };
}
