import { createSelector1, createSelector2, createSelector3, useSelector } from "../AdminRedux";
import { ingredientSelectors, truthSetPhraseSelectors } from "./DataManagerSlice";
import { ItemWithPhrases, TruthSetPhrase, VerifiableField } from "@eatbetter/items-data";

const ingredientPhraseFilter = createSelector1(
  s => s.dataManager.ingredientFilters,
  filters => {
    return (i: ItemWithPhrases) => {
      const query = filters.query?.trim().toLowerCase();

      if (!query || query.length < 2) {
        return [];
      }

      // ID search
      const isIdSearch = query.match(/[a-z]_/);
      const idMatch = isIdSearch && i.id.toLowerCase().includes(query) ? [i.id] : [];
      const disambiguationMatches = isIdSearch
        ? i.type === "disambiguation"
          ? i.items.flatMap(di => {
              return di.itemId.toLowerCase().includes(query) ? [di.itemId] : [];
            })
          : []
        : [];

      // Category search
      const categoryMatch = i.type === "shoppable" && i.category.toLowerCase().includes(query) ? [i.category] : [];

      // phase search
      const phraseMatches = i.phrases.flatMap(({ s, p }) => {
        const phrases = [s, p ?? `${s}s`];
        if (phrases.find(i => i.toLowerCase().includes(query))) {
          return [p ? `${s}, ${p}` : `${s}(s)`];
        }

        return [];
      });

      return [...idMatch, ...disambiguationMatches, ...categoryMatch, ...phraseMatches];
    };
  }
);

export const useIngredientPhraseFilter = () => useSelector(s => ingredientPhraseFilter(s));

const selectIngredients = createSelector2(
  s => ingredientPhraseFilter(s),
  s => ingredientSelectors.selectAll(s.dataManager.ingredients),
  (filter, ingredients) => {
    return ingredients.filter(i => filter(i).length > 0).map(i => i.id);
  }
);

export const useIngredientIds = () => useSelector(s => selectIngredients(s));

export const useIngredient = (id: string) => {
  return useSelector(s => ingredientSelectors.selectById(s.dataManager.ingredients, id));
};

const selectShoppableIngredients = createSelector1(
  s => ingredientSelectors.selectAll(s.dataManager.ingredients),
  items => {
    return items
      .filter(i => i.type === "shoppable")
      .sort()
      .map(i => {
        return { id: i.id, phrase: i.phrases[0]?.s ?? "<none>" };
      });
  }
);

export const useShoppableIngredients = () => useSelector(s => selectShoppableIngredients(s));

export const useIngredientCategories = () => useSelector(s => s.dataManager.ingredientCategories);
export const useKnownDisambiguationTypes = () => useSelector(s => s.dataManager.knownDisambiguationTypes);
export const useUnitMeasurementTypes = () => useSelector(s => s.dataManager.unitMeasurementTypes);

export const useTruthSetPhrase = (phrase: string) => {
  return useSelector(s => truthSetPhraseSelectors.selectById(s.dataManager.truthSetPhrases, phrase));
};

export const selectHistory = createSelector1(
  s => s.dataManager.ingredientHistory,
  history => {
    return history.slice(0, 20);
  }
);

export const useIngredientHistory = () => useSelector(selectHistory);

const selectPhraseIds = createSelector3(
  s => truthSetPhraseSelectors.selectAll(s.dataManager.truthSetPhrases),
  s => s.dataManager.truthSetFilters,
  s => s.dataManager.truthSetPhraseRandomSort,
  (phrases, truthSetFilters, random) => {
    const { query, filters, sort } = truthSetFilters;

    // the filters can be one of 2 types
    // 1. A predidcate defined in the map below
    // 2. A tag name applied during truth set processing
    // If the filter name doesn't exist in the map, we assume it's a server tag
    const predicates: Array<(t: TruthSetPhrase) => boolean> = [];
    const hasDebugTags: string[] = [];
    const notHasDebugTags: string[] = [];

    filters?.forEach(filter => {
      const predicate = truthPredicates[filter];
      if (predicate) {
        predicates.push(predicate);
      } else if (filter.startsWith("+")) {
        hasDebugTags.push(filter.slice(1));
      } else if (filter.startsWith("-")) {
        notHasDebugTags.push(filter.slice(1));
      } else {
        throw new Error(`Invalid filter: ${filter}`);
      }
    });

    if (hasDebugTags.length > 0 || notHasDebugTags.length > 0) {
      predicates.push((phrase: TruthSetPhrase) => {
        return (
          hasDebugTags.every(dt => phrase.debugTags && phrase.debugTags.includes(dt)) &&
          !notHasDebugTags.some(dt => phrase.debugTags && phrase.debugTags.includes(dt))
        );
      });
    }

    const normalizedQuery = query?.toLowerCase() ?? "";
    if (normalizedQuery) {
      predicates.push((phrase: TruthSetPhrase) => phrase.phrase.toLowerCase().includes(normalizedQuery));
    }

    const r = phrases
      .filter(phrase => predicates.every(predicate => predicate(phrase)))
      .sort((a, b) => {
        if (sort === "changed") {
          return (b.dateChanged ?? 0) - (a.dateChanged ?? 0);
        } else if (sort === "random") {
          return (random?.[b.phrase] ?? 0) - (random?.[a.phrase] ?? 0);
        }

        return (b.dateUpdated ?? 0) - (a.dateUpdated ?? 0);
      })
      .map(p => p.phrase);

    return r;
  }
);

type TruthSetPredicate = (p: TruthSetPhrase) => boolean;
const truthPredicates: Record<string, TruthSetPredicate> = {
  "Verified Changed": (p: TruthSetPhrase) => {
    const fields: Array<VerifiableField<unknown> | undefined> =
      p.itemType === "ingredient" ? [p.ingredient, p.shoppablePhrase, p.scaling] : [p.scaling, p.timers];
    return fields.reduce((a, b) => a || b?.status === "correctChanged" || b?.status === "incorrectChanged", false);
  },
  "Ingredients Only": (p: TruthSetPhrase) => p.itemType === "ingredient",
  "Instructions Only": (p: TruthSetPhrase) => p.itemType === "instruction",
  ...getVerifiableFieldPredicates(undefined, "Scale", p => p.scaling, true),
  ...getVerifiableFieldPredicates(
    "ingredient",
    "Ingredient",
    p => (p.itemType === "ingredient" ? p.ingredient : undefined),
    true
  ),
  ...getVerifiableFieldPredicates(
    "ingredient",
    "Shoppable",
    p => (p.itemType === "ingredient" ? p.shoppablePhrase : undefined),
    true
  ),
  ...getVerifiableFieldPredicates(
    "ingredient",
    "Category",
    p => (p.itemType === "ingredient" ? p.category : undefined),
    false
  ),
};

function getVerifiableFieldPredicates(
  typeFilter: TruthSetPhrase["itemType"] | undefined,
  label: string,
  selector: (p: TruthSetPhrase) => VerifiableField<any> | undefined,
  includeVerified: boolean
): Record<string, TruthSetPredicate> {
  const predicates: Record<string, TruthSetPredicate> = {
    [`${label} - Defined`]: (p: TruthSetPhrase) => {
      if (typeFilter && p.itemType !== typeFilter) {
        return false;
      }
      const vf = selector(p);
      return !!vf && !!vf.current;
    },
    [`${label} - Undefined`]: (p: TruthSetPhrase) => {
      if (typeFilter && p.itemType !== typeFilter) {
        return false;
      }
      const vf = selector(p);
      return !vf || !vf.current;
    },
  };

  if (!includeVerified) {
    return predicates;
  }

  const verifiedPredicates: Record<string, TruthSetPredicate> = {
    [`${label} - Unverified`]: (p: TruthSetPhrase) => {
      if (typeFilter && p.itemType !== typeFilter) {
        return false;
      }
      const vf = selector(p);
      return vf?.status === undefined;
    },
    [`${label} - Correct`]: (p: TruthSetPhrase) => {
      if (typeFilter && p.itemType !== typeFilter) {
        return false;
      }
      const vf = selector(p);
      return vf?.status === "correct";
    },
    [`${label} - Incorrect`]: (p: TruthSetPhrase) => {
      if (typeFilter && p.itemType !== typeFilter) {
        return false;
      }
      const vf = selector(p);
      return vf?.status === "incorrect";
    },
  };

  return {
    ...predicates,
    ...verifiedPredicates,
  };
}

export const usePhraseIds = () => useSelector(selectPhraseIds);

const selectTruthSetFilterNames = createSelector1(
  s => s.dataManager.truthSetPhrases.entities,
  e => {
    const phrases = Object.values(e);
    const tags = new Set<string>();
    phrases.forEach(p => {
      if (!p) {
        return;
      }

      p.debugTags?.forEach(t => tags.add(t));
    });

    // we have 2 types of filters
    // 1. Predefined filters from the predicates above
    // 2. Tags that get set on the item during the truth set processing.
    // make sure we don't have any name conflicts.
    const defined = Object.keys(truthPredicates);

    defined.forEach(d => {
      if (d.startsWith("+") || d.startsWith("-")) {
        throw new Error(`Invalid filter name: ${d}`);
      }
    });

    const tagList = [...tags].sort();
    const allTags = tagList.flatMap(t => [`+${t}`, `-${t}`]);
    return [...defined, ...allTags];
  }
);

export const useTruthSetFilterNames = () => useSelector(selectTruthSetFilterNames);
