import { withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { TBody, THeading2 } from "../components/Typography";
import { View } from "react-native";
import { globalStyleColors } from "../components/GlobalStyles";
import { useEffect, useRef } from "react";
import { ScreenFooter } from "../components/ScreenFooter";
import { ContainerEnd } from "../components/Containers";
import { Spacer } from "../components/Spacer";
import { useDispatch } from "../lib/redux/Redux";
import { appSignInLinkReceivedInWeb } from "../lib/system/SystemSlice";
import { useAppSignInLinkForWeb } from "../lib/system/SystemSelectors";

const strings = {
  title: "deglaze email sign-in help",
  explanation: [
    "It looks like you're trying to sign in to the Deglaze app with an email sign-in link.",
    "The link you tapped usually opens the Deglaze app, but you've ended up in the browser.",
    "Make sure you are tapping the link on the phone/tablet that has Deglaze installed. ",
  ],
  linkPre: "If this is the correct device,  ",
  linkText: "tap here",
  linkPost: " to open deglaze and sign in.",
  stillNeedHelp: "Still not working? Please email us at ",
};

export const AppEmailLinkSignInHelpScreen = withNonNavigableScreenContainer("AppEmailLinkSignInHelpScreen", () => {
  const dispatch = useDispatch();
  const reduxLinks = useAppSignInLinkForWeb();
  const links = useRef<typeof reduxLinks>();

  useEffect(() => {
    if (reduxLinks) {
      // save them
      links.current = reduxLinks;

      // clear the link - it's on the props too, but this makes sure subsequent loading of the app works
      // if it sticks around LaunchScreen always navs here
      dispatch(appSignInLinkReceivedInWeb(undefined));

      // we set the URL to be the original link we received. In the case that safari displays the "open in app"
      // banner, this should sign the user in.
      // and if the user refreshes, it will start the flow all over again
      // url wasn't updating in chrome without the set timeout. I'm guessing there was a race between
      // react-navigation and this call.
      setTimeout(() => {
        history.pushState(null, "", links.current!.original);
      }, 0);
    }
  }, [reduxLinks]);

  return (
    <ScreenView backgroundColor={globalStyleColors.colorAccentMidDark} scrollView>
      <View style={{ flex: 1, justifyContent: "center" }}>
        <Spacer vertical={2} />
        <THeading2>{strings.title}</THeading2>
        <Spacer vertical={1} />
        {strings.explanation.map(s => {
          return (
            <>
              <TBody>{s}</TBody>
              <Spacer vertical={1} />
            </>
          );
        })}
        {!!links.current?.app && (
          <>
            <TBody fontWeight="medium">
              {strings.linkPre}
              <a href={links.current.app}>{strings.linkText}</a>
              {strings.linkPost}
            </TBody>
            <Spacer vertical={3} />
            <TBody>
              {strings.stillNeedHelp}
              <a href="mailto:help@deglaze.app&subject=Help signing in">
                <TBody>help@deglaze.app</TBody>
              </a>
            </TBody>
          </>
        )}

        <ContainerEnd vertical>
          <ScreenFooter showLogo />
        </ContainerEnd>
      </View>
    </ScreenView>
  );
});
