import { useQueryStringParam } from "../lib/ParamUtils";
import { useCallback, useEffect, useState } from "react";
import { AdminScreenView } from "../components/AdminScreenView";
import { useDispatch } from "../lib/AdminRedux";
import { getParsedPhrase } from "../lib/data-manager/DataManagerThunks";
import { emptyToUndefined } from "@eatbetter/common-shared";
import { View } from "react-native";
import {
  ButtonRectangle,
  CurrentEnvironment,
  displayUnexpectedErrorAndLog,
  Spacer,
  TBody,
  TextInput,
} from "@eatbetter/ui-shared";
import { useNavigate } from "react-router-dom";
import ReactJson from "react-json-view";
import { PhraseItemType } from "@eatbetter/items-data";

const phraseParam = "phrase";
const itemTypeParam = "type";
const basePath = "/truth/debug-phrase";
export const ParsedItemDebugScreenNav = {
  getPath: (phrase?: string, type?: PhraseItemType) => {
    if (phrase) {
      return `${basePath}?${phraseParam}=${encodeURIComponent(phrase)}&${itemTypeParam}=${type}`;
    }
    return basePath;
  },
};

export const ParsedItemDebugScreen = () => {
  const dispatch = useDispatch();
  const [local, setLocal] = useState<any>();
  const [remote, setRemote] = useState<any>();
  const phrase = useQueryStringParam(phraseParam).optional() ?? "";
  const itemType = (useQueryStringParam(itemTypeParam).optional() ?? "ingredient") as PhraseItemType;
  const [phraseInput, setPhraseInput] = useState(phrase);
  const [waiting, setWaiting] = useState(false);
  const nav = useNavigate();

  useEffect(() => {
    (async () => {
      const p = emptyToUndefined(phrase);
      if (p) {
        const i = await dispatch(getParsedPhrase(phrase, itemType, setWaiting));
        setLocal(i.local);
        setRemote(i.remote);
      } else {
        setLocal(undefined);
      }
    })().catch(err => displayUnexpectedErrorAndLog("Error in ParsedItemDebugScreen useEffect", err));
  }, [phrase, setLocal]);

  const submit = () => {
    nav(ParsedItemDebugScreenNav.getPath(phraseInput));
  };

  const collapseFunction = useCallback((t: string) => {
    return t === "tags" || t.toLowerCase().endsWith("tokens");
  }, []);

  return (
    <AdminScreenView>
      <View style={{ flexDirection: "row" }}>
        <View style={{ flex: 1 }}>
          <TextInput
            value={phraseInput}
            onChangeText={setPhraseInput}
            showClearButton
            editable={!waiting}
            onSubmit={submit}
            placeholderText="Phrase"
          />
        </View>

        <Spacer horizontal={1} />
        <ButtonRectangle onPress={submit} type={"submit"} title={"Submit"} waiting={waiting} disabled={waiting} />
      </View>
      <Spacer vertical={1} />
      <View style={{ flexDirection: "row", justifyContent: "space-around" }}>
        <View>
          <TBody>Local</TBody>
          {local && <JsonViewer src={local} collapse={collapseFunction} />}
        </View>
        <View>
          <TBody>{CurrentEnvironment.configEnvironment()}</TBody>
          {remote && <JsonViewer src={remote} collapse={collapseFunction} />}
        </View>
      </View>
    </AdminScreenView>
  );
};

const JsonViewer = (props: { src: any; collapse?: string[] | ((key: string) => boolean) }) => {
  return (
    <ReactJson
      src={props.src}
      displayDataTypes={false}
      displayObjectSize={false}
      quotesOnKeys={false}
      enableClipboard
      collapsed={3}
      shouldCollapse={field =>
        !!props.collapse &&
        !!field.name &&
        ((Array.isArray(props.collapse) && props.collapse.includes(field.name)) ||
          (!Array.isArray(props.collapse) && props.collapse(field.name)))
      }
    />
  );
};
