import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { useCallback, useMemo, useState } from "react";
import { ProfileEditFieldScreenComponent } from "../components/social/ProfileEditFieldScreenComponent";
import { useRegisteredUser } from "../lib/system/SystemSelectors";
import { updateUserProfile } from "../lib/system/SystemThunks";
import { useDispatch } from "../lib/redux/Redux";
import { useLinkInput } from "../lib/system/UserProfileHooks";
import { UrlString, bottomThrow } from "@eatbetter/common-shared";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";

const strings = {
  screenHeader: "Link",
  label: "Link",
  linkIsInvalid: "Link must be a valid URL.",
};

export const ProfileEditLinkScreen = withNonNavigableScreenContainer("ProfileEditLinkScreen", () => {
  const screen = useScreen();
  const dispatch = useDispatch();
  const persistedLink = useRegisteredUser()?.profileLinks[0]?.url ?? ("" as UrlString);
  const linkInput = useLinkInput(persistedLink);
  const isDirty = useMemo(() => persistedLink !== linkInput.link, [persistedLink, linkInput.link]);

  const [waiting, setWaiting] = useState(false);

  const onSave = useCallback(async () => {
    if (!isDirty) {
      return;
    }

    setWaiting(true);
    try {
      const result = await dispatch(updateUserProfile({ links: [{ url: linkInput.link }] }));
      setWaiting(false);

      switch (result) {
        case "success":
        case "nameInvalid":
        case "usernameInvalid":
        case "usernameUnavailable":
        case "bioInvalid":
          break;
        case "linksInvalid":
          linkInput.setLinkIsInvalid(true);
          return;
        default:
          bottomThrow(result);
      }

      screen.nav.goBack();
    } catch (err) {
      displayUnexpectedErrorAndLog("Unexpected error in ProfileEditLinkScreen.onSubmit", err);
      setWaiting(false);
      return;
    }
  }, [isDirty, dispatch, linkInput.link, linkInput.setLinkIsInvalid, setWaiting, screen.nav.goBack]);

  return (
    <ProfileEditFieldScreenComponent
      screenHeader={strings.screenHeader}
      label={strings.label}
      value={linkInput.link}
      onChangeText={linkInput.onChange}
      isDirty={isDirty}
      onSubmit={onSave}
      showClearButton
      validationState={isDirty ? (linkInput.linkIsInvalid ? "invalid" : "valid") : undefined}
      showIsValidIcon={false}
      errorMessage={linkInput.linkIsInvalid ? strings.linkIsInvalid : undefined}
      waiting={waiting}
      isPostMetadata={false}
      keyboardType="url"
    />
  );
});
