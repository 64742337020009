import React from "react";
import { View } from "react-native";
import { TextInput, TextInputHandle } from "../TextInput";
import { globalStyles } from "../GlobalStyles";

interface Props {
  placeholderText: string;
  value: string | undefined;
  onChangeText: (v: string) => void;
  onSubmit?: () => void;
}

export const RecipeEditTextInput = React.forwardRef<TextInputHandle, Props>((props, ref) => {
  return (
    <View
      style={{
        flex: 1,
        minHeight: 48,
        borderRadius: 12,
        paddingTop: 6,
        paddingHorizontal: 4,
        justifyContent: "center",
        backgroundColor: "white",
        ...globalStyles.shadowTextInput,
      }}
    >
      <TextInput
        ref={ref}
        value={props.value}
        onChangeText={props.onChangeText}
        multiline
        scrollEnabled={false}
        returnKeyType="done"
        onSubmit={props.onSubmit}
        placeholderText={props.placeholderText}
        noBorder
      />
    </View>
  );
});
