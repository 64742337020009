import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useBottomSheet } from "../screens/BottomSheetScreen";
import { useBottomTabBarDimensions } from "../navigation/TabBar";
import { Animated, View } from "react-native";
import { globalStyleConstants } from "./GlobalStyles";
import { BottomActionBar } from "./BottomActionBar";
import { Spacer } from "./Spacer";
import { BottomSheetScreenProps, navTree } from "../navigation/NavTree";
import { checkpointsCompleted } from "../lib/system/SystemSlice";
import { UserCheckpoint } from "@eatbetter/users-shared";
import { useCheckpointCompleted } from "../lib/system/SystemSelectors";
import { reportExplainerSheetDismissed, reportExplainerSheetDisplayed } from "../lib/analytics/AnalyticsEvents";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { useScreen } from "../navigation/ScreenContainer";
import { useDispatch } from "../lib/redux/Redux";

export function useExplainerSheet(
  args: ExplainerSheetProps & BottomSheetScreenProps & { checkpoint: UserCheckpoint; condition?: boolean }
) {
  const dispatch = useDispatch();
  const checkpointCompleted = useCheckpointCompleted(args.checkpoint);
  const { nav } = useScreen();

  const bottomSheetProps = useMemo<BottomSheetScreenProps>(() => {
    return {
      height: args.height,
      content: <ExplainerSheet closeButtonText={args.closeButtonText}>{args.content}</ExplainerSheet>,
      disableGestureDismissal: args.disableGestureDismissal,
    };
  }, [args.height, args.content, args.closeButtonText, args.disableGestureDismissal]);

  // Delay render
  const explainerRenderDelay = 1000;
  const [showExplainer, setShowExplainer] = useState(false);

  useEffect(() => {
    if (args.condition === false) {
      return;
    }

    if (checkpointCompleted) {
      return;
    }

    if (showExplainer) {
      nav.modal(navTree.get.screens.bottomSheet, bottomSheetProps);
      dispatch(checkpointsCompleted([args.checkpoint]));
      const event = reportExplainerSheetDisplayed({ checkpoint: args.checkpoint });
      dispatch(analyticsEvent(event));
      return;
    }

    setTimeout(() => {
      setShowExplainer(true);
    }, explainerRenderDelay);
  }, [args.condition, args.checkpoint, checkpointCompleted, showExplainer]);
}

interface ExplainerSheetProps {
  closeButtonText: string;
}

const ExplainerSheet = React.memo((props: PropsWithChildren<ExplainerSheetProps>) => {
  const dispatch = useDispatch();
  const bottomSheet = useBottomSheet();
  const { bottomNotchHeight } = useBottomTabBarDimensions();

  const fadeAnim = useRef(new Animated.Value(0)).current;

  const onPressClose = useCallback(() => {
    // Fade content out so that sheet exit looks cleaner
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 0,
      useNativeDriver: true,
    }).start();

    const event = reportExplainerSheetDismissed();
    dispatch(analyticsEvent(event));

    bottomSheet?.closeSheetAndGoBack();
  }, [dispatch, bottomSheet?.closeSheetAndGoBack, fadeAnim]);

  useEffect(() => {
    // Fade content in so that text layout isn't visible to the user. Wait until the nav modal is mounted to avoid jank / interference.
    setTimeout(() => {
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 200,
        useNativeDriver: true,
      }).start();
    }, 300);
  }, []);

  return (
    <Animated.View style={{ flex: 1, opacity: fadeAnim }}>
      <View style={{ flex: 1 }}>{props.children}</View>
      <BottomActionBar
        disableSnapToBottom
        containerBackgroundColor="transparent"
        primaryAction={{ actionText: props.closeButtonText, singlePress: true, onPressAction: onPressClose }}
      />
      <Spacer vertical={bottomNotchHeight ?? globalStyleConstants.defaultPadding} unit="pixels" />
    </Animated.View>
  );
});
