import { useEffect, useState } from "react";
import { ScreenView } from "../components/ScreenView";
import { withScreenContainer } from "../navigation/ScreenContainer";
import { CopyKVPair } from "./DebugScreen";
import { TBody } from "../components/Typography";
import { SettingsItem } from "../components/SettingsItem";
import { useDispatch } from "../lib/redux/Redux";
import { getPushPermissionAndUpdateState, requestPushPermissionOrNavToSettings } from "../lib/PushNotificationThunks";
import {
  TappedNotificationSimulationType,
  createLocalDebugNotification,
  createLocalDebugOnboardingNotification,
} from "../lib/debug/DebugThunks";
import { displayExpectedError } from "../lib/Errors";
import { onboardingNotificationPrompts } from "@eatbetter/users-shared";

export const DebugNotificationsScreen = withScreenContainer("DebugNotificationsScreen", () => {
  const dispatch = useDispatch();
  const [pushPermission, setPushPermission] = useState<object>({});

  const checkPushPermission = () => {
    dispatch(getPushPermissionAndUpdateState())
      .then(r => setPushPermission(r))
      .catch(err => alert(`Error: ${err}`));
  };

  useEffect(() => {
    checkPushPermission();
  }, []);

  const simulateNotification = (t: TappedNotificationSimulationType) => {
    const result = dispatch(createLocalDebugNotification(t));
    if (!result) {
      displayExpectedError(
        "No usable data found to create a payload. Create a post, share a recipe, etc. and try again"
      );
    }
  };

  return (
    <ScreenView header={{ type: "default", title: "Notifications" }}>
      <SettingsItem title="Check push permissions" onPress={checkPushPermission} />
      <SettingsItem
        title="Request push permissions"
        onPress={() => dispatch(requestPushPermissionOrNavToSettings()).then(r => alert(JSON.stringify(r)))}
      />
      <CopyKVPair name="Push permission" value={JSON.stringify(pushPermission)} />
      <TBody>
        Push notifications will create a local notification in 10 seconds. Get the app in the desired state and then
        switch away from the app within 10 seconds for the notification to appear.
      </TBody>
      {onboardingNotificationPrompts.map(p => {
        return (
          <SettingsItem
            key={p}
            title={`Push Notification Nav Test Onboarding ${p}`}
            onPress={() => dispatch(createLocalDebugOnboardingNotification(p))}
          />
        );
      })}
      <SettingsItem title="Push Notification Nav Test (Post)" onPress={() => simulateNotification("post")} />
      <SettingsItem title="Push Notification Nav Test (Comment)" onPress={() => simulateNotification("comment")} />
      <SettingsItem title="Push Notification Nav Test (Share)" onPress={() => simulateNotification("sharedRecipe")} />
      <SettingsItem title="Push Notification App Nav" onPress={() => simulateNotification("navigation")} />
    </ScreenView>
  );
});
