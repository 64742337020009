import { KnownPublisherId } from "@eatbetter/recipes-shared";
import { KnownPublisherScreenProps } from "../navigation/NavTree";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HeaderProps } from "../components/ScreenHeaders";
import { KnownEntityRecipeCollection } from "../components/social/KnownRecipeCollection";
import { useSearchIsLoading, useServerRecipeResultCount, useServerSearchResults } from "../lib/search/SearchSelectors";
import { useDispatch } from "../lib/redux/Redux";
import { fetchNextSearchResults, navToSearchQuery } from "../lib/search/SearchThunks";
import { useSearchSession } from "../lib/search/SearchHooks";
import { useOrFetchKnownPublisherProfile } from "../lib/social/SocialHooks";
import { KnownEntityCard } from "../components/social/KnownEntityCard";
import { KnownEntityPageViewedFrom, reportKnownEntityPageViewed } from "../lib/analytics/AnalyticsEvents";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { getEntityDisplayText } from "@eatbetter/posts-shared";

const strings = {
  screenTitle: "Recipe Collection",
  searchPlaceholder: (entityName?: string) =>
    `Search ${getEntityDisplayText({ type: "knownPublisher", name: entityName ?? "Publisher" }).headline}`,
};

export const KnownPublisherScreen = withScreenContainer<KnownPublisherScreenProps>(
  "KnownPublisherScreen",
  props => {
    const screen = useScreen();
    const dispatch = useDispatch();

    const [waitingLoadPublisher, setWaitingLoadPublisher] = useState(false);
    const knownPublisher = useOrFetchKnownPublisherProfile(props.id, setWaitingLoadPublisher);

    const browseSearchSessionId = useSearchSession({ publisherId: props.id, initialLoad: true });
    const { recipes } = useServerSearchResults(browseSearchSessionId);
    const recipeCount = useServerRecipeResultCount(browseSearchSessionId);
    const loadingResults = useSearchIsLoading(browseSearchSessionId);

    const alreadyReportedView = useRef(false);

    useEffect(() => {
      if (!alreadyReportedView.current && knownPublisher?.publisher && screen.nav.focused) {
        const event = reportKnownEntityPageViewed({ entity: knownPublisher.publisher, from: props.analyticsContext });
        dispatch(analyticsEvent(event));
        alreadyReportedView.current = true;
      }
    }, [knownPublisher?.publisher, screen.nav.focused]);

    const loadNextBatch = useCallback(async () => {
      // Only fetch if we're not at the end of the list to avoid multiple refreshes in zero / low result count scenarios
      if (recipeCount && recipes.length < recipeCount.count) {
        await dispatch(fetchNextSearchResults(browseSearchSessionId));
      }
    }, [dispatch, recipeCount, recipes.length]);

    const onPressSearchBox = useCallback(() => {
      dispatch(
        navToSearchQuery({ nav: screen.nav, from: "knownPublisherProfile", knownEntity: knownPublisher?.publisher })
      );
    }, [screen.nav, knownPublisher?.publisher]);

    const feedHeader = useCallback(() => {
      return (
        <>
          {!!knownPublisher?.publisher && (
            <KnownEntityCard
              id={props.id}
              profileInfo={knownPublisher.publisher}
              context="knownPublisher"
              externalLink={knownPublisher.publisher.url}
            />
          )}
        </>
      );
    }, [props.id, knownPublisher?.publisher]);

    const screenHeader = useMemo<HeaderProps>(() => {
      return {
        type: "default",
        title: strings.screenTitle,
        backgroundColor: "white",
      };
    }, []);

    return (
      <ScreenView
        header={screenHeader}
        scrollView={false}
        paddingHorizontal={false}
        paddingVertical="headerAndBottomTabBar"
        loading={waitingLoadPublisher}
      >
        <KnownEntityRecipeCollection
          searchPlaceholderText={strings.searchPlaceholder(knownPublisher?.publisher.name)}
          recipes={recipes}
          onEndReached={loadNextBatch}
          recipesLoading={loadingResults}
          recipeCount={recipeCount?.count}
          maxRecipeCountExceeded={recipeCount?.maxExceeded}
          onPressSearchBox={onPressSearchBox}
          renderListHeader={feedHeader}
          searchSessionId={browseSearchSessionId}
        />
      </ScreenView>
    );
  },
  {
    serializer: {
      analyticsContext: s => s,
      id: s => s,
    },
    parser: {
      analyticsContext: s => s as KnownEntityPageViewedFrom,
      id: s => s as KnownPublisherId,
    },
  }
);
