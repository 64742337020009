import {
  AdminGetFeedArgs,
  AdminSocialFeed,
  AdminUserSocialOverview,
  AdminUserSocialOverviewArgs,
  GetFollowersArgs,
  GetFollowingArgs,
  GetProfilePostsArgs,
  ModeratedSocialPost,
  ModerateSocialPostArgs,
  SocialPosts,
  EntityFollowers,
  UserFollowing,
} from "@eatbetter/posts-shared";
import { ThunkAction } from "../AdminRedux";
import { SetWaitingHandler } from "@eatbetter/ui-shared/dist";

export const getUserSocialOverview = (
  args: AdminUserSocialOverviewArgs,
  setWaiting?: SetWaitingHandler
): ThunkAction<AdminUserSocialOverview> => {
  return async (_dispatch, _getState, deps) => {
    const res = await deps.api.withThrow(setWaiting).getUserSocialOverview(args);
    return res.data;
  };
};

export const getSocialFeed = (args: AdminGetFeedArgs): ThunkAction<AdminSocialFeed> => {
  return async (_dispatch, _getState, deps) => {
    const res = await deps.api.withThrow().getSocialFeed(args);
    return res.data;
  };
};

export const moderateSocialPost = (
  args: ModerateSocialPostArgs,
  setWaiting?: SetWaitingHandler
): ThunkAction<ModeratedSocialPost> => {
  return async (_dispatch, _getState, deps) => {
    const res = await deps.api.withThrow(setWaiting).moderateSocialPost(args);
    return res.data;
  };
};

export const getUserProfilePosts = (args: GetProfilePostsArgs): ThunkAction<SocialPosts> => {
  return async (_dispatch, _getState, deps) => {
    const res = await deps.api.withThrow().getUserProfilePosts(args);
    return res.data;
  };
};

export const getFollowers = (args: GetFollowersArgs): ThunkAction<EntityFollowers> => {
  return async (_dispatch, _getState, deps) => {
    const res = await deps.api.withThrow().getFollowers(args);
    return res.data;
  };
};

export const getFollowing = (args: GetFollowingArgs): ThunkAction<UserFollowing> => {
  return async (_dispatch, _getState, deps) => {
    const res = await deps.api.withThrow().getFollowing(args);
    return res.data;
  };
};
