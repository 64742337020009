import { Platform, StyleSheet, View } from "react-native";
import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ScreenView, useScreenElementDimensions } from "../components/ScreenView";
import { globalStyleColors, globalStyleConstants } from "../components/GlobalStyles";
import { TextInputHandle } from "../components/TextInput";
import { InputAccessoryView } from "../components/InputAccessoryView";
import { BottomActionBar, bottomActionBarConstants } from "../components/BottomActionBar";
import { Spacer } from "../components/Spacer";
import { useDispatch } from "../lib/redux/Redux";
import { createUser, getAuthProviderUserInfo, signOutClicked } from "../lib/system/SystemThunks";
import { bottomThrow, Username } from "@eatbetter/common-shared";
import { useAnonymousUserCreated, usePreAuthDataName, useUserCreateData } from "../lib/system/SystemSelectors";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { log } from "../Log";
import { ScrollView } from "react-native-gesture-handler";
import { useKeyboardLayoutAnimation } from "../components/Keyboard";
import { useNameInput, useUsernameAvailability, useUsernameInput } from "../lib/system/UserProfileHooks";
import { FormValidationError } from "../components/FormValidationError";
import { FormInput } from "../components/FormInput";
import {
  reportAlreadyHaveAcountButtonTapped,
  reportUserCanceledCreatingAccount,
} from "../lib/analytics/AnalyticsEvents";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { Alert } from "../components/Alert/Alert";
import { CreateAccountScreenProps } from "../navigation/NavTree";
import React from "react";
import { Pressable } from "../components/Pressable";
import { TSecondary } from "../components/Typography";
import { navHome } from "../navigation/NavThunks";

const strings = {
  screenHeader: "Finish signing up",
  emailLabel: "Email",
  nameLabel: "Name",
  nameInvalid: "Name should be 3 to 30 characters and should not contain @.",
  usernameLabel: "Username",
  usernameInvalid:
    "Usernames should be 3 to 30 characters and can include a-z, 0-9, dashes, underscores, and periods (but can't end in a period).",
  usernameUnavailable: "A user with that username already exists.",
  submit: "Continue",
  cancelAlert: {
    title: "Cancel signing up?",
    message: "If you cancel, you will be signed out and an account will not be created for you.",
    primary: "Cancel signing up",
    secondary: "Continue",
  },
  alreadyHaveAccount: "Already have an account?",
  alreadyHaveAccountAlert: {
    title: "Already have an account?",
    primary: "Sign in",
    secondary: "Continue creating account",
  },
};

export const CreateAccountScreen = withNonNavigableScreenContainer(
  "CreateAccountScreen",
  (props: CreateAccountScreenProps) => {
    const screen = useScreen();
    const dispatch = useDispatch();
    const { headerHeight, bottomNotchHeight } = useScreenElementDimensions();
    const keyboardHeight = useKeyboardLayoutAnimation();

    const createData = useUserCreateData();

    // we saw a bug where the email field was occassionally blank. I'm not sure how, but
    // we now just bring in the email address from the auth provider if we don't have it
    // in the creation data, and if we don't have that either (which should never happen
    // unless the auth state is horked), we just don't show the email field. We use the
    // email address from the server jwt anyway.
    const [email, setEmail] = useState(createData?.email);
    useEffect(() => {
      dispatch(getAuthProviderUserInfo())
        .then(res => {
          if (res?.email?.verified) {
            setEmail(res.email.address);
          }
        })
        .catch(err => {
          log.errorCaught("Error dispatching getAuthProviderUserInfo in CreateAccountScreen", err);
        });
    }, [setEmail, dispatch]);

    const name = usePreAuthDataName();
    const nameFromAuthProvider = useRef(name).current;
    const anonymousUserCreated = useAnonymousUserCreated();

    const nameInputRef = useRef<TextInputHandle>(null);
    const usernameInputRef = useRef<TextInputHandle>(null);

    const nameInput = useNameInput(nameFromAuthProvider ?? "");
    const usernameInput = useUsernameInput(createData?.suggestedUsername ?? "");

    const [waiting, setWaiting] = useState(false);

    const onSubmitName = useCallback(() => usernameInputRef.current?.focus(), [usernameInputRef]);
    const onSubmitUsername = useCallback(() => nameInputRef.current?.focus(), [nameInputRef]);

    useEffect(() => {
      log.info(`CreateAccountScreen has redirect: ${props.redirectPath}`);
    }, [props.redirectPath]);

    const onSubmit = useCallback(async () => {
      try {
        // if successful, this thunk will set authStatus to signedIn (from signedInNoAccount)
        // which will trigger a navigation in ScreenContainer, which will then nav the user home
        const result = await dispatch(
          createUser(
            {
              name: nameInput.name,
              username: usernameInput.username as Username,
              nameFromAuthProvider,
            },
            setWaiting
          )
        );

        switch (result) {
          case "success":
            // removing survey in 3.3
            dispatch(navHome(screen.nav, "CreateAccountScreen", props.redirectPath));
            // screen.nav.goTo("reset", navTree.get.screens.newUserSurvey, { redirectPath: props.redirectPath });
            return;
          case "nameInvalid":
            nameInput.setNameIsInvalid(true);
            break;
          case "usernameInvalid":
            usernameInput.setUsernameIsInvalid(true);
            break;
          case "usernameUnavailable":
            // this case should be extremely unlikely and probably isn't worth handling
            log.warn("Received usernameUnavailable in onSubmit call in CreateAccountScreen");
            break;
          case "bioInvalid":
          case "linksInvalid":
            break;
          default:
            bottomThrow(result);
        }
      } catch (err) {
        displayUnexpectedErrorAndLog("Unexpected error in CreateAccountScreen.onSubmit", err);
      }
    }, [
      nameInput.name,
      usernameInput.username,
      setWaiting,
      nameInput.setNameIsInvalid,
      usernameInput.setUsernameIsInvalid,
      screen.nav,
      props.redirectPath,
    ]);

    const cancelAndSignOut = useCallback(() => {
      const event = reportUserCanceledCreatingAccount();
      dispatch(analyticsEvent(event));
      dispatch(signOutClicked(screen.nav)).catch(err => {
        log.errorCaught("Error from signOutClicked in CreateAccountScreen", err);
      });
    }, [dispatch, screen.nav]);

    const onCancel = useCallback(() => {
      Alert.alert(strings.cancelAlert.title, strings.cancelAlert.message, [
        {
          type: "discard",
          onPress: cancelAndSignOut,
          text: strings.cancelAlert.primary,
        },
        {
          type: "cancel",
          onPress: () => {},
          text: strings.cancelAlert.secondary,
        },
      ]);
    }, [cancelAndSignOut]);

    const onPressAlreadyHaveAccount = useCallback(() => {
      const event = reportAlreadyHaveAcountButtonTapped();
      dispatch(analyticsEvent(event));

      Alert.alert(strings.alreadyHaveAccountAlert.title, "", [
        {
          type: "save",
          onPress: () => {},
          text: strings.alreadyHaveAccountAlert.secondary,
        },
        {
          type: "cancel",
          onPress: cancelAndSignOut,
          text: strings.alreadyHaveAccountAlert.primary,
        },
      ]);
    }, [dispatch, cancelAndSignOut]);

    useEffect(() => {
      if (Platform.OS === "web") {
        // Safari + Chrome disable focus requests without user interaction, so it ends up in a
        // 'half' state where some animations trigger but there's no keyboard.
        return;
      }

      setTimeout(() => nameInputRef.current?.focus(), 650);
    }, []);

    const usernameAvailable = useUsernameAvailability(usernameInput.username);
    const usernameInvalid = usernameInput.usernameIsInvalid || usernameAvailable === "invalid";

    const paddingBottom = useMemo(() => {
      if (keyboardHeight > 0) {
        return keyboardHeight + bottomActionBarConstants.height;
      }
      return bottomNotchHeight + bottomActionBarConstants.height + 2 * globalStyleConstants.unitSize;
    }, [keyboardHeight, bottomNotchHeight]);

    return (
      <ScreenView
        scrollView={false}
        paddingHorizontal={false}
        paddingVertical={false}
        header={{
          type: "default",
          title: strings.screenHeader,
          right: {
            type: "cancel",
            onPress: onCancel,
            disabled: waiting,
          },
          backgroundColor: "transparent",
        }}
      >
        <ScrollView
          contentContainerStyle={{
            paddingTop: headerHeight + globalStyleConstants.unitSize,
            paddingBottom,
          }}
          keyboardShouldPersistTaps="handled"
        >
          <View style={styles.formWrap}>
            {!!email && (
              <FormInput
                value={email}
                label={strings.emailLabel}
                isReadOnly
                disableLabelAnimation
                validationState="valid"
                paddingHorizontal={globalStyleConstants.unitSize}
                showClearButton={false}
              />
            )}
            <FormInput
              ref={nameInputRef}
              value={nameInput.name}
              validationState={nameInput.nameIsInvalid ? "invalid" : nameInput.name.length > 2 ? "valid" : "none"}
              onChangeText={nameInput.onChange}
              onSubmitEditing={onSubmitName}
              label={strings.nameLabel}
              textContentType="name"
              isReadOnly={waiting}
              paddingHorizontal={globalStyleConstants.unitSize}
              showClearButton={false}
            />
            <FormInput
              ref={usernameInputRef}
              value={usernameInput.username}
              validationState={
                usernameInvalid || usernameAvailable === "unavailable"
                  ? "invalid"
                  : usernameAvailable === "available"
                  ? "valid"
                  : usernameAvailable === "pending"
                  ? "pending"
                  : "none"
              }
              onChangeText={usernameInput.onChange}
              onSubmitEditing={onSubmitUsername}
              label={strings.usernameLabel}
              autoCapitalize="none"
              isReadOnly={waiting}
              paddingHorizontal={globalStyleConstants.unitSize}
              showClearButton={false}
            />
          </View>
          <Spacer vertical={1.5} />
          {nameInput.nameIsInvalid && <ErrorMessage message={strings.nameInvalid} />}
          {usernameInvalid && <ErrorMessage message={strings.usernameInvalid} />}
          {usernameAvailable === "unavailable" && <ErrorMessage message={strings.usernameUnavailable} />}
          <Spacer vertical={1} />
          {/* This doesn't make sense in the context of a user creating an account after trying the app with an anonymous user*/}
          {!anonymousUserCreated && <AlreadyHaveAccountButton onPress={onPressAlreadyHaveAccount} />}
        </ScrollView>
        <InputAccessoryView backgroundColor="transparent">
          <BottomActionBar
            primaryAction={{
              onPressAction: onSubmit,
              actionText: strings.submit,
              waiting,
              disabled:
                !usernameInput.username ||
                usernameInvalid ||
                usernameAvailable !== "available" ||
                !nameInput.name ||
                nameInput.nameIsInvalid,
              singlePress: true,
            }}
            disableSnapToBottom
            containerBackgroundColor="transparent"
          />
        </InputAccessoryView>
      </ScreenView>
    );
  }
);

const ErrorMessage = (props: { message: string }) => {
  return (
    <View style={{ paddingHorizontal: 20 }}>
      <FormValidationError message={props.message} />
    </View>
  );
};

const AlreadyHaveAccountButton = React.memo((props: { onPress: () => void }) => {
  return (
    <Pressable style={styles.alreadyHaveAccount} onPress={props.onPress}>
      <TSecondary align="center" color={globalStyleColors.colorTextLink}>
        {strings.alreadyHaveAccount}
      </TSecondary>
    </Pressable>
  );
});

const styles = StyleSheet.create({
  formWrap: {
    marginHorizontal: 8,
  },
  alreadyHaveAccount: {
    width: "100%",
    alignItems: "center",
  },
});
