import { KnownAuthor, UnknownAuthor, UserAuthor } from "@eatbetter/recipes-shared";
import { View } from "react-native";
import { TBody } from "../Typography";
import { TextInput } from "../TextInput";
import { Spacer } from "../Spacer";
import { emptyToUndefined, isUrl, UrlString } from "@eatbetter/common-shared";
import { Photo } from "../Photo";
import { AppAddPhotoArgs } from "../../lib/Types";
import { PhotoEditControl } from "./PhotoEditControl";
import { pickedPhotoToRef } from "../PhotoPicker";
import { PhotoRefExternal } from "@eatbetter/photos-shared";

export interface PartialUnknownAuthor extends Partial<UnknownAuthor> {
  type: "unknownAuthor";
}

export function getAuthor(a: PartialUnknownAuthor | undefined): UnknownAuthor | undefined {
  if (a === undefined) {
    return undefined;
  }

  if (!emptyToUndefined(a.name)) {
    return undefined;
  }

  const ua: UnknownAuthor = {
    // splat this to prevent accidentally blowing away any properties if this control
    // doesn't get updated
    ...a,
    type: "unknownAuthor",
    name: a.name!.trim(),
    url: emptyToUndefined(a.url) as UrlString,
    photo: a.photo,
  };

  return ua;
}

interface Props {
  author?: UserAuthor | PartialUnknownAuthor | KnownAuthor;
  disabled?: boolean;
  onChange: (a: PartialUnknownAuthor | undefined) => void;
  pickedAuthorPhoto?: AppAddPhotoArgs;
  setPickedAuthorPhoto: (v: AppAddPhotoArgs | undefined) => void;
}

export const AuthorEditControl = (props: Props) => {
  const onChange = (field: keyof PartialUnknownAuthor, value: string) => {
    const updated: PartialUnknownAuthor = {
      ...(props.author ?? {}),
      type: "unknownAuthor",
      [field]: value,
    };

    if (emptyToUndefined(updated.name) && emptyToUndefined(updated.url)) {
      props.onChange(undefined);
    }

    props.onChange(updated);
  };

  const onChangePhotoExternalUrl = (value: string) => {
    const photo: PhotoRefExternal | undefined = isUrl(value)
      ? {
          type: "external",
          url: value as UrlString,
        }
      : undefined;

    const updated: PartialUnknownAuthor = {
      ...(props.author ?? {}),
      type: "unknownAuthor",
      photo,
    };

    props.setPickedAuthorPhoto(undefined);
    props.onChange(updated);
  };

  const onPhotoPicked = (args: AppAddPhotoArgs) => {
    onChangePhotoExternalUrl("");
    props.setPickedAuthorPhoto(args);
  };

  const onPhotoCleared = () => {
    props.setPickedAuthorPhoto(undefined);
    onChangePhotoExternalUrl("");
  };

  // user and book authors are read only
  if (props.author?.type === "userAuthor") {
    return (
      <View>
        <TBody fontWeight="heavy">Type</TBody>
        <TBody>User Author</TBody>
        <Spacer vertical={1} />
        <TBody fontWeight="heavy">Username</TBody>
        <TBody>{props.author.username}</TBody>
        <TBody fontWeight="heavy">User ID</TBody>
        <TBody>{props.author.userId}</TBody>
        {props.author?.photo && (
          <>
            <Spacer vertical={1} />
            <Photo source={props.author?.photo} style="thumbnailLarge" resizeMode="contain" sourceSize="w1290" />
          </>
        )}
      </View>
    );
  }

  return (
    <View>
      <TBody fontWeight="heavy">Type</TBody>
      <TBody>Unknown Author</TBody>
      <Spacer vertical={1} />
      <TBody fontWeight="heavy">Name</TBody>
      <TextInput
        editable={!props.disabled}
        value={props.author?.name}
        onChangeText={(s: string) => onChange("name", s)}
      />
      <Spacer vertical={1} />
      <TBody fontWeight="heavy">URL</TBody>
      {props.author?.type !== "knownAuthor" && (
        <TextInput
          editable={!props.disabled}
          value={props.author?.url}
          onChangeText={(s: string) => onChange("url", s)}
        />
      )}
      <Spacer vertical={2} />
      <PhotoEditControl
        admin
        disabled={props.disabled}
        align="left"
        sectionTitle="Photo"
        photo={{ photoRef: pickedPhotoToRef(props.pickedAuthorPhoto) ?? props.author?.photo, style: "avatarSmall" }}
        onPhotoPicked={onPhotoPicked}
        onChangePhotoExternalUrl={onChangePhotoExternalUrl}
        onClear={onPhotoCleared}
      />
    </View>
  );
};
