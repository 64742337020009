import React from "react";
import { View } from "react-native";
import { TBody, TSecondary } from "./Typography";
import { globalStyleColors } from "./GlobalStyles";

export const SectionHeading = React.memo(
  (props: { badge?: string; text: string; isActionable?: boolean; noPadding?: boolean }) => {
    const padding = props.noPadding ? {} : { paddingHorizontal: 20 };
    const badgeTextColor = globalStyleColors.rgba("colorAccentCool", "opaque");

    return (
      <>
        <View style={[{ flexShrink: 1 }, padding]}>
          {props.isActionable !== undefined ? (
            <TBody opacity={props.isActionable ? "opaque" : "medium"} strikethrough={props.isActionable === false}>
              {!!props.badge && (
                <>
                  <TBody color={globalStyleColors.rgba("colorAccentCool", "medium")}>{"("}</TBody>
                  <TBody
                    color={badgeTextColor}
                    highlightColor={globalStyleColors.rgba("colorAccentCool", "xxlight")}
                    fontWeight="medium"
                  >
                    {`${props.badge}`}
                  </TBody>
                  <TBody color={globalStyleColors.rgba("colorAccentCool", "medium")}>{")"}</TBody>
                  <TBody> </TBody>
                </>
              )}
              {props.text}
            </TBody>
          ) : (
            <TSecondary>{props.text.toUpperCase()}</TSecondary>
          )}
        </View>
      </>
    );
  }
);
