import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { EntitySelectedHandler, getFollowableEntities, SelectEntity, SelectEntityData } from "./SelectEntity";
import { SearchKnownEntitiesNoResults } from "./SearchEntities";
import { useScreenElementDimensions } from "./ScreenView";
import { useKeyboardLayoutAnimation } from "./Keyboard";
import { navToEntityScreen } from "../lib/social/SocialThunks";
import { getSocialEntityId } from "@eatbetter/posts-shared";
import { useScreen } from "../navigation/ScreenContainer";
import { SearchAndFilterBar } from "./SearchBox";
import { globalStyleConstants } from "./GlobalStyles";
import { useDispatch } from "../lib/redux/Redux";
import { useKnownEntities } from "../lib/users/UsersSelectors";
import { LayoutAnimation } from "react-native";
import {
  BrowseAllEntitiesViewedFrom,
  reportBrowseAllEntitiesViewed,
  reportKnownEntitiesSearched,
} from "../lib/analytics/AnalyticsEvents";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { loadKnownEntities } from "../lib/users/UsersThunks";
import { log } from "../Log";
import { debounce } from "lodash";

const strings = {
  searchPlaceholder: "Search recipe collections",
};

export const BrowseKnownEntitiesSearchBar = React.memo(
  (props: { searchPhrase: string; setSearchPhrase: (v: string) => void; paddingHorizontal?: "none" | "default" }) => {
    return (
      <SearchAndFilterBar
        searchPhrase={props.searchPhrase}
        onChangeSearchPhrase={props.setSearchPhrase}
        placeholderText={strings.searchPlaceholder}
        paddingHorizontal={props.paddingHorizontal}
      />
    );
  }
);

export const BrowseKnownEntities = React.memo(
  (props: {
    searchPhrase: string;
    analyticsContext: BrowseAllEntitiesViewedFrom;
    ListHeaderComponent?: React.ReactElement;
    disablePressEntity?: boolean;
  }) => {
    const dispatch = useDispatch();
    const screen = useScreen();
    const { bottomTabBarHeight } = useScreenElementDimensions();
    const keyboardHeight = useKeyboardLayoutAnimation();
    const knownEntityResults = useKnownEntities(props.searchPhrase);

    useEffect(() => {
      dispatch(loadKnownEntities()).catch(err =>
        log.errorCaught("Error calling loadKnownEntities on screen mount", err, {
          analyticsContext: props.analyticsContext,
        })
      );
    }, []);

    const alreadyReportedView = useRef(false);

    useEffect(() => {
      if (!alreadyReportedView.current && screen.nav.focused) {
        const event = reportBrowseAllEntitiesViewed({
          from: props.analyticsContext,
          initialSearchQuery: props.searchPhrase,
        });
        dispatch(analyticsEvent(event));
        alreadyReportedView.current = true;
      }
    }, [screen.nav.focused]);

    const debouncedSearchUsersAnalyticsEvent = useCallback(
      debounce((searchQuery: string) => {
        if (searchQuery) {
          const event = reportKnownEntitiesSearched({ searchQuery });
          dispatch(analyticsEvent(event));
        }
      }, 2000),
      [dispatch]
    );

    useEffect(() => {
      if (screen.nav.focused) {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      }
      debouncedSearchUsersAnalyticsEvent(props.searchPhrase);
    }, [props.searchPhrase]);

    const onSelectEntity = useCallback<EntitySelectedHandler>(
      item => {
        if (props.disablePressEntity === true) {
          return;
        }
        dispatch(navToEntityScreen(getSocialEntityId(item.entity), screen.nav, "browseAllEntities"));
      },
      [dispatch, screen.nav]
    );

    const entityData = useMemo<SelectEntityData[]>(() => {
      return [
        {
          entities: getFollowableEntities(knownEntityResults.entities, "entitySearch"),
        },
      ];
    }, [knownEntityResults.entities]);

    const renderNoResults = useCallback(() => {
      return <SearchKnownEntitiesNoResults />;
    }, []);

    return (
      <>
        <SelectEntity
          type="flexed"
          entityData={entityData}
          queryString={props.searchPhrase}
          waitingForResults={knownEntityResults.loading}
          onSelectEntity={onSelectEntity}
          paddingBottom={(keyboardHeight > 0 ? keyboardHeight : bottomTabBarHeight) + 4 * globalStyleConstants.unitSize}
          paddingTop={0}
          renderNoResults={renderNoResults}
          hideKnownEntityPrefix
          ListHeaderComponent={props.ListHeaderComponent}
        />
      </>
    );
  }
);
