import { AppAddPhotoArgs, SetWaitingHandler } from "../Types";
import { ThunkAction } from "../redux/Redux";
import { DeletePhotoArgs, PhotoRefInternal } from "@eatbetter/photos-shared";
import { uploadPhoto, uploadPhotoInBackground } from "./UploadPhoto";

export const addPhotoInBackground = (
  args: AppAddPhotoArgs,
  onProgress?: (percentage: number) => void
): ThunkAction<PhotoRefInternal> => {
  return async (_dispatch, _state, deps) => {
    const uploadInfo = await deps.api.withThrow().getUploadPhotoRequestInfo({
      dimensions: args.dimensions,
      mediaType: args.mediaType,
    });

    await uploadPhotoInBackground(uploadInfo.data, args.uri, onProgress);

    const photoRef: PhotoRefInternal = {
      type: "internal",
      id: uploadInfo.data.photoId,
    };

    return photoRef;
  };
};

export const addPhoto = (args: AppAddPhotoArgs, setWaiting?: SetWaitingHandler): ThunkAction<PhotoRefInternal> => {
  return async (_dispatch, _state, deps) => {
    // NOTE - THIS IS DUPLICATED IN AdminPhotoThunks.ts
    const uploadInfo = await deps.api.withThrow(setWaiting).getUploadPhotoRequestInfo({
      dimensions: args.dimensions,
      mediaType: args.mediaType,
    });

    await uploadPhoto(uploadInfo.data, args.uri);

    const photoRef: PhotoRefInternal = {
      type: "internal",
      id: uploadInfo.data.photoId,
    };

    return photoRef;
  };
};

export const deletePhoto = (args: DeletePhotoArgs, setWaiting?: SetWaitingHandler): ThunkAction<void> => {
  return async (_dispatch, _state, deps) => {
    await deps.api.withThrow(setWaiting).deletePhoto(args);
  };
};
