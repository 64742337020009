import { TBody } from "@eatbetter/ui-shared";
import { View } from "react-native";

export const TextInputLabel = (props: { text: string }) => {
  return (
    <View style={{ marginBottom: 4 }}>
      <TBody>{props.text}</TBody>
    </View>
  );
};
