import React from "react";
import { View, Image, StyleSheet } from "react-native";
import { Video, VideoSource } from "./Video";

type PhoneType = "iPhone15";

interface PhoneConfig {
  bezelImageSource: any;
  bezelAspectRatio: number;
  videoAspectRatio: number;
  videoScale: number;
}

const config = {
  iPhone15: {
    bezelImageSource: require("../assets/images/iphone15_bezel.png"),
    bezelAspectRatio: 1339 / 2716,
    videoAspectRatio: 1179 / 2556,
    videoScale: 0.89,
  },
} satisfies Record<PhoneType, PhoneConfig>;

interface VideoWithPhoneBezelProps {
  videoAnalyticsId: string | undefined;
  videoSource: VideoSource;
  videoBorderRadius?: number;
  tapToExpand?: boolean;
}

export const VideoWithPhoneBezel = React.memo((props: VideoWithPhoneBezelProps) => {
  return (
    <View style={styles.wrap}>
      <View style={styles.video}>
        <Video videoSource={props.videoSource} videoAnalyticsId={props.videoAnalyticsId} autoPlay isLooping />
      </View>
      <View style={styles.bezelWrap} pointerEvents={props.tapToExpand === false ? undefined : "none"}>
        <Image source={config.iPhone15.bezelImageSource} style={styles.bezel} resizeMode="contain" />
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  wrap: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  video: {
    transform: [{ scale: config.iPhone15.videoScale }],
    aspectRatio: config.iPhone15.videoAspectRatio,
    borderRadius: 20,
    overflow: "hidden",
  },
  bezelWrap: {
    ...StyleSheet.absoluteFillObject,
    alignItems: "center",
  },
  bezel: {
    width: "100%",
    height: "100%",
    aspectRatio: config.iPhone15.bezelAspectRatio,
  },
});
