import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import React, { useCallback, useMemo, useState } from "react";
import { ScreenView } from "../components/ScreenView";
import { StyleSheet, View } from "react-native";
import { TSecondary } from "../components/Typography";
import { useRegisteredUser } from "../lib/system/SystemSelectors";
import { globalStyleColors } from "../components/GlobalStyles";
import { PhotoPicker, usePhotoPicker } from "../components/PhotoPicker";
import { PhotoRef } from "@eatbetter/photos-shared";
import { AppAddPhotoArgs } from "../lib/Types";
import { Separator } from "../components/Separator";
import { Spacer } from "../components/Spacer";
import { Pressable } from "../components/Pressable";
import { navTree } from "../navigation/NavTree";
import { useProfileEditGoBackHandler } from "../components/social/ProfileEditFieldScreenComponent";
import { useDispatch } from "../lib/redux/Redux";
import { updateUserProfile } from "../lib/system/SystemThunks";
import { addPhoto } from "../lib/photos/PhotoThunks";
import { bottomThrow } from "@eatbetter/common-shared";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { SubmitAndCancelButtons } from "../components/Buttons";
import { HeaderProps } from "../components/ScreenHeaders";

const config = {
  fieldSpacing: 16,
};

const strings = {
  screenHeader: "Edit profile",
  name: "Name",
  namePlaceholder: "Enter name",
  username: "Username",
  email: "Email",
  emailPlaceholder: "Enter email",
  bio: "Bio",
  bioPlaceholder: "Enter bio",
  link: "Link",
  linkPlaceholder: "Add a link",
  addPhoto: "Add photo",
  editPhoto: "Change photo",
  updatePhotoInfo: "Your updated photo will be used for new posts, but old posts will remain unchanged.",
};

export const ProfileEditScreen = withNonNavigableScreenContainer("ProfileEditScreen", () => {
  const screen = useScreen();
  const authedUser = useRegisteredUser();
  const dispatch = useDispatch();
  const photoPicker = usePhotoPicker(authedUser?.photo);

  const [waiting, setWaiting] = useState(false);

  const onPressBack = useProfileEditGoBackHandler(!!photoPicker.pickedPhoto);

  const onPressEditName = useCallback(() => {
    screen.nav.goTo("push", navTree.get.screens.profileEditName);
  }, [screen.nav.goTo]);

  const onPressEditUsername = useCallback(() => {
    screen.nav.goTo("push", navTree.get.screens.profileEditUsername);
  }, [screen.nav.goTo]);

  const onPressEditEmail = useCallback(() => {
    screen.nav.goTo("push", navTree.get.screens.profileEditEmail);
  }, [screen.nav.goTo]);

  const onPressEditBio = useCallback(() => {
    screen.nav.goTo("push", navTree.get.screens.profileEditBio);
  }, [screen.nav.goTo]);

  const onPressEditLink = useCallback(() => {
    screen.nav.goTo("push", navTree.get.screens.profileEditLink);
  }, [screen.nav.goTo]);

  const onSubmit = useCallback(async () => {
    if (photoPicker.pickedPhoto) {
      setWaiting(true);
      try {
        const newPhoto = await dispatch(addPhoto(photoPicker.pickedPhoto));
        const result = await dispatch(updateUserProfile({ photo: newPhoto }));

        switch (result) {
          case "success":
            break;
          case "nameInvalid":
          case "usernameInvalid":
          case "usernameUnavailable":
          case "bioInvalid":
          case "linksInvalid":
            throw new Error("Unexpected result from updateUserProfile for photo");
          default:
            bottomThrow(result);
        }
      } catch (err) {
        displayUnexpectedErrorAndLog("Unexpected error updating photo in ProfileEditScreen.onSubmit", err);
        setWaiting(false);
        return;
      }
    }

    setWaiting(false);
    photoPicker.clearPickedPhoto();
  }, [photoPicker.pickedPhoto, setWaiting, dispatch]);

  const header = useMemo<HeaderProps>(() => {
    return {
      type: "custom",
      title: strings.screenHeader,
      right: { type: "done", onPress: onPressBack, disabled: waiting },
      onPressBack,
      disableBack: waiting,
      backgroundColor: "white",
    };
  }, [waiting, onPressBack]);

  return (
    <ScreenView header={header}>
      <PhotoEdit
        photo={photoPicker.photoRef}
        waiting={waiting}
        onPhotoPicked={photoPicker.onPhotoPicked}
        showPickerButton={!photoPicker.pickedPhoto}
      />
      {!!photoPicker.pickedPhoto && (
        <View>
          <Spacer vertical={0.5} />
          <View style={{ alignSelf: "center", width: 200 }}>
            <SubmitAndCancelButtons buttonSize="small" onCancel={photoPicker.clearPickedPhoto} onSubmit={onSubmit} />
          </View>
          <Spacer vertical={1.5} />
          <TSecondary opacity="medium" align="center">
            {strings.updatePhotoInfo}
          </TSecondary>
        </View>
      )}
      <Spacer vertical={1.5} />
      <Separator orientation="row" />
      <TextField
        label={strings.name}
        value={authedUser?.name}
        placeholder={strings.namePlaceholder}
        onPress={onPressEditName}
        disabled={waiting}
      />
      <TextField
        label={strings.username}
        value={authedUser?.username}
        disableFontScaleUp
        onPress={onPressEditUsername}
        disabled={waiting}
      />
      <TextField
        label={strings.email}
        value={authedUser?.email.address}
        placeholder={strings.emailPlaceholder}
        onPress={onPressEditEmail}
        disabled={waiting}
      />
      <TextField
        label={strings.bio}
        value={authedUser?.profileBio}
        placeholder={strings.bioPlaceholder}
        onPress={onPressEditBio}
        disabled={waiting}
      />
      <TextField
        label={strings.link}
        value={authedUser?.profileLinks[0]?.url}
        placeholder={strings.linkPlaceholder}
        onPress={onPressEditLink}
        disabled={waiting}
      />
    </ScreenView>
  );
});

const PhotoEdit = React.memo(
  (props: {
    photo?: PhotoRef;
    waiting?: boolean;
    onPhotoPicked: (args: AppAddPhotoArgs) => void;
    showPickerButton: boolean;
  }) => {
    return (
      <PhotoPicker
        photo={{ photoRef: props.photo, style: "avatarXlarge" }}
        onPhotoPicked={props.onPhotoPicked}
        addButtonText={strings.addPhoto}
        editButtonText={strings.editPhoto}
        waiting={props.waiting}
        hideButton={!props.showPickerButton}
      />
    );
  }
);

const TextField = React.memo(
  (props: {
    label: string;
    value?: string;
    placeholder?: string;
    disableFontScaleUp?: boolean;
    disabled?: boolean;
    onPress: () => void;
  }) => {
    return (
      <Pressable disabled={props.disabled} style={styles.textField} onPress={props.onPress}>
        <Label value={props.label} disableFontScaleUp={props.disableFontScaleUp} />
        <TextValue value={props.value} placeholder={props.placeholder} />
      </Pressable>
    );
  }
);

const Label = React.memo((props: { value: string; disableFontScaleUp?: boolean }) => {
  return (
    <View style={styles.label}>
      <TSecondary enableFontScaling={props.disableFontScaleUp ? "downOnly" : undefined}>{props.value}</TSecondary>
    </View>
  );
});

const TextValue = React.memo((props: { value?: string; placeholder?: string }) => {
  return (
    <View style={styles.textValue}>
      {!!props.value && <TSecondary>{props.value}</TSecondary>}
      {!props.value && !!props.placeholder && <TSecondary opacity="light">{props.placeholder}</TSecondary>}
    </View>
  );
});

const styles = StyleSheet.create({
  textField: {
    flexDirection: "row",
    alignItems: "center",
  },
  label: {
    width: 96,
    marginTop: config.fieldSpacing,
    paddingBottom: config.fieldSpacing,
  },
  textValue: {
    flex: 1,
    borderColor: globalStyleColors.rgba("black", "xlight"),
    borderBottomWidth: 1,
    paddingBottom: config.fieldSpacing,
    marginTop: config.fieldSpacing,
  },
});
