import {
  bottomLog,
  defaultTimeProvider,
  EpochMs,
  safeJsonStringify,
  switchReturn,
  UrlString,
  UserId,
} from "@eatbetter/common-shared";
import {
  AddCommentArgs,
  CreateTextPostClientArgs,
  DeleteCommentArgs,
  GetFollowingArgs,
  SocialPostId,
  SocialTestHooksArgs,
  EntityFollowers,
  UserFollowing,
  GetFollowersArgs,
  FollowUnfollowEntityArgs,
  SocialEntityId,
  isUserId,
  isUserSocialPost,
} from "@eatbetter/posts-shared";
import { log } from "../../Log";
import { SyncThunkAction, ThunkAction } from "../redux/Redux";
import { isLoading } from "../redux/ServerData";
import { SetWaitingHandler } from "../Types";
import {
  likeUpdateRequestErrored,
  postDeleted,
  newPostsErrored,
  newPostsReceived,
  newPostsRequested,
  olderPostsRequested,
  olderPostsReceived,
  olderPostsErrored,
  singlePostReceived,
  likeUpdateRequestStarted,
  likeUpdateRequestSucceeded,
  SocialFeedType,
  selectSocialFeed,
  profileFeed,
  profileInfoReceived,
  likeChanged,
  followingInfoRequested,
  followingInfoReceived,
  followingInfoErrored,
  followingUpdated,
  followingFeed,
  followSuggestionDismissed,
  exploreFeed,
  clearFollowingInfo,
  knownAuthorReceived,
  knownPublisherReceived,
} from "./SocialSlice";
import { singleRecipeReceived } from "../recipes/RecipesSlice";
import {
  AnalyticsFollowUnfollowContext,
  KnownEntityPageViewedFrom,
  ProfileLinkTappedContext,
  recommendedFollowsReceived,
  reportFollowerCountsReceived,
  reportFollowUnfollow,
  reportPostComment,
  reportPostCreated,
  reportPostDeleted,
  reportPostLiked,
  reportProfileLinkTapped,
  reportRecipeAdded,
} from "../analytics/AnalyticsEvents";
import { analyticsEvent } from "../analytics/AnalyticsThunks";
import { displayUnexpectedErrorAndLog } from "../Errors";
import { NavApi } from "../../navigation/ScreenContainer";
import { checkAnonymousUserAndLogError, navToAnonymousSigninIfAnon } from "../util/AnonymousSignIn";
import { selectRecommendedFollows } from "./SocialSelectors";
import { selectIsAnonymousUser, selectUserId } from "../system/SystemSelectors";
import {
  isKnownAuthorId,
  isKnownPublisherId,
  KnownAuthorId,
  KnownPublisherId,
  PartialRecipeId,
} from "@eatbetter/recipes-shared";
import { navTree } from "../../navigation/NavTree";
import { openWebpage } from "../util/WebUtil";
import { maybePromptForReview } from "../system/SystemThunks";

export const navToEntityScreen = (
  id: SocialEntityId,
  nav: NavApi,
  from: KnownEntityPageViewedFrom,
  action: "push" | "replace" = "push"
): SyncThunkAction<void> => {
  return (_dispatch, getState, _deps) => {
    if (isUserId(id)) {
      const authedUserId = selectUserId(getState());
      if (authedUserId === id) {
        nav.switchTab("profileTab", navTree.get.screens.profile);
      } else {
        nav.goTo(action, navTree.get.screens.otherUserProfile, { userId: id });
      }
    } else if (isKnownAuthorId(id)) {
      nav.goTo(action, navTree.get.screens.knownAuthor, { id, analyticsContext: from });
    } else if (isKnownPublisherId(id)) {
      nav.goTo(action, navTree.get.screens.knownPublisher, { id, analyticsContext: from });
    } else {
      bottomLog(id, "Unexpected social entity in onPressEntity");
    }
  };
};

export const followUnfollow = (
  args: FollowUnfollowEntityArgs,
  context: AnalyticsFollowUnfollowContext,
  setWaiting?: SetWaitingHandler
): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: followUnfollow");

    try {
      const r = await deps.api.withThrow(setWaiting).followUnfollow(args);
      dispatch(followingUpdated(r.data));

      const event = reportFollowUnfollow({ followedEntityId: args.entityId, action: args.action, context });
      dispatch(analyticsEvent(event));

      // update the feed in the background so we get the updated count
      dispatch(loadNewProfilePosts()).catch(err => {
        log.errorCaught("Error dispatching loadNewProfilePosts in followUnfollow", err);
      });

      dispatch(maybePromptForReview("Social Entity Followed"));
    } catch (err) {
      log.errorCaught("Unexpected error calling followUnfollow", err);
      throw err;
    }
  };
};

export const dismissFollowRecommendation = (entityId: SocialEntityId): SyncThunkAction<void> => {
  return (dispatch, _getState, deps) => {
    dispatch(followSuggestionDismissed(entityId));

    // we can fail silently here - we track it locally as well.
    deps.api
      .withThrow()
      .reportDismissedFollowRecommendation({ entityId })
      .catch(err => {
        log.errorCaught(`Error calling reportDismissedFollowRecommendation for ${entityId}`, err);
      });
  };
};

export const loadFollowingInfo = (): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: loadFollowingInfo");
    const state = getState();

    try {
      if (isLoading("followingInfo", state, s => s.social.followingInfo)) {
        log.info("loadFollowingInfo called, but status is already loading");
        return;
      }

      const startTime = deps.time.epochMs();
      dispatch(followingInfoRequested(startTime));
      const resp = await deps.api.withThrow().getFollowingIds();

      dispatch(followingInfoReceived({ startTime, data: resp.data }));

      const recCount = selectRecommendedFollows(getState()).length;
      const event = recommendedFollowsReceived(recCount);
      dispatch(analyticsEvent(event));
    } catch (err) {
      log.errorCaught("Unexpected error in loadFollowingInfo", err);
      dispatch(followingInfoErrored());
    }
  };
};

export const loadFollowers = (args: GetFollowersArgs, setWaiting?: SetWaitingHandler): ThunkAction<EntityFollowers> => {
  return async (_dispatch, _gs, deps) => {
    const resp = await deps.api.withThrow(setWaiting).getFollowers(args);
    return resp.data;
  };
};

export const loadFollowing = (args: GetFollowingArgs, setWaiting?: SetWaitingHandler): ThunkAction<UserFollowing> => {
  return async (_dispatch, _gs, deps) => {
    const resp = await deps.api.withThrow(setWaiting).getFollowing(args);
    return resp.data;
  };
};

export const loadKnownAuthorProfileInfo = (
  authorId: KnownAuthorId,
  setWaiting?: SetWaitingHandler
): ThunkAction<void> => {
  return async (dispatch, _gs, deps) => {
    const r = await deps.api.withThrow(setWaiting).getKnownAuthorProfile({ authorId });
    dispatch(knownAuthorReceived(r.data));
  };
};

export const loadKnownPublisherProfileInfo = (
  publisherId: KnownPublisherId,
  setWaiting?: SetWaitingHandler
): ThunkAction<void> => {
  return async (dispatch, _gs, deps) => {
    const r = await deps.api.withThrow(setWaiting).getKnownPublisherProfile({ publisherId });
    dispatch(knownPublisherReceived(r.data));
  };
};

export const loadNewHomeFeedPosts = (feedType: "followingFeed" | "exploreFeed"): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info(`Thunk: loadNewHomeFeedPosts ${feedType}`);
    const state = getState();

    const socialFeedType = switchReturn(feedType, {
      followingFeed: followingFeed,
      exploreFeed: exploreFeed,
    });

    try {
      const desc = `${feedType}.newPostsMeta`;
      if (isLoading(desc, state, s => selectSocialFeed(s.social, socialFeedType)!.newPostsMeta)) {
        log.info(`loadPosts called for ${desc}, but status is already loading`);
        return;
      }

      const startTime = deps.time.epochMs();
      dispatch(newPostsRequested({ startTime, feed: socialFeedType }));

      const resp = await deps.api.withThrow().getPosts({
        count: 10,
        feed: feedType === "followingFeed" ? "following" : "explore",
      });

      // if we have any new posts by the current user, we need to update the profile feed
      const state2 = getState();
      const haveNewByUser = resp.data.posts.some(p => {
        return (
          isUserSocialPost(p) &&
          p.user.userId === state2.system.authedUser.data?.userId &&
          !state2.social.profileFeed.postIds.includes(p.id)
        );
      });

      if (haveNewByUser) {
        dispatch(loadNewProfilePosts()).catch(err => log.errorCaught("Unexpected error in loadNewProfilePosts", err));
      }

      dispatch(newPostsReceived({ data: { startTime, data: resp.data }, feed: socialFeedType }));
    } catch (err) {
      log.errorCaught(`Unexpected error fetching new ${feedType} posts`, err);
      dispatch(newPostsErrored(socialFeedType));
    }
  };
};

export const loadOlderHomeFeedPosts = (feedType: "followingFeed" | "exploreFeed"): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info(`Thunk: loadOlderHomeFeedPosts ${feedType}`);
    const state = getState();

    const socialFeedType = switchReturn(feedType, {
      followingFeed: followingFeed,
      exploreFeed: exploreFeed,
    });

    try {
      const feed = selectSocialFeed(state.social, socialFeedType)!;
      const desc = `${feedType}.olderPostsMeta`;
      if (isLoading(desc, state, feed.olderPostsMeta)) {
        log.info(`loadOlderPosts called for ${feedType}, but status is already loading`);
        return;
      }

      if (!feed.next) {
        // nothing new to fetch
        log.info("No more posts to load");
        return;
      }

      const startTime = deps.time.epochMs();
      dispatch(olderPostsRequested({ startTime, feed: socialFeedType }));

      const resp = await deps.api.withThrow().getPosts({
        feed: feedType === "followingFeed" ? "following" : "explore",
        start: feed.next,
        count: 10,
      });
      dispatch(olderPostsReceived({ data: { startTime, data: resp.data }, feed: socialFeedType }));
    } catch (err) {
      log.errorCaught(`Unexpected error in loadOlderHomeFeedPosts for ${feedType}`, err);
      dispatch(olderPostsErrored(socialFeedType));
    }
  };
};

/**
 * If userId is omitted, gets posts for currently authenticated user
 */
export const loadNewProfilePosts = (userId?: UserId): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: loadNewProfilePosts");
    const state = getState();
    const feed: SocialFeedType = userId ? { type: "otherUserProfileFeed", userId } : profileFeed;

    if (feed === profileFeed && selectIsAnonymousUser(state)) {
      log.info("loadNewProfilePosts called for anonymous user. Not fetching.");
      return;
    }

    const socialFeed = selectSocialFeed(state.social, feed);
    if (!socialFeed) {
      log.warn("No social feed. Returning");
      return;
    }

    const uid = userId ?? state.system.authedUser.data?.userId;

    if (!uid) {
      log.error("No user ID in loadNewProfilePosts. Returning");
      return;
    }

    try {
      if (isLoading(`newPostsMeta for ${safeJsonStringify(feed)}`, state, socialFeed.newPostsMeta)) {
        log.info(`loadNewProfilePosts called for ${safeJsonStringify(feed)}, but status is already loading`);
        return;
      }

      const startTime = deps.time.epochMs();
      dispatch(newPostsRequested({ startTime, feed }));

      const resp = await deps.api.withThrow().getProfilePosts({
        userId: uid,
        count: 10,
      });

      if (resp.data.profileInfo) {
        dispatch(profileInfoReceived({ profileInfo: resp.data.profileInfo, userId }));
        const pi = resp.data.profileInfo;
        const event = reportFollowerCountsReceived({ following: pi.countFollowing, followers: pi.countFollowers });
        dispatch(analyticsEvent(event));
      }
      dispatch(newPostsReceived({ data: { startTime, data: resp.data }, feed }));
    } catch (err) {
      log.errorCaught(`Unexpected error in loadNewProfilePosts for ${safeJsonStringify(feed)}`, err);
      dispatch(newPostsErrored(feed));
    }
  };
};

/**
 * If userId is omitted, gets posts for currently authenticated user
 */
export const loadOlderProfilePosts = (userId?: UserId): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: loadOlderProfilePosts");
    const state = getState();
    const feed: SocialFeedType = userId ? { type: "otherUserProfileFeed", userId } : profileFeed;

    const socialFeed = selectSocialFeed(state.social, feed);
    if (!socialFeed) {
      log.warn("No social feed. Returning");
      return;
    }

    const uid = userId ?? state.system.authedUser.data?.userId;

    if (!uid) {
      log.error("No user ID in loadNewProfilePosts. Returning");
      return;
    }

    try {
      if (isLoading(`olderPostsMeta for ${safeJsonStringify(feed)}`, state, socialFeed.olderPostsMeta)) {
        log.info("loadOlderProfilePosts called, but status is already loading");
        return;
      }

      if (!socialFeed.next) {
        // nothing new to fetch
        log.info("No more posts");
        return;
      }

      const startTime = deps.time.epochMs();
      dispatch(olderPostsRequested({ startTime, feed }));

      const resp = await deps.api.withThrow().getProfilePosts({
        userId: uid,
        start: socialFeed.next,
        count: 10,
      });
      dispatch(olderPostsReceived({ data: { startTime, data: resp.data }, feed }));
    } catch (err) {
      log.errorCaught(`Unexpected error in loadOlderProfilePosts for ${safeJsonStringify(feed)}`, err);
      dispatch(olderPostsErrored(feed));
    }
  };
};

export const loadPost = (postId: SocialPostId): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: loadPost", { postId });
    try {
      const resp = await deps.api.withThrow().getPost({ postId });
      dispatch(singlePostReceived(resp.data));
    } catch (err) {
      log.errorCaught("Unexpected error in loadPost", err, { postId });
    }
  };
};

export const deletePost = (postId: SocialPostId, setWaiting?: SetWaitingHandler): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: deletePost", { postId });
    try {
      await deps.api.withThrow(setWaiting).deletePost({ postId });
      const post = getState().social.posts[postId];
      dispatch(postDeleted(postId));

      const event = reportPostDeleted({ post });
      dispatch(analyticsEvent(event));
    } catch (err) {
      log.errorCaught("Unexpected error in deletePost", err, { postId });
      throw err;
    }
  };
};

export const saveRecipeFromPost = (args: {
  postId: SocialPostId;
  partialRecipeId: PartialRecipeId;
  setWaiting?: SetWaitingHandler;
}): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: saveRecipeFromPost", { postId: args.postId, partialRecipeId: args.partialRecipeId });
    const resp = await deps.api
      .withThrow(args.setWaiting)
      .saveRecipeFromPost({ postId: args.postId, partialRecipeId: args.partialRecipeId });
    dispatch(singleRecipeReceived(resp.data.recipe));
    dispatch(singlePostReceived(resp.data.post));
    const event = reportRecipeAdded({ addedVia: "socialPost", recipe: resp.data.recipe });
    dispatch(analyticsEvent(event));
  };
};

export const likeUnlikePost = (postId: SocialPostId): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: likeUnlikePost", { postId });
    const state = getState();
    const userId = state.system.authedUser.data?.userId;

    if (!userId) {
      log.error("No user ID in likeUnlikePost thunk", { authedUser: state.system.authedUser });
      return;
    }

    const pendingAndQueued = state.social.pendingLikes[postId];

    if (!pendingAndQueued?.queued) {
      log.error(`likeUnlikePost called for post ${postId}, but no queued update`, { pendingAndQueued });
      return;
    }

    const start = defaultTimeProvider();
    dispatch(likeUpdateRequestStarted({ postId, start }));

    try {
      const updated = await deps.api.withThrow().likeUnlikePost(pendingAndQueued.queued.like);
      dispatch(likeUpdateRequestSucceeded({ updatedPost: updated.data }));

      if (pendingAndQueued.queued.like.action === "like") {
        const event = reportPostLiked({ post: updated.data });
        dispatch(analyticsEvent(event));
      }
    } catch (err) {
      log.errorCaught(`Error caught in likeUnlikePost thunk for post ID ${postId}`, err);
      dispatch(likeUpdateRequestErrored({ postId }));
    }
  };
};

export const createTextPost = (args: CreateTextPostClientArgs, setWaiting?: SetWaitingHandler): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: createTextPost");
    try {
      setWaiting?.(true);
      const resp = await deps.api.withThrow().createTextPost(args);

      // at the time of this implementation, the user would be on the home screen when creating
      // a text post, so we don't need to wait to load profile posts
      dispatch(loadNewProfilePosts()).catch(err =>
        log.errorCaught("Error calling loadNewProfilePosts in createTextPost", err)
      );

      const event = reportPostCreated({ post: resp.data });
      dispatch(analyticsEvent(event));
    } catch (err) {
      displayUnexpectedErrorAndLog("Error in createTextPost thunk", err, { args });
    } finally {
      setWaiting?.(false);
    }
  };
};

export const likeChangedClient = (
  args: {
    postId: SocialPostId;
    action: "like" | "unlike";
    time: EpochMs;
  },
  nav: NavApi
): SyncThunkAction<void> => {
  return (dispatch, getState, _deps) => {
    if (navToAnonymousSigninIfAnon(getState(), nav, "like a post")) {
      return;
    }

    dispatch(likeChanged(args));
  };
};

export const addCommentToPost = (args: AddCommentArgs, setWaiting?: SetWaitingHandler): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: addCommentToPost", { args });

    if (checkAnonymousUserAndLogError(getState(), "addCommentToPost")) {
      return;
    }

    const resp = await deps.api.withThrow(setWaiting).addCommentToPost(args);
    dispatch(singlePostReceived(resp.data));

    const event = reportPostComment({ post: resp.data, comment: args.text });
    dispatch(analyticsEvent(event));
  };
};

export const deleteCommentFromPost = (args: DeleteCommentArgs, setWaiting?: SetWaitingHandler): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: deteCommentFromPost", { args });
    const resp = await deps.api.withThrow(setWaiting).deleteCommentFromPost(args);
    dispatch(singlePostReceived(resp.data));
  };
};

export const socialTestHook = (args: SocialTestHooksArgs, setWaiting?: SetWaitingHandler): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    try {
      setWaiting?.(true);
      await deps.api.withThrow(setWaiting).socialTestHooks(args);
      switch (args.action) {
        case "clearFollowingAndFeed":
          await Promise.all([
            // clear following and the reactor will kick in to reload it
            dispatch(clearFollowingInfo()),
            dispatch(loadNewHomeFeedPosts("followingFeed")),
            dispatch(loadNewProfilePosts()),
          ]);
          break;
        case "clearFollowRecommendations":
        case "generateFollowRecommendations":
          // clear following and the reactor will kick in to reload it
          await dispatch(clearFollowingInfo());
          break;
      }
    } finally {
      setWaiting?.(false);
    }
  };
};

export const openProfileLink = (args: {
  url: UrlString;
  urlIndex: number;
  analyticsContext: ProfileLinkTappedContext;
  entityId?: KnownAuthorId | KnownPublisherId;
  otherUserId?: UserId;
}): SyncThunkAction<void> => {
  return (dispatch, _gs, _deps) => {
    openWebpage(args.url);
    dispatch(
      analyticsEvent(
        reportProfileLinkTapped({
          context: args.analyticsContext,
          link: args.url,
          index: args.urlIndex,
          userId: args.otherUserId,
          entityId: args.entityId,
        })
      )
    );
  };
};
