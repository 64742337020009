import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { SettingsItem } from "../components/SettingsItem";
import { useCallback } from "react";
import { navTree } from "../navigation/NavTree";
import { useSystemSetting } from "../lib/system/SystemSelectors";

const strings = {
  title: "How To",
  websites: "Adding recipes from websites",
  social: "Adding recipes from social apps",
  books: "Adding recipes from books",
};

export const HowToAddRecipesScreen = withScreenContainer("HowToAddRecipesScreen", () => {
  const screen = useScreen();
  const recipePhotoIngestionEnabled = !!useSystemSetting("recipePhotoIngestion");

  const onPressWebsites = useCallback(() => {
    screen.nav.goTo("push", navTree.get.screens.howToAddBrowserRecipe);
  }, [screen.nav.goTo]);

  const onPressSocial = useCallback(() => {
    screen.nav.goTo("push", navTree.get.screens.howToAddSocialRecipe);
  }, [screen.nav.goTo]);

  const onPressBooks = useCallback(() => {
    screen.nav.goTo("push", navTree.get.screens.howToAddBookRecipe);
  }, [screen.nav.goTo]);

  return (
    <ScreenView header={{ type: "default", style: "default", title: strings.title }}>
      <SettingsItem title={strings.websites} onPress={onPressWebsites} navAction />
      <SettingsItem title={strings.social} onPress={onPressSocial} navAction />
      {recipePhotoIngestionEnabled && <SettingsItem title={strings.books} onPress={onPressBooks} navAction />}
    </ScreenView>
  );
});
