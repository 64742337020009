import { ScreenView } from "../components/ScreenView";
import { BrowseKnownEntitiesScreenProps } from "../navigation/NavTree";
import { withScreenContainer } from "../navigation/ScreenContainer";
import { BrowseAllEntitiesViewedFrom } from "../lib/analytics/AnalyticsEvents";
import { BrowseKnownEntities, BrowseKnownEntitiesSearchBar } from "../components/BrowseKnownEntities";
import { useMemo, useState } from "react";
import { HeaderProps, useScreenHeaderDimensions } from "../components/ScreenHeaders";
import { View } from "react-native";
import { useKnownEntities } from "../lib/users/UsersSelectors";
import { useSearchAndFilterBarHeight } from "../components/SearchBox";

const strings = {
  screenTitle: "Recipe Collections",
};

export const BrowseKnownEntitiesScreen = withScreenContainer(
  "BrowseKnownEntitiesScreen",
  (props: BrowseKnownEntitiesScreenProps) => {
    const { headerHeight } = useScreenHeaderDimensions();
    const [searchPhrase, setSearchPhrase] = useState("");
    const loading = useKnownEntities(searchPhrase).loading;
    const searchBarHeight = useSearchAndFilterBarHeight();

    const screenHeader = useMemo<HeaderProps>(() => {
      return {
        type: "custom",
        title: strings.screenTitle,
        subHeaderComponent: {
          render: () => <BrowseKnownEntitiesSearchBar searchPhrase={searchPhrase} setSearchPhrase={setSearchPhrase} />,
          getHeight: () => searchBarHeight,
        },
        backgroundColor: "white",
      };
    }, [searchPhrase, setSearchPhrase, searchBarHeight]);

    return (
      <ScreenView
        loading={loading}
        header={screenHeader}
        scrollView={false}
        paddingHorizontal={false}
        paddingVertical={false}
        backgroundColor="white"
      >
        <View style={{ paddingTop: headerHeight + searchBarHeight }}>
          <BrowseKnownEntities searchPhrase={searchPhrase} analyticsContext={props.analyticsContext} />
        </View>
      </ScreenView>
    );
  },
  {
    serializer: {
      searchQuery: s => s,
      analyticsContext: s => s,
    },
    parser: {
      searchQuery: s => s,
      analyticsContext: s => s as BrowseAllEntitiesViewedFrom,
    },
  }
);
