import { isStructuredRecipeError, UserRecipeId } from "@eatbetter/recipes-shared";
import { useRecipeNotes } from "./RecipesSelectors";
import { editRecipeNote } from "./RecipesThunks";
import { log } from "../../Log";
import { RecipeEditFieldLocation } from "../../navigation/NavTree";
import { AutoSaveTextEdit, PersistTextHandler, useAutoSaveTextEdit } from "./UseAutoSaveTextEdit";

export const useRecipeNotesEdit = (recipeId: UserRecipeId, location: RecipeEditFieldLocation): AutoSaveTextEdit => {
  const persistedText = useRecipeNotes(recipeId) ?? "";
  const setPersistedText: PersistTextHandler = (text, dispatch, setPending, callSuccessful) => {
    setPending(true);
    dispatch(editRecipeNote(recipeId, text, location))
      .then(() => {
        callSuccessful(true);
      })
      .catch(err => {
        if (isStructuredRecipeError(err) && err.data.code === "recipes/recipeConflict") {
          log.errorCaught("Recipe conflict error persisting recipe note", err, {}, "warn");
        } else {
          log.errorCaught("Error while persisting recipe note", err);
        }
        callSuccessful(false);
      })
      .finally(() => {
        setPending(false);
      });
  };

  return useAutoSaveTextEdit(persistedText, setPersistedText);
};
