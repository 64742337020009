import { withScreenContainer } from "../navigation/ScreenContainer";
import { useCallback, useEffect, useState } from "react";
import { ScreenView, useScreenElementDimensions } from "../components/ScreenView";
import { FlatList, ListRenderItem, View } from "react-native";
import { log, SavedLogLine } from "../Log";
import { TextInput } from "../components/TextInput";
import { Pressable } from "../components/Pressable";
import { IconRepeat } from "../components/Icons";
import { TBody } from "../components/Typography";

export const DebugLogsScreen = withScreenContainer("DebugLogsScreen", () => {
  const [logs, setLogs] = useState<SavedLogLine[]>([]);
  const [search, setSearch] = useState("");
  const { headerHeight } = useScreenElementDimensions();

  const getLogs = useCallback(() => {
    const l = [...log.getSavedLogs()];
    setLogs(l.reverse());
  }, [setLogs]);

  useEffect(() => {
    getLogs();
  }, []);

  const renderItem: ListRenderItem<SavedLogLine> = ({ item, index }) => {
    const time = new Date(item.time).toLocaleTimeString();
    return (
      <View key={`${index}-${item.time}`} style={{ paddingTop: 10 }}>
        <TBody>
          {time} {item.level} {item.message}
        </TBody>
      </View>
    );
  };

  const toRender = logs.filter(l => l.message.toLowerCase().includes(search.toLowerCase()));

  return (
    <ScreenView scrollView={false} header={{ type: "default", style: "default", title: "Logs" }}>
      <View style={{ flex: 1, paddingTop: headerHeight }}>
        <View style={{ flexDirection: "row" }}>
          <View style={{ flex: 1 }}>
            <TextInput value={search} onChangeText={setSearch} showClearButton />
          </View>
          <Pressable onPress={getLogs}>
            <IconRepeat />
          </Pressable>
        </View>
        <View>
          <TBody>{toRender.length} lines</TBody>
        </View>
        <FlatList data={toRender} renderItem={renderItem} />
      </View>
    </ScreenView>
  );
});
