import { bottomThrow, DataAndType, EpochMs, TypedPrimitive, UserId, Username } from "@eatbetter/common-shared";
import { DeglazeUser, DeglazeUserWithProfileInfo } from "@eatbetter/users-shared";
import {
  RecipeInfo,
  RecipeId,
  AppUserRecipe,
  UserRecipeId,
  RecipeRating,
  PartialRecipeId,
  KnownAuthor,
  KnownPublisher,
  KnownAuthorId,
  KnownPublisherId,
  isKnownAuthorId,
  isKnownPublisherId,
  KnownProfileInfo,
} from "@eatbetter/recipes-shared";

export type SocialPostId = TypedPrimitive<string, "PostId">;

export interface CommentRecipeInfo {
  userRecipeId: UserRecipeId;
  sourceRecipeId?: RecipeId;
  recipeInfo: RecipeInfo;
}

export interface EntityFollowerCounts {
  entityId: SocialEntityId;
  following: number;
  followers: number;
  version: EpochMs;
}

/**
 * @deprecated v3
 */
export interface RecommendedUserFollow_DEPRECATED {
  user: DeglazeUser;
  score?: number;
}

export type RecommendedEntityFollow =
  | {
      type: "recommendedUser";
      entity: DeglazeUser;
      score?: number;
    }
  | {
      type: "recommendedAuthor";
      entity: KnownAuthor;
      score?: number;
    }
  | {
      type: "recommendedPublisher";
      entity: KnownPublisher;
      score?: number;
    };

/**
 * @deprecated v3
 */
export interface RecommendedFollowInfo_DEPRECATED {
  users: RecommendedUserFollow_DEPRECATED[];
  version: EpochMs;
}

export interface RecommendedFollowInfo {
  entities: RecommendedEntityFollow[];
  version: EpochMs;
}

export interface UserFollowingInfo {
  idsFollowed: SocialEntityId[];
  version: EpochMs;
}

/**
 * @deprecated v3
 */
export interface UserFollowingInfoAndRecommendations_DEPRECATED extends UserFollowingInfo {
  recommended: RecommendedFollowInfo_DEPRECATED;
}

export interface UserFollowingInfoAndRecommendations extends UserFollowingInfo {
  recommended: RecommendedFollowInfo;
}

export type SocialPost = UserSocialPost | EntitySocialPost;
/**
 * Posts that can be attributed to any kind of entity (user/KA/KP)
 */
export type EntitySocialPost = NewRecipePost;

/**
 * Posts that are tied to a human user
 */
export type UserSocialPost = UserRecipeActionPost | TextPost;

export function isUserSocialPost(p: SocialPost): p is UserSocialPost {
  return p.type === "userRecipeActionPost" || p.type === "textPost";
}

export function isEntitySocialPost(p: SocialPost): p is EntitySocialPost {
  return p.type === "newRecipePost";
}

export type RecipeSocialPost = UserRecipeActionPost | NewRecipePost;
export function isRecipeSocialPost(p: SocialPost): p is RecipeSocialPost {
  return p.type === "userRecipeActionPost" || p.type === "newRecipePost";
}

export function getSocialEntityIdFromPost(p: SocialPost): SocialEntityId {
  if (isUserSocialPost(p)) {
    return p.user.userId;
  } else if (isEntitySocialPost(p)) {
    return getSocialEntityId(p.entity);
  } else {
    bottomThrow(p);
  }
}

export type NextPostsStart = TypedPrimitive<string, "NextPostsStart">;
export type NextFollowersStart = TypedPrimitive<string, "NextFollowersStart">;

export interface SocialPosts {
  posts: SocialPost[];
  next?: NextPostsStart;
}

export interface SocialProfileInfo {
  user: DeglazeUserWithProfileInfo;
  countRecipes: number;
  countCooks: number;
  countFollowing: number;

  /**
   * Only returned if viewing own profile
   */
  countFollowers: number | undefined;
  /**
   * This will be set when the viewing user ID is set and not equal to the requested
   * profile user ID.
   */
  following?: boolean;

  /**
   * If the user is associated with a known author/publisher, this will be set.
   * At the time of adding this, the UI redirects to the known author/publisher screens if this is set
   */
  knownId?: KnownAuthorId | KnownPublisherId;
}

interface KnownProfileBase extends KnownProfileInfo {
  /**
   * Passive = the author/publisher has not given us permission to represent them directly. "Follow recipes from X"
   * Active - the author/publisher works with us and we can represent them directly "Follow X"
   */
  mode: "active" | "passive";
}

export interface KnownAuthorProfileInfo extends KnownProfileBase {
  author: KnownAuthor;
  user?: DeglazeUser;
}

export interface KnownPublisherProfileInfo extends KnownProfileBase {
  publisher: KnownPublisher;
  user?: DeglazeUser;
}

export interface GetKnownAuthorProfileArgs {
  authorId: KnownAuthorId;
}

export interface GetKnownPublisherProfileArgs {
  publisherId: KnownPublisherId;
}

export interface SocialProfilePosts extends SocialPosts {
  profileInfo?: SocialProfileInfo;
}

interface SocialPostBase<T extends string> {
  type: T;
  id: SocialPostId;
  created: EpochMs;
  comments: PostComments;
  likes: PostLikes;
  version: EpochMs;
  deleted?: boolean;
}

export interface NewRecipePost extends SocialPostBase<"newRecipePost"> {
  entity: SocialEntity;
  recipeInfo: RecipeInfo;
}

export interface TextPost extends SocialPostBase<"textPost"> {
  user: DeglazeUser;
  body: string;
}

export function assertIsUserRecipeActionPost(p: SocialPost): asserts p is UserRecipeActionPost {
  if (p.type !== "userRecipeActionPost") {
    throw new Error(`Expecting userRecipeActionPost. Got ${p.type}`);
  }
}

export interface UserRecipeActionPost extends SocialPostBase<"userRecipeActionPost"> {
  user: DeglazeUser;
  title: string;
  body?: string;
  rating?: RecipeRating;
  saves: PostRecipeSaves;
  sourceRecipeId: RecipeId;
  recipeInfo: RecipeInfo;
  userRecipeId: UserRecipeId;
  userRecipeStats: {
    cooked: number;
  };
}

export type CreateSocialPostArgs = CreateUserRecipeActionPostArgs | CreateTextPostArgs;

interface CreateSocialPostBase<T extends SocialPost["type"]> {
  type: T;
  postId: SocialPostId;
}

export interface CreateTextPostArgs extends CreateSocialPostBase<"textPost"> {
  userId: UserId;
  body: string;
}

export interface CreateUserRecipeActionPostArgs extends CreateSocialPostBase<"userRecipeActionPost"> {
  userId: UserId;
  title: string;
  body?: string;
  sourceRecipeId: RecipeId;
  userRecipeId: UserRecipeId;
  userRecipeStats: { cooked: number };
  recipeInfo: RecipeInfo;
  rating?: RecipeRating;
}

export type ModeratedFeedKey = "curated";
export type NewRecipePostFeedKey = "newRecipePosts";
export type GlobalFeedKey = "all" | ModeratedFeedKey | NewRecipePostFeedKey;

export interface GetFeedPostsArgs {
  start?: NextPostsStart;
  count: number;
  feed?: "following" | "explore";
}

/**
 * @deprecated remove after v3 forced upgrade
 * The new types are defined separately but are otherwise identical
 */
export interface GetFollowersArgs_DEPRECATED {
  userId: UserId;
  type: "followers" | "following";
  count: number;
  start?: NextFollowersStart;
}

export interface GetFollowersArgs {
  entityId: SocialEntityId;
  type: "followers";
  count: number;
  start?: NextFollowersStart;
}

export interface GetFollowingArgs {
  userId: UserId;
  type: "following";
  count: number;
  start?: NextFollowersStart;
}

/**
 * @deprecated Remove after forced v3 upgrade
 * We now have separate types since following now returns a list of entities
 * and not users
 */
export interface UserFollowers_DEPRECATED {
  userId: UserId;
  type: "followers" | "following";
  users: DeglazeUser[];
  next?: NextFollowersStart;
}

export interface EntityFollowers {
  entityId: SocialEntityId;
  type: "followers";
  users: DeglazeUser[];
  next?: NextFollowersStart;
}

export interface UserFollowing {
  userId: UserId;
  type: "following";
  entities: SocialEntity[];
  next?: NextFollowersStart;
}

export interface GetProfilePostsArgs {
  start?: NextPostsStart;
  count: number;
  userId: UserId;
}

export interface GetPostArgs {
  postId: SocialPostId;
}

export interface DeletePostArgs {
  postId: SocialPostId;
}

/**
 * COMMENTS
 */

export interface PostComments {
  count: number;
  items: Comment[];
  next?: NextCommentsStart;
}

export type PartialCommentId = TypedPrimitive<string, "PartialCommentId">;
export type CommentId = TypedPrimitive<string, "CommentId">;

export interface Comment {
  id: CommentId;
  text: string;
  ts: EpochMs;
  user: DeglazeUser;
  mentionsAndIds: Record<Username, UserId>;
  recipes?: CommentRecipeInfo[];

  // deprecated 1/31/2023 - this is here for back compat and can be removed
  mentions: Username[];
}

export type NextCommentsStart = TypedPrimitive<string, "NextCommentsStart">;

export interface AddCommentArgs {
  commentId: PartialCommentId;
  text: string;
  postId: SocialPostId;
  recipeIds?: UserRecipeId[];
}

export interface DeleteCommentArgs {
  commentId: CommentId;
  postId: SocialPostId;
}

/**
 * LIKES
 */

export interface PostLikes {
  count: number;
  items: Like[];
  next?: NextLikesStart;
}

export interface Like {
  user: DeglazeUser;
}

export type NextLikesStart = TypedPrimitive<string, "NextLikesStart">;

export interface LikeUnlikePostArgs {
  action: "like" | "unlike";
  postId: SocialPostId;
}

export type SocialEntityId = UserId | KnownAuthorId | KnownPublisherId;
export type SocialEntity = DeglazeUser | KnownAuthor | KnownPublisher;

export function isUserId(id: SocialEntityId): id is UserId {
  return !isKnownAuthorId(id) && !isKnownPublisherId(id);
}

export function getSocialEntityId(entity: SocialEntity): SocialEntityId {
  if (isDeglazeUser(entity)) {
    return entity.userId;
  }

  return entity.id;
}

export function isDeglazeUser(entity: SocialEntity): entity is DeglazeUser {
  // there are some places in the UI where DeglazeUser is exteneded with a "type" property to drive some UI behavior
  // so we don't want to blindly check for the existence of type
  if ("type" in entity && (entity.type === "knownAuthor" || entity.type === "knownPublisher")) {
    return false;
  }

  const userIdProps: Array<keyof DeglazeUser> = ["userId", "username", "name"];
  return userIdProps.every(p => p in entity);
}

export function isKnownAuthor(entity: SocialEntity): entity is KnownAuthor {
  return "type" in entity && entity.type === "knownAuthor";
}

export function isKnownPublisher(entity: SocialEntity): entity is KnownPublisher {
  return "type" in entity && entity.type === "knownPublisher";
}

export function isKnownUserAuthor(entity: SocialEntity): entity is KnownAuthor & Required<Pick<KnownAuthor, "userId">> {
  return isKnownAuthor(entity) && !!entity.userId;
}

export function getEntityDisplayText(
  entity: Pick<DeglazeUser, "username" | "name"> | Pick<KnownAuthor | KnownPublisher, "type" | "name">,
  opts?: { numLines?: "singleLine" | "twoLine"; prefix?: "recipesFromBy" | "none" }
): { headline: string; subHeadline: string } {
  if (!("type" in entity)) {
    return {
      headline: entity.username,
      subHeadline: entity.name,
    };
  }

  const subHeadline = "Recipe Collection";

  if (opts?.prefix === "none") {
    return {
      headline: entity.name,
      subHeadline,
    };
  }

  const spaceOrNewline = opts?.numLines === "twoLine" ? "\n" : " ";

  switch (entity.type) {
    case "knownAuthor": {
      return {
        headline: `Recipes by${spaceOrNewline}${entity.name}`,
        subHeadline,
      };
    }
    case "knownPublisher": {
      return {
        headline: `Recipes from${spaceOrNewline}${entity.name}`,
        subHeadline,
      };
    }
    default:
      bottomThrow(entity.type);
  }
}

/**
 * @deprecated v3
 */
export interface FollowUnfollowUserArgs_DEPRECATED {
  action: "follow" | "unfollow";
  userId: UserId;
}

export interface FollowUnfollowEntityArgs {
  action: "follow" | "unfollow";
  entityId: SocialEntityId;
}

export interface FollowUnfollowEntityResult {
  action: "follow" | "unfollow";
  entityIds: SocialEntityId[];
}

/**
 * Saves
 */

export interface PostRecipeSaves {
  count: number;
  items: RecipeSave[];
  next?: NextRecipeSavesStart;
}

export interface RecipeSave {
  user: DeglazeUser;
}

export type NextRecipeSavesStart = TypedPrimitive<string, "NextRecipeSavesStart">;

export interface SaveRecipeFromPostArgs {
  postId: SocialPostId;
  /**
   * If not passed, we use the post ID. This is only optional for back-compat reasons and should be set moving forward.
   */
  partialRecipeId?: PartialRecipeId;
}

export interface SaveRecipeFromPostResult {
  post: SocialPost;
  recipe: AppUserRecipe;
}

export interface CreateTextPostClientArgs {
  postId: SocialPostId;
  body: string;
}

/**
 * @deprecated v3
 */
export interface ReportDismissedFollowRecommendationArgs_DEPRECATED {
  userId: UserId;
}

export interface ReportDismissedFollowRecommendationArgs {
  entityId: SocialEntityId;
}

export interface SocialTestHooksArgs {
  action: "clearFollowingAndFeed" | "clearFollowRecommendations" | "generateFollowRecommendations";
}

/**
 * NOTIFICATIONS
 */

export type SocialWebsocketPushTypes = PushPostsUpdated | PushNewFeedPosts;
export type PushPostsUpdated = DataAndType<"social/postsUpdated", PostsUpdatedData>;

export type SocialNotificationPushTypes =
  | PushPostLiked
  | PushPostComment
  | PushPostRecipeSaved
  | PushNewPost
  | PushPostReady
  | PushNewFollower;
export type PushPostLiked = DataAndType<"social/postLiked", PostLikedData>;
export type PushPostComment = DataAndType<"social/postComment", PostCommentData>;
export type PushPostRecipeSaved = DataAndType<"social/postRecipeSaved", PostSavedData>;
export type PushPostReady = DataAndType<"social/postReady", PostData>;
export type PushNewPost = DataAndType<"social/newPost", PostData>;
export type PushNewFollower = DataAndType<"social/newFollower", NewFollowerData>;
export type PushNewFeedPosts = DataAndType<"social/newFeedPosts", NewFeedPostsData>;

export interface NewFollowerData {
  userId: UserId;
}

export interface PostLikedData {
  postId: SocialPostId;
  likedByUserId: UserId;
}

export interface PostSavedData {
  postId: SocialPostId;
  savedByUserId: UserId;
}

export interface PostCommentData {
  postId: SocialPostId;
  commentUserId: UserId;
  commentId?: CommentId;
}

export interface PostsUpdatedData {
  posts: SocialPost[];
}

export interface PostData {
  postId: SocialPostId;
  postType?: SocialPost["type"];
}

export interface NewFeedPostsData {
  feed: "following" | "explore";
}
