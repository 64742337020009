import { lazy } from "./util/Lazy";

export interface LogContext {
  [key: string]: unknown;
}

export type LogLevel = "debug" | "info" | "warn" | "error";

export interface Logger {
  debug(msg: string, ctx?: LogContext): void;
  info(msg: string, ctx?: LogContext): void;
  warn(msg: string, ctx?: LogContext): void;
  error(msg: string, ctx?: LogContext): void;
  errorCaught(msg: string, err: unknown, ctx?: LogContext, level?: LogLevel): void;
}

// Used to send log lines from the app to the server
// debug omitted intentionally
export interface LogLine {
  level: "info" | "warn" | "error";
  message: string;
  context?: LogContext;
}

export interface LogLines {
  lines: Array<LogLine>;
}

/**
 * Shared code that needs access to a logger doesn't know if it's running in the app
 * or on the server, so it doesn't know which implementation to use. Each entrypoint should
 * set the correct logger.
 */
let log: Logger | undefined;

export function setLogger(logger: Logger) {
  log = logger;
}

export function getLogger(): Logger {
  // just default to a test implementation if tests are running and there isn't
  // an implementation set.
  if (!log && process.env["NODE_ENV"] === "test") {
    return testLogger.get;
  }

  if (log === undefined) {
    throw new Error("No logger impl set");
  }

  return log;
}

const testLogger = lazy<Logger>(() => {
  return {
    info(msg: string) {
      // eslint-disable-next-line no-console
      console.log("TEST LOGGER INFO", msg);
    },
    warn(msg: string) {
      // eslint-disable-next-line no-console
      console.log("TEST LOGGER WARNING", msg);
    },
    error(msg: string) {
      // eslint-disable-next-line no-console
      console.log("TEST LOGGER ERROR", msg);
    },
    debug(msg: string) {
      // eslint-disable-next-line no-console
      console.log("TEST LOGGER DEBUG", msg);
    },
    errorCaught(msg: string, err: unknown) {
      // eslint-disable-next-line no-console
      console.log("TEST LOGGER ERRORCAUGHT", msg, err);
    },
  };
});
