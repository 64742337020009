import { useCallback, useMemo } from "react";
import { ScreenView } from "../components/ScreenView";
import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { TBody } from "../components/Typography";
import { HeaderProps } from "../components/ScreenHeaders";
import { SupportInstructions } from "../components/SupportComponents";
import { Spacer } from "../components/Spacer";

const strings = {
  screenHeader: "Support",
  helpSubject: "To get help or report an issue, please contact us at",
  contact: "help@deglaze.app",
};

export const UnauthedSupportScreen = withNonNavigableScreenContainer("UnauthedSupportScreen", () => {
  const screen = useScreen();
  const goBack = useCallback(() => {
    screen.nav.goBack();
  }, [screen.nav.goBack]);

  const header = useMemo<HeaderProps>(() => {
    return { type: "default", title: strings.screenHeader, right: { type: "done", onPress: goBack } };
  }, [goBack]);

  return (
    <ScreenView header={header}>
      <Spacer vertical={3} />
      <TBody align="center">
        <SupportInstructions />
      </TBody>
    </ScreenView>
  );
});
