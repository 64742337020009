import React, { PropsWithChildren, useEffect, useState } from "react";
import { Keyboard, StyleSheet, View } from "react-native";
import { useBottomTabBarDimensions } from "../navigation/TabBar";
import { BottomNotch } from "./BottomNotch";
import { switchReturn } from "@eatbetter/common-shared";

interface Props {
  backgroundColor?: string;
  snapPoint?: { bottom: "screenBottom" } | { bottom: "tabBar" } | { bottom: "custom"; value: number };
}

export const InputAccessoryView = React.memo((props: PropsWithChildren<Props>) => {
  const { bottomNotchHeight, bottomTabBarHeight } = useBottomTabBarDimensions();

  const snapTo = props.snapPoint ?? { bottom: "screenBottom" };
  const bottom = switchReturn(snapTo.bottom, {
    screenBottom: bottomNotchHeight,
    tabBar: bottomTabBarHeight,
    custom: "value" in snapTo ? snapTo.value : bottomNotchHeight,
  });

  const [keyboardHeight, setKeyboardHeight] = useState(0);

  useEffect(() => {
    const keyboardWillShowListener = Keyboard.addListener("keyboardWillShow", e => {
      Keyboard.scheduleLayoutAnimation(e);
      setKeyboardHeight(e.endCoordinates.height);
    });

    const keyboardWillHideListener = Keyboard.addListener("keyboardWillHide", e => {
      Keyboard.scheduleLayoutAnimation(e);
      setKeyboardHeight(0);
    });

    return () => {
      keyboardWillShowListener.remove();
      keyboardWillHideListener.remove();
    };
  }, []);

  const bottomOffset = keyboardHeight > bottom ? keyboardHeight - bottom : 0;

  return (
    <>
      <View style={[styles.container, { bottom: bottom + bottomOffset }]}>{props.children}</View>
      {snapTo.bottom === "screenBottom" && <BottomNotch backgroundColor={props.backgroundColor} />}
    </>
  );
});

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    left: 0,
    right: 0,
    zIndex: 1000,
  },
});
