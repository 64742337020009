import { useRenderTracker } from "../lib/util/RenderTracker";
import {
  getPathFromState,
  LinkingOptions,
  NavigationContainer,
  NavigationState,
  PartialState,
  useNavigation,
  useNavigationContainerRef,
} from "@react-navigation/native";
import {
  getHackModalScreenName,
  GetNavStackComponents,
  getScreenNameFromReactNavigationRouteName,
  ModalNavScreen,
  NavigableScreenType,
  NavScreen,
  ScreenType,
} from "./NavigationTypes";
import { ScreenTypeRouteName, navTree, isNavigableScreen, isNonNavigableScreen, WithName } from "./NavTree";
import { getStackNative } from "./GetStackNative";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { log } from "../Log";
import { useEffect, useRef } from "react";
import { appLaunched } from "../lib/AppLifecycle";
import { AppDispatch, useDispatch } from "../lib/redux/Redux";
import { getTabBarOptions } from "./TabBar";
import { CurrentEnvironment } from "../CurrentEnvironment";
import { getReactNavigationGetIdFunction, setRootNavRef } from "./ScreenContainer";
import { useAuthStatus } from "../lib/system/SystemSelectors";
import {
  AnalyticsTabName,
  reportAuthStatusChanged,
  reportBottomTabChanged,
  reportFontScale,
} from "../lib/analytics/AnalyticsEvents";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { tabsMounted, tabsUnmounted } from "../lib/system/SystemSlice";
import { PixelRatio, Platform } from "react-native";

interface LinkingNode {
  path?: string;
  screens: Record<string, LinkingNode | LinkingLeafNode>;
  initialRouteName?: string;
}

interface LinkingLeafNode {
  path: string;
  exact?: boolean;
  parse?: Record<string, (s: string) => string>;
}

type ReactNavigationState = NavigationState | Omit<PartialState<NavigationState>, "stale">;

let linkingConifg: LinkingOptions<any>;
export function getLinkingConfig(): LinkingOptions<any> {
  if (!linkingConifg) {
    throw new Error("linkingOptions not initialized yet. Has NavigationRoot been mounted or buildNav called?");
  }

  return linkingConifg;
}

function isLinkingNode(x: LinkingNode | LinkingLeafNode): x is LinkingNode {
  const screensKey: keyof LinkingNode = "screens";
  return screensKey in x;
}

function getLinkingNode(screens: LinkingNode["screens"], path?: string, initialRouteName?: string): LinkingNode {
  // keys present with undefined values resulted in an error at the time this was added
  const p = path ? { path } : {};
  const irn = initialRouteName ? { initialRouteName } : {};

  return { screens, ...p, ...irn };
}

const decodeUriComponentProxy = new Proxy<Record<string, (s: string) => string>>(
  {},
  {
    get(): any {
      return (s: string) => decodeURIComponent(s);
    },
  }
);

function screenToLinkingLeafNode(
  screen: WithName<NavScreen<unknown>> | WithName<ModalNavScreen<unknown>>
): LinkingLeafNode {
  if (!isNavigableScreen(screen)) {
    throw new Error("Got non-navigable screen in screenToLinkingLeafNode");
  }

  return {
    path: screen.path,
    exact: screen.pathType === "absolute",

    // react-navigation calls encodeURIComponent when building the URL, but not when
    // parsing the URL to build state. I'm not sure if this is a bug or if there is a reason for this
    // but this takes care of it.
    // One thing to note: this code only comes into play when parsing the initial URL. After that,
    // the URL is produced from the state passed in navigation operations, and not the other way around.
    parse: decodeUriComponentProxy,
  };
}

function getLinkingOptions(config: LinkingOptions<any>["config"]): LinkingOptions<any> {
  return {
    prefixes: ["https://www.mooklab-dev.link", "http://localhost", "http://dev.localhost", "eatbetter://"],
    config,
    getInitialURL: async () => {
      // By default, React Navigation will navigate to the initial URL, but it does
      // so without mounting any screens first. So, for example, if the destination
      // is a modal intended to be displayed over another screen, the modal will
      // end up *not* over the other screen. So, we return null here and handle
      // the navigation to the initial URL ourselves. See InitialLinkHandler
      return null;
    },
    getPathFromState: state => {
      const getPathFromStateWithConfig = (s: ReactNavigationState) => getPathFromState(s, { screens: config!.screens });
      // This seems to fire for multiple screens at once. For example, when navigating, I see one of these for each
      // tab of the BottomTabNavigator:
      // {
      //   "index": 0,
      //   "routes": [
      //   {
      //     "name": "recipeAddMenu-ModalStack",
      //     "state": {
      //       "index": 0,
      //       "routes": [
      //         {
      //           "name": "groceriesTab"
      //         }
      //       ]
      //     },
      //     "key": "recipeAddMenu-ModalStack-v0T47sx78iblFuQ1CdVkm"
      //   }
      // ]
      // }
      // I'm not really sure why, but what we want is the full state, which is documented here: https://reactnavigation.org/docs/navigation-state/
      // Checking for type seems to be sufficient
      if (state.type) {
        // The goal here is to identify if a non-navigable screen is the visible screen, and if so, hide it if that is what is configured
        // this code assumes that all non-navigable screens are on the root stack and so it only checks the root routes.
        // We have a check for this buildNav
        let modified = false;
        let stateToUse: ReactNavigationState = state;

        do {
          const r = removeNonNavigableFromRootState(stateToUse);
          if (r.modified) {
            // all routes are non-navigable. Return root path.
            if (r.newState === undefined) {
              log.info("New state for building path has no routes - returning /");
              return "/";
            }

            modified = true;
            stateToUse = r.newState;
          } else {
            // no modifications from the last call. If we have already modified, return the path based on that
            if (modified) {
              return getPathFromStateWithConfig(stateToUse);
            }
            // otherwise, the while will terminate and we'll return the default
          }
        } while (modified);
      }

      return getPathFromStateWithConfig(state);
    },
  };
}

/**
 * Remove non-navigable screens from route state for purposes of building a path. In the case that we remove the last screen, return
 * undefined as the state, meaning "/" should be returned for the path.
 */
function removeNonNavigableFromRootState(state: ReactNavigationState): {
  modified: boolean;
  newState: ReactNavigationState | undefined;
} {
  // The goal here is to identify if a non-navigable screen is the visible screen, and if so, hide it if that is what is configured
  // this code assumes that all non-navigable screens are on the root stack and so it only checks the root routes.
  // We have a check for this buildNav
  if (state.index !== undefined && state.routes[state.index]) {
    const activeScreenName = getScreenNameFromReactNavigationRouteName(
      state.routes[state.index]!.name
    ) as ScreenTypeRouteName;
    const screen = navTree.get.screens[activeScreenName];
    if (screen && isNonNavigableScreen(screen)) {
      log.info(`Removing non-navigable screen ${activeScreenName} from state for purposes of building path`);
      if (state.index === 0 || state.routes.length <= 1) {
        return { modified: true, newState: undefined };
      }

      const modifiedRoutes = state.routes.slice(0, state.routes.length - 1);
      const modifiedIndex = state.index - 1;
      const newState = {
        ...state,
        index: modifiedIndex,
        routes: modifiedRoutes,
      } as NavigationState;

      return { modified: true, newState };
    }
  }

  return { modified: false, newState: state };
}

export const NavigationRoot = (props: { getStack?: GetNavStackComponents }) => {
  useRenderTracker("NavigationRoot");
  const dispatch = useDispatch();
  const authStatus = useAuthStatus();

  const navigationRef = useNavigationContainerRef();

  // NavigationRoot is the root-most component that is rendered inside the redux-persist gate
  // so by dispatching this here, we know that the state has been rehydrated.
  useEffect(() => {
    return dispatch(appLaunched());
  }, []);

  useEffect(() => {
    dispatch(analyticsEvent(reportAuthStatusChanged(authStatus)));
  }, [authStatus]);

  useEffect(() => {
    const fontScale = PixelRatio.getFontScale();
    dispatch(analyticsEvent(reportFontScale({ fontScale })));
  }, []);

  useEffect(() => {
    setRootNavRef(navigationRef);
  }, [navigationRef]);

  const getStack = props.getStack ?? getStackNative;

  const { navigator, linking } = useRef(buildNav(getStack, dispatch)).current;

  return (
    // We disable documentTitle here to prevent navigation from setting the document title on web (which defaults to route title)
    // The default value and the per-screen dynamic value is controlled in ScreenView. Document title is a web-only feature.
    <NavigationContainer
      documentTitle={{ enabled: false }}
      ref={navigationRef}
      linking={linking}
      onStateChange={CurrentEnvironment.configEnvironment() !== "prod" ? logState : undefined}
    >
      {navigator}
    </NavigationContainer>
  );
};

function buildNav(getStack: GetNavStackComponents, dispatch: AppDispatch) {
  const root = getStack();
  const home = getStack();
  const recipes = getStack();
  const lists = getStack();
  const profile = getStack();
  const Tabs = createBottomTabNavigator();
  const { screens, tabs } = navTree.get;

  // verify that:
  // 1. non-navigable screens are modals or exclusively in the root stack
  // 2. deep-linkable screens are modals ore exclusively in the root stack. This restriction is because we currently
  //    have naive logic to pull the screen name from the nav state and naive logic to actually do the nav.
  //    For example, to get the screen name from a screen within a tab is not currently supported and we would
  //    have to use the switchTab nav instead of goTo. All of this is doable, but not required at the moment.
  Object.entries(screens).forEach(e => {
    const screenName = e[0];
    const s = e[1];
    const isRoot = s.isModal || (s.stacks.length === 1 && s.stacks[0] === "root");
    if (isNonNavigableScreen(s) && !isRoot) {
      throw new Error(
        `Non-navigable screens are only supported for modals and screens exclusively in the root stack. ${screenName} stacks: ${s.stacks}`
      );
    } else if (isNavigableScreen(s) && s.deeplink && !isRoot) {
      throw new Error(
        `Deeplink screens are only supported for modals and screens exclusively in the root stack. ${screenName} stacks: ${s.stacks}`
      );
    }
  });

  const homeNavScreens = Object.values(screens).filter(screen => isScreenForTab("home", screen as ScreenType<unknown>));
  const homeScreens = homeNavScreens.map(screen => {
    return home.getScreen(
      screen.name,
      screen.component,
      screen.options,
      getReactNavigationGetIdFunction(screen as ScreenType<unknown>)
    );
  });
  const HomeNavigator = getTabComponent("Home", home.getNavigator([homeScreens]));
  const homeInitialRoute = homeNavScreens[0]?.name;
  const homeNavigableScreens = homeNavScreens.filter(s => isNavigableScreen(s));
  const homeLinkingEntries = homeNavigableScreens.map(s => [
    s.name,
    screenToLinkingLeafNode(s as WithName<NavigableScreenType<any>>),
  ]);
  const homeScreensLinking = Object.fromEntries(homeLinkingEntries);
  const homeLinking = getLinkingNode(homeScreensLinking, tabs.homeTab.path, homeInitialRoute);

  const recipesNavScreens = Object.values(screens).filter(screen =>
    isScreenForTab("recipes", screen as ScreenType<unknown>)
  );
  const recipesScreens = recipesNavScreens.map(screen => {
    return recipes.getScreen(
      screen.name,
      screen.component,
      screen.options,
      getReactNavigationGetIdFunction(screen as ScreenType<unknown>)
    );
  });
  const RecipesNavigator = getTabComponent("Recipes", recipes.getNavigator([recipesScreens]));
  const recipesInitialRoute = recipesNavScreens[0]?.name;
  const recipesNavigableScreens = recipesNavScreens.filter(s => isNavigableScreen(s));
  const recipesLinkingEntries = recipesNavigableScreens.map(s => [
    s.name,
    screenToLinkingLeafNode(s as WithName<NavigableScreenType<any>>),
  ]);
  const recipesScreensLinking = Object.fromEntries(recipesLinkingEntries);
  const recipesLinking = getLinkingNode(recipesScreensLinking, tabs.recipesTab.path, recipesInitialRoute);

  const listsNavScreens = Object.values(screens).filter(screen =>
    isScreenForTab("lists", screen as ScreenType<unknown>)
  );
  const listsScreens = listsNavScreens.map(screen => {
    return lists.getScreen(
      screen.name,
      screen.component,
      screen.options,
      getReactNavigationGetIdFunction(screen as ScreenType<unknown>)
    );
  });
  const ListsNavigator = getTabComponent("Groceries", lists.getNavigator([listsScreens]));
  const listsInitialRoute = listsNavScreens[0]?.name;
  const listsNavigableScreens = listsNavScreens.filter(s => isNavigableScreen(s));
  const listsLinkingEntries = listsNavigableScreens.map(s => [
    s.name,
    screenToLinkingLeafNode(s as WithName<NavigableScreenType<any>>),
  ]);
  const listsScreensLinking = Object.fromEntries(listsLinkingEntries);
  const listsLinking = getLinkingNode(listsScreensLinking, tabs.groceriesTab.path, listsInitialRoute);

  const profileNavScreens = Object.values(screens).filter(screen =>
    isScreenForTab("profile", screen as ScreenType<unknown>)
  );
  const profileScreens = profileNavScreens.map(screen => {
    return profile.getScreen(
      screen.name,
      screen.component,
      screen.options,
      getReactNavigationGetIdFunction(screen as ScreenType<unknown>)
    );
  });
  const ProfileNavigator = getTabComponent("Profile", profile.getNavigator([profileScreens]));
  const profileInitialRoute = profileNavScreens[0]?.name;
  const profileNavigableScreens = profileNavScreens.filter(s => isNavigableScreen(s));
  const profileLinkingEntries = profileNavigableScreens.map(s => [
    s.name,
    screenToLinkingLeafNode(s as WithName<NavigableScreenType<any>>),
  ]);
  const profileScreensLinking = Object.fromEntries(profileLinkingEntries);
  const profileLinking = getLinkingNode(profileScreensLinking, tabs.profileTab.path, profileInitialRoute);

  const BottomTabs = () => {
    useEffect(() => {
      dispatch(tabsMounted());

      return () => {
        dispatch(tabsUnmounted());
      };
    }, []);

    return (
      <Tabs.Navigator screenOptions={{ headerShown: false, lazy: false, ...getTabBarOptions() }}>
        <Tabs.Screen name={tabs.homeTab.name} component={HomeNavigator} options={tabs.homeTab.options} />
        <Tabs.Screen name={tabs.recipesTab.name} component={RecipesNavigator} options={tabs.recipesTab.options} />
        <Tabs.Screen name={tabs.groceriesTab.name} component={ListsNavigator} options={tabs.groceriesTab.options} />
        <Tabs.Screen name={tabs.profileTab.name} component={ProfileNavigator} options={tabs.profileTab.options} />
      </Tabs.Navigator>
    );
  };

  // this must be named home as it currently stands. When we nav to "home" from the root stack,
  // it's actually finding this component and not the home screen nested within this component.
  // The home screen nested within this component is the first screen, and hence the effect
  // ends up being the same in both cases.
  const tabsScreenName = "home";
  const tabsScreen = root.getScreen(
    tabsScreenName,
    BottomTabs,
    {
      headerShown: false,
      animation: "fade",
      animationTypeForReplace: "push",
    },
    undefined
  );

  const modalRootNavScreens = Object.values(screens).filter(screen => screen.isModal);

  const modalRootScreens = modalRootNavScreens.map(screen => {
    // https://github.com/react-navigation/react-navigation/issues/10333
    // due to this bug, we wrap each modal screen in a navigator and stick that in a screen
    // we use a separate navigator for each screen because we need to set the options per screen.
    // If we had all the modal screens nested in 1 navigator, I couldn't figure out how to support different
    // types of modal presentation styles. There's probably an easier way to do this, but this seems to work.
    const stack = getStack();
    const rawScreen = stack.getScreen(
      screen.name,
      screen.component,
      screen.options,
      getReactNavigationGetIdFunction(screen as ScreenType<unknown>)
    );
    const navigator = stack.getNavigator(rawScreen);
    return root.getScreen(
      getHackModalScreenName(screen.name),
      () => navigator,
      {
        ...screen.options,
        headerShown: false,
      },
      undefined
    );
  });

  const modalRootNavigableScreens = modalRootNavScreens.filter(s => isNavigableScreen(s));
  const modalRootLinkingEntries = modalRootNavigableScreens.map(s => {
    const modalScreens = { [s.name]: screenToLinkingLeafNode(s as WithName<NavigableScreenType<any>>) };
    const node = getLinkingNode(modalScreens);
    return [getHackModalScreenName(s.name), node];
  });

  const modalRootScreensLinking = Object.fromEntries(modalRootLinkingEntries);

  const otherRootNavScreens = Object.values(screens).filter(s => !s.isModal && s.stacks.includes("root"));
  const otherRootScreens = otherRootNavScreens.map(screen =>
    root.getScreen(
      screen.name,
      screen.component,
      screen.options,
      getReactNavigationGetIdFunction(screen as ScreenType<unknown>)
    )
  );
  const otherRootNavigableScreens = otherRootNavScreens.filter(s => isNavigableScreen(s));
  const otherRootScreensLinkingEntries = otherRootNavigableScreens.map(s => [
    s.name,
    screenToLinkingLeafNode(s as WithName<NavigableScreenType<any>>),
  ]);
  const otherRootScreensLinking = Object.fromEntries(otherRootScreensLinkingEntries);

  const rootScreens = [...otherRootScreens, ...modalRootScreens];

  const tabsLinking: LinkingNode["screens"] = {
    [tabs.homeTab.name]: homeLinking,
    [tabs.recipesTab.name]: recipesLinking,
    [tabs.groceriesTab.name]: listsLinking,
    [tabs.profileTab.name]: profileLinking,
  };

  const rootScreensLinking = {
    ...otherRootScreensLinking,
    [tabsScreenName]: getLinkingNode(tabsLinking),
    ...modalRootScreensLinking,
  };

  // do we want the tabScreen as an initial route? We don't for screens like share because we don't want a back button on web, but there might
  // be other cases we do. Go without for now and we'll deal with it when we need to.
  const rootLinking = getLinkingNode(rootScreensLinking, undefined);

  const linking = getLinkingOptions(rootLinking);

  if (CurrentEnvironment.configEnvironment() !== "prod") {
    verifyLinkingConfig(linking);
  }

  linkingConifg = linking;

  return { navigator: root.getNavigator([rootScreens, tabsScreen]), linking };
}

interface ScreenAndPath {
  screenName: string;
  path: string;
}

function verifyLinkingConfig(linkingOptions: LinkingOptions<any>): void {
  // eslint-disable-next-line no-console
  const consoleLog = (s: string) => console.log(s);
  const pathInfo: ScreenAndPath[] = [];
  const addPath = (s: ScreenAndPath) => pathInfo.push(s);
  verifyLinkingConfigRecursive(linkingOptions.config as LinkingNode, "", addPath);
  const foundPaths = new Set<string>();
  pathInfo.sort((a, b) => a.path.localeCompare(b.path));

  startConsoleGroup("Nav linking options");
  consoleLog(JSON.stringify(linkingOptions, null, 2));
  endConsoleGroup();
  const padLength = Math.max(...pathInfo.map(p => p.screenName.length)) + 2;
  startConsoleGroup("Nav screen paths");
  pathInfo.forEach(pi => {
    if (foundPaths.has(pi.path)) {
      const conflicts = pathInfo.filter(p => p.path === pi.path);
      throw new Error(`Path ${pi.path} is present for multiple screens: ${conflicts.map(p => p.screenName)}`);
    }
    foundPaths.add(pi.path);
    consoleLog(`${pi.screenName.padEnd(padLength, " ")}${pi.path}`);
  });
  endConsoleGroup();
}

function startConsoleGroup(label: string) {
  // eslint-disable-next-line no-console
  Platform.OS === "web" ? console.groupCollapsed(label) : console.log(`${label}:`);
}

function endConsoleGroup() {
  if (Platform.OS === "web") {
    // eslint-disable-next-line no-console
    console.groupEnd();
  }
}

function verifyLinkingConfigRecursive(
  startNode: LinkingNode,
  currentPrefix: string,
  addPath: (sap: ScreenAndPath) => void
): void {
  const prefix = startNode.path ? currentPrefix + startNode.path : currentPrefix;
  Object.entries(startNode.screens).forEach(([screenName, node]) => {
    if (isLinkingNode(node)) {
      if ((node.path && !node.path.startsWith("/")) || node.path?.endsWith("/")) {
        throw new Error(`Found linking node path that doesn't start with / or ends with /: ${node.path}`);
      }
      verifyLinkingConfigRecursive(node, prefix, addPath);
    } else {
      if (node.path === "") {
        addPath({ screenName, path: prefix ? prefix : "/" });
        return;
      }

      if (!node.path.startsWith("/") || node.path.endsWith("/")) {
        throw new Error(`Screen ${screenName} has a path that doesn't start with / or ends with /: ${node.path}`);
      }

      // this list of variants starts with a single item and grows when optional params are encountered
      let optionalFound = false;
      const variants: string[][] = [[""]];

      // get all segments except for the empty string that results from the leading slash
      const segments = node.path.split("/").slice(1);
      segments.forEach(s => {
        const param = s.startsWith(":");
        const optionalParam = param && s.endsWith("?");

        if (optionalFound && !optionalParam) {
          throw new Error(`Screen ${screenName} has a required path segment after an optional segment`);
        }

        if (optionalParam) {
          optionalFound = true;
        }

        const paramKey = "_param_";
        if (!param) {
          variants.forEach(v => v.push(s));
        } else if (!optionalParam) {
          variants.forEach(v => v.push(paramKey));
        } else {
          // optional param - don't add to existing variant, but add a new variant that includes it
          variants.push([...variants[variants.length - 1]!, paramKey]);
        }
      });

      variants.forEach(v => {
        const joined = v.join("/");
        const path = node.exact ? joined : prefix + joined;
        addPath({ screenName, path });
      });
    }
  });
}

function isScreenForTab(tab: "home" | "recipes" | "lists" | "profile", screen: ScreenType<unknown>): boolean {
  return !screen.isModal && (screen.stacks.includes("allTabs") || screen.stacks.includes(tab));
}

function logState(state?: NavigationState): void {
  // eslint-disable-next-line no-console
  console.log("NAV STATE CHANGE");
  printRoutes(state, 0);
}

function printRoutes(state: NavigationState | PartialState<NavigationState> | undefined, indentLevel: number) {
  // eslint-disable-next-line no-console
  const print = (s: string) => console.log(`${" ".repeat(indentLevel * 2)}${s}`);
  state?.routes.forEach((r, idx) => {
    const name = idx === state.index ? `--> ${r.name}` : r.name;
    print(name);
    printRoutes(r.state, indentLevel + 1);
  });
}

/**
 * This adds a mixpanel event for tab switches
 */
let lastReportedTab: AnalyticsTabName = "Home";
const getTabComponent = (tab: AnalyticsTabName, component: JSX.Element): (() => JSX.Element) => {
  return () => {
    const navigation = useNavigation();
    const dispatch = useDispatch();
    useEffect(() => {
      // event names don't include specialized events
      return navigation.addListener("tabPress" as any, () => {
        // we were seeing duplicate tab events. Not sure if the user was pressing it
        // multiple times, or if this event fires in some other cases. Either way, we
        // don't need the dup events for analytics.
        if (tab !== lastReportedTab) {
          dispatch(analyticsEvent(reportBottomTabChanged({ tab })));
          lastReportedTab = tab;
        }
      });
    }, [tab, navigation]);

    return component;
  };
};
