import { isAxiosError } from "axios";

/**
 * Returns true if the error is an axios error for a request that timed out
 */
export function isAxiosTimeout(err: unknown): boolean {
  return isAxiosError(err) && err.code === "ECONNABORTED" && err.message.toLowerCase().includes("timeout");
}

/**
 * Returns true if the error is an axios error for a request that was not able to be started
 * because there was a network error. For example, disabling wifi/cellular and making a request
 * results in this error.
 */
export function isAxiosNetworkError(err: unknown): boolean {
  return isAxiosError(err) && err.code === "ERR_NETWORK";
}
