import { SyncThunkAction, ThunkAction } from "../redux/Redux";
import { isLoading } from "../redux/ServerData";
import { log } from "../../Log";
import {
  markNotificationsRead,
  newNotificationsErrored,
  newNotificationsReceived,
  newNotificationsRequested,
  notificationTapped,
  olderNotificationsErrored,
  olderNotificationsReceived,
  olderNotificationsRequested,
} from "./NotificationsSlice";
import { setBadgeCountAsync } from "expo-notifications";
import { selectNotificationUnreadCount } from "./NotificationsSelectors";
import { NotificationId } from "@eatbetter/users-shared";
import { reportNotificationsViewed, reportNotificationTapped } from "../analytics/AnalyticsEvents";
import { analyticsEvent } from "../analytics/AnalyticsThunks";
import { NotificationContext } from "../NotificationHandlers";

export const loadNewNotifications = (): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: loadNewNotifications");
    const state = getState();

    try {
      if (isLoading("notifications.newNotificationsMeta", state, s => s.notifications.newNotificationsMeta)) {
        log.info("loadNewNotifications called, but status is already loading");
        return;
      }

      const startTime = deps.time.epochMs();
      dispatch(newNotificationsRequested(startTime));

      const resp = await deps.api.withThrow().getNotifications({ count: 20 });
      dispatch(newNotificationsReceived({ startTime, data: resp.data }));
    } catch (err) {
      log.errorCaught("Unexpected error fetching new notifications", err);
      dispatch(newNotificationsErrored());
    }

    try {
      const updatedState = getState();
      const badgeCount = selectNotificationUnreadCount(updatedState);
      log.info(`Setting badgeCount to ${badgeCount} in loadNewNotifications`);
      await setBadgeCountAsync(badgeCount);
    } catch (err) {
      log.errorCaught("Error updating badge count in loadNewNotifications", err);
    }
  };
};

export const loadOlderNotifications = (): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: loadOlderNotifications");
    const state = getState();

    try {
      if (isLoading("notifications.oldNotificationsMeta", state, s => s.notifications.oldNotificationsMeta)) {
        log.info("loadOldNotifications called, but status is already loading");
        return;
      }

      if (!state.notifications.next) {
        log.info("No notifications.next. Nothing to fetch.");
        return;
      }

      const startTime = deps.time.epochMs();
      dispatch(olderNotificationsRequested(startTime));

      const resp = await deps.api.withThrow().getNotifications({
        start: state.notifications.next,
        count: 20,
      });

      dispatch(olderNotificationsReceived({ startTime, data: resp.data }));
    } catch (err) {
      log.errorCaught("Unexpected error fetching older notifications", err);
      dispatch(olderNotificationsErrored());
    }
  };
};

export const notificationScreenMounted = (): SyncThunkAction<void> => {
  return (dispatch, getState, _deps) => {
    log.info("Thunk: notificationScreenMounted");
    const unread = selectNotificationUnreadCount(getState());
    const event = reportNotificationsViewed({ unreadCount: unread });
    dispatch(analyticsEvent(event));
  };
};

export const singleNotificationTapped = (
  id: NotificationId,
  context: NotificationContext,
  from: "OS" | "App Notification Center"
): SyncThunkAction<void> => {
  return (dispatch, getState) => {
    log.info("Thunk: singleNotificationTapped");
    dispatch(notificationTapped(id));

    const badgeCount = selectNotificationUnreadCount(getState());
    setBadgeCountAsync(badgeCount).catch(err => {
      log.errorCaught("Unexpected error setting badge count in singleNotificationTapped", err);
    });

    if (badgeCount === 0) {
      dispatch(markAllNotificationsRead());
    }

    const event = reportNotificationTapped({ id, context, from });
    dispatch(analyticsEvent(event));
  };
};

export const markAllNotificationsRead = (): SyncThunkAction<void> => {
  return (dispatch, getState, deps) => {
    log.info("Thunk: markAllNotificationsRead");
    const state = getState();

    if (state.notifications.ids.length === 0) {
      return;
    }

    // get the newest notification and use that timestamp
    // they are sorted by ts desc
    const newestNotificationId = state.notifications.ids[0]!;
    const lastRead = state.notifications.entities[newestNotificationId]?.ts;

    // lastRead should always be defined unless our state is hosed
    if (lastRead && lastRead !== state.notifications.lastReadTime) {
      // this will mark all as read locally
      dispatch(markNotificationsRead(lastRead));

      setBadgeCountAsync(0).catch(err => {
        log.errorCaught("Unexpected error setting notification badge count to 0 in markAllNotificationsRead", err);
      });

      // update the server. Minor impact if this fails, so just fail silently.
      deps.api
        .withThrow()
        .updateNotificationLastReadTime({ lastRead })
        .catch(err => {
          log.errorCaught("Unexpected error calling updateNotificationsLastReadTime", err);
        });
    }
  };
};
