import React, { useCallback } from "react";
import { FlatList, ListRenderItem, StyleSheet, View } from "react-native";
import { Spacer } from "./Spacer";
import { globalStyleConstants } from "./GlobalStyles";
import { PhotoRef } from "@eatbetter/photos-shared";
import { UrlString } from "@eatbetter/common-shared";
import { Photo } from "./Photo";
import { TBody } from "./Typography";
import { ButtonRectangle } from "./Buttons";
import { openWebpage } from "../lib/util/WebUtil";

export const bookCarouselHeight = 148;

const strings = {
  shop: "Shop",
  by: (author: string) => `by ${author}`,
};

type RenderListPart = (props: { highlighted?: boolean; leadingItem?: BookCardProps }) => React.ReactElement;
type RenderItem = ListRenderItem<BookCardProps>;
type KeyExtractor = (item: BookCardProps, index: number) => string;

interface BookCarousel {
  books: BookCardProps[];
}

export const BookCarousel = React.memo((props: BookCarousel) => {
  const renderItem: RenderItem = useCallback(({ item }) => {
    return <BookCard {...item} />;
  }, []);

  const keyExtractor: KeyExtractor = useCallback(item => `${item.title}_${item.purchaseLink}`, []);

  const renderItemSeparator: RenderListPart = useCallback(() => {
    return <Spacer horizontal={3} />;
  }, []);

  const renderListHeader: RenderListPart = useCallback(() => {
    return <Spacer horizontal={globalStyleConstants.defaultPadding} unit="pixels" />;
  }, []);

  const renderListFooter: RenderListPart = useCallback(() => {
    return <Spacer horizontal={globalStyleConstants.defaultPadding} unit="pixels" />;
  }, []);

  return (
    <FlatList
      horizontal
      data={props.books}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      ItemSeparatorComponent={renderItemSeparator}
      ListHeaderComponent={renderListHeader}
      ListFooterComponent={renderListFooter}
      showsHorizontalScrollIndicator={false}
    />
  );
});

interface BookCardProps {
  title: string;
  author: string;
  photo: PhotoRef;
  purchaseLink: UrlString;
}

const BookCard = React.memo((props: BookCardProps) => {
  const onPressPurchase = useCallback(() => {
    openWebpage(props.purchaseLink);
  }, [props.purchaseLink]);

  return (
    <View style={styles.bookCard}>
      <View style={{ width: 96 }}>
        <Photo style="flexed" source={props.photo} resizeMode="contain" sourceSize="w1290" />
      </View>
      <Spacer horizontal={2} />
      <View style={{ maxWidth: 192 }}>
        <TBody fontWeight="normal" numberOfLines={2}>
          {props.title}
        </TBody>
        <Spacer vertical={0.25} />
        {/* <TSecondary numberOfLines={2}>{strings.by(props.author)}</TSecondary> */}
        <Spacer vertical={1} />
        <View style={{ width: 128 }}>
          <ButtonRectangle type="secondary" size="small" title={strings.shop} onPress={onPressPurchase} noFeedback />
        </View>
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  bookCard: {
    height: bookCarouselHeight,
    flexDirection: "row",
    alignItems: "center",
  },
});
