import { useSelector } from "../redux/Redux";
import { CurrentEnvironment } from "../../CurrentEnvironment";

export const useShowPaywallStatusPill = () => {
  return useSelector(s => {
    if (CurrentEnvironment.configEnvironment() === "prod" && !s.system.systemSettings.superAdmin) {
      return false;
    }
    return s.debug.showPaywallStatusPill;
  });
};
