import { ErrorData } from "./Errors";

export class StructuredError<T extends ErrorData<any, any>> extends Error {
  data: T;

  constructor(err: T) {
    super(err.code);
    this.name = StructuredError.name;
    this.data = err;
  }
}

export function isStructuredError<T extends ErrorData<any, any>>(e: unknown): e is StructuredError<T> {
  return e instanceof StructuredError;
}
