import { getSocialEntityId, SocialPostId } from "@eatbetter/posts-shared";
import React, { useCallback, useMemo } from "react";
import { ScreenView } from "../components/ScreenView";
import { usePostLikes } from "../lib/social/SocialSelectors";
import { PostDetailScreenProps } from "../navigation/NavTree";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { HeaderProps } from "../components/ScreenHeaders";
import { getFollowableEntities, EntitySelectedHandler, SelectEntity } from "../components/SelectEntity";
import { navToEntityScreen } from "../lib/social/SocialThunks";
import { useDispatch } from "../lib/redux/Redux";

const strings = {
  screenHeader: "Likes",
  follow: "Follow",
};

export const LikesScreen = withScreenContainer(
  "LikesScreen",
  (props: PostDetailScreenProps) => {
    return React.createElement<Props>(LikeScreenComponent, {
      postId: props.postId,
    });
  },
  {
    serializer: {
      postId: s => s,
    },
    parser: {
      postId: s => s as SocialPostId,
    },
  }
);

interface Props {
  postId: SocialPostId;
}

const LikeScreenComponent = (props: Props) => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const likes = usePostLikes(props.postId, "followed", true);

  const followableUserList = useMemo(
    () => [
      {
        entities: getFollowableEntities(
          likes.map(i => i.user),
          "postLikes"
        ),
      },
    ],
    [likes]
  );

  const header = useMemo<HeaderProps>(() => {
    return { type: "default", title: strings.screenHeader };
  }, []);

  const onSelectUser = useCallback<EntitySelectedHandler>(
    item => {
      dispatch(navToEntityScreen(getSocialEntityId(item.entity), screen.nav, "postLikes"));
    },
    [screen.nav]
  );

  return (
    <ScreenView header={header} paddingHorizontal={false} paddingVertical={"headerAndBottomTabBar"} scrollView={false}>
      <SelectEntity
        type="flexed"
        entityData={followableUserList}
        onSelectEntity={onSelectUser}
        waitingForResults={!likes}
      />
    </ScreenView>
  );
};
