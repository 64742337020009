import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import {
  ReceivedServerData,
  ServerData,
  serverDataErrored,
  serverDataReceived,
  serverDataRequested,
} from "../redux/ServerData";
import { NotificationId, Notification, NextNotificationsStart, Notifications } from "@eatbetter/users-shared";
import { EpochMs } from "@eatbetter/common-shared";

export interface NotificationsState extends EntityState<Notification, string> {
  newNotificationsMeta: ServerData<{}>;
  oldNotificationsMeta: ServerData<{}>;
  lastReadTime?: EpochMs;
  next?: NextNotificationsStart;
  read: NotificationId[];
}

const notificationsAdapter = createEntityAdapter<Notification, string>({
  selectId: n => n.idempotencyId,
  sortComparer: (a, b) => b.ts - a.ts,
});

const initialState: NotificationsState = notificationsAdapter.getInitialState({
  newNotificationsMeta: {},
  oldNotificationsMeta: {},
  read: [],
});

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,

  reducers: create => ({
    notificationTapped: create.reducer((state, action: PayloadAction<NotificationId>) => {
      state.read.push(action.payload);
    }),

    newNotificationsRequested: create.reducer((state, action: PayloadAction<EpochMs>) => {
      serverDataRequested(state.newNotificationsMeta, action.payload);
    }),

    newNotificationsReceived: create.reducer((state, action: PayloadAction<ReceivedServerData<Notifications>>) => {
      serverDataReceived(state.newNotificationsMeta, { data: {}, startTime: action.payload.startTime });
      if (action.payload.data.lastRead) {
        state.lastReadTime = action.payload.data.lastRead;
      }

      state.next = action.payload.data.next;

      // to keep things simple, just blow away everything we have when fetching new.
      notificationsAdapter.setAll(state, action.payload.data.notifications);
    }),

    newNotificationsErrored: create.reducer(state => {
      serverDataErrored(state.newNotificationsMeta);
    }),

    olderNotificationsRequested: create.reducer((state, action: PayloadAction<EpochMs>) => {
      serverDataRequested(state.oldNotificationsMeta, action.payload);
    }),

    olderNotificationsReceived: create.reducer((state, action: PayloadAction<ReceivedServerData<Notifications>>) => {
      serverDataReceived(state.oldNotificationsMeta, { data: {}, startTime: action.payload.startTime });
      notificationsAdapter.setMany(state, action.payload.data.notifications);
      state.next = action.payload.data.next;
    }),

    olderNotificationsErrored: create.reducer(state => {
      serverDataErrored(state.oldNotificationsMeta);
    }),

    markNotificationsRead: create.reducer((state, action: PayloadAction<EpochMs>) => {
      state.lastReadTime = action.payload;
      state.read = [];
    }),
  }),
});

export const {
  markNotificationsRead,
  newNotificationsErrored,
  newNotificationsReceived,
  newNotificationsRequested,
  notificationTapped,
  olderNotificationsErrored,
  olderNotificationsReceived,
  olderNotificationsRequested,
} = notificationsSlice.actions;

export const notificationsReducer = notificationsSlice.reducer;
export const notificationsAdapterSelectors = notificationsAdapter.getSelectors();
