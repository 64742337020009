//https://stackoverflow.com/questions/59050071/narrow-return-type-of-find-from-discriminated-union-array

/**
 * Can be used as a predicate in find/filter to return the correct type from a discriminated
 * union when filtering/finding based on the discriminant key. An array can be passed for
 * the value to filter on multiple types.
 */
export function discriminate<K extends PropertyKey, V extends string | number | boolean>(
  discriminantKey: K,
  discriminantValue: V | V[]
) {
  return <T extends Record<K, any>>(obj: T & Record<K, V extends T[K] ? T[K] : V>): obj is Extract<T, Record<K, V>> => {
    const value = Array.isArray(discriminantValue) ? discriminantValue : [discriminantValue];
    return value.includes(obj[discriminantKey]);
  };
}
