import { HeaderProps, useHeaderScrollAnimation, useScreenHeaderDimensions } from "../components/ScreenHeaders";
import { ScreenView } from "../components/ScreenView";
import { ExternalSharedBookRecipeDetailScreenProps } from "../navigation/NavTree";
import { withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { globalStyleConstants } from "../components/GlobalStyles";
import { View } from "react-native";
import { ButtonRectangle } from "../components/Buttons";
import { useCallback, useEffect, useMemo } from "react";
import { bottomActionBarConstants } from "../components/BottomActionBar";
import { Spacer } from "../components/Spacer";
import { useAuthedUser } from "../lib/system/SystemSelectors";
import Animated from "react-native-reanimated";
import { Photo } from "../components/Photo";
import { useDispatch } from "../lib/redux/Redux";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { reportSharedBookRecipeDetailsViewedOnWeb } from "../lib/analytics/AnalyticsEvents";
import { useShareActions } from "../lib/share/UseShareActions";
import { openWebpage } from "../lib/util/WebUtil";
import { ShareRecipeCard } from "../components/ShareRecipeCard";

const strings = {
  screenHeader: "Book recipe",
  buyButton: "Buy this book",
  save: "Save recipe",
};

export const ExternalSharedBookRecipeDetailScreen = withNonNavigableScreenContainer(
  "ExternalSharedBookRecipeDetailScreen",
  (props: ExternalSharedBookRecipeDetailScreenProps) => {
    const authedUser = useAuthedUser();
    const dispatch = useDispatch();
    const { headerHeight } = useScreenHeaderDimensions();

    useEffect(() => {
      dispatch(
        analyticsEvent(
          reportSharedBookRecipeDetailsViewedOnWeb({
            recipeId: props.recipeId,
            recipeInfo: props.recipe,
          })
        )
      );
    }, []);

    const { onPressSignUp } = useShareActions({
      sharingUserId: props.sharingUserId,
      sharedRecipeId: props.recipeId,
      sharedSourceRecipeID: props.sourceRecipeId,
      viewingUserHasRecipe: props.viewingUserHasRecipe,
    });

    const onPressBookRecipeCard = useCallback(() => {}, []);

    const onPressPurchase = useCallback(() => {
      if (props.recipe.book?.purchaseLink) {
        openWebpage(props.recipe.book.purchaseLink);
      }
    }, [props.recipe.book?.purchaseLink]);

    const [animationProgress, onScroll] = useHeaderScrollAnimation();

    const header = useMemo<HeaderProps>(() => {
      return {
        title: props.recipe.book?.name ?? strings.screenHeader,
        type: "custom",
        style: "default",
        right: !authedUser ? { type: "signUp", onPress: onPressSignUp } : undefined,
        animationConfig: {
          animationProgress,
          disableRetract: true,
          blurBackgroundThreshold: globalStyleConstants.unitSize,
        },
      };
    }, [authedUser, onPressSignUp, animationProgress, props.recipe.book?.name]);

    return (
      <ScreenView scrollView={false} paddingHorizontal={false} paddingVertical={false} header={header}>
        <Animated.ScrollView
          contentContainerStyle={{
            paddingTop: headerHeight + globalStyleConstants.unitSize,
            paddingBottom: bottomActionBarConstants.height + 4 * globalStyleConstants.unitSize,
            paddingHorizontal: 8,
          }}
          showsVerticalScrollIndicator={false}
          onScroll={onScroll}
          scrollEventThrottle={16}
        >
          <ShareRecipeCard recipeInfo={props.recipe} onPress={onPressBookRecipeCard} />
          <Spacer vertical={1.5} />
          <View style={{ paddingHorizontal: globalStyleConstants.unitSize }}>
            <Spacer vertical={1} />
            <View style={{ width: "100%", alignItems: "center" }}>
              <Photo
                source={props.recipe.book?.photo}
                style="thumbnailXlarge"
                sourceSize="w1290"
                resizeMode="contain"
                noBorderRadius
              />
            </View>
            <Spacer vertical={2} />
            <ButtonRectangle
              type="submit"
              size="large"
              title={strings.buyButton}
              onPress={onPressPurchase}
              disabled={!props.recipe.book?.purchaseLink}
              singlePress
            />
          </View>
        </Animated.ScrollView>
      </ScreenView>
    );
  }
);
