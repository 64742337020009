import React, { useCallback } from "react";
import { RecipeCard, RecipeCardProps, recipeCardConstants } from "./RecipeCards";
import { FlatList } from "react-native-gesture-handler";
import { ListRenderItem, StyleSheet, View } from "react-native";
import { Spacer } from "../Spacer";
import { globalStyleConstants } from "../GlobalStyles";

export const recipeCardCarouselHeight = recipeCardConstants.horizontalScrollCardHeight;

interface RecipeCardCarouselProps {
  items: RecipeCardProps[];
}

type RenderListPart = (props: { highlighted?: boolean; leadingItem?: RecipeCardProps }) => React.ReactElement;
type RenderItem = ListRenderItem<RecipeCardProps>;
type KeyExtractor = (item: RecipeCardProps, index: number) => string;

export const RecipeCardCarousel = React.memo((props: RecipeCardCarouselProps) => {
  const renderItem: RenderItem = useCallback(({ item }) => {
    return <RecipeCard {...item} scrollDirection="horizontal" />;
  }, []);

  const keyExtractor: KeyExtractor = useCallback(item => {
    return item.id;
  }, []);

  const renderItemSeparator: RenderListPart = useCallback(() => {
    return <Spacer horizontal={globalStyleConstants.minPadding} unit="pixels" />;
  }, []);

  const renderListHeader: RenderListPart = useCallback(() => {
    return <Spacer horizontal={globalStyleConstants.minPadding} unit="pixels" />;
  }, []);

  const renderListFooter: RenderListPart = useCallback(() => {
    return <Spacer horizontal={globalStyleConstants.minPadding} unit="pixels" />;
  }, []);

  return (
    <View style={styles.wrap}>
      <FlatList
        style={styles.list}
        horizontal
        data={props.items}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        ItemSeparatorComponent={renderItemSeparator}
        ListHeaderComponent={renderListHeader}
        ListFooterComponent={renderListFooter}
        showsHorizontalScrollIndicator={false}
      />
    </View>
  );
});

const styles = StyleSheet.create({
  wrap: {
    height: recipeCardCarouselHeight,
  },
  list: {
    overflow: "visible",
  },
});
