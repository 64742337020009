import { registerRootComponent } from 'expo';

// setImmediate is not available in all (any modern?) browsers, so fall back to setTimeout
// if it's not present.
// This was addressed to fix an issue when we upgraded react-native. There are several shims/libraries
// such as ric/setImmediate that claim to replicate the behavior, but taking the easy way for now.
if (!global.setImmediate) {
  global.setImmediate = global.setTimeout;
}

import App from './src/AdminApp';

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in the Expo client or in a native build,
// the environment is set up appropriately
registerRootComponent(App);
