import React, { useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import {
  FlatList,
  FlatListProps,
  LayoutAnimation,
  LayoutChangeEvent,
  ListRenderItem,
  StyleSheet,
  View,
  ViewToken,
} from "react-native";
import {
  useDebugFeedScrollText,
  useFeedHasNewerPosts,
  useFeedIsScrolled,
  usePostIds,
} from "../../lib/social/SocialSelectors";
import { SocialPostId } from "@eatbetter/posts-shared";
import { SocialPostComponent } from "./SocialPost";
import { globalStyleConstants } from "../GlobalStyles";
import { SmallOvalButton } from "../Buttons";
import { ContainerFadeIn } from "../Containers";
import { getPullToRefresh } from "../PullToRefresh";
import { scrolledFromTop, scrolledToTop, SocialFeedType } from "../../lib/social/SocialSlice";
import { useDispatch } from "../../lib/redux/Redux";
import { log } from "../../Log";
import { reportFeedScrolled } from "../../lib/analytics/AnalyticsEvents";
import { useScreen } from "../../navigation/ScreenContainer";
import { TSecondary } from "../Typography";
import { Spacer } from "../Spacer";
import Reanimated, {
  Extrapolation,
  ScrollEvent,
  interpolate,
  runOnJS,
  useAnimatedRef,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
} from "react-native-reanimated";
import { FlexedSpinner } from "../Spinner";
import { bottomNop, bottomThrow, switchReturn } from "@eatbetter/common-shared";
import { useScrollToTop } from "@react-navigation/native";
import { useScreenElementDimensions } from "../ScreenView";
import { Separator } from "../Separator";
import { useTrackScrollSession } from "../../lib/util/UseTrackScrollSession";
import { analyticsEvent } from "../../lib/analytics/AnalyticsThunks";

export type ViewableItemsChangedHandler = (info: {
  viewableItems: Array<ViewToken>;
  changed: Array<ViewToken>;
}) => void;
export type OnScrollWorklet = (e: ScrollEvent) => void;

type FeedEmptyState = { type: "loading" } | { type: "empty"; emptyStateComponent: string | React.ReactElement };
type FeedItem = { type: "post"; postId: SocialPostId } | { type: "module"; module: InlinedModule };

export interface InlinedModule {
  key: string;
  renderModuleComponent: () => React.ReactNode;
}

// Feed indexes where inlined modules are slotted when present
const inlinedModuleIndexes = [2, 9];

export interface SocialFeedImperativeHandle {
  scrollToTop: () => void;
  scrollToFirstPost: () => void;
  scrollTo: (y: number, animated?: boolean) => void;
  scrollToIndex: (index: number) => void;
}

export interface SocialFeedProps {
  feed: SocialFeedType;
  onEndReached: () => void;
  onPullToRefresh: () => Promise<unknown>;
  feedEmptyStateComponent: string | React.ReactElement;
  headerComponent?: FlatListProps<unknown>["ListHeaderComponent"];
  onScroll?: OnScrollWorklet;
  onEndDrag?: OnScrollWorklet;
  onMomentumEnd?: OnScrollWorklet;
  paddingTop: "none" | "headerHeight" | number;
  paddingTopScrolled?: "none" | "headerHeight" | number;
  paddingBottom: "none" | "bottomTabBarHeight" | number;
  footerComponent?: FlatListProps<unknown>["ListFooterComponent"];
  inlinedModules?: InlinedModule[];
}

export const SocialFeed = React.forwardRef<SocialFeedImperativeHandle, SocialFeedProps>((props, ref) => {
  const screen = useScreen();
  const dispatch = useDispatch();
  const listRef = useAnimatedRef<FlatList>();
  const { postIds, loading } = usePostIds(props.feed);
  useScrollToTop(listRef);

  const { headerHeight, bottomTabBarHeight } = useScreenElementDimensions();

  const paddingTop =
    typeof props.paddingTop === "number"
      ? props.paddingTop
      : switchReturn(props.paddingTop, {
          none: 0,
          headerHeight,
        });

  const paddingBottom =
    typeof props.paddingBottom === "number"
      ? props.paddingBottom
      : switchReturn(props.paddingBottom, {
          none: 0,
          bottomTabBarHeight,
        });

  const paddingTopScrolled = props.paddingTopScrolled
    ? typeof props.paddingTopScrolled === "number"
      ? props.paddingTopScrolled
      : switchReturn(props.paddingTopScrolled, {
          none: 0,
          headerHeight,
        })
    : paddingTop;

  useEffect(() => {
    if (screen.nav.focused) {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    }
  }, [postIds]);

  useImperativeHandle(
    ref,
    () => {
      return {
        scrollTo: (y: number, animated = false) => listRef.current?.scrollToOffset({ offset: y, animated }),
        scrollToTop: () => listRef.current?.scrollToOffset({ offset: 0, animated: true }),
        scrollToFirstPost: () =>
          postIds.length > 0 &&
          listRef.current?.scrollToIndex({
            index: 0,
            animated: true,
            viewOffset: paddingTop,
          }),
        scrollToIndex: (index: number) =>
          postIds.length > 0 &&
          listRef.current?.scrollToIndex({ index, animated: false, viewOffset: paddingTopScrolled }),
      };
    },
    [postIds, paddingTop]
  );

  // when the feed is in a scrolled state and we receive a new post, we show the user a "new posts" button
  // at the top of the screen. When the user taps it, the scroll/refresh is not instant.
  // When the user taps the button, we set this to true.
  // When the scroll position is back to zero, we set this to false.
  const [waitingForScroll, setWaitingForScroll] = useState(false);
  const stateIsScrolled = useFeedIsScrolled(props.feed);
  const debugFeedScrollEnabled = !!useDebugFeedScrollText();
  const haveNewerPosts = useFeedHasNewerPosts(props.feed) || debugFeedScrollEnabled;
  const showNewPostsButton = stateIsScrolled && haveNewerPosts;
  const [listHeaderHeight, setListHeaderHeight] = useState(0);

  const onScrollJs = (contentOffsetY: number) => {
    const isScrolled = contentOffsetY > listHeaderHeight;
    if (isScrolled !== stateIsScrolled) {
      dispatch(isScrolled ? scrolledFromTop(props.feed) : scrolledToTop(props.feed));
      if (!isScrolled) {
        setWaitingForScroll(false);
      }
    }
  };

  // https://github.com/software-mansion/react-native-reanimated/issues/4942#issuecomment-1695336905
  const { onScroll: onScrollWorklet, onEndDrag, onMomentumEnd } = props;
  const scrollY = useSharedValue(0);

  const onScroll = useAnimatedScrollHandler({
    onScroll: e => {
      scrollY.value = e.contentOffset.y;
      if (onScrollWorklet) {
        onScrollWorklet(e);
      }
      runOnJS(onScrollJs)(e.contentOffset.y);
    },
    onEndDrag,
    onMomentumEnd,
  });

  const scrollToTop = useCallback(() => {
    if (listRef.current) {
      setWaitingForScroll(true);

      // we were seeing behavior where tapping the button didn't always scroll, but it did
      // start the spinner. This implies that listRef.current was set. Adding a second
      // call in a 0 ms timeout.
      listRef.current.scrollToOffset({ offset: 0, animated: true });
      setTimeout(() => listRef.current?.scrollToOffset({ offset: 0, animated: true }), 0);
    }
  }, [listRef, setWaitingForScroll]);

  const reportScrolledEvent = useCallback(
    (indexReached: number) => {
      dispatch(analyticsEvent(reportFeedScrolled({ indexReached, feed: props.feed })));
    },
    [dispatch, props.feed]
  );

  const { onViewableItemsChanged } = useTrackScrollSession({ isScrolled: stateIsScrolled, reportScrolledEvent });

  const listData = useMemo(() => {
    if (loading) {
      return [];
    }

    const feedItems = postIds.map<FeedItem>(i => ({ type: "post", postId: i }));

    if (!props.inlinedModules) {
      return feedItems;
    }

    for (let i = 0; i < props.inlinedModules.length; i++) {
      const targetFeedIdx = inlinedModuleIndexes[i];

      if (targetFeedIdx === undefined) {
        log.error("Unsupported inline module position", { inlinedModuleIndexes, lookupIndex: i, targetFeedIdx });
        continue;
      }

      const module = props.inlinedModules[i];
      if (!module) {
        log.error("Current module is falsy, this should never happen", {
          inlinedModuleCount: props.inlinedModules.length,
          currentIndex: i,
        });
        continue;
      }

      const feedModuleItem: FeedItem = { type: "module", module };
      if (targetFeedIdx > feedItems.length) {
        feedItems.push(feedModuleItem);
      } else {
        feedItems.splice(targetFeedIdx, 0, feedModuleItem);
      }
    }

    return feedItems;
  }, [loading, postIds, props.inlinedModules]);

  const renderItem: ListRenderItem<FeedItem> = useCallback(({ item }) => {
    switch (item.type) {
      case "post": {
        return (
          <>
            <Spacer vertical={0.5} />
            <SocialPostComponent postOrId={item.postId} />
          </>
        );
      }
      case "module": {
        return <View key={item.module.key}>{item.module.renderModuleComponent()}</View>;
      }
      default:
        bottomThrow(item);
    }
  }, []);

  const keyExtractor = useCallback((item: FeedItem, index: number) => {
    switch (item.type) {
      case "post": {
        return item.postId;
      }
      case "module": {
        return item.module.key;
      }
      default:
        bottomNop(item);
        return `unknown-item-type-${index}`;
    }
  }, []);

  const contentContainerStyle = useMemo(
    // Flex when loading so that the spinner is centered
    () => [{ paddingBottom, paddingTop }, loading ? { flex: 1 } : {}],
    [paddingBottom, paddingTop, loading]
  );

  const refreshControl = useMemo(
    () => getPullToRefresh(props.onPullToRefresh, paddingTop),
    [props.onPullToRefresh, paddingTop]
  );

  const listEmptyComponent = useMemo(() => {
    const feedStatus: FeedEmptyState = loading
      ? { type: "loading" }
      : { type: "empty", emptyStateComponent: props.feedEmptyStateComponent };
    return <EmptyState feedStatus={feedStatus} />;
  }, [loading, props.feedEmptyStateComponent]);

  const onLayoutListHeader = useCallback(
    (e: LayoutChangeEvent) => {
      setListHeaderHeight(e.nativeEvent.layout.height);
    },
    [setListHeaderHeight]
  );

  const listHeaderComponent = useMemo(() => {
    return (
      <View onLayout={onLayoutListHeader}>
        <>{props.headerComponent}</>
      </View>
    );
  }, [props.headerComponent, onLayoutListHeader]);

  const listFooterComponent = useMemo(() => {
    if (!props.footerComponent) {
      return null;
    }

    return (
      <>
        {postIds.length === 0 && (
          <>
            <Spacer vertical={2} />
            <View style={{ paddingHorizontal: globalStyleConstants.minPadding }}>
              <Separator orientation="row" />
            </View>
          </>
        )}
        {props.footerComponent}
      </>
    );
  }, [postIds.length, props.footerComponent]);

  const newPostsButtonTop = paddingTop + listHeaderHeight + globalStyleConstants.unitSize;
  const newPostsButtonTopScrolledY = newPostsButtonTop - paddingTopScrolled - globalStyleConstants.unitSize;

  const newPostsButtonScrollAnimation = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: interpolate(scrollY.value, [0, newPostsButtonTopScrolledY], [0, -newPostsButtonTopScrolledY], {
            extrapolateRight: Extrapolation.CLAMP,
          }),
        },
      ],
    };
    // Despite Reanimated docs stating that deps are only necessary for use without the babel plugin on web, they do have functional behavior.
    // By default, animation callbacks are stable across renders. The new posts button conditionally renders based on the showNewPosts flag.
    // Without setting the dependency here, the animation does not run when the new posts button shows up and so the initial offset is
    // incorrect (but would immediately correct itself upon scrolling). Setting the dep here ensures that the callback is rebuilt and runs
    // when the new posts button appears.
  }, [showNewPostsButton]);

  return (
    <>
      {showNewPostsButton && (
        <Reanimated.View
          style={[
            {
              position: "absolute",
              zIndex: 100,
              alignSelf: "center",
            },
            { top: newPostsButtonTop },
            newPostsButtonScrollAnimation,
          ]}
        >
          <SmallOvalButton onPress={scrollToTop} title={"New Posts"} waiting={waitingForScroll} />
        </Reanimated.View>
      )}
      {/* The "flex: 1" is required for scrolling to work on web: https://github.com/necolas/react-native-web/issues/1436#issuecomment-612845122 */}
      <ContainerFadeIn style={{ flex: 1 }}>
        <Reanimated.FlatList
          ref={listRef}
          contentContainerStyle={contentContainerStyle}
          data={listData}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          refreshControl={refreshControl}
          onScroll={onScroll}
          scrollEventThrottle={16}
          onEndReached={props.onEndReached}
          onEndReachedThreshold={10}
          onViewableItemsChanged={onViewableItemsChanged}
          ListHeaderComponent={listHeaderComponent}
          ListEmptyComponent={listEmptyComponent}
          showsVerticalScrollIndicator={false}
          ListFooterComponent={loading ? undefined : listFooterComponent}
        />
      </ContainerFadeIn>
    </>
  );
});

const EmptyState = React.memo((props: { feedStatus: FeedEmptyState }) => {
  switch (props.feedStatus.type) {
    case "empty": {
      return (
        <View style={styles.emptyStateWrap}>
          {typeof props.feedStatus.emptyStateComponent === "string" && (
            <TSecondary opacity="medium">{props.feedStatus.emptyStateComponent}</TSecondary>
          )}
          {typeof props.feedStatus.emptyStateComponent !== "string" && props.feedStatus.emptyStateComponent}
        </View>
      );
    }
    case "loading": {
      return <FlexedSpinner debugText="SocialFeed (loading)" />;
    }
    default: {
      bottomThrow(props.feedStatus);
    }
  }
});

const styles = StyleSheet.create({
  emptyStateWrap: {
    paddingTop: 2 * globalStyleConstants.unitSize,
    paddingHorizontal: 20,
  },
});
