import { StyleSheet, View } from "react-native";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import React, { useEffect, useRef, useState } from "react";
import { ScreenView } from "../components/ScreenView";
import { IconEmail } from "../components/Icons";
import { globalStyleColors } from "../components/GlobalStyles";
import { Spacer } from "../components/Spacer";
import { THeading1, TSecondary } from "../components/Typography";
import { CheckYourEmailScreenProps, navTree } from "../navigation/NavTree";
import {
  useAuthStatus,
  useEmailLinkSignInAddress,
  useEmailSigninLinkPending,
  useIsAnonymousUser,
} from "../lib/system/SystemSelectors";
import { Spinner } from "../components/Spinner";
import { useDispatch } from "../lib/redux/Redux";
import { navHome } from "../navigation/NavThunks";
import { bottomLog, bottomThrow } from "@eatbetter/common-shared";
import { Alert } from "../components/Alert/Alert";
import { signInWithEmailLink } from "../lib/system/SystemThunks";
import { displayExpectedError, displayUnexpectedErrorAndLog } from "../lib/Errors";
import { log } from "../Log";
import { clearEmailSignInLink } from "../lib/system/SystemSlice";
import { NeedHelp } from "../components/SignIn";

const strings = {
  checkYourEmail: "Check your email",
  signingYouIn: "Signing you in...",
  instructions: "To continue, tap on the link we sent to",
  emailMismatchAlert: {
    title: "Finish signing in",
    message: (email: string) => `Would you like to sign in with ${email}?`,
    confirm: "Sign In",
    cancel: "Cancel",
  },
};

export const CheckYourEmailScreen = withScreenContainer(
  "CheckYourEmailScreen",
  (props: CheckYourEmailScreenProps) => {
    const screen = useScreen();
    const dispatch = useDispatch();
    const authStatus = useAuthStatus();
    const expectedEmail = useEmailLinkSignInAddress();
    const signInLink = useEmailSigninLinkPending();
    const [signInSuccess, setSignInSuccess] = useState(false);
    // the link gets cleared from redux regardless of the outcome (see below). We don't
    // want to flash the non-pending state before we nav away
    const linkPending = !!signInLink || signInSuccess;

    const isAnon = useIsAnonymousUser();
    const anonMode = useRef(isAnon).current;

    useEffect(() => {
      log.info(`CheckYourEmailScreen has redirect: ${props.redirectPath}`);
    }, [props.redirectPath]);

    // auto-confirm if the email matches what we store in redux when the flow is started.
    // Or if we only have what is stored in redux.
    // The user has to confirm if 1) they differ or 2) we have nothing in redux.
    // This is to prevent users accidentally signing in to the wrong account (Google cites this as a security risk)
    // Note that emailConfirmed will be set to true if both are undefined, but this is fine as we will redirect
    // back to sign-in
    const [emailConfirmed, setEmailConfirmed] = useState(!props.email || props.email === expectedEmail);

    useEffect(() => {
      // if we don't have the email passed in as props, and don't have it stored, we can't sign-in
      // so just go back to the sign-in screen
      if (!props.email && !expectedEmail) {
        log.warn("CheckYourEmailScreen accessed with no email in props and no email found in state");
        screen.nav.goTo("reset", navTree.get.screens.signIn);
      }
    }, [props.email, expectedEmail]);

    useEffect(() => {
      if (!signInLink) {
        return;
      }

      // if the user is anonymous, the auth status will be signed in, but they
      // still need to proceed. In either case, only move forward with the link
      // if the auth status is what we expect
      const isAnonState = isAnon && authStatus === "signedIn";
      const isNormalState = !isAnon && authStatus === "signedOut";
      if (!isAnonState && !isNormalState) {
        return;
      }

      const email = props.email ?? expectedEmail;
      if (!email) {
        // we'll redirect to sign-in above
        return;
      }

      if (!emailConfirmed) {
        Alert.alert(strings.emailMismatchAlert.title, strings.emailMismatchAlert.message(email), [
          {
            type: "cancel",
            text: strings.emailMismatchAlert.cancel,
            onPress: () => {
              dispatch(clearEmailSignInLink());
              screen.nav.goTo("reset", navTree.get.screens.signIn);
            },
          },
          {
            type: "save",
            text: strings.emailMismatchAlert.confirm,
            onPress: () => setEmailConfirmed(true),
          },
        ]);
        return;
      } else {
        dispatch(signInWithEmailLink(email, signInLink, screen.nav))
          .then(res => {
            if (res.success) {
              setSignInSuccess(true);
            } else {
              switch (res.result.code) {
                case "invalidLinkOrEmail":
                  displayExpectedError("Looks like that link is invalid or expired. Please try again.");
                  break;
                case "anonLinkFailedCredsInUse":
                case "anonLinkNotSupported":
                  // these are handled in the thunk;
                  break;
                default:
                  bottomThrow(res.result.code);
              }
            }
          })
          .catch(err => {
            displayUnexpectedErrorAndLog("Error calling signInWithEmailLink", err);
          })
          .finally(() => {
            dispatch(clearEmailSignInLink());
          });
      }
    }, [signInLink, props.email, expectedEmail, emailConfirmed, authStatus, isAnon]);

    useEffect(() => {
      switch (authStatus) {
        case "signedIn":
          if (!anonMode) {
            // in anon mode, we are signedIn, so we don't want to redirect until the user is no longer anonymous,
            // at which point their status should change to signeedInNoAccount
            dispatch(navHome(screen.nav, "checkYourEmailScreen", props.redirectPath));
          }
          break;
        case "signedInNoAccount":
          dispatch(navHome(screen.nav, "checkYourEmailScreen", props.redirectPath));
          break;
        case "pending":
        case "signedOut":
        case "signingOut":
          //nop
          break;
        default:
          bottomLog(authStatus, "CheckYourEmailScreen authStatus useEffect");
      }
    }, [authStatus]);

    return (
      <ScreenView
        scrollView={false}
        paddingHorizontal={false}
        paddingVertical={false}
        header={{ type: "default", title: "", backgroundColor: "transparent" }}
      >
        {/* Three vertical blocks. Main content is centered in 2nd block (so centered overall on the screen) */}
        <View style={{ flex: 1 }} />
        <View style={styles.wrap}>
          {!linkPending && (
            <View>
              <IconEmail opacity="opaque" strokeWidth={1.5} size={72} />
              <View style={styles.badge} />
            </View>
          )}
          <Spacer vertical={1} />
          <THeading1 fontWeight="medium">{linkPending ? strings.signingYouIn : strings.checkYourEmail}</THeading1>
          <Spacer vertical={1} />
          {linkPending && <Waiting />}
          {!linkPending && (
            <View style={styles.instructions}>
              <TSecondary align="center" opacity="medium">
                <TSecondary>{strings.instructions}</TSecondary>
                <TSecondary> </TSecondary>
                <TSecondary fontWeight="medium">{props.email}</TSecondary>
              </TSecondary>
            </View>
          )}
        </View>
        <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
          <NeedHelp />
        </View>
      </ScreenView>
    );
  },
  {
    serializer: {
      email: { optional: true, fn: s => s },
      redirectPath: { optional: true, fn: s => s },
    },
    parser: {
      email: { optional: true, fn: s => s },
      redirectPath: { optional: true, fn: s => s },
    },
  }
);

const Waiting = React.memo(() => {
  return (
    <>
      <Spacer vertical={1} />
      <Spinner />
    </>
  );
});

const styles = StyleSheet.create({
  wrap: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  badge: {
    position: "absolute",
    top: -4,
    right: -6,
    height: 32,
    width: 32,
    borderRadius: 16,
    borderWidth: 5,
    borderColor: globalStyleColors.colorGrey,
    backgroundColor: globalStyleColors.colorAccentWarm,
  },
  instructions: {
    width: 250,
  },
});
