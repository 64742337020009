/**
 * Get the full filename of the file that called the current function. This
 * should be used *only* for scripting purposes that run locally or on CI.
 */
export function getCallingFile(): string {
  const r = /\(([^:]*).*\)/;
  const err = new Error();
  /*
    'Error: ',
  '    at Object.getCallingFile (/Users/jacob/Source/eatbetter/packages/common/shared/src/Util.ts:35:15)',
  '    at Object.handler (/Users/jacob/Source/eatbetter/packages/common/server/src/serverless/LambdaConfig.ts:27:23)',
  '    at Object.<anonymous> (/Users/jacob/Source/eatbetter/packages/deployables/serverless/Serverless.ts:13:38)',
   */
  // Find the stacks that have file info, and take the 3rd. The first is this file, the second is the file calling
  // this function, and the 3rd is the one we want.
  const frames = err.stack!.split("\n").filter(s => r.test(s));
  const frame = frames[2];
  const fileName = r.exec(frame!)?.[1];

  if (!fileName) {
    throw new Error("Could not parse filename from stack");
  }

  return fileName;
}
