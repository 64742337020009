export class ValueCounter<T = string> {
  private readonly getKey: (v: T) => string;
  private readonly counter: Record<string, number> = {};
  constructor(args: { getKey: (v: T) => string }) {
    this.getKey = args.getKey;
  }

  addToCount = (v: T): void => {
    const key = this.getKey(v);
    if (!this.counter[key]) {
      this.counter[key] = 0;
    }

    this.counter[key]++;
  };

  getSortedValues = (): Record<string, number> => {
    return Object.fromEntries(Object.entries(this.counter).sort((a, b) => b[1] - a[1]));
  };
}
