import { ErrorData, isStructuredError, StructuredError } from "@eatbetter/common-shared";

export type SocialPostConflictError = ErrorData<"social/postConflict">;
export type SocialPostNotFoundError = ErrorData<"social/postNotFound">;
export type SocialPostUpdateByUserNotAllowed = ErrorData<"social/updateByUserNotAllowed">;
export type SocialProfileNotFoundError = ErrorData<"social/profileNotFound">;

export type SocialErrorTypes =
  | SocialPostConflictError
  | SocialPostNotFoundError
  | SocialPostUpdateByUserNotAllowed
  | SocialProfileNotFoundError;

export function isStructuredSocialError(err: any): err is StructuredError<SocialErrorTypes> {
  return isStructuredError(err) && err.data.code.startsWith("social/");
}
