import { ThunkAction } from "../AdminRedux";
import {
  ingredientAdded,
  ingredientCategoriesReceived,
  ingredientDeleted,
  ingredientKnownDisambiguationTypesReceived,
  ingredientsReceived,
  ingredientUpdated,
  truthSetPhraseAddedOrUpdated,
  truthSetPhrasesReceived,
  truthSetPhraseUpdated,
  unitMeasurementTypesReceived,
} from "./DataManagerSlice";
import { FieldToVerify, ItemWithPhrases, PhraseItemType } from "@eatbetter/items-data";
import { DataEnvelope, Envelope } from "@eatbetter/common-shared";
import { DisambiguationItemId, ShoppableItemId } from "@eatbetter/items-shared";
import { ParsedIngredient } from "@eatbetter/items-server";
import { SetWaitingHandler } from "@eatbetter/ui-shared/dist";

export const loadIngredients = (): ThunkAction<void> => {
  return async (dispatch, _, deps) => {
    const res = await deps.dataManagerClient.getIngredients();

    handleError(res);
    const { items, categories, knownDisambiguationTypes, unitMeasurementTypes } = res.data;
    dispatch(ingredientsReceived({ items }));
    dispatch(ingredientCategoriesReceived({ categories }));
    dispatch(ingredientKnownDisambiguationTypesReceived({ knownDisambiguationTypes }));
    dispatch(unitMeasurementTypesReceived({ unitMeasurementTypes }));
  };
};

export const addIngredient = (ingredient: ItemWithPhrases): ThunkAction<void> => {
  return async (dispatch, _, deps) => {
    const res = await deps.dataManagerClient.addIngredient({ ingredient });
    handleError(res);
    dispatch(ingredientAdded({ added: res.data.added }));
  };
};

export const updateIngredient = (
  id: ShoppableItemId | DisambiguationItemId,
  ingredient: ItemWithPhrases
): ThunkAction<void> => {
  return async (dispatch, _, deps) => {
    const res = await deps.dataManagerClient.updateIngredient({ id, ingredient });
    handleError(res);
    const { updated, associatedUpdated, deletedId } = res.data;
    dispatch(ingredientUpdated({ updated, deletedId, associatedUpdated }));
  };
};

export const deleteIngredient = (id: ShoppableItemId | DisambiguationItemId): ThunkAction<void> => {
  return async (dispatch, _, deps) => {
    const res = await deps.dataManagerClient.deleteIngredient({ id });
    handleError(res);
    dispatch(ingredientDeleted({ deletedId: res.data.deletedId }));
  };
};

export const addTruthSetPhrase = (phrase: string, type: PhraseItemType): ThunkAction<void> => {
  return async (dispatch, _, deps) => {
    const res = await deps.dataManagerClient.addTruthSetPhrase(phrase, type);
    handleError(res);
    dispatch(truthSetPhraseAddedOrUpdated(res.data));
  };
};

export const loadPhrases = (): ThunkAction<void> => {
  return async (dispatch, _, deps) => {
    const res = await deps.dataManagerClient.getTruthSetPhrases();
    handleError(res);
    const { phrases } = res.data;
    dispatch(truthSetPhrasesReceived({ phrases }));
  };
};

export const getParsedPhrase = (
  phrase: string,
  type: PhraseItemType,
  setWaiting?: SetWaitingHandler
): ThunkAction<{ local: ParsedIngredient; remote: ParsedIngredient }> => {
  return async (_dispatch, _getState, deps) => {
    try {
      setWaiting?.(true);
      const [localRes, remoteRes] = await Promise.all([
        deps.dataManagerClient.getParsedPhrase(phrase, type),
        deps.api.withThrow().parseIngredientPhrase({ phrase }),
      ]);
      handleError(localRes);
      return { local: localRes.data, remote: remoteRes.data.parsed as unknown as ParsedIngredient };
    } finally {
      setWaiting?.(false);
    }
  };
};

export const verifyTruthSetFields = (
  phrase: string,
  type: PhraseItemType,
  fields: Array<FieldToVerify>
): ThunkAction<void> => {
  return async (dispatch, _, deps) => {
    const res = await deps.dataManagerClient.verifyTruthSetPhraseFields({ phrase, fields, type });
    handleError(res);
    dispatch(truthSetPhraseUpdated({ phrase: res.data.updated }));
  };
};

function handleError<T>(res: Envelope<T>): asserts res is DataEnvelope<T> {
  if (res.error) {
    throw new Error(`${res.error.code}: ${res.error.userMessage ?? res.error.unfriendlyMessage}`);
  }
}
