import { bottomThrow } from "@eatbetter/common-shared";
import { RecipeTagCategoryList, RecipeTagManifest } from "@eatbetter/recipes-shared/src/RecipeTagTypes";
import React, { useCallback } from "react";
import { StyleSheet, View } from "react-native";
import { AppRecipeTag, RecipeTimeTag } from "../../lib/recipes/RecipesSlice";
import { globalStyleColors, globalStyleConstants } from "../GlobalStyles";
import { IconPlus } from "../Icons";
import { Pressable } from "../Pressable";
import { SectionHeading } from "../SectionHeading";
import { SelectableOval, SelectableOvalRenderBadge } from "../SelectableOval";
import { Spacer } from "../Spacer";
import { TSecondary } from "../Typography";

const strings = {
  userTagsHeader: "My Tags",
  addNewTag: "Add New",
  emptySection: "No tags.",
};

export type RecipeTimeTagCategory = {
  category: string;
  tags: RecipeTimeTag[];
};

interface TagSelectControlProps {
  userTags: string[];
  timeTags?: RecipeTimeTagCategory[];
  systemTags: RecipeTagCategoryList;
  selectedTags: AppRecipeTag[];
  renderTagLabel: (tag: AppRecipeTag) => string;
  onPressTag: (tag: AppRecipeTag) => void;
  waiting?: Record<string, boolean>;
  onPressAddTag?: () => void;
  hideEmptyUserSection?: boolean;
}

export const RecipeTagSelect = React.memo((props: TagSelectControlProps) => {
  const renderLastItem = useCallback(() => {
    return <>{!!props.onPressAddTag && <AddNewTagButton onPress={props.onPressAddTag} />}</>;
  }, [props.onPressAddTag]);

  return (
    <View>
      {!(props.hideEmptyUserSection && props.userTags.length === 0) && (
        <TagSection
          isFirst
          sectionTitle={strings.userTagsHeader}
          sectionTags={props.userTags.map(i => ({ type: "user", tag: i }))}
          selectedTags={props.selectedTags}
          renderTagLabel={props.renderTagLabel}
          onPressTag={props.onPressTag}
          waiting={props.waiting}
          renderLastItem={renderLastItem}
        />
      )}
      {props.systemTags.map(i => (
        <TagSection
          key={i.category}
          isFirst={false}
          sectionTitle={i.category}
          sectionTags={i.tags.map(i => ({ type: "system", tag: i }))}
          selectedTags={props.selectedTags}
          renderTagLabel={props.renderTagLabel}
          onPressTag={props.onPressTag}
          waiting={props.waiting}
        />
      ))}
      {!!props.timeTags &&
        props.timeTags.length > 0 &&
        props.timeTags.map(i => (
          <TagSection
            key={i.category}
            isFirst={false}
            sectionTitle={i.category}
            sectionTags={i.tags.map(i => {
              switch (i.type) {
                case "totalTime": {
                  return { type: i.type, tag: i.tag, totalTime: i.totalTime };
                }
                default:
                  bottomThrow(i.type);
              }
            })}
            selectedTags={props.selectedTags}
            renderTagLabel={props.renderTagLabel}
            onPressTag={props.onPressTag}
            waiting={props.waiting}
          />
        ))}
      <Spacer vertical={2} />
    </View>
  );
});

const AddNewTagButton = React.memo((props: { onPress: () => void }) => {
  return (
    <Pressable style={styles.addNewTagButton} onPress={props.onPress}>
      <IconPlus opacity="medium" strokeWidth={2.5} size={18} />
      <Spacer horizontal={0.25} />
      <TSecondary fontWeight="medium" opacity="medium">
        {strings.addNewTag}
      </TSecondary>
    </Pressable>
  );
});

const EmptyTagSection = React.memo(() => {
  return (
    <View style={styles.emptySection}>
      <TSecondary opacity="light">{strings.emptySection}</TSecondary>
    </View>
  );
});

const TagSection = React.memo(
  (props: {
    isFirst: boolean;
    sectionTitle: string;
    sectionTags: AppRecipeTag[];
    selectedTags: AppRecipeTag[];
    renderTagLabel: (tag: AppRecipeTag) => string;
    onPressTag: (tag: AppRecipeTag) => void;
    waiting?: Record<string, boolean>;
    renderLastItem?: () => React.ReactElement;
  }) => {
    return (
      <>
        {!props.isFirst && <Spacer vertical={2} />}
        <SectionHeading text={props.sectionTitle} noPadding />
        <Spacer vertical={1} />
        {props.sectionTags.length === 0 && <EmptyTagSection />}
        {!!props.sectionTags && (
          <SelectableTagGroup
            tags={props.sectionTags}
            onPressTag={props.onPressTag}
            selectedTags={props.selectedTags}
            renderTagLabel={props.renderTagLabel}
            waiting={props.waiting}
            renderLastTime={props.renderLastItem}
          />
        )}
      </>
    );
  }
);

export const SelectableTagGroup = React.memo(
  (props: {
    tags: AppRecipeTag[];
    renderTagLabel: (tag: AppRecipeTag) => string;
    onPressTag: (tag: AppRecipeTag) => void;
    selectedTags: AppRecipeTag[];
    waiting?: Record<string, boolean>;
    renderTagBadge?: SelectableOvalRenderBadge;
    renderLastTime?: () => React.ReactElement;
  }) => {
    return (
      <View style={styles.sectionWrap}>
        {props.tags.map(i => {
          const isSelected = props.selectedTags.some(selected => selected.type === i.type && selected.tag === i.tag);
          const isWaiting = props.waiting?.[i.tag];

          return (
            <View key={i.tag} style={styles.tagWrap}>
              <SelectableTag
                tag={i}
                renderTagLabel={props.renderTagLabel}
                onPress={props.onPressTag}
                waiting={isWaiting}
                isSelected={isSelected}
                renderBadge={props.renderTagBadge}
              />
            </View>
          );
        })}
        {props.renderLastTime?.()}
      </View>
    );
  }
);

export const SelectableTag = React.memo(
  (props: {
    tag: AppRecipeTag;
    renderTagLabel: (tag: AppRecipeTag) => string;
    onPress: (tag: AppRecipeTag) => void;
    isSelected: boolean;
    waiting?: boolean;
    disabled?: boolean;
    renderBadge?: SelectableOvalRenderBadge;
  }) => {
    const onPress = useCallback(() => {
      props.onPress(props.tag);
    }, [props.tag, props.onPress]);

    const tagLabel = props.renderTagLabel(props.tag);

    return (
      <SelectableOval
        text={tagLabel}
        isSelected={props.isSelected}
        onPress={onPress}
        waiting={props.waiting}
        disabled={props.disabled || props.waiting}
        renderBadge={props.renderBadge}
      />
    );
  }
);

export function getTagDisplayLabel(tag: AppRecipeTag, manifest: RecipeTagManifest): string {
  if (tag.type !== "system") {
    return tag.tag;
  }

  const systemTagLabel = manifest.tagDisplay[tag.tag] ?? tag.tag;

  return systemTagLabel;
}

const styles = StyleSheet.create({
  addNewTagButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "auto",
    height: 32,
    borderRadius: 16,
    paddingLeft: 0.75 * globalStyleConstants.unitSize,
    paddingRight: 1.25 * globalStyleConstants.unitSize,
    borderColor: globalStyleColors.rgba("black", "medium"),
    borderWidth: 0.5,
  },
  emptySection: {
    paddingBottom: globalStyleConstants.unitSize,
    width: "auto",
  },
  sectionWrap: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  tagWrap: {
    marginBottom: 8,
    marginRight: 8,
  },
});
