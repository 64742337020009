import React from "react";
import { TSecondary } from "./Typography";
import { globalStyleColors } from "./GlobalStyles";

export const TextLink = React.memo((props: { onPress: () => void; text: string; size?: "secondary" }) => {
  return (
    <TSecondary
      fontWeight="medium"
      onPress={props.onPress}
      suppressHighlighting
      color={globalStyleColors.colorTextLink}
    >
      {props.text}
    </TSecondary>
  );
});
