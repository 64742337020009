import { useCallback, useState } from "react";
import { View, StyleSheet, LayoutAnimation, ViewProps } from "react-native";
import { Spacer } from "./Spacer";
import { PhotoRef } from "@eatbetter/photos-shared";
import { AppAddPhotoArgs } from "../lib/Types";
import { Photo, PhotoSizeName } from "./Photo";
import { UrlString } from "@eatbetter/common-shared";
import { Pressable } from "./Pressable";
import { TTertiary } from "./Typography";
import { Opacity, globalStyleColors, globalStyleConstants } from "./GlobalStyles";
import { Spinner } from "./Spinner";
import { selectPhotoFromLibrary } from "../lib/photos/SelectPhoto";

const config = {
  photoPreviewMaxWidth: 500,
};

const strings = {
  addButtonText: "Add photo",
  editButtonText: "Change photo",
};

interface Props {
  addButtonText?: string;
  editButtonText?: string;
  hideButton?: boolean;
  photo?: { style: PhotoSizeName; photoRef?: PhotoRef };
  onPhotoPicked: (args: AppAddPhotoArgs) => void;
  align?: "center" | "left";
  disabled?: boolean;
  waiting?: boolean;
}

export function usePhotoPicker(originalPhoto?: PhotoRef) {
  const [pickedPhoto, setPickedPhoto] = useState<AppAddPhotoArgs>();

  const onPhotoPicked = useCallback(
    (args: AppAddPhotoArgs) => {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      setPickedPhoto(args);
    },
    [setPickedPhoto]
  );

  const clearPickedPhoto = useCallback(() => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setPickedPhoto(undefined);
  }, [setPickedPhoto]);

  return {
    pickedPhoto,
    photoRef: pickedPhotoToRef(pickedPhoto) ?? originalPhoto,
    onPhotoPicked,
    clearPickedPhoto,
  };
}

export function pickedPhotoToRef(pickedPhoto?: AppAddPhotoArgs): PhotoRef | undefined {
  if (!pickedPhoto) {
    return undefined;
  }

  return {
    type: "external",
    url: pickedPhoto.uri as UrlString,
  };
}

export const PhotoPicker = (props: Props) => {
  const pickPhoto = async () => {
    const result = await selectPhotoFromLibrary();

    if (result.success) {
      props.onPhotoPicked(result.result);
    }
  };

  const align: ViewProps["style"] = props.align === "left" ? { alignItems: "flex-start" } : { alignItems: "center" };

  return (
    <View>
      <Spacer vertical={1} />
      {!!props.photo && (
        <View style={align}>
          <View style={[{ width: "100%", maxWidth: config.photoPreviewMaxWidth }, align]}>
            <Photo source={props.photo.photoRef} style={props.photo.style} resizeMode="cover" sourceSize="w1290" />
          </View>
          <Spacer vertical={1} />
          {!props.hideButton && (
            <PickImageButton
              title={props.editButtonText ?? strings.editButtonText}
              onPress={pickPhoto}
              disabled={props.disabled}
              waiting={props.waiting}
            />
          )}
        </View>
      )}
      {!props.photo && !props.hideButton && (
        <PickImageButton
          title={props.addButtonText ?? strings.addButtonText}
          onPress={pickPhoto}
          disabled={props.disabled}
          waiting={props.waiting}
        />
      )}
    </View>
  );
};

const PickImageButton = (props: { title: string; onPress: () => void; disabled?: boolean; waiting?: boolean }) => (
  <View style={{ flexWrap: "wrap" }}>
    {!!props.waiting && <Spinner />}
    {!props.waiting && (
      <Pressable onPress={props.onPress} style={styles.button} disabled={props.disabled || props.waiting}>
        {!props.waiting && (
          <View style={{ opacity: props.waiting ? Opacity.transparent : Opacity.opaque }}>
            <TTertiary color={globalStyleColors.colorAccentCool} fontWeight="medium">
              {props.title}
            </TTertiary>
          </View>
        )}
      </Pressable>
    )}
  </View>
);

const styles = StyleSheet.create({
  button: {
    height: 28,
    borderRadius: 14,
    paddingHorizontal: globalStyleConstants.unitSize,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderColor: globalStyleColors.rgba("colorAction", "medium"),
    backgroundColor: "white",
  },
});
