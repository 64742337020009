import { withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { View } from "react-native";
import { Spinner } from "../components/Spinner";
import { TBody } from "../components/Typography";
import { useEffect, useState } from "react";
import { useDispatch } from "../lib/redux/Redux";
import { resetApp } from "../lib/system/SystemThunks";
import { log } from "../Log";

export const AppResetScreen = withNonNavigableScreenContainer("AppResetScreen", () => {
  const dispatch = useDispatch();
  const [done, setDone] = useState(false);

  useEffect(() => {
    dispatch(resetApp())
      .then(() => setDone(true))
      .catch(err => {
        // this is probably not going to do anything since the user is most likely logged out
        // and our log endpoint is currently authed
        log.errorCaught("Error dispatching resetApp", err);
      });
  }, []);

  useEffect(() => {}, []);
  return (
    <ScreenView>
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        {done && <TBody align="center">App reset complete! Please close and reopen the app</TBody>}
        {!done && (
          <>
            <TBody>Resetting the app...</TBody>
            <Spinner />
          </>
        )}
      </View>
    </ScreenView>
  );
});
