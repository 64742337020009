import { PropsWithChildren, useCallback, useState } from "react";
import {
  Insets,
  LayoutChangeEvent,
  PressableStateCallbackType,
  Pressable as RNPressable,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from "react-native";
import { Opacity } from "./GlobalStyles";

const config = {
  singlePressDelayMs: 500,
};

interface Props {
  onPress: () => void;
  onLayout?: (event: LayoutChangeEvent) => void;
  onLongPress?: () => void;
  onPressIn?: () => void;
  onPressOut?: () => void;
  disabled?: boolean;
  noFeedback?: boolean;
  singlePress?: boolean;
  hitSlop?: Insets | number | null;
  style?: StyleProp<ViewStyle>;
  hoveredStyleWeb?: any;
  focusedStyleWeb?: any;
}

export const Pressable = (props: PropsWithChildren<Props>) => {
  const [pressed, setPressed] = useState(false);

  const onPress = useCallback(() => {
    if (props.singlePress) {
      setPressed(true);
      setTimeout(() => setPressed(false), config.singlePressDelayMs);
    }

    props.onPress();
  }, [props.singlePress, setPressed, props.onPress]);

  const style = useCallback(
    ({ hovered, pressed, focused }: PressableStateCallbackType) => {
      const stylePressed = props.noFeedback ? {} : styles.pressed;
      const styleNotPressed = props.noFeedback ? {} : props.disabled ? styles.disabled : styles.notPressed;

      return [
        hovered && props.hoveredStyleWeb ? props.hoveredStyleWeb : undefined,
        pressed ? stylePressed : styleNotPressed,
        focused && props.focusedStyleWeb ? props.focusedStyleWeb : undefined,
        props.style ?? {},
      ];
    },
    [props.hoveredStyleWeb, props.noFeedback, props.disabled, props.focusedStyleWeb, props.style]
  );

  return (
    <RNPressable
      hitSlop={props.hitSlop}
      disabled={props.disabled || pressed}
      onPressIn={props.onPressIn}
      onPressOut={props.onPressOut}
      onPress={onPress}
      onLongPress={props.onLongPress}
      style={style}
      onLayout={props.onLayout}
    >
      {props.children}
    </RNPressable>
  );
};

const styles = StyleSheet.create({
  notPressed: {
    opacity: Opacity.opaque,
  },
  pressed: {
    opacity: Opacity.medium,
  },
  disabled: {
    opacity: Opacity.light,
  },
});

// https://github.com/necolas/react-native-web/issues/1684#issuecomment-766451866
declare module "react-native" {
  interface PressableStateCallbackType {
    hovered?: boolean;
    focused?: boolean;
  }
}
