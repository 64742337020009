import { ContentType, TypedPrimitive, UrlString } from "@eatbetter/common-shared";

export type PhotoId = TypedPrimitive<string, "photoId">;
export type PhotoMediaType = "png" | "jpg" | "jpeg" | "gif" | "tif" | "tiff" | "heic" | "heif" | "bmp" | "webp";

export type PhotoSize = (typeof photoSizes)[number];
export const photoSizes = ["w288", "w1290"] as const;

interface PhotoRefBase<TType extends string> {
  type: TType;
}

export interface PhotoRefInternal extends PhotoRefBase<"internal"> {
  id: PhotoId;
}

export interface PhotoRefExternal extends PhotoRefBase<"external"> {
  url: UrlString;
  cdn?: { id: PhotoId; h: string };
  /**
   * If true, we use the external url directly and skip the photo service/CDN
   */
  direct?: true;
}
export type PhotoRef = PhotoRefInternal | PhotoRefExternal;

/**
 * Determine if the underlying photo from 2 photo refs is the same. CDN info is ignored.
 * Undefined is allowed to make it simpler on callers when a photo is optional. Both undefined is treated as true.
 */
export function isSamePhoto(ref1: PhotoRef | undefined, ref2: PhotoRef | undefined) {
  if (ref1 === undefined && ref2 === undefined) {
    return true;
  }

  if (ref1?.type === "external" && ref2?.type === "external") {
    return ref1.url === ref2.url;
  }

  if (ref1?.type === "internal" && ref2?.type === "internal") {
    return ref1.id === ref2.id;
  }

  return false;
}

export interface EntityDisplay {
  name: string;
  photo?: PhotoRef;
}

export interface UploadPhotoRequestInfo {
  url: UrlString;
  photoId: PhotoId;
  contentType: ContentType;
}

export interface Dimensions {
  width?: number;
  height?: number;
}

export interface S3UploadAddPhotoArgs {
  mediaType: PhotoMediaType;
  dimensions: Dimensions;
}

export interface DeletePhotoArgs {
  photo: PhotoRefInternal;
}
