import { AdminRecipesScreenIdNav } from "./AdminRecipesScreen";
import { AdminScreenView } from "../components/AdminScreenView";
import {
  ButtonRectangle,
  displayUnexpectedErrorAndLog,
  FlexedSpinner,
  Spacer,
  TBody,
  THeading1,
  THeading2,
} from "@eatbetter/ui-shared";
import { useStringParam } from "../lib/ParamUtils";
import { useAdminFetchRecipe } from "../lib/useAdminFetchRecipe";
import { ExternalRecipeId, RecipeId, ServerRecipe } from "@eatbetter/recipes-shared";
import { Link } from "react-router-dom";
import { NvpTable } from "../components/NvpTable";
import { ellipsizeSentence } from "@eatbetter/common-shared";
import { useState } from "react";
import { RecipeLink } from "../components/RecipeLink";
import { FindOrAddRecipe } from "../components/FindOrAddRecipe";
import { View } from "react-native";
import { RecipeCopies } from "@eatbetter/composite-shared";
import { AdminBooksScreenIdNav } from "./AdminBooksScreen";
import { Alert } from "@eatbetter/ui-shared";
import { useDispatch } from "../lib/AdminRedux";
import { orphanRecipe } from "../lib/recipes/AdminRecipesThunks";

const recipeIdParam = ":recipeId";
export const AdminRecipeOrphanScreenNav = {
  getPath: (id?: string) => {
    return `${AdminRecipesScreenIdNav.getPath(id)}/orphan`;
  },
};

export const AdminRecipeOrphanScreen = () => {
  const id = useStringParam(recipeIdParam).required() as RecipeId;
  const [refreshToken, setRefreshToken] = useState(Date.now());

  const recipeResult = useAdminFetchRecipe(id, undefined, refreshToken.toString());

  if (!recipeResult) {
    return (
      <AdminScreenView>
        <FlexedSpinner />
      </AdminScreenView>
    );
  }

  const { recipe, userRecipes } = recipeResult;

  if (recipe.type !== "externalUrlRecipe") {
    return (
      <AdminScreenView>
        <TBody>Orphaning a recipe is only supported for URL Recipes</TBody>
      </AdminScreenView>
    );
  }

  const onOrphan = () => {
    setRefreshToken(Date.now());
  };

  return (
    <AdminScreenView>
      <THeading1>Orphan Recipe</THeading1>
      <Spacer vertical={1} />
      <TBody>Orphaning a URL recipe means that:</TBody>
      <TBody>
        1. No URL Lookups will point to the recipe. The lookup for the canonical URL of the recipe will be remapped to
        another recipe.
      </TBody>
      <TBody>
        2. Any user recipes associated with the orphaned recipe will be assigned the sourceRecipeId of the new recipe
        and updated.
      </TBody>
      <Spacer vertical={1} />
      <THeading2 color="red">Once a recipe is orphaned, it cannot be easily undone. Be careful!</THeading2>
      <Spacer vertical={1} />
      <THeading2>Recipe to orphan</THeading2>
      <RecipeDetails recipe={recipe} userRecipes={userRecipes} />
      <Spacer vertical={2} />
      {recipe.orphanedBy && (
        <TBody>
          Recipe <Link to={AdminRecipesScreenIdNav.getPath(id)}>{id}</Link> is orphaned by{" "}
          <Link to={AdminRecipesScreenIdNav.getPath(recipe.orphanedBy)}>{recipe.orphanedBy}</Link>
        </TBody>
      )}
      {!recipe.orphanedBy && <FindRecipeAndOrphan recipeIdToOrphan={id as ExternalRecipeId} onOrphan={onOrphan} />}
    </AdminScreenView>
  );
};

const FindRecipeAndOrphan = (props: { recipeIdToOrphan: ExternalRecipeId; onOrphan: () => void }) => {
  const [id, setId] = useState<RecipeId | undefined>();
  const [waiting, setWaiting] = useState(false);
  const dispatch = useDispatch();

  const recipe = useAdminFetchRecipe(id, setWaiting);

  const onSearch = (id?: RecipeId) => {
    setId(id);
  };

  const doOrphan = () => {
    dispatch(
      orphanRecipe({ orphanedByRecipeId: id as ExternalRecipeId, toOrphanRecipeId: props.recipeIdToOrphan }, setWaiting)
    )
      .then(() => {
        props.onOrphan();
      })
      .catch(err => {
        displayUnexpectedErrorAndLog("Unexpected error callign orphanRecipe", err);
      });
  };

  const onOrphan = () => {
    if (!recipe) {
      return;
    }

    Alert.alert("Are you sure?", "This is hard to undo. Please review carefully one last time", [
      {
        type: "cancel",
        onPress: () => {},
      },
      {
        type: "save",
        text: "Orphan",
        onPress: doOrphan,
      },
    ]);
  };

  return (
    <View>
      <THeading2>{"Replacement Recipe"}</THeading2>
      <FindOrAddRecipe onSearch={onSearch} externalOnly waiting={waiting} />
      {recipe?.recipe && (
        <>
          <RecipeDetails recipe={recipe.recipe} userRecipes={recipe?.userRecipes} />
          {recipe && (
            <View style={{ width: 200 }}>
              <Spacer vertical={2} />
              <ButtonRectangle type="submit" title="Orphan" onPress={onOrphan} />
            </View>
          )}
        </>
      )}
    </View>
  );
};

const RecipeDetails = (props: { recipe: ServerRecipe; userRecipes?: RecipeCopies }) => {
  const { recipe, userRecipes } = props;
  return (
    <table>
      <tbody>
        <NvpTable name="ID">
          <RecipeLink id={recipe.id} />
        </NvpTable>
        <NvpTable name="Title" value={recipe.title} />
        {recipe.type === "externalUrlRecipe" && (
          <NvpTable name="Canonical URL">
            <TBody>
              <a href={recipe.canonicalUrl} target="_blank" rel="noreferrer">
                {recipe.canonicalUrl}
              </a>
            </TBody>
          </NvpTable>
        )}
        {recipe.type === "externalBookRecipe" && (
          <NvpTable name="Book Title">
            <TBody>
              <Link to={AdminBooksScreenIdNav.getPath(recipe.book.id)}>{recipe.book.name}</Link>
            </TBody>
          </NvpTable>
        )}
        <NvpTable name="Description" value={ellipsizeSentence(recipe.description, 100)} />
        <NvpTable name="Ingredient Count" value={recipe.ingredients.sections.reduce((a, b) => a + b.items.length, 0)} />
        <NvpTable
          name="Instruction Count"
          value={recipe.instructions.sections.reduce((a, b) => a + b.items.length, 0)}
        />
        {userRecipes && (
          <NvpTable
            name="User Recipe Count"
            value={`${userRecipes.copies.length} ${userRecipes.mightBeMore ? "(or more)" : ""}`}
          />
        )}
      </tbody>
    </table>
  );
};
