import { AdminBook } from "@eatbetter/recipes-shared";
import { useCallback } from "react";
import { View } from "react-native";
import { useDispatch } from "../lib/AdminRedux";
import { editBook } from "../lib/books/AdminBooksThunks";
import { addPhoto } from "../lib/photos/AdminPhotoThunks";
import { BookEditControl, BookEditOnSaveArgs } from "./BookEditControl";

interface Props {
  bookToEdit: AdminBook;
  onDone: () => void;
}

export const BookEdit = (props: Props) => {
  const dispatch = useDispatch();

  const onSave = useCallback(
    async (args: BookEditOnSaveArgs) => {
      const newPhoto = args.newCoverPhoto ? await dispatch(addPhoto(args.newCoverPhoto)) : undefined;
      const editArgs: AdminBook = { ...args.book, coverPhoto: newPhoto ?? args.book?.coverPhoto };

      await dispatch(editBook(editArgs));
      props.onDone();
    },
    [dispatch, props.onDone]
  );

  return (
    <View>
      <BookEditControl bookToEdit={props.bookToEdit} onSave={onSave} />
    </View>
  );
};
