import { SocialPostId } from "@eatbetter/posts-shared";
import React, { useCallback, useEffect } from "react";
import { useDispatch } from "../lib/redux/Redux";
import { PostDetailScreenProps } from "../navigation/NavTree";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { saveRecipeFromPost } from "../lib/social/SocialThunks";
import { ViewRecipeScreenComponent, ViewRecipeScreenComponentProps } from "../components/ViewRecipeScreenComponent";
import { log } from "../Log";
import { usePostForDetailScreen } from "../lib/social/SocialHooks";
import { useIdempotentId } from "../lib/util/UseIdempotentId";
import { PartialRecipeId } from "@eatbetter/recipes-shared";
import { usePostSourceRecipeId } from "../lib/social/SocialSelectors";
import { FlexedSpinner } from "../components/Spinner";

// NOTE: This screen is very similar to ShareViewRecipeScreen, SearchViewRecipeScreen
// Updates here may apply there as well.
export const PostViewRecipeScreen = withScreenContainer(
  "PostViewRecipeScreen",
  (props: PostDetailScreenProps) => {
    const dispatch = useDispatch();
    const post = usePostForDetailScreen(props.postId);
    const [id, refreshId] = useIdempotentId<PartialRecipeId>();
    const recipeId = usePostSourceRecipeId(post?.id);
    const { nav } = useScreen();

    useEffect(() => {
      if (post && !recipeId) {
        log.error(`Unexpected social post encountered in PostViewRecipeScreen for post ${props.postId}: ${post?.type}`);
        if (nav.canGoBack()) {
          nav.goBack();
        }
      }
    }, [recipeId, post]);

    const onSaveRecipe = useCallback(async () => {
      if (!recipeId || !post) {
        return;
      }

      await dispatch(saveRecipeFromPost({ postId: post.id, partialRecipeId: id }));
      refreshId();
    }, [dispatch, post?.id, id, refreshId, recipeId]);

    if (!recipeId) {
      // useEffect above will nav back if this is not set and we have a post
      // this will also trigger if we are loading a post, but currently, I don't believe
      // there are any cases where we nav to this screen and don't have the post loaded.
      return <FlexedSpinner />;
    }

    return React.createElement<ViewRecipeScreenComponentProps>(ViewRecipeScreenComponent, {
      context: "post",
      recipeId: recipeId,
      onSaveRecipe,
    });
  },
  {
    serializer: {
      postId: s => s,
    },
    parser: {
      postId: s => s as SocialPostId,
    },
  }
);
