import { withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { useCallback, useMemo, useState } from "react";
import { ProfileEditFieldScreenComponent } from "../components/social/ProfileEditFieldScreenComponent";
import { useRegisteredUser } from "../lib/system/SystemSelectors";

const strings = {
  screenHeader: "Email",
  label: "Email",
};

export const ProfileEditEmailScreen = withNonNavigableScreenContainer("ProfileEditEmailScreen", () => {
  const persistedEmail = useRegisteredUser()?.email.address ?? "";
  const [email, setEmail] = useState(persistedEmail);
  const isDirty = useMemo(() => persistedEmail !== email, [persistedEmail, email]);

  const onSave = useCallback(() => {
    // Readonly for now
  }, []);

  return (
    <ProfileEditFieldScreenComponent
      screenHeader={strings.screenHeader}
      label={strings.label}
      value={email}
      onChangeText={setEmail}
      isDirty={isDirty}
      onSubmit={onSave}
      isReadOnly
      isPostMetadata={false}
    />
  );
});
