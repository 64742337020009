import { RecipeRating, UserRecipeId } from "@eatbetter/recipes-shared";
import React, { useCallback, useState } from "react";
import { StarRating } from "../StarRating";
import { View } from "react-native";
import { globalStyleConstants } from "../GlobalStyles";
import { TBody } from "../Typography";
import { Spacer } from "../Spacer";
import { useRecipeRating, useRecipeTitle } from "../../lib/recipes/RecipesSelectors";
import { ButtonRectangle } from "../Buttons";
import { useDispatch } from "../../lib/redux/Redux";
import { editRecipeRating } from "../../lib/recipes/RecipesThunks";
import { useBottomSheet } from "../../screens/BottomSheetScreen";
import { Haptics } from "../Haptics";
import { displayUnexpectedErrorAndLog } from "../../lib/Errors";

const strings = {
  submitButton: "Save",
};

export interface RecipeRatingEditProps {
  rating: RecipeRating | undefined;
  onChangeRating?: (rating: RecipeRating | undefined) => void;
  style?: "compact" | "normal";
  disabled?: boolean;
  clearable?: boolean;
}

export const RecipeRatingEdit = React.memo((props: RecipeRatingEditProps) => {
  const onChangeRating = useCallback(
    (value: number | undefined) => {
      props.onChangeRating?.(value === undefined ? undefined : { type: "1to5Star", rating: value });
    },
    [props.onChangeRating]
  );

  return (
    <StarRating
      value={props.rating?.rating ?? 0}
      onChangeValue={props.onChangeRating ? onChangeRating : undefined}
      style={props.style}
      disabled={props.disabled}
      clearable={props.clearable}
    />
  );
});

interface RecipeRatingEditSheetProps {
  recipeId: UserRecipeId;
}

export const recipeRatingEditSheetHeight = 260;
export const RecipeRatingEditSheet = React.memo((props: RecipeRatingEditSheetProps) => {
  const dispatch = useDispatch();
  const bottomSheet = useBottomSheet();
  const recipeRating = useRecipeRating(props.recipeId);
  const recipeTitle = useRecipeTitle(props.recipeId);

  const [recipeRatingDraft, setRecipeRatingDraft] = useState(recipeRating);
  const [waiting, setWaiting] = useState(false);

  const onSubmit = useCallback(async () => {
    try {
      await dispatch(editRecipeRating(props.recipeId, "recipeDetail", recipeRatingDraft ?? null, setWaiting));
      Haptics.feedback("operationSucceeded");
      bottomSheet?.closeSheetAndGoBack();
    } catch (err) {
      displayUnexpectedErrorAndLog("RecipeRatingEditSheet: Error dispatching editRecipeRating", err);
    }
  }, [dispatch, recipeRatingDraft, props.recipeId, setWaiting]);

  const isDirty = recipeRating?.rating !== recipeRatingDraft?.rating;

  return (
    <View style={{ padding: globalStyleConstants.defaultPadding }}>
      <TBody align="center" fontWeight="medium">
        {recipeTitle}
      </TBody>
      <Spacer vertical={3} />
      <View style={{ alignItems: "center" }}>
        <RecipeRatingEdit rating={recipeRatingDraft} onChangeRating={setRecipeRatingDraft} />
      </View>
      <Spacer vertical={4} />
      <ButtonRectangle
        onPress={onSubmit}
        type="submit"
        title={strings.submitButton}
        disabled={!isDirty}
        waiting={waiting}
        size="large"
      />
    </View>
  );
});
