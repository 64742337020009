import { EpochMs } from "./UtilityTypes";

export interface NowOrLater {
  /**
   * If it's time to take an action based on the error count/last attempt
   */
  now: boolean;

  /**
   * If now is false, the number of ms until now will be true
   */
  laterIn?: number;

  /**
   * If now is false, the EpochMs at which point now will be true
   */
  laterAt?: EpochMs;
}

export function nowOrLaterBackoff(args: {
  lastAttempt: EpochMs;
  failureCount: number;
  timeNow: EpochMs;
  maxDelay?: number;
}): NowOrLater {
  const time = args.lastAttempt + exponentialBackoff(args.failureCount, args.maxDelay) * 1000;
  return time <= args.timeNow ? { now: true } : { now: false, laterIn: time - args.timeNow, laterAt: time as EpochMs };
}

export function nowOrLaterDelayFromLast(args: { last: EpochMs; delayInSeconds: number; timeNow: EpochMs }): NowOrLater {
  const time = args.last + args.delayInSeconds * 1000;
  return time <= args.timeNow ? { now: true } : { now: false, laterIn: time - args.timeNow, laterAt: time as EpochMs };
}

function exponentialBackoff(failureCount: number, maxDelay = 60): number {
  return Math.min(Math.pow(2, failureCount - 1), maxDelay);
}
