import { AppDispatch, SyncThunkAction } from "../redux/Redux";
import { addSeconds, EpochMs, filterOutFalsy, UrlString } from "@eatbetter/common-shared";
import { CookingSessionId } from "@eatbetter/cooking-shared";
import { RecipeInstructionId, RecipeSectionId } from "@eatbetter/recipes-shared";
import {
  CookingTimerId,
  LocalNotificationId,
  RecipeCookingTimer,
  recipeTimerStarted,
  timerAcknowledged,
  timerDeleted,
  timerEndTimeChanged,
  timerNotificationsAdded,
  timerNotificationsDeleted,
  timerPaused,
  timerRestarted,
} from "./CookingSessionsSlice";
import { log } from "../../Log";
import { EntityState } from "@reduxjs/toolkit";
import { RootState } from "../redux/RootReducer";
import { Deps } from "../Deps";
import { reportCookingTimerCreated } from "../analytics/AnalyticsEvents";
import { analyticsEvent } from "../analytics/AnalyticsThunks";
import { TimerAlarm } from "../TimerAlarm";
import { getCookingTimerStatus } from "./CookingTimerTick";
import { cancelAllScheduledNotificationsAsync } from "expo-notifications";
import { navActionRequested } from "../system/SystemSlice";
import { getLinkForNavigableScreen } from "../../navigation/ScreenContainer";
import { navTree } from "../../navigation/NavTree";

export const evaluateTimers = (tick: EpochMs): SyncThunkAction<void> => {
  return (dispatch, getState, deps) => {
    const state = getState();

    if (state.cookingSessions.timers.ids.length > 0) {
      deps.timerAlarm?.startBackgroundAudio().catch(err => {
        log.errorCaught("Unexpected error calling startBackgroundAudio in evaluteTimers", err);
      });
    }
    const alertingTimers = getAlertingTimers(tick, state.cookingSessions.timers);
    if (alertingTimers.length > 0) {
      log.info("Starting timer alarm");
      deps.timerAlarm?.startAlarm().catch(err => {
        log.errorCaught("Unexpected error calling startAlarm in evaluateTimers", err);
      });

      // open the modal if it's not already open
      dispatch(timerScreenFocusRequested());
    }
  };
};

export const cookingSessionHasRemainingTimers = (csid: CookingSessionId): SyncThunkAction<boolean> => {
  return (_dispatch, getState, deps) => {
    const allTimers = Object.values(getState().cookingSessions.timers.entities) as RecipeCookingTimer[];
    const sessionTimers = allTimers.filter(t => t.cookingSessionId === csid);
    const tick = deps.time.epochMs();
    const timersRemaining = sessionTimers.some(t => {
      const status = getCookingTimerStatus(t, tick);
      return status.status === "running" && status.percentComplete < 95;
    });
    return timersRemaining;
  };
};

const timerScreenFocusRequested = (): SyncThunkAction<void> => {
  return (dispatch, _getState, _deps) => {
    dispatch(navActionRequested({ screenName: "timers", props: {} }));
  };
};

export const timerNotificationTapped = (timerId: CookingTimerId): SyncThunkAction<void> => {
  return (dispatch, getState, _deps) => {
    log.info("Thunk: timerNotificationTapped");
    dispatch(acknowledgeAlertingTimers());

    const state = getState();
    const timer = state.cookingSessions.timers.entities[timerId];

    if (timer) {
      dispatch(timerScreenFocusRequested());
    }
  };
};

export const startRecipeTimer = (args: {
  id: CookingTimerId;
  minSeconds: number;
  maxSeconds: number;
  cookingSessionId: CookingSessionId;
  sectionId: RecipeSectionId;
  instructionId: RecipeInstructionId;
  substringRange: [number, number];
}): SyncThunkAction<void> => {
  return (dispatch, getState, deps) => {
    log.info("Thunk: startRecipeTimer", { args });
    const startTime = deps.time.epochMs();
    const endTime = (startTime + args.minSeconds * 1000) as EpochMs;

    dispatch(
      recipeTimerStarted({
        id: args.id,
        startTime,
        endTime,
        minSeconds: args.minSeconds,
        maxSeconds: args.maxSeconds,
        cookingSessionId: args.cookingSessionId,
        sectionId: args.sectionId,
        instructionId: args.instructionId,
        substringRange: args.substringRange,
      })
    );

    deps.secondTimer.start();

    deleteAndAddNotifications(args.id, endTime, dispatch, getState, deps).catch(err => {
      log.errorCaught("Unexpected error calling deleteAndAddNotifications in startRecipeTimer", err);
    });

    const state = getState();
    const recipeTitle = getRecipeTitle(state, args.cookingSessionId);
    const tapUrl = getTimerTapUrl();

    if (liveActivitiesEnabled(state)) {
      deps.liveActivities
        .startCookingTimerLiveActivity({ timerId: args.id, endTime, recipeTitle, tapUrl })
        .catch(err => {
          log.errorCaught("Unexpected error calling startCookingTimerLiveActivity in startRecipeTimer", err);
        });
    }

    const timerCountInclusive = getState().cookingSessions.timers.ids.length;
    const event = reportCookingTimerCreated({
      minDuration: args.minSeconds,
      maxDuration: args.maxSeconds,
      timerCountInclusive,
    });
    dispatch(analyticsEvent(event));
  };
};

export const pauseTimer = (id: CookingTimerId, lastTickEvaluated: EpochMs): SyncThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: pauseTimer", { id });
    if (lastTickEvaluated % 1000 !== 0) {
      log.error(`pauseTimer called with a lastTick time that was not a multiple of 1000: ${lastTickEvaluated}`);
    }

    // use lastTickEvaluated if possible - this will minimize the risk of the timer jumping around
    dispatch(timerPaused({ id, pauseTime: lastTickEvaluated }));

    const state = getState();
    deleteNotifications(id, dispatch, state, deps).catch(err => {
      log.errorCaught("Error calling deleteNotifications in pauseTimer", err);
    });

    if (liveActivitiesEnabled(state)) {
      deps.liveActivities.endCookingTimerLiveActivity({ timerId: id }).catch(err => {
        log.errorCaught("Unexpected error calling endCookingTimerLiveActivity in pauseTimer", err);
      });
    }
  };
};

export const restartTimer = (id: CookingTimerId, lastTickEvaluated: EpochMs): SyncThunkAction<void> => {
  return (dispatch, getState, deps) => {
    log.info("Thunk: restartTimer", { id });
    if (lastTickEvaluated % 1000 !== 0) {
      log.error(`restartTimer called with a lastTick time that was not a multiple of 1000: ${lastTickEvaluated}`);
    }

    const state = getState();
    const timer = state.cookingSessions.timers.entities[id];

    if (!timer || !timer.pausedTime) {
      log.error(`Attempted to restart timer ${id} but no timer found, or timer not paused`, { timer });
      return;
    }

    // the original end time has already been adjusted to make ticking smooth, so extend
    const endTime = (timer.endTime + lastTickEvaluated - timer.pausedTime) as EpochMs;

    dispatch(timerRestarted({ id, newEndTime: endTime }));

    deleteAndAddNotifications(id, endTime, dispatch, getState, deps).catch(err => {
      log.errorCaught("Unexpected error calling deleteAndAddNotifications in restartTimer", err);
    });

    const recipeTitle = getRecipeTitle(state, timer.cookingSessionId);
    const tapUrl = getTimerTapUrl();

    if (liveActivitiesEnabled(state)) {
      deps.liveActivities.startCookingTimerLiveActivity({ timerId: id, endTime, recipeTitle, tapUrl }).catch(err => {
        log.errorCaught("Unexpected error calling startCookingTimerLiveActivity in startRecipeTimer", err);
      });
    }
  };
};

export const changeTimerEndTime = (
  id: CookingTimerId,
  deltaInSeconds: number,
  lastTick: EpochMs
): SyncThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: changeTimerEndTime", { id, deltaInSeconds });
    if (lastTick % 1000 !== 0) {
      log.error(`changeTimerEndTime called with a lastTick time that was not a multiple of 1000: ${lastTick}`);
    }

    // if there are any timers alerting, the user can silence them by extending the time, so dispatch
    // the acknowledgement thunk here
    dispatch(acknowledgeAlertingTimers());

    const state = getState();
    const timer = state.cookingSessions.timers.entities[id];

    if (!timer) {
      log.error(`changeTimerEndtime called for timer ${id} but timer couldn't be found`);
      return;
    }

    let endTime = timer.endTime;

    const secondsRemaining = Math.max(0, (timer.endTime - lastTick) / 1000);

    // if delta is negative, only change if it doesn't end the timer
    if (deltaInSeconds < 0 && Math.abs(deltaInSeconds) < secondsRemaining) {
      endTime = (timer.endTime + deltaInSeconds * 1000) as EpochMs;
    }

    if (deltaInSeconds > 0) {
      if (secondsRemaining === 0) {
        endTime = (lastTick + deltaInSeconds * 1000) as EpochMs;
      } else {
        endTime = (timer.endTime + deltaInSeconds * 1000) as EpochMs;
      }
    }

    dispatch(timerEndTimeChanged({ id, newEndTime: endTime }));

    deleteAndAddNotifications(id, endTime, dispatch, getState, deps).catch(err => {
      log.errorCaught("Error calling deleteAndAddNotifications in changeTimerEndTime", err);
    });

    if (liveActivitiesEnabled(state)) {
      deps.liveActivities.updateCookingTimerLiveActivity({ timerId: id, endTime }).catch(err => {
        log.errorCaught("Unexpected error calling updateCookingTimerLiveActivity in changeTimerEndTime", err);
      });
    }
  };
};

export const deleteTimer = (id: CookingTimerId): SyncThunkAction<void> => {
  return (dispatch, getState, deps) => {
    log.info("Thunk: deleteTimer", { id });

    deleteTimersInternal([id], true, dispatch, getState, deps);
  };
};

/**
 * Delete timers for cooking sessions that have been deleted
 */
export const deleteStaleTimers = (ids: CookingTimerId[]): SyncThunkAction<void> => {
  return (dispatch, getState, deps) => {
    log.info("Thunk: deleteStaleTimers", { ids });

    // we need this in addition to deleteTimer because we don't want to acknowledge alerting timers
    // and we don't want a mixpanel event here

    deleteTimersInternal(ids, false, dispatch, getState, deps);
  };
};

export const deleteAllTimers = (): SyncThunkAction<void> => {
  return (dispatch, getState, deps) => {
    dispatch(acknowledgeAlertingTimers());

    const state = getState();
    const timerIds = state.cookingSessions.timers.ids as CookingTimerId[];

    deleteTimersInternal(timerIds, true, dispatch, getState, deps);
  };
};

function deleteTimersInternal(
  timerIds: CookingTimerId[],
  acknowledgeAlerting: boolean,
  dispatch: AppDispatch,
  getState: () => RootState,
  deps: Deps
): void {
  if (acknowledgeAlerting) {
    dispatch(acknowledgeAlertingTimers());
  }

  const state = getState();
  timerIds.forEach(id => {
    deleteNotifications(id, dispatch, state, deps).catch(err => {
      log.errorCaught("Error calling deleteNotifications in deleteTimer", err);
    });

    // this must come after the call to getState for deleteNotifications
    dispatch(timerDeleted(id));

    if (liveActivitiesEnabled(state)) {
      deps.liveActivities.endCookingTimerLiveActivity({ timerId: id }).catch(err => {
        log.errorCaught("Unexpected error calling endCookingTimerLiveActivity in deleteTimer", err);
      });
    }
  });

  // the call to actually delete the timer from dispatch above is synchronous, so
  // get refreshed state and see if we have any left.
  const state2 = getState();
  cleanupIfNoTimers(state2, deps.timerAlarm);
}

export const cleanupTimerNotificationsOnSignOut = async () => {
  await cancelAllScheduledNotificationsAsync().catch(err => {
    log.errorCaught("Error calling cancelAllScheduledNotificationsAsync", err);
  });
};

function getAlertingTimers(tick: EpochMs, timerMap: EntityState<RecipeCookingTimer, string>): RecipeCookingTimer[] {
  const timers = filterOutFalsy(Object.values(timerMap.entities));
  return timers.filter(t => {
    return tick > t.endTime && !t.alertAcknowledged;
  });
}

function getRecipeTitle(state: RootState, id: CookingSessionId) {
  return state.cookingSessions.cookingSessions[id]?.sourceRecipe.title ?? "";
}

function getTimerTapUrl(ackOnTap = true): UrlString {
  return getLinkForNavigableScreen(navTree.get.screens.timers, { acknowledgeTimers: ackOnTap });
}

function liveActivitiesEnabled(state: RootState): boolean {
  return state.system.systemSettings.liveActivities ?? true;
}

function cleanupIfNoTimers(state: RootState, timerAlarm?: TimerAlarm) {
  if (state.cookingSessions.timers.ids.length > 0) {
    return;
  }

  if (timerAlarm) {
    timerAlarm.stopBackgroundAudio().catch(err => {
      log.errorCaught("Unexpected error calling stopBackgroundAudio in stopBackgroundAudioIfNoTimers", err);
    });
  }
}

export const acknowledgeAlertingTimers = (): SyncThunkAction<void> => {
  return (dispatch, getState, deps) => {
    log.info("Thunk: acknowledgeAlertingTimer");
    const tick = deps.time.epochMs();
    const alertingTimers = getAlertingTimers(tick, getState().cookingSessions.timers);
    log.info(`Acknowledging ${alertingTimers.length} timers`);

    if (alertingTimers.length > 0) {
      deps.timerAlarm?.stopAlarm().catch(err => {
        log.errorCaught("Unexected error calling stopAlarm in ackknowledgeAlertingTimer", err);
      });
    }

    alertingTimers.forEach(t => {
      dispatch(acknowledgeSingleTimer(t.id, tick));
    });
  };
};

export const acknowledgeSingleTimer = (timerId: CookingTimerId, tick: EpochMs): SyncThunkAction<void> => {
  return (dispatch, getState, deps) => {
    dispatch(timerAcknowledged({ id: timerId, timeAcknowledged: tick }));

    deleteNotifications(timerId, dispatch, getState(), deps).catch(err => {
      log.errorCaught("Unexpected error calling deleteNotifications in acknowledgeSingleTimer", err, { timerId });
    });
  };
};

export const timerMounted = (cb: (n: EpochMs) => void): SyncThunkAction<EpochMs> => {
  return (_dispatch, _getState, deps) => {
    return deps.secondTimer.registerCallback(cb);
  };
};

export const timerUnmounted = (cb: (n: EpochMs) => void): SyncThunkAction<void> => {
  return (_dispatch, _getState, deps) => {
    deps.secondTimer.unregisterCallback(cb);
  };
};

async function deleteAndAddNotifications(
  cookingTimerId: CookingTimerId,
  endTime: EpochMs,
  dispatch: AppDispatch,
  getState: () => RootState,
  deps: Deps
): Promise<void> {
  if (!deps.timerAlarm) {
    return;
  }

  const timerAlarm = deps.timerAlarm;

  const state = getState();
  const timer = state.cookingSessions.timers.entities[cookingTimerId];

  if (!timer) {
    log.error(`deleteAndAddNotifications couldn't find timer with ID ${cookingTimerId}`);
    return;
  }

  const userId = state.system.authedUser.data?.userId;

  if (!userId) {
    log.error("No user ID in deleteAndAddNotifications", { authedUser: state.system.authedUser });
    return;
  }

  const title = state.cookingSessions.cookingSessions[timer.cookingSessionId]?.sourceRecipe.title;

  if (timer.notificationIds && timer.notificationIds.length > 0) {
    await deleteNotifications(timer.id, dispatch, state, deps);
  }

  const times = [endTime, addSeconds(endTime, 30), addSeconds(endTime, 60), addSeconds(endTime, 90)];
  const promises = times.map(async (time, idx) => {
    const id = await timerAlarm.createNotification({
      userId,
      timerId: timer.id,
      notificationTime: time,
      recipeTitle: title,
      sound: idx === 0 ? undefined : "highPitch",
    });
    return id as LocalNotificationId;
  });

  const notificationIds = await Promise.all(promises);
  dispatch(timerNotificationsAdded({ timerId: timer.id, notificationIds }));
}

async function deleteNotifications(
  cookingTimerId: CookingTimerId,
  dispatch: AppDispatch,
  state: RootState,
  deps: Deps
): Promise<void> {
  if (!deps.timerAlarm) {
    return;
  }

  const timerAlarm = deps.timerAlarm;

  const timer = state.cookingSessions.timers.entities[cookingTimerId];

  if (!timer) {
    log.error(`deleteNotifications called for cooking timer ${cookingTimerId} but no timer found`);
    return;
  }

  const notificationIds = timer.notificationIds ?? [];

  const promises = notificationIds.map(async id => {
    try {
      await timerAlarm.deleteNotification(id);
    } catch (err) {
      log.errorCaught("Unexpected error calling cancelScheduledNotificationAsync", { id });
    }
  });

  await Promise.all(promises);
  dispatch(timerNotificationsDeleted({ timerId: cookingTimerId }));
}
