import { useState } from "react";
import { LayoutRectangle, StyleSheet, View } from "react-native";
import { smallScreenBreakpoint, useResponsiveDimensions } from "./Responsive";
import { globalStyleColors, globalStyleConstants, globalStyles, Opacity } from "./GlobalStyles";
import { bottomThrow, switchReturn } from "@eatbetter/common-shared";
import { Spacer } from "./Spacer";
import { TBody } from "./Typography";
import { ButtonRectangle } from "./Buttons";

const constants = {
  arrowSize: 14,
};

interface TooltipProps {
  target: LayoutRectangle;
  message: string | React.ReactElement;
  buttonText?: string;
  onPressButton?: () => void;
  maxWidth?: `${number}%`;
  paddingVertical?: "normal" | "min";
}

export const Tooltip = (props: TooltipProps) => {
  const [messageBoxHeight, setMessageBoxHeight] = useState(0);

  const { x, y, width, height } = props.target;
  const { width: screenWidth, height: screenHeight, isSmallScreen } = useResponsiveDimensions();

  const marginFromTarget = globalStyleConstants.minPadding;
  const screenBoundaryRect = {
    left: globalStyleConstants.minPadding,
    right: screenWidth - globalStyleConstants.minPadding,
    top: globalStyleConstants.minPadding,
    bottom: screenHeight - globalStyleConstants.minPadding,
  };

  // Infer arrow direction based on the target's vertical position
  const arrowDirection: "up" | "down" = y + height / 2 > screenHeight / 2 ? "down" : "up";

  // Calculate horizontal alignment
  const targetCenterX = x + width / 2;
  const screenCenterX = screenWidth / 2;
  const isCenterAligned = Math.abs(targetCenterX - screenCenterX) <= 24;

  const maxWidth = switchReturn(props.maxWidth ?? "80%", {
    "50%": 0.5 * screenWidth,
    "60%": 0.6 * screenWidth,
    "70%": 0.7 * screenWidth,
    "80%": 0.8 * screenWidth,
  });
  const messageMaxWidth = Math.min(maxWidth, smallScreenBreakpoint);

  const messageHorizontalPosition = isCenterAligned
    ? {
        alignSelf: "center" as const,
      }
    : targetCenterX < screenCenterX
    ? { left: globalStyleConstants.minPadding, right: undefined }
    : { left: undefined, right: globalStyleConstants.minPadding };

  // Calculate vertical positions
  const arrowX = targetCenterX - constants.arrowSize / 2;
  const arrowLeft = Math.min(screenBoundaryRect.right - constants.arrowSize, Math.max(screenBoundaryRect.left, arrowX));

  const getPositions = () => {
    let arrowTop: number, messageTop: number | undefined;
    switch (arrowDirection) {
      case "down": {
        // Position arrow just above the target
        arrowTop = y - constants.arrowSize - marginFromTarget;

        // Position message box so its bottom is flush with the top of the arrow
        messageTop = arrowTop - messageBoxHeight;

        // Ensure arrow and message stay within screen bounds
        arrowTop = Math.max(screenBoundaryRect.top + constants.arrowSize, arrowTop);
        break;
      }
      case "up": {
        // Position arrow just below the target
        arrowTop = y + height + marginFromTarget;

        // Position message box so its top is flush with the bottom of the arrow
        messageTop = arrowTop + constants.arrowSize;

        // Ensure arrow and message stay within screen bounds
        arrowTop = Math.min(screenBoundaryRect.bottom - constants.arrowSize * 2, arrowTop);
        messageTop = Math.min(screenBoundaryRect.bottom - constants.arrowSize, messageTop);
        break;
      }
      default:
        bottomThrow(arrowDirection);
    }

    return { arrowTop, messageTop };
  };

  const { arrowTop, messageTop } = getPositions();

  return (
    <>
      <View
        onLayout={event => {
          const { height } = event.nativeEvent.layout;
          setMessageBoxHeight(height);
        }}
        style={[
          styles.message,
          {
            ...(messageTop !== undefined ? { top: messageTop } : {}),
            ...messageHorizontalPosition,
            maxWidth: messageMaxWidth,
          },
          props.paddingVertical === "min" ? { paddingVertical: globalStyleConstants.unitSize } : {},
        ]}
      >
        {typeof props.message === "string" && (
          <TBody align="center" enableFontScaling="upOnly" scale={isSmallScreen ? 1 : 1.3}>
            {props.message}
          </TBody>
        )}
        {typeof props.message !== "string" && props.message}
        {!!props.onPressButton && !!props.buttonText && (
          <>
            <Spacer vertical={1.5} />
            <CloseButton text={props.buttonText} onPress={props.onPressButton} />
          </>
        )}
      </View>
      <View style={{ position: "absolute", left: arrowLeft, top: arrowTop }}>
        <Arrow type={arrowDirection} />
      </View>
    </>
  );
};

const Arrow = (props: { type: "up" | "down" }) => {
  switch (props.type) {
    case "up": {
      return <View style={styles.arrowUp} />;
    }
    case "down": {
      return <View style={styles.arrowDown} />;
    }
    default:
      bottomThrow(props.type);
  }
};

const CloseButton = (props: { text: string; onPress: () => void }) => {
  const width = props.text.length < 7 ? 56 : props.text.length < 13 ? 128 : "100%";

  return (
    <View style={{ width }}>
      <ButtonRectangle type="secondary" size="small" title={props.text} onPress={props.onPress} />
    </View>
  );
};

const styles = StyleSheet.create({
  message: {
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    padding: globalStyleConstants.defaultPadding,
    minWidth: 96,
    minHeight: 24,
    backgroundColor: globalStyleColors.white,
    borderRadius: 8,
    ...globalStyles.shadowItem,
    shadowOpacity: Opacity.light,
  },
  arrowUp: {
    width: 0,
    height: 0,
    backgroundColor: "transparent",
    borderStyle: "solid",
    borderLeftWidth: constants.arrowSize / 1.5,
    borderRightWidth: constants.arrowSize / 1.5,
    borderBottomWidth: constants.arrowSize,
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    borderBottomColor: globalStyleColors.white,
  },
  arrowDown: {
    width: 0,
    height: 0,
    backgroundColor: "transparent",
    borderStyle: "solid",
    borderLeftWidth: constants.arrowSize / 1.5,
    borderRightWidth: constants.arrowSize / 1.5,
    borderTopWidth: constants.arrowSize,
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    borderTopColor: globalStyleColors.white,
  },
});
