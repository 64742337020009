import React, { useCallback, useEffect, useState } from "react";
import { ParsedIngredientSummary, StandardPrimaryCategory } from "@eatbetter/items-shared";
import {
  ButtonRectangle,
  CurrentEnvironment,
  displayUnexpectedErrorAndLog,
  globalStyleConstants,
  SetWaitingHandler,
  Spacer,
  TBody,
  THeading1,
  THeading2,
} from "@eatbetter/ui-shared";
import { Link, useNavigate } from "react-router-dom";
import { AdminRecipeEditScreenNav } from "../screens/AdminRecipeEditScreen";
import { View } from "react-native";
import {
  AuthorComponent,
  BookComponent,
  CategorizedIngredients,
  IndexComponent,
  Ingredients,
  Instructions,
  PhotoComponent,
  PublisherComponent,
  TagsComponent,
  TimeComponent,
  YieldComponent,
} from "./AdminRecipeComponents";
import { ExternalRecipe, RecipeTagManifest } from "@eatbetter/recipes-shared";
import { daysBetween, defaultTimeProvider, switchReturn, UrlString } from "@eatbetter/common-shared";
import { useDispatch } from "../lib/AdminRedux";
import { reprocessExternalUrlRecipe, saveRecipeCopy } from "../lib/recipes/AdminRecipesThunks";
import { UserSearchSelect } from "./SearchSelect";
import { NvpTable } from "./NvpTable";
import { AdminGetRecipeResult, RecipeCopies } from "@eatbetter/composite-shared";
import { AnonymousUser, DeglazeUser } from "@eatbetter/users-shared";
import { Divider } from "antd";
import { AdminRecipeOrphanScreenNav } from "../screens/AdminRecipeOrphanScreen";
import { RecipeLink } from "./RecipeLink";
import { addIngredientCategoryOverride } from "../lib/lists/AdminListsThunks";
import { AdminCategoryOverrideArgs, IngredientCategoryOverride } from "@eatbetter/lists-shared";
import { AdminRecipeDocumentsScreenNav } from "../screens/AdminRecipeDocumentsScreen";

export const ExternalRecipeView = React.memo(
  (props: {
    recipe: ExternalRecipe;
    parsed: ParsedIngredientSummary[];
    overrides?: IngredientCategoryOverride[];
    setRecipe: (updated: AdminGetRecipeResult) => void;
    tagManifest: RecipeTagManifest;
    recipeCopies?: RecipeCopies;
  }) => {
    const dispatch = useDispatch();

    const recipe = props.recipe;

    // we set this when the prop value changes *or* when we make an update call, hence the state variable
    const [overrides, setOverrides] = useState<IngredientCategoryOverride[] | undefined>(props.overrides);

    useEffect(() => {
      setOverrides(props.overrides);
    }, [props.overrides]);

    const createdDaysAgo = daysBetween(recipe.created, defaultTimeProvider()).toFixed(1);
    const versionDaysAgo = daysBetween(recipe.version, defaultTimeProvider()).toFixed(1);
    const s3Key = recipe.id.replaceAll(":", "!");
    const s3Url =
      `https://s3.console.aws.amazon.com/s3/buckets/deglaze-${CurrentEnvironment.configEnvironment()}-recipes?prefix=${s3Key}/&region=us-west-2` as UrlString;
    const nav = useNavigate();

    const onEdit = () => {
      if (!recipe) {
        return;
      }

      nav(AdminRecipeEditScreenNav.getPath(recipe.id));
    };

    const [waitingReprocess, setWaitingReprocess] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState<(DeglazeUser | AnonymousUser)[]>([]);

    const sendToUsers = useCallback(async () => {
      await Promise.all(
        selectedUsers.map(i => {
          return dispatch(saveRecipeCopy({ userId: i.userId, sourceRecipeId: recipe.id }));
        })
      );

      alert(`Success! Recipe pushed to ${selectedUsers.length} user(s).`);
    }, [selectedUsers, dispatch]);

    const reprocess = async () => {
      if (!recipe) {
        return;
      }

      try {
        const updated = await dispatch(reprocessExternalUrlRecipe({ recipeId: recipe.id }, setWaitingReprocess));
        props.setRecipe(updated);
      } catch (err) {
        displayUnexpectedErrorAndLog("Error in Admin ExternalRecipeView reprocess", err);
      }
    };

    const onCategoryOverride = (
      text: string,
      category: StandardPrimaryCategory | null,
      setWaiting: SetWaitingHandler
    ) => {
      const args: AdminCategoryOverrideArgs = { recipeId: props.recipe.id, category, ingredientText: text };
      dispatch(addIngredientCategoryOverride(args, setWaiting))
        .then(o => {
          setOverrides(o.adminCategoryOverrides);
        })
        .catch(err => {
          displayUnexpectedErrorAndLog("Error dispatching addIngredientCategoryOverride", err, { args });
        });
    };

    const screenTitle = switchReturn(props.recipe.type, {
      externalBookRecipe: "External Book Recipe",
      externalUrlRecipe: "External URL Recipe",
    });

    const otherUrls = recipe.type === "externalUrlRecipe" && recipe.otherUrls ? recipe.otherUrls : [];

    return (
      <View>
        <THeading1 fontWeight="medium">{`${screenTitle} ${recipe.id}`}</THeading1>
        <Spacer vertical={1} />
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <View style={{ width: globalStyleConstants.unitSize * 12 }}>
            <ButtonRectangle onPress={onEdit} type="submit" title="Edit" />
          </View>
          <Spacer horizontal={8} />
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <View style={{ width: 24 * globalStyleConstants.unitSize }}>
              <UserSearchSelect mode="multi" selected={selectedUsers} onChange={setSelectedUsers} />
            </View>
            <Spacer horizontal={1} />
            <ButtonRectangle type="submit" title="Send" onPress={sendToUsers} />
          </View>
          <Spacer horizontal={1} />
          <View>
            <ButtonRectangle onPress={reprocess} type="submit" title="Reprocess" waiting={waitingReprocess} />
          </View>
          {props.recipe.type === "externalUrlRecipe" && (
            <>
              <Spacer horizontal={1} />
              <View>
                <ButtonRectangle
                  type="cancel"
                  title="Orphan"
                  onPress={() => nav(AdminRecipeOrphanScreenNav.getPath(recipe.id))}
                />
              </View>
            </>
          )}
        </View>
        <Spacer vertical={1} />
        <table>
          <tbody>
            <tr>
              <td>
                <TBody fontWeight="heavy">Meta</TBody>
              </td>
            </tr>
            <NvpTable name="ID" value={recipe.id} />
            {recipe.type === "externalUrlRecipe" && !!recipe.orphanedBy && (
              <NvpTable name="Orphaned by" backgroundColor="red">
                <RecipeLink id={recipe.orphanedBy} />
              </NvpTable>
            )}
            {recipe.type === "externalUrlRecipe" && <NvpTable name="Status" value={recipe.status} />}
            <NvpTable name="Created" value={`${recipe.created} (${createdDaysAgo} days ago)`} />
            <NvpTable name="Version" value={`${recipe.version} (${versionDaysAgo} days ago)`} />
            <NvpTable name="S3">
              <TBody>
                <a href={s3Url} target="_blank" rel="noreferrer">
                  {s3Url}
                </a>
              </TBody>
            </NvpTable>
            {recipe.type === "externalUrlRecipe" && (
              <>
                <NvpTable name="Last updated by" value={recipe.manuallyUpdatedBy ?? "<none>"} />
                <NvpTable
                  name="Admin Updated fields"
                  value={recipe.manuallyUpdatedFields.length === 0 ? "<none>" : recipe.manuallyUpdatedFields.join(", ")}
                />
                <NvpTable
                  name="AI Updated fields"
                  value={recipe.aiUpdatedFields.length === 0 ? "<none>" : recipe.aiUpdatedFields.join(", ")}
                  backgroundColor={recipe.aiUpdatedFields.length > 0 ? "yellow" : undefined}
                />
                <NvpTable name="Ingestion Source" value={recipe.ingestionSource ?? "-"} />
                <NvpTable name="Full Access" value={JSON.stringify(recipe.faInfo)} />
                <NvpTable name="Search Indexing">
                  <IndexComponent recipe={recipe} />
                </NvpTable>
                <NvpTable
                  name="Date Published"
                  value={recipe.datePublished ? new Date(recipe.datePublished).toLocaleString() : "-"}
                />
                <NvpTable
                  name="Date Modified"
                  value={recipe.dateModified ? new Date(recipe.dateModified).toLocaleString() : "-"}
                />
                <NvpTable name="">
                  <TBody>
                    <Link to={AdminRecipeDocumentsScreenNav.getPath(recipe.id)} target="_blank">
                      Docs
                    </Link>
                  </TBody>
                </NvpTable>
              </>
            )}

            {recipe.type === "externalBookRecipe" && (
              <>
                <NvpTable name="Full Access" value={recipe.faInfo ? JSON.stringify(recipe.faInfo) : "-"} />
                <NvpTable name="Search Indexing">
                  <IndexComponent recipe={recipe} />
                </NvpTable>
                <Spacer vertical={2} />
                <BookComponent book={recipe.book} />
              </>
            )}
            <Spacer vertical={2} />
            <tr>
              <td>
                <TBody fontWeight="heavy">Recipe</TBody>
              </td>
            </tr>
            {recipe.type === "externalUrlRecipe" && (
              <>
                <NvpTable name="URL">
                  <TBody>
                    <a href={recipe.canonicalUrl} target="_blank" rel="noreferrer">
                      {recipe.canonicalUrl}
                    </a>
                  </TBody>
                </NvpTable>
                <NvpTable name="Not Canonical" value={`${recipe.noCanonicalUrl}`} />
              </>
            )}
            {otherUrls.map(u => {
              return (
                <NvpTable name="Secondary URL" opacity={0.5} key={u}>
                  <TBody>
                    <a href={u} target="_blank" rel="noreferrer">
                      {u}
                    </a>
                  </TBody>
                </NvpTable>
              );
            })}
            <NvpTable name="Title">
              <THeading2>{recipe.title}</THeading2>
            </NvpTable>
            <Divider />
            <NvpTable name="Photo">
              <PhotoComponent photo={recipe.photo} size="large" />
            </NvpTable>
            <Divider />
            <NvpTable name="Author">
              <AuthorComponent
                author={recipe.author}
                parsedAuthor={recipe.type === "externalUrlRecipe" ? recipe.parsedAuthor : undefined}
              />
            </NvpTable>
            <Divider />
            <NvpTable name="Publisher">
              <PublisherComponent publisher={recipe.publisher} />
            </NvpTable>
            <Divider />
            <NvpTable name="Description">
              <TBody>{recipe.description}</TBody>
            </NvpTable>
            <Divider />
            <NvpTable name="Time">
              <TimeComponent
                time={recipe.time}
                timeRaw={recipe.type === "externalUrlRecipe" ? recipe.rawTime : undefined}
              />
            </NvpTable>
            <Divider />
            <NvpTable name="Yield">
              <YieldComponent
                yield={recipe.recipeYield}
                yieldRaw={recipe.type === "externalUrlRecipe" ? recipe.rawRecipeYield : undefined}
              />
            </NvpTable>
            <Divider />
            <NvpTable name="Tags">
              <TagsComponent manifest={props.tagManifest} tags={recipe.tags} />
            </NvpTable>
            <Divider />
            <NvpTable name="Ingredients">
              <Ingredients ingredients={recipe.ingredients} />
            </NvpTable>
            <Divider />
            <NvpTable name="Instructions">
              <Instructions instructions={recipe.instructions} />
            </NvpTable>
            <Divider />
            <NvpTable name="Categorized">
              <CategorizedIngredients items={props.parsed} overrides={overrides} onOverride={onCategoryOverride} />
            </NvpTable>
          </tbody>
        </table>
        {props.recipeCopies && (
          <THeading2>
            {props.recipeCopies.copies.length} {props.recipeCopies.copies.length === 1 ? "copy" : "copies"}{" "}
            {`${props.recipeCopies.mightBeMore ? "(or more)" : ""}`}
          </THeading2>
        )}
        {props.recipeCopies?.copies.map(c => {
          return (
            <View style={{ flexDirection: "row" }} key={c.recipeId}>
              <RecipeLink id={c.recipeId} />
              <Spacer horizontal={1} />
              {(!!c.deleted || !!c.archived) && <TBody> (archived or deleted)</TBody>}
            </View>
          );
        })}
      </View>
    );
  }
);
