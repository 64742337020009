/**
 * Return a random integer between min and max, inclusive.
 * @param min
 * @param max
 */
export function randomInteger(min: number, max: number): number {
  if (!Number.isInteger(min) || !Number.isInteger(max) || min > max) {
    throw new Error(`Invalid args for randomInteger. Min: ${min} max: ${max}`);
  }

  return Math.floor(Math.random() * (max - min + 1)) + min;
}
