import { bottomNop, emptyToUndefined, getFormattedDuration } from "@eatbetter/common-shared";
import {
  RecipeIngredients,
  RecipeInstructions,
  RecipeTime,
  RecipeYield,
  AppRecipe,
  RecipeIngredient,
  RecipeInstruction,
} from "@eatbetter/recipes-shared";
import React, { useCallback } from "react";
import { View } from "react-native";
import { ContainerPadded } from "../Containers";
import { Spacer } from "../Spacer";
import { THeading2, TSecondary, TTertiary } from "../Typography";
import { RecipeSectionHeading } from "./RecipeSectionHeading";
import { RecipeDetailTitleAndSource } from "./RecipeTitleAndSource";
import { Separator } from "../Separator";
import { BuyBookButton } from "../Buttons";
import { RecipeDetailPhotos } from "../RecipePhoto";
import { globalStyleConstants } from "../GlobalStyles";
import { Haptics } from "../Haptics";
import { usePaywallStatus } from "../PaywallDetector";
import { useRecipeInstructionsAccess } from "../../lib/recipes/UseRecipeInstructionsAccess";
import { HiddenWebInstructionsMessage } from "./HiddenInstructionsMessage";
import { useGetSocialMediaDomainType, useRecipeSourceUrl } from "../../lib/recipes/RecipesSelectors";
import { log } from "../../Log";
import { openWebpage } from "../../lib/util/WebUtil";
import { RecipeYieldDisplay } from "./RecipeYield";
import { ScalingAndConversionsButton } from "./ScalingAndConversions";
import { useScaled } from "../../lib/recipes/UseScaled";
import { ModifiableRecipeText } from "./RecipeText";
import { useUserSetting } from "../../lib/system/SystemSelectors";
import { Spinner } from "../Spinner";

const strings = {
  ingredients: "Ingredients",
  instructions: "Instructions",
  noIngredients: "(No ingredients)",
  noInstructions: "(No instructions)",
  totalTime: "Total Time",
  activeTime: "Active Time",
  noScaling: "Scaling unavailable (offline)",
};

interface Props {
  recipe: AppRecipe;
  toggleReaderMode?: () => void;
  recipeScale: number;
  setRecipeScale?: (v: number) => void;
  waitingScalingData?: boolean;
  fetchScalingDataErrored?: boolean;
}

export const RecipeDetailContent = React.memo((props: Props) => {
  const publisher = props.recipe.publisher;
  const author = props.recipe.author;
  const book = props.recipe.book;
  const title = props.recipe.title;
  const source = props.recipe.source;
  const bookPurchaseLink = props.recipe.book?.purchaseLink;
  const ingredients = props.recipe.ingredients;
  const recipeYield = props.recipe.recipeYield;
  const recipeTime = props.recipe.time;

  const instructionsAccess = useRecipeInstructionsAccess(props.recipe);
  const { paywallIsUp } = usePaywallStatus(props.recipe);

  // if there's a paywall, don't render description
  const showDescription = emptyToUndefined(props.recipe.description) !== undefined && !paywallIsUp;
  const description = showDescription ? props.recipe.description : undefined;

  const isSocialMediaRecipe = !!useGetSocialMediaDomainType(props.recipe);
  const recipeUrl = useRecipeSourceUrl(props.recipe);

  // Show scaling control only if we have scaling info AND if a setter was passed in via props
  const showScalingControl = !!props.recipe.hasScalingInfo && !!props.setRecipeScale;

  const onPressSocialMediaRecipePhoto = useCallback(() => {
    if (!isSocialMediaRecipe) {
      log.error("onPressSocialMediaRecipePhoto called but isSocialMediaRecipe is falsy. Ignoring");
      return;
    }
    if (!recipeUrl) {
      log.error("onPressSocialMediaRecipePhoto called but recipeUrl is falsy. Ignoring");
      return;
    }
    openWebpage(recipeUrl);
  }, [isSocialMediaRecipe, recipeUrl]);

  const onPressPublisherLink = useCallback(() => {
    if (props.toggleReaderMode) {
      props.toggleReaderMode();
      Haptics.feedback("itemStatusChanged");
    }
  }, [props.toggleReaderMode]);

  const getInstructionsAndEmptyMessage = () => {
    switch (instructionsAccess.type) {
      case "show":
        return { instructions: props.recipe.instructions, emptyMessage: strings.noInstructions };
      case "hide":
        return {
          instructions: undefined,
          emptyMessage: (
            <HiddenWebInstructionsMessage
              context="xRay"
              hiddenReason={instructionsAccess.reason}
              onPressLink={onPressPublisherLink}
              publisherName={publisher?.name}
            />
          ),
        };
      default: {
        bottomNop(instructionsAccess);
        return { instructions: props.recipe.instructions, emptyMessage: strings.noInstructions };
      }
    }
  };

  const { instructions: recipeInstructions, emptyMessage: instructionsEmptyMessage } = getInstructionsAndEmptyMessage();

  const showBuyBookButton = source.type === "book" && !!bookPurchaseLink;

  return (
    <ContainerPadded all={globalStyleConstants.minPadding / globalStyleConstants.unitSize}>
      <RecipeDetailPhotos
        recipe={props.recipe}
        onPress={isSocialMediaRecipe ? onPressSocialMediaRecipePhoto : "expand"}
      />
      <ContainerPadded horizontal={1} bottom={3}>
        <RecipeDetailTitleAndSource
          title={title}
          publisher={publisher}
          book={book}
          author={author}
          sourceType={source.type}
          isSocialMediaRecipe={isSocialMediaRecipe}
        />
        {showBuyBookButton && (
          <>
            <Spacer vertical={1} />
            <BuyBookButton recipe={props.recipe} bookPurchaseLink={bookPurchaseLink} />
          </>
        )}
        {!!description && (
          <>
            <Spacer vertical={1.5} />
            <TSecondary>{description}</TSecondary>
          </>
        )}
        {(recipeYield || recipeTime?.total) && (
          <>
            <Spacer vertical={1.5} />
            <Separator orientation="row" />
            <Spacer vertical={1.5} />
            <RecipeTimeYield recipeTime={recipeTime} recipeYield={recipeYield} recipeScale={props.recipeScale} />
          </>
        )}
        <Spacer vertical={1.5} />
        <Separator orientation="row" />
        <Spacer vertical={2} />
        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
          <View>
            <THeading2>{strings.ingredients}</THeading2>
          </View>
          {showScalingControl && props.setRecipeScale && (
            <ScalingAndConversionsButton
              recipeTitle={title}
              recipeYield={recipeYield}
              scale={props.recipeScale}
              onChangeScale={props.setRecipeScale}
              showLabel
              strokeWidth="medium"
            />
          )}
          {!showScalingControl && !!props.waitingScalingData && <Spinner />}
          {!showScalingControl && !props.waitingScalingData && !!props.fetchScalingDataErrored && (
            <>
              <Spacer horizontal={1} />
              <View style={{ flexShrink: 1 }}>
                <TTertiary align="center" opacity="medium" italic numberOfLines={1}>
                  {strings.noScaling}
                </TTertiary>
              </View>
            </>
          )}
        </View>
        <Spacer vertical={1.5} />
        <RecipeIngredientsComponent ingredients={ingredients} recipeScale={props.recipeScale ?? 1} />
        <Spacer vertical={1.5} />
        <Separator orientation="row" />
        <Spacer vertical={2} />
        <THeading2>{strings.instructions}</THeading2>
        <Spacer vertical={1.5} />
        <RecipeInstructionsComponent
          instructions={recipeInstructions}
          emptyMessage={instructionsEmptyMessage}
          recipeScale={props.recipeScale ?? 1}
        />
      </ContainerPadded>
    </ContainerPadded>
  );
});

const RecipeIngredientsComponent = React.memo(
  (props: { ingredients: RecipeIngredients | undefined; recipeScale: number }) => {
    const ingredients = props.ingredients;

    if (!ingredients) {
      return <EmptyIngredientsOrInstructions emptyMessage={strings.noIngredients} />;
    }

    return (
      <>
        {ingredients.sections.map((section, idx) => (
          <View key={section.id}>
            <ContainerPadded top={idx !== 0 ? 1 : 0} bottom={section.title || idx !== 0 ? 1 : 0}>
              <RecipeSectionHeading key={section.id} size="secondary" sectionIndex={idx} text={section.title} />
            </ContainerPadded>
            {section.items.map((i, idx) => (
              <IngredientOrInstructionItem key={`${i.id}_${idx}`} text={i} index={idx} scale={props.recipeScale} />
            ))}
          </View>
        ))}
      </>
    );
  }
);

const RecipeInstructionsComponent = React.memo(
  (props: {
    instructions: RecipeInstructions | undefined;
    emptyMessage: string | React.ReactElement;
    recipeScale: number;
  }) => {
    if (!props.instructions || props.instructions.sections.length === 0) {
      return <EmptyIngredientsOrInstructions emptyMessage={props.emptyMessage} />;
    }

    return (
      <>
        {props.instructions.sections.map((section, idx) => (
          <View key={section.id}>
            <ContainerPadded top={idx !== 0 ? 1 : 0} bottom={section.title || idx !== 0 ? 1 : 0}>
              <RecipeSectionHeading key={section.id} size="secondary" sectionIndex={idx} text={section.title} />
            </ContainerPadded>
            {section.items.map((i, idx) => (
              <IngredientOrInstructionItem key={`${i.id}_${idx}`} text={i} index={idx} scale={props.recipeScale} />
            ))}
          </View>
        ))}
      </>
    );
  }
);

const IngredientOrInstructionItem = React.memo(
  (props: { index: number; text: RecipeIngredient | RecipeInstruction; scale: number }) => {
    const unitConversion = useUserSetting("unitConversion");
    const scaled = useScaled(props.text, props.scale, unitConversion);

    return (
      <View>
        {props.index !== 0 && <Spacer vertical={1} />}
        <ModifiableRecipeText tokens={scaled} fontSize="secondary" />
      </View>
    );
  }
);

const EmptyIngredientsOrInstructions = React.memo((props: { emptyMessage: string | React.ReactElement }) => {
  return (
    <>
      {typeof props.emptyMessage === "string" && <TSecondary>{props.emptyMessage}</TSecondary>}
      {typeof props.emptyMessage !== "string" && props.emptyMessage}
    </>
  );
});

const RecipeTimeYield = React.memo(
  (props: { recipeTime: RecipeTime | undefined; recipeYield: RecipeYield | undefined; recipeScale: number }) => {
    const unitConversion = useUserSetting("unitConversion");

    return (
      <View>
        {!!props.recipeTime && !!props.recipeYield && <Spacer vertical={1} />}
        {!!props.recipeYield && (
          <RecipeYieldDisplay yield={props.recipeYield} recipeScale={props.recipeScale} recipeUnits={unitConversion} />
        )}
        {!!props.recipeTime && !!props.recipeYield && <Spacer vertical={1} />}
        {!!props.recipeTime && (
          <View>
            <TSecondary>{`${strings.totalTime}: ${getFormattedDuration(
              { milliseconds: props.recipeTime.total[0] },
              false,
              "XXh:XXm"
            )}`}</TSecondary>
          </View>
        )}
      </View>
    );
  }
);
