import React, { useCallback, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { BottomActionBar } from "../components/BottomActionBar";
import { globalStyleColors, globalStyleConstants } from "../components/GlobalStyles";
import { Haptics } from "../components/Haptics";
import { useScreenHeaderDimensions } from "../components/ScreenHeaders";
import { ScreenView } from "../components/ScreenView";
import { Spacer } from "../components/Spacer";
import { TextInput, TextInputProps } from "../components/TextInput";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { editRecipeTag } from "../lib/recipes/RecipesThunks";
import { useUserTags } from "../lib/recipes/RecipeTagSelectors";
import { useDispatch } from "../lib/redux/Redux";
import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { RecipeTagAddNewScreenProps } from "../navigation/NavTree";

const strings = {
  screenTitle: "New Tag",
  placeholderText: 'e.g "Emily\'s Favorites"',
  save: "Save",
  dispatchError: "We're having trouble connecting and couldn't save your tag",
};

export const RecipeTagAddNewScreen = withNonNavigableScreenContainer(
  "RecipeTagAddNewScreen",
  (props: RecipeTagAddNewScreenProps) => {
    const screen = useScreen();
    const dispatch = useDispatch();

    const [text, setText] = useState("");
    const [waiting, setWaiting] = useState(false);

    const userTags = useUserTags();

    const onSubmit = useCallback(() => {
      const newTag = text.trim();

      // It could be annoying to get casing wrong and end up with dup tags. Check and just
      // return if we find a tag with the same casing already.
      if (userTags.some(i => i.toLowerCase() === newTag.toLowerCase())) {
        Haptics.feedback("itemAdded");
        screen.nav.goBack();
        return;
      }

      dispatch(
        editRecipeTag(
          {
            tag: { type: "user", tag: newTag },
            action: "add",
            recipeId: props.recipeId,
          },
          props.recipeEditFieldLocation,
          setWaiting
        )
      )
        .then(() => {
          Haptics.feedback("operationSucceeded");
          screen.nav.goBack();
        })
        .catch(err => {
          displayUnexpectedErrorAndLog(strings.dispatchError, err, {
            recipeId: props.recipeId,
            tag: newTag,
          });
        });
    }, [text, dispatch, setWaiting, props.recipeId, props.recipeEditFieldLocation, userTags]);

    const onCancel = useCallback(() => {
      screen.nav.goBack();
    }, [screen.nav.goBack]);

    const { modalHeaderHeight } = useScreenHeaderDimensions();

    return (
      <ScreenView
        backgroundColor={globalStyleColors.colorGrey}
        scrollView={false}
        paddingHorizontal={false}
        paddingVertical={false}
        header={{
          type: "native",
          title: strings.screenTitle,
          right: { type: "cancel", onPress: onCancel },
        }}
        isModal
      >
        <ScrollView
          keyboardShouldPersistTaps="always"
          contentContainerStyle={{ paddingTop: modalHeaderHeight + 96, justifyContent: "center" }}
        >
          <TagTextInput
            value={text}
            onChangeText={setText}
            placeholderText={strings.placeholderText}
            onSubmit={onSubmit}
          />
          <Spacer vertical={1} />
          <SubmitButton onPress={onSubmit} waiting={waiting} disabled={!text} />
        </ScrollView>
      </ScreenView>
    );
  }
);

const TagTextInput = React.memo(
  (props: Pick<TextInputProps, "value" | "placeholderText" | "onChangeText" | "onSubmit">) => {
    return (
      <View style={{ paddingHorizontal: 1.5 * globalStyleConstants.unitSize }}>
        <View style={styles.textInput}>
          <TextInput
            value={props.value}
            placeholderText={props.placeholderText}
            onChangeText={props.onChangeText}
            onSubmit={props.onSubmit}
            autoFocus
            noBorder
            noPadding
          />
        </View>
      </View>
    );
  }
);

const SubmitButton = React.memo((props: { onPress: () => void; waiting?: boolean; disabled: boolean }) => {
  return (
    <View>
      <BottomActionBar
        primaryAction={{
          actionText: strings.save,
          waiting: props.waiting,
          onPressAction: props.onPress,
          disabled: props.disabled,
        }}
        containerBackgroundColor="transparent"
        disableSnapToBottom
      />
    </View>
  );
});

const styles = StyleSheet.create({
  textInput: {
    backgroundColor: "white",
    height: 48,
    paddingHorizontal: 1.5 * globalStyleConstants.unitSize,
    borderRadius: 24,
    justifyContent: "center",
  },
});
