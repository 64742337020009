import { ScreenView } from "../components/ScreenView";
import { NewUserSurveyScreenProps } from "../navigation/NavTree";
import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { navHome } from "../navigation/NavThunks";
import React, { useCallback } from "react";
import { Pressable } from "../components/Pressable";
import { TBody, THeading1 } from "../components/Typography";
import { globalStyleColors, globalStyleConstants } from "../components/GlobalStyles";
import { ScrollView, StyleSheet } from "react-native";
import { Spacer } from "../components/Spacer";
import { BottomActionBar } from "../components/BottomActionBar";
import { Haptics } from "../components/Haptics";
import { reportSurveyCompleted, reportSurveyDismissed } from "../lib/analytics/AnalyticsEvents";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { useSurveyOptions } from "../components/SurveyOptions";
import { useDispatch } from "../lib/redux/Redux";

const strings = {
  surveyName: "New User Survey v1.3",
  skip: "Skip",
  headline: "What do you want most from Deglaze?",
  surveyOptions: [
    "Easier grocery lists + shopping",
    "Organize all my recipes in one place",
    "Clutter-free cooking mode with timers",
    "Get meal inspiration from my friends",
    "Share a grocery list with my household",
    "I'm not sure",
  ] as const,
  submit: "Let's Go",
};

const surveyData = [
  {
    key: "grocery",
    displayText: strings.surveyOptions[0],
  },
  {
    key: "recipes",
    displayText: strings.surveyOptions[1],
  },
  {
    key: "cooking",
    displayText: strings.surveyOptions[2],
  },
  {
    key: "social",
    displayText: strings.surveyOptions[3],
  },
  {
    key: "household",
    displayText: strings.surveyOptions[4],
  },
  {
    key: "unknown",
    displayText: strings.surveyOptions[5],
  },
];

export const NewUserSurveyScreen = withNonNavigableScreenContainer<NewUserSurveyScreenProps>(
  "NewUserSurveyScreen",
  props => {
    const dispatch = useDispatch();
    const screen = useScreen();

    const surveyOptions = useSurveyOptions("singleSelect", surveyData);
    const selectedOption = surveyOptions.selected[0];
    const renderSurveyOptions = surveyOptions.renderSurveyOptions;

    const navigateHome = useCallback(() => {
      dispatch(navHome(screen.nav, "CreateAccountScreen", props.redirectPath));
    }, [dispatch, props.redirectPath]);

    const onDismissSurvey = useCallback(() => {
      const event = reportSurveyDismissed({
        surveyName: strings.surveyName,
        surveyOptions: surveyData.map(i => i.displayText),
      });
      dispatch(analyticsEvent(event));
      Haptics.feedback("itemStatusChanged");
      navigateHome();
    }, [dispatch, navigateHome]);

    const onPressSubmit = useCallback(() => {
      if (!selectedOption) {
        return;
      }

      const event = reportSurveyCompleted({
        surveyName: strings.surveyName,
        surveyOptions: surveyData.map(i => i.key),
        surveySelection: selectedOption.key,
        surveySelectionIndex: selectedOption.index,
      });
      dispatch(analyticsEvent(event));
      Haptics.feedback("operationSucceeded");
      navigateHome();
    }, [selectedOption, dispatch, navigateHome]);

    return (
      <ScreenView scrollView={false}>
        <SkipButton onPress={onDismissSurvey} />
        <ScrollView contentContainerStyle={{ flex: 1 }}>
          <Spacer vertical={1} />
          <THeading1 align="center">{strings.headline}</THeading1>
          <Spacer vertical={2} />
          {renderSurveyOptions()}
        </ScrollView>
        <BottomActionBar
          primaryAction={{ actionText: strings.submit, onPressAction: onPressSubmit, disabled: !selectedOption }}
          containerBackgroundColor="transparent"
        />
      </ScreenView>
    );
  }
);

const SkipButton = React.memo((props: { onPress: () => void }) => {
  return (
    <Pressable style={styles.skipButton} onPress={props.onPress}>
      <TBody color={globalStyleColors.colorAccentCool}>{strings.skip}</TBody>
    </Pressable>
  );
});

const styles = StyleSheet.create({
  skipButton: {
    width: "100%",
    paddingBottom: globalStyleConstants.unitSize,
    alignItems: "flex-end",
  },
});
