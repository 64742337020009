import { KnownAuthorId } from "@eatbetter/recipes-shared";
import { KnownAuthorScreenProps } from "../navigation/NavTree";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HeaderProps } from "../components/ScreenHeaders";
import { KnownEntityRecipeCollection } from "../components/social/KnownRecipeCollection";
import { useSearchIsLoading, useServerRecipeResultCount, useServerSearchResults } from "../lib/search/SearchSelectors";
import { useDispatch } from "../lib/redux/Redux";
import { fetchNextSearchResults, navToSearchQuery } from "../lib/search/SearchThunks";
import { useSearchSession } from "../lib/search/SearchHooks";
import { useOrFetchKnownAuthorProfile } from "../lib/social/SocialHooks";
import { KnownEntityCard } from "../components/social/KnownEntityCard";
import { ProfileCard } from "../components/social/ProfileCard";
import { UrlString, Username, filterOutFalsy } from "@eatbetter/common-shared";
import React from "react";
import { TBody } from "../components/Typography";
import { View } from "react-native";
import { Spacer } from "../components/Spacer";
import { VerifiedBadge } from "../components/social/VerifiedBadge";
import { BookCarousel } from "../components/BookCarousel";
import { Separator } from "../components/Separator";
import { globalStyleConstants } from "../components/GlobalStyles";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { KnownEntityPageViewedFrom, reportKnownEntityPageViewed } from "../lib/analytics/AnalyticsEvents";
import { getEntityDisplayText } from "@eatbetter/posts-shared";

const strings = {
  screenTitle: "Recipe Collection",
  searchPlaceholder: (entityName?: string) =>
    `Search ${getEntityDisplayText({ type: "knownAuthor", name: entityName ?? "Author" }).headline}`,
};

export const KnownAuthorScreen = withScreenContainer<KnownAuthorScreenProps>(
  "KnownAuthorScreen",
  props => {
    const dispatch = useDispatch();
    const screen = useScreen();

    const [waitingLoadAuthor, setWaitingLoadAuthor] = useState(false);
    const knownAuthor = useOrFetchKnownAuthorProfile(props.id, setWaitingLoadAuthor);

    const browseSearchSessionId = useSearchSession({ authorId: props.id, initialLoad: true });
    const { recipes } = useServerSearchResults(browseSearchSessionId);
    const recipeCount = useServerRecipeResultCount(browseSearchSessionId);
    const loadingResults = useSearchIsLoading(browseSearchSessionId);

    const alreadyReportedView = useRef(false);

    useEffect(() => {
      if (!alreadyReportedView.current && knownAuthor?.author && screen.nav.focused) {
        const event = reportKnownEntityPageViewed({ entity: knownAuthor.author, from: props.analyticsContext });
        dispatch(analyticsEvent(event));
        alreadyReportedView.current = true;
      }
    }, [knownAuthor?.author, screen.nav.focused]);

    const loadNextBatch = useCallback(async () => {
      // Only fetch if we're not at the end of the list to avoid multiple refreshes in zero / low result count scenarios
      if (recipeCount && recipes.length < recipeCount.count) {
        await dispatch(fetchNextSearchResults(browseSearchSessionId));
      }
    }, [dispatch, recipeCount, recipes.length]);

    const onPressSearchBox = useCallback(() => {
      dispatch(navToSearchQuery({ nav: screen.nav, from: "knownAuthorProfile", knownEntity: knownAuthor?.author }));
    }, [screen.nav, knownAuthor?.author]);

    const feedHeader = useCallback(() => {
      if (!knownAuthor) {
        return <></>;
      }

      return (
        <>
          {!!knownAuthor.user && knownAuthor.mode === "active" && (
            <>
              <ProfileCard
                entityId={knownAuthor.author.id}
                otherUserId={knownAuthor.user.userId}
                photo={knownAuthor.author.photo}
                bannerPhoto={knownAuthor.bannerPhoto}
                name={knownAuthor.author.name}
                username={knownAuthor.user.username}
                profileBio={knownAuthor.profileBio}
                profileLinks={{
                  instagramHandle: knownAuthor.links?.instagramHandle,
                  substackLink: knownAuthor.links?.substack as UrlString,
                  links: filterOutFalsy([
                    knownAuthor.links?.website as UrlString | undefined,
                    ...(knownAuthor.links?.otherLinks?.map(i => i.url) ?? []),
                  ]),
                }}
              />
              {!!knownAuthor.books && (
                <>
                  <Spacer vertical={0.5} />
                  <BookCarousel
                    books={knownAuthor.books.flatMap(i => {
                      if (!i.photo || !i.purchaseLink) {
                        return [];
                      }
                      return [
                        {
                          title: i.name,
                          author: knownAuthor.author.name,
                          photo: i.photo,
                          purchaseLink: i.purchaseLink,
                        },
                      ];
                    })}
                  />
                  <Spacer vertical={1} />
                  <View style={{ paddingHorizontal: globalStyleConstants.defaultPadding }}>
                    <Separator orientation="row" />
                  </View>
                  <Spacer vertical={1} />
                </>
              )}
            </>
          )}
          {!(knownAuthor.user && knownAuthor.mode === "active") && (
            <KnownEntityCard id={props.id} profileInfo={knownAuthor.author} context="knownAuthor" />
          )}
        </>
      );
    }, [knownAuthor, props.id]);

    const screenHeader = useMemo<HeaderProps>(() => {
      return {
        type: "default",
        title: knownAuthor?.user
          ? () => <>{!!knownAuthor.user && <KnownUserAuthorTitle username={knownAuthor.user.username} />}</>
          : strings.screenTitle,
        backgroundColor: "white",
      };
    }, [knownAuthor?.user]);

    return (
      <ScreenView
        header={screenHeader}
        scrollView={false}
        paddingHorizontal={false}
        paddingVertical="headerAndBottomTabBar"
        loading={waitingLoadAuthor}
      >
        <KnownEntityRecipeCollection
          searchPlaceholderText={strings.searchPlaceholder(knownAuthor?.author.name)}
          recipes={recipes}
          onEndReached={loadNextBatch}
          recipesLoading={loadingResults}
          recipeCount={recipeCount?.count}
          maxRecipeCountExceeded={recipeCount?.maxExceeded}
          onPressSearchBox={onPressSearchBox}
          renderListHeader={feedHeader}
          searchSessionId={browseSearchSessionId}
        />
      </ScreenView>
    );
  },
  {
    serializer: {
      analyticsContext: s => s,
      id: s => s,
    },
    parser: {
      analyticsContext: s => s as KnownEntityPageViewedFrom,
      id: s => s as KnownAuthorId,
    },
  }
);

const KnownUserAuthorTitle = React.memo((props: { username: Username }) => {
  return (
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <TBody fontWeight="medium">{props.username}</TBody>
      <Spacer horizontal={0.5} />
      <VerifiedBadge />
    </View>
  );
});
