import { View } from "react-native";
import { SegmentedControl, getSegmentedControlHeight } from "./SegmentedControl";
import React, { useCallback, useMemo } from "react";
import { useCheckpointCompleted } from "../lib/system/SystemSelectors";
import { checkpointsCompleted } from "../lib/system/SystemSlice";
import { useDispatch } from "../lib/redux/Redux";
import { WalkthroughStep } from "./Walkthrough";
import { THeading2, TSecondary } from "./Typography";
import { Spacer } from "./Spacer";
import { ReaderModeWalkthrough } from "../lib/recipes/UseReaderMode";
import { bottomThrow } from "@eatbetter/common-shared";

const strings = {
  web: "Web",
  readerMode: "X-ray",
  walkthroughTapXrayHead: "X-ray Mode",
  walkthroughTapXraySub:
    "We load the web page by default to support authors + publishers. Tap X-ray to see just the recipe.",
  walkthroughExplainXrayButton: "Enable X-ray",
};

export const readerModeToggleConstants = {
  width: 128,
  height: getSegmentedControlHeight("small"),
};

export type ReaderModeSpotlightContext =
  | "spotlightLibraryReaderMode"
  | "spotlightPostViewRecipeReaderMode"
  | "spotlightShareViewRecipeReaderMode"
  | "spotlightSearchViewRecipeReaderMode";

export function useReaderModeToggle(
  isReaderMode: boolean,
  setIsReaderMode: (v: boolean) => void,
  disabled: boolean,
  walkthrough?: ReaderModeWalkthrough
) {
  const dispatch = useDispatch();
  const recipeReaderModeToggled = useCheckpointCompleted("recipeReaderModeToggled");

  const onPress = useCallback(() => {
    setIsReaderMode(!isReaderMode);
    if (!recipeReaderModeToggled) {
      dispatch(checkpointsCompleted(["recipeReaderModeToggled"]));
    }
  }, [dispatch, isReaderMode, setIsReaderMode, recipeReaderModeToggled]);

  return useMemo(
    () => <ReaderModeToggle value={isReaderMode} onPress={onPress} disabled={disabled} walkthrough={walkthrough} />,
    [isReaderMode, onPress, disabled, walkthrough]
  );
}

interface ReaderModeToggleProps {
  value: boolean;
  onPress: () => void;
  disabled: boolean;
  walkthrough?: ReaderModeWalkthrough;
}

export const ReaderModeToggle = React.memo((props: ReaderModeToggleProps) => {
  const walkthrough = useMemo(() => {
    if (!props.walkthrough) {
      return undefined;
    }

    switch (props.walkthrough.step) {
      case "tapXray": {
        const messageText = (
          <>
            <THeading2>{strings.walkthroughTapXrayHead}</THeading2>
            <Spacer vertical={0.5} />
            <TSecondary align="center">{strings.walkthroughTapXraySub}</TSecondary>
          </>
        );
        const onPressXray = props.walkthrough.onPressNext;
        const maxWidth = "70%" as const;
        return { messageText, onPressXray, maxWidth };
      }
      default:
        bottomThrow(props.walkthrough.step);
    }
  }, [props.walkthrough]);

  const readerModeToggle = (
    <View style={{ backgroundColor: "transparent" }}>
      <SegmentedControl
        height="small"
        width={readerModeToggleConstants.width}
        disabled={props.disabled}
        segments={[
          {
            label: strings.web,
            onPress: props.onPress,
            isSelected: !props.value,
          },
          {
            label: strings.readerMode,
            onPress: props.onPress,
            isSelected: props.value,
          },
        ]}
      />
    </View>
  );

  return walkthrough ? (
    <WalkthroughStep
      show={!!props.walkthrough}
      message={walkthrough.messageText}
      onPressChildComponent={walkthrough.onPressXray}
      buttonText={"gotIt"}
      maxWidth={walkthrough.maxWidth}
    >
      {readerModeToggle}
    </WalkthroughStep>
  ) : (
    readerModeToggle
  );
});
