import React, { useCallback, useImperativeHandle, useMemo, useRef } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { AppRecipeTag } from "../lib/recipes/RecipesSlice";
import { useTagManifest } from "../lib/recipes/RecipeTagSelectors";
import { globalStyleColors, globalStyleConstants } from "./GlobalStyles";
import { IconEx, IconFilter, IconPlus, IconSearch } from "./Icons";
import { Pressable } from "./Pressable";
import { getTagDisplayLabel, SelectableTagGroup } from "./recipes/RecipeTagSelect";
import { Separator } from "./Separator";
import { Spacer } from "./Spacer";
import { TextInput, TextInputHandle, TextInputProps } from "./TextInput";
import { TSecondary } from "./Typography";
import { SelectableOvalRenderBadge } from "./SelectableOval";
import { RecipeTagId } from "@eatbetter/recipes-shared";
import { switchReturn } from "@eatbetter/common-shared";

const strings = {
  cancel: "Cancel",
  totalPrefix: "Under ",
  activePrefix: "Active: ",
};

const constants = {
  searchBarHeight: 72,
  filterBarHeight: 44,
};

export function useSearchAndFilterBarHeight(activeTagFilters?: AppRecipeTag[], showSuggestions?: boolean) {
  if ((activeTagFilters && activeTagFilters.length > 0) || !!showSuggestions) {
    return constants.searchBarHeight + constants.filterBarHeight;
  }

  return constants.searchBarHeight;
}

function getFilterSuggestions(): AppRecipeTag[] {
  return [
    {
      type: "system",
      tag: "main" as RecipeTagId,
    },
    {
      type: "system",
      tag: "dessert" as RecipeTagId,
    },
    {
      type: "system",
      tag: "breakfast" as RecipeTagId,
    },
    {
      type: "system",
      tag: "side" as RecipeTagId,
    },
    {
      type: "system",
      tag: "appetizer" as RecipeTagId,
    },
  ];
}

export type SearchAndFilterBarProps = {
  searchPhrase?: string;
  onChangeSearchPhrase?: (v: string) => void;
  onPressFilterButton?: () => void;
  onPressAddFilterButton?: () => void;
  activeTagFilters?: AppRecipeTag[];
  onRemoveFilter?: (filter: AppRecipeTag) => void;
  onPressFilterSuggestion?: (filter: AppRecipeTag) => void;
  showCancelButton?: boolean;
  onPressCancel?: () => void;
  onPressSubmit?: () => void;
  editable?: boolean;
  onPressSearchBox?: () => void;
  onFocus?: () => void;
  keyboardReturnKeyType?: "done" | "search";
  paddingHorizontal?: "default" | "none";
} & Pick<TextInputProps, "placeholderText" | "autoFocus">;

export const SearchAndFilterBar = React.memo(
  React.forwardRef<TextInputHandle, SearchAndFilterBarProps>((props, ref) => {
    const hasActiveFilters = !!props.activeTagFilters && props.activeTagFilters.length > 0;
    const height = useSearchAndFilterBarHeight(props.activeTagFilters, !!props.onPressFilterSuggestion);

    return (
      <View style={{ height }}>
        <SearchBar
          ref={ref}
          searchPhrase={props.searchPhrase}
          onChangeSearchPhrase={props.onChangeSearchPhrase}
          onPressFilterButton={props.onPressFilterButton}
          placeholderText={props.placeholderText}
          autoFocus={props.autoFocus}
          onFocus={props.onFocus}
          hasActiveFilters={hasActiveFilters}
          onPressSubmit={props.onPressSubmit}
          showCancelButton={props.showCancelButton}
          onPressCancel={props.onPressCancel}
          editable={props.editable}
          onPressSearchBox={props.onPressSearchBox}
          keyboardReturnKeyType={props.keyboardReturnKeyType}
          paddingHorizontal={props.paddingHorizontal}
        />
        {hasActiveFilters && (
          <FiltersBar
            onPressFilterButton={props.onPressAddFilterButton ?? props.onPressFilterButton}
            activeTagFilters={props.activeTagFilters}
            onRemoveFilter={props.onRemoveFilter}
          />
        )}
        {!hasActiveFilters && !!props.onPressFilterSuggestion && (
          <FiltersBar
            onPressFilterButton={props.onPressAddFilterButton ?? props.onPressFilterButton}
            onPressFilterSuggestion={props.onPressFilterSuggestion}
          />
        )}
      </View>
    );
  })
);

type SearchProps = Pick<
  SearchAndFilterBarProps,
  | "searchPhrase"
  | "onChangeSearchPhrase"
  | "placeholderText"
  | "autoFocus"
  | "onPressFilterButton"
  | "showCancelButton"
  | "onPressCancel"
  | "onPressSubmit"
  | "onPressSearchBox"
  | "onFocus"
  | "editable"
  | "keyboardReturnKeyType"
  | "paddingHorizontal"
> & { hasActiveFilters: boolean };

const SearchBar = React.memo(
  React.forwardRef<TextInputHandle, SearchProps>((props, ref) => {
    const inputRef = useRef<TextInputHandle>(null);

    const editable = props.editable !== false;
    const showFilterButton = !props.hasActiveFilters && !!props.onPressFilterButton;
    const showCancelButton = !!props.showCancelButton;
    const keyboardReturnKey = props.keyboardReturnKeyType ?? "done";

    const onPressCancel = useCallback(() => {
      if (props.onPressCancel) {
        props.onPressCancel();
        inputRef.current?.blur();
      }
    }, [props.onPressCancel, inputRef]);

    const onPressSearchBox = useCallback(() => {
      props.onPressSearchBox?.();
    }, [props.onPressSearchBox]);

    useImperativeHandle(ref, () => inputRef.current!);

    const paddingHorizontal = switchReturn(props.paddingHorizontal ?? "default", {
      default: {},
      none: { paddingHorizontal: 0 },
    });

    return (
      <View style={[styles.searchBar, paddingHorizontal]}>
        <Pressable style={styles.searchBox} onPress={onPressSearchBox} noFeedback disabled={editable}>
          <IconSearch opacity="opaque" size={22} />
          <Spacer horizontal={0.25} />
          <View
            style={{
              flex: 1,
              paddingLeft: globalStyleConstants.unitSize / 2,
              pointerEvents: editable ? undefined : "none",
            }}
          >
            <TextInput
              ref={inputRef}
              value={props.searchPhrase}
              placeholderText={props.placeholderText}
              onChangeText={props.onChangeSearchPhrase}
              onSubmit={props.onPressSubmit}
              onFocus={props.onFocus}
              autoFocus={props.autoFocus}
              returnKeyType={keyboardReturnKey}
              backgroundColor={"transparent"}
              editable={editable}
              noBorder
              noPadding
            />
          </View>
        </Pressable>
        {showFilterButton && !!props.onPressFilterButton && <FilterButton onPress={props.onPressFilterButton} />}
        {showCancelButton && <CancelButton onPress={onPressCancel} marginLeftUnits={1} />}
      </View>
    );
  })
);

type FilterProps = Pick<
  SearchAndFilterBarProps,
  "activeTagFilters" | "onRemoveFilter" | "onPressFilterButton" | "onPressFilterSuggestion"
>;

const FiltersBar = React.memo((props: FilterProps) => {
  const tagManifest = useTagManifest();

  const onPressTag = useCallback(
    (tag: AppRecipeTag) => {
      if (props.activeTagFilters?.some(i => i.tag === tag.tag)) {
        props.onRemoveFilter?.(tag);
      }
      // If it's not an active tag, it must be a suggestion
      props.onPressFilterSuggestion?.(tag);
    },
    [props.activeTagFilters, props.onRemoveFilter, props.onPressFilterSuggestion]
  );

  const onPressAddFilter = useCallback(() => {
    props.onPressFilterButton?.();
  }, [props.onPressFilterButton]);

  const renderDeleteBadge = useCallback<SelectableOvalRenderBadge>(({ isSelected }) => {
    return <>{isSelected && <DeleteBadge />}</>;
  }, []);

  const renderTagLabel = useCallback(
    (tag: AppRecipeTag): string => {
      const defaultLabel = getTagDisplayLabel(tag, tagManifest);

      if (tag.type === "totalTime") {
        return `${strings.totalPrefix}${defaultLabel}`;
      }

      return defaultLabel;
    },
    [tagManifest]
  );

  const filterTagSuggestions = useMemo<AppRecipeTag[]>(() => {
    if (!props.onPressFilterSuggestion || (!!props.activeTagFilters && props.activeTagFilters.length > 0)) {
      return [];
    }
    return getFilterSuggestions();
  }, [props.onPressFilterSuggestion, props.activeTagFilters?.length]);

  return (
    <View style={styles.filterBar}>
      <AddFilterButton onPress={onPressAddFilter} marginBottomUnits={0.5} />
      <ScrollView
        horizontal
        bounces={false}
        showsHorizontalScrollIndicator={false}
        style={{ paddingHorizontal: globalStyleConstants.unitSize }}
        contentContainerStyle={{ paddingRight: 0.5 * globalStyleConstants.unitSize }}
        keyboardShouldPersistTaps="always"
      >
        <SelectableTagGroup
          tags={props.activeTagFilters ?? filterTagSuggestions ?? []}
          selectedTags={props.activeTagFilters ?? []}
          onPressTag={onPressTag}
          renderTagBadge={renderDeleteBadge}
          renderTagLabel={renderTagLabel}
        />
      </ScrollView>
      <View style={styles.filterRightBoundary} />
    </View>
  );
});

const FilterButton = React.memo((props: { onPress: () => void }) => {
  return (
    <Pressable style={styles.filterButton} onPress={props.onPress}>
      <IconFilter opacity="medium" />
    </Pressable>
  );
});

const AddFilterButton = React.memo(
  (props: { onPress: () => void; marginBottomUnits?: number; marginRightUnits?: number }) => {
    return (
      <View
        style={{
          alignItems: "center",
          flexDirection: "row",
          marginBottom: props.marginBottomUnits ? props.marginBottomUnits * globalStyleConstants.unitSize : undefined,
          marginRight: props.marginRightUnits ? props.marginRightUnits * globalStyleConstants.unitSize : undefined,
        }}
      >
        <Pressable style={{ flexDirection: "row", alignItems: "center" }} onPress={props.onPress}>
          <IconPlus opacity="opaque" size={28} color={globalStyleColors.colorAccentCool} />
          <Spacer horizontal={0.5} />
          <View>
            <TSecondary color={globalStyleColors.colorAccentCool}>{"Filter"}</TSecondary>
          </View>
        </Pressable>
        <Spacer horizontal={1} />
        <Separator orientation="column" color={globalStyleColors.rgba("black", "light")} lineWidth={0.5} />
      </View>
    );
  }
);

const CancelButton = React.memo((props: { onPress: () => void; marginLeftUnits?: number }) => {
  return (
    <Pressable
      style={props.marginLeftUnits ? { marginLeft: props.marginLeftUnits * globalStyleConstants.unitSize } : {}}
      onPress={props.onPress}
    >
      <TSecondary fontWeight="medium" color={globalStyleColors.colorAccentCool}>
        {strings.cancel}
      </TSecondary>
    </Pressable>
  );
});

const DeleteBadge = React.memo(() => {
  return (
    <View
      style={{ marginLeft: 0.5 * globalStyleConstants.unitSize, marginRight: -0.5 * globalStyleConstants.unitSize }}
    >
      <IconEx color={"white"} opacity="opaque" size={14} />
    </View>
  );
});

const styles = StyleSheet.create({
  searchBar: {
    flexDirection: "row",
    alignItems: "center",
    height: constants.searchBarHeight,
    paddingHorizontal: globalStyleConstants.defaultPadding,
    paddingVertical: globalStyleConstants.unitSize,
  },
  searchBox: {
    flex: 1,
    flexShrink: 1,
    flexDirection: "row",
    alignItems: "center",
    height: constants.searchBarHeight - 2 * globalStyleConstants.unitSize,
    backgroundColor: globalStyleColors.rgba("colorGreyDark", "light"),
    borderRadius: constants.searchBarHeight / 1.2,
    paddingHorizontal: 1.3 * globalStyleConstants.unitSize,
  },
  filterBar: {
    height: constants.filterBarHeight,
    flexDirection: "row",
    marginLeft: globalStyleConstants.unitSize + 8,
    paddingBottom: 0.5 * globalStyleConstants.unitSize,
  },
  filterButton: {
    paddingLeft: globalStyleConstants.unitSize,
  },
  filterRightBoundary: {
    width: globalStyleConstants.unitSize,
    backgroundColor: "white",
    zIndex: 1,
    shadowColor: "white",
    shadowRadius: 6,
    shadowOffset: {
      height: 0,
      width: -1,
    },
    shadowOpacity: 0.6,
  },
});
