import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { DeglazeUser } from "@eatbetter/users-shared";
import { KnownEntities } from "@eatbetter/recipes-shared";
import {
  ReceivedServerData,
  ServerData,
  serverDataErrored,
  serverDataReceived,
  serverDataRequested,
} from "../redux/ServerData";
import { EpochMs } from "@eatbetter/common-shared";

export interface UserSearchState {
  pendingSearches: string[];
  completedSearches: string[];
  currentQuery: string;
  users: EntityState<DeglazeUser, string>;
  entities: ServerData<KnownEntities>;
}

const usersAdapter = createEntityAdapter<DeglazeUser, string>({
  selectId: u => u.userId,
  sortComparer: (a, b) => a.username.localeCompare(b.username),
});

const initialState: UserSearchState = {
  pendingSearches: [],
  completedSearches: [],
  currentQuery: "",
  users: usersAdapter.getInitialState(),
  entities: {},
};

const userSearchSlice = createSlice({
  name: "userSearch",
  initialState,

  reducers: create => ({
    resetState: create.reducer((state, action: PayloadAction<{ initialUsers: DeglazeUser[] }>) => {
      state.pendingSearches = [];
      state.completedSearches = [];
      state.currentQuery = "";
      usersAdapter.setAll(state.users, action.payload.initialUsers);
    }),

    searchStarted: create.reducer((state, action: PayloadAction<{ query: string }>) => {
      state.pendingSearches.push(action.payload.query);
    }),

    searchCompleted: create.reducer((state, action: PayloadAction<{ query: string; results: DeglazeUser[] }>) => {
      const query = action.payload.query;
      state.pendingSearches = state.pendingSearches.filter(s => s !== query);
      state.completedSearches.push(query);
      usersAdapter.setMany(state.users, action.payload.results);
    }),

    searchErrored: create.reducer((state, action: PayloadAction<{ query: string }>) => {
      state.pendingSearches = state.pendingSearches.filter(s => s !== action.payload.query);
    }),

    setCurrentQuery: create.reducer((state, action: PayloadAction<{ query: string }>) => {
      state.currentQuery = action.payload.query;
    }),

    entitiesRequested: create.reducer((state, action: PayloadAction<EpochMs>) => {
      serverDataRequested(state.entities, action.payload);
    }),
    entitiesReceived: create.reducer((state, action: PayloadAction<ReceivedServerData<KnownEntities>>) => {
      serverDataReceived(state.entities, action.payload);
    }),
    entitiesErrored: create.reducer(state => {
      serverDataErrored(state.entities);
    }),
  }),
});

export const {
  resetState,
  searchStarted,
  searchCompleted,
  searchErrored,
  setCurrentQuery,
  entitiesRequested,
  entitiesReceived,
  entitiesErrored,
} = userSearchSlice.actions;

export const usersSearchReducer = userSearchSlice.reducer;
