import { Image, Platform, StyleSheet, View } from "react-native";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import React, { useCallback, useMemo } from "react";
import { ScreenView } from "../components/ScreenView";
import { globalStyleColors } from "../components/GlobalStyles";
import { Spacer } from "../components/Spacer";
import { THeading1, TSecondary } from "../components/Typography";
import { Pressable } from "../components/Pressable";
import AppleAppStoreLogo from "../assets/logo_apple_app_store.svg";
import { useDispatch } from "../lib/redux/Redux";
import { signOutClicked } from "../lib/system/SystemThunks";
import { HeaderProps } from "../components/ScreenHeaders";
import { ScreenFooter } from "../components/ScreenFooter";
import { useAuthedUserId } from "../lib/system/SystemSelectors";
import { openWebpage } from "../lib/util/WebUtil";
import { Alert } from "../components/Alert/Alert";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { reportGetAppButtonPressed } from "../lib/analytics/AnalyticsEvents";
import { getAppStoreLink } from "../lib/util/AppStoreLink";

const strings = {
  welcomeToDeglaze: "Welcome to Deglaze!",
  instructions: "Download the app to experience the new way to shop, cook, share, and discover great recipes.",
  androidAlert: {
    title: "Coming Soon",
    message: "Deglaze for Android is coming soon.",
    ok: "OK",
  },
};

export const DownloadAppScreen = withScreenContainer("DownloadAppScreen", () => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const signedIn = !!useAuthedUserId();

  const onPressAppleButton = useCallback(() => {
    const event = reportGetAppButtonPressed({ platform: "ios" });
    dispatch(analyticsEvent(event));

    openWebpage(getAppStoreLink(), { webBehavior: "newTab" });
  }, [dispatch]);

  const onPressGoogleButton = useCallback(() => {
    const event = reportGetAppButtonPressed({ platform: "android" });
    dispatch(analyticsEvent(event));

    Alert.alert(strings.androidAlert.title, strings.androidAlert.message, [
      {
        type: "cancel",
        text: strings.androidAlert.ok,
        onPress: () => {},
      },
    ]);
  }, [dispatch]);

  const onPressSignOut = useCallback(async () => {
    await dispatch(signOutClicked(screen.nav));
  }, [dispatch, screen.nav]);

  const header = useMemo<HeaderProps>(() => {
    return {
      type: "default",
      title: "",
      backgroundColor: "transparent",
      right: signedIn ? { type: "signOut", onPress: onPressSignOut } : undefined,
    };
  }, [onPressSignOut, signedIn]);

  return (
    <ScreenView
      scrollView={false}
      paddingHorizontal={false}
      paddingVertical={false}
      header={header}
      backgroundColor={globalStyleColors.colorAccentMidDark}
    >
      <View style={styles.wrap}>
        <THeading1 color={globalStyleColors.blackSoft} fontWeight="medium">
          {strings.welcomeToDeglaze}
        </THeading1>
        <Spacer vertical={1} />
        <View style={styles.instructions}>
          <TSecondary color={globalStyleColors.blackSoft} align="center">
            <TSecondary>{strings.instructions}</TSecondary>
          </TSecondary>
        </View>
        <Spacer vertical={3} />
        <View style={{ flexDirection: "row", width: "100%", justifyContent: "center", alignItems: "center" }}>
          <AppleButton onPress={onPressAppleButton} />
          <GoogleButton onPress={onPressGoogleButton} />
        </View>
        <Footer />
      </View>
    </ScreenView>
  );
});

const AppleButton = React.memo((props: { onPress: () => void }) => {
  return (
    <Pressable style={{ padding: 10 }} onPress={props.onPress}>
      <AppleAppStoreLogo height={40} width={120} />
    </Pressable>
  );
});

const GoogleButton = React.memo((props: { onPress: () => void }) => {
  return (
    <Pressable onPress={props.onPress}>
      <Image source={require("../assets/images/google_play_badge.png")} style={{ width: 155, height: 60 }} />
    </Pressable>
  );
});

const Footer = React.memo(() => {
  return <View style={styles.footer}>{Platform.OS === "web" && <ScreenFooter showLogo />}</View>;
});

const styles = StyleSheet.create({
  wrap: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: "center",
    alignItems: "center",
  },
  instructions: {
    width: 250,
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
});
