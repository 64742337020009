import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { ItemWithPhrases, TruthSetPhrase } from "@eatbetter/items-data";
import { KnownDisambiguationType, RawCategory, UnitMeasurementType } from "@eatbetter/items-server";
import { DisambiguationItemId, ShoppableItemId } from "@eatbetter/items-shared";
import { Draft } from "immer";

export type TruthSetIngredientFilter = "hasIngredient" | "noIngredient";

export interface HistoryItem {
  op: "add" | "edit" | "delete";
  actionDescription: string;
  id: ShoppableItemId | DisambiguationItemId;
  originalItem: ItemWithPhrases;
}

export interface DataManagerState {
  ingredients: EntityState<ItemWithPhrases, string>;
  ingredientFilters: {
    query?: string;
  };
  ingredientHistory: HistoryItem[];
  ingredientCategories: RawCategory[];
  knownDisambiguationTypes: KnownDisambiguationType[];
  unitMeasurementTypes: UnitMeasurementType[];
  truthSetPhrases: EntityState<TruthSetPhrase, string>;
  truthSetFilters: {
    query?: string;
    filters?: string[];
    sort?: TruthSetSort;
  };
  truthSetPhraseRandomSort?: Record<string, number>;
}

export type TruthSetSort = "touched" | "changed" | "random";

const ingredientsAdapter = createEntityAdapter<ItemWithPhrases, string>({
  selectId: i => i.id,
});

const phrasesAdapter = createEntityAdapter<TruthSetPhrase, string>({
  selectId: i => i.phrase,
  sortComparer: (a, b) => {
    return (b.dateUpdated ?? 0) - (a.dateUpdated ?? 0);
  },
});

const initialState: DataManagerState = {
  ingredients: ingredientsAdapter.getInitialState(),
  ingredientFilters: {},
  ingredientHistory: [],
  ingredientCategories: [],
  knownDisambiguationTypes: [],
  unitMeasurementTypes: [],
  truthSetPhrases: phrasesAdapter.getInitialState(),
  truthSetFilters: {},
};

const dataManagerSlice = createSlice({
  name: "dataManager",
  initialState,

  reducers: create => ({
    ingredientsReceived: create.reducer((state, action: PayloadAction<{ items: ItemWithPhrases[] }>) => {
      ingredientsAdapter.setAll(state.ingredients, action.payload.items);
    }),

    ingredientsFiltered: create.reducer((state, action: PayloadAction<{ query?: string }>) => {
      state.ingredientFilters.query = action.payload.query?.toLowerCase();
    }),

    ingredientCategoriesReceived: create.reducer((state, action: PayloadAction<{ categories: RawCategory[] }>) => {
      state.ingredientCategories = action.payload.categories.sort();
    }),

    ingredientKnownDisambiguationTypesReceived: create.reducer(
      (state, action: PayloadAction<{ knownDisambiguationTypes: KnownDisambiguationType[] }>) => {
        state.knownDisambiguationTypes = action.payload.knownDisambiguationTypes;
      }
    ),

    ingredientAdded: create.reducer((state, action: PayloadAction<{ added: ItemWithPhrases }>) => {
      ingredientsAdapter.upsertOne(state.ingredients, action.payload.added);
      const history: HistoryItem = {
        op: "add",
        actionDescription: `Added ${action.payload.added.id}`,
        id: action.payload.added.id,
        originalItem: action.payload.added,
      };
      addToHistory(state, history);
    }),

    ingredientUpdated: create.reducer(
      (
        state,
        action: PayloadAction<{
          updated: ItemWithPhrases;
          associatedUpdated: Array<ItemWithPhrases>;
          deletedId?: ShoppableItemId | DisambiguationItemId;
        }>
      ) => {
        const originalId = action.payload.deletedId ?? action.payload.updated.id;
        const newId = action.payload.updated.id;
        const originalItem = state.ingredients.entities[originalId]!;

        if (action.payload.deletedId) {
          ingredientsAdapter.removeOne(state.ingredients, action.payload.deletedId);
        }

        ingredientsAdapter.upsertOne(state.ingredients, action.payload.updated);
        ingredientsAdapter.upsertMany(state.ingredients, action.payload.associatedUpdated);

        const idChangeText = originalId !== newId ? `(ID change from ${originalId} -> ${newId}` : "";
        const history: HistoryItem = {
          op: "edit",
          actionDescription: `Edited item ${originalItem.id}. ${idChangeText}`,
          id: newId,
          originalItem,
        };
        addToHistory(state, history);
      }
    ),

    ingredientDeleted: create.reducer(
      (state, action: PayloadAction<{ deletedId: ShoppableItemId | DisambiguationItemId }>) => {
        const originalItem = state.ingredients.entities[action.payload.deletedId]!;
        ingredientsAdapter.removeOne(state.ingredients, action.payload.deletedId);
        const history: HistoryItem = {
          op: "delete",
          actionDescription: `Deleted item ${action.payload.deletedId}`,
          id: action.payload.deletedId,
          originalItem,
        };
        addToHistory(state, history);
      }
    ),

    phrasesFiltered: create.reducer(
      (
        state,
        action: PayloadAction<{
          query?: string;
          filters?: string[];
          sort: TruthSetSort;
        }>
      ) => {
        state.truthSetFilters.query = action.payload.query === "" ? undefined : action.payload.query;
        state.truthSetFilters.filters = action.payload.filters;

        if (action.payload.sort === "random" && state.truthSetFilters.sort !== "random") {
          // assign random sort values
          const random: Record<string, number> = {};
          Object.values(state.truthSetPhrases.entities).forEach(e => {
            random[e!.phrase] = Math.random();
          });
          state.truthSetPhraseRandomSort = random;
        }
        state.truthSetFilters.sort = action.payload.sort;
      }
    ),

    truthSetPhrasesReceived: create.reducer((state, action: PayloadAction<{ phrases: TruthSetPhrase[] }>) => {
      phrasesAdapter.setAll(state.truthSetPhrases, action.payload.phrases);
    }),

    truthSetPhraseUpdated: create.reducer((state, action: PayloadAction<{ phrase: TruthSetPhrase }>) => {
      phrasesAdapter.setOne(state.truthSetPhrases, action.payload.phrase);
    }),

    truthSetPhraseAddedOrUpdated: create.reducer((state, action: PayloadAction<{ phrase: TruthSetPhrase }>) => {
      if (!state.truthSetPhrases.entities[action.payload.phrase.phrase] && state.truthSetPhraseRandomSort) {
        state.truthSetPhraseRandomSort[action.payload.phrase.phrase] = Math.random();
      }
      phrasesAdapter.upsertOne(state.truthSetPhrases, action.payload.phrase);
    }),

    unitMeasurementTypesReceived: create.reducer(
      (state, action: PayloadAction<{ unitMeasurementTypes: UnitMeasurementType[] }>) => {
        state.unitMeasurementTypes = action.payload.unitMeasurementTypes.sort();
      }
    ),
  }),
});

function addToHistory(state: Draft<DataManagerState>, item: HistoryItem) {
  state.ingredientHistory.unshift(item);
}

export const {
  ingredientAdded,
  ingredientUpdated,
  ingredientDeleted,
  ingredientCategoriesReceived,
  ingredientKnownDisambiguationTypesReceived,
  ingredientsFiltered,
  ingredientsReceived,
  phrasesFiltered,
  truthSetPhrasesReceived,
  truthSetPhraseAddedOrUpdated,
  truthSetPhraseUpdated,
  unitMeasurementTypesReceived,
} = dataManagerSlice.actions;
export const dataManagerReducer = dataManagerSlice.reducer;
export const ingredientSelectors = ingredientsAdapter.getSelectors();
export const truthSetPhraseSelectors = phrasesAdapter.getSelectors();
