import { RootState } from "./RootReducer";
import { Platform } from "react-native";
import { log } from "../../Log";
import { isLoading, ServerData } from "./ServerData";
import { NowOrLater, nowOrLaterBackoff, nowOrLaterDelayFromLast, safeJsonStringify } from "@eatbetter/common-shared";
import { selectHasFullWebAccess } from "../system/SystemSelectors";

export interface FetchOptions {
  /**
   * The number of seconds after which data is considered stale and will be refetched.
   */
  staleThresholdSeconds?: number;

  /**
   * The number of seconds after which the "loading" status is ignored. This is a safeguard
   * to protect against missed state updates, which can happen when a user has a bad connection.
   * A default is used if not set explicitly.
   */
  loadingStaleThresholdSeconds?: number;

  /**
   * If true, fetch won't happen until there is an authed user.
   * Default true
   */
  authRequired?: boolean;

  /**
   * If true, fetch for anonymous users. Default is true.
   */
  fetchForAnonymous?: boolean;

  /**
   * Fetch for waitlist users? Default is false; Ignored if authRequired is false.
   */
  fetchForWaitlistUsers?: boolean;

  /**
   * Fetch for users that are on web but don't have full web access.
   * Default is false. Ignored if authRequired is false.
   */
  fetchForLimitedWebUsers?: boolean;
}

/**
 * Determine if data should be fetched now or soon based on current state.
 */
export function shouldFetch(
  description: string,
  state: RootState,
  selector: (s: RootState) => ServerData<unknown>,
  opts: FetchOptions
): NowOrLater {
  const serverData = selector(state);

  if (opts.authRequired !== false) {
    if (state.system.authStatus !== "signedIn") {
      return { now: false };
    }

    if (opts.fetchForAnonymous === false && state.system.authedUser.data?.isRegistered === false) {
      return { now: false };
    }

    if (
      !state.system.authedUser.data ||
      (opts.fetchForWaitlistUsers !== true && state.system.authedUser.data.access === "waitlist")
    ) {
      return { now: false };
    }

    if (Platform.OS === "web" && opts.fetchForLimitedWebUsers !== true && !selectHasFullWebAccess(state)) {
      return { now: false };
    }
  }

  // don't log here - we'll log when the call actually gets made, assuming the thunk calls isLoading, which it should.
  // This is to prevent a lot of log spam when the reactors run.
  if (isLoading(`${description} (shouldFetch)`, state, selector, opts.loadingStaleThresholdSeconds, false)) {
    // already loading
    return { now: false };
  }

  if (serverData.data === undefined) {
    if (serverData.errorCount) {
      if (!serverData.lastAttempt) {
        log.error(
          `${description}: errorCount is set, but lastAttempt not set. This should not happen. serverData: ${safeJsonStringify(
            serverData
          )}`
        );
        // since there is no start timestamp, there is no way to throttle. Just return false.
        // this will never happen as long as the standard serverData functions are used to update state.
        return { now: false };
      }

      return nowOrLaterBackoff({
        lastAttempt: serverData.lastAttempt,
        failureCount: serverData.errorCount,
        timeNow: state.system.time,
      });
    }

    return { now: true };
  }

  if (opts.staleThresholdSeconds && serverData.lastAttempt) {
    // use lastAttempt here so data is only refreshed ever staleThresholdSeconds regardless of success.
    // The other option is to use lastUpdated and calculate a backoff, but that seems like overkill
    return nowOrLaterDelayFromLast({
      last: serverData.lastAttempt,
      delayInSeconds: opts.staleThresholdSeconds,
      timeNow: state.system.time,
    });
  }

  return { now: false };
}
