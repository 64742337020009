import { GroceryListItemId } from "@eatbetter/lists-shared";
import React, { useCallback, useEffect, useState } from "react";
import { LayoutAnimation, LayoutChangeEvent, StyleSheet, View } from "react-native";
import { GroceryItemStatusChangeHandler, GroceryListItem, groceryListItemConstants } from "./GroceryListItem";
import { Pressable } from "../Pressable";
import { Opacity, globalStyleColors, globalStyleConstants, globalStyles } from "../GlobalStyles";
import { TSecondary, TTertiary } from "../Typography";
import { Spacer } from "../Spacer";
import { AnimatedChevron } from "../AnimatedChevron";
import { SwipeableRow } from "../SwipeableRow";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";

const strings = {
  items: " items",
};

const config = {
  layoutAnimationDurationMs: 300,
  collapsedItemMarginTop: groceryListItemConstants.marginTop,
  collapsedItemPaddingHorizontal: groceryListItemConstants.marginTop,
  expandedGroupMarginVertical: 10,
  itemIndent: globalStyleConstants.minPadding,
  cardStackCount: 1,
};

interface Props {
  groupName: string;
  mergedItemIds: GroceryListItemId[];
  onItemStatusChange: GroceryItemStatusChangeHandler;
  onEdit?: (id: GroceryListItemId) => void;
  showSwipeHint: boolean;
}

type LayoutAnimationState = "expanding" | "expanded" | "closing" | "closed";

const layoutAnimation = LayoutAnimation.create(config.layoutAnimationDurationMs, "easeInEaseOut", "opacity");

export const MergedListItemGroup = React.memo((props: Props) => {
  const [layoutAnimationState, setLayoutAnimationState] = useState<LayoutAnimationState>("closed");
  const [itemLayoutData, setItemLayoutData] = useState<Record<GroceryListItemId, { index: number; height: number }>>(
    {}
  );

  const onCompleteGroup = useCallback(() => {
    props.mergedItemIds.forEach(i => props.onItemStatusChange(i, "completed", props.mergedItemIds.length, true));
  }, [props.mergedItemIds, props.onItemStatusChange]);

  const toggleExpanded = useCallback(() => {
    if (layoutAnimationState === "expanded") {
      setLayoutAnimationState("closing");
      LayoutAnimation.configureNext(layoutAnimation, () => setLayoutAnimationState("closed"));
      return;
    }

    setLayoutAnimationState("expanding");
    LayoutAnimation.configureNext(layoutAnimation, () => setLayoutAnimationState("expanded"));
  }, [layoutAnimationState, setLayoutAnimationState]);

  const onItemLayout = useCallback(
    (id: GroceryListItemId, index: number, e: LayoutChangeEvent) => {
      const height = e.nativeEvent.layout.height;
      setItemLayoutData(prev => ({ ...prev, [id]: { index, height } }));
    },
    [setItemLayoutData]
  );

  useEffect(() => {
    setItemLayoutData(prev =>
      Object.fromEntries(Object.entries(prev).filter(([key]) => props.mergedItemIds.includes(key as GroceryListItemId)))
    );
  }, [props.mergedItemIds]);

  const itemLayouts = Object.values(itemLayoutData);
  const expandingOrExpanded = layoutAnimationState === "expanding" || layoutAnimationState === "expanded";

  const groupMarginBottom = expandingOrExpanded ? config.expandedGroupMarginVertical : 0;
  const groupCollapsedHeight =
    groceryListItemConstants.minHeight + config.cardStackCount * config.collapsedItemMarginTop;
  const groupExpandedHeight = itemLayouts.reduce<number>((result, curr) => result + curr.height, 0);
  const groupHeight = expandingOrExpanded ? groupExpandedHeight : groupCollapsedHeight;

  return (
    <SwipeableRow
      disabled={layoutAnimationState === "expanded"}
      actionStyle="completeAction"
      minHeight={groceryListItemConstants.minHeight}
      onSwipedLeft={onCompleteGroup}
      onSwipedRight={onCompleteGroup}
      showSwipeHint={props.showSwipeHint}
    >
      {expandingOrExpanded && (
        <GroupCollapseButton
          groupName={props.groupName}
          onPress={toggleExpanded}
          marginTop={config.expandedGroupMarginVertical}
          marginBottom={groceryListItemConstants.marginTop}
        />
      )}
      {!expandingOrExpanded && (
        <GroupCard groupName={props.groupName} onPress={toggleExpanded} mergedItemCount={props.mergedItemIds.length} />
      )}
      <View style={{ height: groupHeight, marginBottom: groupMarginBottom }}>
        {props.mergedItemIds.map((id, idx) => {
          const itemHeight = expandingOrExpanded ? undefined : groceryListItemConstants.minHeight;
          const itemMarginTop = expandingOrExpanded
            ? itemLayouts.filter(i => i.index < idx).reduce<number>((result, curr) => result + curr.height, 0)
            : idx < config.cardStackCount
            ? (idx + 1) * config.collapsedItemMarginTop
            : 0;
          const itemZIndex = layoutAnimationState === "expanded" ? 0 : props.mergedItemIds.length - idx;
          const itemPaddingHorizontal = expandingOrExpanded ? 0 : (idx + 1) * config.collapsedItemPaddingHorizontal;
          const itemOpacity =
            layoutAnimationState === "closed" && idx + 1 > config.cardStackCount ? Opacity.transparent : Opacity.opaque;

          return (
            <View
              key={id}
              style={[
                styles.mergedItem,
                {
                  height: itemHeight,
                  marginTop: itemMarginTop,
                  zIndex: itemZIndex,
                  paddingHorizontal: itemPaddingHorizontal,
                  opacity: itemOpacity,
                },
              ]}
            >
              <MergedItem
                id={id}
                index={idx}
                onItemStatusChange={props.onItemStatusChange}
                onEdit={props.onEdit}
                onItemLayout={onItemLayout}
                groupCount={props.mergedItemIds.length}
                disabled={layoutAnimationState === "closed"}
                noPaddingTop={!expandingOrExpanded}
              />
            </View>
          );
        })}
      </View>
    </SwipeableRow>
  );
});

const MergedItem = React.memo(
  (props: {
    id: GroceryListItemId;
    index: number;
    noPaddingTop?: boolean;
    groupCount: number;
    onItemStatusChange: GroceryItemStatusChangeHandler;
    onEdit?: (id: GroceryListItemId) => void;
    onItemLayout: (id: GroceryListItemId, index: number, event: LayoutChangeEvent) => void;
    disabled?: boolean;
  }) => {
    const onLayout = useCallback(
      (event: LayoutChangeEvent) => {
        props.onItemLayout(props.id, props.index, event);
      },
      [props.id, props.index, props.onItemLayout]
    );

    return (
      <View onLayout={onLayout}>
        {!props.noPaddingTop && props.index !== 0 && (
          <Spacer vertical={groceryListItemConstants.marginTop} unit="pixels" />
        )}
        <GroceryListItem
          key={props.id}
          id={props.id}
          onStatusChange={props.onItemStatusChange}
          onEdit={props.onEdit}
          indentContent={config.itemIndent}
          groupCount={props.groupCount}
          disabled={props.disabled}
          showSwipeHint={false}
        />
      </View>
    );
  }
);

const GroupCard = React.memo((props: { groupName: string; onPress: () => void; mergedItemCount: number }) => {
  return (
    <TouchableWithoutFeedback onPress={props.onPress} containerStyle={styles.groupCard}>
      <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
        <View style={{ flexShrink: 1 }}>
          <TSecondary numberOfLines={2}>{props.groupName}</TSecondary>
        </View>
        <View style={{ flexWrap: "wrap", marginLeft: globalStyleConstants.unitSize }}>
          <TTertiary opacity="medium">{props.mergedItemCount + strings.items}</TTertiary>
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
});

const GroupCollapseButton = React.memo(
  (props: { groupName: string; onPress: () => void; marginTop: number; marginBottom: number }) => {
    return (
      <View style={{ flexWrap: "wrap", marginTop: props.marginTop, marginBottom: props.marginBottom }}>
        <Pressable style={styles.groupCollapseButton} onPress={props.onPress}>
          <TSecondary color={globalStyleColors.colorAccentCool}>{props.groupName}</TSecondary>
          <Spacer horizontal={0.5} />
          <AnimatedChevron size={20} color={globalStyleColors.colorAccentCool} startPosition="down" isRotated />
        </Pressable>
      </View>
    );
  }
);

const styles = StyleSheet.create({
  mergedItem: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
  },
  groupCard: {
    position: "absolute",
    justifyContent: "center",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    height: groceryListItemConstants.minHeight,
    backgroundColor: "white",
    borderRadius: globalStyleConstants.defaultBorderRadius,
    paddingHorizontal: globalStyleConstants.defaultPadding,
    ...globalStyles.shadowItem,
  },
  groupCollapseButton: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: groceryListItemConstants.marginTop,
    borderRadius: globalStyleConstants.defaultPadding,
    paddingLeft: globalStyleConstants.defaultPadding,
    paddingRight: globalStyleConstants.unitSize,
    backgroundColor: globalStyleColors.white,
    ...globalStyles.shadowItem,
  },
});
