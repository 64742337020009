export function range(first: number, second?: number) {
  const start = second === undefined ? 0 : first;
  const end = second === undefined ? first : second;

  const r: number[] = [];
  for (let i = start; i <= end; i++) {
    r.push(i);
  }
  return r;
}
