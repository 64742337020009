import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ScreenView } from "../ScreenView";
import { RecipeDetail } from "./RecipeDetail";
import { HeaderProps, useHeaderOffset } from "../ScreenHeaders";
import { ReaderModeWalkthrough, useReaderMode } from "../../lib/recipes/UseReaderMode";
import { AppRecipe, haveRecipeIngredients, isUserRecipeId, UserRecipeId } from "@eatbetter/recipes-shared";
import { globalStyleColors } from "../GlobalStyles";
import { useDispatch } from "../../lib/redux/Redux";
import { getAndStoreUserRecipe, reportRecipePaywallHit, reportRecipeViewedTime } from "../../lib/recipes/RecipesThunks";
import { displayUnexpectedErrorAndLog } from "../../lib/Errors";
import { NavApi, useScreen } from "../../navigation/ScreenContainer";
import { navTree } from "../../navigation/NavTree";
import { RecipeDetailOptions, getRecipeDetailOptionsHeight } from "./RecipeDetailOptions";
import { analyticsEvent } from "../../lib/analytics/AnalyticsThunks";
import {
  reportRecipeDetailWalkthroughCompleted,
  reportRecipeDetailWalkthroughStarted,
  reportRecipeLibraryContextMenuOpened,
} from "../../lib/analytics/AnalyticsEvents";
import { useCheckpointCompleted, useSystemSetting } from "../../lib/system/SystemSelectors";
import {
  useGetSocialMediaDomainType,
  useIsOwnUserRecipe,
  useLibraryRecipe,
  useRecipeSourceType,
  useRecipeSourceUrl,
} from "../../lib/recipes/RecipesSelectors";
import { usePaywallDetection, usePaywallStatus } from "../PaywallDetector";
import { UrlString, bottomThrow, switchReturn } from "@eatbetter/common-shared";
import { WebViewNavigationStateChangeHandler } from "../WebView";
import { RecipeDetailActionBar, useRecipeDetailActionBarHeight } from "./RecipeDetailActionBar";
import { useCookingSessionId } from "../../lib/cooking/CookingSessionsSelectors";
import { PaywallLocation } from "@eatbetter/composite-shared";
import { shareRecipeLink } from "../../lib/share/ShareThunks";
import { useWebViewSession } from "../../lib/webview/WebViewHooks";
import { useWebViewCurrentUrl, useWebViewIsBrowseMode, useWebViewIsSignIn } from "../../lib/webview/WebViewSelectors";
import { maybePromptForReview } from "../../lib/system/SystemThunks";
import { checkpointsCompleted } from "../../lib/system/SystemSlice";
import { log } from "../../Log";
import { Haptics } from "../Haptics";
import { LayoutAnimation } from "react-native";
import { openWebpage } from "../../lib/util/WebUtil";
import { HeaderRightProps } from "../ScreenHeaderRightButtons";
import { getOptionsMenuHeight, OptionsMenu } from "../OptionsMenu";
import { useReportContentIssueMenuItem } from "../social/ReportContentIssue";
import { recipeDetailMounted, recipeDetailUnmounted } from "../../lib/recipes/RecipesSlice.ts";

const strings = {
  browsing: "Browsing",
  reportContentIssue: "Report Content Issue",
};

interface Props {
  recipe: AppRecipe | undefined;
  onSaveRecipe?: () => Promise<void>;
  waitingSaveRecipe?: boolean;
  context: "search" | "post" | "share" | "library";
}

const walkthroughSteps = ["actions", "tapXray"] as const;
const walkthroughRenderDelayMs = 1000;

/**
 * This screen component merges library view recipe (RecipeDetailScreen.tsx) and non-library view recipe (ViewRecipeScreenComponent.tsx).
 * It conditionally renders screen nav bar actions (menu, share) as well as bottom bar actions (Add to Grocery, Start Cooking, Add to Library)
 * based on whether or not the recipe is in the user's library.
 */
export const RecipeDetailScreenComponent = React.memo((props: Props) => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const cookingSessionId = useCookingSessionId(props.recipe?.id);
  const externalShareEnabled = !!useSystemSetting("externalRecipeShare");
  const recipeViewedInLibrary = useCheckpointCompleted("recipeViewedInLibrary");

  const [waitingScalingData, setWaitingScalingData] = useState(false);
  const [fetchScalingDataErrored, setFetchScalingDataErrored] = useState(false);

  const libraryRecipe = useLibraryRecipe(props.recipe?.id);
  const inLibrary = !!libraryRecipe;

  const [recipeScale, setRecipeScale] = useState(libraryRecipe?.scale ?? 1);

  useEffect(() => {
    if (!recipeViewedInLibrary && props.context === "library") {
      dispatch(checkpointsCompleted(["recipeViewedInLibrary"]));
    }
  }, [props.context]);

  // prompt for review on unmount if the user toggles x-ray or shares the recipe
  const promptForReviewOnUnmount = useRef(false);

  const { walkthroughStep, onWalkthroughNext, readerModeEnabled, toggleReaderModeEnabled, renderReaderModeToggle } =
    useRecipeDetailWalkthrough(props.recipe);

  useEffect(() => {
    if (readerModeEnabled) {
      promptForReviewOnUnmount.current = true;
    }
  }, [readerModeEnabled, promptForReviewOnUnmount]);

  // THIS CALL IS CRITICAL TO SCALING/CONVERSION
  // This call reports the recipe view AND updates it with scaling information.
  // 1. This ensures we have scaling information
  // 2. Never hurts to make sure we have the most recent copy.
  useEffect(() => {
    if (inLibrary && props.recipe?.id && isUserRecipeId(props.recipe.id)) {
      const recipeId = props.recipe.id;
      dispatch(recipeDetailMounted(props.recipe.id));
      dispatch(reportRecipeViewedTime(props.recipe.id, setWaitingScalingData))
        .then(result => setFetchScalingDataErrored(result.type === "error"))
        .catch(() => {
          /* Error handling happens in the thunk already */
        });

      return () => dispatch(recipeDetailUnmounted(recipeId));
    }

    return () => {};
  }, [inLibrary, props.recipe?.id]);

  // we don't persist the scale for the recipe when the value is changed on this page, but it might change
  // on the underlying recipe if the user adds the recipe to the grocery list. If we get a new value because the
  // recipe value has changed, update it here.
  // Note this could result in some strange behavior in a multi-user scenario, but the normal case of a user
  // adjusting the value on the grocery sheet and then expecting that value to reflect here trumps that case
  useEffect(() => {
    if (libraryRecipe?.scale) {
      setRecipeScale(libraryRecipe.scale);
    }
  }, [libraryRecipe?.scale]);

  const userRecipe =
    props.recipe && "type" in props.recipe && props.recipe.type === "userRecipe" ? props.recipe : undefined;
  const userRecipeId = userRecipe?.id;
  const isOwnRecipe = useIsOwnUserRecipe(userRecipeId);
  const recipeUrl = useRecipeSourceUrl(props.recipe);

  const webViewSessionId = useWebViewSession(recipeUrl);
  const webViewIsBrowseMode = useWebViewIsBrowseMode(webViewSessionId);
  const webViewCurrentUrl = useWebViewCurrentUrl(webViewSessionId);
  const isSigninUrl = !!useWebViewIsSignIn(webViewSessionId);

  const { detectPaywallSignin } = usePaywallDetection(props.recipe);
  const paywallStatus = usePaywallStatus(props.recipe);

  const alreadyReportedPaywallHit = useRef(false);

  useEffect(() => {
    return () => {
      if (promptForReviewOnUnmount.current) {
        dispatch(maybePromptForReview("Recipe Detail Viewed"));
      }
    };
  }, []);

  useEffect(() => {
    if (!alreadyReportedPaywallHit.current && paywallStatus.paywallIsUp && props.recipe) {
      dispatch(
        reportRecipePaywallHit(
          props.recipe,
          switchReturn<Props["context"], PaywallLocation>(props.context, {
            library: "Library Recipe Detail",
            post: "Post View Recipe",
            share: "Share View Recipe",
            search: "Search View Recipe",
          })
        )
      );
      alreadyReportedPaywallHit.current = true;
    }
  }, [paywallStatus.paywallIsUp, !!props.recipe]);

  const [currentWebViewUrl, setCurrentWebViewUrl] = useState(recipeUrl);

  const onWebViewNavStateChanged = useCallback<WebViewNavigationStateChangeHandler>(
    e => {
      // Keep track of the web view URL to open externally and for analytics
      setCurrentWebViewUrl(e.url as UrlString);
      detectPaywallSignin(e);
    },
    [detectPaywallSignin]
  );

  const onPressShareButton = useCallback(async () => {
    if (!props.recipe) {
      return;
    }

    if (!externalShareEnabled) {
      // this condition was added when we allowed sharing of non-library recipes. We need to update the backend and some
      // other app logic to have this work with non-library recipes, but not worthing doing at the moment since this
      // isn't even currently acessible.
      if (isUserRecipeId(props.recipe.id)) {
        screen.nav.modal(navTree.get.screens.shareRecipe, { recipeId: props.recipe.id });
      }
      return;
    }

    promptForReviewOnUnmount.current = true;

    try {
      await dispatch(
        shareRecipeLink({
          recipe: props.recipe,
          webViewUrl: currentWebViewUrl,
          nav: screen.nav,
        })
      );
    } catch (err) {
      displayUnexpectedErrorAndLog("Error dispatching shareRecipeLink in RecipeDetailScreenComponent", err, {
        userRecipeId,
        sourceRecipeId: userRecipe?.sourceRecipeId,
      });
    }
  }, [
    screen.nav,
    externalShareEnabled,
    props.recipe,
    userRecipe?.sourceRecipeId,
    currentWebViewUrl,
    dispatch,
    promptForReviewOnUnmount,
  ]);

  const onPressMenu = useCallback(() => {
    if (!userRecipeId) {
      displayUnexpectedErrorAndLog(
        "RecipeDetailScreenComponent.onPressMenu called but userRecipeId is falsy",
        {},
        { props }
      );
      return;
    }

    screen.nav.modal(navTree.get.screens.bottomSheet, {
      content: (
        <RecipeDetailOptions
          recipeId={userRecipeId}
          sourceRecipeId={userRecipe?.sourceRecipeId}
          openExternalUrl={currentWebViewUrl}
          nav={screen.nav}
        />
      ),
      height: getRecipeDetailOptionsHeight({ recipeSourceType: userRecipe?.source.type }),
    });
    dispatch(analyticsEvent(reportRecipeLibraryContextMenuOpened()));
  }, [screen.nav, userRecipeId, userRecipe?.sourceRecipeId, currentWebViewUrl, userRecipe?.source.type]);

  const onPressReportContentMenu = useCallback(() => {
    if (!userRecipeId) {
      displayUnexpectedErrorAndLog(
        "RecipeDetailScreenComponent.onPressReportContentMenu called but userRecipeId is falsy",
        {},
        { props }
      );
      return;
    }

    screen.nav.modal(navTree.get.screens.bottomSheet, {
      content: <ReportContentIssueMenu nav={screen.nav} userRecipeId={userRecipeId} />,
      height: getOptionsMenuHeight(1),
    });
    dispatch(analyticsEvent(reportRecipeLibraryContextMenuOpened()));
  }, [screen.nav.modal, userRecipeId]);

  const onRefreshLibraryRecipe = useCallback(async () => {
    if (inLibrary && userRecipeId) {
      await dispatch(getAndStoreUserRecipe({ recipeId: userRecipeId }));
    }
  }, [userRecipeId]);

  const { height: actionBarHeight, onChangeHeight: onChangeActionBarHeight } = useRecipeDetailActionBarHeight();
  const actionBarBorderWidth = recipeUrl && !readerModeEnabled && currentWebViewUrl === recipeUrl ? "thick" : "thin";

  const renderActionBar = useCallback(() => {
    return (
      <>
        {!!props.recipe && (
          <RecipeDetailActionBar
            webViewSessionId={webViewSessionId}
            recipe={props.recipe}
            onSaveRecipe={props.onSaveRecipe}
            waitingSaveRecipe={props.waitingSaveRecipe}
            topBorderWidth={actionBarBorderWidth}
            onChangeHeight={onChangeActionBarHeight}
            context={props.context}
            showWalkthrough={walkthroughStep === "actions"}
            onWalkthroughNext={onWalkthroughNext}
            recipeScale={recipeScale}
          />
        )}
      </>
    );
  }, [
    webViewSessionId,
    props.recipe,
    props.onSaveRecipe,
    props.waitingSaveRecipe,
    actionBarBorderWidth,
    readerModeEnabled,
    onChangeActionBarHeight,
    props.context,
    walkthroughStep,
    onWalkthroughNext,
    recipeScale,
  ]);

  const headerAnimationRef = useHeaderOffset();

  useEffect(() => {
    // This resets the header header position to prevent the x-ray button from going out of view if the user scrolls
    // quickly before the walkthrough begins.
    headerAnimationRef.value = 0;
  }, [walkthroughStep]);

  const screenHeader = useMemo<HeaderProps>(() => {
    const getHeaderRightSide = (): HeaderRightProps | undefined => {
      if (webViewIsBrowseMode) {
        if (webViewCurrentUrl && !isSigninUrl) {
          return { type: "openLink", onPress: () => openWebpage(webViewCurrentUrl) };
        }
        return undefined;
      }

      if (inLibrary && !cookingSessionId) {
        return {
          type: "twoButtons",
          left: { type: "share", onPress: onPressShareButton },
          right: { type: "menu", onPress: onPressMenu },
        };
      }

      if (!inLibrary && !!userRecipeId && !isOwnRecipe) {
        return {
          type: "twoButtons",
          left: { type: "share", onPress: onPressShareButton },
          right: { type: "menu", onPress: onPressReportContentMenu },
        };
      }

      return {
        type: "share",
        onPress: onPressShareButton,
      };
    };

    return {
      type: "custom",
      title: webViewIsBrowseMode ? strings.browsing : renderReaderModeToggle,
      right: getHeaderRightSide(),
      animationConfig: {
        animationProgress: headerAnimationRef,
        blurBackgroundThreshold: 0,
      },
    };
  }, [
    webViewCurrentUrl,
    webViewIsBrowseMode,
    renderReaderModeToggle,
    userRecipeId,
    inLibrary,
    cookingSessionId,
    onPressShareButton,
    onPressMenu,
    headerAnimationRef,
    isSigninUrl,
    isOwnRecipe,
    onPressReportContentMenu,
  ]);

  return (
    <ScreenView
      header={screenHeader}
      scrollView={false}
      paddingHorizontal={false}
      paddingVertical={false}
      backgroundColor={globalStyleColors.colorGreyLight}
      loading={!props.recipe}
    >
      {!!props.recipe && (
        <RecipeDetail
          webViewSessionId={webViewSessionId}
          isReaderMode={readerModeEnabled}
          toggleReaderMode={toggleReaderModeEnabled}
          recipe={props.recipe}
          screenHeaderAnimationRef={headerAnimationRef}
          onWebViewNavigationStateChange={onWebViewNavStateChanged}
          renderActionBar={renderActionBar}
          actionBarHeight={actionBarHeight}
          refreshLibraryRecipe={inLibrary ? onRefreshLibraryRecipe : undefined}
          recipeScale={recipeScale}
          setRecipeScale={setRecipeScale}
          waitingFetchScalingData={waitingScalingData}
          fetchScalingDataErrored={fetchScalingDataErrored}
        />
      )}
    </ScreenView>
  );
});

function useRecipeDetailWalkthrough(recipe: AppRecipe | undefined) {
  const dispatch = useDispatch();
  const screen = useScreen();
  const xrayToggled = useCheckpointCompleted("recipeReaderModeToggled");
  const isWebRecipe = useRecipeSourceType(recipe) === "url";
  const isSocialMediaRecipe = !!useGetSocialMediaDomainType(recipe);
  const haveIngredients = !!recipe && haveRecipeIngredients(recipe.ingredients);

  const [walkthroughStep, setWalkthroughStep] = useState<(typeof walkthroughSteps)[number] | null>(null);
  const [walkthroughComplete, setWalkthroughComplete] = useState(false);

  // Triggers onboarding walkthrough
  useEffect(() => {
    if (
      screen.nav.focused &&
      !xrayToggled &&
      !walkthroughComplete &&
      walkthroughStep === null &&
      isWebRecipe &&
      !isSocialMediaRecipe &&
      haveIngredients
    ) {
      setTimeout(() => {
        setWalkthroughStep(walkthroughSteps[0]);
        dispatch(analyticsEvent(reportRecipeDetailWalkthroughStarted()));
      }, walkthroughRenderDelayMs);
    }
  }, [
    screen.nav.focused,
    xrayToggled,
    walkthroughComplete,
    !!walkthroughStep,
    isWebRecipe,
    isSocialMediaRecipe,
    haveIngredients,
  ]);

  // Updates walkthrough state to the next step
  const onWalkthroughNext = useCallback(() => {
    Haptics.feedback("itemStatusChanged");
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);

    setWalkthroughStep(prev => {
      if (prev === null) {
        log.error("onWalkthroughNext called but walkthrough step is null (walkthrough is already complete)");
        return prev;
      }
      const nextIdx = walkthroughSteps.indexOf(prev) + 1;
      if (nextIdx < walkthroughSteps.length) {
        return walkthroughSteps[nextIdx] as typeof walkthroughStep;
      }
      setWalkthroughComplete(true);
      return null;
    });
  }, [dispatch, setWalkthroughComplete]);

  // Reader mode walkthrough args (supports multiple walkthrough steps as we test variations)
  const readerModeWalkthrough = useMemo<ReaderModeWalkthrough | undefined>(() => {
    if (walkthroughStep === null) {
      return undefined;
    }

    switch (walkthroughStep) {
      case "actions": {
        return undefined;
      }
      case "tapXray": {
        return { step: walkthroughStep, onPressNext: onWalkthroughNext };
      }
      default:
        bottomThrow(walkthroughStep);
    }
  }, [walkthroughStep, onWalkthroughNext]);

  const { readerModeEnabled, setReaderModeEnabled, toggleReaderModeEnabled, renderReaderModeToggle } = useReaderMode(
    recipe,
    readerModeWalkthrough
  );

  // When the walkthrough is complete, toggle x-ray
  useEffect(() => {
    if (walkthroughComplete && !walkthroughStep) {
      setReaderModeEnabled(true);
      if (!xrayToggled) {
        dispatch(checkpointsCompleted(["recipeReaderModeToggled"]));
      }
      dispatch(analyticsEvent(reportRecipeDetailWalkthroughCompleted()));
    }
  }, [walkthroughComplete, !!walkthroughStep]);

  return { walkthroughStep, onWalkthroughNext, readerModeEnabled, toggleReaderModeEnabled, renderReaderModeToggle };
}

const ReportContentIssueMenu = React.memo((props: { userRecipeId: UserRecipeId; nav: NavApi }) => {
  const reportContentMenuItem = useReportContentIssueMenuItem("recipeDetail", props.userRecipeId, props.nav, true);

  return <OptionsMenu>{reportContentMenuItem}</OptionsMenu>;
});
