import { Envelope } from "@eatbetter/common-shared";
import {
  AdminBook,
  BookId,
  CreateEditBookArgs,
  ExternalBookRecipe,
  FindBooksArgs,
  FindBooksResult,
  RecipeErrorTypes,
} from "@eatbetter/recipes-shared";
import { SetWaitingHandler } from "@eatbetter/ui-shared";
import { ThunkAction } from "../AdminRedux";

export const createBookOrThrow = (args: CreateEditBookArgs, setWaiting?: SetWaitingHandler): ThunkAction<AdminBook> => {
  return async (_dispatch, _getState, deps) => {
    const result = await deps.api.withThrow(setWaiting).createBook(args);
    return result.data;
  };
};

export const getBook = (
  id: BookId,
  setWaiting?: SetWaitingHandler
): ThunkAction<Envelope<AdminBook, RecipeErrorTypes>> => {
  return async (_dispatch, _getState, deps) => {
    return deps.api.withReturn(setWaiting).getBook({ id });
  };
};

export const getBookOrThrow = (id: BookId, setWaiting?: SetWaitingHandler): ThunkAction<AdminBook> => {
  return async (_dispatch, _getState, deps) => {
    const result = await deps.api.withThrow(setWaiting).getBook({ id });
    return result.data;
  };
};

export const editBook = (
  updated: AdminBook,
  setWaiting?: SetWaitingHandler
): ThunkAction<Envelope<AdminBook, RecipeErrorTypes>> => {
  return async (_dispatch, _getState, deps) => {
    return deps.api.withReturn(setWaiting).editBook(updated);
  };
};

export const findBooks = (
  args: FindBooksArgs,
  setWaiting?: SetWaitingHandler
): ThunkAction<Envelope<FindBooksResult, RecipeErrorTypes>> => {
  return async (_dispatch, _getState, deps) => {
    return deps.api.withReturn(setWaiting).findBooks(args);
  };
};

export const archiveBook = (
  id: BookId,
  setWaiting?: SetWaitingHandler
): ThunkAction<Envelope<AdminBook, RecipeErrorTypes>> => {
  return async (_dispatch, _getState, deps) => {
    return deps.api.withReturn(setWaiting).archiveBook({ id });
  };
};

export const unArchiveBook = (
  id: BookId,
  setWaiting?: SetWaitingHandler
): ThunkAction<Envelope<AdminBook, RecipeErrorTypes>> => {
  return async (_dispatch, _getState, deps) => {
    return deps.api.withReturn(setWaiting).unArchiveBook({ id });
  };
};

export const getBookRecipes = (
  id: BookId,
  setWaiting?: SetWaitingHandler
): ThunkAction<Envelope<{ recipes: ExternalBookRecipe[] }, RecipeErrorTypes>> => {
  return async (_dispatch, _getState, deps) => {
    return deps.api.withReturn(setWaiting).getBookRecipes({ id });
  };
};
