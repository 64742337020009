import { useScreen, withScreenContainer } from "../navigation/ScreenContainer.tsx";
import { BookSelectScreenProps } from "../navigation/NavTree.ts";
import { EditUserRecipeAttributionArgs, UserRecipeId } from "@eatbetter/recipes-shared";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HeaderProps } from "../components/ScreenHeaders.tsx";
import { globalStyleColors, globalStyleConstants } from "../components/GlobalStyles.ts";
import { ScreenView } from "../components/ScreenView.tsx";
import { TBody } from "../components/Typography.tsx";
import { useExistingBookAttribution, useLibraryRecipe } from "../lib/recipes/RecipesSelectors.ts";
import { TextInput, TextInputHandle } from "../components/TextInput.tsx";
import { Keyboard, ScrollView, View } from "react-native";
import { deepEquals, filterOutFalsy } from "@eatbetter/common-shared";
import { PhotoRef } from "@eatbetter/photos-shared";
import { BookAttribution } from "../lib/recipes/RecipesSlice.ts";
import { Pressable } from "../components/Pressable.tsx";
import { Spacer } from "../components/Spacer.tsx";
import { Photo } from "../components/Photo.tsx";
import { ButtonRectangle } from "../components/Buttons.tsx";
import { Separator } from "../components/Separator.tsx";
import { SelectedCircle } from "../components/SelectedCircle.tsx";
import { useDispatch } from "../lib/redux/Redux.ts";
import { editRecipeAttribution } from "../lib/recipes/RecipesThunks.ts";
import { displayUnexpectedErrorAndLog } from "../lib/Errors.ts";

export const BookSelectionScreen = withScreenContainer(
  "BookSelectionScreen",
  (props: BookSelectScreenProps) => {
    const screen = useScreen();
    const [filter, setFilter] = useState("");
    const [selected, setSelected] = useState<BookAttribution | undefined>();
    const inputRef = useRef<TextInputHandle>(null);
    const dispatch = useDispatch();
    const [waiting, setWaiting] = useState(false);

    const books = useExistingBookAttribution();
    const recipe = useLibraryRecipe(props.recipeId);

    useEffect(() => {
      if (books.length === 0 || !recipe) {
        screen.nav.pop(props.popCount);
      }
    }, [books, screen.nav]);

    const onSelect = useCallback(
      (tapped: BookAttribution) => {
        if (attributionEqual(selected, tapped)) {
          setSelected(undefined);
        } else {
          setSelected(tapped);
          Keyboard.dismiss();
        }
      },
      [setSelected, selected]
    );

    const onSave = useCallback(() => {
      if (!selected || !recipe) {
        return;
      }

      const baseArgs: Pick<EditUserRecipeAttributionArgs, "recipeId" | "version"> = {
        recipeId: recipe.id,
        version: recipe.version,
      };

      const args: EditUserRecipeAttributionArgs =
        selected.type === "book"
          ? {
              ...baseArgs,
              type: "book",
              bookId: selected.book.id,
            }
          : {
              ...baseArgs,
              type: "userEntered",
              attribution: selected.attribution,
            };

      dispatch(editRecipeAttribution(args, setWaiting))
        .then(() => {
          screen.nav.pop(props.popCount);
        })
        .catch(err => {
          displayUnexpectedErrorAndLog(
            "Unexpected error dispatching editRecipeAttribution on BookSelectionScreen",
            err,
            { args }
          );
        });
    }, [recipe, selected, dispatch, setWaiting, screen.nav]);

    interface BookDisplay {
      name: string;
      author?: string;
      photo?: PhotoRef;
      value: BookAttribution;
    }
    const filtered = useMemo(() => {
      const display = books.map<BookDisplay>(b => {
        if (b.type === "book") {
          return { name: b.book.name, author: b.author, photo: b.book.photo, value: b };
        } else {
          return { name: b.attribution.name, author: b.attribution.author, photo: b.attribution.photo, value: b };
        }
      });

      if (filter.trim() === "") {
        return display;
      }

      const lcFilter = filter.toLowerCase();
      return display.filter(b => {
        const terms = [b.name, b.author];
        return filterOutFalsy(terms)
          .map(t => t.toLowerCase())
          .some(t => t.includes(lcFilter));
      });
    }, [books, filter]);

    const header = useMemo<HeaderProps>(() => {
      return {
        type: "native",
        backgroundColor: globalStyleColors.colorGreyLight,
        title: "BOOK SELECTION SCREEN", //attribution-todo
        right: { type: "cancel", onPress: () => screen.nav.pop(props.popCount) },
      };
    }, [screen.nav.pop]);

    return (
      <ScreenView header={header} scrollView={false}>
        <View style={{ width: "100%" }}>
          <TextInput
            value={filter}
            onChangeText={setFilter}
            ref={inputRef}
            placeholderText={props.popCount.toString()}
          />
        </View>
        <Spacer vertical={1} />
        <ScrollView style={{ flex: 1 }} keyboardShouldPersistTaps="always">
          {filtered.map((b, idx) => {
            return (
              <Pressable onPress={() => onSelect(b.value)} key={`${b.name} ${b.author} ${idx}`}>
                <View>
                  <View style={{ flexDirection: "row", flex: 1 }}>
                    <Photo source={b.photo} sourceSize="w288" style="thumbnailSmall" />
                    <TBody>
                      {b.name} {b.author ?? ""}
                    </TBody>
                    <View style={{ paddingVertical: 1.25 * globalStyleConstants.unitSize }}>
                      <Separator orientation="column" />
                    </View>
                    <View style={{ padding: globalStyleConstants.unitSize }}>
                      <SelectedCircle isSelected={attributionEqual(selected, b.value)} />
                    </View>
                  </View>
                  <Spacer vertical={1} />
                </View>
              </Pressable>
            );
          })}
        </ScrollView>
        <ButtonRectangle type="submit" title="Save" onPress={onSave} disabled={!selected} waiting={waiting} />
      </ScreenView>
    );
  },
  {
    serializer: {
      recipeId: s => s,
      popCount: n => n.toString(),
    },
    parser: {
      recipeId: s => s as UserRecipeId,
      popCount: s => Number.parseInt(s),
    },
  }
);

function attributionEqual(a: BookAttribution | undefined, b: BookAttribution | undefined) {
  if (a === undefined || b === undefined) {
    return false;
  }

  return deepEquals(a, b);
}
