import React, { useCallback, useEffect, useLayoutEffect, useMemo } from "react";
import { LayoutAnimation, StyleSheet, View } from "react-native";
import { getFollowableEntities, EntitySelectedHandler, SelectEntity } from "../SelectEntity";
import { useDispatch } from "../../lib/redux/Redux";
import { checkpointsCompleted } from "../../lib/system/SystemSlice";
import { dismissFollowRecommendation, navToEntityScreen } from "../../lib/social/SocialThunks";
import { Separator } from "../Separator";
import { Haptics } from "../Haptics";
import { Spacer } from "../Spacer";
import { globalStyleConstants } from "../GlobalStyles";
import { TBody } from "../Typography";
import { TextButton } from "../Buttons";
import { useScreen } from "../../navigation/ScreenContainer";
import { useHasFollowings, useHaveFollowingFeedPosts, useRecommendedFollows } from "../../lib/social/SocialSelectors";
import { switchReturn } from "@eatbetter/common-shared";
import { useCheckpointCompleted } from "../../lib/system/SystemSelectors";
import { followSuggestionModuleDismissed } from "../../lib/social/SocialSlice";
import { analyticsEvent } from "../../lib/analytics/AnalyticsThunks";
import {
  reportRecommendedFollowDismissed,
  reportRecommendedFollowsDismissed,
  reportRecommendedFollowsDisplayed,
  reportRecommendedFollowTapped,
} from "../../lib/analytics/AnalyticsEvents";
import { log } from "../../Log";
import { CtaLocation } from "@eatbetter/composite-shared";
import { getSocialEntityId } from "@eatbetter/posts-shared";

const strings = {
  headline: "Suggested for you",
  close: "Close",
};

interface RecommendedFollowsProps {
  context: "topSlotFeed" | "inlineFeed";
}

export const RecommendedFollows = React.memo((props: RecommendedFollowsProps) => {
  const dispatch = useDispatch();
  const focused = useScreen().nav.focused;
  const { nav } = useScreen();

  const hasFollowings = useHasFollowings();
  const hasPosts = useHaveFollowingFeedPosts();

  const dismissed = useCheckpointCompleted("recommendedFollowsDismissed");
  const dismissable = !dismissed && hasFollowings && hasPosts;

  const recommendations = useRecommendedFollows();
  const users = useMemo(() => {
    if (recommendations.length === 0) {
      return [];
    }

    return [
      {
        entities: getFollowableEntities(
          recommendations.map(i => i.entity),
          switchReturn(props.context, {
            topSlotFeed: "recommendedFollowsFeedTopSlot",
            inlineFeed: "recommendedFollowsFeedInlineSlot",
          }),
          true
        ),
      },
    ];
  }, [recommendations, props.context]);

  const onSelectUser = useCallback<EntitySelectedHandler>(
    item => {
      dispatch(analyticsEvent(reportRecommendedFollowTapped({ entity: item.entity })));
      dispatch(navToEntityScreen(getSocialEntityId(item.entity), nav, "recommendedFollows"));
    },
    [dispatch, nav]
  );

  const onDismissRecommendation = useCallback<EntitySelectedHandler>(
    item => {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      Haptics.feedback("itemStatusChanged");
      dispatch(dismissFollowRecommendation(getSocialEntityId(item.entity)));
      dispatch(analyticsEvent(reportRecommendedFollowDismissed({ recommendedEntity: item.entity })));
    },
    [dispatch]
  );

  const onDismissModule = useCallback(() => {
    try {
      dispatch(followSuggestionModuleDismissed());
      dispatch(checkpointsCompleted(["recommendedFollowsDismissed"]));
      dispatch(analyticsEvent(reportRecommendedFollowsDismissed({ recommendationCount: recommendations.length })));
    } catch (err) {
      log.errorCaught("Error caught in RecommendedFollows.onDismissModule", err);
    }
  }, [dispatch]);

  useEffect(() => {
    const ctaLocation: CtaLocation = switchReturn(props.context, {
      inlineFeed: "Inline Feed",
      topSlotFeed: "Top of Feed",
    });

    if (users.length > 0) {
      // NOTE: We are currently firing this on mount, which does *not* necessarily mean the user actually sees it.
      // We would need to plumb through the visibility of the item to this component to do that.
      dispatch(
        analyticsEvent(reportRecommendedFollowsDisplayed({ ctaLocation, recommendationCount: recommendations.length }))
      );
    }
  }, [props.context, users.length > 0]);

  useLayoutEffect(() => {
    if (focused) {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    }
  }, [users.length]);

  useLayoutEffect(() => {
    return () => {
      if (focused) {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      }
    };
  }, []);

  if (users.length === 0) {
    return null;
  }

  return (
    <>
      <View style={styles.paddingHorizontal}>
        <Spacer vertical={1.5} />
        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
          <TBody fontWeight="medium">{strings.headline}</TBody>
          {dismissable && <TextButton text={strings.close} onPress={onDismissModule} />}
        </View>
        <Spacer vertical={0.5} />
      </View>
      <SelectEntity
        horizontal
        type="flexed"
        entityData={users}
        onSelectEntity={onSelectUser}
        onDismissEntity={onDismissRecommendation}
        waitingForResults={false}
        paddingHorizontal="none"
      />
      <Spacer vertical={1} />
      <View style={{ paddingHorizontal: globalStyleConstants.minPadding }}>
        <Separator orientation="row" />
      </View>
    </>
  );
});

const styles = StyleSheet.create({
  paddingHorizontal: {
    paddingHorizontal: globalStyleConstants.defaultPadding,
  },
});
