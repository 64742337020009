import {
  AdminEditExternalBookRecipeArgs,
  Author,
  ExternalBookRecipe,
  RecipeTagManifest,
} from "@eatbetter/recipes-shared";
import { View } from "react-native";
import { EditExternalBookRecipeOnSaveArgs, RecipeEditControl } from "@eatbetter/ui-shared";
import { useDispatch } from "../lib/AdminRedux";
import { editExternalBookRecipe } from "../lib/recipes/AdminRecipesThunks";
import { addPhoto } from "../lib/photos/AdminPhotoThunks";
import { ParsedIngredientSummary } from "@eatbetter/items-shared";

export const EditExternalBookRecipe = (props: {
  initialRecipe: ExternalBookRecipe;
  parsedIngredients?: ParsedIngredientSummary[];
  tagManifest?: RecipeTagManifest;
  onDone: () => void;
}) => {
  const dispatch = useDispatch();

  const onSave = async (args: EditExternalBookRecipeOnSaveArgs) => {
    const recipePhoto = args.newRecipePhoto ? await dispatch(addPhoto(args.newRecipePhoto)) : args.photo;
    const authorPhoto = args.newAuthorPhoto ? await dispatch(addPhoto(args.newAuthorPhoto)) : args.author?.photo;

    const author: Author | undefined = args.author
      ? {
          ...args.author,
          photo: authorPhoto,
        }
      : args.author;

    const editArgs: AdminEditExternalBookRecipeArgs = {
      id: props.initialRecipe.id,
      tagUpdate: args.tags ? { op: "replace", by: "admin", tags: args.tags } : undefined,
      version: props.initialRecipe.version,
      updates: {
        title: args.title,
        description: args.description,
        ingredients: args.ingredients,
        instructions: args.instructions,
        author,
        book: args.book,
        photo: recipePhoto,
        time: args.time,
        recipeYield: args.recipeYield,
      },
    };

    await dispatch(editExternalBookRecipe(editArgs));
    props.onDone();
  };

  return (
    <View>
      <RecipeEditControl
        type="adminExternalBook"
        initialRecipe={props.initialRecipe}
        onSave={onSave}
        parsedIngredients={props.parsedIngredients}
        tagManifest={props.tagManifest}
      />
    </View>
  );
};
