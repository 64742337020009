import { TBody, displayUnexpectedErrorAndLogHandler } from "@eatbetter/ui-shared";
import { RecipeId } from "@eatbetter/recipes-shared";
import { useStringParam } from "../lib/ParamUtils";
import { useEffect, useState } from "react";
import { AdminScreenView } from "../components/AdminScreenView";
import { View } from "react-native";
import { useDispatch } from "../lib/AdminRedux";
import { getRecipe } from "../lib/recipes/AdminRecipesThunks";
import { useNavigate } from "react-router-dom";
import { Divider } from "antd";
import { AdminGetRecipeResult } from "@eatbetter/composite-shared";
import { ExternalRecipeView } from "../components/ExternalRecipeView";
import { UserRecipeView } from "../components/UserRecipeView";
import { FindOrAddRecipe } from "../components/FindOrAddRecipe";

export const AdminRecipesScreenEmptyNav = {
  getPath: () => "/recipes",
};

const recipeIdParam = ":recipeId";
export const AdminRecipesScreenIdNav = {
  getPath: (id?: string) => {
    return `${AdminRecipesScreenEmptyNav.getPath()}/${id ?? recipeIdParam}`;
  },
};

export const AdminRecipesScreen = () => {
  const id = useStringParam(recipeIdParam).optional();
  const [searchQuery, setSearchQuery] = useState(id ?? "");
  const [waiting, setWaiting] = useState(false);
  const [data, setData] = useState<AdminGetRecipeResult | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [refreshToken, setRefreshToken] = useState(Date.now());
  const dispatch = useDispatch();
  const nav = useNavigate();

  // on param change, search for the id if one is specified
  useEffect(() => {
    if (id === undefined) {
      setData(undefined);
    } else {
      // errors are handled within submitSearch
      fetchRecipe(id);
    }
  }, [id, refreshToken]);

  const fetchRecipe = (idToSearch: string) => {
    const fn = async () => {
      const r = await dispatch(getRecipe(idToSearch as RecipeId, setWaiting));
      if (r.data) {
        setData(r.data);
        setError(undefined);
        setSearchQuery("");
      } else {
        setError(
          `Error fetching '${idToSearch}': ${r.error.code} ${r.error.unfriendlyMessage ?? ""} ${
            r.error.userMessage ?? ""
          }`
        );
      }
    };

    fn().catch(displayUnexpectedErrorAndLogHandler(`Error fetching recipe ID ${searchQuery} on AdminRecipesScreen`));
  };

  const onSearch = (id?: RecipeId) => {
    if (id) {
      nav(AdminRecipesScreenIdNav.getPath(id));
    } else {
      // clear data if the search didn't return a recipe
      setData(undefined);
    }
  };

  const onUpdate = () => {
    setRefreshToken(Date.now());
  };

  return (
    <AdminScreenView>
      <FindOrAddRecipe onSearch={onSearch} waiting={waiting} />
      <TBody color="red">{error}</TBody>
      <Divider />
      <View>
        {(data?.recipe.type === "externalUrlRecipe" || data?.recipe.type === "externalBookRecipe") && (
          <ExternalRecipeView
            recipe={data.recipe}
            tagManifest={data.tagManifest}
            parsed={data.parsedIngredients}
            setRecipe={setData}
            recipeCopies={data.userRecipes}
            overrides={data.adminCategoryOverrides}
          />
        )}
        {data?.recipe.type === "userRecipe" && (
          <UserRecipeView
            recipe={data?.recipe}
            parsed={data.parsedIngredients}
            tagManifest={data.tagManifest}
            onRecipeUpdated={onUpdate}
          />
        )}
      </View>
    </AdminScreenView>
  );
};
