import { RecipeTagCategoryList, SystemRecipeTag, UserRecipeId } from "@eatbetter/recipes-shared";
import React, { useCallback, useEffect, useState } from "react";
import { LayoutAnimation, View } from "react-native";
import { displayUnexpectedErrorAndLog } from "../../lib/Errors";
import { AppRecipeTag } from "../../lib/recipes/RecipesSlice";
import { editRecipeTag } from "../../lib/recipes/RecipesThunks";
import { useRecipeTags, useTagManifest, useUserTags } from "../../lib/recipes/RecipeTagSelectors";
import { useDispatch } from "../../lib/redux/Redux";
import { Haptics } from "../Haptics";
import { getTagDisplayLabel, RecipeTagSelect } from "./RecipeTagSelect";
import { useScreen } from "../../navigation/ScreenContainer";
import { RecipeEditFieldLocation } from "../../navigation/NavTree";

const strings = {
  tagSelectError: "Sorry, we're having trouble connecting right now and couldn't save your tag update",
};

interface Props {
  recipeId: UserRecipeId;
  onPressAddUserTag: () => void;
  location: RecipeEditFieldLocation;
}

export const RecipeTagsEdit = React.memo((props: Props) => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const recipeTags = useRecipeTags(props.recipeId);
  const userTags = useUserTags();
  const tagManifest = useTagManifest();

  const [waiting, setWaiting] = useState<Record<string, boolean>>({});

  useEffect(() => {
    if (!screen.nav.focused) {
      return;
    }
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  }, [userTags, recipeTags, tagManifest.categoryList, screen.nav.focused]);

  const toggleTag = useCallback(
    (tag: AppRecipeTag) => {
      if (tag.type === "totalTime") {
        throw new Error(`Tags edit: Unsupported tag type ${tag.type}`);
      }

      Haptics.feedback("itemStatusChanged");

      const selected = recipeTags.some(recipeTag => recipeTag.type === tag.type && recipeTag.tag === tag.tag);

      dispatch(
        editRecipeTag(
          {
            tag,
            action: selected ? "remove" : "add",
            recipeId: props.recipeId,
          },
          props.location,
          value => setWaiting(prev => ({ ...prev, [tag.tag]: value }))
        )
      ).catch(err => {
        displayUnexpectedErrorAndLog(strings.tagSelectError, err, {
          recipeId: props.recipeId,
          tag,
        });
      });
    },
    [recipeTags, dispatch, setWaiting, props.location, props.recipeId]
  );

  const renderTagLabel = useCallback(
    (tag: AppRecipeTag): string => {
      return getTagDisplayLabel(tag, tagManifest);
    },
    [tagManifest]
  );

  return (
    <RecipeTagSelect
      userTags={userTags}
      systemTags={tagManifest.categoryList}
      selectedTags={recipeTags}
      renderTagLabel={renderTagLabel}
      onPressTag={toggleTag}
      waiting={waiting}
      onPressAddTag={props.onPressAddUserTag}
    />
  );
});

// ADMIN-ONLY

interface AdminRecipeTagsEditProps {
  systemTags: RecipeTagCategoryList;
  selectedTags: SystemRecipeTag[];
  renderTagLabel: (tag: SystemRecipeTag) => string;
  onPressTag: (tag: SystemRecipeTag) => void;
}

export const AdminRecipeTagsEdit = (props: AdminRecipeTagsEditProps) => {
  const onPressTag = useCallback(
    (tag: AppRecipeTag) => {
      if (tag.type !== "system") {
        throw new Error("Unexpected tag type in AdminRecipeTagsEdit: " + tag.type);
      }

      props.onPressTag(tag);
    },
    [props.onPressTag]
  );

  const renderTagLabel = useCallback(
    (tag: AppRecipeTag) => {
      if (tag.type !== "system") {
        throw new Error("Unexpected tag type in AdminRecipeTagsEdit: " + tag.type);
      }

      return props.renderTagLabel(tag);
    },
    [props.renderTagLabel]
  );

  return (
    <View style={{ padding: 24, paddingTop: 0, borderWidth: 1, borderRadius: 12, borderColor: "black" }}>
      <RecipeTagSelect
        userTags={[]}
        systemTags={props.systemTags}
        selectedTags={props.selectedTags}
        renderTagLabel={renderTagLabel}
        onPressTag={onPressTag}
        hideEmptyUserSection
      />
    </View>
  );
};
