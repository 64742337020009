import { useCallback } from "react";
import { useGetSocialMediaDomainType } from "../recipes/RecipesSelectors";
import { navTree } from "../../navigation/NavTree";
import { UserRecipeId } from "@eatbetter/recipes-shared";
import { NavApi } from "../../navigation/ScreenContainer";

/**
 * Returns a callback that will navigate to the appropriate report issue screen for the given
 * recipe type (e.g. social, web) and issue type, i.e. missing vs incorrect.
 */
export function useNavToReportRecipeIssue(args: {
  recipeId: UserRecipeId;
  issueType: "missing" | "incorrect";
  nav: NavApi;
}) {
  const isSocialMediaRecipe = useGetSocialMediaDomainType(args.recipeId);

  const navToReportRecipeIssue = useCallback(() => {
    const destination =
      args.issueType === "missing"
        ? isSocialMediaRecipe
          ? navTree.get.screens.reportSocialMediaRecipeMissingIssue
          : navTree.get.screens.reportMissingRecipeIssue
        : navTree.get.screens.reportRecipeIssue;

    args.nav.goTo("push", destination, { recipeId: args.recipeId });
  }, [isSocialMediaRecipe, args.recipeId]);

  return navToReportRecipeIssue;
}
