import React, { useCallback } from "react";
import { saveSharedRecipe } from "../lib/recipes/RecipesThunks";
import { useDispatch } from "../lib/redux/Redux";
import { ShareViewRecipeScreenProps } from "../navigation/NavTree";
import { withScreenContainer } from "../navigation/ScreenContainer";
import { ViewRecipeScreenComponent, ViewRecipeScreenComponentProps } from "../components/ViewRecipeScreenComponent";
import { PartialRecipeId, RecipeId } from "@eatbetter/recipes-shared";
import { UserId } from "@eatbetter/common-shared";
import { useIdempotentId } from "../lib/util/UseIdempotentId";

// NOTE: This screen is very similar to PostViewRecipeScreen, SearchViewRecipeScreen
// Updates here may apply there as well.
export const ShareViewRecipeScreen = withScreenContainer(
  "ShareViewRecipeScreen",
  (props: ShareViewRecipeScreenProps) => {
    const dispatch = useDispatch();
    const [id, refreshId] = useIdempotentId<PartialRecipeId>();

    const onSaveRecipe = useCallback(async () => {
      await dispatch(
        saveSharedRecipe(
          {
            partialRecipeId: id,
            sourceRecipeId: props.sourceRecipeId,
            sharedByRecipeId: props.sharedByRecipeId,
            sharedByUserId: props.sharedByUserId,
          },
          props.context
        )
      );
      refreshId();
    }, [
      id,
      props.sourceRecipeId,
      props.sharedByRecipeId,
      props.sharedByUserId,
      props.context,
      dispatch,
      id,
      refreshId,
    ]);

    return React.createElement<ViewRecipeScreenComponentProps>(ViewRecipeScreenComponent, {
      context: "share",
      recipeId: props.sourceRecipeId,
      onSaveRecipe,
    });
  },
  {
    serializer: {
      sourceRecipeId: s => s,
      sharedByUserId: s => s,
      sharedByRecipeId: s => s,
      context: s => s,
    },
    parser: {
      sourceRecipeId: s => s as RecipeId,
      sharedByUserId: s => s as UserId,
      sharedByRecipeId: s => s as RecipeId,
      context: s => s as "socialPostComment" | "userShared",
    },
  }
);
