import React from "react";
import { StyleSheet, View } from "react-native";
import { globalStyleColors, globalStyleConstants } from "./GlobalStyles";
import { Pressable } from "./Pressable";
import { Spinner } from "./Spinner";
import { TSecondary } from "./Typography";

const constants = {
  height: 34,
};

export type SelectableOvalRenderBadge = (args: {
  isSelected: boolean;
  waiting: boolean;
  disabled: boolean;
}) => React.ReactElement;

interface Props {
  text: string;
  isSelected: boolean;
  onPress: () => void;
  waiting?: boolean;
  disabled?: boolean;
  renderBadge?: SelectableOvalRenderBadge;
}

export const SelectableOval = React.memo((props: Props) => {
  const textColor = props.isSelected ? "white" : globalStyleColors.black;
  const backgroundColor = props.isSelected ? globalStyleColors.colorAccentCool : "transparent";

  return (
    <Pressable
      style={[styles.container, { backgroundColor }]}
      onPress={props.onPress}
      noFeedback
      disabled={props.disabled}
    >
      {props.waiting && (
        <View style={{ position: "absolute", zIndex: 1 }}>
          <Spinner color={textColor === "white" ? "light" : "accentCool"} />
        </View>
      )}
      <View>
        <TSecondary color={textColor} opacity={props.waiting ? "transparent" : "opaque"}>
          {props.text}
        </TSecondary>
      </View>
      {props.renderBadge?.({ isSelected: props.isSelected, waiting: !!props.waiting, disabled: !!props.disabled })}
    </Pressable>
  );
});

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "auto",
    height: constants.height,
    borderRadius: constants.height / 2,
    paddingHorizontal: 1.25 * globalStyleConstants.unitSize,
    borderColor: globalStyleColors.rgba("colorAccentCool", "medium"),
    borderWidth: 0.5,
  },
});
