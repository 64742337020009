import React from "react";
import { StyleSheet, View } from "react-native";
import { TSecondary, TTertiary } from "./Typography";
import { ColorKey, globalStyleColors } from "./GlobalStyles";

interface BadgeProps {
  count?: number;
  scale?: number;
  offset?: number;
  color?: Extract<ColorKey, "colorAccentCool" | "red">;
}

export const Badge = React.memo((props: BadgeProps) => {
  if (!(props.count && props.count > 0)) {
    return null;
  }

  const scale = props.scale ?? 1;
  const offset = props.offset ?? 0;
  const color = props.color ? globalStyleColors[props.color] : globalStyleColors.red;

  return (
    <View
      style={[
        styles.badge,
        {
          backgroundColor: color,
          transform: [{ scaleX: scale }, { scaleY: scale }, { translateX: -offset }, { translateY: offset }],
        },
      ]}
    >
      <TTertiary color="white" fontWeight="medium">
        {props.count}
      </TTertiary>
    </View>
  );
});

export const NewBadge = React.memo(() => {
  return (
    <View
      style={{
        paddingVertical: 4,
        paddingHorizontal: 12,
        borderRadius: Number.MAX_SAFE_INTEGER,
        backgroundColor: globalStyleColors.rgba("colorAccentCool", "xlight"),
      }}
    >
      <TSecondary fontWeight="medium" color={globalStyleColors.colorAccentCool}>
        {"New"}
      </TSecondary>
    </View>
  );
});

const styles = StyleSheet.create({
  badge: {
    minWidth: 18,
    height: 18,
    borderRadius: 18,
    paddingHorizontal: 4,
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: -8,
    right: -8,
    zIndex: 1,
  },
});
