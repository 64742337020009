import React, { useCallback, useMemo } from "react";
import { ScreenView, useScreenElementDimensions } from "../components/ScreenView";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { UrlString, isUrl } from "@eatbetter/common-shared";
import { View } from "react-native";
import { ViewVideoScreenProps } from "../navigation/NavTree";
import { Video, VideoPlaybackMode, VideoSource } from "../components/Video";
import { Pressable } from "../components/Pressable";
import { IconEx } from "../components/Icons";

export const ViewRemoteVideoScreen = withScreenContainer(
  "ViewVideoScreen",
  (props: ViewVideoScreenProps) => {
    return React.createElement<Props>(ViewVideoScreenComponent, {
      analyticsId: props.analyticsId,
      videoSource: props.videoSource,
    });
  },
  {
    serializer: {
      videoSource: s => JSON.stringify(s),
      analyticsId: s => s,
    },
    parser: {
      videoSource: s => {
        if (isUrl(s)) {
          return s as UrlString;
        }
        return JSON.parse(s) as number;
      },
      analyticsId: s => s,
    },
  }
);

interface Props {
  videoSource: UrlString | number;
  analyticsId: string;
}

const ViewVideoScreenComponent = React.memo((props: Props) => {
  const screen = useScreen();
  const { modalHeaderHeight } = useScreenElementDimensions();

  const onPressClose = useCallback(() => {
    screen.nav.goBack();
  }, [screen.nav.goBack]);

  const playbackMode = useMemo<VideoPlaybackMode>(() => {
    return {
      type: "fullscreenOnly",
      // The timeout here avoids a conflict between the full screen player dismiss + navigation animations that sometimes crashes
      // on debug builds
      onDismissFullscreenPlayer: () => setTimeout(() => onPressClose(), 500),
    };
  }, [onPressClose]);

  const videoSource = useMemo<VideoSource>(() => {
    if (typeof props.videoSource === "string" && isUrl(props.videoSource)) {
      return {
        type: "remote",
        url: props.videoSource,
      };
    }

    return {
      type: "bundled",
      nodeRequireOutput: props.videoSource,
    };
  }, [props.videoSource]);

  return (
    <ScreenView scrollView={false} paddingHorizontal={false} paddingVertical={false} backgroundColor="black">
      <View style={{ flex: 1 }}>
        <Video videoAnalyticsId={props.analyticsId} videoSource={videoSource} playbackMode={playbackMode} autoPlay />
        <Close onPress={onPressClose} top={modalHeaderHeight} left={28} />
      </View>
    </ScreenView>
  );
});

const Close = React.memo((props: { onPress: () => void; top: number; left: number }) => {
  return (
    <Pressable style={{ position: "absolute", zIndex: 10, left: props.left, top: props.top }} onPress={props.onPress}>
      <IconEx size={28} opacity="opaque" color="white" />
    </Pressable>
  );
});
