import { bottomThrow, SocialMediaDomainKey, switchReturn } from "@eatbetter/common-shared";
import React from "react";
import { Image, ImageProps, View } from "react-native";

export const SocialMediaLogo = React.memo((props: { type: SocialMediaDomainKey; size?: "small" | "medium" }) => {
  const size = props.size ?? "medium";

  switch (props.type) {
    case "instagram": {
      const instagramLogo = require("../assets/images/instagram_logo.png");
      return <LogoImage source={instagramLogo} size={size} />;
    }
    case "tikTok": {
      const tiktokLogo = require("../assets/images/tiktok_logo.png");
      return <LogoImage source={tiktokLogo} size={size} />;
    }
    case "facebook": {
      const facebookLogo = require("../assets/images/facebook_logo.png");
      return <LogoImage source={facebookLogo} size={size} />;
    }
    case "youtube": {
      const youtTubeLogo = require("../assets/images/youtube_logo.png");
      return <LogoImage source={youtTubeLogo} size={size} aspectRatio={177 / 256} />;
    }
    case "pinterest": {
      const pinterestLogo = require("../assets/images/pinterest_logo.png");
      return <LogoImage source={pinterestLogo} size={size} />;
    }
    default:
      bottomThrow(props.type);
  }
});

const LogoImage = (props: { source: ImageProps["source"]; size: "small" | "medium"; aspectRatio?: number }) => {
  const size = switchReturn(props.size, {
    small: 18,
    medium: 24,
  });

  const { width, height } = props.aspectRatio
    ? props.aspectRatio > 0
      ? { width: size * props.aspectRatio, height: size }
      : { width: size, height: size * props.aspectRatio }
    : { width: size, height: size };

  return (
    <View>
      <Image source={props.source} resizeMode="contain" style={{ width, height }} />
    </View>
  );
};
