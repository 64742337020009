import { Reactor } from "../redux/Reactors";
import { loadRecipes, loadRecipesScript, updateProcessingLibraryRecipes } from "./RecipesThunks";
import {
  daysBetween,
  defaultTimeProvider,
  EpochMs,
  minutesBetween,
  nowOrLaterDelayFromLast,
  secondsBetween,
} from "@eatbetter/common-shared";
import { Platform } from "react-native";
import { shouldFetch } from "../redux/ShouldFetch";
import { selectFetchIntervalForHouseholdUpdate, selectIsHouseholdUpdatePeriodActive } from "../system/SystemSelectors";
import { UserRecipeId } from "@eatbetter/recipes-shared";

const fetchRecipesReactor: Reactor = state => {
  // the recipes were making web redux-persist crawl, so just skipping them for now
  if (Platform.OS === "web") {
    return undefined;
  }

  const fetchResult = shouldFetch("recipes.meta", state, s => s.recipes.meta, {
    staleThresholdSeconds: selectFetchIntervalForHouseholdUpdate(state, 300),
  });

  const fetchFullListNow =
    selectIsHouseholdUpdatePeriodActive(state) ||
    nowOrLaterDelayFromLast({
      last: state.recipes.meta.lastFullGet ?? (0 as EpochMs),
      delayInSeconds: 86400,
      timeNow: state.system.time,
    }).now;

  if (fetchResult.now) {
    return {
      dispatch: loadRecipes(fetchFullListNow ? "full" : "partial"),
    };
  }

  if (fetchResult.laterIn) {
    return {
      kickInMs: fetchResult.laterIn,
    };
  }

  return undefined;
};

const fetchRecipesScriptReactor: Reactor = state => {
  const fetchResult = shouldFetch("recipes.script", state, s => s.recipes.script, {
    staleThresholdSeconds: 43200,
  });

  if (fetchResult.now) {
    return { dispatch: loadRecipesScript() };
  }

  if (fetchResult.laterIn) {
    return { kickInMs: fetchResult.laterIn };
  }

  return undefined;
};

// In the normal case, we expect websocket updates to keep the recipe up to date in a very timely fashion.
// In the event that websockets aren't working as expected, or the processing is just taking a long time,
// we poll to make sure a user doesn't perceive a processing recipe to be stuck.
// While this is quite conservative, a good improvement could be to bring websocket ping status into the redux
// state so we could be more conservative if we know websockets are connected and working
const updateProcessingRecipesReactor: Reactor = state => {
  const toRemove: UserRecipeId[] = [];
  let toUpdate: UserRecipeId | undefined;
  let kickInMs: number | undefined;
  // we can remove multiple in one pass, but we limit updating to a single recipe
  Object.entries(state.recipes.processingRecipes).forEach(e => {
    const [id, lastFetch] = e;
    const recipe = state.recipes.entities[id];
    const now = defaultTimeProvider();
    if (
      !recipe ||
      recipe.status !== "processing" ||
      recipe.archived ||
      recipe.deleted ||
      daysBetween(recipe.created, now) > 2
    ) {
      toRemove.push(id as UserRecipeId);

      // if we already have a recipe, no need to check - we'll catch it next time
    } else if (!toUpdate) {
      const seconds = secondsBetween(now, Math.max(recipe.version, lastFetch) as EpochMs);
      const minutesSinceLastRecipeUpdate = minutesBetween(now, recipe.version);

      // if we're within 2 minutes, check every 5 seconds. If we're between 2 and 5, every 15 seconds. Over 10, every minute.
      const interval = minutesSinceLastRecipeUpdate < 2 ? 5 : minutesSinceLastRecipeUpdate < 5 ? 15 : 60;

      if (seconds > interval) {
        toUpdate = id as UserRecipeId;
      } else {
        // note the interval might change between now and then, but that's okay
        const kickCandidate = 1000 * (interval - seconds);
        if (!kickInMs || kickCandidate < kickInMs) {
          kickInMs = kickCandidate;
        }
      }
    }
  });

  if (toRemove.length > 0 || !!toUpdate) {
    return { dispatch: updateProcessingLibraryRecipes(toUpdate, toRemove) };
  } else if (kickInMs && kickInMs < 10000) {
    return { kickInMs };
  }

  return undefined;
};

export const recipesReactors = [fetchRecipesReactor, fetchRecipesScriptReactor, updateProcessingRecipesReactor];
