import { Reactor } from "../redux/Reactors";
import { loadNewNotifications } from "./NotificationsThunks";
import { shouldFetch } from "../redux/ShouldFetch";

const fetchNotificationsReactor: Reactor = state => {
  const fetchResult = shouldFetch(
    "notifications.newNotificationsMeta",
    state,
    s => s.notifications.newNotificationsMeta,
    {
      staleThresholdSeconds: 300,
    }
  );

  if (fetchResult.now) {
    return {
      dispatch: loadNewNotifications(),
    };
  }

  if (fetchResult.laterIn) {
    return {
      kickInMs: fetchResult.laterIn,
    };
  }

  return undefined;
};

export const notificationsReactors = [fetchNotificationsReactor];
