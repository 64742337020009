import { addSearchSession, removeSearchSession, SearchSessionId } from "./SearchSlice";
import { KnownAuthorId, KnownPublisherId } from "@eatbetter/recipes-shared";
import { newId } from "@eatbetter/common-shared";
import { useEffect, useRef } from "react";
import { useDispatch } from "../redux/Redux";
import { searchSubmitted } from "./SearchThunks";

export const useSearchSession = (args: {
  authorId?: KnownAuthorId;
  publisherId?: KnownPublisherId;
  /**
   * If true, a search request will be made immediately to load
   * results for an empty query (e.g. browse results for a KA/KP)
   */
  initialLoad: boolean;
}): SearchSessionId => {
  const sessionId = useRef(newId<SearchSessionId>()).current;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addSearchSession({ sessionId, authorId: args.authorId, publisherId: args.publisherId }));

    // start a search if we have an author id or publisher id to get the initial browse experience going.
    if (args.initialLoad) {
      dispatch(searchSubmitted(sessionId));
    }

    return () => {
      dispatch(removeSearchSession({ sessionId }));
    };
  }, [args.authorId, args.publisherId, sessionId]);

  return sessionId;
};
