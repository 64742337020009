import { DeglazeUser } from "@eatbetter/users-shared";
import { createSelector4, getCreateSelectorWithCacheSize } from "../redux/CreateSelector";
import { useSelector } from "../redux/Redux";
import { KnownAuthor, KnownPublisher } from "@eatbetter/recipes-shared";
import { RootState } from "../redux/RootReducer";

export interface UserSearchResults {
  users: DeglazeUser[];
  pending: boolean;
}

export const useUserSearchUsers = () => useSelector(selectUserSearchUsers);

const selectUserSearchUsers = createSelector4(
  s => s.users.search.users.entities,
  s => s.users.search.currentQuery,
  s => s.users.search.pendingSearches,
  s => s.system.authedUser.data?.userId,
  (entities, query, pendingQueries, userId) => {
    const users = Object.values(entities).filter(u => {
      return (
        !!u &&
        u.userId !== userId &&
        (query === "" || u.username.startsWith(query) || u.name.toLowerCase().startsWith(query))
      );
    }) as DeglazeUser[];

    const pending = pendingQueries.some(q => q === query);

    const result: UserSearchResults = {
      users,
      pending,
    };

    return result;
  }
);

export interface EntitySearchResults {
  entities: Array<KnownAuthor | KnownPublisher>;
  loading: boolean;
}

export const useKnownEntities = (query: string, maxCount?: number): EntitySearchResults =>
  useSelector(s => selectKnownEntities(s, query, maxCount));

const selectKnownEntities: (s: RootState, query: string, maxCount?: number) => EntitySearchResults =
  getCreateSelectorWithCacheSize(10)(
    [
      s => s.users.search.entities,
      (_s, query: string, _maxCount?: number) => query,
      (_s, _query: string, maxCount?: number) => maxCount,
    ],
    (entities, query, maxCount) => {
      const loading = !entities.data && entities.status === "loading";
      if (loading) {
        return { loading: true, entities: [] };
      }

      const hasRedirect = (k: KnownAuthor | KnownPublisher): boolean =>
        (k.type === "knownAuthor" && !!k.redirectToPublisherId) ||
        (k.type === "knownPublisher" && !!k.redirectToAuthorId);

      // Filter out entities with redirects to avoid redundency (e.g. Alison Roman + Alison Roman Newsletter)
      const filteredEntities = entities.data?.entities.filter(e => !hasRedirect(e));

      if (query.trim() === "") {
        return { loading: false, entities: filteredEntities?.slice(0, maxCount) ?? [] };
      }

      const q = query.trim().toLowerCase();
      const unsortedResults = filteredEntities?.filter(e => e.name.toLowerCase().includes(q)).slice(0, maxCount) ?? [];
      return { loading: false, entities: unsortedResults };
    }
  );
