import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getLinkForNavigableScreen, useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView, useScreenElementDimensions } from "../components/ScreenView";
import { TBody, THeading2, TSecondary } from "../components/Typography";
import { HouseholdJoinScreenProps, navTree } from "../navigation/NavTree";
import { Spacer } from "../components/Spacer";
import { useDispatch } from "../lib/redux/Redux";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { completeHouseholdLink, getHouseholdInviteInfo } from "../lib/Household";
import { HouseholdInviteInfo, isStructuredUserError } from "@eatbetter/users-shared";
import { Platform, StyleSheet, View } from "react-native";
import { ContainerFadeIn } from "../components/Containers";
import { FlexedSpinner } from "../components/Spinner";
import { useAuthStatus, useIsAnonymousUser } from "../lib/system/SystemSelectors";
import { SignIn } from "../components/SignIn";
import { ButtonRectangle } from "../components/Buttons";
import { referralDataAvailable, UserAuthStatus } from "../lib/system/SystemSlice";
import { getPathAndQuery } from "@eatbetter/common-shared";
import { navHome } from "../navigation/NavThunks";
import { AvatarAndHeading } from "../components/AvatarAndHeading";
import { HeaderProps } from "../components/ScreenHeaders";
import { signOutClicked } from "../lib/system/SystemThunks";
import { reportHouseholdInviteLinkAccepted, reportHouseholdInviteLinkViewed } from "../lib/analytics/AnalyticsEvents";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { ScreenFooter } from "../components/ScreenFooter";
import { globalStyleConstants } from "../components/GlobalStyles";

const strings = {
  headerWeb: "deglaze",
  headerApp: "Join a household",
  title: {
    joinOtherHousehold: (name: string) => `${name} would like you to join their household`,
    otherJoinMyHousehold: (name: string) => `${name} would like to join your household`,
  },
  body: "This will allow you to share your Deglaze recipe library and grocery list. ",
  notSignedInBody: "To continue, choose an option below to sign in or sign up.",
  submitTitle: "Accept",
  goHome: "Take me Home",
  success: "Success! Within a couple minutes, your recipes and grocery lists will be fully synced.",
  defaultError: "Looks like the invitation is invalid.",
  errorHeader: "Uh-oh",
};

export const HouseholdJoinScreen = withScreenContainer(
  "HouseholdJoinScreen",
  (props: HouseholdJoinScreenProps) => {
    const dispatch = useDispatch();
    const authStatus = useAuthStatus();
    const [waiting, setWaiting] = useState(false);
    const [success, setSuccess] = useState(false);
    const [info, setInfo] = useState<HouseholdInviteInfo | undefined>(undefined);
    const [expectedError, setExpectedError] = useState<string | undefined>();
    const screen = useScreen();
    const userIsAnonymous = useIsAnonymousUser();

    useEffect(() => {
      dispatch(getHouseholdInviteInfo(props, setWaiting))
        .then(i => {
          setInfo(i);
          const event = reportHouseholdInviteLinkViewed({
            sharedByUserId: i.user.userId,
            sharedByUsername: i.user.username,
          });
          dispatch(analyticsEvent(event));
        })
        .catch(err => handleError(err, setExpectedError, props, "Error dispatching getHouseholdInviteInfo"));
    }, [props.base64Data, props.hmac, dispatch, authStatus]);

    useEffect(() => {
      // set the referral data so it's attributed correctly
      if (authStatus === "signedOut" && info?.user.userId) {
        dispatch(referralDataAvailable({ referredBy: info?.user.userId }));
      }
    }, [authStatus, info?.user.userId]);

    const redirectPath = useMemo(() => {
      const link = getLinkForNavigableScreen(navTree.get.screens.householdJoin, props);
      return getPathAndQuery(link);
    }, [props]);

    const goHome = useCallback(() => {
      dispatch(navHome(screen.nav, "HouseholdJoinScreen"));
    }, [screen.nav, dispatch]);

    const onSubmit = useCallback(async () => {
      if (!info) {
        return;
      }

      try {
        await dispatch(completeHouseholdLink(props, info.user.userId, setWaiting));
        setSuccess(true);

        const event = reportHouseholdInviteLinkAccepted({
          sharedByUserId: info.user.userId,
          sharedByUsername: info.user.username,
        });
        dispatch(analyticsEvent(event));
      } catch (err) {
        handleError(err, setExpectedError, props, "Error dispatching completeHouseholdLink");
      }
    }, [info, dispatch, props]);

    const onPressSignOut = useCallback(async () => {
      await dispatch(signOutClicked(screen.nav));
    }, [dispatch, screen.nav]);

    return React.createElement<Props>(HouseholdJoinScreenComponent, {
      authStatus,
      info,
      waiting,
      onSubmit,
      onPressSignOut,
      redirectPath,
      goHome,
      success,
      errorMessage: expectedError,
      userIsAnonymous,
    });
  },
  {
    serializer: {
      hmac: s => s,
      base64Data: s => s,
    },
    parser: {
      hmac: s => s,
      base64Data: s => s,
    },
    nameRemapping: {
      base64Data: "d",
      hmac: "h",
    },
  }
);

interface Props {
  authStatus: UserAuthStatus;
  info: HouseholdInviteInfo | undefined;
  redirectPath: string;
  errorMessage: string | undefined;
  waiting: boolean;
  userIsAnonymous: boolean;
  onSubmit: () => Promise<void>;
  onPressSignOut: () => Promise<void>;
  goHome: () => void;
  success: boolean;
}

const HouseholdJoinScreenComponent = (props: Props) => {
  const { headerHeight, bottomTabBarHeight } = useScreenElementDimensions();

  // we show the sign-in control for signedInNoAccount because it handles the redirect to the create account screen when
  // the user is returned from google/apple sign-in.
  const showSignIn =
    (props.authStatus === "signedOut" || props.authStatus === "signedInNoAccount" || props.userIsAnonymous) &&
    !props.errorMessage &&
    !props.success;
  const showSignOut = Platform.OS === "web" && props.authStatus === "signedIn";

  const paddingBottom =
    Platform.OS === "web"
      ? 2 * globalStyleConstants.unitSize
      : Math.max(bottomTabBarHeight, globalStyleConstants.unitSize);

  // SignIn if the user is signed out
  // "Go Home" button if the user has been linked
  // "Accept" button if the user is signed in and the invite is valid
  const actionContent = (
    <View style={{ paddingBottom }}>
      {showSignIn && <SignIn redirectPath={props.redirectPath} allowAnonymousIfAvailable={false} />}
      {!showSignIn && (
        <>
          {props.success && <GoHomeButton goHome={props.goHome} />}
          {!props.success && (
            <View style={{ alignItems: "center" }}>
              <ButtonRectangle
                onPress={props.onSubmit}
                title={strings.submitTitle}
                type="submit"
                waiting={props.waiting}
                disabled={props.waiting || !props.info || !!props.errorMessage}
                size="large"
              />
            </View>
          )}
        </>
      )}
    </View>
  );

  const content = (
    <>
      {!!props.info && (
        <ContainerFadeIn style={{ flex: 1 }}>
          <View style={styles.container}>
            <View>
              <AvatarAndHeading
                avatar={{ size: "avatarLarge", photo: props.info.user.photo }}
                heading={{ text: strings.title[props.info.direction](props.info.user.name), numberOfLines: 3 }}
              />
              <Spacer vertical={1.5} />
              {props.success && <TSecondary>{strings.success}</TSecondary>}
              {!props.success && (
                <TSecondary>
                  {strings.body}
                  {showSignIn ? strings.notSignedInBody : ""}
                </TSecondary>
              )}
            </View>
            <View>{actionContent}</View>
          </View>
        </ContainerFadeIn>
      )}
      {!props.info && <>{props.waiting && <FlexedSpinner />}</>}
    </>
  );

  const header = useMemo<HeaderProps>(() => {
    if (Platform.OS === "web") {
      return {
        type: "default",
        style: "tabRoot",
        title: strings.headerWeb,
        right: showSignOut ? { type: "signOut", onPress: props.onPressSignOut, disabled: props.waiting } : undefined,
      };
    }

    return {
      type: "default",
      style: "default",
      title: strings.headerApp,
      // There isn't necessarily a back button available depending on how the user arrived here, so we need Done.
      // Specifically, if the user is signed out, clicks the join link, signs in, and creates an account, they will
      // end up on this screen with no back button.
      right: {
        type: "done",
        onPress: props.goHome,
      },
    };
  }, [showSignOut, props.onPressSignOut, props.waiting, props.goHome]);

  return (
    <ScreenView header={header}>
      <View style={{ flex: 1, paddingTop: headerHeight + 20 }}>
        {!props.errorMessage && content}
        {!!props.errorMessage && (
          <View style={styles.container}>
            <View>
              <THeading2>{strings.errorHeader}</THeading2>
              <Spacer vertical={1} />
              <TBody>{props.errorMessage}</TBody>
            </View>
            <View>
              <GoHomeButton goHome={props.goHome} />
            </View>
          </View>
        )}
        {Platform.OS === "web" && !showSignIn && <ScreenFooter />}
      </View>
    </ScreenView>
  );
};

const GoHomeButton = (props: { goHome: () => void }) => {
  return (
    <View style={{ alignItems: "center" }}>
      <ButtonRectangle onPress={props.goHome} title={strings.goHome} type="submit" size="large" />
    </View>
  );
};

function handleError(err: any, setExpectedError: (s: string) => void, props: HouseholdJoinScreenProps, opName: string) {
  if (isStructuredUserError(err) && err.data.code === "users/invalidHouseholdJoinData") {
    const message = err.data.userMessage ?? strings.defaultError;
    setExpectedError(message);
  } else {
    displayUnexpectedErrorAndLog(opName, err, { props });
  }
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    justifyContent: "space-between",
  },
});
