import { BookId, ExternalRecipeId } from "@eatbetter/recipes-shared";
import { FlexedSpinner, Spacer, THeading1 } from "@eatbetter/ui-shared";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BookRecipeAdd } from "../components/BookRecipeAdd";
import { AdminScreenView } from "../components/AdminScreenView";
import { useStringParam } from "../lib/ParamUtils";
import { useAdminFetchBook } from "../lib/useAdminFetchBook";
import { AdminBooksScreenIdNav } from "./AdminBooksScreen";
import { AdminRecipesScreenIdNav } from "./AdminRecipesScreen";

const bookIdParam = ":bookId";
export const AdminRecipeAddBookScreenNav = {
  getPath: (id?: string) => {
    return `${AdminBooksScreenIdNav.getPath(id ?? bookIdParam)}/add-recipe`;
  },
};

export const AdminBookAddRecipeScreen = () => {
  const nav = useNavigate();
  const bookId = useStringParam<BookId>(bookIdParam).required();
  const [waiting, setWaiting] = useState(false);

  const book = useAdminFetchBook(bookId, setWaiting);

  const onDone = useCallback(
    (recipeId: ExternalRecipeId) => {
      nav(AdminRecipesScreenIdNav.getPath(recipeId));
    },
    [nav]
  );

  if (!book) {
    return <FlexedSpinner />;
  }

  return (
    <AdminScreenView>
      <THeading1 fontWeight="medium">Add Book Recipe</THeading1>
      <Spacer vertical={2} />
      {waiting && <FlexedSpinner />}
      <BookRecipeAdd book={book} onDone={onDone} />
    </AdminScreenView>
  );
};
