import { switchReturn } from "@eatbetter/common-shared";
import { Alert as RNAlert, AlertButton as RNAlertButton } from "react-native";
import { AlertStrings, AlertInterface, AlertButton } from "./AlertInterfaces";

export const Alert: AlertInterface = {
  alert(title: string, message: string, buttons: AlertButton[]) {
    const rnAlertButtons: RNAlertButton[] = buttons.map(i => {
      const button = switchReturn<AlertButton["type"], RNAlertButton>(i.type, {
        cancel: {
          text: i.text ?? AlertStrings.cancel,
          style: "cancel",
          onPress: i.onPress,
        },
        delete: {
          text: i.text ?? AlertStrings.delete,
          style: "destructive",
          onPress: i.onPress,
        },
        discard: {
          text: i.text ?? AlertStrings.discard,
          style: "destructive",
          onPress: i.onPress,
        },
        save: {
          text: i.text ?? AlertStrings.save,
          style: "default",
          onPress: i.onPress,
        },
      });

      return button;
    });

    RNAlert.alert(title, message, rnAlertButtons);
  },
};
