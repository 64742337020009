import { useCallback } from "react";
import { useBottomSheet } from "../../screens/BottomSheetScreen";
import { useDispatch } from "../../lib/redux/Redux";
import { analyticsEvent } from "../../lib/analytics/AnalyticsThunks";
import {
  reportLibraryRecipeContextMenuItemTapped,
  reportPostContextMenuItemTapped,
} from "../../lib/analytics/AnalyticsEvents";
import { UserRecipeId } from "@eatbetter/recipes-shared";
import { displayUnexpectedErrorAndLog } from "../../lib/Errors";
import { NavApi } from "../../navigation/ScreenContainer";
import { navTree } from "../../navigation/NavTree";
import { OptionsMenuItem } from "../OptionsMenu";
import { bottomThrow } from "@eatbetter/common-shared";

const strings = {
  reportIssue: "Report Content Issue",
};

export function useReportContentIssueMenuItem(
  context: "post" | "recipeDetail",
  userRecipeId: UserRecipeId | undefined,
  nav: NavApi,
  isFirst: boolean
) {
  const dispatch = useDispatch();
  const bottomSheet = useBottomSheet();

  const onPressReportPost = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();

    switch (context) {
      case "post": {
        dispatch(analyticsEvent(reportPostContextMenuItemTapped({ action: "reportContent" })));
        break;
      }
      case "recipeDetail": {
        dispatch(analyticsEvent(reportLibraryRecipeContextMenuItemTapped({ action: "reportUserContentIssue" })));
        break;
      }
      default:
        bottomThrow(context);
    }
    setTimeout(() => {
      if (!userRecipeId) {
        displayUnexpectedErrorAndLog("onPressReportPost called but props.userRecipeId is falsy", {});
        return;
      }
      nav.goTo("push", navTree.get.screens.reportUserRecipeContentIssue, { recipeId: userRecipeId }), 300;
    });
  }, [nav.goTo, userRecipeId, bottomSheet?.closeSheetAndGoBack]);

  const menuItem = (
    <OptionsMenuItem icon="flag" text={strings.reportIssue} onPress={onPressReportPost} isFirst={isFirst} />
  );

  return menuItem;
}
