import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { signOutClicked } from "../lib/system/SystemThunks";
import { globalStyleColors } from "../components/GlobalStyles";
import { View, StyleSheet, Platform } from "react-native";
import { useDispatch } from "../lib/redux/Redux";
import { THeading1, TSecondary } from "../components/Typography";
import { Spacer } from "../components/Spacer";
import React from "react";
import { ScreenFooter } from "../components/ScreenFooter";

const strings = {
  deglaze: "deglaze",
  onTheList: "You're on the waitlist!",
  explanation:
    "We've been growing fast, and to make sure we can offer the absolute best-in-class experience and support, we have set up a queue to get access to Deglaze.\n\n" +
    "We sincerely hope it's worth the wait and we'll let you know when your account is ready!",
};

export const WaitlistScreen = withNonNavigableScreenContainer("WaitlistScreen", () => {
  const dispatch = useDispatch();
  const screen = useScreen();

  return (
    <ScreenView
      header={{
        type: "default",
        title: "",
        right: { type: "signOut", onPress: async () => dispatch(signOutClicked(screen.nav)) },
        backgroundColor: "transparent",
      }}
      scrollView={false}
      paddingHorizontal={false}
      paddingVertical={false}
    >
      <View style={styles.container}>
        <THeading1 font="serif" align="center" color={globalStyleColors.blackSoft}>
          {strings.deglaze}
        </THeading1>
        <Spacer vertical={2} />
        <THeading1 align="center">{strings.onTheList}</THeading1>
        <Spacer vertical={2} />
        <TSecondary align="center">{strings.explanation}</TSecondary>
      </View>
      {Platform.OS === "web" && <Footer />}
    </ScreenView>
  );
});

const Footer = React.memo(() => {
  return <View style={styles.footer}>{Platform.OS === "web" && <ScreenFooter />}</View>;
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 20,
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
});
