import { useBottomTabBarDimensions } from "../../navigation/TabBar.tsx";
import { Animated, StyleSheet, View } from "react-native";
import { usePulseAnimation } from "../AttentionGrabbers.tsx";
import { useKeyboardLayoutAnimation } from "../Keyboard.tsx";
import { recipeStatusBannerStyles } from "./RecipeStatusBanner.tsx";
import { isUrl } from "@eatbetter/common-shared";
import { useCallback, useMemo, useState } from "react";
import { globalStyleColors, globalStyleConstants } from "../GlobalStyles.ts";
import Clipboard from "@react-native-clipboard/clipboard";
import { Spacer } from "../Spacer.tsx";
import { IconHelp } from "../Icons.tsx";
import { Pressable } from "../Pressable.tsx";
import { TBody } from "../Typography.tsx";
import { Alert } from "../Alert/Alert.ts";
import { useScreen } from "../../navigation/ScreenContainer.tsx";
import { navTree, WebViewSignInHelpScreenProps } from "../../navigation/NavTree.ts";
import { AlertButton } from "../Alert/AlertInterfaces.ts";
import { webViewNavRequested } from "../../lib/webview/WebViewSlice.ts";
import { useDispatch } from "../../lib/redux/Redux.ts";
import { reportWebViewSignInLinkPasted } from "../../lib/analytics/AnalyticsEvents.ts";
import { analyticsEvent } from "../../lib/analytics/AnalyticsThunks.ts";

const strings = {
  okay: "Okay",
  help: "Help",
  placeholder: "Paste link from email here",
  alertTitle: "No URL found",
  alertBody: "Copy the URL from the email and try again.",
};

export const WebViewSignInHelp = (props: WebViewSignInHelpScreenProps) => {
  const { bottomTabBarHeight } = useBottomTabBarDimensions();
  const animation = usePulseAnimation({ iterations: 1, pulseSize: "small", delay: 500 });
  const { nav } = useScreen();

  const onHelp = useCallback(() => {
    nav.modal(navTree.get.screens.webViewSignInHelp, props);
  }, [nav]);

  useKeyboardLayoutAnimation(undefined, animation.startAnimation);

  return (
    <View style={[styles.container, { bottom: bottomTabBarHeight }]}>
      <Animated.View style={animation.animatedStyle}>
        <View style={styles.innerContainer}>
          <WebViewSignInHelpPasteTarget {...props} onHelp={onHelp} />
          <Spacer horizontal={1} />
          <Pressable onPress={onHelp}>
            <IconHelp />
          </Pressable>
        </View>
      </Animated.View>
    </View>
  );
};

export const WebViewSignInHelpPasteTarget = (
  props: WebViewSignInHelpScreenProps & { onHelp?: () => void; onAfterNav?: () => void }
) => {
  const dispatch = useDispatch();
  const [text, setText] = useState("");

  // if this control is rendered in the help screen, onHelp will be undefined and we only want an okay button
  const buttons: [AlertButton, ...AlertButton[]] = useMemo(() => {
    const b: AlertButton[] = [
      {
        type: "save",
        text: strings.okay,
        onPress: () => {},
      },
    ];

    if (props.onHelp) {
      b.unshift({
        type: "save",
        text: strings.help,
        onPress: props.onHelp,
      });
    }

    return b as [AlertButton, ...AlertButton[]];
  }, [props.onHelp]);

  const onPressPasteTarget = useCallback(async () => {
    const hasUrl = await Clipboard.hasURL();
    if (!hasUrl) {
      Alert.alert(strings.alertTitle, strings.alertBody, buttons);
    } else {
      const str = await Clipboard.getString();
      if (isUrl(str)) {
        setText(str);
        // if onHelp is passed, it's the webview, as we don't display a help icon on the help screen
        const event = reportWebViewSignInLinkPasted(str, props.domain, props.onHelp ? "WebView" : "HelpScreen");
        dispatch(analyticsEvent(event));
        dispatch(webViewNavRequested({ sessionId: props.sessionId, url: str }));
        props.onAfterNav?.();
      }
    }
  }, [setText, buttons, props.onAfterNav]);
  return (
    <View style={styles.pasteTarget}>
      <Pressable onPress={onPressPasteTarget} noFeedback>
        {!text && <TBody opacity="light">{strings.placeholder}</TBody>}
        {!!text && <TBody opacity="dark">{text}</TBody>}
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    ...recipeStatusBannerStyles.container,
    paddingHorizontal: globalStyleConstants.unitSize * 2,
    backgroundColor: undefined,
  },
  innerContainer: {
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
    width: "100%",
    paddingHorizontal: globalStyleConstants.unitSize,
  },
  pasteTarget: {
    width: "100%",
    height: 48,
    backgroundColor: "white",
    borderRadius: 24,
    paddingHorizontal: 1.5 * globalStyleConstants.unitSize,
    borderWidth: 1,
    borderColor: globalStyleColors.rgba("blackSoft", "light"),
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
  },
});
