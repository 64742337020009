import { Image, ImageSourcePropType, StyleSheet, View } from "react-native";
import { globalStyleColors, globalStyleConstants, globalStyles, Opacity } from "../components/GlobalStyles";
import { ScreenView, useScreenElementDimensions } from "../components/ScreenView";
import { InKitchenFirstRunProps } from "../navigation/NavTree";
import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import {
  defaultScreenAspectRatio,
  maxContentWidth,
  smallScreenBreakpoint,
  useResponsiveDimensions,
} from "../components/Responsive";
import { TabView, TabViewRoute } from "../components/TabView";
import { useDispatch } from "../lib/redux/Redux";
import { useCallback, useEffect, useState } from "react";
import { SlideTimeTrackerCompletedHandler, useSlideTimeTracker } from "../lib/util/UseSlideTimeTracker";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { Haptics } from "../components/Haptics";
import { BottomActionBar, bottomActionBarConstants } from "../components/BottomActionBar";
import React from "react";
import { useBottomTabBarDimensions } from "../navigation/TabBar";
import { TBody, TextProps, THeading1 } from "../components/Typography";
import { bottomThrow } from "@eatbetter/common-shared";
import { useScreenHeaderDimensions } from "../components/ScreenHeaders";
import { Pressable } from "../components/Pressable";
import { IconChevronLeft, IconEx } from "../components/Icons";
import { TabViewProgress } from "../components/TabViewProgress";
import { Spacer } from "../components/Spacer";
import { Video } from "../components/Video";
import {
  reportInKitchenFirstRunCarouselCanceled,
  reportInKitchenFirstRunCarouselCompleted,
  reportInKitchenFirstRunCarouselStarted,
} from "../lib/analytics/AnalyticsEvents";

const strings = {
  intro: {
    headline: "Now you're cooking",
    subheadline: "Deglaze Cooking Sessions make\nyour life easier in the kitchen",
  },
  checklists: {
    headline: "Keep track of your prep",
    subheadline: "Check off ingredients as you go.\nYour screen stays on while you cook.",
  },
  timers: {
    headline: "Start timers with one tap",
    subheadline: "Tap on highlighted times in the recipe instructions to start timers",
  },
  multipleRecipe: {
    headline: "Cook multiple recipes at once",
    subheadline: 'Tap "Start Cooking" on 2 or more recipes, then switch between them with one tap.',
  },
  next: "Next",
  done: "Let's Go!",
};

// Assets
const intro = require("../assets/in_kitchen_first_run/in_kitchen_intro.png");
const checklistVideo = require("../assets/in_kitchen_first_run/checklists_demo.mp4");
const timersVideo = require("../assets/in_kitchen_first_run/timers_demo.mp4");
const multipleRecipesVideo = require("../assets/in_kitchen_first_run/multiple_recipes_demo.mp4");

const constants = {
  introPhotoAspectRatio: 2556 / 1179,
  videoDemoAspectRatio: 1,
};

const routeKeys = ["intro", "checklists", "timers", "multipleRecipes"] as const;
type RouteKey = (typeof routeKeys)[number];
const isRouteKey = (str: string): str is RouteKey => routeKeys.some(i => i === str);

const routes: TabViewRoute<RouteKey>[] = [
  { key: "intro", title: "Intro" },
  { key: "checklists", title: "Checklists" },
  { key: "timers", title: "Timers" },
  { key: "multipleRecipes", title: "Multiple Recipes" },
];

function getVideoAnalyticsId(routeKey: RouteKey) {
  return `in_kitchen_first_run_${routeKey}`;
}

export const InKitchenFirstRunScreen = withNonNavigableScreenContainer<InKitchenFirstRunProps>(
  "InKitchenFirstRunScreen",
  props => {
    const dispatch = useDispatch();
    const screen = useScreen();

    const [tabIndex, setTabIndex] = useState(0);

    const onSlideTimeTrackerCompleted = useCallback<SlideTimeTrackerCompletedHandler>(
      results => {
        dispatch(
          analyticsEvent(
            reportInKitchenFirstRunCarouselCompleted({
              durations: results.durations,
              backActions: results.backActions,
            })
          )
        );
      },
      [dispatch]
    );

    const completeSlideTimeTracker = useSlideTimeTracker(tabIndex, onSlideTimeTrackerCompleted);

    useEffect(() => {
      dispatch(analyticsEvent(reportInKitchenFirstRunCarouselStarted()));
    }, []);

    const onCancel = useCallback(() => {
      dispatch(analyticsEvent(reportInKitchenFirstRunCarouselCanceled()));
      completeSlideTimeTracker();
      screen.nav.goBack();
    }, [dispatch, completeSlideTimeTracker, screen.nav.goBack]);

    const onBack = useCallback(() => {
      setTabIndex(prev => Math.max(0, prev - 1));
    }, [setTabIndex]);

    const onNext = useCallback(() => {
      Haptics.feedback("itemStatusChanged");
      if (tabIndex >= routes.length - 1) {
        completeSlideTimeTracker();
        props.onComplete();
        screen.nav.goBack();
        return;
      }
      setTabIndex(prev => prev + 1);
    }, [tabIndex, setTabIndex, completeSlideTimeTracker, props.onComplete, screen.nav.goBack]);

    const renderRoute = useCallback(
      (routeProps: { route: TabViewRoute }) => {
        const routeKey = routeProps.route.key;
        if (!isRouteKey(routeKey)) {
          throw new Error("Unexpected route key: " + routeKey);
        }

        return <CarouselRoute type={routeKey} />;
      },
      [tabIndex]
    );

    return (
      <ScreenView scrollView={false} paddingHorizontal={false} paddingVertical={"none"} backgroundColor="white" isModal>
        <Header
          hideCancelButton
          tabIndex={tabIndex}
          onPressCancel={onCancel}
          onPressBack={onBack}
          style={tabIndex === 0 ? "dark" : "light"}
        />
        <TabView
          routes={routes}
          index={tabIndex}
          onIndexChange={setTabIndex}
          tabBar={"none"}
          renderRoute={renderRoute}
        />
        <Progress routeCount={routes.length} tabIndex={tabIndex} style={tabIndex === 0 ? "dark" : "light"} />
        <BottomActionBar
          primaryAction={{
            actionText: tabIndex === routes.length - 1 ? strings.done : strings.next,
            onPressAction: onNext,
          }}
          containerBackgroundColor="transparent"
        />
      </ScreenView>
    );
  }
);

const textShadow = { color: globalStyleColors.rgba("black", "medium"), offset: { width: 0, height: 1 }, radius: 2 };

const CarouselRoute = React.memo((props: { type: RouteKey }) => {
  switch (props.type) {
    case "intro": {
      return <IntroRoute />;
    }
    case "checklists": {
      return (
        <VideoDemoRoute
          headline={strings.checklists.headline}
          subheadline={strings.checklists.subheadline}
          videoAnalyticsId={getVideoAnalyticsId(props.type)}
          videoSource={checklistVideo}
        />
      );
    }
    case "timers": {
      return (
        <VideoDemoRoute
          headline={strings.timers.headline}
          subheadline={strings.timers.subheadline}
          videoAnalyticsId={getVideoAnalyticsId(props.type)}
          videoSource={timersVideo}
        />
      );
    }
    case "multipleRecipes": {
      return (
        <VideoDemoRoute
          headline={strings.multipleRecipe.headline}
          subheadline={strings.multipleRecipe.subheadline}
          videoAnalyticsId={getVideoAnalyticsId(props.type)}
          videoSource={multipleRecipesVideo}
        />
      );
    }
    default:
      bottomThrow(props.type);
  }
});

const IntroRoute = React.memo(() => {
  const { bottomTabBarHeight } = useScreenElementDimensions();
  const progressBarTop = getProgressBarTop(bottomTabBarHeight);

  const dimensions = useResponsiveDimensions();
  const fontScale = dimensions.isSmallScreen ? 1.2 : 1.8;
  const fontScaleProps: Partial<TextProps> = {
    enableFontScaling: "upOnly",
    scale: fontScale,
    adjustsFontSizeToFit: true,
  };

  return (
    <View style={{ flex: 1 }}>
      <IntroPhoto source={intro} />
      <View
        style={{
          position: "absolute",
          bottom: progressBarTop + 5 * globalStyleConstants.unitSize,
          minWidth: "80%",
          maxWidth: maxContentWidth,
          alignSelf: "center",
          paddingHorizontal: globalStyleConstants.defaultPadding,
        }}
      >
        <View
          style={{
            width: "100%",
            alignSelf: "center",
            paddingHorizontal: globalStyleConstants.defaultPadding,
            maxWidth: Math.min(dimensions.width, smallScreenBreakpoint),
          }}
        >
          <THeading1
            {...fontScaleProps}
            fontWeight="medium"
            align="center"
            color="white"
            textShadow={textShadow}
            numberOfLines={1}
          >
            {strings.intro.headline}
          </THeading1>
          <Spacer vertical={0.5} />
          <TBody {...fontScaleProps} color="white" textShadow={textShadow} align="center" numberOfLines={2}>
            {strings.intro.subheadline}
          </TBody>
        </View>
      </View>
    </View>
  );
});

const VideoDemoRoute = React.memo(
  (props: { headline: string; subheadline: string; videoAnalyticsId: string; videoSource: number }) => {
    const { statusBarHeight } = useScreenElementDimensions();
    const progressBarTop = getProgressBarTop(statusBarHeight);

    const dimensions = useResponsiveDimensions();
    const fontScale = dimensions.isSmallScreen ? 1.2 : 1.8;
    const fontScaleProps: Partial<TextProps> = {
      enableFontScaling: "upOnly",
      scale: fontScale,
      adjustsFontSizeToFit: true,
    };

    return (
      <View
        style={{
          flex: 1,
          paddingTop: getHeaderHeight(statusBarHeight) + globalStyleConstants.defaultPadding,
          paddingBottom: progressBarTop,
          paddingHorizontal: globalStyleConstants.defaultPadding,
          justifyContent: "space-between",
        }}
      >
        <View
          style={{
            height: "60%",
            minHeight: 300,
            alignItems: "center",
            ...globalStyles.shadowItem,
            shadowColor: globalStyleColors.colorAccentCool,
            shadowOpacity: Opacity.light,
          }}
        >
          <View
            style={{
              aspectRatio: constants.videoDemoAspectRatio,
              borderRadius: globalStyleConstants.defaultBorderRadius,
              overflow: "hidden",
            }}
          >
            <Video
              videoAnalyticsId={props.videoAnalyticsId}
              videoSource={{ type: "bundled", nodeRequireOutput: props.videoSource }}
              autoPlay
              isLooping
              playbackMode={{ type: "contained" }}
              resizeMode="cover"
              hidePlayButton
              backgroundColor="transparent"
              spinnerColor="dark"
              tapToExpand={false}
            />
          </View>
        </View>
        <View
          style={{
            height: "30%",
            minHeight: 148,
            width: "100%",
            maxWidth: smallScreenBreakpoint,
            alignSelf: "center",
            justifyContent: "center",
            paddingVertical: globalStyleConstants.unitSize,
          }}
        >
          <THeading1 {...fontScaleProps} numberOfLines={1} align="center" fontWeight="medium">
            {props.headline}
          </THeading1>
          <Spacer vertical={0.5} />
          <TBody {...fontScaleProps} numberOfLines={2} align="center">
            {props.subheadline}
          </TBody>
        </View>
      </View>
    );
  }
);

const getHeaderHeight = (statusBarHeight: number) => {
  return statusBarHeight + 18 + 24 + 18;
};

const Header = React.memo(
  (props: {
    tabIndex: number;
    onPressBack: () => void;
    onPressCancel: () => void;
    style: "light" | "dark";
    hideCancelButton?: boolean;
  }) => {
    const { statusBarHeight } = useScreenHeaderDimensions();

    return (
      <View
        style={[
          styles.header,
          {
            paddingTop: statusBarHeight + 18,
            height: getHeaderHeight(statusBarHeight),
            justifyContent: props.tabIndex === 0 ? "flex-end" : "space-between",
          },
        ]}
      >
        {props.tabIndex > 0 && <BackButton onPress={props.onPressBack} style={props.style} />}
        {props.hideCancelButton !== true && <CancelButton onPress={props.onPressCancel} style={props.style} />}
      </View>
    );
  }
);

const CancelButton = React.memo((props: { onPress: () => void; style: "light" | "dark" }) => {
  return (
    <Pressable noFeedback onPress={props.onPress}>
      <IconEx opacity="opaque" color={props.style === "dark" ? "white" : "black"} strokeWidth={2} size={24} />
    </Pressable>
  );
});

const BackButton = React.memo((props: { onPress: () => void; style: "light" | "dark" }) => {
  return (
    <Pressable noFeedback onPress={props.onPress}>
      <IconChevronLeft opacity="opaque" color={props.style === "dark" ? "white" : "black"} size={28} />
    </Pressable>
  );
});

const getProgressBarTop = (bottomTabBarHeight: number) => {
  return bottomTabBarHeight + bottomActionBarConstants.height + 6 + 24;
};

const Progress = React.memo((props: { tabIndex: number; routeCount: number; style: "light" | "dark" }) => {
  const { bottomTabBarHeight } = useBottomTabBarDimensions();

  return (
    <View
      style={[
        styles.progress,
        {
          bottom: bottomTabBarHeight + bottomActionBarConstants.height + 6,
          opacity: props.style === "dark" ? Opacity.light : undefined,
        },
      ]}
    >
      <TabViewProgress
        count={props.routeCount}
        currentIndex={props.tabIndex}
        style={props.style === "dark" ? "overlay" : "default"}
      />
    </View>
  );
});

const IntroPhoto = (props: { source: ImageSourcePropType }) => {
  const { width: screenWidth, screenAspectRatio } = useResponsiveDimensions();

  const bgAspectRatio = (constants.introPhotoAspectRatio / defaultScreenAspectRatio) * screenAspectRatio;
  const width = screenWidth;
  const height = width * bgAspectRatio;

  return (
    <View>
      <Image resizeMode="cover" source={props.source} style={{ width, height }} />
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    position: "absolute",
    width: "100%",
    alignSelf: "center",
    flexDirection: "row",
    paddingHorizontal: globalStyleConstants.defaultPadding,
    maxWidth: maxContentWidth,
    zIndex: 100,
  },
  progress: {
    position: "absolute",
    alignSelf: "center",
  },
});
