import { SyncThunkAction } from "../redux/Redux";
import { log } from "../../Log";
import { processAnalyticsEvent, processAttributionEvent } from "./Analytics";
import { AnalyticsEventAndProperties } from "@eatbetter/composite-shared";

export const analyticsEvent = (event: AnalyticsEventAndProperties): SyncThunkAction<void> => {
  return (_dispatch, getState, deps) => {
    if (getState().system.impersonateUser) {
      // don't record actions when impersonating a user
      return;
    }

    if (deps.mixpanel) {
      try {
        processAnalyticsEvent(deps.mixpanel, event);
      } catch (err) {
        log.errorCaught("Error processing analytics event", err, { event });
      }
    }

    if (event.attributionEvents) {
      try {
        processAttributionEvent(deps.firebaseAnalytics, deps.appsFlyer, event.attributionEvents);
      } catch (err) {
        log.errorCaught("Error processing attribution event", err, { event });
      }
    }
  };
};
