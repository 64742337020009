import { ClientInfo, requestHeaderNames } from "@eatbetter/common-shared";

export function getAppMetaHeaders(info: ClientInfo) {
  const h: Record<string, string> = {
    [requestHeaderNames.devBuildHeader]: info.devBuild ? "1" : "0",
  };

  if (info.platform) {
    h[requestHeaderNames.platformHeader] = info.platform;
  }

  if (info.gitSha) {
    h[requestHeaderNames.gitShaHeader] = info.gitSha;
  }

  if (info.appVersion) {
    h[requestHeaderNames.appVersionHeader] = info.appVersion;
  }

  if (info.deviceId) {
    h[requestHeaderNames.deviceIdHeader] = info.deviceId;
  }

  if (info.deviceType) {
    h[requestHeaderNames.deviceTypeHeader] = info.deviceType;
  }

  if (info.deviceOsVersion) {
    h[requestHeaderNames.deviceOsVesionHeader] = info.deviceOsVersion;
  }

  return h;
}
