import React from "react";
import { StyleSheet, View } from "react-native";
import { TTertiary } from "../Typography";
import { Spacer } from "../Spacer";
import { IconCookPotOpen } from "../Icons";
import { globalStyleColors } from "../GlobalStyles";

interface Props {
  cookCount: number;
}

export const RecipeCookCount = React.memo((props: Props) => {
  return (
    <View style={styles.cookCount}>
      <IconCookPotOpen opacity="opaque" size={16} color={globalStyleColors.colorGreyDark} />
      <Spacer horizontal={0.5} />
      <TTertiary opacity={"medium"} color={globalStyleColors.black}>
        {props.cookCount}
      </TTertiary>
    </View>
  );
});

const styles = StyleSheet.create({
  cookCount: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
});
