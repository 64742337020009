import { StyleSheet, View } from "react-native";
import { TBody } from "./Typography";
import { Pressable } from "./Pressable";
import { IconChevronRight } from "./Icons";
import { ContainerEnd } from "./Containers";
import { globalStyleColors } from "./GlobalStyles";
import { Spinner } from "./Spinner";

export const SettingsItem = (props: {
  title: string;
  onPress: () => void;

  /**
   * If the item navigates, a right chevron will be displayed.
   * If not, the text will display as Action Text (different color)
   */
  navAction?: boolean;

  /**
   * Styles the item as a destructive
   */
  destructive?: boolean;

  waiting?: boolean;
}) => {
  const textColor = props.destructive
    ? globalStyleColors.colorAccentWarm
    : props.navAction
    ? undefined
    : globalStyleColors.colorTextLink;

  return (
    <Pressable singlePress onPress={props.onPress} disabled={props.waiting}>
      <View style={styles.container}>
        <TBody color={textColor}>{props.title}</TBody>
        {!props.waiting && props.navAction && (
          <ContainerEnd horizontal>
            <IconChevronRight />
          </ContainerEnd>
        )}
        {props.waiting && (
          <ContainerEnd horizontal>
            <Spinner />
          </ContainerEnd>
        )}
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 60,
    alignItems: "center",
    flexDirection: "row",
  },
});
