import { useEffect } from "react";
import { Platform, View } from "react-native";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { navTree } from "../navigation/NavTree";
import { useDispatch, useSelector } from "../lib/redux/Redux";
import { ScreenView } from "../components/ScreenView";
import { log } from "../Log";
import { navHome } from "../navigation/NavThunks";
import { bottomThrow } from "@eatbetter/common-shared";
import { useAppSignInLinkForWeb, useLaunchCarouselCompleted } from "../lib/system/SystemSelectors";

export const LaunchScreen = withScreenContainer(
  "LaunchScreen",
  () => {
    const screen = useScreen();
    const authStatus = useSelector(s => s.system?.authStatus);
    const carouselCompleted = useLaunchCarouselCompleted();
    const dispatch = useDispatch();
    const appSignInLinkForWeb = useAppSignInLinkForWeb();

    // When the app is launched and we already have an auth status, this handles the navigation.
    // If the auth status resolves after (because the user signs in), a useEffect in ScreenContainer
    // handles the navigation.
    // Note that we disable auth status redirects in the container with the options below
    // IMPORTANT: Some of this is duplicated in SignIn.tsx and in ScreenContainer.tsx.
    // Logic changes here might need to be ported there (or refactor into a function).
    useEffect(() => {
      // We experienced an issue where unversal links were not working for some people. The end result
      // of this is that they opened web and had no recourse. We now detect these links and show a help page,
      // including a link with the custom app protocol that will always open the app if it's installed
      if (Platform.OS === "web" && appSignInLinkForWeb) {
        screen.nav.goTo("reset", navTree.get.screens.appEmailLinkSignInHelp);
        return;
      }

      switch (authStatus) {
        case "signedIn":
        case "signedInNoAccount":
          dispatch(navHome(screen.nav, "LaunchScreen"));
          break;
        case "signedOut":
        case "signingOut": {
          // carousel is only supported on devices
          if (Platform.OS !== "web") {
            log.info("Navigating to launch carousel from LaunchScreen");
            screen.nav.goTo("reset", navTree.get.screens.launchCarousel);
          } else {
            log.info("Navigating to signInScreen from LaunchScreen");
            screen.nav.goTo("reset", navTree.get.screens.signIn);
          }

          break;
        }
        case "pending":
          //noop
          break;
        default:
          bottomThrow(authStatus);
      }
    }, [authStatus, carouselCompleted, appSignInLinkForWeb]);

    return (
      <ScreenView>
        <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }} />
      </ScreenView>
    );
  },
  undefined
);
