import { useEffect, useState } from "react";
import { useDispatch } from "./AdminRedux";
import { displayUnexpectedErrorAndLogHandler, SetWaitingHandler } from "@eatbetter/ui-shared";
import { getTagManifestOrThrow } from "./recipes/AdminRecipesThunks";
import { RecipeTagManifest } from "@eatbetter/recipes-shared";

export function useAdminTagManifest(setWaiting?: SetWaitingHandler) {
  const dispatch = useDispatch();
  const [tagManifest, setTagManifest] = useState<RecipeTagManifest | undefined>(undefined);

  useEffect(() => {
    dispatch(getTagManifestOrThrow(setWaiting))
      .then(setTagManifest)
      .catch(displayUnexpectedErrorAndLogHandler("Error fetching tagManifest in useAdminTagManifest"));
  }, []);

  return tagManifest;
}
