export const TableFromObject = (props: { object: object }) => {
  return (
    <table>
      <tbody>
        {Object.entries(props.object).map(e => {
          const [key, value] = e;
          return (
            <tr key={key}>
              <td style={{ width: 1, paddingRight: 24 }}>{key}</td>
              <td>{`${value}`}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
