import { is2ndLevelDomainMatch, switchReturn, UrlString } from "@eatbetter/common-shared";
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { Animated, Platform, StyleSheet, View, ViewStyle } from "react-native";
import { WebView as RNWebView, WebViewNavigation, WebViewProps as RNWebViewProps } from "react-native-webview";
import {
  ShouldStartLoadRequest,
  WebViewErrorEvent,
  WebViewHttpErrorEvent,
  WebViewMessageEvent,
  WebViewProgressEvent,
} from "react-native-webview/lib/WebViewTypes";
import { log } from "../Log";
import { globalStyleColors, globalStyles, Opacity } from "./GlobalStyles";
import { IconChevronLeft, IconChevronRight, IconReload } from "./Icons";
import { Pressable } from "./Pressable";
import { useResponsiveDimensions } from "./Responsive";
import { useScreenElementDimensions } from "./ScreenView";
import { Spacer } from "./Spacer";
import { useDispatch } from "../lib/redux/Redux";
import {
  webViewErrored,
  webViewLoadingProgressUpdated,
  webViewNavigationStateChanged,
  WebViewSessionId,
} from "../lib/webview/WebViewSlice";
import {
  useWebViewCanGoBack,
  useWebViewCanGoForward,
  useWebViewInitialUrl,
  useWebViewIsNavigated,
  useWebViewLoadingProgress,
  useWebViewRequestedUrl,
} from "../lib/webview/WebViewSelectors";
import { useAppMetadata } from "../lib/system/SystemSelectors.ts";

const config = {
  // this is a user agent from ios safari. Google sign-in doesn't work with the default user agent
  userAgent:
    "Mozilla/5.0 (iPhone; CPU iPhone OS 18_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/18.1.1 Mobile/15E148 Safari/604.1",
};

export const webViewConstants = {
  webNavBarHeight: 40,
  progressBarHeight: 2,
};

export type WebViewScrollEventHandler = RNWebViewProps["onScroll"];
export type WebViewNavigationStateChangeHandler = (e: WebViewNavigation) => void;
export type WebViewMessageEventHandler = (e: WebViewMessageEvent) => void;
type WebViewLoadingProgressHandler = (e: WebViewProgressEvent) => void;
type WebViewErrorHandler = (e: WebViewErrorEvent) => void;
type WebViewHttpErrorHandler = (e: WebViewHttpErrorEvent) => void;

interface Props {
  sessionId: WebViewSessionId | undefined;
  contentInsets?: { top?: number; bottom?: number };
  padding?: { top?: number; bottom?: number };
  onScroll?: WebViewScrollEventHandler;
  onNavigationStateChange?: WebViewNavigationStateChangeHandler;
  onMessage?: WebViewMessageEventHandler;
  onError?: WebViewErrorHandler;
  onHttpError?: WebViewHttpErrorHandler;
  injectedJavaScript?: string;
}

export interface WebViewImperativeHandle {
  goForward: () => void;
  goBack: () => void;
  reload: () => void;
  stopLoading: () => void;
  injectJavascript: (script: string) => void;
}

export const WebView = React.forwardRef<WebViewImperativeHandle, Props>((props, ref) => {
  const dispatch = useDispatch();
  const initialUrl = useWebViewInitialUrl(props.sessionId);
  const [sourceUrl, setSourceUrl] = useState(initialUrl);
  const requestedUrl = useWebViewRequestedUrl(props.sessionId);
  const urls = useRef<string[]>([]);

  // For domains with an app (NY Times and Substack currently), we have some logic in onShouldStartLoadWithRequest
  // to prevent redirects from opening the app.
  const appRedirectHosts = useAppMetadata()?.appRedirectHosts;
  const stopAppRedirect = useRef(false);

  useEffect(() => {
    if (props.sessionId) {
      // set the url to the requested url if we have it, and the initial url otherwise
      // note that we don't ever clear requested URL, and we can't without changing this logic. Otherwise
      // we'd nav back to the original url.
      const url = requestedUrl ?? initialUrl;
      if (url) {
        stopAppRedirect.current = !!appRedirectHosts?.some(d => is2ndLevelDomainMatch(d, url));
        setSourceUrl(url);
      }
    }
  }, [props.sessionId, initialUrl, requestedUrl, setSourceUrl, appRedirectHosts, stopAppRedirect]);

  const onNavigationStateChange = useCallback<WebViewNavigationStateChangeHandler>(
    e => {
      if (!props.sessionId) {
        log.error("onNavigationStateChange: sessionId is undefined");
        return;
      }

      // This in theory should never happen, but Saveur does a redirect at the beginning to the same URL that sets canGoBack to tru,
      // and so the web nav bar shows even though the user has taken no action. We correct for that here, and it should be a no-op
      // in every other case because if you're back to where you started, we don't need to show the nav bar unless you took a loop to
      // get there and want to go back, which should be very rare.
      const canGoBack = initialUrl === e.url ? false : e.canGoBack;

      // track the last few urls. We use this to determine things like if we just came from a
      // cloudfront url.
      urls.current.push(e.url);
      if (urls.current.length > 10) {
        urls.current.shift();
      }

      dispatch(
        webViewNavigationStateChanged({
          sessionId: props.sessionId,
          url: e.url as UrlString,
          canGoBack,
          canGoForward: e.canGoForward,
        })
      );
      props.onNavigationStateChange?.(e);
    },
    [dispatch, initialUrl, props.sessionId, props.onNavigationStateChange, urls]
  );

  const onLoadProgress = useCallback<WebViewLoadingProgressHandler>(
    ({ nativeEvent: e }) => {
      if (!props.sessionId) {
        log.error("onLoadProgress: sessionId is undefined");
        return;
      }

      dispatch(webViewLoadingProgressUpdated({ sessionId: props.sessionId, loadingProgress: e.progress }));
    },
    [props.sessionId]
  );

  const onError = useCallback<WebViewErrorHandler>(
    ({ nativeEvent: e }) => {
      log.error("Webview onError invoked", {
        loading: e.loading,
        errorCode: e.code,
        errorDescription: e.description,
        errorDomain: e.domain,
        url: e.url,
        title: e.title,
      });

      if (!props.sessionId) {
        log.error("onError: sessionId is undefined");
        return;
      }

      dispatch(webViewErrored({ sessionId: props.sessionId }));
    },
    [dispatch, props.sessionId]
  );

  const onHttpError = useCallback<WebViewHttpErrorHandler>(
    ({ nativeEvent: e }) => {
      log.error("Webview onHttpError invoked", {
        loading: e.loading,
        statusCode: e.statusCode,
        errorDescription: e.description,
        url: e.url,
        title: e.title,
      });

      if (!props.sessionId) {
        log.error("onHttpError: sessionId is undefined");
        return;
      }

      dispatch(webViewErrored({ sessionId: props.sessionId }));
    },
    [dispatch, props.sessionId]
  );

  const webViewRef = useRef<RNWebView>(null);

  useImperativeHandle(
    ref,
    () => {
      return {
        goForward: () => webViewRef.current?.goForward(),
        goBack: () => webViewRef.current?.goBack(),
        reload: () => webViewRef.current?.reload(),
        stopLoading: () => webViewRef.current?.stopLoading(),
        injectJavascript: (script: string) => webViewRef.current?.injectJavaScript(script),
      };
    },
    [webViewRef.current]
  );

  const loadCount = useRef(0);
  const [resetCount, setResetCount] = useState(0);

  const source = useMemo(() => (sourceUrl ? { uri: sourceUrl } : undefined), [sourceUrl]);

  const containerStyle = useMemo(
    () => ({ paddingTop: props.padding?.top, paddingBottom: props.padding?.bottom }),
    [props.padding]
  );

  const onShouldStartLoadWithRequest = useCallback(
    (e: ShouldStartLoadRequest) => {
      // This allows navigation only to https:// URLs. When we encounter an http:// URL, we update `window.location` with JavaScript to navigatate
      // without re-rendering the WebView, and otherwise preserving the session and navigation state
      if (e.url.startsWith("http://")) {
        const httpsUrl = ensureHttps(e.url);
        const jsCode = `window.location = "${httpsUrl}";`;
        webViewRef.current?.injectJavaScript(jsCode);
        return false;
      }

      // logic below is only to stop app redirect. The logic breaks some login flows (bon appetit, for example)
      // so we want to use it sparingly
      if (!stopAppRedirect.current) {
        return true;
      }

      // This detects navigation state changes that could lead to deep-linking to another app (e.g. NYT app) and prevents
      // them by re-rendering the WebView. The detection pattern here is for the case when the initial link loaded into the WebView
      // is also a universal link; the initial load won't deep link, but a second call that is not directly initiated by the user will
      // (e.g. redirect after signing in).
      if (e.isTopFrame && e.url === initialUrl) {
        log.info(`onShouldStartLoadWithRequest called for initial url ${e.url}`);

        // if we reset to the original url below, the cloudflare challenge is never completed
        // and we end up in a redirect loop.
        // NOTE: We now gate this on the stopAppRedirect ref above, so this likely is no longer relevant, but
        // leaving in case we get an app + cloudflare combo and it's doing no harm.
        if (redirectFromCloudflare(urls.current)) {
          return true;
        }

        // https://github.com/react-native-webview/react-native-webview/blob/master/docs/Reference.md#onshouldstartloadwithrequest
        // On Android, this not called on the first load, so the first time we see it, the browser is loading it again
        // On ios, this is called on the first load, so look for the second.
        const threshold = Platform.OS === "android" ? -1 : 0;
        if (loadCount.current > threshold) {
          log.info(
            `WebView onShouldStartLoadWithRequest encountered initial URI again. Count is ${loadCount.current}. Resetting.`
          );
          loadCount.current = 0;
          // this is used as the key for control below. Initially, I tried resetting by changing the URI object, but that seemed to have no
          // effect. I then appended #{resetCount} to the URI, and that resulted in a navigation, but it also effected the history, so the
          // back button rendered. By setting the key and replacing the element, things seem to work.
          setResetCount(c => c + 1);

          return false;
        } else {
          loadCount.current += 1;
        }
      }
      return true;
    },
    [initialUrl, webViewRef.current, urls, stopAppRedirect]
  );

  if (!source) {
    return null;
  }

  return (
    <RNWebView
      key={resetCount}
      ref={webViewRef}
      source={source}
      contentInset={props.contentInsets}
      onScroll={props.onScroll}
      onNavigationStateChange={onNavigationStateChange}
      onLoadProgress={onLoadProgress}
      onError={onError}
      onHttpError={onHttpError}
      mediaPlaybackRequiresUserAction
      allowsInlineMediaPlayback
      showsVerticalScrollIndicator={false}
      bounces
      containerStyle={containerStyle}
      userAgent={config.userAgent}
      onMessage={props.onMessage}
      originWhitelist={["*"]}
      injectedJavaScriptBeforeContentLoaded={props.injectedJavaScript}
      injectedJavaScriptBeforeContentLoadedForMainFrameOnly
      onShouldStartLoadWithRequest={onShouldStartLoadWithRequest}
    />
  );
});

function redirectFromCloudflare(urls: string[]): boolean {
  // only check the last for now
  const urlsToCheck = urls.slice(-1);
  return urlsToCheck.some(u => {
    try {
      const parsedUrl = new URL(u);
      for (const key of parsedUrl.searchParams.keys()) {
        // all of the cloudfront keys seem to start with this, so just checking
        // for any key matching instead of a specific one
        if (key.startsWith("__cf")) {
          return true;
        }
      }

      return false;
    } catch (err) {
      return false;
    }
  });
}

interface WebViewNavBarProps {
  sessionId: WebViewSessionId | undefined;
  onPressGoBack: () => void;
  onPressGoForward: () => void;
  onPressRefresh: () => void;
  bottomOffset?: number;
}

export const WebViewNavBar = React.memo((props: WebViewNavBarProps) => {
  const { bottomTabBarHeight } = useScreenElementDimensions();
  const canGoBack = useWebViewCanGoBack(props.sessionId);
  const canGoForward = useWebViewCanGoForward(props.sessionId);
  const loadingProgress = useWebViewLoadingProgress(props.sessionId);
  const collapsed = !useWebViewIsNavigated(props.sessionId);
  const bottom = bottomTabBarHeight + (props.bottomOffset ?? 0);
  const height = collapsed ? 0 : webViewConstants.webNavBarHeight;

  return (
    <View style={[styles.navBar, { height, bottom }]}>
      {!collapsed && (
        <View style={styles.navBarControls}>
          <WebNavButton type="back" disabled={!canGoBack} onPress={props.onPressGoBack} />
          <Spacer horizontal={3} />
          <WebNavButton type="forward" disabled={!canGoForward} onPress={props.onPressGoForward} />
          <Spacer horizontal={3} />
          <WebNavButton type="reload" disabled={loadingProgress < 1} onPress={props.onPressRefresh} />
        </View>
      )}
    </View>
  );
});

interface WebViewProgressBarProps {
  sessionId: WebViewSessionId | undefined;
  width?: number;
}

export const WebViewLoadingProgressBar = React.memo((props: WebViewProgressBarProps) => {
  const loadingProgress = useWebViewLoadingProgress(props.sessionId);
  const screenWidth = useResponsiveDimensions().width;
  const width = useRef(props.width ?? screenWidth).current;

  const progress = useRef(new Animated.Value(-width)).current;
  const opacity = useRef(new Animated.Value(Opacity.transparent)).current;

  const animatedStyle: Animated.WithAnimatedObject<ViewStyle> = useMemo(() => {
    return {
      transform: [
        {
          translateX: progress,
        },
      ],
      opacity: opacity,
    };
  }, [progress, opacity]);

  useEffect(() => {
    if (loadingProgress === 0) {
      return;
    }

    if (loadingProgress === 1) {
      progress.setValue(1);
      Animated.timing(opacity, { toValue: Opacity.transparent, useNativeDriver: true }).start();
      return;
    }

    opacity.setValue(Opacity.opaque);
    Animated.timing(progress, { toValue: -width + loadingProgress * width, useNativeDriver: true }).start();
  }, [width, opacity, progress, loadingProgress]);

  return (
    <View style={styles.progressBar}>
      <Animated.View style={[styles.progressIndicator, { width }, animatedStyle]} />
    </View>
  );
});

const WebNavButton = React.memo(
  (props: { type: "back" | "forward" | "reload"; disabled: boolean; onPress: () => void }) => {
    const color = props.disabled ? "black" : globalStyleColors.colorAction;
    const opacity = props.disabled ? "light" : "opaque";

    const icon = switchReturn(props.type, {
      back: <IconChevronLeft size={32} opacity={opacity} color={color} />,
      forward: <IconChevronRight size={32} opacity={opacity} color={color} />,
      reload: <IconReload strokeWidth={1.5} size={22} opacity={opacity} color={color} />,
    });

    return (
      <Pressable onPress={props.onPress} disabled={props.disabled}>
        {icon}
      </Pressable>
    );
  }
);

const styles = StyleSheet.create({
  navBar: {
    height: webViewConstants.webNavBarHeight,
    position: "absolute",
    justifyContent: "center",
    backgroundColor: globalStyleColors.white,
    left: 0,
    right: 0,
    zIndex: 2,
    ...globalStyles.borderBottomBarThick,
  },
  navBarControls: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginLeft: 12,
  },
  progressBar: {
    position: "absolute",
    overflow: "hidden",
    left: 0,
    right: 0,
  },
  progressIndicator: {
    backgroundColor: globalStyleColors.colorAccentCool,
    height: webViewConstants.progressBarHeight,
  },
});

function ensureHttps(url: string) {
  return url.replace(/^http:/i, "https:");
}
