import React from "react";
import SplashScreenLogoRaw from "../assets/logo_splash_screen.svg";
import { globalStyleColors, globalStyleConstants } from "./GlobalStyles";
import { StyleSheet, View } from "react-native";
import { ContainerFadeIn } from "./Containers";
import { THeading2 } from "./Typography";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useResponsiveDimensions } from "./Responsive";
import { Spacer } from "./Spacer";

const strings = {
  tagline: "a smart recipe manager that\nmakes cooking easier",
};

const constants = {
  logoWidth: 225,
  logoHeight: 71,
  logoColor: globalStyleColors.blackSoft,
  logoTopOffset: 103,
};

export const Splash = React.memo(() => {
  const { top: safeAreaTop } = useSafeAreaInsets();

  // This coordinates with the splash screen. If positioning changes here, it must change there as well.
  const top = safeAreaTop + constants.logoTopOffset;

  return (
    <View style={styles.container}>
      <View style={[styles.logoContainer, { top }]}>
        <SplashScreenLogoRaw width={constants.logoWidth} height={constants.logoHeight} color={constants.logoColor} />
        <Spacer vertical={1} />
        <Tagline />
      </View>
    </View>
  );
});

const Tagline = () => {
  const { defaultFontScale } = useResponsiveDimensions();

  return (
    <ContainerFadeIn style={styles.tagline} delay={300} duration={800}>
      <THeading2
        fontWeight="normal"
        color={globalStyleColors.blackSoft}
        align="center"
        numberOfLines={2}
        adjustsFontSizeToFit
        enableFontScaling="upOnly"
        scale={defaultFontScale}
      >
        {strings.tagline}
      </THeading2>
    </ContainerFadeIn>
  );
};

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: globalStyleColors.colorAccentMidDark,
  },
  logoContainer: {
    position: "absolute",
    alignSelf: "center",
    alignItems: "center",
  },
  tagline: {
    marginTop: globalStyleConstants.unitSize,
    minWidth: constants.logoWidth,
    alignSelf: "center",
  },
});
