import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { useCallback, useEffect, useState } from "react";
import { Alert } from "../components/Alert/Alert";
import { Spacer } from "../components/Spacer";
import { TBody, TSecondary } from "../components/Typography";
import { useAuthedUser } from "../lib/system/SystemSelectors";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { useDispatch } from "../lib/redux/Redux";
import { deleteAccount } from "../lib/system/SystemThunks";
import React from "react";
import { globalStyleColors } from "../components/GlobalStyles";
import { Pressable } from "../components/Pressable";
import { View } from "react-native";
import { FlexedSpinner, Spinner } from "../components/Spinner";
import { log } from "../Log";

const strings = {
  title: "Delete Account",
  deleteAccount: "Delete account",
  alert: {
    title: "Delete Account",
    message: "Are you sure you want to delete your account? This cannot be undone.",
  },
  details: `Deleting your account will sign you out and you will no longer be able to sign in.
  
Data associated with your account will be deleted within the next 72 hours, including:
  
    •  Recipes you have added to your library
    •  Your posts, likes, and comments
    •  Your profile information and cooking history
    •  Grocery list items you have added
  
If you are a member of a household, you will be removed from the household, but any items added by other users that are part of the household will remain.
  
You will be notified when deletion is complete at `,
};

export const DeleteAccountScreen = withScreenContainer("DeleteAccountScreen", () => {
  const authedUser = useAuthedUser();
  const dispatch = useDispatch();
  const screen = useScreen();
  const [waiting, setWaiting] = useState(false);

  const doDelete = useCallback(() => {
    dispatch(deleteAccount(screen.nav, setWaiting)).catch(err => {
      displayUnexpectedErrorAndLog("Error dispatching deleteAccount", err);
    });
  }, [screen.nav]);

  const onPressDeleteAccount = useCallback(() => {
    setWaiting(true);
    Alert.alert(strings.alert.title, strings.alert.message, [
      {
        type: "cancel",
        onPress: () => setWaiting(false),
      },
      {
        type: "delete",
        onPress: doDelete,
      },
    ]);
  }, [doDelete]);

  useEffect(() => {
    if (authedUser?.isRegistered === false) {
      log.error("Got anonymous user in DeleteAccountScreen. This should not happen.");
    }
  }, [authedUser]);

  if (!authedUser?.isRegistered) {
    return null;
  }

  return (
    <ScreenView header={{ type: "default", style: "default", title: strings.title }}>
      {!authedUser && (
        // This is expected  when the deletion call begins because sign out is called, which clears redux state
        <FlexedSpinner />
      )}
      {!!authedUser && (
        <>
          <Spacer vertical={1} />
          <TSecondary>
            <TSecondary>{strings.details}</TSecondary>
            <TSecondary fontWeight="medium">{authedUser.email.address}</TSecondary>
          </TSecondary>
          <Spacer vertical={3} />
          <DeleteAccountButton onPress={onPressDeleteAccount} waiting={waiting} />
        </>
      )}
    </ScreenView>
  );
});

const DeleteAccountButton = React.memo((props: { onPress: () => void; waiting: boolean }) => {
  return (
    <Pressable onPress={props.onPress} disabled={props.waiting}>
      <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
        <TBody align="center" color={globalStyleColors.colorAccentWarm} fontWeight="medium">
          {strings.deleteAccount}
        </TBody>
        {props.waiting && (
          <>
            <Spacer horizontal={1} />
            <Spinner />
          </>
        )}
      </View>
    </Pressable>
  );
});
