import ReconnectingWebSocket from "reconnecting-websocket";
import { defaultTimeProvider, EpochMs, newId } from "@eatbetter/common-shared";
import { log } from "../Log";
import { CurrentEnvironment } from "../CurrentEnvironment";

export class WebsocketManager {
  private readonly url: string;
  private readonly connection: ReconnectingWebSocket;
  private readonly getToken: () => Promise<string | undefined>;
  private intervalHandle: ReturnType<typeof setInterval> | undefined;
  private pingSent: number | undefined;
  private pingLatency: number | undefined;
  private pingResponse: EpochMs | undefined;
  private opened = false;

  constructor(opts: { url: string; getToken: () => Promise<string | undefined> }) {
    this.getToken = opts.getToken;
    this.url = opts.url;

    const getUrl = async () => {
      const token = await this.getToken();
      return `${this.url}?clientTrace=${newId()}&Authorization=${token}`;
    };

    this.connection = new ReconnectingWebSocket(getUrl, [], {
      startClosed: !this.opened,
    });

    this.connection.addEventListener("message", this.defaultOnReceive);

    this.connection.addEventListener("error", err => {
      log.warn("Websocket error handler invoked", { err });
    });

    this.connection.addEventListener("open", () => {
      log.info("Websocket connection opened, starting ping");
      this.startPing();
    });

    this.connection.addEventListener("close", e => {
      log.info("Websocket connection closed, canceling ping", { reason: e.reason, code: e.code });
      this.clearPing();
    });
  }

  /**
   * Set on receive handler. This is intended to be called exactly once.
   */
  onReceive(handler: (received: string) => void) {
    this.connection.removeEventListener("message", this.defaultOnReceive);
    this.connection.addEventListener("message", e => {
      // if the backend has isuses, received might be undefined since a message like this will be returned:
      // {"message": "Internal server error", "connectionId":"azSvac-lvHcCGqw=", "requestId":"azS46FWbPHcFhdg="}
      // no data will lead to received being undefined
      if (e.data === undefined || isApiGatewayError(e.data)) {
        log.error("Got websocket push with unexpected data", { message: e });
        return;
      }

      const { handled } = this.handleSystemMessages(e);
      if (handled) {
        return;
      }

      log.info("Websocket message received ", { data: e.data.substring(0, 100) });
      handler(e.data);
    });
  }

  connect() {
    if (!this.opened) {
      log.info("Websocket connect called. Calling reconnection on underlying connection");
      this.opened = true;
      this.connection.reconnect();
    }
  }

  close() {
    if (this.opened) {
      log.info("Websocket close called. Calling close on underlying connection");
      this.opened = false;
      this.connection.close();
    }
  }

  get lastPingLatency(): number | undefined {
    return this.pingLatency;
  }

  get lastPingResponse(): EpochMs | undefined {
    return this.pingResponse;
  }

  private startPing = () => {
    // make sure we clear the interval if it's already running
    this.clearPing();
    this.pingSent = undefined;
    this.sendPing();
    this.intervalHandle = setInterval(() => {
      this.sendPing();
    }, 60 * 1000 * (CurrentEnvironment.configEnvironment() === "local" ? 10 : 1));
  };

  private clearPing = () => {
    // we dont set this.pingSent to undefined here in case we just sent - this will
    // allow the latency calculation to succeed if we get a pong.
    // We do clear it in startPing
    if (this.intervalHandle) {
      clearInterval(this.intervalHandle);
      this.intervalHandle = undefined;
    }
  };

  private sendPing = () => {
    try {
      if (this.pingSent !== undefined) {
        log.warn("Stale websocket connection found. Reconnecting.");
        this.clearPing();
        this.connection.close();

        // Ensure the connection is still supposed to be open.
        if (this.opened) {
          this.connection.reconnect();
        }
      } else {
        this.pingSent = defaultTimeProvider();
        log.info("Sending websocket ping");
        this.connection.send("Ping");
      }
    } catch (err) {
      log.warn("Received error on websocket Ping send", { err });
    }
  };

  private defaultOnReceive(received: MessageEvent): void {
    const handled = this.handleSystemMessages(received);
    if (!handled) {
      log.error("Received websocket message with no handler", { received: received.data });
    }
  }

  private handleSystemMessages = (received: MessageEvent): { handled: boolean } => {
    const lc = received.data?.toString().toLowerCase();
    if (lc === "pong") {
      this.handlePong();
      return { handled: true };
    }

    return { handled: false };
  };

  private handlePong = (): void => {
    const latency = defaultTimeProvider() - (this.pingSent ?? 0);
    log.info(`Websocket pong received ${latency} ms after last ping sent`);
    this.pingResponse = defaultTimeProvider();
    this.pingSent = undefined;
    this.pingLatency = latency;
  };
}

function isApiGatewayError(e: any): boolean {
  // Api Gateway will send a message like this if a two-way integration (simply a websocket path that is supposed to
  // return a response) errors out.
  // {"message": "Internal server error", "connectionId":"azTrecaNvHcCGqw=", "requestId":"azT-dHp0vHcFmgw="}
  try {
    const o = JSON.parse(e);
    return o.message && o.connectionId && o.requestId;
  } catch (err) {
    return false;
  }
}
