import { deepEquals } from "./DeepEquals";

/**
 * Ensures an async function only has a single execution at a time. During execution, subsequent callers
 * will receive the same promise the original caller received.
 * If the function takes an argument, the function will throw if an execution is under way and the passed
 * args are different than the original args.
 * @param fn
 */
export function serializeAsyncFunction<TResult>(fn: () => Promise<TResult>): () => Promise<TResult>;
export function serializeAsyncFunction<TArgs, TResult>(
  fn: (args: TArgs) => Promise<TResult>
): (args: TArgs) => Promise<TResult>;
export function serializeAsyncFunction<TArgs = void, TResult = void>(
  fn: ((args?: TArgs) => Promise<TResult>) | (() => Promise<TResult>)
): (args?: TArgs) => Promise<TResult> {
  let p: Promise<TResult> | undefined;
  let currentArgs: TArgs | undefined;

  return async (args?: TArgs) => {
    if (p) {
      if (deepEquals(currentArgs, args)) {
        return p;
      } else {
        throw new Error("Duplicate calls to function with different args");
      }
    }

    currentArgs = args;
    p = fn(args).finally(() => {
      p = undefined;
    });

    return p;
  };
}
