import { ItemWithPhrases, Phrase } from "@eatbetter/items-data";
import {
  ButtonRectangle,
  ContainerEnd,
  ContainerPadded,
  IconChevronRight,
  IconEx,
  Pressable,
  Spacer,
  TBody,
  TextInput,
} from "@eatbetter/ui-shared";
import { TextInputLabel } from "./TextInputLabel";
import { Dispatch, Reducer, useEffect, useReducer, useState } from "react";
import { produce } from "immer";
import { View } from "react-native";
import { bottomThrow } from "@eatbetter/common-shared";
import { ShoppableItemId } from "@eatbetter/items-shared";
import {
  DisambiguationSingleItem,
  KnownDisambiguationType,
  RawCategory,
  UnitMeasurementType,
  GroupName,
} from "@eatbetter/items-server";
import {
  useIngredientCategories,
  useKnownDisambiguationTypes,
  useShoppableIngredients,
  useUnitMeasurementTypes,
} from "../lib/data-manager/DataManagerSelectors";
import { Select } from "antd";

interface IngredientEditControlProps {
  initialItem: ItemWithPhrases;
  onSave: (i: ItemWithPhrases) => void;
  onDelete?: () => void;
  onCancel: () => void;
}

type IngredientEditActions =
  | { type: "editId"; id: string }
  | { type: "editCategory"; category: RawCategory }
  | { type: "addPhrase" }
  | { type: "editPhrase"; index: number; phrase: Phrase }
  | { type: "setPhraseMisspelled"; index: number; misspelled: boolean }
  | { type: "deletePhrase"; index: number }
  | { type: "addDisambiguationItem" }
  | { type: "deleteDisambiguationItem"; index: number }
  | { type: "setDisambiguationShoppableId"; index: number; shoppableId: ShoppableItemId }
  | { type: "setDisambiguationKnownType"; index: number; value: KnownDisambiguationType | undefined }
  | { type: "setDisambiguationUnitMeasurementType"; index: number; value: UnitMeasurementType | undefined }
  | { type: "addDisambiguationItemKeword"; index: number; keyword: string }
  | { type: "deleteDisambiguationItemKeyword"; index: number; keyword: string }
  | { type: "setDisambiguationDefault"; index: number }
  | { type: "deleteDisambiguationDefault" }
  | { type: "setDisambiguationDefaultCategory"; category?: RawCategory }
  | { type: "setGroupName"; groupName?: GroupName };

type IngredientReducer = Reducer<ItemWithPhrases, IngredientEditActions>;

const { Option } = Select;

export const IngredientEditControl = (props: IngredientEditControlProps) => {
  const [ingredient, dispatch] = useReducer<IngredientReducer>((current, action) => {
    return produce(current, draft => {
      switch (action.type) {
        case "editId": {
          draft.id = action.id as ShoppableItemId;
          break;
        }
        case "editCategory": {
          if (draft.type !== "shoppable") {
            throw new Error("Can't set category for a disambiguation item");
          }
          draft.category = action.category;
          break;
        }
        case "editPhrase": {
          draft.phrases[action.index] = action.phrase;
          break;
        }
        case "addPhrase": {
          draft.phrases.push({ s: "" });
          break;
        }
        case "deletePhrase": {
          draft.phrases.splice(action.index, 1);
          break;
        }
        case "setPhraseMisspelled": {
          draft.phrases[action.index]!.misspelled = action.misspelled ? true : undefined;
          break;
        }
        case "addDisambiguationItem": {
          if (draft.type !== "disambiguation") {
            throw new Error(`Action type ${action.type} valid only for disambiguation items`);
          }

          draft.items.push({ itemId: "" as ShoppableItemId, keywords: [] });
          break;
        }
        case "deleteDisambiguationItem": {
          if (draft.type !== "disambiguation") {
            throw new Error(`Action type ${action.type} valid only for disambiguation items`);
          }

          draft.items.splice(action.index, 1);
          break;
        }
        case "setDisambiguationShoppableId": {
          if (draft.type !== "disambiguation") {
            throw new Error(`Action type ${action.type} valid only for disambiguation items`);
          }

          draft.items[action.index]!.itemId = action.shoppableId;
          break;
        }
        case "setDisambiguationKnownType": {
          if (draft.type !== "disambiguation") {
            throw new Error(`Action type ${action.type} valid only for disambiguation items`);
          }

          draft.items[action.index]!.known = action.value;
          break;
        }
        case "setDisambiguationUnitMeasurementType": {
          if (draft.type !== "disambiguation") {
            throw new Error(`Action type ${action.type} valid only for disambiguation items`);
          }

          draft.items[action.index]!.unitMeasurementType = action.value;
          break;
        }
        case "addDisambiguationItemKeword": {
          if (draft.type !== "disambiguation") {
            throw new Error(`Action type ${action.type} valid only for disambiguation items`);
          }

          draft.items[action.index]!.keywords.push(action.keyword);
          break;
        }
        case "deleteDisambiguationItemKeyword": {
          if (draft.type !== "disambiguation") {
            throw new Error(`Action type ${action.type} valid only for disambiguation items`);
          }

          draft.items[action.index]!.keywords = draft.items[action.index]!.keywords.filter(k => k !== action.keyword);
          break;
        }
        case "setDisambiguationDefault": {
          if (draft.type !== "disambiguation") {
            throw new Error(`Action type ${action.type} valid only for disambiguation items`);
          }

          draft.default = draft.items[action.index]!.itemId;
          break;
        }
        case "deleteDisambiguationDefault": {
          if (draft.type !== "disambiguation") {
            throw new Error(`Action type ${action.type} valid only for disambiguation items`);
          }

          draft.default = undefined;
          break;
        }
        case "setDisambiguationDefaultCategory": {
          if (draft.type !== "disambiguation") {
            throw new Error(`Action type ${action.type} valid only for disambiguation items`);
          }

          draft.defaultCategory = action.category;
          break;
        }
        case "setGroupName": {
          draft.groupName = action.groupName;
          break;
        }
        default:
          bottomThrow(action);
      }
    });
  }, props.initialItem);

  const onCategoryChange = (s: RawCategory) => {
    if (s) {
      dispatch({ type: "editCategory", category: s });
    }
  };

  const onDefaultCategoryChange = (s?: RawCategory) => {
    dispatch({ type: "setDisambiguationDefaultCategory", category: s });
  };

  return (
    <ContainerPadded all={1}>
      <View style={{ flexDirection: "row" }}>
        <ButtonRectangle onPress={() => props.onSave(ingredient)} type="submit" title="Save" />
        <Spacer horizontal={1} />
        <ButtonRectangle onPress={props.onCancel} type="cancel" title="Cancel" />
        <Spacer horizontal={1} />
        {props.onDelete && <ButtonRectangle onPress={props.onDelete} type="cancel" title="Delete" />}
      </View>
      <Spacer vertical={1} />
      <TextInputLabel text="ID" />
      <TextInput value={ingredient.id} onChangeText={id => dispatch({ type: "editId", id })} />
      <Spacer vertical={1} />
      {ingredient.type === "shoppable" && (
        <>
          <TextInputLabel text="Category" />
          <CategoryChooser onChange={onCategoryChange} category={ingredient.category} />
          <Spacer vertical={1} />
        </>
      )}
      {ingredient.type === "disambiguation" && (
        <>
          <TextInputLabel text="Default Category (optional)" />
          <CategoryChooser onChange={onDefaultCategoryChange} category={ingredient.defaultCategory} />
          <Spacer vertical={1} />
        </>
      )}
      <TextInputLabel text="Group Name" />
      <GroupNameEdit dispatch={dispatch} groupName={ingredient.groupName} />
      <Spacer vertical={1} />
      <TextInputLabel text="Phrases (primary phrase should be first)" />
      <PhraseList dispatch={dispatch} phrases={ingredient.phrases} />
      {ingredient.type === "disambiguation" && (
        <>
          <Spacer vertical={2} />
          <DisambiguationItemList items={ingredient.items} dispatch={dispatch} default={ingredient.default} />
        </>
      )}
    </ContainerPadded>
  );
};

const CategoryChooser = (props: { category?: RawCategory; onChange?: (c: RawCategory) => void }) => {
  const categories = useIngredientCategories();

  return (
    <Select showSearch value={props.category} onChange={props.onChange} allowClear>
      {categories.map(c => {
        return (
          <Option value={c} key={c}>
            {c}
          </Option>
        );
      })}
    </Select>
  );
};

interface PhraseListProps {
  dispatch: Dispatch<IngredientEditActions>;
  phrases: Phrase[];
}

const PhraseList = (props: PhraseListProps) => {
  const onAdd = () => {
    props.dispatch({ type: "addPhrase" });
  };

  return (
    <View>
      <Spacer vertical={1} />
      {props.phrases.map((phrase, idx) => {
        return <SinglePhrase dispatch={props.dispatch} phrase={phrase} key={idx} index={idx} onSubmit={onAdd} />;
      })}
      <ButtonRectangle onPress={onAdd} type="submit" title="Add Phrase" />
    </View>
  );
};

interface GroupNameEditProps {
  dispatch: Dispatch<IngredientEditActions>;
  groupName?: GroupName;
}

const GroupNameEdit = (props: GroupNameEditProps) => {
  const [otherText, setOtherText] = useState(typeof props.groupName === "object" ? props.groupName.other : "");

  useEffect(() => {
    if (typeof props.groupName === "object") {
      props.dispatch({ type: "setGroupName", groupName: { other: otherText } });
    }
  }, [otherText]);

  const onSelectChange = (v: "s" | "p" | "other" | undefined) => {
    if (v === "other") {
      props.dispatch({ type: "setGroupName", groupName: { other: otherText } });
    } else {
      props.dispatch({ type: "setGroupName", groupName: v });
    }
  };

  const value = typeof props.groupName === "object" ? "other" : props.groupName;

  return (
    <View style={{ flex: 1, flexDirection: "row" }}>
      <Select value={value} allowClear onChange={onSelectChange} style={{ width: 200 }}>
        <Option value="s" key="s">
          Singular
        </Option>
        <Option value="p" key="p">
          Plural
        </Option>
        <Option value="other" key="other">
          Other
        </Option>
      </Select>
      <Spacer horizontal={2} />
      {typeof props.groupName === "object" && (
        <TextInput value={otherText} onChangeText={setOtherText} placeholderText={"Other text"} />
      )}
    </View>
  );
};

interface SinglePhraseProps {
  onSubmit?: () => void;
  dispatch: Dispatch<IngredientEditActions>;
  phrase: Phrase;
  index: number;
}

const SinglePhrase = (props: SinglePhraseProps) => {
  const onChangeSingular = (str: string) => {
    const newPhrase = {
      ...props.phrase,
      s: str,
      p: props.phrase.p,
    };
    props.dispatch({ type: "editPhrase", index: props.index, phrase: newPhrase });
  };

  const onChangePlural = (str: string) => {
    const newPhrase = {
      ...props.phrase,
      s: props.phrase.s,
      p: str ? str : undefined,
    };
    props.dispatch({ type: "editPhrase", index: props.index, phrase: newPhrase });
  };

  const placeholder = !props.phrase.p ? (props.phrase.s ? `${props.phrase.s}s` : undefined) : undefined;

  const isLikelyNew = !props.phrase.s;

  return (
    <ContainerPadded bottom={1} flexDirection="row">
      <View style={{ flex: 1 }}>
        <TextInput
          value={props.phrase.s}
          onChangeText={onChangeSingular}
          onSubmit={props.onSubmit}
          autoFocus={isLikelyNew}
        />
      </View>
      <Spacer horizontal={1} />
      <Pressable
        onPress={() => onChangePlural(props.phrase.s)}
        style={{ justifyContent: "center", alignItems: "center" }}
        disabled={props.phrase.s === props.phrase.p}
      >
        <IconChevronRight />
      </Pressable>
      <Spacer horizontal={1} />
      <View style={{ flex: 1 }}>
        <TextInput
          value={props.phrase.p ?? ""}
          onChangeText={onChangePlural}
          placeholderText={placeholder}
          onSubmit={props.onSubmit}
        />
      </View>
      <Spacer horizontal={1} />
      <View style={{ alignItems: "center", flexDirection: "row" }}>
        <TBody color="lightgray">misspelled</TBody>
        <Spacer horizontal={0.5} />
        <input
          type="checkbox"
          checked={props.phrase.misspelled ?? false}
          onChange={checked =>
            props.dispatch({ type: "setPhraseMisspelled", index: props.index, misspelled: checked.target.checked })
          }
        />
        <Spacer horizontal={1} />
        <Pressable onPress={() => props.dispatch({ type: "deletePhrase", index: props.index })}>
          <IconEx />
        </Pressable>
      </View>
    </ContainerPadded>
  );
};

interface DisambiguationItemListProps {
  default?: ShoppableItemId;
  items: DisambiguationSingleItem[];
  dispatch: Dispatch<IngredientEditActions>;
}

const DisambiguationItemList = (props: DisambiguationItemListProps) => {
  const onAdd = () => {
    props.dispatch({ type: "addDisambiguationItem" });
  };

  return (
    <View>
      {props.items.map((item, idx) => {
        const isDefault = !!props.default && item.itemId === props.default;
        return (
          <SingleDisambiguationItem dispatch={props.dispatch} item={item} key={idx} index={idx} isDefault={isDefault} />
        );
      })}
      <ButtonRectangle onPress={onAdd} type="submit" title="Add Disambiguation Item" />
    </View>
  );
};

interface SingleDisambiguationItemProps {
  item: DisambiguationSingleItem;
  index: number;
  isDefault: boolean;
  dispatch: Dispatch<IngredientEditActions>;
}

const SingleDisambiguationItem = (props: SingleDisambiguationItemProps) => {
  const shoppable = useShoppableIngredients();
  const knownDisambiguationTypes = useKnownDisambiguationTypes();
  const unitMeasurementTypes = useUnitMeasurementTypes();

  const [keyword, setKeyword] = useState("");
  const onIdChange = (id: ShoppableItemId) =>
    props.dispatch({ type: "setDisambiguationShoppableId", index: props.index, shoppableId: id });
  const onKnownChange = (k: KnownDisambiguationType) =>
    props.dispatch({ type: "setDisambiguationKnownType", index: props.index, value: k ? k : undefined });
  const onUnitMeasurementChange = (k: UnitMeasurementType) => {
    props.dispatch({ type: "setDisambiguationUnitMeasurementType", index: props.index, value: k ? k : undefined });
  };
  const addKeyword = () => {
    props.dispatch({ type: "addDisambiguationItemKeword", index: props.index, keyword });
    setKeyword("");
  };

  return (
    <ContainerPadded bottom={1}>
      <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
        <TextInputLabel text={`Disambiguation Item ${props.index + 1}`} />
        <Spacer horizontal={1} />
        <TBody color="lightgray">default</TBody>
        <Spacer horizontal={0.5} />
        <input
          type="checkbox"
          checked={props.isDefault}
          onChange={checked => {
            if (checked.target.checked) {
              props.dispatch({ type: "setDisambiguationDefault", index: props.index });
            } else {
              props.dispatch({ type: "deleteDisambiguationDefault" });
            }
          }}
        />
        <ContainerEnd horizontal>
          <Pressable onPress={() => props.dispatch({ type: "deleteDisambiguationItem", index: props.index })}>
            <IconEx />
          </Pressable>
        </ContainerEnd>
      </View>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Select
          showSearch
          value={props.item.itemId}
          style={{ width: 500 }}
          placeholder="Shoppable ID"
          onChange={onIdChange}
        >
          {shoppable.map(s => {
            return <Option value={s.id} key={s.id}>{`${s.id} (${s.phrase})`}</Option>;
          })}
        </Select>
      </View>
      <Spacer vertical={1} />
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Select
          showSearch
          allowClear
          value={props.item.known}
          style={{ width: 200 }}
          placeholder="Known Types"
          onChange={onKnownChange}
        >
          {knownDisambiguationTypes.map(s => {
            return (
              <Option value={s} key={s}>
                {s}
              </Option>
            );
          })}
        </Select>
        <Spacer horizontal={1} />
        <Select
          allowClear
          value={props.item.unitMeasurementType}
          placeholder="Unit type"
          onChange={onUnitMeasurementChange}
        >
          {unitMeasurementTypes.map(u => {
            return (
              <Option value={u} key={u}>
                {u}
              </Option>
            );
          })}
        </Select>
      </View>
      <View style={{ flexDirection: "row", alignItems: "center", paddingVertical: 12 }}>
        <View>
          <TextInput value={keyword} onChangeText={setKeyword} onSubmit={addKeyword} />
        </View>
        <Spacer horizontal={1} />
        <ButtonRectangle onPress={addKeyword} type={"submit"} title="Add Keyword" />
        <Spacer horizontal={2} />
        {props.item.keywords.map(k => {
          return <DisambiguationKeyword key={k} dispatch={props.dispatch} index={props.index} keyword={k} />;
        })}
      </View>
    </ContainerPadded>
  );
};

interface DisambiguationKeywordProps {
  dispatch: Dispatch<IngredientEditActions>;
  keyword: string;
  index: number;
}

const DisambiguationKeyword = (props: DisambiguationKeywordProps) => {
  const onDelete = () =>
    props.dispatch({ type: "deleteDisambiguationItemKeyword", index: props.index, keyword: props.keyword });

  return (
    <View
      style={{
        flexDirection: "row",
        borderWidth: 0.1,
        borderColor: "lightgray",
        borderRadius: 4,
        alignItems: "center",
        padding: 6,
        marginRight: 12,
      }}
    >
      <TBody>{props.keyword}</TBody>
      <Spacer horizontal={1} />
      <Pressable onPress={onDelete}>
        <IconEx size={8} />
      </Pressable>
    </View>
  );
};
