import { Linking, Platform } from "react-native";
import { UrlString, bottomThrow } from "@eatbetter/common-shared";
import { log } from "../../Log";
import { ViewWebPageScreenProps, navTree } from "../../navigation/NavTree";
import { useCallback } from "react";
import { useScreen } from "../../navigation/ScreenContainer";
import { useDispatch } from "../redux/Redux";
import { analyticsEvent } from "../analytics/AnalyticsThunks";
import { reportInternalSiteOpened } from "../analytics/AnalyticsEvents";

export type InternalWebPage = "privacyPolicy" | "termsOfService";

/**
 * Returns ViewWebPageScreenProps for internal pages (e.g. privacy policy, terms of service)
 */
function getInternalWebPageScreenProps(page: InternalWebPage): ViewWebPageScreenProps {
  switch (page) {
    case "privacyPolicy": {
      return { screenHeader: "Privacy Policy", url: "https://www.deglaze.app/privacy" as UrlString };
    }
    case "termsOfService": {
      return { screenHeader: "Terms of Service", url: "https://www.deglaze.app/terms" as UrlString };
    }
    default: {
      bottomThrow(page, log);
    }
  }
}

/**
 * Returns a callback that opens an internal web page (e.g. Privacy Policy). In the app, this will navigate to a
 * screen with a full-screen web view (ViewWebPageScreen). On web, it opens the page in the same tab.
 */
export function useOpenInternalWebPage(page: InternalWebPage) {
  const screen = useScreen();
  const dispatch = useDispatch();

  const openInternalWebPage = useCallback(() => {
    dispatch(analyticsEvent(reportInternalSiteOpened(page)));
    const pageProps = getInternalWebPageScreenProps(page);

    if (Platform.OS === "web") {
      openWebpage(pageProps.url);
      return;
    }

    screen.nav.modal(navTree.get.screens.viewWebPage, pageProps);
  }, [page, screen.nav.modal, dispatch]);

  return openInternalWebPage;
}

/**
 * Returns true if the platform is web and the current page was loaded via back/forward/refresh
 */
export function webpageLoadedFromBackForwardOrRefresh() {
  if (Platform.OS !== "web") {
    return false;
  }

  const navType = window?.performance?.getEntriesByType("navigation")[0] as PerformanceNavigationTiming;
  const type = navType?.type;

  return type === "back_forward" || type === "reload";
}

/**
 * Open a web page. In the app, this will open the user's browser. In web, by default, it will navigate
 * the current tab. Specifying webBehavior: newTab for options will open the link in a new tab on web.
 */
export function openWebpage(url: UrlString, options?: { webBehavior: "sameTab" | "newTab" }) {
  if (Platform.OS === "web") {
    if (options?.webBehavior === "newTab") {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  } else {
    Linking.openURL(url).catch(err => {
      log.errorCaught(`Error calling Linking.openURL for '${url}'`, err);
    });
  }
}
