import { UserRecipeId } from "@eatbetter/recipes-shared";
import React, { useCallback, useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { BottomActionBar, bottomActionBarConstants } from "../components/BottomActionBar";
import { ScreenView, useScreenElementDimensions } from "../components/ScreenView";
import {
  MultiSelectEntity,
  EntitySelectedHandler,
  getSelectableEntities,
  updateSelectableEntities,
  SelectEntity,
} from "../components/SelectEntity";
import { useDispatch } from "../lib/redux/Redux";
import { useUserSearchUsers } from "../lib/users/UsersSelectors";
import { searchUsers } from "../lib/users/UsersThunks";
import { ShareRecipeScreenProps } from "../navigation/NavTree";
import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { log } from "../Log";
import { shareRecipe } from "../lib/recipes/RecipesThunks";
import { Haptics } from "../components/Haptics";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { differenceBy } from "lodash";
import { InputAccessoryView } from "../components/InputAccessoryView";
import { SearchAndFilterBar, useSearchAndFilterBarHeight } from "../components/SearchBox";
import { globalStyleConstants } from "../components/GlobalStyles";
import { useKeyboardLayoutAnimation } from "../components/Keyboard";
import { getSocialEntityId, isDeglazeUser } from "@eatbetter/posts-shared";

const strings = {
  header: "Share recipe",
  submit: "Share",
  searchPlaceholder: "Search",
};

export const ShareRecipeScreen = withNonNavigableScreenContainer(
  "ShareRecipeScreen",
  (props: ShareRecipeScreenProps) => {
    const dispatch = useDispatch();
    const screen = useScreen();
    const users = useUserSearchUsers();

    const [waitingSubmit, setWaitingSubmit] = useState(false);

    const [searchPhrase, setSearchPhrase] = useState("");
    const [refreshResults, setRefreshResults] = useState(0);

    const [selectedUsers, setSelectedUsers] = useState<MultiSelectEntity[]>([]);
    const [sortedUsers, setSortedUsers] = useState<MultiSelectEntity[]>([]);

    useEffect(() => {
      dispatch(searchUsers("", { type: "shareRecipe", shareRecipeId: props.recipeId })).catch(err =>
        log.errorCaught("Unexpected error calling searchUsers when loading ShareRecipeScreen", err)
      );
    }, []);

    useEffect(() => {
      setSortedUsers(prev => updateSelectableEntities(prev, selectedUsers));
    }, [selectedUsers]);

    useEffect(() => {
      const selectableUsers = getSelectableEntities(users.users, selectedUsers);
      const additionalUsers = differenceBy(selectedUsers, selectableUsers, i => getSocialEntityId(i.entity));
      const result = searchPhrase === "" ? additionalUsers.concat(selectableUsers) : selectableUsers;
      setSortedUsers(result.sort((a, b) => Number(b.isSelected) - Number(a.isSelected)).slice());
    }, [refreshResults]);

    const onChangeSearchPhrase = useCallback(
      async (text: string) => {
        setSearchPhrase(text);
        await dispatch(searchUsers(text, { type: "shareRecipe", shareRecipeId: props.recipeId }));
        setRefreshResults(prev => prev + 1);
      },
      [dispatch, props.recipeId, setSearchPhrase, setRefreshResults]
    );

    const onSelectUser = useCallback<EntitySelectedHandler>(
      async item => {
        if (item.type === "multiSelect") {
          setSelectedUsers(prev => {
            const existing = prev.find(i => getSocialEntityId(i.entity) === getSocialEntityId(item.entity));
            if (!existing) {
              return [{ ...item, isSelected: !item.isSelected }, ...prev];
            }
            return prev.map(i =>
              getSocialEntityId(i.entity) === getSocialEntityId(item.entity)
                ? { ...item, isSelected: !item.isSelected }
                : i
            );
          });

          if (searchPhrase) {
            await onChangeSearchPhrase("");
          }
        }
      },
      [setSelectedUsers, onChangeSearchPhrase, searchPhrase]
    );

    const onCancel = useCallback(() => {
      screen.nav.goBack();
    }, [screen]);

    const onSubmit = useCallback(async () => {
      const selected = sortedUsers.filter(u => u.isSelected);
      // this filter should not have an effect, but it provides a safeguard and handles the type guard for us
      const recipients = selected.map(i => i.entity).filter(isDeglazeUser);
      const recipientIds = recipients.map(u => u.userId);
      try {
        await dispatch(shareRecipe({ recipeId: props.recipeId, recipientIds }, recipients, setWaitingSubmit));
        Haptics.feedback("operationSucceeded");
        screen.nav.goBack();
      } catch (err) {
        displayUnexpectedErrorAndLog("Error in onSubmit in ShareRecipeScreen", err);
      }
    }, [screen, dispatch, sortedUsers, props.recipeId]);

    return React.createElement<Props>(ShareRecipeScreenComponent, {
      recipeId: props.recipeId,
      selectableUsers: sortedUsers,
      selectedUsers,
      onSelectUser,
      waitingSubmit,
      waitingForSearchResults: users.pending,
      onCancel,
      onSubmit,
      searchPhrase,
      onChangeSearchPhrase,
    });
  }
);

interface Props {
  recipeId: UserRecipeId;
  selectableUsers: MultiSelectEntity[];
  selectedUsers: MultiSelectEntity[];
  waitingForSearchResults: boolean;
  waitingSubmit: boolean;
  onCancel: () => void;
  onSelectUser: EntitySelectedHandler;
  onSubmit: () => void;
  searchPhrase: string;
  onChangeSearchPhrase: (v: string) => void;
}

const ShareRecipeScreenComponent = React.memo((props: Props) => {
  const { headerHeight, bottomNotchHeight } = useScreenElementDimensions();
  const keyboardHeight = useKeyboardLayoutAnimation();

  const renderSearchBox = useCallback(() => {
    return (
      <SearchAndFilterBar
        searchPhrase={props.searchPhrase}
        onChangeSearchPhrase={props.onChangeSearchPhrase}
        placeholderText={strings.searchPlaceholder}
        autoFocus
      />
    );
  }, [props.searchPhrase, props.onChangeSearchPhrase]);

  const searchBoxHeight = useSearchAndFilterBarHeight();
  const getSearchBoxHeight = useCallback(() => {
    return searchBoxHeight;
  }, [searchBoxHeight]);

  const entityData = useMemo(() => [{ entities: props.selectableUsers }], [props.selectableUsers]);

  return (
    <ScreenView
      header={{
        type: "custom",
        title: strings.header,
        right: { type: "cancel", onPress: props.onCancel },
        subHeaderComponent: { render: renderSearchBox, getHeight: getSearchBoxHeight },
      }}
      scrollView={false}
      paddingHorizontal={false}
      paddingVertical={false}
    >
      <SelectEntity
        type="overlay"
        entityData={entityData}
        onSelectEntity={props.onSelectUser}
        waitingForResults={props.waitingForSearchResults}
        keyboardDismissMode="none"
        queryString={props.searchPhrase}
        paddingTop={headerHeight + searchBoxHeight + globalStyleConstants.unitSize}
        paddingBottom={
          (keyboardHeight > 0
            ? keyboardHeight + bottomActionBarConstants.height
            : keyboardHeight + bottomNotchHeight + bottomActionBarConstants.height) +
          2 * globalStyleConstants.unitSize
        }
      />
      <InputAccessoryView>
        <BottomActionBar
          primaryAction={{
            actionText: strings.submit,
            onPressAction: props.onSubmit,
            disabled: props.selectedUsers.every(i => !i.isSelected),
            waiting: props.waitingSubmit,
          }}
          disableSnapToBottom
        />
      </InputAccessoryView>
    </ScreenView>
  );
});
