import React from "react";
import { IconVerified } from "../Icons";
import { globalStyleColors } from "../GlobalStyles";

export const VerifiedBadge = React.memo(() => {
  return (
    <IconVerified
      color={globalStyleColors.rgba("colorTextLink", "medium")}
      size={18}
      strokeWidth={2.5}
      opacity="opaque"
    />
  );
});
