export class Lazy<T> {
  private isSet = false;
  private value: T | undefined;

  constructor(private init: () => T) {}

  get get(): T {
    if (!this.isSet) {
      const v = this.init();
      this.value = v;
      this.isSet = true;
    }

    return this.value!;
  }
}

export function lazy<T>(init: () => T) {
  return new Lazy<T>(init);
}
