import React from "react";
import { View } from "react-native";
import { Video } from "./Video";

type SnippetType = "scaling" | "textSize";

interface Snippet {
  type: SnippetType;
  analyticsId: string;
  video: number;
  aspectRatio: number;
}

const snippets: Array<Snippet> = [
  {
    type: "scaling",
    analyticsId: "scaling_control_snippet",
    video: require("../assets/feature_snippets/scaling_demo.mp4"),
    aspectRatio: 300 / 200,
  },
  {
    type: "textSize",
    analyticsId: "text_size_snippet",
    video: require("../assets/feature_snippets/text_size_demo.mp4"),
    aspectRatio: 1080 / 135,
  },
];

interface Props {
  type: (typeof snippets)[number]["type"];
}

export const FeatureDemoSnippet = React.memo((props: Props) => {
  const snippet = snippets.find(i => i.type === props.type);

  if (!snippet) {
    return null;
  }

  return (
    <View style={{ width: "100%", aspectRatio: snippet.aspectRatio }}>
      <Video
        videoAnalyticsId={snippet.analyticsId}
        videoSource={{ type: "bundled", nodeRequireOutput: snippet.video }}
        autoPlay
        isLooping
        playbackMode={{ type: "contained" }}
        resizeMode="cover"
        hidePlayButton
        backgroundColor="transparent"
        spinnerColor="dark"
        tapToExpand={false}
      />
    </View>
  );
});
