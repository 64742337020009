import { NavApi, useScreen, withScreenContainer } from "../navigation/ScreenContainer.tsx";
import { TBody, THeading1, TTertiary } from "../components/Typography.tsx";
import { ScreenView } from "../components/ScreenView.tsx";
import { useEffect, useMemo } from "react";
import { HeaderProps } from "../components/ScreenHeaders.tsx";
import { globalStyleColors, globalStyleConstants } from "../components/GlobalStyles.ts";
import { AttributionQuestionsScreenProps, AttributionQuestionStep, navTree } from "../navigation/NavTree.ts";
import { AppUserRecipe, UserRecipeId } from "@eatbetter/recipes-shared";
import { useExistingBookAttribution, useLibraryRecipe } from "../lib/recipes/RecipesSelectors.ts";
import { Spacer } from "../components/Spacer.tsx";
import { Pressable } from "../components/Pressable.tsx";
import { View } from "react-native";
import { bottomNop } from "@eatbetter/common-shared";

export const AttributionQuestionsScreen = withScreenContainer(
  "AttributionQuestionsScreen",
  (props: AttributionQuestionsScreenProps) => {
    const screen = useScreen();
    const recipe = useLibraryRecipe(props.recipeId);

    useEffect(() => {
      if (!isUserSourceRecipe(recipe)) {
        screen.nav.pop(props.popCount);
      }
    }, [recipe, screen.nav]);

    const header = useMemo<HeaderProps>(() => {
      return {
        type: "native",
        backgroundColor: globalStyleColors.colorGreyLight,
        title: "QUESTION", //attribution-todo
        right: { type: "cancel", onPress: () => screen.nav.pop(props.popCount) },
      };
    }, [screen.nav.goBack]);

    const existingBooks = useExistingBookAttribution();

    const qAndA = useMemo(
      () => getQAndA(recipe, props, screen.nav, existingBooks.length),
      [recipe, props.step, existingBooks.length]
    );

    if (!recipe || !qAndA) {
      // we'll redirect in useEffect above for no recipe, so just return an empty screen
      return <ScreenView />;
    }

    return (
      <ScreenView header={header}>
        <THeading1>{qAndA.question}</THeading1>
        <Spacer vertical={2} />
        {qAndA.answers.map(a => {
          return (
            <Pressable onPress={a.onSelect} key={a.answer}>
              <View style={{ borderWidth: 1, padding: globalStyleConstants.defaultPadding }}>
                <TBody>{a.answer}</TBody>
                {!!a.explainer && <TTertiary>{a.explainer}</TTertiary>}
              </View>
            </Pressable>
          );
        })}
      </ScreenView>
    );
  },
  {
    serializer: {
      recipeId: s => s,
      step: { optional: true, fn: s => s },
      popCount: n => n.toString(),
    },
    parser: {
      recipeId: s => s as UserRecipeId,
      step: { optional: true, fn: s => s as AttributionQuestionStep },
      popCount: s => Number.parseInt(s),
    },
  }
);

interface QuestionAndAnswers {
  question: string;
  answers: Array<{ answer: string; explainer?: string; onSelect: () => void }>;
}

function isUserSourceRecipe(r?: AppUserRecipe) {
  return r?.source.type === "user" || r?.source.type === "userPhoto";
}

function getQAndA(
  recipe: AppUserRecipe | undefined,
  props: AttributionQuestionsScreenProps,
  nav: NavApi,
  existingBookCount: number
): QuestionAndAnswers | undefined {
  if (!recipe || !isUserSourceRecipe(recipe)) {
    return undefined;
  }

  const sourceType = recipe.source.type;
  if (sourceType !== "user" && sourceType !== "userPhoto") {
    throw new Error("Unexpected source type");
  }

  let step = props.step;

  // we should only use this if there is a book or user entered book, but use a default just in case
  const bookTitle =
    recipe.book?.name ??
    (recipe.userEnteredAttribution?.type === "book" ? recipe.userEnteredAttribution?.name : "the book");

  if (!step) {
    if (sourceType === "userPhoto") {
      const hasBook = !!recipe.book;
      const hasSimple = !!recipe.userEnteredAttribution;

      // fall through
      if (!hasBook && !hasSimple) {
        step = "whatType";
      } else if (hasBook) {
        step = "existingBookOptions";
      } else {
        // simple
      }
    }
  }

  if (!step) {
    return undefined;
  }

  if (step === "whatType") {
    return {
      question: "Is this recipe from a cookbook?",
      answers: [
        {
          answer: "Yes, it's from a book",
          onSelect: () =>
            nav.goTo("push", navTree.get.screens.attributionQuestions, {
              ...props,
              step: "bookEntryOptions",
              popCount: props.popCount + 1,
            }),
        },
        {
          answer: "No, it's not from a book",
          onSelect: () =>
            nav.goTo("push", navTree.get.screens.editSimpleAttribution, {
              recipeId: props.recipeId,
              popCount: props.popCount + 1,
            }),
        },
      ],
    };
  } else if (step === "existingBookOptions") {
    return {
      question: "What do you want to do?",
      answers: [
        {
          answer: `Replace "${bookTitle}" with a different recipe source`,
          onSelect: () =>
            nav.goTo("push", navTree.get.screens.attributionQuestions, {
              recipeId: props.recipeId,
              step: "whatType",
              popCount: props.popCount + 1,
            }),
        },
        {
          answer: `Edit the book details for "${bookTitle}"`,
          onSelect: () =>
            nav.goTo("push", navTree.get.screens.bookEdit, { recipeId: props.recipeId, popCount: props.popCount + 1 }),
        },
      ],
    };
  } else if (step === "bookEntryOptions") {
    const r = {
      question: "How do you want to enter the book?",
      answers: [
        {
          answer: "Take a photo of the book cover",
          explainer: "🪄We'll get the book details with AI",
          onSelect: () =>
            nav.goTo("push", navTree.get.screens.bookCoverPhoto, {
              recipeId: props.recipeId,
              popCount: props.popCount + 1,
            }),
        },
        {
          answer: "Enter the title and author",
          onSelect: () =>
            nav.goTo("push", navTree.get.screens.bookEdit, { recipeId: props.recipeId, popCount: props.popCount + 1 }),
        },
      ],
    };

    if (existingBookCount > 0) {
      r.answers.unshift({
        answer: "Select from existing books in my Recipe Library",
        onSelect: () =>
          nav.goTo("push", navTree.get.screens.bookSelection, {
            recipeId: props.recipeId,
            popCount: props.popCount + 1,
          }),
      });
    }

    return r;
  } else {
    bottomNop(step);
  }

  return undefined;
}
