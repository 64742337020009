import React, { useCallback } from "react";
import { SectionHeading } from "../SectionHeading";
import { Spacer } from "../Spacer";
import { AvatarAndHeading } from "../AvatarAndHeading";
import { DeglazeUser } from "@eatbetter/users-shared";
import { Pressable } from "../Pressable";
import { useScreen } from "../../navigation/ScreenContainer";
import { navTree } from "../../navigation/NavTree";

const strings = {
  sectionHeader: "Added By",
};

export const GroceryItemAddedBy = React.memo((props: { addedByUser: DeglazeUser }) => {
  const screen = useScreen();

  const onPressUser = useCallback(() => {
    // Since we're in a modal here, use the profile tab to pop the other user's profile screen
    screen.nav.switchTab("profileTab", navTree.get.screens.otherUserProfile, { userId: props.addedByUser.userId });
  }, [screen.nav.switchTab, props.addedByUser.userId]);

  return (
    <Pressable onPress={onPressUser}>
      <SectionHeading text={strings.sectionHeader} noPadding />
      <Spacer vertical={1} />
      <AvatarAndHeading
        avatar={{ photo: props.addedByUser.photo }}
        heading={{ text: props.addedByUser.name }}
        subHeading={{ text: props.addedByUser.username }}
      />
    </Pressable>
  );
});
