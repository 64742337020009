import { DurationMs } from "@eatbetter/common-shared";
import { UserRecipeId } from "@eatbetter/recipes-shared";
import React, { useCallback, useState } from "react";
import { ScrollView } from "react-native";
import { displayUnexpectedErrorAndLog } from "../../lib/Errors";
import { useRecipeTime } from "../../lib/recipes/RecipesSelectors";
import { editRecipeTime } from "../../lib/recipes/RecipesThunks";
import { useDispatch } from "../../lib/redux/Redux";
import { useScreen } from "../../navigation/ScreenContainer";
import { BottomActionBar } from "../BottomActionBar";
import { Haptics } from "../Haptics";
import { useScreenHeaderDimensions } from "../ScreenHeaders";
import { RecipeTimePicker } from "./RecipeTimePicker/RecipeTimePicker";
import { RecipeEditFieldLocation } from "../../navigation/NavTree";

const strings = {
  totalTime: "Total",
  save: "Save",
  submitError: "We're having trouble connecting and couldn't save your changes",
};

interface Props {
  recipeId: UserRecipeId;
  location: RecipeEditFieldLocation;
}

export const RecipeTimeEdit = React.memo((props: Props) => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const recipeTime = useRecipeTime(props.recipeId);
  const { headerHeight } = useScreenHeaderDimensions();

  const [waiting, setWaiting] = useState(false);

  const [totalTime, setTotalTime] = useState<DurationMs | undefined>(recipeTime?.total?.[0]);
  const [totalTimeIsDirty, setTotalTimeIsDirty] = useState(false);

  const onChangeTotalTime = useCallback(
    (value: DurationMs) => {
      setTotalTimeIsDirty(true);
      setTotalTime(value);
    },
    [setTotalTimeIsDirty, setTotalTime]
  );

  const onSubmit = useCallback(async () => {
    if (!totalTimeIsDirty || !totalTime) {
      return;
    }

    try {
      await dispatch(editRecipeTime(props.recipeId, props.location, { total: [totalTime, totalTime] }, setWaiting));
      Haptics.feedback("operationSucceeded");
      screen.nav.goBack();
    } catch (err) {
      displayUnexpectedErrorAndLog(strings.submitError, err);
    }
  }, [dispatch, props.recipeId, totalTimeIsDirty, totalTime, props.location]);

  const padding = 20;

  return (
    <>
      <ScrollView style={{ paddingTop: headerHeight + padding, paddingHorizontal: padding }}>
        <RecipeTimePicker
          label={strings.totalTime}
          durationMs={totalTime ?? (0 as DurationMs)}
          onChangeTime={onChangeTotalTime}
          expandedOnLoad
        />
      </ScrollView>
      <BottomActionBar
        primaryAction={{
          actionText: strings.save,
          onPressAction: onSubmit,
          disabled: !totalTimeIsDirty,
          waiting,
        }}
        containerBackgroundColor="transparent"
      />
    </>
  );
});
