import {
  ReceivedServerData,
  ServerData,
  serverDataErrored,
  serverDataReceived,
  serverDataRequested,
} from "../redux/ServerData";
import { defaultTimeProvider, EpochMs, TypedPrimitive, UserId } from "@eatbetter/common-shared";
import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import {
  CookingSession,
  CookingSessionId,
  CookingSessionIngredient,
  CookingSessionIngredientIndex,
  CookingSessionInstruction,
  CookingSessionInstructionIndex,
  CookingSessionPushData,
} from "@eatbetter/cooking-shared";
import { setDiff } from "@eatbetter/common-shared/src/SetOps";
import {
  addUpdate,
  ItemWithUpdates,
  mergeItemWithUpdates,
  rehydrateItemWithUpdates,
  updateErrored,
  updateStarted,
  updateSucceeded,
} from "../redux/ItemWithUpdates";
import { RecipeInstructionId, RecipeSectionId } from "@eatbetter/recipes-shared";
import { log } from "../../Log";
import { Draft } from "immer";
import { userSignedInEvent, userSignedOutEvent } from "../system/SystemSlice";

export interface CookingSessionsState {
  meta: ServerData<{}>;
  activeSessionId?: CookingSessionId;
  cookingSessionIds: CookingSessionId[];
  cookingSessions: { [cookingSessionId: string]: CookingSessionState };
  timers: EntityState<RecipeCookingTimer, string>;
  focusInstructionRequest?: FocusedInstruction;
  audioEnabled: boolean;
  userId?: UserId;
  /**
   * Allows us to edit cooking session scale so the UI is responsive for the user without persisting every single
   * change as the user moves the slider.
   */
  tempScaleValues: Record<CookingSessionId, number>;
}

export type UpdateableCookingSessionMeta = Pick<CookingSession, "scale" | "unitConversion">;
export interface CookingSessionMeta extends UpdateableCookingSessionMeta {
  /**
   * This is the metaVersion from teh cooking session, but renamed to make the ItemWithUpdate
   * helper functions happy
   */
  version: EpochMs;
}

export type CookingSessionState = Omit<
  CookingSession,
  "ingredientStatuses" | "selectedInstructions" | "scale" | "unitConversion"
> & {
  ingredientStatuses: AppCookingSessionIngredientStatuses;
  selectedInstructions: AppSelectedInstructions;
  notesViewed: boolean;
  // This object is already pretty complex, so rather than adding the entire thing as ItemWithUpdate,
  // only use the pattern for the applicable fields. We also need a version field to get the full
  // benefit of ItemWithUpdates. For these fields, we want the metaVersion property, but we rename
  // it to version in this object so everything works (see ItemWithUpdates for details on how it uses version)
  meta: ItemWithUpdates<CookingSessionMeta, UpdateableCookingSessionMeta>;
};

export interface AppCookingSessionIngredientStatuses {
  [sectionId: string]: AppCookingSessionIngredient[];
}

export interface AppSelectedInstructions {
  [userId: string]: AppSelectedInstruction;
}

export type AppCookingSessionIngredient = ItemWithUpdates<
  CookingSessionIngredient,
  Pick<CookingSessionIngredient, "status">
>;

export type AppSelectedInstruction = ItemWithUpdates<CookingSessionInstruction, CookingSessionInstruction>;
export type CookingTimerId = TypedPrimitive<string, "cookingTimerId">;

export type LocalNotificationId = TypedPrimitive<string, "LocalNotificationId">;

// a timer that is running has a concrete end time
// a timer that is not running has a duration
// if there is only a duration, it must be continuously running,
// which we cannot guarantee (app restarts, phone battery dies, etc.)
export interface RecipeCookingTimer {
  type: "recipeInstructionTimer";
  id: CookingTimerId;
  startTime: EpochMs;
  endTime: EpochMs;
  pausedTime?: EpochMs;
  alertAcknowledged?: EpochMs;
  notificationIds?: LocalNotificationId[];
  cookingSessionId: CookingSessionId;
  sectionId: RecipeSectionId;
  instructionId: RecipeInstructionId;
  substringRange: [number, number];
  minSeconds: number;
  maxSeconds: number;
}

/**
 * Used to focus a specific recipe instruction before navigating to the RecipeInKitchenScreen
 */
export interface FocusedInstruction {
  cookingSessionId: CookingSessionId;
  sectionId: RecipeSectionId;
  instructionId: RecipeInstructionId;
}

export interface CreateRecipeInstructionTimerArgs {
  id: CookingTimerId;
  startTime: EpochMs;
  endTime: EpochMs;
  minSeconds: number;
  maxSeconds: number;
  cookingSessionId: CookingSessionId;
  sectionId: RecipeSectionId;
  instructionId: RecipeInstructionId;
  substringRange: [number, number];
}

const timersAdapter = createEntityAdapter<RecipeCookingTimer, string>({
  selectId: e => e.id,
});

const initialState: CookingSessionsState = {
  meta: {},
  cookingSessionIds: [],
  cookingSessions: {},
  timers: timersAdapter.getInitialState(),
  audioEnabled: false,
  tempScaleValues: {},
};

export function rehydrateCookingSessionsState(persisted: Draft<CookingSessionsState>): void {
  persisted.audioEnabled = false;
  persisted.tempScaleValues = {};

  Object.values(persisted.cookingSessions).forEach(cs => {
    if (cs) {
      Object.values(cs.ingredientStatuses).forEach(section => {
        section.forEach(s => rehydrateItemWithUpdates(s));
      });

      Object.values(cs.selectedInstructions).forEach(si => {
        rehydrateItemWithUpdates(si);
      });

      rehydrateItemWithUpdates(cs.meta);
    }
  });
}

const cookingSessionsSlice = createSlice({
  name: "cookingSessions",
  initialState,

  reducers: create => ({
    activeCookingSessionChanged: create.reducer((state, action: PayloadAction<CookingSessionId>) => {
      if (!state.cookingSessionIds.includes(action.payload)) {
        log.error(`Attempted to set the active cooking session to an invalid value: ${action.payload}`);
        return;
      }

      state.activeSessionId = action.payload;
    }),

    cookingSessionsRequested: create.reducer((state, action: PayloadAction<EpochMs>) => {
      serverDataRequested(state.meta, action.payload);
    }),

    cookingSessionsReceived: create.reducer((state, action: PayloadAction<ReceivedServerData<CookingSession[]>>) => {
      serverDataReceived(state.meta, { data: {}, startTime: action.payload.startTime });
      updateCookingSessions(state, action.payload.data);
    }),

    cookingSessionsErrored: create.reducer(state => {
      serverDataErrored(state.meta);
    }),

    cookingSessionAdded: create.reducer((state, action: PayloadAction<CookingSession>) => {
      handleReceivedCookingSession(state, action.payload);
    }),

    cookingSessionRemoved: create.reducer((state, action: PayloadAction<{ id: CookingSessionId }>) => {
      removeCookingSession(state, action.payload.id);
    }),

    cookingSessionConflict: create.reducer((state, action: PayloadAction<{ item: CookingSession }>) => {
      handleReceivedCookingSession(state, action.payload.item);
    }),

    cookingSessionPushReceived: create.reducer((state, action: PayloadAction<CookingSessionPushData>) => {
      handleReceivedCookingSession(state, action.payload.cookingSession);
    }),

    cookingSessionScaleUpdatedClient: create.reducer(
      (state, action: PayloadAction<{ id: CookingSessionId; scale: number; persist: boolean }>) => {
        const { id, scale, persist } = action.payload;
        const meta = state.cookingSessions[id]?.meta;
        if (!meta) {
          log.error(`cookingSessionScaleUpdatedClient called for ${id} but no cooking session found`);
          return;
        }

        if (persist) {
          const merged = mergeItemWithUpdates(meta);
          if (merged.scale !== scale) {
            addUpdate(meta, { scale });
          }
          delete state.tempScaleValues[id];
        } else {
          state.tempScaleValues[id] = scale;
        }
      }
    ),

    cookingSessionUpdateStarted: create.reducer((state, action: PayloadAction<CookingSessionId>) => {
      const meta = state.cookingSessions[action.payload]?.meta;
      if (!meta) {
        log.error(`cookingSessionUpdateStarted called for ${action.payload} but no cooking session found`);
        return;
      }

      updateStarted(meta, "cookingSessionUpdateStarted");
    }),

    cookingSessionUpdateErrored: create.reducer((state, action: PayloadAction<CookingSessionId>) => {
      const meta = state.cookingSessions[action.payload]?.meta;
      if (!meta) {
        log.error(`cookingSessionUpdateErrored called for ${action.payload} but no cooking session found`);
        return;
      }

      updateErrored(meta, "cookingSessionUpdateErrored");
    }),

    cookingSessionUpdateSuccess: create.reducer((state, action: PayloadAction<CookingSession>) => {
      const meta = state.cookingSessions[action.payload.id]?.meta;
      if (!meta) {
        log.error(`cookingSessionUpdateSuccess called for ${action.payload.id} but no cooking session found`);
        return;
      }

      const newMeta = getMetaFromSession(action.payload);
      updateSucceeded(meta, newMeta.item, "cookingSessionUpdateSuccess");

      // might as well update everything else
      updateCookingSession(state, action.payload);
    }),

    ingredientStatusUpdated: create.reducer(
      (
        state,
        action: PayloadAction<{
          cookingSessionId: CookingSessionId;
          sectionId: RecipeSectionId;
          ingredientId: CookingSessionIngredientIndex;
        }>
      ) => {
        const cookingSession = state.cookingSessions[action.payload.cookingSessionId]!;

        const current = internalIngredientStatusSelector(
          cookingSession,
          action.payload.sectionId,
          action.payload.ingredientId
        )!;

        const merged = mergeItemWithUpdates(current);

        const newStatus = merged.status === "pending" ? "completed" : "pending";

        current.updates.push({ update: { status: newStatus } });

        if (current.itemState === "persisted") {
          current.itemState = "updateNeeded";
        }
      }
    ),

    ingredientUpdateStarted: create.reducer(
      (
        state,
        action: PayloadAction<{
          cookingSessionId: CookingSessionId;
          sectionId: RecipeSectionId;
          ingredientId: CookingSessionIngredientIndex;
        }>
      ) => {
        const cookingSession = state.cookingSessions[action.payload.cookingSessionId]!;
        const current = internalIngredientStatusSelector(
          cookingSession,
          action.payload.sectionId,
          action.payload.ingredientId
        )!;

        updateStarted(current, "cookingSessionIngredient");
      }
    ),

    ingredientUpdateErrored: create.reducer(
      (
        state,
        action: PayloadAction<{
          cookingSessionId: CookingSessionId;
          sectionId: RecipeSectionId;
          ingredientId: CookingSessionIngredientIndex;
        }>
      ) => {
        const cookingSession = state.cookingSessions[action.payload.cookingSessionId]!;
        const current = internalIngredientStatusSelector(
          cookingSession,
          action.payload.sectionId,
          action.payload.ingredientId
        )!;

        updateErrored(current, "cookingSessionIngredient");
      }
    ),

    ingredientUpdateSuccess: create.reducer(
      (
        state,
        action: PayloadAction<{
          cookingSessionId: CookingSessionId;
          sectionId: RecipeSectionId;
          index: CookingSessionIngredientIndex;
          updatedItem: CookingSession;
        }>
      ) => {
        const cookingSession = state.cookingSessions[action.payload.cookingSessionId]!;
        const itemAndUpdates = internalIngredientStatusSelector(
          cookingSession,
          action.payload.sectionId,
          action.payload.index
        );
        const updatedItem =
          action.payload.updatedItem.ingredientStatuses[action.payload.sectionId]?.[action.payload.index];

        if (itemAndUpdates && updatedItem) {
          updateSucceeded(itemAndUpdates, updatedItem, "cookingSessionIngredient");
        }

        // since we have full data, go ahead and update anything that has changed
        updateCookingSession(state, action.payload.updatedItem);
      }
    ),

    selectedInstructionUpdated: create.reducer(
      (
        state,
        action: PayloadAction<{
          cookingSessionId: CookingSessionId;
          userId: UserId;
          instructionSectionId: RecipeSectionId;
          instructionIndex?: CookingSessionInstructionIndex;
          instructionId?: RecipeInstructionId;
        }>
      ) => {
        const cookingSession = state.cookingSessions[action.payload.cookingSessionId]!;
        const clientTs = defaultTimeProvider();

        const current = internalSelectedInstructionSelector(cookingSession, action.payload.userId);

        let index = action.payload.instructionIndex;
        if (index === undefined && action.payload.instructionId) {
          const section = cookingSession.sourceRecipe.instructions.sections.find(
            s => s.id === action.payload.instructionSectionId
          );
          index = section?.items.findIndex(
            i => i.id === action.payload.instructionId
          ) as CookingSessionInstructionIndex;
        }

        if (index === undefined) {
          log.error("Could not determine instruction index.", { args: action.payload });
          return;
        }

        if (!current) {
          const newItem: CookingSessionInstruction = {
            sectionId: action.payload.instructionSectionId,
            index,
            state: "selected",
            clientTs,
          };

          // There is no explicit create operation on the server, so it simplifies the reactor to just
          // treat everything as an update.
          cookingSession.selectedInstructions[action.payload.userId] = {
            item: newItem,
            itemState: "updateNeeded",
            updates: [{ update: newItem }],
          };
        } else {
          const updated: CookingSessionInstruction = {
            sectionId: action.payload.instructionSectionId,
            index,
            state:
              current.item.sectionId === action.payload.instructionSectionId &&
              current.item.index === action.payload.instructionIndex
                ? current.item.state === "default"
                  ? "selected"
                  : "default"
                : "selected",
            clientTs,
          };

          current.updates.push({
            update: updated,
          });

          if (current.itemState === "persisted") {
            current.itemState = "updateNeeded";
          }
        }
      }
    ),

    selectedInstructionUpdateStarted: create.reducer(
      (
        state,
        action: PayloadAction<{
          cookingSessionId: CookingSessionId;
          userId: UserId;
        }>
      ) => {
        const cookingSession = state.cookingSessions[action.payload.cookingSessionId]!;
        const current = internalSelectedInstructionSelector(cookingSession, action.payload.userId)!;

        updateStarted(current, "cookingSessionInstruction");
      }
    ),

    selectedInstructionUpdateErrored: create.reducer(
      (
        state,
        action: PayloadAction<{
          cookingSessionId: CookingSessionId;
          userId: UserId;
        }>
      ) => {
        const cookingSession = state.cookingSessions[action.payload.cookingSessionId]!;
        const current = internalSelectedInstructionSelector(cookingSession, action.payload.userId)!;

        updateErrored(current, "cookingSessionInstruction");
      }
    ),

    selectedInstructionUpdateSuccess: create.reducer(
      (
        state,
        action: PayloadAction<{
          cookingSessionId: CookingSessionId;
          userId: UserId;
          updatedItem: CookingSession;
        }>
      ) => {
        const cookingSession = state.cookingSessions[action.payload.cookingSessionId]!;
        const current = internalSelectedInstructionSelector(cookingSession, action.payload.userId)!;
        current.errorCount = undefined;
        current.lastAttempt = undefined;
        current.updates = current.updates.filter(u => !u.pending);
        current.itemState = current.updates.length > 0 ? "updateNeeded" : "persisted";

        // set the new item here, otherwise updateCookingSession will blow away any pending updates
        // by setting it here, the version check in updateCookingSession will cause the
        // item to be skipped
        const newItem = action.payload.updatedItem.selectedInstructions[action.payload.userId] ?? current.item;
        updateSucceeded(current, newItem, "cookingSessionInstruction");

        updateCookingSession(state, action.payload.updatedItem);
      }
    ),

    recipeTimerStarted: create.reducer((state, action: PayloadAction<CreateRecipeInstructionTimerArgs>) => {
      if (state.timers.ids.includes(action.payload.id)) {
        log.error(`Timer with duplicate ID ${action.payload.id} attemted to add`);
        return;
      }

      const endTime = adjustEndTime(action.payload.endTime);

      timersAdapter.addOne(state.timers, {
        type: "recipeInstructionTimer",
        id: action.payload.id,
        startTime: action.payload.startTime,
        endTime,
        minSeconds: action.payload.minSeconds,
        maxSeconds: action.payload.maxSeconds,
        cookingSessionId: action.payload.cookingSessionId,
        sectionId: action.payload.sectionId,
        instructionId: action.payload.instructionId,
        substringRange: action.payload.substringRange,
      });
    }),

    timerPaused: create.reducer((state, action: PayloadAction<{ id: CookingTimerId; pauseTime: EpochMs }>) => {
      const timer = state.timers.entities[action.payload.id];
      if (!timer) {
        log.error("Attempting to pause a timer that is not in the entity map");
        return;
      }

      timer.pausedTime = action.payload.pauseTime;
    }),

    timerRestarted: create.reducer((state, action: PayloadAction<{ id: CookingTimerId; newEndTime: EpochMs }>) => {
      const timer = state.timers.entities[action.payload.id];
      if (!timer) {
        log.error("Attempting to restart a timer that is not in the entity map");
        return;
      }

      if (!timer.pausedTime) {
        log.error("Attempting to restart a timer that was not puased.");
        return;
      }

      // we used to sort the timers, hence the use of this function (it reevalutes the sort)
      // we dropped that, but this code is working, so leave it.
      timersAdapter.updateOne(state.timers, {
        id: action.payload.id,
        changes: {
          endTime: action.payload.newEndTime,
          pausedTime: undefined,
        },
      });
    }),

    timerEndTimeChanged: create.reducer((state, action: PayloadAction<{ id: CookingTimerId; newEndTime: EpochMs }>) => {
      const timer = state.timers.entities[action.payload.id];
      if (!timer) {
        log.error("Attempting to adjust end time for a timer that is not in the entity map");
        return;
      }

      // we used to sort the timers, hence the use of this function (it reevalutes the sort)
      // we dropped that, but this code is working, so leave it.
      timersAdapter.updateOne(state.timers, {
        id: action.payload.id,
        changes: { endTime: action.payload.newEndTime, alertAcknowledged: undefined },
      });
    }),

    timerDeleted: create.reducer((state, action: PayloadAction<CookingTimerId>) => {
      timersAdapter.removeOne(state.timers, action.payload);
    }),

    timerAcknowledged: create.reducer(
      (state, action: PayloadAction<{ id: CookingTimerId; timeAcknowledged: EpochMs }>) => {
        const timer = state.timers.entities[action.payload.id];
        if (!timer) {
          log.error("Attempting to acknowledge a timer that is not in the entity map");
          return;
        }

        timer.alertAcknowledged = action.payload.timeAcknowledged;
      }
    ),

    focusInstructionRequested: create.reducer(
      (
        state,
        action: PayloadAction<{
          cookingSessionId: CookingSessionId;
          sectionId: RecipeSectionId;
          instructionId: RecipeInstructionId;
        }>
      ) => {
        const { cookingSessionId, sectionId, instructionId } = action.payload;
        state.focusInstructionRequest = {
          cookingSessionId,
          sectionId,
          instructionId,
        };
      }
    ),

    focusInstructionCompleted: create.reducer(state => {
      state.focusInstructionRequest = undefined;
    }),

    timerNotificationsAdded: create.reducer(
      (state, action: PayloadAction<{ timerId: CookingTimerId; notificationIds: LocalNotificationId[] }>) => {
        const { timerId, notificationIds } = action.payload;
        const timer = state.timers.entities[timerId];
        if (!timer) {
          log.error(`Couldn't find timer with ID ${timerId} in timerNotificationAdded`);
          return;
        }

        const existingNotifications = timer.notificationIds ?? [];
        timer.notificationIds = [...existingNotifications, ...notificationIds];
      }
    ),

    timerNotificationsDeleted: create.reducer((state, action: PayloadAction<{ timerId: CookingTimerId }>) => {
      const { timerId } = action.payload;
      const timer = state.timers.entities[timerId];
      if (!timer) {
        // in the timer deletion path, the timer deletion from redux happens in parallel with the call
        // to deleteNotifications. The call to delete notifications dispatches this action, so
        // it's not unexpected to not have the timer exist here.
        return;
      }

      timer.notificationIds = [];
    }),

    audioEnabledChanged: create.reducer((state, action: PayloadAction<{ enabled: boolean }>) => {
      state.audioEnabled = action.payload.enabled;
    }),

    viewedRecipeNotes: create.reducer((state, action: PayloadAction<{ cookingSessionId: CookingSessionId }>) => {
      const cookingSession = state.cookingSessions[action.payload.cookingSessionId];
      if (cookingSession) {
        cookingSession.notesViewed = true;
      }
    }),

    cookingSessionMounted: create.reducer(
      (state, action: PayloadAction<{ cookingSessionId: CookingSessionId; userId: UserId }>) => {
        // Auto-select the first instruction if there is no instruction selected. This makes it clear to
        // new users that instructions are selectable by tapping. It also gives us a way of knowing how many
        // users interacted with the cooking session.
        const cookingSession = state.cookingSessions[action.payload.cookingSessionId];
        if (!cookingSession) {
          return;
        }

        const firstSectionId = cookingSession.sourceRecipe.instructions.sections[0]?.id;
        if (!firstSectionId) {
          return;
        }

        // if we already have a selected instruction, nothing to do
        const instructionSelected = cookingSession.selectedInstructions[action.payload.userId];
        if (instructionSelected) {
          return;
        }

        const clientTs = defaultTimeProvider();

        const selectedFirstInstruction: CookingSessionInstruction = {
          index: 0 as CookingSessionInstructionIndex,
          clientTs,
          sectionId: firstSectionId,
          state: "selected",
        };

        cookingSession.selectedInstructions[action.payload.userId] = {
          item: selectedFirstInstruction,
          itemState: "updateNeeded",
          updates: [{ update: selectedFirstInstruction }],
        };
      }
    ),
  }),

  extraReducers: builder => {
    builder.addCase(userSignedInEvent, (state, action) => {
      state.userId = action.payload.userId;
    });

    builder.addCase(userSignedOutEvent, state => {
      state.userId = undefined;
    });
  },
});

export const {
  activeCookingSessionChanged,
  cookingSessionAdded,
  cookingSessionMounted,
  cookingSessionsRequested,
  cookingSessionsReceived,
  cookingSessionsErrored,
  cookingSessionPushReceived,
  cookingSessionRemoved,
  cookingSessionConflict,
  cookingSessionScaleUpdatedClient,
  cookingSessionUpdateStarted,
  cookingSessionUpdateErrored,
  cookingSessionUpdateSuccess,
  ingredientStatusUpdated,
  ingredientUpdateStarted,
  ingredientUpdateErrored,
  ingredientUpdateSuccess,
  selectedInstructionUpdated,
  selectedInstructionUpdateStarted,
  selectedInstructionUpdateErrored,
  selectedInstructionUpdateSuccess,
  recipeTimerStarted,
  timerPaused,
  timerRestarted,
  timerEndTimeChanged,
  timerDeleted,
  timerAcknowledged,
  focusInstructionRequested,
  focusInstructionCompleted,
  timerNotificationsAdded,
  timerNotificationsDeleted,
  audioEnabledChanged,
  viewedRecipeNotes,
} = cookingSessionsSlice.actions;

export const cookingSessionsReducer = cookingSessionsSlice.reducer;

export function internalIngredientStatusSelector(
  state: CookingSessionState,
  sectionId: RecipeSectionId,
  ingredientIndex: CookingSessionIngredientIndex
): AppCookingSessionIngredient | undefined {
  const ingredientStatus = state.ingredientStatuses[sectionId]?.[ingredientIndex];
  return ingredientStatus;
}

export function internalSelectedInstructionSelector(state: CookingSessionState, userId: UserId) {
  const selectedInstruction = state.selectedInstructions[userId];
  return selectedInstruction;
}

function updateCookingSessions(draft: CookingSessionsState, resp: CookingSession[]) {
  const cookingSessionIds = resp.map(cs => cs.id);

  const diff = setDiff(draft.cookingSessionIds, cookingSessionIds);

  diff.removed.forEach(removedId => removeCookingSession(draft, removedId));
  diff.added.forEach(id => addCookingSession(draft, resp.find(cs => cs.id === id)!));
  diff.intersection.forEach(id => updateCookingSession(draft, resp.find(cs => cs.id === id)!));
}

function updateCookingSession(draft: CookingSessionsState, cookingSession: CookingSession) {
  const current = draft.cookingSessions[cookingSession.id]!;

  if (current.version >= cookingSession.version) {
    return;
  }

  current.status = cookingSession.status;
  current.version = cookingSession.version;

  Object.entries(cookingSession.ingredientStatuses).forEach(i => {
    const [sectionId, ingredients] = i;
    ingredients.forEach((i, idx) =>
      updateIngredientStatus(current, sectionId as RecipeSectionId, idx as CookingSessionIngredientIndex, i)
    );
  });

  Object.entries(cookingSession.selectedInstructions).forEach(i => {
    const [userId, instruction] = i;
    updateSelectedInstruction(current, userId as UserId, instruction);
  });

  if (cookingSession.metaVersion > current.meta.item.version) {
    // this might blow away some updates, but that's okay since they will fail because of the version mismatch
    draft.meta = getMetaFromSession(cookingSession);
  }
}

function updateIngredientStatus(
  draft: CookingSessionState,
  sectionId: RecipeSectionId,
  index: CookingSessionIngredientIndex,
  item: CookingSessionIngredient
) {
  const existing = internalIngredientStatusSelector(draft, sectionId, index);

  if (!existing) {
    const newItem: AppCookingSessionIngredient = {
      item,
      itemState: "persisted",
      updates: [],
    };

    if (draft.ingredientStatuses[sectionId]) {
      draft.ingredientStatuses[sectionId]!.push(newItem);
    } else {
      draft.ingredientStatuses[sectionId] = [newItem];
    }

    return;
  }

  if (item.version > existing.item.version) {
    // we could be blowing away updates here, but it doesn't really matter since we just got a newer
    // version from the server and the condition check would have failed anyway.
    // we know it exists because of the check above, so ! is safe
    draft.ingredientStatuses[sectionId]![index]! = { item, updates: [], itemState: "persisted" };
  }
}

function updateSelectedInstruction(draft: CookingSessionState, userId: UserId, item: CookingSessionInstruction) {
  const existing = internalSelectedInstructionSelector(draft, userId);

  if (!existing) {
    const newItem: AppSelectedInstruction = {
      item,
      itemState: "persisted",
      updates: [],
    };

    draft.selectedInstructions[userId] = newItem;
    return;
  }

  // only set the item if it's newer
  if (existing.item.clientTs < item.clientTs) {
    existing.item = item;
  }
}

function addCookingSession(draft: CookingSessionsState, cookingSession: CookingSession) {
  if (draft.cookingSessionIds.includes(cookingSession.id)) {
    log.error(`CookingSessionSlice addCookingSession was called and the ID, ${cookingSession.id} was already present`);
    return;
  }

  const state: CookingSessionState = {
    ...cookingSession,
    ingredientStatuses: {} as AppCookingSessionIngredientStatuses,
    selectedInstructions: {} as AppSelectedInstructions,
    notesViewed: false,
    meta: getMetaFromSession(cookingSession),
  };

  Object.entries(cookingSession.ingredientStatuses).forEach(c => {
    const [sectionId, ingredients] = c;
    ingredients.forEach((i, idx) =>
      updateIngredientStatus(state, sectionId as RecipeSectionId, idx as CookingSessionIngredientIndex, i)
    );
  });

  Object.entries(cookingSession.selectedInstructions).forEach(c => {
    const [userId, instruction] = c;
    updateSelectedInstruction(state, userId as UserId, instruction);
  });

  draft.cookingSessionIds.push(cookingSession.id);
  draft.cookingSessions[cookingSession.id] = state;

  if (!draft.activeSessionId) {
    draft.activeSessionId = cookingSession.id;
  }
}

function getMetaFromSession(cs: CookingSession): CookingSessionState["meta"] {
  return {
    item: {
      version: cs.metaVersion,
      scale: cs.scale,
      unitConversion: cs.unitConversion,
    },
    updates: [],
    itemState: "persisted",
  };
}

function removeCookingSession(draft: CookingSessionsState, cookingSessionId: CookingSessionId) {
  deleteEntity(cookingSessionId, draft.cookingSessionIds, draft.cookingSessions);

  if (draft.activeSessionId === cookingSessionId) {
    draft.activeSessionId = draft.cookingSessionIds[0];
  }
}

function handleReceivedCookingSession(state: CookingSessionsState, item: CookingSession) {
  const cookingSession = state.cookingSessions[item.id];
  const alreadyExists = !!cookingSession;

  if (alreadyExists) {
    if (item.status === "active") {
      updateCookingSession(state, item);
    } else {
      removeCookingSession(state, item.id);
    }
  } else if (item.status === "active") {
    addCookingSession(state, item);
  }
}

function deleteEntity(id: string, ids: string[], items: { [key: string]: unknown }) {
  deleteFromArray(id, ids);
  delete items[id];
}

function deleteFromArray(id: string, ids: string[]) {
  const index = ids.findIndex(i => i === id);
  if (index !== -1) ids.splice(index, 1);
}

function adjustEndTime(endTime: number): EpochMs {
  // suppose we are setting a 5:00 timer.
  // 1. We want the timer to show 5:00 when the user starts it
  // 2. We want the timer to move to 4:59 within 1 second, but ideally not too fast, which
  //    can seem frenetic.
  // 3. Because we have a "universal" clock that drives all the ticks for all timers
  //    and because that clock ticks on even seconds (epoch ending in 000), we want
  //    the end time to fall between 250 and 750 so that there is little chance of a
  //    missed tick when the clock tick is off by a "normal" amount (50 ms or so).
  const msDelta = endTime % 1000;
  let newEndTime = endTime;

  if (msDelta < 250) {
    // results in 500 - 750
    newEndTime += 500;
  } else if (msDelta < 500) {
    // results in 500-750 with at least 251ms added
    endTime += 750 - msDelta;
  } else {
    // results in 250-749
    endTime += 750;
  }

  return newEndTime as EpochMs;
}
