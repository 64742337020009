import { StyleSheet, View } from "react-native";
import { AlertInterface, AlertButton, AlertStrings } from "./AlertInterfaces";
import RootSibling from "react-native-root-siblings";
import { globalStyleColors } from "../GlobalStyles";
import { Pressable } from "../Pressable";
import { TBody, TTertiary } from "../Typography";
import { switchReturn } from "@eatbetter/common-shared";

/**
 * This web version looks and behaves nearly identical to app modal on iOS.
 * It does not currently take into account web browser back / forward - we'll
 * need to build this if we want to use this on web in a customer-facing fashion
 * down the line.
 */
export const Alert: AlertInterface = {
  alert(title: string, message: string, buttons: [AlertButton, ...AlertButton[]]) {
    let alert: RootSibling | undefined = undefined;

    const closeAlert = () => alert?.destroy();

    const onPressAlertButton = (idx: number) => {
      closeAlert();
      buttons[idx]?.onPress();

      // Enable scrolling when alert is closed
      document.body.style.overflow = "auto";
    };

    // Disable scrolling when alert is shown
    document.body.style.overflow = "hidden";

    alert = new RootSibling(
      (
        <AlertComponent
          title={title}
          message={message}
          buttons={buttons}
          onPressAlertButton={onPressAlertButton}
          onPressBackground={closeAlert}
        />
      )
    );
  },
};

const AlertComponent = (props: {
  title: string;
  message: string;
  buttons: [AlertButton, ...AlertButton[]];
  onPressAlertButton: (idx: number) => void;
  onPressBackground: () => void;
}) => {
  return (
    <Pressable style={styles.container} onPress={props.onPressBackground} noFeedback>
      <Pressable style={styles.alert} onPress={() => {}} noFeedback>
        <AlertText title={props.title} message={props.message} />
        <AlertButtons buttons={props.buttons} onPressAlertButton={props.onPressAlertButton} />
      </Pressable>
    </Pressable>
  );
};

const AlertText = (props: { title: string; message: string }) => {
  return (
    <View style={styles.alertText}>
      <TBody fontWeight="medium">{props.title}</TBody>
      <TTertiary align="center">{props.message}</TTertiary>
    </View>
  );
};

const AlertButtons = (props: {
  buttons: [AlertButton, ...AlertButton[]];
  onPressAlertButton: (idx: number) => void;
}) => {
  const [button0, button1] = props.buttons;

  return (
    <View style={styles.twoButtons}>
      <AlertButtonComponent alertButton={button0} idx={0} onPress={props.onPressAlertButton} />
      {!!button1 && <AlertButtonComponent alertButton={button1} idx={1} onPress={props.onPressAlertButton} />}
    </View>
  );
};

const AlertButtonComponent = (props: { alertButton: AlertButton; idx: number; onPress: (idx: number) => void }) => {
  const onPress = () => {
    props.onPress(props.idx);
  };

  const text = switchReturn(props.alertButton.type, {
    delete: props.alertButton.text ?? AlertStrings.delete,
    cancel: props.alertButton.text ?? AlertStrings.cancel,
    discard: props.alertButton.text ?? AlertStrings.discard,
    save: props.alertButton.text ?? AlertStrings.save,
  });

  const color = switchReturn(props.alertButton.type, {
    delete: globalStyleColors.colorAccentWarm,
    cancel: globalStyleColors.colorAccentCool,
    discard: globalStyleColors.colorAccentWarm,
    save: globalStyleColors.colorAccentCool,
  });

  return (
    <Pressable style={styles.button} onPress={onPress}>
      <TBody fontWeight="medium" color={color} align="center">
        {text}
      </TBody>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    // @ts-ignore: on web we want fixed positioning and react-native-web translates this properly, but the value does not exist on the
    // RN StyleSheet type definition
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: globalStyleColors.rgba("black", "light"),
    justifyContent: "center",
    alignItems: "center",
  },
  alert: {
    maxHeight: 200,
    width: 280,
    borderRadius: 20,
    backgroundColor: globalStyleColors.colorGreyLight,
  },
  alertText: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 24,
  },
  twoButtons: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    overflow: "hidden",
    borderColor: globalStyleColors.rgba("black", "xlight"),
    borderTopWidth: 1,
  },
  button: {
    flex: 1,
    padding: 12,
    alignItems: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    borderColor: globalStyleColors.rgba("black", "xlight"),
  },
});
