import { Envelope, UserId } from "@eatbetter/common-shared";
import { SetWaitingHandler } from "@eatbetter/ui-shared";
import { SearchUsersArgs, UserErrorTypes } from "@eatbetter/users-shared";
import { ThunkAction } from "../AdminRedux";
import {
  AdminGetUserResult,
  AdminSearchUsersResult,
  AdminSetUserFullAccessArgs,
} from "@eatbetter/users-shared/dist/UserAdminTypes";
import { AdminUserOverview } from "@eatbetter/composite-shared";

export const getUser = (userId: UserId, setWaiting?: SetWaitingHandler): ThunkAction<AdminGetUserResult> => {
  return async (_dispatch, _getState, deps) => {
    const resp = await deps.api.withThrow(setWaiting).getUser({ userId });
    return resp.data;
  };
};

export const getUserOverview = (userId: UserId, setWaiting?: SetWaitingHandler): ThunkAction<AdminUserOverview> => {
  return async (_dispatch, _getState, deps) => {
    const resp = await deps.api.withThrow(setWaiting).getUserOverview({ userId });
    return resp.data;
  };
};

export const searchUsers = (
  args: SearchUsersArgs,
  setWaiting?: SetWaitingHandler
): ThunkAction<Envelope<AdminSearchUsersResult, UserErrorTypes>> => {
  return async (_dispatch, _getState, deps) => {
    return deps.api.withReturn(setWaiting).searchUsers(args);
  };
};

export const setUserFullAccess = (
  args: AdminSetUserFullAccessArgs,
  setWaiting?: SetWaitingHandler
): ThunkAction<void> => {
  return async (_dispatch, _getState, deps) => {
    await deps.api.withThrow(setWaiting).setUserFullAccess(args);
  };
};
