import { useScreen } from "../../navigation/ScreenContainer";
import { ScreenView } from "../ScreenView";
import { useCallback, useEffect, useRef } from "react";
import { TextInputHandle } from "../TextInput";
import { Spacer } from "../Spacer";
import React from "react";
import { Alert } from "../Alert/Alert";
import { FormInput, FormInputProps } from "../FormInput";
import { FormValidationError } from "../FormValidationError";
import { TBody, TSecondary } from "../Typography";
import { InputAccessoryView } from "../InputAccessoryView";
import { BottomActionBar } from "../BottomActionBar";
import { SupportLink } from "../SupportComponents";
import { View } from "react-native";
import { globalStyleColors, globalStyleConstants } from "../GlobalStyles";
import { charLength } from "@eatbetter/common-shared";

const strings = {
  save: "Save",
  oldPostsRemainUnchanged: (label: string) =>
    `Your updated ${label} will be used for new posts, but old posts will remain unchanged.`,
  alert: {
    title: "Discard changes?",
    subTitle: "If you go back now, you will lose your changes.",
    primary: "Discard changes",
    secondary: "Keep editing",
  },
  contactUs: {
    subject: "Please contact us at",
    address: "help@deglaze.app",
    predicate: "if you'd like to change your",
  },
};

export function useProfileEditGoBackHandler(isDirty: boolean) {
  const screen = useScreen();
  const onPressBack = useCallback(() => {
    if (isDirty) {
      Alert.alert(strings.alert.title, strings.alert.subTitle, [
        {
          type: "discard",
          text: strings.alert.primary,
          onPress: () => screen.nav.goBack(),
        },
        {
          type: "cancel",
          text: strings.alert.secondary,
          onPress: () => {},
        },
      ]);

      return;
    }

    screen.nav.goBack();
  }, [isDirty, screen.nav.goBack]);

  return onPressBack;
}

interface Props
  extends Pick<
    FormInputProps,
    | "autoCapitalize"
    | "isReadOnly"
    | "keyboardType"
    | "showClearButton"
    | "textContentType"
    | "validationState"
    | "multiline"
    | "showIsValidIcon"
    | "label"
    | "value"
    | "onChangeText"
  > {
  screenHeader: string;
  isDirty: boolean;
  onSubmit: () => void;
  errorMessage?: string;
  waiting?: boolean;
  isPostMetadata: boolean;
  showCharLimit?: number;
}

export const ProfileEditFieldScreenComponent = React.memo((props: Props) => {
  const inputRef = useRef<TextInputHandle>(null);

  const onPressBack = useProfileEditGoBackHandler(props.isDirty);

  const onSubmit = useCallback(() => {
    props.onSubmit();
  }, [props.onSubmit]);

  useEffect(() => {
    setTimeout(() => inputRef.current?.focus(), 650);
  }, []);

  return (
    <ScreenView
      scrollView={false}
      header={{
        type: "custom",
        title: props.screenHeader,
        onPressBack,
        disableBack: props.waiting,
        backgroundColor: "transparent",
      }}
    >
      <FormInput
        ref={inputRef}
        label={props.label}
        value={props.value ?? ""}
        onChangeText={props.onChangeText}
        onSubmitEditing={onSubmit}
        autoCapitalize={props.autoCapitalize}
        isReadOnly={props.isReadOnly ? true : props.waiting}
        keyboardType={props.keyboardType}
        showClearButton={props.isReadOnly ? false : props.showClearButton}
        textContentType={props.textContentType}
        validationState={props.validationState}
        showIsValidIcon={props.showIsValidIcon}
        multiline={props.multiline}
      />
      {!props.isReadOnly && props.isPostMetadata && (
        <>
          <Spacer vertical={1.5} />
          <TSecondary opacity="medium">{strings.oldPostsRemainUnchanged(props.label.toLowerCase())}</TSecondary>
        </>
      )}
      {!!props.errorMessage && (
        <>
          <Spacer vertical={1.5} />
          <FormValidationError message={props.errorMessage} />
        </>
      )}
      {!props.errorMessage && !!props.isReadOnly && (
        <>
          <Spacer vertical={1.5} />
          <TSecondary opacity="medium">
            <TSecondary>{strings.contactUs.subject}</TSecondary>
            <TSecondary> </TSecondary>
            <SupportLink />
            <TSecondary> </TSecondary>
            <TSecondary>{`${strings.contactUs.predicate} ${props.label.toLowerCase()}.`}</TSecondary>
          </TSecondary>
        </>
      )}
      <InputAccessoryView backgroundColor="transparent">
        {!props.isReadOnly && (
          <>
            {!!props.showCharLimit && (
              <View style={{ paddingHorizontal: globalStyleConstants.defaultPadding, alignItems: "flex-end" }}>
                <TBody color={props.validationState === "invalid" ? globalStyleColors.colorError : undefined}>
                  {props.showCharLimit - charLength(props.value)}
                </TBody>
              </View>
            )}
            <BottomActionBar
              disableSnapToBottom
              containerBackgroundColor="transparent"
              primaryAction={{
                actionText: strings.save,
                onPressAction: onSubmit,
                disabled: !props.value || !props.isDirty || props.validationState !== "valid",
                waiting: props.waiting,
                singlePress: true,
              }}
            />
          </>
        )}
      </InputAccessoryView>
    </ScreenView>
  );
});
