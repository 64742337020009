import { EpochMs } from "@eatbetter/common-shared";
import { log } from "../../Log";
import { RootState } from "./RootReducer";

export interface ServerData<T> {
  status?: "loading" | "idle" | "error";
  /**
   * The timestamp of the *start* time of the request that last updated the data
   */
  lastUpdated?: EpochMs;

  /**
   * The timestamp of the start time of the most recently attempted request, regardless
   * of status or outcome.
   */
  lastAttempt?: EpochMs;

  /**
   * The number of successive errors fetching. Reset to undefined upon successful fetch.
   */
  errorCount?: number;

  data?: T;
}

export interface ReceivedServerData<T> {
  startTime: EpochMs;
  data: T;
}

/**
 * Intended only for use with reducers using immer since this mutates s;
 * The timestamp is passed in to allow for the possibility of multiple outstanding requests.
 */
export function serverDataRequested<T>(s: ServerData<T>, startTime: EpochMs) {
  s.status = "loading";
  s.lastAttempt = startTime;
}

/**
 * Intended only for use with reducers using immer since this mutates.
 * @param s
 * @param received
 * @param updateDataIf - In some cases, the data might be unchanged from the previous version (for example, the version has not changed).
 *                       This predicate can be used to determine if the data should be updated. Note that the *metadata* is updated regardless.
 */
export function serverDataReceived<T>(
  s: ServerData<T>,
  received: ReceivedServerData<T>,
  updateDataIf?: (a: { current: T; previous: T }) => boolean
): void {
  if (received.startTime < (s.lastUpdated ?? 0)) {
    // if we have a more recent response, don't touch it
    log.info(
      `Ignoring server data received because there is data that is ${s.lastUpdated ?? 0 - received.startTime}ms newer`
    );
    return;
  }

  s.lastUpdated = received.startTime;
  s.status = "idle";
  s.errorCount = undefined;

  const doUpdate = s.data === undefined || (updateDataIf?.({ current: received.data, previous: s.data }) ?? true);

  if (doUpdate) {
    s.data = received.data;
  }
}

export function serverDataErrored<T>(s: ServerData<T>) {
  s.status = "error";
  s.errorCount = (s.errorCount ?? 0) + 1;
}

export function isLoading(
  description: string,
  state: RootState,
  serverDataOrSelector: ServerData<unknown> | ((s: RootState) => ServerData<unknown>),
  loadingStaleThresholdSeconds = 11,
  logIfStale = true
) {
  // much of this is duplicated in DeferredAction.ts
  // Refactoring for both cases is probably not worth it as the logic might
  // diverge.

  const serverData = typeof serverDataOrSelector === "object" ? serverDataOrSelector : serverDataOrSelector(state);

  if (!serverData) {
    log.error(`No server data found for ${description}. This should not happen.`);
    // if this happens, our state is hosed, but log and hope for the best.
    return false;
  }

  if (serverData.status === "loading" && serverData.lastAttempt) {
    const threshold = serverData.lastAttempt + loadingStaleThresholdSeconds * 1000;
    const stale = state.system.time > threshold;

    if (stale && logIfStale) {
      log.warn(`Stale loading status identified for ${description}.`, {
        lastAttempt: serverData.lastAttempt,
        now: state.system.time,
      });
    }

    return !stale;
  }

  return false;
}
