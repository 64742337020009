import * as Notifications from "expo-notifications";
import { PushTokenValue, ReceivedNotificationData } from "@eatbetter/users-shared";
import { log } from "../Log";
import { SyncThunkAction, ThunkAction } from "./redux/Redux";
import { Linking, Platform } from "react-native";
import { setNotificationHandler } from "expo-notifications";
import { loadNewNotifications } from "./notifications/NotificationsThunks";
import { notificationTapped, PushPermission, pushPermissionUpdated, userPromptedForPush } from "./system/SystemSlice";
import { NotificationContext } from "./NotificationHandlers";
import { reportPushPermissionChanged, reportPushPermissionScreenDisplayed } from "./analytics/AnalyticsEvents";
import { analyticsEvent } from "./analytics/AnalyticsThunks";
import { NotificationPermissionProps } from "../components/recipes/NotificationPermission";

/**
 * Set up the notification handlers. This function does not prompt the user for push notifications.
 */
export const subscribeForPushNotifications = (): ThunkAction<void> => {
  return async (dispatch, _getState, _deps) => {
    log.info("subscribeForPushNotifications called.");

    if (Platform.OS === "web") {
      log.info("NYI: Push notifications not supported on web.");
      return;
    }

    //
    //NOTE: app/App.tsx has the subscription for background notifications.
    //

    // this is only relevant while the app is foregrounded
    // just reload notifications and don't show any system notification ui/sounds
    log.info("Calling setNotificationHandler for pushes received in foreground");
    setNotificationHandler({
      handleNotification: async () => {
        log.info("Got notification while the app is foregrounded. Loading new notifications");
        dispatch(loadNewNotifications()).catch(err => {
          log.errorCaught("Unexpected error in loadNewNotifications called from handleNotification", err);
        });

        return {
          shouldShowAlert: false,
          shouldPlaySound: false,
          shouldSetBadge: false,
        };
      },
    });

    // Get and persist push token whenever a token is rolled while the app is open
    log.info("Adding push token listener to persist token");
    Notifications.addPushTokenListener(token => {
      log.info("New push token issued while app is in foreground.", { token });
      dispatch(persistDevicePushToken(token)).catch(err =>
        log.errorCaught("Error dispatching persistDevicePushToken from listener", err, { token })
      );
    });

    // Subscribe to incoming notifications while the app is foregrounded
    log.info("Adding push addNotificationReceivedListener");
    Notifications.addNotificationReceivedListener(notification => {
      log.info("Push notification received in foreground", { notification });
    });

    // Subscribe to user responses to notifications
    log.info("Adding push addNotificationResponseReceivedListener");
    Notifications.addNotificationResponseReceivedListener(response => {
      log.info("Notification tapped", { response });
      if (response.actionIdentifier === Notifications.DEFAULT_ACTION_IDENTIFIER) {
        // Local and remote notifications have a different shape and the types don't seem to reflect this
        //
        // Sample LOCAL payload:
        //{
        //   "actionIdentifier": "expo.modules.notifications.actions.DEFAULT",
        //   "notification": {
        //     "date": 1670980552.0036201,
        //     "request": {
        //       "content": {
        //         "summaryArgumentCount": 0,
        //         "targetContentIdentifier": null,
        //         "threadIdentifier": "",
        //         "attachments": [],
        //         "categoryIdentifier": "",
        //         "summaryArgument": "",
        //         "data": {
        //           "foo": "bar"
        //         },
        //         "title": "Timer Complete ⏲",
        //         "subtitle": null,
        //         "badge": null,
        //         "launchImageName": "",
        //         "sound": null,
        //         "body": "Timer Test"
        //       },
        //       "identifier": "nV4hk3OXye3aaZlW_Av5iAmVpnm-OjbjC_iPSj2BuMVxyfqpbC_9-1670980552000",
        //       "trigger": {
        //         "seconds": 4.6702799797058105,
        //         "class": "UNTimeIntervalNotificationTrigger",
        //         "type": "timeInterval",
        //         "repeats": false
        //       }
        //     }
        //   }
        // }
        //
        //
        // Sample REMOTE payload - note data is null in notification.request.content.data, but defined
        // in notification.trigger.payload.data
        // {
        //   "actionIdentifier": "expo.modules.notifications.actions.DEFAULT",
        //   "notification": {
        //     "date": 1666933885.0994904,
        //     "request": {
        //       "content": {
        //         "summaryArgumentCount": 0,
        //         "targetContentIdentifier": null,
        //         "threadIdentifier": "",
        //         "attachments": [],
        //         "categoryIdentifier": "",
        //         "summaryArgument": null,
        //         "data": null,
        //         "title": "New Comment 💬",
        //         "subtitle": null,
        //         "badge": 4,
        //         "launchImageName": "",
        //         "sound": null,
        //         "body": "Eat Better commented on your post for \"Stuffed Shells\""
        //       },
        //       "identifier": "BFC84E82-7487-40D0-8CBC-697E4C3FB51C",
        //       "trigger": {
        //         "payload": {
        //           "data": {
        //             "commentUserId": "sAbP2Oz6clh8fl1VjSwJPIDwqIm2",
        //             "commentId": "c:1666933882938:Aq0jZjj0V9z1Ex4B",
        //             "postId": "_post_a_1666932759143_PX57-z0XurB-EYYi"
        //           },
        //           "type": "social/postComment",
        //           "aps": {
        //             "content-available": 1,
        //             "alert": {
        //               "title": "New Comment 💬",
        //               "body": "Eat Better commented on your post for \"Stuffed Shells\""
        //             },
        //             "badge": 4
        //           },
        //           "idempotencyId": "comment__post_a_1666932759143_PX57-z0XurB-EYYi_Aq0jZjj0V9z1Ex4B"
        //         },
        //         "type": "push",
        //         "class": "UNPushNotificationTrigger"
        //       }
        //     }
        //   }
        // }

        const payload: any =
          response?.notification?.request?.trigger?.type === "push"
            ? (<any>response?.notification?.request?.trigger)?.payload
            : response.notification.request.content.data;
        if (payload) {
          const { data, type, notificationIdempotencyId, notificationTargetUserId } = payload;
          if (data && type && notificationTargetUserId) {
            const d: ReceivedNotificationData<NotificationContext> = {
              data,
              type,
              notificationIdempotencyId,
              notificationTargetUserId,
            };

            log.info("User tapped notification", { data: d });
            dispatch(notificationTapped(d));
          } else {
            log.error("Received push notification missing expected fields", { payload, response });
          }
        } else {
          log.error("No payload in expected loation for notifcation", { response });
        }
      } else {
        log.error(`Unexpected action identifier in notification response handler: ${response.actionIdentifier}`);
      }
    });

    // save the push token at least once per app lifecycle
    // we were not getting reliably notified when the token changed
    await dispatch(getPushPermissionAndUpdateState({ forcePeristToken: true }));
  };
};

export const notificationPermissionsScreenDisplayed = (
  context: NotificationPermissionProps["context"]
): SyncThunkAction<void> => {
  return (dispatch, _getState, _deps) => {
    dispatch(userPromptedForPush());
    const event = reportPushPermissionScreenDisplayed({ context });
    dispatch(analyticsEvent(event));
  };
};

/**
 * Determine push permissions and update the state accordingly.
 */
export const getPushPermissionAndUpdateState = (opts?: { forcePeristToken: boolean }): ThunkAction<PushPermission> => {
  return async (dispatch, getState, _deps) => {
    log.info(`Thunk: getPushPermissionAndUpdateState. Opts: ${JSON.stringify(opts)}`);
    const { status } = await Notifications.getPermissionsAsync();
    const userHasBeenPrompted = status !== "undetermined";
    const havePermission = status === "granted";
    const permission = { havePermission, userHasBeenPrompted };
    const current = getState().system.pushPermission;
    log.info(`getPushPermissionAndUpdateState got permssion ${JSON.stringify(permission)}`);
    dispatch(pushPermissionUpdated(permission));

    // we added the force option to call on startup because we seemed to have stopped getting (or never got)
    // reliable notifications when the token changed.
    if (havePermission && (opts?.forcePeristToken || !current?.havePermission)) {
      // do this in the background - not a big deal if it fails, as we should pick it up the next time the pushTokenListener fires
      dispatch(getAndPersistPushToken()).catch(err =>
        log.errorCaught("Error calling getAndPersistPushToken in getPushPermissionAndUpdateState", err)
      );
    }

    // record the change in state if the user has taken action. This can be via the prompt or the settings screen. We detect here instead of
    // in the call to request permissions so that we also catch the settings screen case.
    // We know they have taken action if 1) userHasBeenPrompted changes from false to true - we ignore undefined here as that should only
    // be the case at app startup and 2) userHasBeenPrompted remains true but the permission has changed. I believe this will only be the
    // case if the user toggles the setting in the ios settings screen.
    const justPrompted = current && !current.userHasBeenPrompted && permission.userHasBeenPrompted;
    const changedInSettings =
      current &&
      current.userHasBeenPrompted &&
      permission.userHasBeenPrompted &&
      current.havePermission !== permission.havePermission;

    if (justPrompted && changedInSettings) {
      log.warn("Unexpected state for push notification logic", { justPrompted, changedInSettings });
    }

    if (justPrompted || changedInSettings) {
      const event = reportPushPermissionChanged({
        havePermission: permission.havePermission,
        changedVia: justPrompted ? "Prompt" : "Settings",
      });
      dispatch(analyticsEvent(event));
    }

    return permission;
  };
};

export const requestPushPermissionOrNavToSettings = (): ThunkAction<{
  havePermission: boolean;
  settingsOpened: boolean;
}> => {
  return async (dispatch, _getState, _deps) => {
    const { havePermission, userHasBeenPrompted } = await dispatch(getPushPermissionAndUpdateState());
    if (havePermission) {
      return { havePermission, settingsOpened: false };
    }

    if (!userHasBeenPrompted) {
      const { status } = await Notifications.requestPermissionsAsync({
        ios: {
          allowAlert: true,
          allowBadge: true,
          allowSound: true,
          allowAnnouncements: true,
        },
      });

      const havePermission = status === "granted";
      log.info(
        `User did not have push permissions and had not been prompted. Result of prompting is havePermission: ${havePermission}`
      );

      // update the state;
      await dispatch(getPushPermissionAndUpdateState());

      return { havePermission, settingsOpened: false };
    }

    log.info("User did not have push permissions and has previously denied. Opening settings.");
    await Linking.openSettings();
    log.info("Opened settings screen");

    return { havePermission: false, settingsOpened: true };
  };
};

const getAndPersistPushToken = (): ThunkAction<void> => {
  return async (dispatch, _getState, _deps) => {
    log.info("Thunk: getAndPersistPushToken");

    // the getDevicePushTokenAsync call hangs sometimes, so set a timeout for logging
    const timeoutHandle = setTimeout(() => {
      log.warn("Requested device push token, but didn't receive response in 5 seconds");
    }, 5000);
    const token = await Notifications.getDevicePushTokenAsync().catch(err => {
      log.errorCaught("Error calling getDevicePushTokenAsync", err);
      return undefined;
    });

    clearTimeout(timeoutHandle);
    log.info(`Got push token ${JSON.stringify(token)}`);

    if (token) {
      log.info("Persisting push token");
      //fail silently here. The token should get picked up next time the app is opened.
      dispatch(persistDevicePushToken(token)).catch(err =>
        log.errorCaught("Error dispatching persistDevicePushToken", err, { token })
      );
    }
  };
};

const persistDevicePushToken = (token: Notifications.DevicePushToken): ThunkAction<void> => {
  return async (_dispatch, getState, deps) => {
    log.info("Thunk: persistDevicePushToken");

    const state = getState();
    if (state.system.impersonateUser) {
      // don't persist when impersonating users
      return;
    }

    if (state.system.authStatus !== "signedIn" && state.system.authStatus !== "signedInNoAccount") {
      log.warn("Received push token, but user is not signed in. Cannot persist");
      return;
    }

    try {
      if (!token.data) {
        log.error("persistDevicePushToken called with no token.data");
        return;
      }
      if (token.type !== "ios" && token.type !== "android") {
        log.error(`Not implemented: push notifications for ${token.type} are not supported.`, { token });
        return;
      }

      log.info("Persisting push token: ", { token });
      await deps.api.withThrow().createPushToken({ platform: token.type, value: token.data as PushTokenValue });
    } catch (err) {
      log.errorCaught(`Unexpected error persisting push token. authStatus is ${state.system.authStatus}`, err);
    }
  };
};
