import { useCallback, useMemo, useState } from "react";
import { ScreenView, useScreenElementDimensions } from "../components/ScreenView";
import { Spacer } from "../components/Spacer";
import { TBody, TextProps } from "../components/Typography";
import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { ScrollView, View } from "react-native";
import { SurveyOtherOptionFormScreenProps } from "../navigation/NavTree";
import { HeaderProps } from "../components/ScreenHeaders";
import { Alert } from "../components/Alert/Alert";
import { ButtonRectangle } from "../components/Buttons";
import { log } from "../Log";
import { ParagraphTextInput } from "../components/ParagraphTextInput";
import { globalStyleConstants } from "../components/GlobalStyles";
import { useKeyboardLayoutAnimation } from "../components/Keyboard";
import { bottomActionBarConstants } from "../components/BottomActionBar";
import { smallScreenBreakpoint, useResponsiveDimensions } from "../components/Responsive";

const strings = {
  alert: {
    title: "Discard changes?",
    message: "If you go back now, you will lose your changes.",
    discard: "Discard changes",
    cancel: "Keep editing",
  },
  submit: "Submit",
};

export const SurveyOtherOptionFormScreen = withNonNavigableScreenContainer<SurveyOtherOptionFormScreenProps>(
  "SurveyOtherOptionFormScreen",
  props => {
    const screen = useScreen();
    const [text, setText] = useState("");

    const onPressCancelHeaderButton = useCallback(() => {
      if (text) {
        Alert.alert(strings.alert.title, strings.alert.message, [
          {
            type: "discard",
            text: strings.alert.discard,
            onPress: () => screen.nav.goBack(),
          },
          {
            type: "cancel",
            text: strings.alert.cancel,
            onPress: () => {},
          },
        ]);
        return;
      }

      screen.nav.goBack();
    }, [text, screen.nav.goBack]);

    const onPressSubmit = useCallback(async () => {
      try {
        await props.onSubmit(text);
      } catch (err) {
        // Displaying error to user is handled in calling component
        log.errorCaught("SurveyOtherOptionFormScreen: error calling props.onSubmit", err, { text });
        return;
      }
      screen.nav.goBack();
    }, [text, props.onSubmit, screen.nav.goBack]);

    const header = useMemo<HeaderProps>(() => {
      return {
        type: "native",
        title: props.screenTitle ?? "",
        right: { type: "cancel", onPress: onPressCancelHeaderButton },
        backgroundColor: "white",
      };
    }, [props.screenTitle, onPressCancelHeaderButton]);

    const keyboardHeight = useKeyboardLayoutAnimation();
    const { defaultFontScale } = useResponsiveDimensions();
    const { modalHeaderHeight, bottomTabBarHeight } = useScreenElementDimensions();

    const textCommonProps: Partial<TextProps> = {
      enableFontScaling: "upOnly",
      scale: defaultFontScale,
    };

    return (
      <ScreenView header={header} isModal scrollView={false} paddingVertical={"none"}>
        <ScrollView
          contentContainerStyle={{
            paddingTop: modalHeaderHeight + globalStyleConstants.unitSize,
            paddingBottom:
              Math.max(keyboardHeight, bottomTabBarHeight + bottomActionBarConstants.height) +
              2 * globalStyleConstants.unitSize,
            width: "100%",
            maxWidth: smallScreenBreakpoint,
            alignSelf: "center",
          }}
          keyboardShouldPersistTaps="handled"
          showsVerticalScrollIndicator={false}
        >
          <View style={{ alignSelf: "center" }}>
            <TBody {...textCommonProps} align="center">
              {props.label}
            </TBody>
          </View>
          <Spacer vertical={1} />
          <ParagraphTextInput
            text={text}
            onChangeText={setText}
            placeholderText={props.textInputPlaceholder}
            autofocus
          />
          <Spacer vertical={1.5} />
          <ButtonRectangle
            type="submit"
            size="large"
            title={strings.submit}
            onPress={onPressSubmit}
            disabled={!text.trim()}
          />
        </ScrollView>
      </ScreenView>
    );
  }
);
