import { useScreen, withNonNavigableScreenContainer } from "../../navigation/ScreenContainer";
import { ScreenView } from "../../components/ScreenView";
import { useCallback, useEffect } from "react";
import { useDispatch } from "../../lib/redux/Redux";
import { onboardingQuestionAnswered, userPromptedForPush } from "../../lib/system/SystemSlice";
import { requestPushPermissionOrNavToSettings } from "../../lib/PushNotificationThunks";
import { log } from "../../Log";
import { onboardingScreenHeader } from "../../components/onboarding/OnboardingScreenHeader";
import { Image, View } from "react-native";
import { smallScreenBreakpoint, useResponsiveDimensions } from "../../components/Responsive";
import { OnboardingScreenHeadline } from "../../components/onboarding/OnboardingHeadline";
import { TSecondary } from "../../components/Typography";
import { Spacer } from "../../components/Spacer";
import { ButtonRectangle, TextButton } from "../../components/Buttons";
import { navToNextOnboardingScreen } from "../../navigation/NavThunks";
import { useBottomTabBarDimensions } from "../../navigation/TabBar";
import { globalStyleConstants } from "../../components/GlobalStyles";

const notificationsImage = require("../../assets/onboarding/notifications.png");

const strings = {
  headline: "Can we notify you when cooking timers are complete?",
  subheadline: [
    "Start cooking timers right from your recipe instructions with one tap. We'll notify you when your timers finish.",
    "We'll also notify you when your friends like recipes you've cooked.",
  ],
  yes: "Yes, please",
  no: "Not now",
};

export const OnboardingNotificationsScreen = withNonNavigableScreenContainer("OnboardingNotificationsScreen", () => {
  const { nav } = useScreen();
  const dispatch = useDispatch();
  const { defaultFontScale, width: screenWidth } = useResponsiveDimensions();
  const { bottomTabBarHeight } = useBottomTabBarDimensions();

  useEffect(() => {
    // this sets the timestamp in redux so that we don't display the prompt again on the home screen
    // and we have a record that the user has seen this screen
    dispatch(userPromptedForPush());
  });

  const navNext = useCallback(
    (resp: boolean) => {
      dispatch(onboardingQuestionAnswered({ notificationsPromptResponse: resp }));
      dispatch(navToNextOnboardingScreen(nav, "replace"));
    },
    [dispatch, nav]
  );

  const onYes = useCallback(async () => {
    try {
      await dispatch(requestPushPermissionOrNavToSettings());
    } catch (err) {
      log.errorCaught("Error dispatching requestPushPermissionOrNavToSettings in onboarding", err);
    }

    navNext(true);
  }, [navNext]);

  const onNo = useCallback(() => {
    navNext(false);
  }, [navNext]);

  return (
    <ScreenView scrollView={false} header={onboardingScreenHeader}>
      <OnboardingScreenHeadline
        fontSize={screenWidth < smallScreenBreakpoint ? "h2" : "h1"}
        headline={strings.headline}
        subHeadline={() => (
          <>
            <TSecondary enableFontScaling="upOnly" scale={defaultFontScale}>
              {strings.subheadline[0]}
            </TSecondary>
            <Spacer vertical={1} />
            <TSecondary enableFontScaling="upOnly" scale={defaultFontScale}>
              {strings.subheadline[1]}
            </TSecondary>
          </>
        )}
        paddingBottomUnits={0}
      />
      <NotificationsImage />
      <View
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          bottom: bottomTabBarHeight,
          padding: globalStyleConstants.defaultPadding,
          alignItems: "center",
        }}
      >
        <ButtonRectangle type="submit" size="large" title={strings.yes} onPress={onYes} />
        <Spacer vertical={2} />
        <TextButton text={strings.no} onPress={onNo} bold />
      </View>
    </ScreenView>
  );
});

const NotificationsImage = () => {
  const { height: screenHeight } = useResponsiveDimensions();

  const aspectRatio = 800 / 970;
  const height = 0.5 * screenHeight;
  const width = height * aspectRatio;

  return <Image source={notificationsImage} resizeMode="contain" style={{ alignSelf: "center", width, height }} />;
};
