import { useCallback, useEffect, useRef } from "react";
import { Keyboard, StyleSheet, View } from "react-native";
import { TextInputHandle } from "../TextInput";
import { GroceryListItemId } from "@eatbetter/lists-shared";
import { globalStyleColors, globalStyleConstants } from "../GlobalStyles";
import { Spacer } from "../Spacer";
import { useListItem } from "../../lib/lists/ListsSelectors";
import { GroceryItemTextInput } from "./GroceryItemTextInput";
import { Pressable } from "../Pressable";
import { IconCheck } from "../Icons";
import { useGroceryItemTextEdit } from "../../lib/recipes/UseGroceryItemEdit";
import Animated, { FadeIn } from "react-native-reanimated";
import { Spinner } from "../Spinner";
import { GroceryItemCategoryEdit } from "./GroceryItemCategoryEdit";
import { GroceryItemRecipeSource } from "./GroceryItemRecipeSource";
import { GroceryItemAddedBy } from "./GroceryItemAddedBy";
import { useAuthedUser } from "../../lib/system/SystemSelectors";

const config = {
  paddingTop: globalStyleConstants.unitSize,
  paddingBottom: globalStyleConstants.unitSize,
};

interface Props {
  itemId: GroceryListItemId;
  onDismiss: () => void;
}

export const GroceryListItemEdit = (props: Props) => {
  const item = useListItem(props.itemId);
  const inputRef = useRef<TextInputHandle>(null);

  const authedUser = useAuthedUser();
  const addedByOtherHouseholdUser =
    authedUser && item.addedBy !== authedUser.userId
      ? authedUser.household.find(i => i.userId === item.addedBy)
      : undefined;

  const { text, setText, status } = useGroceryItemTextEdit(props.itemId);
  const initialText = useRef(text);

  useEffect(() => {
    const initialTextValue = initialText.current;

    return () => {
      if (status === "error") {
        setText(initialTextValue);
      }
    };
  }, []);

  const renderStatusIcon = useCallback(() => {
    return (
      <>
        {status === "saved" && (
          <Animated.View entering={FadeIn}>
            <IconCheck strokeWidth={2} color={globalStyleColors.colorAccentCool} opacity="opaque" />
            <Spacer vertical={0.25} />
          </Animated.View>
        )}
        {status === "pending" && (
          <Animated.View entering={FadeIn}>
            <Spinner color="accentCool" />
            <Spacer vertical={0.25} />
          </Animated.View>
        )}
      </>
    );
  }, [status]);

  return (
    <Pressable noFeedback onPress={Keyboard.dismiss} style={styles.container}>
      <GroceryItemTextInput
        ref={inputRef}
        inputText={text}
        onChangeText={setText}
        renderSavedIcon={renderStatusIcon}
        isErrorState={status === "error"}
        showClearButton={false}
        multiline
        blurOnSubmit
      />
      <Spacer vertical={2.5} />
      <View>
        <GroceryItemCategoryEdit itemId={item.id} />
        {addedByOtherHouseholdUser && (
          <>
            <Spacer vertical={2.5} />
            <GroceryItemAddedBy addedByUser={addedByOtherHouseholdUser} />
          </>
        )}
        <Spacer vertical={2.5} />
        {item.type === "recipe" && <GroceryItemRecipeSource item={item} />}
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: globalStyleConstants.defaultPadding,
    paddingTop: config.paddingTop,
    paddingBottom: config.paddingBottom,
  },
});
