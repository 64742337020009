import React, { useCallback, useEffect, useRef } from "react";
import { Pressable } from "./Pressable";
import { TextInput, TextInputHandle } from "./TextInput";
import { globalStyleConstants, globalStyles } from "./GlobalStyles";
import { StyleSheet } from "react-native";
import { useResponsiveDimensions } from "./Responsive";

interface Props {
  text: string;
  onChangeText: (v: string) => void;
  placeholderText: string;
  autofocus?: boolean;
}

export const ParagraphTextInput = React.memo((props: Props) => {
  const inputRef = useRef<TextInputHandle>(null);
  const { defaultFontScale } = useResponsiveDimensions();

  useEffect(() => {
    if (props.autofocus) {
      setTimeout(() => inputRef.current?.focus(), 650);
    }
  }, [props.autofocus]);

  const onPressTextInput = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <Pressable onPress={onPressTextInput} noFeedback style={styles.textInput}>
      <TextInput
        minHeight={84}
        ref={inputRef}
        value={props.text}
        onChangeText={props.onChangeText}
        multiline
        noBorder
        backgroundColor="transparent"
        placeholderText={props.placeholderText}
        fontScale={defaultFontScale}
      />
    </Pressable>
  );
});

const styles = StyleSheet.create({
  textInput: {
    maxHeight: 148,
    paddingHorizontal: globalStyleConstants.minPadding,
    paddingVertical: globalStyleConstants.minPadding,
    borderRadius: globalStyleConstants.defaultPadding,
    backgroundColor: "white",
    ...globalStyles.shadowTextInput,
  },
});
