import { AdminRecipesScreenIdNav } from "./AdminRecipesScreen";
import { useStringParam } from "../lib/ParamUtils";
import { RecipeId } from "@eatbetter/recipes-shared";
import { useIdempotentId, Spacer, FlexedSpinner, THeading1 } from "@eatbetter/ui-shared";
import { useAdminFetchRecipe } from "../lib/useAdminFetchRecipe";
import { useState } from "react";
import { AdminScreenView } from "../components/AdminScreenView";
import { EditExternalUrlRecipe } from "../components/EditExternalUrlRecipe";
import { Link } from "react-router-dom";
import { EditExternalBookRecipe } from "../components/EditExternalBookRecipe";
import { EditUserRecipe } from "../components/EditUserRecipe";

const recipeIdParam = ":recipeId";
export const AdminRecipeEditScreenNav = {
  getPath: (id?: string) => {
    return `${AdminRecipesScreenIdNav.getPath(id ?? recipeIdParam)}/edit`;
  },
};

export const AdminRecipeEditScreen = () => {
  const id = useStringParam<RecipeId>(recipeIdParam).required();
  const [waiting, setWaiting] = useState(false);

  // since the edit components hold their own state, we need to use a key to replace them once a save/cancel
  // happens. We call refreshToken in the edit component's onDone call and then use the token in the key
  const [token, refreshToken] = useIdempotentId();
  const recipe = useAdminFetchRecipe(id, setWaiting, token);
  const key = `${id}${recipe?.recipe.version}${token}`;

  const onDone = () => {
    refreshToken();
    alert("Saved!");
  };

  return (
    <AdminScreenView>
      <THeading1 fontWeight="medium">
        Edit recipe <Link to={AdminRecipesScreenIdNav.getPath(id)}>{id}</Link>
      </THeading1>
      <Spacer vertical={2} />
      {waiting && <FlexedSpinner />}
      {recipe?.recipe.type === "externalUrlRecipe" && (
        <EditExternalUrlRecipe
          initialRecipe={recipe.recipe}
          parsedIngredients={recipe.parsedIngredients}
          tagManifest={recipe.tagManifest}
          onDone={onDone}
          key={key}
        />
      )}
      {recipe?.recipe.type === "externalBookRecipe" && (
        <EditExternalBookRecipe
          initialRecipe={recipe.recipe}
          parsedIngredients={recipe.parsedIngredients}
          tagManifest={recipe.tagManifest}
          onDone={onDone}
          key={key}
        />
      )}
      {recipe?.recipe.type === "userRecipe" && <EditUserRecipe initialRecipe={recipe.recipe} onDone={onDone} />}
    </AdminScreenView>
  );
};
