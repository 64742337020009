import {
  DataEnvelope,
  Envelope,
  ErrorData,
  responseHeaderNames,
  StructuredError,
  switchReturn,
} from "@eatbetter/common-shared";
import axiosLib, { AxiosInstance } from "axios";
import { ConfigEnvironment } from "../CurrentEnvironment";
import { log } from "../Log";
import { MetaHeaders } from "@eatbetter/common-shared";
import { SetWaitingHandler } from "./Types";

export type ErrorStrategy = "throwOnError" | "returnErrorEnvelope";
export type AppEnvelope<T extends ErrorStrategy, TData, TError extends ErrorData = ErrorData> = "throwOnError" extends T
  ? DataEnvelope<TData>
  : Envelope<TData, TError>;

export interface ApiUrls {
  gateway: string;
  items: string;
  photos: string;
  //cdn: string;
  gatewayWebsocket: string;
  adminGateway: string;
}

export function getUrlsForEnv(env: ConfigEnvironment): ApiUrls {
  return switchReturn(env, {
    local: {
      gateway: "http://localhost:3000/local",
      items: "http://localhost:3010/local",
      photos: "http://localhost:3030/local",

      gatewayWebsocket: "ws://localhost:3001/local",
      adminGateway: "http://localhost:3030/local",
    },
    dev: {
      gateway: "https://gateway.mooklab-dev.link/v1",
      items: "https://items.mooklab-dev.link/v1",
      photos: "https://photos.mooklab-dev.link/v1",
      gatewayWebsocket: "wss://gateway-ws.mooklab-dev.link/v1",
      adminGateway: "https://admin-gateway.mooklab-dev.link/v1",
    },
    prod: {
      gateway: "https://gateway.deglaze.app/v1",
      items: "https://items.deglaze.app/v1",
      photos: "https://photos.deglaze.app/v1",
      gatewayWebsocket: "wss://gateway-ws.deglaze.app/v1",
      adminGateway: "https://admin-gateway.deglaze.app/v1",
    },
  });
}

export interface ApiClientFactory<
  TReturn extends ApiClientBase<"returnErrorEnvelope">,
  TThrow extends ApiClientBase<"throwOnError">
> {
  /**
   * API will throw if an error envelope is returned. Caller does not need to check for existence of data.
   */
  withThrow(waitingHandler?: (waiting: boolean) => void): TThrow;

  /**
   * API will return an error envelope if status code is 418 (all errors with useful info will be status code 418)
   * Caller must check that data exists to determine call success
   */
  withReturn(waitingHandler?: (waiting: boolean) => void): TReturn;
}

export function getApiClientFactory<
  TReturn extends ApiClientBase<"returnErrorEnvelope">,
  TThrow extends ApiClientBase<"throwOnError">
>(opts: {
  returnFactory: (waitingHandler?: SetWaitingHandler) => TReturn;
  throwFactory: (waitingHandler?: SetWaitingHandler) => TThrow;
}): ApiClientFactory<TReturn, TThrow> {
  return {
    withReturn(waitingHandler?: SetWaitingHandler): TReturn {
      return opts.returnFactory(waitingHandler);
    },
    withThrow(waitingHandler?: SetWaitingHandler): TThrow {
      return opts.throwFactory(waitingHandler);
    },
  };
}

type Service = keyof ApiUrls;

export interface ApiClientConstructorArgs {
  urls: ApiUrls;
  getToken: () => Promise<string | undefined>;
  errorEnvelopeStrategy: ErrorStrategy;
  waitingHandler?: (waiting: boolean) => void;
  metaHeaders?: MetaHeaders;
}

let axiosInstance: AxiosInstance | undefined;

export function getAxiosInstance(): AxiosInstance {
  if (!axiosInstance) {
    axiosInstance = axiosLib.create({
      timeout: 10 * 1000,
      validateStatus: status => {
        // 418 signifies meaningful error data the caller might want to handle
        return status === 200 || status === 418;
      },
    });
  }

  return axiosInstance;
}

/***
 * The API has 2 error handling modes.
 * 1. throwOnError - in this mode, an error is thrown if an ErrorEnvelope is encountered.
 *    In this mode, the return types are DataEnvelopes, so callers don't need to check for errors.
 * 2. returnErrorEnvelope - in this mode, callers must check for errors before using data (or just
 *    check the existence of data to ensure the call succeeded).
 */
export abstract class ApiClientBase<TErrorHandling extends ErrorStrategy> {
  private readonly urls: ApiUrls;
  private readonly getToken: () => Promise<string | undefined>;
  private readonly throwOnErrorEnvelope: boolean;
  private readonly metaHeaders: MetaHeaders;
  private static regionStats: Record<string, number> = {};

  static readonly additionalHeaders: Record<string, string> = {};

  protected readonly axios: AxiosInstance;
  protected readonly waitingHandler: ((waiting: boolean) => void) | undefined;

  protected constructor(opts: ApiClientConstructorArgs) {
    this.throwOnErrorEnvelope = opts.errorEnvelopeStrategy === "throwOnError";
    this.urls = opts.urls;

    this.axios = getAxiosInstance();

    this.getToken = opts.getToken;
    this.waitingHandler = opts.waitingHandler;
    this.metaHeaders = opts.metaHeaders ?? {};
  }

  /**
   * Expose request headers for requests made outside of this client. For example, we use this to generate headers
   * for issue a native background request in ios in the share extension.
   */
  async getRequestHeaders(contentType?: string): Promise<Record<string, string>> {
    const token = await this.getToken();

    const authHeader = token ? { authorization: `Bearer ${token}` } : ({} as {});

    const headers = {
      ...authHeader,
      ...this.metaHeaders,
      ...ApiClientBase.additionalHeaders,
    };

    if (contentType) {
      return {
        ...headers,
        "Content-Type": contentType,
      };
    }

    return headers;
  }

  getUrl(type: keyof ApiUrls): string {
    return this.urls[type];
  }

  /**
   * Kludge to get impersonation header into already created factory. This should be used sparingly. If we have more places we need
   * dynamic headers, we should switch metaHeaders to also take a function in addition to a map.
   * If we clean this up, we should also clean up the impersonateUserAtAuth thunk.
   */
  static addAdditionalMetaHeader(key: string, value: string): void {
    ApiClientBase.additionalHeaders[key] = value;
  }

  /**
   * Kludge to remove impersonation header into already created factory. This should be used sparingly. If we have more places we need
   * dynamic headers, we should switch metaHeaders to also take a function in addition to a map.
   * If we clean this up, we should also clean up the impersonateUserAtAuth thunk.
   */
  static removeAdditionalMetaHeader(key: string): void {
    delete ApiClientBase.additionalHeaders[key];
  }

  static get requestRegionStats(): Record<string, number> {
    return ApiClientBase.regionStats;
  }

  protected async get<TData, TError extends ErrorData = ErrorData>(
    service: Service,
    path: string,
    opts: { log: boolean } = { log: false }
  ): Promise<AppEnvelope<TErrorHandling, TData, TError>> {
    return this.makeRequest<never, TData, TError>("get", service, path, opts);
  }

  protected async post<TPayload, TData, TError extends ErrorData = ErrorData>(
    service: Service,
    path: string,
    payload: TPayload,
    opts: { log: boolean } = { log: false }
  ): Promise<AppEnvelope<TErrorHandling, TData, TError>> {
    return this.makeRequest<TPayload, TData, TError>("post", service, path, opts, payload);
  }

  protected async patch<TPayload, TData, TError extends ErrorData = ErrorData>(
    service: Service,
    path: string,
    payload: TPayload,
    opts: { log: boolean } = { log: false }
  ): Promise<AppEnvelope<TErrorHandling, TData, TError>> {
    return this.makeRequest<TPayload, TData, TError>("patch", service, path, opts, payload);
  }

  /**
   * All logging in this function must respect the opts.log flag to prevent an infinite
   * loop when makign a logging request
   */
  private async makeRequest<TPayload, TData, TError extends ErrorData>(
    method: "get" | "post" | "patch",
    service: Service,
    path: string,
    opts: { log: boolean } = { log: false },
    data?: TPayload
  ): Promise<AppEnvelope<TErrorHandling, TData, TError>> {
    // NOTE: IT'S CRITICAL THAT THIS FUNCTION NOT LOG IF opts.log IS FALSE
    // THE REMOTE LOGGER CALLS THE API AND IF THE API LOGS WHEN IT IS TOLD NOT TO
    // THE REMOTE LOGGER CAN END UP IN AN INFINITE LOOP.
    this.waitingHandler?.(true);

    const url = this.urls[service] + path;

    if (opts.log) {
      log.info(`Start request ${method} ${url}`);
    }

    const headers = await this.getRequestHeaders();

    const resp = await this.axios
      .request({
        url,
        method,
        data,
        headers,
      })
      .catch(err => {
        if (opts.log) {
          log.warn(`End request (Error) ${method} ${url}`, { err });
        }
        throw err;
      })
      .finally(() => {
        this.waitingHandler?.(false);
      });

    if (opts.log) {
      log.info(`End request ${method} ${url} ${resp.status}`, { resp: resp.data });
    }

    ApiClientBase.incrementRegionStats(resp.headers[responseHeaderNames.awsRegionHeader]);
    const env = resp.data as Envelope<TData, TError>;
    if (this.throwOnErrorEnvelope && env.error) {
      if (opts.log) {
        log.warn(`Received error envelope for ${method} ${url}`, { error: env.error });
      }
      throw new StructuredError(env.error);
    }

    return env as AppEnvelope<TErrorHandling, TData, TError>;
  }

  private static incrementRegionStats(region: string | undefined): void {
    if (region === undefined || region.trim() === "") {
      return;
    }

    const key = region.trim();
    if (!ApiClientBase.regionStats[key]) {
      this.regionStats[key] = 0;
    }

    ApiClientBase.regionStats[key] = (ApiClientBase.regionStats[key] ?? 0) + 1;
  }
}
