import { CookingSessionId } from "@eatbetter/cooking-shared";
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Switch, View, StyleSheet, LayoutChangeEvent } from "react-native";
import { AtMentionQuery, AtMentionSelectUser, TextInputWithAtMentions } from "../components/AtMentions";
import { BottomActionBar, bottomActionBarConstants } from "../components/BottomActionBar";
import { globalStyleColors, globalStyleConstants, globalStyles, Opacity } from "../components/GlobalStyles";
import { Haptics } from "../components/Haptics";
import { InputAccessoryView } from "../components/InputAccessoryView";
import { ScreenView, useScreenElementDimensions } from "../components/ScreenView";
import { selectEntityConstants } from "../components/SelectEntity";
import { Spacer } from "../components/Spacer";
import { TSecondary } from "../components/Typography";
import { useCookingSessionRecipeId, useHaveCookingSession } from "../lib/cooking/CookingSessionsSelectors";
import { endCookingSession } from "../lib/cooking/CookingSessionsThunks";
import { useDispatch } from "../lib/redux/Redux";
import { useUserSearchUsers } from "../lib/users/UsersSelectors";
import { SearchUsersContext } from "../lib/users/UsersThunks";
import { EndCookingScreenProps, navTree } from "../navigation/NavTree";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { emptyToUndefined, switchReturn } from "@eatbetter/common-shared";
import { RecipeNotes } from "../components/recipes/RecipeNotes";
import { UserRecipeId } from "@eatbetter/recipes-shared";
import { Separator } from "../components/Separator";
import { Pressable } from "../components/Pressable";
import { TextInputHandle } from "../components/TextInput";
import { SectionHeading } from "../components/SectionHeading";
import { RecipeTags } from "../components/recipes/RecipeTags";
import { RecipeTime } from "../components/recipes/RecipeTime";
import { useKeyboardLayoutAnimation } from "../components/Keyboard";
import { HeaderProps, useHeaderScrollAnimation } from "../components/ScreenHeaders";
import Animated from "react-native-reanimated";
import { navToAnonymousSignin } from "../lib/util/AnonymousSignIn";
import { useCheckpointCompleted, useIsAnonymousUser } from "../lib/system/SystemSelectors";
import { RecipeRatingEdit, RecipeRatingEditProps } from "../components/recipes/RecipeRating";
import { useIsOwnUserRecipe, useRecipeRating } from "../lib/recipes/RecipesSelectors";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { useWalkthrough, WalkthroughStep, WalkthroughStepProps } from "../components/Walkthrough";
import { checkpointsCompleted } from "../lib/system/SystemSlice";

const strings = {
  title: "End Cooking",
  end: {
    save: "End & Save",
    post: "End & Post",
    discard: "End & Discard",
  },
  saveHeader: "Save",
  rating: "Rate this recipe",
  saveOption: "Mark as cooked?",
  saveOrPostAction: "log your cooks",
  recipeHeader: "Private notes",
  postHeader: "Create Post",
  postOption: "Post publicly?",
  comment: "Add a comment",
  optional: "(optional)",
  commentPlaceholder: "How'd it go? Share more about what you made and how it turned out, and use @ to tag someone.",
  walkthrough: {
    saveActivity: "Mark this recipe as cooked in\nyour library",
    createPost: "Share this recipe on your profile and to your followers, with an optional comment",
  },
};

const walkthroughSteps = ["saveActivity", "createPost"] as const;

export const EndCookingSessionScreen = withScreenContainer(
  "EndCookingSessionScreen",
  (props: EndCookingScreenProps) => {
    const dispatch = useDispatch();
    const screen = useScreen();
    const { headerHeight, bottomTabBarHeight } = useScreenElementDimensions();
    const keyboardHeight = useKeyboardLayoutAnimation();
    const isAnonUser = useIsAnonymousUser();
    const haveCookingSession = useHaveCookingSession(props.cookingSessionId);
    const recipeId = useCookingSessionRecipeId(props.cookingSessionId);
    const recipeRating = useRecipeRating(recipeId);
    const isOwnUserRecipe = useIsOwnUserRecipe(recipeId);

    const [waiting, setWaiting] = useState(false);

    const [saveActivity, setSaveActivity] = useState(isAnonUser ? false : true);
    const [publishActivity, setPublishActivity] = useState(!isAnonUser);

    const [comment, setComment] = useState("");
    const [commentCursorPostion, setCommentCursorPostion] = useState(comment.length);
    const [commentAtMentionQuery, setCommentAtMentionQuery] = useState<AtMentionQuery>();
    const [commentFocused, setCommentFocused] = useState(false);

    const [recipeRatingDraft, setRecipeRatingDraft] = useState(recipeRating);

    const onEndWalkthrough = useCallback(() => {
      dispatch(checkpointsCompleted(["endCookingSessionWalkthroughCompleted"]));
    }, [dispatch]);

    const walkthroughCompleted = useCheckpointCompleted("endCookingSessionWalkthroughCompleted");
    const walkthrough = useWalkthrough(
      "End Cooking Screen",
      walkthroughSteps,
      !walkthroughCompleted && screen.nav.focused,
      onEndWalkthrough
    );

    const getWalkthroughStepProps = useCallback(
      (step: "saveActivity" | "createPost"): WalkthroughStepProps => {
        const { message, buttonText } = switchReturn(step, {
          createPost: { message: strings.walkthrough.createPost, buttonText: "gotIt" as const },
          saveActivity: { message: strings.walkthrough.saveActivity, buttonText: "next" as const },
        });

        return {
          show: true,
          message,
          buttonText,
          // Allow toggling if user is registered, but disable if anon otherwise the sign-in flow triggers with the walkthrough still active
          onPressChildComponent: isAnonUser ? () => {} : undefined,
          wobble: false,
          onPressButton: walkthrough.goToNextStep,
        };
      },
      [walkthrough.goToNextStep]
    );

    const saveActivityWalkthroughProps = useMemo(() => {
      return getWalkthroughStepProps("saveActivity");
    }, [getWalkthroughStepProps]);

    const createPostWalkthrougProps = useMemo(() => {
      return getWalkthroughStepProps("createPost");
    }, [getWalkthroughStepProps]);

    // we have a separate ref for this because in the event that a family cooking session is ended by another user and haveCookingSession
    // becomes false, then we don't want to treat this as a save even if saveActivity is true
    const cookingSessionJustSaved = useRef(false);

    const scrollViewRef = useRef<Animated.ScrollView>(null);
    const createPostSectionY = useRef(0);

    // Easy way for now to scroll up on focus of the comment box - we need to go after the keyboard thus the setTimeout
    useEffect(() => {
      if (commentFocused) {
        setTimeout(() => scrollViewRef.current?.scrollTo({ y: createPostSectionY.current }), 300);
      }
    }, [commentFocused]);

    const onCreatePostSectionLayout = useCallback((e: LayoutChangeEvent) => {
      createPostSectionY.current = e.nativeEvent.layout.y;
    }, []);

    const onChangeRating = useCallback<NonNullable<RecipeRatingEditProps["onChangeRating"]>>(
      rating => {
        setRecipeRatingDraft(rating);
      },
      [setRecipeRatingDraft]
    );

    useEffect(() => {
      if (saveActivity && isAnonUser) {
        setSaveActivity(false);
        navToAnonymousSignin(screen.nav, { mode: "action", userVisibleActionDescription: strings.saveOrPostAction });
      }
    }, [saveActivity, screen.nav, isAnonUser, setPublishActivity]);

    useEffect(() => {
      if (!haveCookingSession) {
        screen.nav.goTo("reset", navTree.get.screens.recipesHome, {
          cookingSessionJustSaved: cookingSessionJustSaved.current,
        });
      }
    }, [haveCookingSession]);

    const onPressEndCookingSession = useCallback(async () => {
      try {
        if (saveActivity) {
          cookingSessionJustSaved.current = true;

          await dispatch(
            endCookingSession({
              endArgs: {
                type: "save",
                cookingSessionId: props.cookingSessionId,
                createPost: publishActivity,
                comment: emptyToUndefined(comment),
                rating: recipeRatingDraft,
              },
              setWaiting,
            })
          );

          Haptics.feedback("operationSucceeded");
        } else {
          // Discard
          await dispatch(
            endCookingSession({
              endArgs: {
                type: "discard",
                cookingSessionId: props.cookingSessionId,
              },
              setWaiting,
            })
          );

          Haptics.feedback("itemDeleted");
        }
      } catch (err) {
        displayUnexpectedErrorAndLog("Error caught ending cooking session", err, {
          cookingSessionId: props.cookingSessionId,
          saveActivity,
          haveCookingSession,
        });
        setWaiting(false);
      }
    }, [
      saveActivity,
      dispatch,
      props.cookingSessionId,
      publishActivity,
      comment,
      setWaiting,
      recipeRating,
      recipeRatingDraft,
      haveCookingSession,
    ]);

    const [animationProgress, onScroll] = useHeaderScrollAnimation();

    const paddingTop = headerHeight + globalStyleConstants.unitSize;
    const paddingBottom =
      (keyboardHeight > 0
        ? keyboardHeight + bottomActionBarConstants.height
        : bottomTabBarHeight + bottomActionBarConstants.height) +
      1.5 * globalStyleConstants.unitSize;

    const header: HeaderProps = useMemo(() => {
      return {
        title: strings.title,
        type: "custom",
        animationConfig: {
          animationProgress,
          disableRetract: true,
          blurBackgroundThreshold: globalStyleConstants.unitSize,
        },
      };
    }, [animationProgress]);

    return (
      <ScreenView
        scrollView={false}
        paddingVertical={false}
        paddingHorizontal={false}
        header={header}
        loading={waiting}
      >
        <Animated.ScrollView
          ref={scrollViewRef}
          contentContainerStyle={{ paddingTop, paddingBottom }}
          keyboardDismissMode="none"
          keyboardShouldPersistTaps={commentAtMentionQuery ? "always" : "handled"}
          onScroll={onScroll}
          scrollEventThrottle={16}
        >
          <SaveActivitySection
            label={strings.saveOption}
            value={saveActivity}
            onValueChange={setSaveActivity}
            rating={saveActivity ? recipeRatingDraft : recipeRating}
            onChangeRating={onChangeRating}
            showRecipeRating={!isOwnUserRecipe}
            walkthroughStep={walkthrough.currentStep}
            walkthroughProps={saveActivityWalkthroughProps}
          />
          <Spacer vertical={2} />
          <CreatePostSection
            onLayout={onCreatePostSectionLayout}
            label={strings.postOption}
            value={saveActivity && publishActivity}
            onValueChange={setPublishActivity}
            placeholderText={strings.commentPlaceholder}
            text={comment}
            onChangeText={setComment}
            cursorPosition={commentCursorPostion}
            setCursorPosition={setCommentCursorPostion}
            atMentionQuery={commentAtMentionQuery}
            setAtMentionQuery={setCommentAtMentionQuery}
            searchUserContext={{ type: "newPost" }}
            disabled={!saveActivity}
            focused={commentFocused}
            setFocused={setCommentFocused}
            walkthroughStep={walkthrough.currentStep}
            walkthroughProps={createPostWalkthrougProps}
          />
          <Spacer vertical={2} />
          <RecipeTagsAndNotesSection recipeId={recipeId} />
        </Animated.ScrollView>
        <EndCookingButton
          onPress={onPressEndCookingSession}
          endAction={saveActivity ? (publishActivity ? "post" : "save") : "discard"}
        />
      </ScreenView>
    );
  },
  {
    serializer: {
      cookingSessionId: s => s,
    },
    parser: {
      cookingSessionId: s => s as CookingSessionId,
    },
  }
);

type SaveActivitySectionProps = ToggleOptionProps &
  RecipeRatingItemProps & { showRecipeRating: boolean; walkthroughStep?: (typeof walkthroughSteps)[number] };

const SaveActivitySection = React.memo((props: SaveActivitySectionProps) => {
  return (
    <>
      <SectionHeading text={strings.saveHeader} />
      <Spacer vertical={1} />
      <View style={styles.sectionWrap}>
        <ToggleOption
          label={props.label}
          value={props.value}
          onValueChange={props.onValueChange}
          walkthroughProps={props.walkthroughStep === "saveActivity" ? props.walkthroughProps : undefined}
        />
        {!!props.showRecipeRating && (
          <>
            <SectionItemSeparator />
            <RecipeRatingItem rating={props.rating} onChangeRating={props.onChangeRating} disabled={!props.value} />
          </>
        )}
      </View>
    </>
  );
});

type RecipeRatingItemProps = Pick<RecipeRatingEditProps, "rating" | "onChangeRating"> & { disabled?: boolean };

const RecipeRatingItem = React.memo((props: RecipeRatingItemProps) => {
  return (
    <SectionItem labelText={strings.rating} disabled={props.disabled}>
      <RecipeRatingEdit
        clearable={false}
        rating={props.rating}
        onChangeRating={props.onChangeRating}
        disabled={props.disabled}
      />
    </SectionItem>
  );
});

const RecipeTagsAndNotesSection = React.memo((props: { recipeId?: UserRecipeId }) => {
  return (
    <>
      <SectionHeading text={strings.recipeHeader} />
      <Spacer vertical={1} />
      <View style={styles.sectionWrap}>
        {!!props.recipeId && (
          <RecipeNotes recipeId={props.recipeId} style="compact" location="endCookingSession" noShadow />
        )}
        <SectionItemSeparator />
        {!!props.recipeId && (
          <RecipeTags recipeId={props.recipeId} style="compact" location="endCookingSession" noShadow />
        )}
        <SectionItemSeparator />
        {!!props.recipeId && (
          <RecipeTime recipeId={props.recipeId} style="compact" location="endCookingSession" noShadow />
        )}
      </View>
    </>
  );
});

const CreatePostSection = React.memo(
  (
    props: ToggleOptionProps &
      CommentSectionProps & {
        onLayout: (e: LayoutChangeEvent) => void;
        walkthroughStep?: (typeof walkthroughSteps)[number];
      }
  ) => {
    return (
      <View onLayout={props.onLayout} style={{ zIndex: 999 }}>
        <SectionHeading text={strings.postHeader} />
        <Spacer vertical={1} />
        <View style={styles.sectionWrap}>
          <ToggleOption
            label={props.label}
            value={props.value}
            onValueChange={props.onValueChange}
            disabled={props.disabled}
            walkthroughProps={props.walkthroughStep === "createPost" ? props.walkthroughProps : undefined}
          />
          <SectionItemSeparator />
          <CommentItem
            label={props.label}
            placeholderText={props.placeholderText}
            text={props.text}
            onChangeText={props.onChangeText}
            cursorPosition={props.cursorPosition}
            setCursorPosition={props.setCursorPosition}
            atMentionQuery={props.atMentionQuery}
            setAtMentionQuery={props.setAtMentionQuery}
            searchUserContext={props.searchUserContext}
            disabled={!props.value}
            focused={props.focused}
            setFocused={props.setFocused}
          />
        </View>
      </View>
    );
  }
);

const EndCookingButton = React.memo((props: { onPress: () => void; endAction: "save" | "post" | "discard" }) => {
  const buttonText = switchReturn(props.endAction, {
    save: strings.end.save,
    post: strings.end.post,
    discard: strings.end.discard,
  });

  return (
    <InputAccessoryView snapPoint={{ bottom: "tabBar" }}>
      <BottomActionBar
        primaryAction={{ actionText: buttonText, onPressAction: props.onPress }}
        disableSnapToBottom
        containerBackgroundColor="transparent"
      />
    </InputAccessoryView>
  );
});

interface ToggleOptionProps {
  label: string;
  value: boolean;
  onValueChange: (value: boolean) => void;
  disabled?: boolean;
  walkthroughProps?: WalkthroughStepProps;
}

const ToggleOption = React.memo((props: ToggleOptionProps) => {
  const toggle = (
    <Switch
      disabled={props.disabled}
      value={props.value}
      onValueChange={props.onValueChange}
      trackColor={{ true: globalStyleColors.colorAccentCool }}
    />
  );

  return (
    <SectionItem labelText={props.label} disabled={props.disabled}>
      {!!props.walkthroughProps && <WalkthroughStep {...props.walkthroughProps}>{toggle}</WalkthroughStep>}
      {!props.walkthroughProps && toggle}
    </SectionItem>
  );
});

type SectionItemProps = PropsWithChildren & ItemLabelProps;

const SectionItem = React.memo((props: SectionItemProps) => {
  return (
    <View style={styles.itemWrap}>
      <ItemLabel labelText={props.labelText} disabled={props.disabled} />
      {props.children}
    </View>
  );
});

interface ItemLabelProps {
  labelText: string;
  optional?: boolean;
  disabled?: boolean;
}

const ItemLabel = React.memo((props: ItemLabelProps) => {
  return (
    <TSecondary>
      <TSecondary opacity={props.disabled ? "light" : undefined}>{props.labelText}</TSecondary>
      {!!props.optional && (
        <>
          <TSecondary>{"  "}</TSecondary>
          <TSecondary opacity={"light"}>{strings.optional}</TSecondary>
        </>
      )}
    </TSecondary>
  );
});

interface CommentSectionProps {
  label: string;
  text: string;
  onChangeText: (text: string) => void;
  cursorPosition: number;
  setCursorPosition: (value: number) => void;
  atMentionQuery: AtMentionQuery | undefined;
  setAtMentionQuery: (value: AtMentionQuery | undefined) => void;
  searchUserContext: SearchUsersContext;
  placeholderText: string;
  focused: boolean;
  setFocused: (value: boolean) => void;
  disabled?: boolean;
}

const CommentItem = React.memo((props: CommentSectionProps) => {
  const screen = useScreen();
  const commentInputRef = useRef<TextInputHandle>(null);

  const onBlur = useCallback(() => {
    props.setFocused(false);
  }, [props.setFocused]);

  const onFocus = useCallback(() => {
    props.setFocused(true);
  }, [props.setFocused]);

  const onPressTitle = useCallback(() => {
    commentInputRef.current?.focus();
  }, [commentInputRef]);

  useEffect(() => {
    // If we don't explicitly blur the text input when the user navigates away (i.e. tapping Tags or Time), the text input preserves
    // the focus state and then when the user comes back the keyboard will pop back up again.
    if (!screen.nav.focused) {
      commentInputRef.current?.blur();
    }
  }, [screen.nav.focused]);

  return (
    <Pressable
      style={{ paddingTop: globalStyleConstants.unitSize }}
      onPress={onPressTitle}
      noFeedback
      disabled={props.disabled}
    >
      <View style={{ minHeight: 32, justifyContent: "center", paddingLeft: globalStyleConstants.unitSize }}>
        <ItemLabel labelText={strings.comment} optional disabled={props.disabled} />
      </View>
      <View style={[styles.comment, { opacity: props.disabled ? Opacity.medium : undefined }]}>
        <TextInputWithAtMentions
          ref={commentInputRef}
          value={props.text}
          scrollEnabled={false}
          onChangeText={props.onChangeText}
          cursorPosition={props.cursorPosition}
          setCursorPosition={props.setCursorPosition}
          setAtMentionQuery={props.setAtMentionQuery}
          searchUsersContext={props.searchUserContext}
          placeholderText={props.placeholderText}
          editable={!props.disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          fontSize={"secondary"}
        />
      </View>
      {!!props.focused && (
        <AtMentionSelectUserInline
          text={props.text}
          onChangeText={props.onChangeText}
          atMentionQuery={props.atMentionQuery}
          setAtMentionQuery={props.setAtMentionQuery}
          cursorPosition={props.cursorPosition}
        />
      )}
    </Pressable>
  );
});

const AtMentionSelectUserInline = React.memo(
  (props: {
    text: string;
    onChangeText: (v: string) => void;
    atMentionQuery: AtMentionQuery | undefined;
    setAtMentionQuery: (v: AtMentionQuery | undefined) => void;
    cursorPosition: number;
  }) => {
    const atMentionUserCount = useUserSearchUsers().users.length;

    const verticalPadding = 24; // padding from AtMentionSelectUser
    const singleItemHeight = selectEntityConstants.listItemHeight + verticalPadding;
    const multipleItemHeight =
      2 * selectEntityConstants.listItemHeight + selectEntityConstants.listItemSpacerHeight + verticalPadding;

    const height = atMentionUserCount > 1 ? multipleItemHeight : singleItemHeight;
    const transform = [{ translateY: height - 8 }];

    return (
      <>
        {!!props.atMentionQuery && (
          <View style={[styles.selectUser, { height, transform }]}>
            <AtMentionSelectUser
              presentation={{ type: "inline" }}
              text={props.text}
              setText={props.onChangeText}
              atMentionQuery={props.atMentionQuery}
              setAtMentionQuery={props.setAtMentionQuery}
              cursorPosition={props.cursorPosition}
            />
          </View>
        )}
      </>
    );
  }
);

const SectionItemSeparator = React.memo(() => {
  return (
    <View style={{ paddingLeft: globalStyleConstants.unitSize }}>
      <Separator orientation="row" />
    </View>
  );
});

const styles = StyleSheet.create({
  comment: {
    backgroundColor: "white",
    borderRadius: 20,
    paddingHorizontal: globalStyleConstants.unitSize,
    paddingBottom: 0.5 * globalStyleConstants.unitSize,
    minHeight: 56,
  },
  selectUser: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "white",
    shadowColor: "black",
    shadowOpacity: 0.1,
    shadowRadius: 8,
    shadowOffset: { height: 6, width: 0 },
  },
  itemWrap: {
    minHeight: 52,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "white",
    paddingHorizontal: globalStyleConstants.unitSize,
    paddingVertical: 0.75 * globalStyleConstants.unitSize,
    borderRadius: 20,
  },
  sectionWrap: {
    backgroundColor: "white",
    borderRadius: 20,
    marginHorizontal: 8,
    ...globalStyles.shadowItem,
    shadowOpacity: 0.05,
  },
});
