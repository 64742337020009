import { View } from "react-native";
import { ScreenView } from "../../components/ScreenView";
import { useScreen, withScreenContainer } from "../../navigation/ScreenContainer";
import { ShareExtensionDemoVideo } from "../OnboardShareExtensionScreen";
import { Spacer } from "../../components/Spacer";
import { TBody, TSecondary } from "../../components/Typography";
import { useCallback } from "react";
import { navTree } from "../../navigation/NavTree";
import { TextLink } from "../../components/TextLink";
import { useScreenTimerWithScreenViewEvent } from "../../lib/util/UseScreenTimer";

const strings = {
  headline: "Save Recipes from your Browser",
  firstParagraph: [
    "The easiest way to add recipes to Deglaze is by sharing them to Deglaze. This works with websites, and also ",
    "social apps",
    " and recipe apps.",
  ],
  secondParagraph: [
    "Just tap the Share icon in the browser and select Deglaze. If you don't see Deglaze, do this ",
    "one-time 30-second setup",
    ".",
  ],
} as const;

export const HowToAddBrowserRecipesScreen = withScreenContainer("HowToAddBrowserRecipeScreen", () => {
  const screen = useScreen();

  useScreenTimerWithScreenViewEvent();

  const onPressSocial = useCallback(() => {
    screen.nav.goTo("push", navTree.get.screens.howToAddSocialRecipe);
  }, [screen.nav.goTo]);

  const onPressSetupShareExtension = useCallback(() => {
    screen.nav.modal(navTree.get.screens.onboardShareExtensionFirstTime);
  }, [screen.nav.modal]);

  return (
    <ScreenView
      header={{
        type: "default",
        title: () => <TBody fontWeight="medium">{strings.headline}</TBody>,
        backgroundColor: "white",
      }}
    >
      <View>
        <TSecondary>
          <TSecondary>{strings.firstParagraph[0]}</TSecondary>
          <TextLink text={strings.firstParagraph[1]} onPress={onPressSocial} />
          <TSecondary>{strings.firstParagraph[2]}</TSecondary>
        </TSecondary>
        <Spacer vertical={1} />
        <TSecondary>
          <TSecondary>{strings.secondParagraph[0]}</TSecondary>
          <TextLink text={strings.secondParagraph[1]} onPress={onPressSetupShareExtension} />
          <TSecondary>{strings.secondParagraph[2]}</TSecondary>
        </TSecondary>
      </View>
      <Spacer vertical={1} />
      <View style={{ height: 600 }}>
        <ShareExtensionDemoVideo />
      </View>
    </ScreenView>
  );
});
