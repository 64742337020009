import { useDispatch } from "../../lib/redux/Redux";
import { useState } from "react";
import { View } from "react-native";
import { ButtonRectangle } from "../Buttons";
import { SocialTestHooksArgs } from "@eatbetter/posts-shared";
import { socialTestHook } from "../../lib/social/SocialThunks";
import { displayUnexpectedErrorAndLog } from "../../lib/Errors";
import { Spacer } from "../Spacer";

export const SocialTestHooks = () => {
  const dispatch = useDispatch();
  const [waiting, setWaiting] = useState(false);
  const [currentAction, setCurrentAction] = useState<SocialTestHooksArgs["action"] | undefined>();

  const onPress = async (action: SocialTestHooksArgs["action"]) => {
    try {
      setCurrentAction(action);
      await dispatch(socialTestHook({ action }, setWaiting));
    } catch (err) {
      displayUnexpectedErrorAndLog("Error calling socialTestHook", err);
    }
  };

  return (
    <View style={{ padding: 12 }}>
      <ButtonRectangle
        type="submit"
        title="Clear following and feed"
        onPress={() => onPress("clearFollowingAndFeed")}
        disabled={waiting}
        waiting={currentAction === "clearFollowingAndFeed" && waiting}
      />
      <Spacer vertical={1} />
      <ButtonRectangle
        type="submit"
        title="Clear follow recommendations"
        onPress={() => onPress("clearFollowRecommendations")}
        disabled={waiting}
        waiting={currentAction === "clearFollowRecommendations" && waiting}
      />
      <Spacer vertical={1} />
      <ButtonRectangle
        type="submit"
        title="Generate follow recommendations"
        onPress={() => onPress("generateFollowRecommendations")}
        disabled={waiting}
        waiting={currentAction === "generateFollowRecommendations" && waiting}
      />
    </View>
  );
};
