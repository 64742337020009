import React, { useCallback } from "react";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { useSystemSetting } from "../lib/system/SystemSelectors";
import { useDispatch } from "../lib/redux/Redux";
import { navTree } from "../navigation/NavTree";
import { useSurveyCard } from "../components/SurveyCard";
import { usePromptForNotifications } from "../lib/util/UsePromptForNotifications";
import { useAnonymousRegistrationPrompt } from "../lib/util/UseAnonymousRegistrationPrompt";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { SocialFeedsScreenComponent } from "../components/social/SocialFeedsScreenComponent";
import { reportFindUsersButtonTapped } from "../lib/analytics/AnalyticsEvents";
import { useDebugFeedScrollText } from "../lib/social/SocialSelectors";

const strings = {
  title: "deglaze",
};

export const HomeScreen = withScreenContainer("HomeScreen", () => {
  const textPostsEnabled = useSystemSetting("textPosts");

  return React.createElement<Props>(HomeScreenComponent, {
    textPostsEnabled,
  });
});

interface Props {
  textPostsEnabled: boolean;
}

const HomeScreenComponent = (props: Props) => {
  const dispatch = useDispatch();
  const screen = useScreen();

  /**
   * Enable here and in the rendering code below to display a new feature modal. See `useNewFeatureModal` for
   * triggering logic.
   */
  // const newFeatureModal = useNewFeatureModal();

  const onPressCreatePostButton = useCallback(() => {
    screen.nav.modal(navTree.get.screens.createTextPost);
  }, [screen.nav.modal]);

  const onPressUsersButton = useCallback(() => {
    dispatch(analyticsEvent(reportFindUsersButtonTapped()));
    screen.nav.goTo("push", navTree.get.screens.searchEntities);
  }, [dispatch, screen.nav.goTo]);

  const onPressNotificationCenterButton = useCallback(() => {
    screen.nav.goTo("push", navTree.get.screens.notificationCenter);
  }, [screen.nav.goTo]);

  // navs to the registration modal once thresholds are met
  useAnonymousRegistrationPrompt(screen.nav);
  // prompt for notifications. willPrompt will return true if the prompt will fire, but it can't yet
  usePromptForNotifications();
  // Disabled Jun 3rd, but leaving commented to keep the logic for presenting it
  // display the onboarding video card. We pass willPrompt to make sure it doesn't display until after the notification prompt is done
  // const onboardingVideoCard = useOnboardingHowToVideo(willPrompt);
  const surveyCard = useSurveyCard();

  const debugFeedScrollText = useDebugFeedScrollText();

  // This component slides in above the tab view with the feeds
  const renderTopSlot = useCallback(() => {
    return surveyCard;
  }, [surveyCard]);

  return (
    <>
      <SocialFeedsScreenComponent
        context="homeScreen"
        screenTitle={debugFeedScrollText ?? strings.title}
        renderTopSlot={renderTopSlot}
        onPressCreatePost={onPressCreatePostButton}
        onPressNotificationCenter={onPressNotificationCenterButton}
        onPressUsers={onPressUsersButton}
        textPostsEnabled={props.textPostsEnabled}
      />
      {/* {newFeatureModal} */}
    </>
  );
};
