import { CurrentEnvironment } from "../../CurrentEnvironment";
import { log } from "../../Log";
import { useEffect, useRef } from "react";
import { setDiff } from "@eatbetter/common-shared";

export const useRenderTracker = (name: string, props?: Record<string, any>) => {
  if (!CurrentEnvironment.debugBuild()) {
    return;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const lastProps = useRef<Record<string, any>>();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    log.debug(`RENDER TRACKER: Rendering ${name}`);

    if (!props) {
      return;
    }

    if (!lastProps.current) {
      lastProps.current = props;
      return;
    }

    const oldKeys = Object.keys(lastProps.current);
    const newKeys = Object.keys(props);

    const diff = setDiff(oldKeys, newKeys);
    if (diff.added.length > 0) {
      log.debug(`\tRENDER TRACKER: New keys detected for ${name}: ${diff.added}`);
      return;
    }

    if (diff.removed.length > 0) {
      log.debug(`\tRENDER TRACKER: Keys removed for ${name}: ${diff.removed}`);
      return;
    }

    diff.intersection.forEach(key => {
      if (lastProps.current?.[key] !== props[key]) {
        log.debug(`\tRENDER TRACKER: Prop ${key} changed for ${name}`);
      }
    });

    lastProps.current = props;
  });
};
