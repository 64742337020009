export const AlertStrings = {
  cancel: "Cancel",
  delete: "Delete",
  resume: "Resume",
  discard: "Discard",
  save: "Save",
};

interface AlertButtonBase<T extends string> {
  text?: string;
  type: T;
  onPress: () => void;
}

export type CancelButton = AlertButtonBase<"cancel">;
export type SaveButton = AlertButtonBase<"save">;
export type DiscardButton = AlertButtonBase<"discard">;
export type DeleteButton = AlertButtonBase<"delete">;

export type AlertButton = CancelButton | DeleteButton | SaveButton | DiscardButton;

export interface AlertInterface {
  alert: (title: string, message: string, buttons: [AlertButton, ...AlertButton[]]) => void;
}
