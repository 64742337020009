import {
  UrlString,
  UserId,
  filterOutFalsy,
  getDisplayUrl,
  getInstagramProfileUrl,
  switchReturn,
} from "@eatbetter/common-shared";
import React, { useCallback } from "react";
import { View } from "react-native";
import { useDispatch } from "../../lib/redux/Redux";
import { IconLink, IconOpenExternal } from "../Icons";
import { Pressable } from "../Pressable";
import { Spacer } from "../Spacer";
import { TBody, TSecondary } from "../Typography";
import { globalStyleColors, globalStyleConstants } from "../GlobalStyles";
import { ProfileLinkTappedContext } from "../../lib/analytics/AnalyticsEvents";
import { KnownAuthorId, KnownPublisherId } from "@eatbetter/recipes-shared";
import { useScreen } from "../../navigation/ScreenContainer";
import { navTree } from "../../navigation/NavTree";
import { Separator } from "../Separator";
import { openProfileLink } from "../../lib/social/SocialThunks";
import { Image } from "../Image/Image";

const strings = {
  andMore: (urlCount: number) => (urlCount > 1 ? ` and ${urlCount - 1} more` : ""),
  links: "Links",
};

export interface ProfileLinksProps {
  otherUserId?: UserId;
  entityId?: KnownAuthorId | KnownPublisherId;
  links?: UrlString[];
  instagramHandle?: string;
  substackLink?: UrlString;
}

export const ProfileLinks = React.memo((props: ProfileLinksProps) => {
  const analyticsContext: ProfileLinkTappedContext = props.otherUserId
    ? props.entityId
      ? "knownUserAuthorProfile"
      : "otherUserProfile"
    : "ownProfile";

  const socialLinks = filterOutFalsy([
    props.instagramHandle ? (
      <InstagramLink
        instagramHandle={props.instagramHandle}
        analyticsContext={analyticsContext}
        entityId={props.entityId}
        otherUserId={props.otherUserId}
      />
    ) : undefined,
    props.substackLink ? (
      <SubstackLink
        substackUrl={props.substackLink}
        analyticsContext={analyticsContext}
        entityId={props.entityId}
        otherUserId={props.otherUserId}
      />
    ) : undefined,
  ]);

  return (
    <View>
      {!!props.links && (
        <EntityUrlLinks
          urls={props.links}
          analyticsContext={analyticsContext}
          otherUserId={props.otherUserId}
          entityId={props.entityId}
        />
      )}
      {socialLinks.length > 0 && (
        <>
          <Spacer vertical={1} />
          <View style={{ flexDirection: "row" }}>
            {socialLinks.map((i, idx) => {
              return (
                <>
                  {idx !== 0 && (
                    <>
                      <Spacer horizontal={1} />
                      <Separator orientation="column" />
                      <Spacer horizontal={1} />
                    </>
                  )}
                  {i}
                </>
              );
            })}
          </View>
        </>
      )}
    </View>
  );
});

export const EntityUrlLinks = React.memo(
  (props: {
    urls: UrlString[];
    otherUserId?: UserId;
    entityId?: KnownAuthorId | KnownPublisherId;
    analyticsContext: ProfileLinkTappedContext;
  }) => {
    const dispatch = useDispatch();
    const screen = useScreen();

    const onPress = useCallback(() => {
      if (props.urls.length === 1 && props.urls[0]) {
        dispatch(
          openProfileLink({
            analyticsContext: props.analyticsContext,
            url: props.urls[0],
            urlIndex: 0,
            otherUserId: props.otherUserId,
            entityId: props.entityId,
          })
        );
      } else {
        screen.nav.modal(navTree.get.screens.bottomSheet, {
          height: 250,
          content: (
            <SelectLinkSheet
              links={props.urls}
              analyticsContext={props.analyticsContext}
              entityId={props.entityId}
              otherUserId={props.otherUserId}
            />
          ),
        });
      }
    }, [dispatch, props.analyticsContext, props.urls, props.otherUserId, props.entityId, screen.nav.modal]);

    if (!props.urls[0]) {
      return null;
    }

    const displayUrl = getDisplayUrl(props.urls[0]);
    const displayUrlAndMore = `${displayUrl}${strings.andMore(props.urls.length)}`;

    return (
      <EntityUrlLinkDisplay
        displayText={displayUrlAndMore}
        onPress={onPress}
        entityId={props.entityId}
        otherUserId={props.otherUserId}
      />
    );
  }
);

interface EntityUrlLinkProps {
  url: UrlString;
  index: number;
  analyticsContext: ProfileLinkTappedContext;
  otherUserId?: UserId;
  entityId?: KnownAuthorId | KnownPublisherId;
}

export const EntityUrlLink = React.memo((props: EntityUrlLinkProps) => {
  const dispatch = useDispatch();

  const onPress = useCallback(() => {
    dispatch(
      openProfileLink({
        analyticsContext: props.analyticsContext,
        url: props.url,
        urlIndex: props.index,
        otherUserId: props.otherUserId,
        entityId: props.entityId,
      })
    );
  }, [props.analyticsContext, props.url, props.index, props.otherUserId, props.entityId]);

  return (
    <EntityUrlLinkDisplay
      displayText={getDisplayUrl(props.url)}
      onPress={onPress}
      entityId={props.entityId}
      otherUserId={props.otherUserId}
    />
  );
});

const EntityUrlLinkDisplay = React.memo(
  (props: {
    icon?: "website" | "instagram" | "substack";
    displayText: string;
    entityId?: KnownAuthorId | KnownPublisherId;
    otherUserId?: UserId;
    onPress: () => void;
  }) => {
    const icon = switchReturn(props.icon ?? "website", {
      website:
        props.otherUserId || !props.entityId ? (
          <IconLink strokeWidth={2} size={18} opacity="opaque" color={globalStyleColors.colorAccentCool} />
        ) : (
          <IconOpenExternal strokeWidth={2} size={18} opacity="opaque" color={globalStyleColors.colorAccentCool} />
        ),
      instagram: (
        <Image
          source={require("../../assets/images/instagram_logo.png")}
          style={{ width: 18, height: 18 }}
          resizeMode="contain"
        />
      ),
      substack: (
        <Image
          source={require("../../assets/images/substack_logo.png")}
          style={{ width: 18, height: 18 }}
          resizeMode="contain"
        />
      ),
    });

    return (
      <Pressable
        style={{ flexDirection: "row", alignItems: "center", alignSelf: "flex-start" }}
        onPress={props.onPress}
      >
        {icon}
        <Spacer horizontal={0.5} />
        <TSecondary fontWeight="medium" color={globalStyleColors.colorAccentCool}>
          {props.displayText}
        </TSecondary>
      </Pressable>
    );
  }
);

const InstagramLink = React.memo(
  (props: {
    instagramHandle: string;
    analyticsContext: ProfileLinkTappedContext;
    entityId?: KnownAuthorId | KnownPublisherId;
    otherUserId?: UserId;
  }) => {
    const dispatch = useDispatch();

    const onPress = useCallback(() => {
      dispatch(
        openProfileLink({
          url: getInstagramProfileUrl(props.instagramHandle),
          urlIndex: 0,
          analyticsContext: props.analyticsContext,
          entityId: props.entityId,
          otherUserId: props.otherUserId,
        })
      );
    }, [dispatch, props.instagramHandle, props.analyticsContext, props.entityId, props.otherUserId]);

    return (
      <EntityUrlLinkDisplay
        icon="instagram"
        displayText="Instagram"
        onPress={onPress}
        entityId={props.entityId}
        otherUserId={props.otherUserId}
      />
    );
  }
);

const SubstackLink = React.memo(
  (props: {
    substackUrl: UrlString;
    analyticsContext: ProfileLinkTappedContext;
    entityId?: KnownAuthorId | KnownPublisherId;
    otherUserId?: UserId;
  }) => {
    const dispatch = useDispatch();

    const onPress = useCallback(() => {
      dispatch(
        openProfileLink({
          url: props.substackUrl,
          urlIndex: 0,
          analyticsContext: props.analyticsContext,
          entityId: props.entityId,
          otherUserId: props.otherUserId,
        })
      );
    }, [dispatch, props.substackUrl, props.analyticsContext, props.entityId, props.otherUserId]);

    return (
      <EntityUrlLinkDisplay
        icon="substack"
        displayText="Substack"
        onPress={onPress}
        entityId={props.entityId}
        otherUserId={props.otherUserId}
      />
    );
  }
);

const SelectLinkSheet = React.memo(
  (props: {
    links: UrlString[];
    analyticsContext: ProfileLinkTappedContext;
    entityId?: KnownAuthorId | KnownPublisherId;
    otherUserId?: UserId;
  }) => {
    return (
      <View
        style={{ paddingHorizontal: globalStyleConstants.defaultPadding, paddingTop: globalStyleConstants.unitSize }}
      >
        <View>
          <TBody fontWeight="medium" align="center">
            {strings.links}
          </TBody>
        </View>
        <Spacer vertical={1} />
        <Separator orientation="row" />
        {props.links.map((i, idx) => {
          return (
            <View key={i}>
              <Spacer vertical={2} />
              <EntityUrlLink
                url={i}
                index={idx}
                analyticsContext={props.analyticsContext}
                entityId={props.entityId}
                otherUserId={props.otherUserId}
              />
            </View>
          );
        })}
      </View>
    );
  }
);
