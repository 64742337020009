import { useCallback } from "react";
import { useScreen } from "../../navigation/ScreenContainer";
import { SocialEntityId } from "@eatbetter/posts-shared";
import { useDispatch } from "../redux/Redux";
import { navToEntityScreen } from "../social/SocialThunks";
import { KnownEntityPageViewedFrom } from "../analytics/AnalyticsEvents";

export function useNavToProfileScreen(entityId: SocialEntityId, from: KnownEntityPageViewedFrom) {
  const dispatch = useDispatch();
  const { nav } = useScreen();

  const navToProfileScreen = useCallback(() => {
    dispatch(navToEntityScreen(entityId, nav, from));
  }, [entityId, nav]);

  return navToProfileScreen;
}
