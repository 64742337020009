import { AutoSaveTextEdit, PersistTextHandler, useAutoSaveTextEdit } from "./UseAutoSaveTextEdit";
import { GroceryListItemId } from "@eatbetter/lists-shared";
import { useListItem, useListRecipe } from "../lists/ListsSelectors";
import { groceryListItemTextUpdated } from "../lists/ListsSlice";
import { useScaled } from "./UseScaled";
import { useEffect, useRef } from "react";
import { useUserSetting } from "../system/SystemSelectors";

export const useGroceryItemTextEdit = (id: GroceryListItemId): AutoSaveTextEdit => {
  const listItem = useListItem(id);
  const recipeListItem = listItem.type === "recipe" ? listItem : undefined;
  const listRecipe = useListRecipe(recipeListItem?.recipeInstanceId);

  const units = useUserSetting("unitConversion");

  const scale = listRecipe?.listRecipe.scale ?? 1;
  const prevScale = useRef(scale);
  const scaledPersistedText = useScaled(recipeListItem, scale, units).reduce((acc, curr) => (acc += curr.text), "");
  const persistedText = recipeListItem && scaledPersistedText.length > 0 ? scaledPersistedText : listItem.text;

  // Don't trigger persist cycle when recipe is scaled via the scaling UX, however we do want to update the text input
  // to reflect the new scaling
  const shouldPersist = useRef(true);
  useEffect(() => {
    if (scale !== prevScale.current) {
      shouldPersist.current = false;
      prevScale.current = scale;
    }
  }, [scale]);

  const setPersistedText: PersistTextHandler = (text, dispatch, setPending, callSuccessful, isDirty) => {
    if (!shouldPersist.current) {
      shouldPersist.current = true;
      isDirty(false);
      return;
    }
    if (!isValid(text)) {
      callSuccessful(false);
      setPending(false);
      return;
    }

    dispatch(groceryListItemTextUpdated({ id, text }));
    callSuccessful(true);
    setPending(false);
  };

  return useAutoSaveTextEdit(persistedText, setPersistedText, { setPendingOnTextChange: shouldPersist.current });
};

function isValid(text: string): boolean {
  return text.trim() !== "";
}
