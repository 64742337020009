import React, { useCallback, useEffect, useMemo, useState } from "react";
import { View } from "react-native";
import {
  ContainerPadded,
  displayUnexpectedErrorAndLogHandler,
  globalStyleConstants,
  IconCircleCheck,
  IconCircleCheckFilled,
  IconCircleEx,
  IconCircleExFilled,
  Pressable,
  TBody,
  TextInput,
} from "@eatbetter/ui-shared";
import { AdminDispatch, useDispatch } from "../lib/AdminRedux";
import { loadPhrases, verifyTruthSetFields } from "../lib/data-manager/DataManagerThunks";
import { useTruthSetPhrase, usePhraseIds, useTruthSetFilterNames } from "../lib/data-manager/DataManagerSelectors";
import { phrasesFiltered, TruthSetSort } from "../lib/data-manager/DataManagerSlice";
import { debounce } from "lodash";
import {
  FieldToVerify,
  PersistedTruthSetIngredientPhrase,
  PersistedTruthSetInstructionPhrase,
  PhraseItemType,
  TruthSetPhrase,
  TruthSetVerifiedStatus,
  VerifiableField,
  VerifiableFieldName,
} from "@eatbetter/items-data";
import { deepEquals, discriminate, EpochMs, filterOutFalsy, isToday, switchReturn } from "@eatbetter/common-shared";
import { Spacer } from "@eatbetter/ui-shared";
import BigList from "react-native-big-list";
import { Popover, Select } from "antd";
import { AdminScreenView } from "../components/AdminScreenView";
import { ParsedItemDebugScreenNav } from "./ParsedItemDebugScreen";
import { InstructionTimer } from "@eatbetter/items-server";
import { ScalingMeasurement, ScalingQuantity, ScalingQuantityUnit } from "@eatbetter/items-shared";
import ReactDiffViewer from "react-diff-viewer";

type SelectItem<T> = { label: string; value: T | null };

const sortOptions: Array<SelectItem<TruthSetSort>> = [
  { label: "Sort: Touched", value: "touched" },
  { label: "Sort: Changed", value: "changed" },
  { label: "Sort: Random", value: "random" },
];

const doSearch = debounce(
  (dispatch: AdminDispatch, query: string, filters: Array<string | null>, sort: TruthSetSort) => {
    dispatch(
      phrasesFiltered({
        query,
        filters: filterOutFalsy(filters),
        sort,
      })
    );
  },
  300
);

const { Option } = Select;

export const TruthSetScreenNav = {
  getPath: () => "/truth",
};

export const TruthSetScreen = () => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const [sort, setSort] = useState<TruthSetSort>("touched");
  const [selectedFilters, setSelectedFilters] = useState<Array<string | null>>([]);
  const phraseIds = usePhraseIds();
  const filterNames = useTruthSetFilterNames();

  const filterOptions: Array<SelectItem<string>> = useMemo(() => {
    return filterNames.map(t => {
      return { label: t, value: t };
    });
  }, [filterNames]);

  useEffect(() => {
    dispatch(loadPhrases()).catch(displayUnexpectedErrorAndLogHandler("Error calling loadPhrases"));
  }, []);

  useEffect(() => {
    doSearch(dispatch, query, selectedFilters, sort);
  }, [query, selectedFilters, sort]);

  return (
    <AdminScreenView>
      <View style={{ flexDirection: "row", zIndex: 1000, alignItems: "center" }}>
        <View style={{ width: 200 }}>
          <TextInput value={query} onChangeText={setQuery} placeholderText="Search" showClearButton />
        </View>
        <Spacer horizontal={1} />
        <Select value={sort} onChange={setSort}>
          {sortOptions.map(s => {
            return (
              <Option value={s.value} key={s.label}>
                {s.label}
              </Option>
            );
          })}
        </Select>
        <Spacer horizontal={1} />
        <Select
          value={selectedFilters}
          onChange={setSelectedFilters}
          mode="multiple"
          allowClear
          placeholder="Filters"
          style={{ flex: 1 }}
          options={filterOptions}
          listHeight={400}
        />
      </View>
      <Spacer vertical={1} />
      <TBody>{phraseIds.length} phrases</TBody>

      {/*Flatlist performance was atrocious, even after using getItemLayout, etc. This is much better.*/}
      <BigList
        data={phraseIds}
        itemHeight={160}
        renderItem={({ item }) => <DataManagerTokenTagsPhraseComponent phraseId={item} key={item} />}
        // No idea why, but items don't render unless renderEmpty renders something
        renderEmpty={() => (
          <View>
            <TBody>No results</TBody>
          </View>
        )}
        renderFooter={undefined}
        renderHeader={undefined}
      />
    </AdminScreenView>
  );
};

interface TruthSetPhraseDiff {
  key: string;
  previous?: string | null;
  current?: string | null;
  display: "table" | "diff";
  time?: EpochMs;
}

interface TruthSetVerifiedPhraseDiff {
  key: string;
  verified?: string | null;
  current?: string | null;
  display: "table" | "diff";
}

export function diffFromPrevious(phrase: TruthSetPhrase): {
  verifiedChanges: TruthSetVerifiedPhraseDiff[];
  otherChanges: TruthSetPhraseDiff[];
} {
  const otherChanges: TruthSetPhraseDiff[] = [];
  const verifiedChanges: TruthSetVerifiedPhraseDiff[] = [];

  const diff = <T,>(
    key: string,
    display: "table" | "diff",
    vf: VerifiableField<T> | undefined,
    toString: (value?: T | null) => string | undefined | null
  ) => {
    if (!vf) {
      return;
    }

    if (vf.status === "correctChanged" || vf.status === "incorrectChanged") {
      verifiedChanges.push({
        key,
        verified: toString(vf.verified),
        current: toString(vf.current),
        display,
      });
    }

    if (vf.previous !== undefined) {
      otherChanges.push({
        key,
        current: toString(vf.current),
        previous: toString(vf.previous),
        time: vf.changed,
        display,
      });
    }
  };

  if (phrase.itemType === "ingredient") {
    diff("Ingredient", "table", phrase.ingredient, s => s);
    diff("Ingredient Phrase", "table", phrase.ingredientPhrase, s => s);
    diff("Shoppable Phrase", "table", phrase.shoppablePhrase, s => s);
    diff("Category", "table", phrase.category, s => s);
  } else if (phrase.itemType === "instruction") {
    diff("Timers", "diff", phrase.timers, s => JSON.stringify(s));
  }

  diff("Scaling", "diff", phrase.scaling, s => JSON.stringify(s));

  return { otherChanges, verifiedChanges };
}

type Diff = { verifiedChanges: TruthSetVerifiedPhraseDiff[]; otherChanges: TruthSetPhraseDiff[] };

const DataManagerTokenTagsPhraseComponent = React.memo((props: { phraseId: string }) => {
  const phrase = useTruthSetPhrase(props.phraseId);

  const diff: Diff = useMemo(() => {
    return phrase ? diffFromPrevious(phrase) : { verifiedChanges: [], otherChanges: [] };
  }, [phrase]);

  if (!phrase) {
    throw new Error(`Could not find phrase ${props.phraseId}`);
  }

  return (
    <ContainerPadded all={1} bottom={2}>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <DataManagerPhraseComponent phrase={phrase} />

        <Spacer horizontal={1} />
        <a href={ParsedItemDebugScreenNav.getPath(phrase.phrase, phrase.itemType)} target="_blank" rel="noreferrer">
          Debug
        </a>
        <Spacer horizontal={1} />
        <Time label="Touched" timestamp={phrase.dateUpdated} />
        <Spacer horizontal={1} />
        <Popover content={<Diff {...diff} />}>
          <View>
            <Time label="Changed" timestamp={phrase.dateChanged} />
          </View>
        </Popover>
      </View>
      <Spacer vertical={1} />
      {phrase.itemType === "ingredient" && <IngredientVerifyButtons phrase={phrase} diff={diff} />}
      {phrase.itemType === "instruction" && <InstructionVerifyButtons phrase={phrase} diff={diff} />}
    </ContainerPadded>
  );
});

const DataManagerPhraseComponent = React.memo((props: { phrase: TruthSetPhrase }) => {
  const phrase = props.phrase;

  const segments = getSegments(phrase);

  const underlineOffset = 5;
  return (
    <span>
      {segments.map((s, idx) => {
        const tds: React.CSSProperties["textDecorationStyle"][] = [];
        if (s.timer) {
          tds.push("dotted");
        }

        if (s.scale) {
          tds.push("double");
        } else if (s.convert) {
          tds.push("solid");
        }

        const something = (s.color && s.color !== "black") || s.timer || s.scale || s.convert;
        const fontWeight = something ? 700 : undefined;

        // if we have multiple types of underline, use strike-through. We shouldn't ever hit this if the parser is working.
        const textDecoration: React.CSSProperties["textDecoration"] =
          tds.length === 1 ? "underline" : tds.length > 1 ? "line-through" : "none";
        const textDecorationStyle = tds.length === 1 ? tds[0] : undefined;
        const textDecorationColor = s.scaleConvertColor ?? "black";
        const backgroundColor = s.multipleMeasurements ? "pink" : undefined;

        // without this, the underline doesn't render for letters with descenders, which is significant for the single character g (as in grams)
        const textUnderlineOffset = textDecorationStyle ? underlineOffset : undefined;

        return (
          <span
            key={idx}
            style={{
              color: s.color,
              textDecoration,
              textDecorationStyle,
              textDecorationColor,
              textUnderlineOffset,
              fontWeight,
              backgroundColor,
            }}
          >
            {s.text}
          </span>
        );
      })}
    </span>
  );
});

const IngredientVerifyButtons = React.memo((props: { phrase: PersistedTruthSetIngredientPhrase; diff: Diff }) => {
  const { phrase, diff } = props;
  return (
    <>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {phrase.ingredient && (
          <VerifyButtons
            type={phrase.itemType}
            phrase={phrase.phrase}
            fieldName="ingredient"
            field={phrase.ingredient}
          />
        )}
        {(phrase.ingredient?.status === "correctChanged" || phrase.ingredient?.status === "incorrectChanged") && (
          <StatusIndicator diff={diff} status={phrase.ingredient.status} />
        )}
        <Spacer horizontal={1} />
        <TBody>{phrase.ingredient?.current ?? "(No Ingredient)"}</TBody>
        {!!phrase.ingredient?.current && !phrase.category?.current && <TBody> (No category)</TBody>}
      </View>
      <Spacer vertical={1} />
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {phrase.shoppablePhrase && (
          <VerifyButtons
            phrase={phrase.phrase}
            type={phrase.itemType}
            fieldName="shoppablePhrase"
            field={phrase.shoppablePhrase}
          />
        )}
        {(phrase.shoppablePhrase?.status === "correctChanged" ||
          phrase.shoppablePhrase?.status === "incorrectChanged") && (
          <StatusIndicator diff={diff} status={phrase.shoppablePhrase.status} />
        )}
        <Spacer horizontal={1} />
        <TBody>{phrase.shoppablePhrase?.current ?? "(No shoppable phrase)"}</TBody>
      </View>

      <Spacer vertical={1} />
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {phrase.scaling && (
          <VerifyButtons phrase={phrase.phrase} type={phrase.itemType} fieldName="scaling" field={phrase.scaling} />
        )}
        {(phrase.scaling?.status === "correctChanged" || phrase.scaling?.status === "incorrectChanged") && (
          <StatusIndicator diff={diff} status={phrase.scaling.status} />
        )}
        <Spacer horizontal={1} />
        <TBody>Scaling</TBody>
      </View>
    </>
  );
});

const InstructionVerifyButtons = React.memo((props: { phrase: PersistedTruthSetInstructionPhrase; diff: Diff }) => {
  const { phrase, diff } = props;
  return (
    <>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {phrase.scaling && (
          <VerifyButtons phrase={phrase.phrase} type={phrase.itemType} fieldName="scaling" field={phrase.scaling} />
        )}
        {(phrase.scaling?.status === "correctChanged" || phrase.scaling?.status === "incorrectChanged") && (
          <StatusIndicator diff={diff} status={phrase.scaling.status} />
        )}
        <Spacer horizontal={1} />
        <TBody>Scaling</TBody>
      </View>
      <Spacer vertical={1} />
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {phrase.timers && (
          <VerifyButtons phrase={phrase.phrase} type={phrase.itemType} fieldName="timers" field={phrase.timers} />
        )}
        {(phrase.timers?.status === "correctChanged" || phrase.timers?.status === "incorrectChanged") && (
          <StatusIndicator diff={diff} status={phrase.timers.status} />
        )}
        <Spacer horizontal={1} />
        <TBody>Timers</TBody>
      </View>
    </>
  );
});

const VerifyButtons = React.memo(
  (props: { phrase: string; type: PhraseItemType; fieldName: VerifiableFieldName; field: VerifiableField<any> }) => {
    const dispatch = useDispatch();
    const [waiting, setWaiting] = useState(false);
    const checkIcon = props.field?.status === "correct" ? <IconCircleCheckFilled /> : <IconCircleCheck />;
    const exIcon = props.field?.status === "incorrect" ? <IconCircleExFilled /> : <IconCircleEx />;

    const onPressCheck = useCallback(() => {
      const action: FieldToVerify["action"] = props.field?.status !== "correct" ? "markCorrect" : "clear";
      setWaiting(true);
      dispatch(
        verifyTruthSetFields(props.phrase, props.type, [
          { name: props.fieldName, action, currentValue: props.field.current },
        ])
      )
        .catch(displayUnexpectedErrorAndLogHandler("Error verifying phrase"))
        .finally(() => setWaiting(false));
    }, [props.field]);

    const onPressEx = useCallback(() => {
      const action: FieldToVerify["action"] = props.field?.status !== "incorrect" ? "markIncorrect" : "clear";
      setWaiting(true);
      dispatch(
        verifyTruthSetFields(props.phrase, props.type, [
          { name: props.fieldName, action, currentValue: props.field.current },
        ])
      )
        .catch(displayUnexpectedErrorAndLogHandler("Error verifying phrase"))
        .finally(() => setWaiting(false));
    }, [props.field]);

    return (
      <>
        <Pressable onPress={onPressCheck} disabled={waiting}>
          {checkIcon}
        </Pressable>
        <Spacer horizontal={0.25} />
        <Pressable onPress={onPressEx} disabled={waiting}>
          {exIcon}
        </Pressable>
      </>
    );
  }
);

const Time = (props: { label: string; timestamp?: EpochMs }) => {
  const time = getTime(props.timestamp);
  return (
    <span>
      {props.label}: {time}
    </span>
  );
};

function getTime(ts?: EpochMs) {
  if (!ts) {
    return "-";
  }

  const d = new Date(ts);
  if (isToday(ts)) {
    return d.toLocaleTimeString();
  } else {
    return d.toLocaleDateString();
  }
}

const Diff = (props: { verifiedChanges: TruthSetVerifiedPhraseDiff[]; otherChanges: TruthSetPhraseDiff[] }) => {
  if (props.verifiedChanges.length === 0 && props.otherChanges.length === 0) {
    return <TBody>No diff to show</TBody>;
  }

  const verifiedDiffFields = props.verifiedChanges.filter(c => c.display === "diff");
  // don't show both verified and other diff
  const otherDiffFields = props.otherChanges
    .filter(c => c.display === "diff")
    .filter(c => !verifiedDiffFields.some(d => d.key === c.key));

  const style = { paddingLeft: globalStyleConstants.unitSize, paddingRight: globalStyleConstants.unitSize };

  return (
    <View>
      <table style={{ textAlign: "center", verticalAlign: "middle" }}>
        <tbody>
          {props.verifiedChanges.filter(c => c.display === "table").length > 0 && (
            <tr>
              <th style={style}>Key</th>
              <th style={style}>Verified</th>
              <th style={style}>Current</th>
              <th />
            </tr>
          )}
          {props.verifiedChanges
            .filter(c => c.display === "table")
            .map(vc => {
              return (
                <tr key={vc.key}>
                  <td style={style}>{vc.key}</td>
                  <td style={style}>{`${vc.verified}`}</td>
                  <td style={style}>{`${vc.current}`}</td>
                  <td />
                </tr>
              );
            })}
          {props.otherChanges.filter(c => c.display === "table").length > 0 && (
            <tr>
              <th style={style}>Key</th>
              <th style={style}>Previous</th>
              <th style={style}>Current</th>
              <th style={style}>Time</th>
            </tr>
          )}
          {props.otherChanges
            .filter(c => c.display === "table")
            .map(d => {
              return (
                <tr key={d.key}>
                  <td style={style}>{d.key}</td>
                  <td style={style}>{`${d.previous}`}</td>
                  <td style={style}>{`${d.current}`}</td>
                  <td style={style}>{getTime(d.time)}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {verifiedDiffFields.length > 0 &&
        verifiedDiffFields.map(d => {
          return (
            <View style={style} key={`${d.key}-verified`}>
              <span>{d.key} (verified)</span>
              {d.verified === d.current && <span>(no difference)</span>}
              <ReactDiffViewer oldValue={d.verified ?? ""} newValue={d.current ?? "<none>"} splitView={false} />
              <Spacer vertical={1} />
            </View>
          );
        })}
      {otherDiffFields.length > 0 &&
        otherDiffFields.map(d => {
          return (
            <View style={style} key={`${d.key}-other`}>
              <span>
                {d.key} (not verified) {getTime(d.time)}
              </span>
              {d.previous === d.current && <span>(no difference)</span>}
              <ReactDiffViewer oldValue={d.previous ?? ""} newValue={d.current ?? "<none>"} splitView={false} />
              <Spacer vertical={1} />
            </View>
          );
        })}
    </View>
  );
};

const StatusIndicator = (props: {
  status?: TruthSetVerifiedStatus;
  diff: { verifiedChanges: TruthSetVerifiedPhraseDiff[]; otherChanges: TruthSetPhraseDiff[] };
}) => {
  if (!props.status) {
    return null;
  }

  const color =
    props.status === undefined
      ? "white"
      : switchReturn<TruthSetVerifiedStatus, string>(props.status, {
          correct: "green",
          incorrect: "lightpink",
          correctChanged: "red",
          incorrectChanged: "lightgreen",
        });

  return (
    <Popover content={<Diff {...props.diff} />}>
      <View
        style={{ height: 24, backgroundColor: color, padding: 6, margin: 6, borderRadius: 6, justifyContent: "center" }}
      >
        <TBody color="white" align="center">
          {props.status ? getStatusText(props.status) : ""}
        </TBody>
      </View>
    </Popover>
  );
};

function getStatusText(status: TruthSetVerifiedStatus): string {
  switch (status) {
    case "correct":
      return "Correct";
    case "correctChanged":
      return "Was Correct";
    case "incorrect":
      return "Incorrect";
    case "incorrectChanged":
      return "Was Incorrect";
  }
}

interface Segment {
  text: string;
  timer?: boolean;

  // we use the same underline color for all elements of a single measurement
  convert?: boolean;
  scale?: boolean;
  scaleConvertColor?: string;
  implicit?: boolean;
  // this should always be false if things are working, but just in case, we use a background color if we detect this
  multipleMeasurements?: boolean;

  // color from the token map
  color?: string;
}

function getSegments(phrase: TruthSetPhrase): Segment[] {
  type TypeAndRange =
    | { type: "timer"; timer: InstructionTimer; range: [number, number] }
    | {
        type: "measurement";
        measurement: ScalingMeasurement;
        range: [number, number];
        insertText?: string;
        measurementId: string;
      }
    | { type: "tag"; color: string; range: [number, number] };

  const ranges: TypeAndRange[] = [];

  // TAGS
  const tags =
    phrase.tokenTags?.map<TypeAndRange>(t => {
      return {
        type: "tag",
        color: t.color,
        range: t.range,
      };
    }) ?? [];

  ranges.push(...tags);

  // MEASUREMENTS
  const measurements =
    phrase.scaling?.current?.measurements.flatMap<TypeAndRange>((m, idx) => {
      const ranges: TypeAndRange[] = [];
      const addQu = (qu: ScalingQuantityUnit) => {
        const q = qu.q;
        const u = qu.u ? [qu.u] : [];
        const tars = [...q, ...u].map<TypeAndRange>(i => {
          const valueKey: keyof ScalingQuantity = "value";
          const insertText = i.insert ? (valueKey in i ? i.value.toString() : i.s) : undefined;
          return {
            type: "measurement",
            range: i.range,
            insertText,
            measurement: m,
            measurementId: idx.toString(),
          };
        });
        ranges.push(...tars);
      };

      switch (m.type) {
        case "qu":
          addQu(m);
          break;
        case "additive":
          m.measurements.forEach(i => addQu(i));
          break;
        case "alternates":
          m.measurements.forEach(i => {
            if (i.type === "qu") {
              addQu(i);
            } else {
              i.measurements.forEach(i2 => addQu(i2));
            }
          });
      }

      return ranges;
    }) ?? [];

  ranges.push(...measurements);

  // TIMERS
  if (phrase.itemType === "instruction") {
    const timers =
      phrase.timers?.current?.map<TypeAndRange>(t => {
        return {
          type: "timer",
          timer: t,
          range: t.range,
        };
      }) ?? [];
    ranges.push(...timers);
  }

  ranges.sort((a, b) => {
    if (a.range[0] !== b.range[0]) {
      return a.range[0] - b.range[0];
    }

    return a.range[1] - b.range[1];
  });

  // Ranges that include the current index (see loop below)
  let currentRanges: TypeAndRange[] = [];

  // remove any ranges from current index from ranges and add to currentRanges
  const addToCurrentRanges = (index: number) => {
    while (index >= (ranges[0]?.range[0] ?? Number.MAX_SAFE_INTEGER)) {
      currentRanges.push(ranges.shift()!);
    }
  };

  // remove ranges from currentRanges once we reach their end index
  const removeFromCurrentRanges = (index: number) => {
    currentRanges = currentRanges.filter(r => r.range[1] > index);
  };

  // we use the same color for a given measurement
  const measurementColors = ["black", "magenta", "lime", "purple"];
  let colorIndex = -1;

  const colorMap: Record<string, string> = {};

  const getSegmentMetaForImplicitFromCurrentRanges = (): Segment | undefined => {
    const measurements = currentRanges.filter(discriminate("type", "measurement")).filter(m => !!m.insertText);
    if (measurements.length === 0) {
      return undefined;
    }

    if (measurements.length > 1) {
      throw new Error("Expecting at most 1 implicit measurment");
    }

    const implicit = measurements[0]!;
    if (implicit.range[0] !== implicit.range[1]) {
      throw new Error("Found implicit measurement with range with min != max");
    }

    if (implicit.measurement.type !== "qu") {
      throw new Error("Currently only support implicit measurement of type qu in admin truth screen");
    }

    if (!implicit.insertText) {
      throw new Error("expecting insert text");
    }

    return {
      text: `[IMPLICIT ${implicit.insertText}] `,
      scale: implicit.measurement.scale,
      scaleConvertColor: getColorForMeasuremntId(implicit.measurementId),
      implicit: true,
    };
  };

  const getColorForMeasuremntId = (measurementId?: string) => {
    if (!measurementId) {
      return undefined;
    }

    if (!colorMap[measurementId]) {
      colorIndex = (colorIndex + 1) % measurementColors.length;
      colorMap[measurementId] = measurementColors[colorIndex]!;
    }
    return colorMap[measurementId];
  };

  const getSegmentMetaFromCurrentRanges = (): Omit<Segment, "text"> => {
    // insertText is handled in getSegmentMetaForImplicitFromCurrentRanges
    const measurements = currentRanges.filter(discriminate("type", "measurement")).filter(m => !m.insertText);
    const measurement = measurements[0];
    const multipleMeasurements = measurements.length > 1;

    return {
      color: currentRanges.find(discriminate("type", "tag"))?.color,
      timer: currentRanges.some(cr => cr.type === "timer"),
      scale: measurement?.measurement.scale ?? false,
      // note we aren't checking noConvert here because we haven't handled it in the UI yet - we'd want to add a different underline style, or something
      convert: !!measurement,
      scaleConvertColor: getColorForMeasuremntId(measurement?.measurementId),
      multipleMeasurements,
    };
  };

  let currentSegment: Segment | undefined;

  const getCurrentSegmentMeta = (): Omit<Segment, "text"> | undefined => {
    if (!currentSegment) {
      return undefined;
    }

    // you can't delete a required property, so make the compiler happy
    // we can't just expand and set { text: undefined } because deepEquals won't match
    // if the key is still present (see comparison below)
    const cs = { ...currentSegment } as Omit<Segment, "text"> & { text?: string };
    delete cs.text;
    return cs;
  };

  const segments: Segment[] = [];

  for (let i = 0; i < phrase.phrase.length; i++) {
    addToCurrentRanges(i);
    const segmentMeta = getSegmentMetaFromCurrentRanges();
    const implicitMeta = getSegmentMetaForImplicitFromCurrentRanges();
    if (implicitMeta) {
      if (currentSegment) {
        segments.push(currentSegment);
      }
      currentSegment = {
        ...segmentMeta,
        text: "",
      };
      segments.push(implicitMeta);
    }

    if (!deepEquals(segmentMeta, getCurrentSegmentMeta())) {
      if (currentSegment) {
        segments.push(currentSegment);
      }
      currentSegment = {
        ...segmentMeta,
        text: "",
      };
    }

    currentSegment!.text += phrase.phrase[i];

    removeFromCurrentRanges(i);
  }

  if (currentSegment) {
    segments.push(currentSegment);
  }

  return segments;
}
