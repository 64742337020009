import { withScreenContainer } from "../navigation/ScreenContainer.tsx";
import { EditSimpleAttributionScreenProps } from "../navigation/NavTree.ts";
import { TBody } from "../components/Typography.tsx";
import { UserRecipeId } from "@eatbetter/recipes-shared";

export const EditSimpleAttributionScreen = withScreenContainer(
  "EditSimpleAttributionScreen",
  (props: EditSimpleAttributionScreenProps) => {
    return <TBody>Edit simple attribution for {props.recipeId}</TBody>;
  },
  {
    serializer: {
      recipeId: s => s,
      popCount: n => n.toString(),
    },
    parser: {
      recipeId: s => s as UserRecipeId,
      popCount: s => Number.parseInt(s),
    },
  }
);
