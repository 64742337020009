import { AdminBook, BookId } from "@eatbetter/recipes-shared";
import { useEffect, useState } from "react";
import { useDispatch } from "./AdminRedux";
import { displayUnexpectedErrorAndLogHandler, SetWaitingHandler } from "@eatbetter/ui-shared";
import { getBookOrThrow } from "./books/AdminBooksThunks";

export function useAdminFetchBook(bookId?: BookId, setWaiting?: SetWaitingHandler, refreshToken?: string) {
  const dispatch = useDispatch();
  const [book, setBook] = useState<AdminBook | undefined>(undefined);

  useEffect(() => {
    if (bookId) {
      dispatch(getBookOrThrow(bookId, setWaiting))
        .then(setBook)
        .catch(displayUnexpectedErrorAndLogHandler("Error fetching book in useAdminFetchBook", { bookId }));
    }
  }, [bookId, refreshToken]);

  return book;
}
