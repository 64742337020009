import { reduxPersistKey, SyncThunkAction, ThunkAction } from "../redux/Redux";
import { log } from "../../Log";
import { addSeconds, defaultTimeProvider, EpochMs, newId } from "@eatbetter/common-shared";
import { PushPostComment } from "@eatbetter/posts-shared/src/SocialTypes";
import { PushPostReady } from "@eatbetter/posts-shared";
import {
  NotificationOnboardingHelp,
  OnboardingNotificationPrompt,
  PushNotificationAppNavigation,
  ReceivedNotificationData,
} from "@eatbetter/users-shared";
import { PushRecipeShared } from "@eatbetter/recipes-shared";
import { scheduleNotificationAsync } from "expo-notifications";

export const websocketPushTest = (): ThunkAction<void> => {
  return async (_dispatch, _state, deps) => {
    try {
      await deps.api.withThrow().websocketPush(new Date().toString());
    } catch (err) {
      log.errorCaught("Error from websocketPushTest", err);
    }
  };
};

export const purgeReduxPersist = (): ThunkAction<void> => {
  return async (_dispatch, _state, deps) => {
    await deps.reduxPersistor.purge();
  };
};

export const getReduxPersistValue = (): ThunkAction<string | null> => {
  return async (_dispatch, _state, deps) => {
    return await deps.asyncStorage.getItem(`persist:${reduxPersistKey}`);
  };
};

export const createLocalDebugOnboardingNotification = (type: OnboardingNotificationPrompt): SyncThunkAction<void> => {
  return (_dispatch, getState, _deps) => {
    const state = getState();
    const notificationTargetUserId = state.system.authedUser.data!.userId;
    const d: ReceivedNotificationData<NotificationOnboardingHelp> = {
      type: "users/onboardingPrompt",
      data: { prompt: type },
      notificationTargetUserId,
    };

    scheduleNotification(`Test Onboarding Notification: ${type}`, d);
  };
};

export type TappedNotificationSimulationType = "post" | "comment" | "sharedRecipe" | "navigation";

export const createLocalDebugNotification = (type: TappedNotificationSimulationType): SyncThunkAction<boolean> => {
  return (_dispatch, getState, _deps) => {
    const state = getState();
    const notificationTargetUserId = state.system.authedUser.data!.userId;

    if (type === "sharedRecipe") {
      const share = Object.values(state.notifications.entities).find(n => n?.context.type === "recipes/recipeShared")
        ?.context as PushRecipeShared;
      if (share) {
        const d: ReceivedNotificationData<PushRecipeShared> = {
          type: "recipes/recipeShared",
          notificationTargetUserId,
          data: {
            sharedBy: share.data.sharedBy,
            sharedByRecipeId: share.data.sharedByRecipeId,
            sourceRecipeId: share.data.sourceRecipeId,
          },
        };

        scheduleNotification("Test shared recipe notification", d);
        return true;
      }
    }

    if (type === "post" || type === "comment") {
      const posts = Object.values(state.social.posts);
      const post = posts.find(p => p.comments.items.length > 0) ?? posts[0];

      if (!post) {
        log.info("No posts found. Can't schedule debug notifcation");
        return false;
      }

      if (type === "post") {
        const d: ReceivedNotificationData<PushPostReady> = {
          type: "social/postReady",
          data: {
            postId: post.id,
          },
          notificationTargetUserId,
        };

        scheduleNotification("Test post notification", d);
        return true;
      }

      if (type === "comment") {
        const d: ReceivedNotificationData<PushPostComment> = {
          type: "social/postComment",
          data: {
            postId: post.id,
            commentUserId: post.comments.items[0]!.user.userId,
            commentId: post.comments.items[0]!.id,
          },
          notificationTargetUserId,
        };

        scheduleNotification("Test comment notification", d);
        return true;
      }
    }

    if (type === "navigation") {
      const d: ReceivedNotificationData<PushNotificationAppNavigation> = {
        notificationTargetUserId,
        type: "users/appNavigation",
        data: {
          tabName: "homeTab",
          screen: "home",
          params: undefined,
        },
      };
      scheduleNotification("Nav to home", d);
      return true;
    }

    log.info("Unable to schedule debug notification");
    return false;
  };
};

function scheduleNotification(title: string, data: any) {
  scheduleNotificationAsync({
    identifier: newId(),
    content: {
      title,
      data,
    },
    trigger: {
      date: addSeconds(defaultTimeProvider(), 10),
    },
  })
    .then(() => log.info(`Scheduled debug notification: ${title}`))
    .catch(err => log.errorCaught("Unexpected error in DebugThunks.scheduleNotification", err));
}

interface WebsocketDebugInfo {
  lastPingLatency: number | undefined;
  lastPingResponse: EpochMs | undefined;
}

export const websocketDebugInfo = (): SyncThunkAction<WebsocketDebugInfo> => {
  return (_dispatch, _getState, deps) => {
    const ws = deps.websocketConnection;
    return {
      lastPingLatency: ws.lastPingLatency,
      lastPingResponse: ws.lastPingResponse,
    };
  };
};
