import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";

import {
  useAllCompletedCheckpoints,
  useAllNumericCheckpoints,
  useNumericCheckpointValue,
} from "../lib/system/SystemSelectors";
import {
  getAllNumericUserCheckpoints,
  getAllUserCheckpoints,
  NumericUserCheckpoint,
  UserCheckpoint,
} from "@eatbetter/users-shared";
import { ScreenView } from "../components/ScreenView";
import { Switch, View } from "react-native";
import { TBody, THeading2 } from "../components/Typography";
import { ContainerEnd } from "../components/Containers";
import { checkpointsCleared, checkpointsCompleted, numericCheckpointsUpdated } from "../lib/system/SystemSlice";
import { globalStyleColors, globalStyleConstants } from "../components/GlobalStyles";
import { useBottomSheet } from "./BottomSheetScreen";
import { TextInput } from "../components/TextInput";
import { useState } from "react";
import { SubmitAndCancelButtons } from "../components/Buttons";
import { navTree } from "../navigation/NavTree";
import { Spacer } from "../components/Spacer";
import { Separator } from "../components/Separator";
import { useDispatch } from "../lib/redux/Redux";

const allKeys = getAllUserCheckpoints();
const allNumericKeys = getAllNumericUserCheckpoints();

export const DebugCheckpointsScreen = withScreenContainer("DebugCheckpointsScreen", () => {
  const completed = useAllCompletedCheckpoints();
  const numeric = useAllNumericCheckpoints();
  const dispatch = useDispatch();
  const { nav } = useScreen();

  const onValueChange = (enabled: boolean, cp: UserCheckpoint) => {
    const action = enabled ? checkpointsCompleted([cp]) : checkpointsCleared([cp]);
    dispatch(action);
  };

  const onNumericValueChange = (key: NumericUserCheckpoint) => {
    nav.modal(navTree.get.screens.bottomSheet, { content: <UpdateNumericCheckpoint keyName={key} />, height: 500 });
  };

  return (
    <ScreenView header={{ type: "default", title: "Checkpoints" }}>
      {allKeys.map(key => {
        return (
          <View key={key} style={{ flexDirection: "row", paddingBottom: globalStyleConstants.unitSize }}>
            <TBody fontWeight="medium">{key}</TBody>
            <ContainerEnd horizontal>
              <Switch value={completed.includes(key)} onValueChange={enabled => onValueChange(enabled, key)} />
            </ContainerEnd>
          </View>
        );
      })}
      <Spacer vertical={1} />
      <Separator orientation="row" />
      <Spacer vertical={1} />
      {allNumericKeys.map(key => {
        return (
          <View
            key={key}
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              paddingBottom: globalStyleConstants.unitSize,
            }}
          >
            <View style={{ flexShrink: 1 }}>
              <TBody fontWeight="medium" numberOfLines={1} adjustsFontSizeToFit>
                {key}
              </TBody>
            </View>
            <Spacer horizontal={1} />
            <View>
              <TBody color={globalStyleColors.colorTextLink} onPress={() => onNumericValueChange(key)}>
                {numeric[key] ?? "(not set)"}
              </TBody>
            </View>
          </View>
        );
      })}
    </ScreenView>
  );
});

const UpdateNumericCheckpoint = (props: { keyName: NumericUserCheckpoint }) => {
  const val = useNumericCheckpointValue(props.keyName);
  const dispatch = useDispatch();
  const [text, setText] = useState(val?.toString() ?? "");
  const onTextUpdate = (s: string) => {
    setText([...s].filter(c => "0123456789".includes(c)).join(""));
  };
  const b = useBottomSheet();

  const onUpdate = () => {
    const newVal = text.trim() === "" ? null : parseInt(text);
    dispatch(numericCheckpointsUpdated({ [props.keyName]: newVal }));
    b?.closeSheetAndGoBack();
  };

  return (
    <View style={{ flex: 1, padding: 2 * globalStyleConstants.unitSize }}>
      <THeading2>Update {props.keyName}</THeading2>
      <Spacer vertical={1} />
      <TextInput value={text} onChangeText={onTextUpdate} keyboardType="number-pad" showClearButton />
      <Spacer vertical={1} />
      <SubmitAndCancelButtons onSubmit={onUpdate} onCancel={() => b?.closeSheetAndGoBack()} />
    </View>
  );
};
