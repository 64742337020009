import { useEffect, useRef } from "react";
import { WebViewSessionId, addWebViewSession, removeWebViewSession } from "./WebViewSlice";
import { UrlString, newId } from "@eatbetter/common-shared";
import { useDispatch } from "../redux/Redux";

export const useWebViewSession = (url: UrlString | undefined): WebViewSessionId | undefined => {
  const sessionId = useRef(newId<WebViewSessionId>()).current;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!url) {
      return;
    }

    dispatch(addWebViewSession({ sessionId, url }));

    return () => {
      dispatch(removeWebViewSession({ sessionId }));
    };
  }, [sessionId, url]);

  if (!url) {
    return undefined;
  }

  return sessionId;
};
