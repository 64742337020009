import { getPathAndQuery, newId, UserId } from "@eatbetter/common-shared";
import { PartialRecipeId, RecipeId } from "@eatbetter/recipes-shared";
import { useCallback, useRef, useState } from "react";
import { Platform } from "react-native";
import { saveSharedRecipe } from "../recipes/RecipesThunks";
import { displayUnexpectedErrorAndLog } from "../Errors";
import { useDispatch } from "../redux/Redux";
import { navTree } from "../../navigation/NavTree";
import { getLinkForNavigableScreen, useScreen } from "../../navigation/ScreenContainer";
import { useAuthedUser } from "../system/SystemSelectors";
import { analyticsEvent } from "../analytics/AnalyticsThunks";
import { reportSaveRecipeTappedOnShareScreen, reportSignUpTappedOnShareScreen } from "../analytics/AnalyticsEvents";

interface Args {
  sharingUserId: UserId;
  sharedRecipeId?: RecipeId;
  sharedSourceRecipeID?: RecipeId;
  viewingUserHasRecipe: boolean;
  onRecipeSaved?: () => void;
}

const sessionSaveKey = (r: RecipeId) => `save-${r}`;

export const useShareActions = (args: Args) => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const partialRecipeId = useRef(newId<PartialRecipeId>()).current;
  const [recipeSaved, setRecipeSaved] = useState(false);
  const [waitingForSave, setWaitingForSave] = useState(false);
  const authedUser = useAuthedUser();

  // this is for the share external recipe screen to pass to the view user recipe external screen so that if the user goes back, they
  // see the correct status. If we need any more state sharing, we should switch to storing some of this in redux.
  const onRecipeSaved = useCallback(() => {
    setRecipeSaved(true);
  }, [setRecipeSaved]);

  const doSave = useCallback(() => {
    if (!args.sharedRecipeId || Platform.OS !== "web") {
      return Promise.resolve();
    }

    return dispatch(
      saveSharedRecipe(
        {
          sharedByRecipeId: args.sharedRecipeId,
          partialRecipeId,
          sourceRecipeId: args.sharedSourceRecipeID ?? args.sharedRecipeId,
          sharedByUserId: args.sharingUserId,
        },
        "userShared",
        setWaitingForSave
      )
    )
      .then(() => {
        sessionStorage.removeItem(sessionSaveKey(args.sharedRecipeId!));
        setRecipeSaved(true);
        args.onRecipeSaved?.();
      })
      .catch(err => {
        displayUnexpectedErrorAndLog("Error calling saveSharedRecipe on from useShareActions", err, {
          recipeId: args.sharedRecipeId,
          userId: args.sharingUserId,
          sourceRecipeId: args.sharedSourceRecipeID,
          partialRecipeId,
        });
      });
  }, [args.sharingUserId, args.sharedRecipeId, partialRecipeId, args.sharedSourceRecipeID, setRecipeSaved, dispatch]);

  const onPressSignUp = useCallback(() => {
    dispatch(analyticsEvent(reportSignUpTappedOnShareScreen()));
    screen.nav.goTo("push", navTree.get.screens.signIn, { showBackButton: true });
  }, [screen.nav.goTo, dispatch]);

  const onPressSave = useCallback(() => {
    if (!args.sharedRecipeId || Platform.OS !== "web") {
      return;
    }

    dispatch(analyticsEvent(reportSaveRecipeTappedOnShareScreen()));

    if (authedUser) {
      // the submit button displays a spinner until the promise resolves.
      return doSave();
    } else {
      // if the user is not signed in, come back to this screen after auth and add a partial ID to complete the save.
      // We pass the partial ID to help ensure the recipe can only be saved once.
      sessionStorage.setItem(sessionSaveKey(args.sharedRecipeId), "1");
      const redirectLink = getLinkForNavigableScreen(navTree.get.screens.externalSharedRecipe, {
        userId: args.sharingUserId,
        sharedRecipeId: args.sharedRecipeId,
        sourceRecipeId: args.sharedSourceRecipeID,
        action: "save",
      });
      const redirectPath = getPathAndQuery(redirectLink);
      screen.nav.goTo("push", navTree.get.screens.signIn, { showBackButton: true, redirectPath });
      return Promise.resolve();
    }
  }, [
    doSave,
    args.sharingUserId,
    args.sharedRecipeId,
    args.sharedSourceRecipeID,
    screen.nav.goTo,
    authedUser,
    dispatch,
  ]);

  return {
    doSave,
    onPressSave,
    onPressSignUp,
    recipeSaved: recipeSaved || args.viewingUserHasRecipe,
    waitingForSave,
    onRecipeSaved,
  };
};
