// Using this to find a system sound. Should be able to ditch this library
// once we have our own sounds
import { log } from "../Log";
import { EpochMs, UserId } from "@eatbetter/common-shared";
import { CookingTimerId, LocalNotificationId } from "./cooking/CookingSessionsSlice";
import {
  cancelScheduledNotificationAsync,
  dismissNotificationAsync,
  scheduleNotificationAsync,
} from "expo-notifications";
import { ReceivedNotificationData } from "@eatbetter/users-shared";
import { TimerNotificationContext } from "./NotificationHandlers";
import { getVolume, setVolume } from "./Volume";
import { NativeAudioApi } from "./NativeAudio";
import * as FileSystem from "expo-file-system";
import { AppStateStatus } from "react-native";

const alarmFile = "media/alarm.mp3";
const highAlarmFile = "media/alarm_high_pitch.m4r";
const lowAlarmFile = "media/alarm_low_pitch.m4r";
const backgroundFile = "media/one_second.mp3";

const maxVolume = 0.75;
const volumeIncreaseTimeout = 15000;

interface AlarmSoundFilePaths {
  background: string;
  alarm: string;
}

export class TimerAlarm {
  private previousSystemVolume: number | undefined;
  private timeoutHandle: ReturnType<typeof setTimeout> | undefined;
  private readonly nativeAudioApi: NativeAudioApi;
  private initP: Promise<void> | undefined;

  // this automatic increase in volume works, but disabling for now as it is questionable if it's what users want
  // there might be a use case for silent alarms if the user has the phone visible and has, for example, a sleeping baby
  // Possibly expose this as a user option?
  private readonly increaseVolumeEnabled = false;

  constructor(args?: { audioApi?: NativeAudioApi }) {
    this.nativeAudioApi = args?.audioApi ?? new NativeAudioApi();
  }

  async init(): Promise<void> {
    // save the promise so other functions can wait for it to complete.
    // There is a race condition in initializaiton, which is pretty slow (about half a second)
    // and when the first function in this class gets called.
    if (!this.initP) {
      this.initP = (async () => {
        const files = this.getAlarmSoundFilePaths();
        await this.nativeAudioApi.init({
          timerAlarmFilePath: files.alarm,
          backgroundFilePath: files.background,
        });
      })();
    }

    return this.initP!;
  }

  async handleAppStateChanged(appState: AppStateStatus): Promise<void> {
    await this.waitForInit();
    if (appState === "active" || appState === "background") {
      await this.nativeAudioApi.appStateChanged({ appState });
    }
  }

  /**
   * Starts the background audio so that if the user switches away from the app, the audio stays active
   */
  async startBackgroundAudio(): Promise<void> {
    try {
      await this.waitForInit();
      await this.nativeAudioApi.startBackgroundAudio();
    } catch (err) {
      log.errorCaught("Error in TimerAlarm.startBackgroundAudio", err);
      throw err;
    }
  }

  async stopBackgroundAudio(): Promise<void> {
    try {
      await this.waitForInit();
      await this.nativeAudioApi.stopBackgroundAudio();
    } catch (err) {
      log.errorCaught("Error in TimerAlarm.stopBackgroundAudio", err);
      throw err;
    }
  }

  async startAlarm(): Promise<void> {
    try {
      await this.waitForInit();
      await this.nativeAudioApi.startTimerAlarm();

      if (this.increaseVolumeEnabled) {
        const volume = await getVolume();
        if (volume === 0) {
          this.previousSystemVolume = volume;
          setVolume(0.5);
        }

        await this.setIncreaseVolumeTimeout();
      }
    } catch (err) {
      log.errorCaught("Unexpected error in TimerAlarm.startAlarm", err);
      throw err;
    }
  }

  async stopAlarm(): Promise<void> {
    try {
      if (this.timeoutHandle) {
        clearTimeout(this.timeoutHandle);
        this.timeoutHandle = undefined;
      }

      await this.waitForInit();
      await this.nativeAudioApi.stopTimerAlarm();

      // go ahead and reset the volume. If it has changed, this will lower the volume back to the user's previous setting
      if (this.previousSystemVolume !== undefined) {
        setVolume(this.previousSystemVolume);
        this.previousSystemVolume = undefined;
      }
    } catch (err) {
      log.errorCaught("Unexpected error in TimerAlarm.stopAlarm", err);
      throw err;
    }
  }

  async createNotification(args: {
    userId: UserId;
    timerId: CookingTimerId;
    notificationTime: EpochMs;
    recipeTitle?: string;
    sound?: "highPitch" | "lowPitch";
  }): Promise<LocalNotificationId> {
    const data: ReceivedNotificationData<TimerNotificationContext> = {
      type: "timers/timerComplete",
      data: { timerId: args.timerId },
      notificationTargetUserId: args.userId,
    };

    const sound = args.sound === "highPitch" ? highAlarmFile : args.sound === "lowPitch" ? lowAlarmFile : undefined;

    const id = await scheduleNotificationAsync({
      identifier: `${args.timerId}-${args.notificationTime}`,
      content: {
        title: "Timer Complete ⏲",
        body: args.recipeTitle,
        sound,
        data: data as any,
      },
      trigger: {
        date: args.notificationTime,
      },
    });

    return id as LocalNotificationId;
  }

  async deleteNotification(notificationId: LocalNotificationId): Promise<void> {
    await cancelScheduledNotificationAsync(notificationId);
    await dismissNotificationAsync(notificationId);
  }

  private async waitForInit(): Promise<void> {
    if (!this.initP) {
      throw new Error("Init has not yet been called on this TimerAlarm instance");
    }

    return this.initP;
  }

  private async setIncreaseVolumeTimeout(): Promise<void> {
    if (this.timeoutHandle) {
      clearTimeout(this.timeoutHandle);
      this.timeoutHandle = undefined;
    }

    this.timeoutHandle = setTimeout(async () => {
      const currentVolume = await getVolume();

      if (currentVolume >= maxVolume) {
        return;
      }

      if (this.previousSystemVolume === undefined) {
        this.previousSystemVolume = currentVolume;
      }

      setVolume(Math.min(currentVolume + 0.1, maxVolume));

      await this.setIncreaseVolumeTimeout();
    }, volumeIncreaseTimeout);
  }

  private getAlarmSoundFilePaths(): AlarmSoundFilePaths {
    return {
      alarm: FileSystem.bundleDirectory + alarmFile,
      background: FileSystem.bundleDirectory + backgroundFile,
    };
  }
}
