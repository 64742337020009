import { EpochMs, SuccessOrFailure } from "@eatbetter/common-shared";

export interface ServiceStatus {
  activeIssue?: ActiveIssue;
}

export interface ActiveIssue {
  title: string;
  body: string;
  lastUpdate: EpochMs;
}

export function getServiceStatusUrl(env: "prod" | "dev" | "local") {
  const path = "/service-status/status.json";
  const domain = env === "prod" ? "https://cdn.deglaze.app" : "https://cdn.mooklab-dev.link";

  return `${domain}${path}`;
}

export function validateServiceStatus(s: ServiceStatus): SuccessOrFailure<never, string> {
  if (!s.activeIssue) {
    return { success: true };
  }

  return validateServiceStatusWithIssue(s);
}

export function validateServiceStatusWithIssue(s: ServiceStatus): SuccessOrFailure<never, string> {
  const keys = Object.keys(s);
  if (!s.activeIssue) {
    return { success: false, result: `Expecting activeIssue. Got ${keys}` };
  }

  const issue = s.activeIssue;
  const issueKeys = Object.keys(issue);
  if (!issue.title || !issue.body || !issue.lastUpdate) {
    return { success: false, result: `Expecting title, body, lastUpdate keys on activeIssue. Got ${issueKeys}` };
  }

  if (issue.title !== issue.title.trim() || issue.body !== issue.body.trim()) {
    return { success: false, result: "Title or body is not trimmed" };
  }

  const maxTitleLength = 40;
  if (issue.title.length > maxTitleLength) {
    return {
      success: false,
      result: `Expecting title no longer than ${maxTitleLength} characters. Got length of ${issue.title.length}`,
    };
  }

  return { success: true };
}
