import { TypedPrimitive } from "@eatbetter/common-shared";

export type ShoppableItemId = TypedPrimitive<string, "ShoppableItemId">;
export type DisambiguationItemId = TypedPrimitive<string, "DisambiguationItemId">;

export type Plurality = "plural" | "singular";
export interface Forms {
  s: string;
  p: string;
}

export interface GroceryListAutocompleteQuery {
  q: string;
}

export interface GroceryListAutocompleteResults {
  results: GroceryListAutocompleteResult[];
}

export interface GroceryListAutocompleteResult {
  phrase: string;
  category: StandardCategory;
  forms: Forms;
  shoppableId: ShoppableItemId;
}

export interface DebugParserRequest {
  phrase: string;
}

export type ParsedIngredientTags = Array<{ range: [number, number]; color: string }>;

export interface ParsedIngredientSummary {
  phrase: string;
  quantity?: string;
  unit?: string;
  ingredient?: string;
  category?: string;
  categoryOverriddenFrom?: string;
  normalizedPhrase: string;
  shoppablePhrase?: string;
  tags: ParsedIngredientTags;
}

export const standardCategories = [
  "alcohol",
  "produce",
  "dairy",
  "meat",
  "fish",
  "spices",
  "pantry",
  "frozen",
  "refrigerated",
  "other",
] as const;

export type StandardPrimaryCategory = (typeof standardCategories)[number];
export type StandardSubcategory = TypedPrimitive<number, "standardSubcategory">;

/**
 * Default subcategory to use if we don't know the subcategory.
 */
export const unknownSubcategory = Number.MAX_SAFE_INTEGER as StandardSubcategory;

export interface StandardCategory {
  primary: StandardPrimaryCategory;
  sub: StandardSubcategory;
}
