import { UserId } from "@eatbetter/common-shared";
import { ScreenView } from "../components/ScreenView";
import { OtherUserProfileScreenProps } from "../navigation/NavTree";
import { withScreenContainer } from "../navigation/ScreenContainer";
import { useProfileInfo } from "../lib/social/SocialSelectors";
import { useMemo } from "react";
import { HeaderProps } from "../components/ScreenHeaders";
import { FollowersFollowing } from "../components/social/FollowersFollowing";

const strings = {
  screenTitle: (username?: string) => `${username ?? "User"} is following`,
};

export const OtherUserFollowingScreen = withScreenContainer<OtherUserProfileScreenProps>(
  "OtherUserFollowingScreen",
  props => {
    const profileInfo = useProfileInfo(props.userId);

    const header = useMemo<HeaderProps>(() => {
      return {
        type: "default",
        title: strings.screenTitle(profileInfo?.user.username),
        backgroundColor: "transparent",
      };
    }, [profileInfo?.user.username]);

    return (
      <ScreenView
        header={header}
        scrollView={false}
        paddingHorizontal={"none"}
        paddingVertical={"headerAndBottomTabBar"}
        loading={!profileInfo}
      >
        <FollowersFollowing userId={props.userId} />
      </ScreenView>
    );
  },
  {
    serializer: {
      userId: s => s,
    },
    parser: {
      userId: s => s as UserId,
    },
  }
);
