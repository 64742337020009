import { UserId } from "./CommonTypes";

export type AppVersion = `${number}.${number}.${number}.${number}` | `${number}.${number}.${number}`;

// NOTE these headers are configured to be forwarded in cloudfront.tf. If this list
// changes, that file should likely be updated as well.
export const requestHeaderNames = {
  platformHeader: "x-platform",
  deviceTypeHeader: "x-device-type",
  deviceIdHeader: "x-device-id",
  deviceOsVesionHeader: "x-device-os-version",
  gitShaHeader: "x-sha",
  appVersionHeader: "x-app-version",
  impersonateHeader: "x-impersonate",
  devBuildHeader: "x-dev",
} as const;

export const responseHeaderNames = {
  awsRegionHeader: "x-region",
  latency: "x-lat",
};

export const platforms = ["ios", "ios-share-extension", "web", "web-admin"] as const;
export type ClientPlatform = (typeof platforms)[number];

export interface MetaHeaders {
  [key: string]: string;
}

export interface ClientInfo {
  platform?: ClientPlatform;
  gitSha?: string;
  devBuild?: boolean;
  appVersion?: AppVersion;
  deviceType?: string;
  deviceId?: string;
  deviceOsVersion?: string;
  impersonateUserId?: UserId;
}
