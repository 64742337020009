import * as TaskManager from "expo-task-manager";
import { log } from "../Log";
import { getGlobalDispatch } from "./redux/Redux";
import { waitForReduxPersist } from "./system/SystemThunks";
import { loadNewNotifications } from "./notifications/NotificationsThunks";
import { defaultTimeProvider } from "@eatbetter/common-shared";

export const backgroundNotificationTaskName = "backgroundNotificationTask";

// note: It might make sense to keep app status in redux and have the reactors not run if the app is not foregrounded
TaskManager.defineTask(backgroundNotificationTaskName, async ({ data, error, executionInfo }) => {
  log.info("Received a notification in the background!", { data, error, executionInfo });

  try {
    const start = defaultTimeProvider();
    const dispatch = getGlobalDispatch();

    if (!dispatch) {
      log.error("Dispatch is undefined in background notification task");
      return;
    }

    // Make sure redux-persist is fully ready to go.
    // Logging with errors throughout this function so they are reported to
    // datadog.
    log.info("Waiting for ReduxPersist");
    await dispatch(waitForReduxPersist());
    log.info("waitForReduxPersist resolved");

    // this will fetch notifications and set the notification count correctly
    await dispatch(loadNewNotifications());
    const end = defaultTimeProvider();
    log.info(`Background notification handler completed successfully in ${end - start}ms`);
  } catch (err) {
    log.errorCaught("Caught error in background notification handler", err);
  }
});
