export function filterUndefinedFromObject<T extends object>(o: T): T {
  const entries = Object.entries(o).flatMap(e => {
    if (e[1] === undefined) {
      return [];
    }

    return [e];
  });

  return Object.fromEntries(entries) as T;
}
