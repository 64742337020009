import React, { useCallback, useEffect } from "react";
import { usePushPermission } from "../../lib/system/SystemSelectors";
import {
  notificationPermissionsScreenDisplayed,
  requestPushPermissionOrNavToSettings,
} from "../../lib/PushNotificationThunks";
import { useDispatch } from "../../lib/redux/Redux";
import { log } from "../../Log";
import { View } from "react-native";
import { THeading1, TSecondary } from "../Typography";
import { Spacer } from "../Spacer";
import { BottomActionBar } from "../BottomActionBar";
import { StyleSheet } from "react-native";
import { switchReturn } from "@eatbetter/common-shared";
import { useBottomSheet } from "../../screens/BottomSheetScreen";
import { globalStyleConstants } from "../GlobalStyles";

const strings = {
  yes: "Enable",
  no: "Not Now",
  firstTime: {
    headline: "Enable notifications?",
    body: "We'll notify you when cooking timers complete and when friends interact with your content",
  },
  timers: {
    headline: "Enable timers?",
    body: "We need your permission to notify you when timers complete",
  },
};

export const notificationPermissionConstants = {
  height: 280,
};

export interface NotificationPermissionProps {
  context: "firstTime" | "timers";
  onGranted?: () => void;
}

export const NotificationPermission = React.memo((props: NotificationPermissionProps) => {
  const dispatch = useDispatch();
  const pushPermission = usePushPermission();
  const bottomSheet = useBottomSheet();

  useEffect(() => {
    dispatch(notificationPermissionsScreenDisplayed(props.context));
  }, [props.context]);

  useEffect(() => {
    if (pushPermission?.havePermission) {
      props.onGranted?.();
      bottomSheet?.closeSheetAndGoBack();
    }
  }, [pushPermission?.havePermission]);

  const onPressAccept = useCallback(() => {
    dispatch(requestPushPermissionOrNavToSettings())
      .then(r => {
        if (!r.havePermission && !r.settingsOpened) {
          // if the user rejects us form the system prompt, go ahead and close.
          // if they accept, or if we opened settings, don't close.
          // In the settings case, we'll detect the change on app focus
          // Regardless of how it changes, the useEffect above needs to run to fire the onGranted handler.
          bottomSheet?.closeSheetAndGoBack();
        }
      })
      .catch(err =>
        log.errorCaught("Error calling requestPushPermissionsOrNavToSettings from NotificationPermissionsScreen", err)
      );
  }, [dispatch, bottomSheet?.closeSheetAndGoBack]);

  const onPressReject = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
  }, [bottomSheet?.closeSheetAndGoBack]);

  const copy = switchReturn(props.context, {
    firstTime: strings.firstTime,
    timers: strings.timers,
  });

  return (
    <>
      <View style={styles.content}>
        <THeading1 align="center">{copy.headline}</THeading1>
        <Spacer vertical={1.5} />
        <View style={{ height: 64 }}>
          <TSecondary align="center" numberOfLines={3}>
            {copy.body}
          </TSecondary>
        </View>
      </View>
      <Spacer vertical={3} />
      <BottomActionBar
        primaryAction={{ actionText: strings.yes, onPressAction: onPressAccept }}
        secondaryAction={{ actionText: strings.no, onPressAction: onPressReject }}
        containerBackgroundColor="transparent"
        disableSnapToBottom
      />
    </>
  );
});

const styles = StyleSheet.create({
  content: {
    paddingTop: 3 * globalStyleConstants.unitSize,
    paddingHorizontal: globalStyleConstants.defaultPadding,
  },
});
