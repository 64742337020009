import React, { useCallback, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { TSecondary } from "../Typography";
import { Spacer } from "../Spacer";
import { ButtonRectangle } from "../Buttons";
import { useDispatch } from "../../lib/redux/Redux";
import { globalStyleConstants } from "../GlobalStyles";
import { Separator } from "../Separator";
import { analyticsEvent } from "../../lib/analytics/AnalyticsThunks";
import { useScreen } from "../../navigation/ScreenContainer";
import { navTree } from "../../navigation/NavTree";
import { reportFollowCollectionsFeedModuleButtonPressed } from "../../lib/analytics/AnalyticsEvents";
import { switchReturn } from "@eatbetter/common-shared";
import { CtaLocation } from "@eatbetter/composite-shared";
import { checkpointsCompleted } from "../../lib/system/SystemSlice";

const strings = {
  cta: "Browse Recipe Collections",
  emptyFeed:
    "Follow your favorite recipe sites + authors to get never-ending meal inspiration from people you trust. You'll see their latest recipes here.",
  inlineFeed: "Follow your favorite recipe sites + authors to discover new recipes from people you trust.",
};

interface FollowCollectionsFeedModuleProps {
  context: "emptyFeed" | "inlineFeed";
}

export const FollowCollectionsFeedModule = React.memo((props: FollowCollectionsFeedModuleProps) => {
  const dispatch = useDispatch();
  const { nav } = useScreen();

  const ctaLocation: CtaLocation = switchReturn(props.context, {
    emptyFeed: "Empty Feed",
    inlineFeed: "Inline Feed",
  });

  useEffect(() => {
    if (props.context === "emptyFeed") {
      // we display context in the empty feed, so mark this as completed
      dispatch(checkpointsCompleted(["known_follow_explained"]));
    }
  }, [props.context]);

  const onPress = useCallback(() => {
    dispatch(analyticsEvent(reportFollowCollectionsFeedModuleButtonPressed(ctaLocation)));
    nav.goTo("push", navTree.get.screens.browseKnownEntities, {
      searchQuery: "",
      analyticsContext: props.context === "emptyFeed" ? "followingFeedEmptyState" : "followingFeedInlined",
    });
  }, [dispatch, props.context]);

  const message = strings[props.context];

  return (
    <>
      <View style={props.context === "emptyFeed" ? styles.emptyFeed : styles.inlineFeed}>
        <TSecondary
          fontWeight={props.context === "emptyFeed" ? "normal" : "medium"}
          align={props.context === "emptyFeed" ? "left" : "center"}
        >
          {message}
        </TSecondary>
        <Spacer vertical={1.5} />
        <ButtonRectangle
          type={props.context === "inlineFeed" ? "submit" : "secondary"}
          icon="searchUsers"
          size="medium"
          singlePress
          title={strings.cta}
          onPress={onPress}
        />
      </View>
      {props.context === "inlineFeed" && (
        <View style={{ paddingHorizontal: globalStyleConstants.minPadding }}>
          <Spacer vertical={3} />
          <Separator orientation="row" />
        </View>
      )}
    </>
  );
});

const styles = StyleSheet.create({
  emptyFeed: {},
  inlineFeed: {
    paddingHorizontal: globalStyleConstants.defaultPadding,
    paddingTop: 3 * globalStyleConstants.unitSize,
  },
});
