/**
 * Used as a user is typing a potential mention. This function lets the caller know if the
 * current text is the start to a potentially valid @mention. When this function returns false,
 * the caller can stop querying the backend to retrieve users.
 */
export function shouldQueryForMentionUsers(value: string) {
  const r = /^@[a-zA-Z0-9-_.]{0,29}[a-zA-Z0-9-_]?$/;
  return r.test(value);
}

export interface ParsedMention {
  type: "mention";
  username: string;
  mention: string;
}

export interface ParseMentionsResult {
  usernames: string[];
  tokens: Array<string | ParsedMention>;
}

export function parseMentions(text: string): ParseMentionsResult {
  // this is derived from the username regex (Usernames.ts), with the following differences:
  // 1. Allows uppercase letters (since it's from user input)
  // 2. Requires the @ since it's a mention
  // 3. Must begin with whitespace or the start of the string
  // 4. Must end with whitespace or punctuation at the end of the string
  // ?<= is a lookbehind assertion and ?= is a lookahead assertion. These require that the characters be there,
  // but doesn't count them as part of the match. Otherwise, "@jacob @angel" would be problematic if @jacob
  // matched the space, therefore meaning that @angel wouldn't match since it is not preceded by whitespace
  // or the start of the string.

  // NOTE: Turns out ios and safari doesn't support lookbehind assertions. This is what we previously had:
  // const regex = /(?<=^|[\s])@[a-zA-Z0-9-_.]{2,29}[a-zA-Z0-9-_](?=$|[\s,.])/g;
  // We work around this in a hacky way below
  const regex = /(^|[\s])@[a-zA-Z0-9-_.]{2,29}[a-zA-Z0-9-_](?=$|[\s,.!?:'’])/g;

  const tokens: Array<string | ParsedMention> = [];
  const usernames: string[] = [];

  let match: RegExpExecArray | null;
  let currentIndex = 0;
  while ((match = regex.exec(text))) {
    // with the lookbehind assertion, no leading whitespace was included in the match, but we need to account
    // for it now.
    const mentionWithPossibleWhitespace = match[0]!.toLowerCase();
    const mention = mentionWithPossibleWhitespace.trimStart();
    const username = mention.substring(1);

    // because we can't use lookbehind assertions, we will look for whitepace at the beginning of the match
    // and add that back to the plain text
    const adjustment = mention.length - mentionWithPossibleWhitespace.length;

    const plainText = text.substring(currentIndex, match.index - adjustment);
    if (plainText) {
      tokens.push(plainText);
    }

    tokens.push({
      type: "mention",
      mention,
      username,
    });

    if (!usernames.includes(username)) {
      usernames.push(username);
    }

    currentIndex = match.index + match[0]!.length;
  }

  const end = text.substring(currentIndex);
  if (end) {
    tokens.push(end);
  }

  return {
    usernames,
    tokens,
  };
}
