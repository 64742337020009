import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ScreenView, useScreenElementDimensions } from "../components/ScreenView";
import { useDispatch, useSelector } from "../lib/redux/Redux";
import { UserSearchResults, useUserSearchUsers } from "../lib/users/UsersSelectors";
import { searchUsers } from "../lib/users/UsersThunks";
import { log } from "../Log";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { impersonateUser } from "../lib/system/SystemThunks";
import { UserId } from "@eatbetter/common-shared";
import { ButtonRectangle } from "../components/Buttons";
import { View } from "react-native";
import { SearchAndFilterBar, useSearchAndFilterBarHeight } from "../components/SearchBox";
import { useKeyboardLayoutAnimation } from "../components/Keyboard";
import { globalStyleConstants } from "../components/GlobalStyles";
import { SelectEntity, SelectEntityType, SingleSelectEntity } from "../components/SelectEntity";
import { isDeglazeUser } from "@eatbetter/posts-shared";
import { resetState } from "../lib/users/UserSearchSlice";

const strings = {
  screenHeader: "Impersonate User",
  searchPlaceholder: "Search users on Deglaze",
  clearImpersonatedUser: "Clear impersonated user",
  startImpersonating: (name: string) =>
    `You will now be signed out. When you sign back in, you will be impersonating ${name}`,
  stopImpersonating: "You will now be signed out. When you sign back in, you will no longer be impersonating",
};

//
export const ImpersonateUserScreen = withScreenContainer("ImpersonateUserScreen", () => {
  const [searchPhrase, setSearchPhrase] = useState("");
  const searchResults = useUserSearchUsers();
  const dispatch = useDispatch();
  const screen = useScreen();
  const impersonatedUserId = useSelector(s => s.system.impersonateUser);

  useEffect(() => {
    dispatch(searchUsers("", { type: "adminSearchUsers" })).catch(err =>
      log.errorCaught("Unexpected error calling searchUsers when loading ImpersonateUsersScreen", err)
    );

    // clear the search results so we don't get known-associated users in user search to avoid confusion
    return () => {
      dispatch(resetState({ initialUsers: [] }));
    };
  }, []);

  const onChangeSearchPhrase = useCallback(
    (searchPhrase: string) => {
      setSearchPhrase(searchPhrase);
      dispatch(searchUsers(searchPhrase, { type: "adminSearchUsers" })).catch(err => {
        log.errorCaught("Unexpcted error in SearchUsersScreen.onChangeSearchPhrase", err);
      });
    },
    [dispatch, setSearchPhrase]
  );

  const onSelectUser = useCallback(
    (i?: SelectEntityType) => {
      const d = i?.entity;

      if (d && !isDeglazeUser(d)) {
        alert(`Can only impersonate users. Can't impersonate ${d}`);
        return;
      }

      const message = d ? strings.startImpersonating(d.name) : strings.stopImpersonating;
      alert(message);

      const userId = d?.userId;
      dispatch(impersonateUser(userId, screen.nav)).catch(err => {
        log.errorCaught("Error impersonating user", err);
        alert(`Error attempting to set impersonateUser: ${err}`);
      });
    },
    [screen.nav]
  );

  return React.createElement<Props>(ImpersonateUserComponent, {
    searchPhrase,
    onChangeSearchPhrase,
    searchResults,
    onSelectUser,
    impersonatedUserId,
  });
});

interface Props {
  impersonatedUserId?: UserId;
  searchPhrase: string;
  onChangeSearchPhrase: (v: string) => void;
  onSelectUser: (d: SelectEntityType | undefined) => void;
  searchResults: UserSearchResults;
}

const ImpersonateUserComponent = (props: Props) => {
  const { headerHeight, bottomTabBarHeight } = useScreenElementDimensions();
  const keyboardHeight = useKeyboardLayoutAnimation();

  const renderSearchBox = useCallback(() => {
    return (
      <>
        {!props.impersonatedUserId && (
          <SearchAndFilterBar
            searchPhrase={props.searchPhrase}
            onChangeSearchPhrase={props.onChangeSearchPhrase}
            placeholderText={strings.searchPlaceholder}
          />
        )}
      </>
    );
  }, [props.searchPhrase, props.onChangeSearchPhrase, props.impersonatedUserId]);

  const searchBoxHeight = useSearchAndFilterBarHeight();
  const getSearchBoxHeight = useCallback(() => {
    return searchBoxHeight;
  }, [searchBoxHeight]);

  const entities = useMemo(
    () => [{ entities: props.searchResults.users.map<SingleSelectEntity>(u => ({ type: "singleSelect", entity: u })) }],
    [props.searchResults.users]
  );

  return (
    <ScreenView
      header={{
        type: "custom",
        subHeaderComponent: { render: renderSearchBox, getHeight: getSearchBoxHeight },
        title: strings.screenHeader,
      }}
      scrollView={!!props.impersonatedUserId}
      paddingHorizontal={false}
      paddingVertical={false}
    >
      {!!props.impersonatedUserId && (
        <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
          <ButtonRectangle
            onPress={() => props.onSelectUser(undefined)}
            type="submit"
            title={strings.clearImpersonatedUser}
          />
        </View>
      )}
      {!props.impersonatedUserId && (
        <SelectEntity
          type="overlay"
          entityData={entities}
          onSelectEntity={props.onSelectUser}
          waitingForResults={props.searchResults.pending}
          keyboardDismissMode="none"
          queryString={props.searchPhrase}
          paddingTop={headerHeight + searchBoxHeight + globalStyleConstants.unitSize}
          paddingBottom={
            (keyboardHeight > 0 ? keyboardHeight : keyboardHeight + bottomTabBarHeight) +
            2 * globalStyleConstants.unitSize
          }
        />
      )}
    </ScreenView>
  );
};
