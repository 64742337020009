import { NativeModules, NativeEventEmitter } from "react-native";
import { serializeAsyncFunction } from "@eatbetter/common-shared/dist/util/SerializeAsyncFunction";

const { Audio: NativeAudio } = NativeModules;

export interface AudioInitArgs {
  timerAlarmFilePath: string;
  backgroundFilePath: string;
}

export interface AudioInstructionsNowPlayingArgs {
  title: string;
  instruction: string;
  photoUrl?: string;
  hasNext: boolean;
  author?: string;
  bookOrPublisher?: string;
}

export interface AppStateChangedArgs {
  appState: "active" | "background";
}

// NOTE: These are listed on the native side in Audio.swift
const events = ["onPlay", "onPause", "onNext", "onPrevious", "onInterrupted"] as const;
type NowPlayingEvent = (typeof events)[number];
export type NowPlayingEventHandler = { [key in NowPlayingEvent]-?: () => void };

export class NativeAudioApi {
  private eventEmitter: NativeEventEmitter;

  constructor() {
    this.eventEmitter = new NativeEventEmitter(NativeAudio);
  }

  // Most of the functions here are serialized because I don't know the behavior if they run in parallel and
  // have setActive calls, etc. running in close succession.

  init: (args: AudioInitArgs) => Promise<void> = serializeAsyncFunction(async (args: AudioInitArgs) => {
    await NativeAudio.initAudio(args);

    // this.eventEmitter = new NativeEventEmitter(NativeAudio)
    //
    // this.eventEmitter.addListener("onPrevious", () => console.log("ON PREVIOUS BITCHES!"))
  });

  startAudioInstructions: (args: NowPlayingEventHandler) => Promise<void> = serializeAsyncFunction(
    async (args: NowPlayingEventHandler) => {
      this.clearEventHandlers();
      events.forEach(e => this.eventEmitter.addListener(e, () => args[e]()));
      await NativeAudio.startAudioInstructions({});
    }
  );

  endAudioInstructions: () => Promise<void> = serializeAsyncFunction(async () => {
    await NativeAudio.endAudioInstructions({});
    this.clearEventHandlers();
  });

  async setAudioInstructionsNowPlaying(args: AudioInstructionsNowPlayingArgs): Promise<void> {
    // this is intentionally not wrapped with serializeAsyncFunction. Last should win.
    await NativeAudio.setAudioInstructionsNowPlaying(args);
  }

  async startSpeech(): Promise<void> {
    await NativeAudio.startSpeech({});
  }

  async endSpeech(): Promise<void> {
    await NativeAudio.endSpeech({});
  }

  startBackgroundAudio: () => Promise<void> = serializeAsyncFunction(async () => {
    await NativeAudio.startBackgroundAudio({});
  });

  stopBackgroundAudio: () => Promise<void> = serializeAsyncFunction(async () => {
    await NativeAudio.stopBackgroundAudio({});
  });

  startTimerAlarm: () => Promise<void> = serializeAsyncFunction(async () => {
    await NativeAudio.startTimerAlarm({});
  });

  stopTimerAlarm: () => Promise<void> = serializeAsyncFunction(async () => {
    await NativeAudio.stopTimerAlarm({});
  });

  appStateChanged: (args: AppStateChangedArgs) => Promise<void> = async (args: AppStateChangedArgs) => {
    // we were previously serializing here, but we were getting errors about different args.
    // Based on what is happening on the native side, I think it should be safer to not serialize.
    await NativeAudio.appStateChanged(args);
  };

  private clearEventHandlers(): void {
    events.forEach(e => {
      this.eventEmitter.removeAllListeners(e);
    });
  }
}
