import { RecipeId } from "@eatbetter/recipes-shared";
import { UserId } from "@eatbetter/common-shared";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "../redux/Redux";
import { getShareData } from "./ShareThunks";
import { log } from "../../Log";
import { ShareData } from "@eatbetter/composite-shared";
import { useAuthedUserId } from "../system/SystemSelectors";

const maxErrors = 1;

type ShareDataOrStatus = { data: ShareData; status: undefined } | { data: undefined; status: "waiting" | "error" };

export const useShareData = (userId: UserId, recipeId?: RecipeId): ShareDataOrStatus => {
  const dispatch = useDispatch();
  const [waiting, setWaiting] = useState(true);
  const [errorCount, setErrorCount] = useState(0);
  const [shareData, setShareData] = useState<ShareData | undefined>();
  const viewingUserId = useAuthedUserId();

  useEffect(() => {
    if (errorCount <= maxErrors) {
      setWaiting(true);
      dispatch(getShareData({ userId, recipeId, viewingUserId }))
        .then(res => {
          setShareData(res);
          setWaiting(false);
        })
        .catch(err => {
          // NOTE that this will not be reported in cases where the user isn't authed.
          log.errorCaught("Caught error in useShareData calling getShareData", err);
          setErrorCount(c => c + 1);
        });
    }
  }, [userId, recipeId, errorCount, viewingUserId]);

  useEffect(() => {
    if (errorCount > maxErrors) {
      setWaiting(false);
    }
  }, [errorCount]);

  const status = errorCount > maxErrors ? "error" : waiting ? "waiting" : undefined;
  return useMemo<ShareDataOrStatus>(() => {
    if (status) {
      return { status };
    }

    if (shareData) {
      return {
        data: shareData,
      };
    }

    log.error(
      "UseShareData - unexpected state: status is not 'waiting' | 'error' but shareData is undefined. Returning 'error' status.",
      {
        status,
        shareData,
      }
    );
    return { status: "error" };
  }, [shareData, status]);
};
