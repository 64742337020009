import { withScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { SettingsItem } from "../components/SettingsItem";
import { useOpenInternalWebPage } from "../lib/util/WebUtil";

const strings = {
  title: "About Deglaze",
  privacyPolicy: "Privacy Policy",
  termsAndConditions: "Terms of Service",
};

export const AboutScreen = withScreenContainer("AboutScreen", () => {
  const openPrivacy = useOpenInternalWebPage("privacyPolicy");
  const openTerms = useOpenInternalWebPage("termsOfService");

  return (
    <ScreenView header={{ type: "default", style: "default", title: strings.title }}>
      <SettingsItem title={strings.privacyPolicy} onPress={openPrivacy} navAction />
      <SettingsItem title={strings.termsAndConditions} onPress={openTerms} navAction />
    </ScreenView>
  );
});
