import { UserRecipeId } from "@eatbetter/recipes-shared";
import React, { useCallback, useMemo } from "react";
import { FlatListProps, ListRenderItem, StyleSheet, View } from "react-native";
import { useRecipe } from "../lib/recipes/RecipesSelectors";
import { AvatarAndHeading } from "./AvatarAndHeading";
import { globalStyleConstants } from "./GlobalStyles";
import { RecipeCard, UserRecipePressedHandler } from "./recipes/RecipeCards";
import { TBody } from "./Typography";
import { Spacer } from "./Spacer";
import { FlatList } from "react-native-gesture-handler";

const strings = {
  waitingSearch: (query: string) => `Searching for "${query}"`,
  noRecipesFound: "No recipes found.",
};

export type SelectableRecipe = { recipeId: UserRecipeId; isSelected?: boolean };
export type RecipeSelectedHandler = (recipe: SelectableRecipe) => void;

interface Props extends Pick<FlatListProps<any>, "keyboardDismissMode" | "onScroll"> {
  recipes: SelectableRecipe[];
  onSelectRecipe: RecipeSelectedHandler;
  queryString?: string;
  waitingForResults: boolean;
  paddingTop: number;
  paddingBottom: number;
}

export const SelectRecipeOverlay = React.memo((props: Props) => {
  const onPressListItem = useCallback<UserRecipePressedHandler>(
    recipeId => {
      const recipe = props.recipes.find(i => i.recipeId === recipeId);
      if (recipe) {
        props.onSelectRecipe(recipe);
      }
    },
    [props.onSelectRecipe, props.recipes]
  );

  const renderItem: ListRenderItem<SelectableRecipe> = useCallback(
    ({ item, index }) => {
      return <SelectableRecipeCard index={index} selectableRecipe={item} onSelect={onPressListItem} />;
    },
    [onPressListItem]
  );

  const renderItemSeparator = useCallback(() => {
    return <Spacer vertical={globalStyleConstants.minPadding} unit="pixels" />;
  }, []);

  const keyExtractor = useCallback((item: SelectableRecipe) => item.recipeId, []);

  const emptyList = useMemo(
    () => (
      <>
        {props.waitingForResults && (
          <AvatarAndHeading waiting={{ message: strings.waitingSearch(props.queryString ?? "") }} />
        )}
        {!props.waitingForResults && (
          <View style={{ paddingHorizontal: 1.5 * globalStyleConstants.unitSize }}>
            <TBody opacity="medium">{strings.noRecipesFound}</TBody>
          </View>
        )}
      </>
    ),
    [props.waitingForResults, props.queryString]
  );

  return (
    <>
      <FlatList
        data={props.recipes}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        ListEmptyComponent={emptyList}
        ItemSeparatorComponent={renderItemSeparator}
        style={styles.list}
        keyboardShouldPersistTaps="always"
        keyboardDismissMode={props.keyboardDismissMode}
        contentContainerStyle={{ paddingTop: props.paddingTop, paddingBottom: props.paddingBottom }}
        onScroll={props.onScroll}
      />
    </>
  );
});

const SelectableRecipeCard = React.memo(
  (props: { selectableRecipe: SelectableRecipe; index: number; onSelect: UserRecipePressedHandler }) => {
    const recipe = useRecipe(props.selectableRecipe.recipeId);

    if (!recipe) {
      return null;
    }

    return (
      <RecipeCard
        {...recipe}
        index={props.index}
        onPress={props.onSelect}
        isSelected={props.selectableRecipe.isSelected}
        searchSessionId={undefined}
        showTimeFilter={false}
      />
    );
  }
);

const styles = StyleSheet.create({
  list: {
    ...StyleSheet.absoluteFillObject,
    zIndex: 1,
    backgroundColor: "white",
    paddingHorizontal: 8,
  },
});
