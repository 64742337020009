import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { AnonymousRegistrationScreenProps } from "../navigation/NavTree";
import { ScreenView } from "../components/ScreenView";
import { SignIn } from "../components/SignIn";
import { useEffect, useRef } from "react";
import { HeaderProps } from "../components/ScreenHeaders";
import { TBody, THeading1, THeading2 } from "../components/Typography";
import { Spacer } from "../components/Spacer";
import { defaultTimeProvider, switchReturn } from "@eatbetter/common-shared";
import { useNumericCheckpointValue } from "../lib/system/SystemSelectors";
import { useDispatch } from "../lib/redux/Redux";
import { numericCheckpointsUpdated } from "../lib/system/SystemSlice";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { reportAnonymousRegistrationScreenDisplayed } from "../lib/analytics/AnalyticsEvents";
import { globalStyleColors } from "../components/GlobalStyles";
import { View } from "react-native";

const strings = {
  requiredTitle: "Join for Free",
  suggestedTitle: "Liking Deglaze?",
  genericBody: "Create an account to make sure you don't lose your data. It's free and only takes a few seconds.",
  actionBody: (action: string) => `You need an account to ${action}. It's free and only takes a few seconds.`,
};

export const AnonymousRegistrationScreen = withNonNavigableScreenContainer<AnonymousRegistrationScreenProps>(
  "AnonymousRegistrationScreen",
  props => {
    const { nav } = useScreen();
    const dispatch = useDispatch();
    const attempts = useNumericCheckpointValue("anonymousRegistrationPromptCount");

    useEffect(() => {
      // we don't track action-driven displays as an attempt - these values are used to drive suggested vs required, and only once they meet the recipe or time threshold
      // see useAnonymousRegistrationPrompt.
      if (props.mode !== "action") {
        dispatch(
          numericCheckpointsUpdated({
            anonymousRegistrationPromptCount: (attempts ?? 0) + 1,
            anonymousRegistrationPromptLastShown: defaultTimeProvider(),
          })
        );
      }

      // only report count for non-action driven displays
      dispatch(
        analyticsEvent(
          reportAnonymousRegistrationScreenDisplayed(props.mode, props.mode !== "action" ? attempts : undefined)
        )
      );
    }, []);

    const headerProps = useRef<HeaderProps>({
      type: "native",
      title: () => <THeading2 font="serif">{"deglaze"}</THeading2>,
      right: { type: "cancel", onPress: () => nav.goBack() },
      backgroundColor: globalStyleColors.colorAccentMidDark,
    }).current;

    const title = switchReturn(props.mode, {
      required: strings.requiredTitle,
      suggested: strings.suggestedTitle,
      action: strings.requiredTitle,
    });

    const body = switchReturn(props.mode, {
      required: strings.genericBody,
      suggested: strings.genericBody,
      action: strings.actionBody(props.mode === "action" ? props.userVisibleActionDescription : "continue"),
    });

    return (
      <ScreenView
        backgroundColor={globalStyleColors.colorAccentMidDark}
        header={props.mode !== "required" ? headerProps : undefined}
        isModal
      >
        <View style={{ flex: 1 }}>
          <Spacer vertical={4} />
          <THeading1 align="center" adjustsFontSizeToFit numberOfLines={2}>
            {title}
          </THeading1>
          <Spacer vertical={1} />
          <TBody align="center" adjustsFontSizeToFit numberOfLines={3}>
            {body}
          </TBody>
          <Spacer vertical={2} />
          <View style={{ position: "absolute", left: 0, right: 0, bottom: 0 }}>
            <SignIn allowAnonymousIfAvailable={false} />
          </View>
        </View>
      </ScreenView>
    );
  }
);
