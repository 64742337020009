import {
  AdminBook,
  AdminCreateExternalBookRecipeArgs,
  ExternalBookRecipe,
  ExternalRecipeId,
  PartialRecipeId,
} from "@eatbetter/recipes-shared";
import { View } from "react-native";
import {
  EditExternalBookRecipeOnSaveArgs,
  newRecipeIngredients,
  newRecipeInstructions,
  RecipeEditControl,
  useIdempotentId,
} from "@eatbetter/ui-shared";
import { useDispatch } from "../lib/AdminRedux";
import { addPhoto } from "../lib/photos/AdminPhotoThunks";
import { EpochMs } from "@eatbetter/common-shared";
import { addExternalBookRecipe } from "../lib/recipes/AdminRecipesThunks";
import { useAdminTagManifest } from "../lib/useAdminTagManifest";

export const BookRecipeAdd = (props: { book: AdminBook; onDone: (recipeId: ExternalRecipeId) => void }) => {
  const dispatch = useDispatch();
  const [id, refreshId] = useIdempotentId<PartialRecipeId>();
  const tagManifest = useAdminTagManifest();

  const bookEntity = {
    id: props.book.id,
    name: props.book.title,
    photo: props.book.coverPhoto,
    purchaseLink: props.book.purchaseLink,
  };
  const author = props.book.primaryAuthor;

  const initialRecipe: ExternalBookRecipe = {
    type: "externalBookRecipe",
    id: "" as ExternalRecipeId,
    book: bookEntity,
    author,
    title: "",
    description: "",
    ingredients: newRecipeIngredients(),
    instructions: newRecipeInstructions(),
    source: { type: "book", bookId: props.book.id, recipeId: "" as ExternalRecipeId },
    stats: {},
    tags: [],
    created: 0 as EpochMs,
    version: 0 as EpochMs,
    ac: false,
  };

  const onSave = async (args: EditExternalBookRecipeOnSaveArgs) => {
    const photo = args.newRecipePhoto ? await dispatch(addPhoto(args.newRecipePhoto)) : undefined;

    const createArgs: AdminCreateExternalBookRecipeArgs = {
      id: id,
      book: bookEntity,
      author,
      title: args.title,
      description: args.description,
      ingredients: args.ingredients,
      instructions: args.instructions,
      photo,
      time: args.time,
      recipeYield: args.recipeYield,
      tags: args.tags,
    };

    const resp = await dispatch(addExternalBookRecipe(createArgs));
    refreshId();
    props.onDone(resp.id);
  };

  return (
    <View>
      <RecipeEditControl
        type="adminExternalBook"
        initialRecipe={initialRecipe}
        onSave={onSave}
        tagManifest={tagManifest}
      />
    </View>
  );
};
