import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { useCallback, useMemo, useState } from "react";
import { ProfileEditFieldScreenComponent } from "../components/social/ProfileEditFieldScreenComponent";
import { useRegisteredUser } from "../lib/system/SystemSelectors";
import { updateUserProfile } from "../lib/system/SystemThunks";
import { useDispatch } from "../lib/redux/Redux";
import { useBioInput } from "../lib/system/UserProfileHooks";
import { bottomThrow } from "@eatbetter/common-shared";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";

const strings = {
  screenHeader: "Bio",
  label: "Bio",
  bioIsInvalid: "Bio must be 150 characters or less.",
};

export const ProfileEditBioScreen = withNonNavigableScreenContainer("ProfileEditBioScreen", () => {
  const screen = useScreen();
  const dispatch = useDispatch();
  const persistedBio = useRegisteredUser()?.profileBio ?? "";
  const bioInput = useBioInput(persistedBio);
  const isDirty = useMemo(() => persistedBio !== bioInput.bio, [persistedBio, bioInput.bio]);

  const [waiting, setWaiting] = useState(false);

  const onSave = useCallback(async () => {
    if (!isDirty) {
      return;
    }

    setWaiting(true);
    try {
      const result = await dispatch(updateUserProfile({ bio: bioInput.bio }));
      setWaiting(false);

      switch (result) {
        case "success":
        case "nameInvalid":
        case "usernameInvalid":
        case "usernameUnavailable":
          break;
        case "bioInvalid":
          bioInput.setBioIsInvalid(true);
          break;
        case "linksInvalid":
          break;
        default:
          bottomThrow(result);
      }

      screen.nav.goBack();
    } catch (err) {
      displayUnexpectedErrorAndLog("Unexpected error in ProfileEditBioScreen.onSubmit", err);
      setWaiting(false);
      return;
    }
  }, [isDirty, dispatch, bioInput.bio, bioInput.setBioIsInvalid, setWaiting, screen.nav.goBack]);

  return (
    <ProfileEditFieldScreenComponent
      screenHeader={strings.screenHeader}
      label={strings.label}
      value={bioInput.bio}
      onChangeText={bioInput.onChange}
      isDirty={isDirty}
      onSubmit={onSave}
      showClearButton
      validationState={isDirty ? (bioInput.bioIsInvalid ? "invalid" : "valid") : undefined}
      showIsValidIcon={false}
      errorMessage={bioInput.bioIsInvalid ? strings.bioIsInvalid : undefined}
      waiting={waiting}
      isPostMetadata={false}
      multiline
      showCharLimit={bioInput.maxBioLength}
    />
  );
});
