import { withErrorBoundary } from "../ErrorBoundary";
import { DocumentHeadEditComponent } from "./DocumentHeadInterfaces";
import { Helmet } from "react-helmet-async";

export const DocumentHeadEdit: DocumentHeadEditComponent = withErrorBoundary(
  "DocumentHeadEditWeb",
  props => {
    return (
      <Helmet>
        {!!props.documentTitle && <title>{props.documentTitle}</title>}
        {!!props.themeColor && <meta name="theme-color" content={props.themeColor} />}
      </Helmet>
    );
  },
  () => null
);
