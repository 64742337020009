import { BookId } from "@eatbetter/recipes-shared";
import { FlexedSpinner, Spacer, THeading1, useIdempotentId } from "@eatbetter/ui-shared";
import { useState } from "react";
import { Link } from "react-router-dom";
import { AdminScreenView } from "../components/AdminScreenView";
import { BookEdit } from "../components/BookEdit";
import { useStringParam } from "../lib/ParamUtils";
import { useAdminFetchBook } from "../lib/useAdminFetchBook";
import { AdminBooksScreenIdNav } from "./AdminBooksScreen";

const bookIdParam = ":bookId";
export const AdminBookEditScreenNav = {
  getPath: (id?: string) => {
    return `${AdminBooksScreenIdNav.getPath(id ?? bookIdParam)}/edit`;
  },
};

export const AdminBookEditScreen = () => {
  const id = useStringParam<BookId>(bookIdParam).required();
  const [waiting, setWaiting] = useState(false);

  // since the edit components hold their own state, we need to use a key to replace them once a save/cancel
  // happens. We call refreshToken in the edit component's onDone call and then use the token in the key
  const [token, refreshToken] = useIdempotentId();
  const book = useAdminFetchBook(id, setWaiting, token);
  const key = `${id}${book?.version}${token}`;

  const onDone = () => {
    refreshToken();
    alert("Saved!");
  };

  return (
    <AdminScreenView>
      <THeading1 fontWeight="medium">
        Edit Book <Link to={AdminBooksScreenIdNav.getPath(id)}>{id}</Link>
      </THeading1>
      <Spacer vertical={2} />
      {waiting && <FlexedSpinner />}
      {!!book && <BookEdit bookToEdit={book} onDone={onDone} key={key} />}
      {!book && <FlexedSpinner />}
    </AdminScreenView>
  );
};
