import { View } from "react-native";
import { Opacity, globalStyleConstants } from "./GlobalStyles";
import React from "react";
import { TSecondary } from "./Typography";
import { Spacer } from "./Spacer";
import { bottomThrow } from "@eatbetter/common-shared";

interface TextFragment {
  type: "text";
  text: string;
  fontWeight?: "normal" | "medium";
}

interface IconFragment {
  type: "icon";
  icon: JSX.Element;
}

export type StepFragment = TextFragment | IconFragment;

export interface Paragraph {
  fragments: Array<StepFragment>;
}

const IconSpanComponent = React.memo((props: Omit<IconFragment, "type"> & { scale?: number }) => {
  return <View style={{ paddingHorizontal: 0.5 * globalStyleConstants.unitSize }}>{props.icon}</View>;
});

const TextSpanComponent = React.memo((props: Omit<TextFragment, "type"> & { scale?: number }) => {
  return (
    <TSecondary
      fontWeight={props.fontWeight ?? "normal"}
      enableFontScaling={props.scale ? "upAndDown" : undefined}
      scale={props.scale}
    >
      {props.text}
    </TSecondary>
  );
});

interface ParagraphComponentProps extends Paragraph {
  isFirst?: boolean;
  enumeration?: number;
  spaceBefore?: number;
  alignText?: "left" | "center" | "right";
  textOpacity?: keyof typeof Opacity;
  scale?: number;
}

export const ParagraphComponent = React.memo((props: ParagraphComponentProps) => {
  const fragments = (
    <TSecondary>
      {props.fragments.map((fragment, idx) => {
        switch (fragment.type) {
          case "text": {
            return (
              <TextSpanComponent key={idx} text={fragment.text} fontWeight={fragment.fontWeight} scale={props.scale} />
            );
          }
          case "icon": {
            return <IconSpanComponent key={idx} icon={fragment.icon} />;
          }
          default: {
            bottomThrow(fragment);
          }
        }
      })}
    </TSecondary>
  );

  return (
    <>
      {!props.isFirst && <Spacer vertical={props.spaceBefore ?? 1} />}
      <TSecondary align={props.alignText ?? "left"} opacity={props.textOpacity ?? "opaque"}>
        {!!props.enumeration && (
          <View style={{ flexDirection: "row" }}>
            <View style={{ width: 1.75 * globalStyleConstants.unitSize }}>
              <Enumeration stepNumber={props.enumeration} scale={props.scale} />
            </View>
            {fragments}
          </View>
        )}
        {!props.enumeration && fragments}
      </TSecondary>
    </>
  );
});

const Enumeration = React.memo((props: { stepNumber: number; scale?: number }) => {
  return (
    <TSecondary
      numberOfLines={1}
      enableFontScaling={props.scale ? "upAndDown" : undefined}
      scale={props.scale}
    >{`${props.stepNumber}.`}</TSecondary>
  );
});
