import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TabView, TabViewBarProps, TabViewProps } from "../TabView";
import { UserId, bottomThrow } from "@eatbetter/common-shared";
import { log } from "../../Log";
import { EntitySelectedHandler, SelectEntity, getFollowableEntities } from "../SelectEntity";
import { useFollowers } from "../../lib/social/SocialHooks";
import { useProfileInfo } from "../../lib/social/SocialSelectors";
import { getPullToRefresh } from "../PullToRefresh";
import { useAuthedUserId } from "../../lib/system/SystemSelectors";
import { useScreen } from "../../navigation/ScreenContainer";
import { LayoutAnimation } from "react-native";
import { getSocialEntityId } from "@eatbetter/posts-shared";
import { navToEntityScreen } from "../../lib/social/SocialThunks";
import { useDispatch } from "../../lib/redux/Redux";

const strings = {
  following: "Following",
  followers: "Followers",
  followingEmpty: (ownProfile: boolean, username?: string) =>
    ownProfile ? "You're not following anyone yet" : `${username ?? "User"} is not following anyone yet`,
  followersEmpty: (ownProfile: boolean, username?: string) =>
    ownProfile ? "You have no followers yet" : `${username ?? "User"} has no followers yet`,
};

interface Props {
  userId: UserId | undefined;
  initialTab?: FollowersFollowingRoute["key"];
}

interface FollowersFollowingRoute {
  key: "following" | "followers";
  title: string;
}

export const FollowersFollowing = React.memo((props: Props) => {
  const screen = useScreen();
  const dispatch = useDispatch();
  const authedUserId = useAuthedUserId();
  const profileInfo = useProfileInfo(props.userId);
  const {
    items: following,
    fetchNext: fetchNextFollowingBatch,
    refresh: refreshFollowing,
    waiting: _waitingFollowingBatch,
  } = useFollowers(props.userId, "following");
  const {
    items: followers,
    fetchNext: fetchNextFollowersBatch,
    refresh: refreshFollowers,
    waiting: _waitingFollowersBatch,
  } = useFollowers(props.userId, "followers");

  useEffect(() => {
    if (screen.nav.focused) {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    }
  }, [followers, following]);

  const onPressEntity = useCallback<EntitySelectedHandler>(
    item => {
      dispatch(navToEntityScreen(getSocialEntityId(item.entity), screen.nav, "profileFollowersFollowing"));
    },
    [screen.nav, authedUserId]
  );

  const isOwnProfile = props.userId === undefined;

  const followingRoute: FollowersFollowingRoute = { key: "following", title: strings.following };
  const followersRoute: FollowersFollowingRoute = { key: "followers", title: strings.followers };
  const tabViewRoutes: FollowersFollowingRoute[] = isOwnProfile ? [followingRoute, followersRoute] : [followingRoute];

  const initialIndex = tabViewRoutes.findIndex(i => i.key === (props.initialTab ?? "following"));
  const [tabViewIndex, setTabViewIndex] = useState(initialIndex);

  const followingList = useMemo(() => {
    if (following.length === 0) {
      return [];
    }
    return [{ entities: getFollowableEntities(following, isOwnProfile ? "ownFollowing" : "otherUserFollowing") }];
  }, [isOwnProfile, following]);
  const followersList = useMemo(() => {
    if (followers.length === 0) {
      return [];
    }
    return [{ entities: getFollowableEntities(followers, isOwnProfile ? "ownFollowers" : "otherUserFollowers") }];
  }, [isOwnProfile, followers]);

  const renderRoute = useCallback<TabViewProps<FollowersFollowingRoute>["renderRoute"]>(
    ({ route }) => {
      const followersFollowingRoute = route as FollowersFollowingRoute;

      switch (followersFollowingRoute.key) {
        case "following": {
          return (
            <SelectEntity
              type="flexed"
              entityData={followingList}
              onSelectEntity={onPressEntity}
              waitingForResults={false}
              backgroundColor="transparent"
              emptyInitialStateMessage={strings.followingEmpty(isOwnProfile, profileInfo?.user.username)}
              onEndReached={fetchNextFollowingBatch}
              refreshControl={getPullToRefresh(refreshFollowing)}
            />
          );
        }
        case "followers": {
          return (
            <SelectEntity
              type="flexed"
              entityData={followersList}
              onSelectEntity={onPressEntity}
              waitingForResults={false}
              backgroundColor="transparent"
              emptyInitialStateMessage={strings.followersEmpty(isOwnProfile, profileInfo?.user.username)}
              onEndReached={fetchNextFollowersBatch}
              refreshControl={getPullToRefresh(refreshFollowers)}
            />
          );
        }
        default:
          bottomThrow(followersFollowingRoute.key, log);
      }
    },
    [
      followingList,
      fetchNextFollowingBatch,
      refreshFollowing,
      followersList,
      fetchNextFollowersBatch,
      refreshFollowers,
      props.userId,
      profileInfo,
      onPressEntity,
    ]
  );

  const tabBar = useMemo<TabViewBarProps>(() => {
    if (tabViewRoutes.length > 1) {
      return {
        type: "flex",
        backgroundColor: "transparent",
      };
    }

    return "none" as const;
  }, [tabViewRoutes.length]);

  return (
    <TabView
      index={tabViewIndex}
      onIndexChange={setTabViewIndex}
      routes={tabViewRoutes}
      tabBar={tabBar}
      renderRoute={renderRoute}
      swipeEnabled={tabViewRoutes.length > 1}
    />
  );
});
