import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BottomActionBar, BottomActionBarProps } from "../BottomActionBar";
import { useDispatch } from "../../lib/redux/Redux";
import {
  changeActiveCookingSession,
  cookingSessionScreenUnmountedOrNavedAway,
} from "../../lib/cooking/CookingSessionsThunks";
import { useScreen } from "../../navigation/ScreenContainer";
import { Haptics } from "../Haptics";
import { displayUnexpectedErrorAndLog } from "../../lib/Errors";
import { CookingSessionId } from "@eatbetter/cooking-shared";
import { navTree } from "../../navigation/NavTree";
import { useCookingSessionIds } from "../../lib/cooking/CookingSessionsSelectors";
import { cookingSessionHasRemainingTimers } from "../../lib/cooking/CookingTimerThunks";
import { Alert } from "../Alert/Alert";
import { WalkthroughStepProps } from "../Walkthrough";

const strings = {
  startCooking: "Start Cooking",
  addToGroceries: "Add to Groceries",
  saveRecipe: "Add to Your Library",
  switchRecipe: "Switch Recipe",
  endCooking: "End Cooking",
  timerRemainingTitle: "A timer is running",
  timerRemainingBody: "It will be deleted if you end now. Do you still want to end cooking?",
};

export interface InKitchenRecipeActionBarProps {
  cookingSessionId: CookingSessionId;
  topBorderWidth?: "thin" | "thick";
  endCookingWalkthrough?: WalkthroughStepProps;
}

export const InKitchenRecipeActionBar = React.memo((props: InKitchenRecipeActionBarProps) => {
  const dispatch = useDispatch();
  const screen = useScreen();

  const [waitingEndCooking, setWaitingEndCooking] = useState(false);
  const [cookingSessionEnded, setCookingSessionEnded] = useState(false);

  const cookingSessionId = props.cookingSessionId;
  const cookingSessionIds = useCookingSessionIds();

  const topBorderWidth = props.topBorderWidth ?? "thin";

  const switchCookingSession = useCallback(() => {
    if (cookingSessionId) {
      const index = cookingSessionIds.indexOf(cookingSessionId);
      if (index >= 0) {
        const newIndex = (index + 1) % cookingSessionIds.length;
        const newId = cookingSessionIds[newIndex];
        if (newId) {
          dispatch(changeActiveCookingSession({ cookingSessionId: newId }));
          Haptics.feedback("itemStatusChanged");
        }
      }
    }
  }, [cookingSessionIds, cookingSessionId, dispatch]);

  const onPressEndCooking = useCallback(async () => {
    if (!cookingSessionId) {
      return;
    }

    const doEnd = () => {
      // start the spinner now. It will be stopped once the operation is done.
      setWaitingEndCooking(true);
      setCookingSessionEnded(true);
    };

    const timersRemaining = dispatch(cookingSessionHasRemainingTimers(cookingSessionId));

    if (timersRemaining) {
      Alert.alert(strings.timerRemainingTitle, strings.timerRemainingBody, [
        { type: "cancel", onPress: () => {} },
        { type: "save", text: strings.endCooking, onPress: doEnd },
      ]);
    } else {
      doEnd();
    }
  }, [cookingSessionId, dispatch]);

  useEffect(() => {
    if (cookingSessionEnded) {
      if (!cookingSessionId) {
        displayUnexpectedErrorAndLog("End Cooking pressed but cookingSessionId is falsy", {}, { cookingSessionId });
        return;
      }
      dispatch(cookingSessionScreenUnmountedOrNavedAway());
      screen.nav.goTo("push", navTree.get.screens.endCookingSession, { cookingSessionId });
      setCookingSessionEnded(false);
      setWaitingEndCooking(false);
    }
  }, [cookingSessionEnded]);

  const actions = useMemo<BottomActionBarProps>(() => {
    return {
      primaryAction: {
        actionText: strings.endCooking,
        waiting: waitingEndCooking,
        onPressAction: onPressEndCooking,
        walkthrough: props.endCookingWalkthrough,
      },
      secondaryAction: {
        actionText: strings.switchRecipe,
        onPressAction: switchCookingSession,
        singlePress: false,
        disabled: cookingSessionIds.length === 1,
      },
    };
  }, [
    cookingSessionIds.length,
    onPressEndCooking,
    switchCookingSession,
    waitingEndCooking,
    props.endCookingWalkthrough,
  ]);

  return (
    <BottomActionBar
      primaryAction={actions.primaryAction}
      secondaryAction={actions.secondaryAction}
      border={topBorderWidth}
      noBorderRadius
    />
  );
});
