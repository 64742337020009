import { Base64Data } from "@eatbetter/common-shared";
import { gzip as pakoGzip } from "pako";
import { deflate } from "react-native-gzip";
import { Buffer } from "buffer";
import { Platform } from "react-native";

export async function gzip(data: string): Promise<Base64Data> {
  if (Platform.OS === "web") {
    const input = Buffer.from(data);
    const deflated = pakoGzip(input);
    return Buffer.from(deflated).toString("base64") as Base64Data;
  }

  const native = await deflate(data);
  return native as Base64Data;
}
