import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { CurrentEnvironment } from "@eatbetter/ui-shared";

let isInitialized = false;

function initIfNot() {
  if (!isInitialized) {
    firebase.initializeApp(CurrentEnvironment.configEnvironment() === "prod" ? firebaseProdConfig : firebaseDevConfig);
    isInitialized = true;
  }
}

export const getToken = async (): Promise<string | undefined> => {
  initIfNot();
  return firebase.auth().currentUser?.getIdToken();
};

export const getCurrentUserEmail = () => {
  return firebase.auth().currentUser?.email;
};

const isLocalHost = () => window.location.hostname.endsWith("localhost");

export const onSignIn = async () => {
  initIfNot();
  const provider = new firebase.auth.GoogleAuthProvider();

  if (isLocalHost()) {
    await firebase.auth().signInWithPopup(provider);
  } else {
    await firebase.auth().signInWithRedirect(provider);
    await firebase.auth().getRedirectResult();
  }

  return true;
};

export const subscribeToAuth = (args: { onSignIn: () => void; onSignOut: () => void }): (() => void) => {
  initIfNot();

  return firebase.auth().onAuthStateChanged(user => {
    if (user) {
      args.onSignIn();
    } else {
      args.onSignOut();
    }
  });
};

const firebaseDevConfig = {
  apiKey: "AIzaSyCHNOndTqCPVizhNVp6glqBE9PTn7zWw9U",
  authDomain: "admin.mooklab-dev.link",
  projectId: "deglaze-dev-admin-project",
  storageBucket: "deglaze-dev-admin-project.appspot.com",
  messagingSenderId: "406663119750",
  appId: "1:406663119750:web:1c3e6170dc67ed94a26207",
};

const firebaseProdConfig = {
  apiKey: "AIzaSyB1gdgAzrft-BhBTmAkF_n0jLiePQlnIls",
  authDomain: "admin.deglaze.app",
  projectId: "deglaze-prod-admin-project",
  storageBucket: "deglaze-prod-admin-project.appspot.com",
  messagingSenderId: "980748172150",
  appId: "1:980748172150:web:9bcb5d03c9502b27895857",
};
