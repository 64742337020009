import { RecipeId } from "@eatbetter/recipes-shared";
import { TBody } from "@eatbetter/ui-shared";
import { Link } from "react-router-dom";
import { AdminRecipesScreenIdNav } from "../screens/AdminRecipesScreen";

export const RecipeLink = (props: { id: RecipeId }) => {
  return (
    <TBody>
      <Link to={AdminRecipesScreenIdNav.getPath(props.id)}>{props.id}</Link>
    </TBody>
  );
};
