import React, { useCallback } from "react";
import { UserRecipeId } from "@eatbetter/recipes-shared";
import { StyleSheet, View } from "react-native";
import { useRecipeTime } from "../../lib/recipes/RecipesSelectors";
import { globalStyleConstants, globalStyles } from "../GlobalStyles";
import { IconChevronRight } from "../Icons";
import { Spacer } from "../Spacer";
import { useScreen } from "../../navigation/ScreenContainer";
import { navTree, RecipeEditFieldLocation } from "../../navigation/NavTree";
import { Pressable } from "../Pressable";
import { getFormattedDuration } from "@eatbetter/common-shared";
import { SelectableOval } from "../SelectableOval";
import { TSecondary } from "../Typography";

const strings = {
  time: "Time",
  noTime: "No total time set.",
  total: "Total",
  active: "Active",
  addNew: "Add total time",
};

interface Props {
  recipeId: UserRecipeId;
  style?: "default" | "compact";
  disabled?: boolean;
  location: RecipeEditFieldLocation;
  noShadow?: boolean;
}

export const RecipeTime = (props: Props) => {
  const time = useRecipeTime(props.recipeId);
  const screen = useScreen();

  const totalTimeDisplay = time?.total?.[0]
    ? getFormattedDuration({ milliseconds: time.total[0] }, false, "XXh:XXm")
    : undefined;

  const onPress = useCallback(() => {
    screen.nav.goTo("push", navTree.get.screens.recipeTimeEdit, {
      recipeId: props.recipeId,
      recipeEditFieldLocation: props.location,
    });
  }, [screen.nav.goTo, props.recipeId, props.location]);

  return (
    <Pressable
      style={[styles.container, props.noShadow ? {} : globalStyles.shadowItem]}
      onPress={onPress}
      noFeedback
      disabled={props.disabled}
    >
      <View style={styles.heading}>
        <View>
          <TSecondary>{strings.time}</TSecondary>
        </View>
        <View style={styles.headingContent}>
          {props.style === "compact" && (
            <>
              {!time && (
                <>
                  <TSecondary opacity="light">{strings.addNew}</TSecondary>
                  <Spacer horizontal={1} />
                </>
              )}
              {!!time && (
                <>
                  <Spacer horizontal={2} />
                  <View style={{ flexShrink: 1 }}>
                    <TSecondary numberOfLines={1} opacity="medium">
                      {`${strings.total}: ${totalTimeDisplay}`}
                    </TSecondary>
                  </View>
                  <Spacer horizontal={1} />
                </>
              )}
            </>
          )}
          <IconChevronRight />
        </View>
      </View>
      {props.style !== "compact" && (
        <>
          <Spacer vertical={1} />
          <View>
            {!time && <TSecondary opacity="medium">{strings.noTime}</TSecondary>}
            {!!time && (
              <View style={{ flexDirection: "row", alignItems: "center", flexWrap: "wrap" }}>
                <RecipeTimeDisplay label={strings.total} timeDisplay={totalTimeDisplay} />
              </View>
            )}
          </View>
        </>
      )}
    </Pressable>
  );
};

const RecipeTimeDisplay = React.memo((props: { label: string; timeDisplay?: string }) => {
  if (!props.timeDisplay) {
    return null;
  }

  return <SelectableOval isSelected disabled onPress={() => {}} text={`${props.label}: ${props.timeDisplay}`} />;
});

const styles = StyleSheet.create({
  container: {
    padding: globalStyleConstants.unitSize,
    backgroundColor: "white",
    borderRadius: 20,
  },
  heading: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: 32,
  },
  headingContent: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
});
