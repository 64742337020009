import { UrlString } from "@eatbetter/common-shared";
import { Platform, Share as RNShare } from "react-native";
import { noWebSupport } from "../lib/Web";

interface ShareArgs {
  url: UrlString;
  message?: string;
}

export const Share = {
  async share(args: ShareArgs) {
    if (Platform.OS === "web") {
      noWebSupport();
    }

    await RNShare.share({
      url: args.url,
      message: args.message,
    });
  },
};
