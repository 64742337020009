import React, { PropsWithChildren, useCallback, useState } from "react";
import { globalStyleColors, globalStyleConstants, Opacity } from "../GlobalStyles";
import { TBody, Text, TextProps } from "../Typography";
import { RecipeTextToken } from "../../lib/recipes/UseScaled";
import { Pressable } from "../Pressable";
import { RootSiblingPortal } from "react-native-root-siblings";
import { Tooltip } from "../Tooltip";
import { LayoutAnimation, StyleSheet, View } from "react-native";
import { Haptics } from "../Haptics";
import { ScaledConvertedLabels } from "./ScalingAndConversions";

export interface ModifiableRecipeTextProps {
  tokens: RecipeTextToken[];
  fontSize: Extract<TextProps["fontSize"], "body" | "secondary">;
  fontScale?: number;
  opacity?: keyof typeof Opacity;
  strikethrough?: boolean;
  numberOfLines?: number;
  italic?: boolean;
  tapDisabled?: boolean;
}

export const ModifiableRecipeText = React.memo((props: ModifiableRecipeTextProps) => {
  return (
    <RecipeText
      fontSize={props.fontSize}
      fontScale={props.fontScale}
      opacity={props.opacity}
      strikethrough={props.strikethrough}
      numberOfLines={props.numberOfLines}
    >
      {props.tokens.map((i, idx) => {
        const key = `${idx}_${i.text}`;
        const textProps: RecipeTextProps = {
          fontSize: props.fontSize,
          fontScale: props.fontScale,
          italic: props.italic,
        };

        if (i.isModified || !!i.tooltipInfo) {
          return <ModifiedRecipeText {...textProps} changedTextToken={i} tapDisabled={props.tapDisabled} key={key} />;
        }

        return (
          <RecipeText {...textProps} key={key}>
            {i.text}
          </RecipeText>
        );
      })}
    </RecipeText>
  );
});

interface ModifiedRecipeTextProps {
  fontSize: Extract<TextProps["fontSize"], "body" | "secondary">;
  fontScale?: number;
  italic?: boolean;
  changedTextToken: RecipeTextToken;
  tapDisabled?: boolean;
}

export const ModifiedRecipeText = React.memo((props: ModifiedRecipeTextProps) => {
  const [tooltip, setTooltip] = useState<{ x: number; y: number } | null>(null);

  const onPress = useCallback<NonNullable<TextProps["onPress"]>>(
    ({ nativeEvent: { pageX, pageY } }) => {
      Haptics.feedback("itemStatusChanged");
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      setTooltip({ x: pageX, y: pageY });
    },
    [setTooltip]
  );

  const onDismissTooltip = useCallback(() => {
    setTooltip(null);
  }, [setTooltip]);

  const textProps: RecipeTextProps = {
    ...modifiedTextProps,
    fontWeight: props.changedTextToken.isModified ? "medium" : "normal",
    fontSize: props.fontSize,
    fontScale: props.fontScale,
    italic: props.italic,
    onPress: props.tapDisabled ? undefined : onPress,
  };

  const tooltipInfo = props.changedTextToken.tooltipInfo;
  const toolTipArrow = "   →   ";

  return (
    <>
      <RecipeText {...textProps}>{props.changedTextToken.text}</RecipeText>
      {tooltip && !!tooltipInfo && (
        <RootSiblingPortal>
          <Pressable style={StyleSheet.absoluteFill} onPress={onDismissTooltip} noFeedback>
            <Tooltip
              target={{ x: tooltip.x, y: tooltip.y, width: 1, height: 1 }}
              paddingVertical="min"
              message={
                <View style={{ minWidth: "50%", alignItems: "center" }}>
                  {!!(tooltipInfo.scaleFactor || tooltipInfo.conversion) && (
                    <View style={{ paddingVertical: globalStyleConstants.minPadding }}>
                      <ScaledConvertedLabels scale={tooltipInfo.scaleFactor} conversion={tooltipInfo.conversion} />
                    </View>
                  )}
                  <TBody>
                    <TBody scale={props.fontScale} enableFontScaling="upOnly">
                      {tooltipInfo.original}
                    </TBody>
                    <TBody scale={props.fontScale} enableFontScaling="upOnly">
                      {toolTipArrow}
                    </TBody>
                    <Text
                      {...modifiedTextProps}
                      fontWeight="medium"
                      fontSize="body"
                      scale={props.fontScale}
                      enableFontScaling="upOnly"
                    >
                      {tooltipInfo.modified}
                    </Text>
                  </TBody>
                </View>
              }
            />
          </Pressable>
        </RootSiblingPortal>
      )}
    </>
  );
});

interface RecipeTextProps {
  fontSize: Extract<TextProps["fontSize"], "body" | "secondary">;
  fontScale?: number;
  fontWeight?: TextProps["fontWeight"];
  color?: string;
  opacity?: keyof typeof Opacity;
  strikethrough?: boolean;
  underline?: TextProps["underline"];
  underlineColor?: string;
  highlightColor?: TextProps["highlightColor"];
  numberOfLines?: number;
  italic?: boolean;
  textShadow?: TextProps["textShadow"];
  onPress?: TextProps["onPress"];
}

export const RecipeText = React.memo((props: PropsWithChildren<RecipeTextProps>) => {
  return (
    <Text
      color={props.color}
      fontSize={props.fontSize}
      fontWeight={props.fontWeight}
      scale={props.fontScale}
      enableFontScaling={props.fontScale ? "upOnly" : undefined}
      opacity={props.opacity}
      strikethrough={props.strikethrough}
      underline={props.underline}
      underlineColor={props.underlineColor}
      highlightColor={props.highlightColor}
      numberOfLines={props.numberOfLines}
      italic={props.italic}
      textShadow={props.textShadow}
      onPress={props.onPress}
      suppressHighlighting
    >
      {props.children}
    </Text>
  );
});

const modifiedTextProps: RecipeTextProps = {
  fontSize: "secondary",
  color: globalStyleColors.rgba("colorAccentCool", "opaque"),
  highlightColor: globalStyleColors.rgba("colorAccentCool", "xxlight"),
};
