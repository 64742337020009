import { Keyboard, StyleSheet, View } from "react-native";
import { globalStyleColors, globalStyleConstants } from "../GlobalStyles";
import React, { useCallback } from "react";
import { GroceryListItemId } from "@eatbetter/lists-shared";
import { useDispatch } from "../../lib/redux/Redux";
import { useGroceryCategories, useListItemCategory } from "../../lib/lists/ListsSelectors";
import { StandardPrimaryCategory } from "@eatbetter/items-shared";
import { Haptics } from "../Haptics";
import { SectionHeading } from "../SectionHeading";
import { Spacer } from "../Spacer";
import { Pressable } from "../Pressable";
import { TSecondary } from "../Typography";
import { groceryListItemCategoryChanged } from "../../lib/lists/ListsThunks";

const strings = {
  sectionHeader: "Aisle",
};

const config = {
  sectionHeadingHeight: 21 + globalStyleConstants.unitSize,
  categoryHeight: 36,
  categoryMarginBottom: globalStyleConstants.unitSize / 1.5,
};

export const groceryItemCategoryEditConstants = {
  height: 3 * (config.categoryHeight + config.categoryMarginBottom) + config.sectionHeadingHeight,
};

export const GroceryItemCategoryEdit = React.memo((props: { itemId: GroceryListItemId }) => {
  const dispatch = useDispatch();
  const primaryCategories = useGroceryCategories();
  const selectedCategory = useListItemCategory(props.itemId);

  const onSelectCategory = useCallback(
    (category: StandardPrimaryCategory) => {
      Keyboard.dismiss();
      Haptics.feedback("itemStatusChanged");
      dispatch(groceryListItemCategoryChanged({ id: props.itemId, manualCategory: category }));
    },
    [dispatch, props.itemId]
  );

  return (
    <View style={styles.container}>
      <SectionHeading text={strings.sectionHeader} noPadding />
      <Spacer vertical={1} />
      <View style={styles.categories}>
        {primaryCategories.map(i => {
          return (
            <CategoryButton
              key={i.category}
              isSelected={i.category === selectedCategory}
              category={i.category}
              display={i.display}
              onPress={onSelectCategory}
            />
          );
        })}
      </View>
    </View>
  );
});

const CategoryButton = React.memo(
  (props: {
    category: StandardPrimaryCategory;
    display: string;
    onPress: (category: StandardPrimaryCategory) => void;
    isSelected: boolean;
  }) => {
    const onPress = useCallback(() => {
      props.onPress(props.category);
    }, [props.onPress, props.category]);

    return (
      <Pressable
        style={[styles.category, props.isSelected ? styles.selected : {}]}
        onPress={onPress}
        noFeedback
        disabled={props.isSelected}
      >
        <TSecondary color={props.isSelected ? "white" : globalStyleColors.colorAccentCool}>{props.display}</TSecondary>
      </Pressable>
    );
  }
);

const styles = StyleSheet.create({
  container: {},
  categories: {
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
  },
  category: {
    justifyContent: "center",
    backgroundColor: "white",
    height: config.categoryHeight,
    borderRadius: config.categoryHeight / 2,
    borderWidth: 1,
    borderColor: globalStyleColors.colorAccentCool,
    marginRight: globalStyleConstants.unitSize / 1.5,
    marginBottom: config.categoryMarginBottom,
    paddingHorizontal: globalStyleConstants.unitSize,
  },
  selected: {
    backgroundColor: globalStyleColors.colorAccentCool,
  },
});
