import { useRef, useEffect, useCallback } from "react";
import { ViewToken } from "react-native";
import { useSessionStartTime } from "../system/SystemSelectors";
import { log } from "../../Log";
import { useDispatch } from "../redux/Redux";

interface UseTrackScrollSessionProps {
  isScrolled: boolean;
  reportScrolledEvent: (indexReached: number) => void;
}

export const useTrackScrollSession = ({ isScrolled, reportScrolledEvent }: UseTrackScrollSessionProps) => {
  const dispatch = useDispatch();
  const maxIndexViewed = useRef(0);
  const currentSession = useSessionStartTime();
  const lastSession = useRef(currentSession);

  useEffect(() => {
    // if we're not scrolled, and we're in a new session, reset the scroll counter.
    // scenario: user scrolls to index 10, leaves app, comes back 3 hours later, scrolls to top
    // then scrolls to position 5, we want to report that view activity even though the user had
    // previously scrolled to 10 for the currently mounted screen.
    // Note that if a user scrolls *down* when the new session starts, and *then* scrolls back to zero
    // we could see something like "Viewed index 20" followed by "Viewed index 5" in the same app session.
    if (currentSession !== lastSession.current && !isScrolled) {
      log.info(
        "useTrackScrollSession(): New session detected and user is scrolled to top. Resetting list maxIndexViewed and session ref"
      );
      maxIndexViewed.current = 0;
      lastSession.current = currentSession;
    }
  }, [isScrolled, currentSession]);

  const onViewableItemsChanged = useCallback(
    (info: { viewableItems: Array<ViewToken>; changed: Array<ViewToken> }) => {
      const maxIndex = info.viewableItems[info.viewableItems.length - 1]?.index ?? 0;
      if (maxIndex > maxIndexViewed.current) {
        maxIndexViewed.current = maxIndex;
        if (shouldReportFeedScrolled(maxIndex)) {
          reportScrolledEvent(maxIndex);
        }
      }
    },
    [dispatch, shouldReportFeedScrolled, reportScrolledEvent]
  );

  return { onViewableItemsChanged };
};

function shouldReportFeedScrolled(indexReached: number) {
  return indexReached !== 0 && indexReached % 5 === 0;
}
