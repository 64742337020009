import {
  DurationMs,
  getDurationInHours,
  getDurationInMilliseconds,
  getDurationInMinutes,
  parseInteger,
} from "@eatbetter/common-shared";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { StyleSheet, View } from "react-native";
import { globalStyleColors, globalStyleConstants } from "../../GlobalStyles";
import { Spacer } from "../../Spacer";
import { TextInput } from "../../TextInput";
import { TBody } from "../../Typography";
import { RecipeTimePickerComponent, RecipeTimePickerProps } from "./RecipeTimePickerInterfaces";

const strings = {
  hours: "Hours",
  minutes: "Minutes",
};

export const RecipeTimePicker: RecipeTimePickerComponent = React.memo((props: RecipeTimePickerProps) => {
  const [hours, setHours] = useState<string>(String(getDurationInHours({ milliseconds: props.durationMs })));
  const [hoursError, setHoursError] = useState(false);

  const [minutes, setMinutes] = useState<string>(String(getDurationInMinutes({ milliseconds: props.durationMs }) % 60));
  const [minutesError, setMinutesError] = useState(false);

  const debounced = useRef(
    debounce(
      (
        hours: string,
        setHoursError: (value: boolean) => void,
        minutes: string,
        setMinutesError: (value: boolean) => void,
        onChangeTime: (durationMs: DurationMs) => void
      ) => {
        let isInvalid = false;

        const hoursValue = parseInteger(hours);
        if (isNaN(hoursValue)) {
          setHoursError(true);
          isInvalid = true;
        } else {
          setHoursError(false);
        }

        const minutesValue = parseInteger(minutes);
        if (isNaN(minutesValue)) {
          setMinutesError(true);
          isInvalid = true;
        } else {
          setMinutesError(false);
        }

        if (isInvalid) {
          onChangeTime(Number.NaN as DurationMs);
          return;
        }

        const hoursMs = getDurationInMilliseconds({ hours: hoursValue + Math.floor(minutesValue / 60) });
        const minutesMs = getDurationInMilliseconds({ minutes: minutesValue % 60 });
        const updatedTime = hoursMs + minutesMs;
        onChangeTime(updatedTime as DurationMs);
      },
      500
    )
  ).current;

  useEffect(() => {
    debounced(hours, setHoursError, minutes, setMinutesError, props.onChangeTime);
  }, [hours, minutes]);

  return (
    <TimeField
      label={props.label}
      hours={hours}
      onChangeHours={setHours}
      showHoursError={hoursError}
      minutes={minutes}
      onChangeMinutes={setMinutes}
      showMinutesError={minutesError}
    />
  );
});

const TimeField = React.memo(
  (props: {
    label: string;
    hours: string;
    onChangeHours: (value: string) => void;
    showHoursError: boolean;
    minutes: string;
    onChangeMinutes: (value: string) => void;
    showMinutesError: boolean;
  }) => {
    return (
      <View style={styles.row}>
        <View style={styles.label}>
          <TBody>{props.label}</TBody>
        </View>
        <Spacer horizontal={2} />
        <TimeInput
          value={props.hours}
          unit={strings.hours}
          onChangeText={props.onChangeHours}
          showError={props.showHoursError}
        />
        <Spacer horizontal={2} />
        <TimeInput
          value={props.minutes}
          unit={strings.minutes}
          onChangeText={props.onChangeMinutes}
          showError={props.showMinutesError}
        />
      </View>
    );
  }
);

const TimeInput = React.memo(
  (props: { value: string; unit: string; onChangeText: (value: string) => void; showError: boolean }) => {
    const errorStyle = props.showError ? { backgroundColor: globalStyleColors.rgba("colorAccentWarm", "medium") } : {};

    return (
      <View style={[styles.input, errorStyle]}>
        <TextInput
          value={props.value}
          maxLength={4}
          noBorder
          onChangeText={props.onChangeText}
          backgroundColor="transparent"
        />
        <TBody>{props.unit}</TBody>
      </View>
    );
  }
);

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  label: {
    minWidth: 80,
  },
  input: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "white",
    borderRadius: 0.5 * globalStyleConstants.unitSize,
    paddingHorizontal: globalStyleConstants.unitSize,
    minWidth: 300,
    maxWidth: 300,
  },
});
