import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { EpochMs } from "@eatbetter/common-shared";
import { ParsedIngredientSummary } from "@eatbetter/items-shared";

export interface DebugState {
  tempWebsocketTest: string;
  parsedIngredients: EntityState<ParsedIngredientWithMeta, number>;
  showPaywallStatusPill: boolean;
}

export interface ParsedIngredientWithMeta {
  time: EpochMs;
  parsed: ParsedIngredientSummary;
  latencyInMs: number;
}

const parserAdapter = createEntityAdapter<ParsedIngredientWithMeta, number>({
  selectId: r => r.time,
  sortComparer: (a, b) => b.time - a.time,
});

const initialState: DebugState = {
  showPaywallStatusPill: false,
  tempWebsocketTest: "",
  parsedIngredients: parserAdapter.getInitialState(),
};

const debugSlice = createSlice({
  name: "debug",
  initialState,

  reducers: create => ({
    websocketTestReceived: create.reducer((state, action: PayloadAction<string>) => {
      state.tempWebsocketTest = action.payload;
    }),

    setShowPaywallStatusPill: create.reducer((state, action: PayloadAction<{ enabled: boolean }>) => {
      state.showPaywallStatusPill = action.payload.enabled;
    }),
  }),
});

export const { websocketTestReceived, setShowPaywallStatusPill } = debugSlice.actions;

export const debugReducer = debugSlice.reducer;

export const debugParserSelectors = parserAdapter.getSelectors();
