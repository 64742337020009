import { AdminScreenView } from "../components/AdminScreenView";
import { useDispatch } from "../lib/AdminRedux";
import {
  ButtonRectangle,
  displayUnexpectedErrorAndLog,
  FlexedSpinner,
  Spacer,
  TBody,
  THeading1,
} from "@eatbetter/ui-shared";
import { useEffect, useRef, useState } from "react";
import { View } from "react-native";
import { storeHtml, storeHtmlPreview } from "../lib/recipes/AdminRecipesThunks";
import { RawHtml } from "@eatbetter/common-shared";
import { AdminStoreHtmlPreviewResult, AdminStoreHtmlResult } from "@eatbetter/recipes-shared";
import { AdminRecipesScreenIdNav } from "./AdminRecipesScreen";
import { NvpTable } from "../components/NvpTable";

export const AdminStoreHtmlScreenNav = {
  getPath: () => "/recipes/store-html",
};

const urlId = "urlInput";
const htmlId = "htmlInput";

export const AdminStoreHtmlScreen = () => {
  const intervalHandle = useRef<ReturnType<typeof setInterval> | undefined>();
  const [url, setUrl] = useState("");
  const [html, setHtml] = useState("");
  const [preview, setPreview] = useState<AdminStoreHtmlPreviewResult | undefined>();
  const [result, setResult] = useState<AdminStoreHtmlResult | undefined>();
  const [waitingPreview, setWaitingPreview] = useState(true);
  const [waitingSubmit, setWaitingSubmit] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const handle = setInterval(() => {
      const urlVal = (document.getElementById(urlId) as HTMLInputElement).value;
      const htmlVal = (document.getElementById(htmlId) as HTMLInputElement).value;
      if (urlVal && htmlVal) {
        setUrl(urlVal);
        setHtml(htmlVal);
        if (intervalHandle.current) {
          clearInterval(intervalHandle.current);
        }
      }
    }, 100);

    intervalHandle.current = handle;
    return () => clearInterval(handle);
  }, []);

  useEffect(() => {
    if (url && html) {
      dispatch(storeHtmlPreview({ html: html as RawHtml }, setWaitingPreview))
        .then(res => {
          setPreview(res);
        })
        .catch(err => {
          displayUnexpectedErrorAndLog("Unexpected error in AdminStoreHtmlScreen", err);
        });
    }
  }, [url, html]);

  const onSubmit = () => {
    dispatch(storeHtml({ html: html as RawHtml }, setWaitingSubmit))
      .then(res => {
        setResult(res);
      })
      .catch(err => {
        displayUnexpectedErrorAndLog("Error while storing HTML", err);
      });
  };

  const content = waitingPreview ? (
    <FlexedSpinner />
  ) : (
    <View>
      <table>
        <tbody>
          <NvpTable name="Page URL" value={url} />
          {!!preview && <NvpTable name="Canonical URL" value={preview.canonicalUrl ?? "Could not parse"} />}
          {!!preview?.recipe && (
            <>
              <NvpTable name="Recipe ID">
                <TBody>
                  <a href={AdminRecipesScreenIdNav.getPath(preview.recipe.id)} target="_blank" rel="noreferrer">
                    {preview.recipe.id}
                  </a>
                </TBody>
              </NvpTable>
              <NvpTable name="User Recipe Count" value={preview.userRecipes?.toString() ?? "-"} />
              <NvpTable name="Status" value={preview.recipe.status} />
              {preview.recipe.manuallyUpdatedFields.length > 0 && (
                <NvpTable name="Manually Set Fields" value={preview.recipe.manuallyUpdatedFields.join(", ")} />
              )}
            </>
          )}
          {!!preview?.message && <NvpTable name="Message" value={preview.message} />}
        </tbody>
      </table>
      <Spacer vertical={2} />
      <ButtonRectangle
        onPress={onSubmit}
        type="submit"
        title="Store and process HTML"
        waiting={waitingSubmit}
        disabled={!!result}
      />
      <Spacer vertical={2} />
      {result && (
        <TBody>
          HTML for recipe <a href={AdminRecipesScreenIdNav.getPath(result.recipeId)}>{result.recipeId}</a> has been
          stored and processing has been queued
        </TBody>
      )}
    </View>
  );

  return (
    <AdminScreenView>
      <input type="hidden" id={urlId} name={urlId} />
      <input type="hidden" id={htmlId} name={htmlId} />
      <THeading1 fontWeight="medium">Store and Process HTML</THeading1>
      <Spacer vertical={2} />
      {content}
    </AdminScreenView>
  );
};
