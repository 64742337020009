import { ParsedIngredientTags } from "@eatbetter/items-shared";
import { View } from "react-native";
import { Pressable, TBody } from "@eatbetter/ui-shared";

export const ColorCodedPhrase = (props: {
  tags: ParsedIngredientTags;
  phrase: string;
  fontWeight?: "heavy" | "normal";
}) => {
  return (
    <Pressable onPress={() => navigator.clipboard.writeText(props.phrase)}>
      <View style={{ flexDirection: "row" }}>
        {props.tags.map(t => {
          const text = props.phrase.substr(t.range[0], t.range[1] - t.range[0] + 1);
          return (
            <TBody fontWeight={props.fontWeight ?? "normal"} key={t.range[0]} color={t.color}>
              {text}
            </TBody>
          );
        })}
      </View>
    </Pressable>
  );
};
