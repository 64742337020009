import { UserRecipeId } from "@eatbetter/recipes-shared";
import { ReportRecipeIssueScreenProps } from "../navigation/NavTree";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "../lib/redux/Redux";
import { reportIssue } from "../lib/system/SystemThunks";
import { HeaderProps } from "../components/ScreenHeaders";
import { ScrollView } from "react-native";
import { BottomActionBar } from "../components/BottomActionBar";
import { useRecipeTitle } from "../lib/recipes/RecipesSelectors";
import { Haptics } from "../components/Haptics";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { TBody } from "../components/Typography";
import { Spacer } from "../components/Spacer";
import { ParagraphTextInput } from "../components/ParagraphTextInput";

const strings = {
  screenTitle: "Report a Recipe Issue",
  headline: "Did we miss a recipe on this page? Please describe the issue and we'll try to fix it.",
  submit: "Submit",
  placeholderText: "Describe the issue...",
};

export const ReportMissingRecipeIssueScreen = withScreenContainer<ReportRecipeIssueScreenProps>(
  "ReportMissingRecipeIssueScreen",
  props => {
    const screen = useScreen();
    const dispatch = useDispatch();
    const recipeTitle = useRecipeTitle(props.recipeId);

    const [text, setText] = useState("");
    const [waiting, setWaiting] = useState(false);

    const submit = useCallback(async () => {
      try {
        await dispatch(
          reportIssue(
            { type: "recipeIssue", recipeId: props.recipeId, issue: "missingRecipe", otherText: text },
            setWaiting
          )
        );
      } catch (err) {
        displayUnexpectedErrorAndLog("ReportMissingRecipeIssueScreen: error caught in submit()", err, {
          text,
          recipeId: props.recipeId,
        });
        // Don't nav back on error
        return;
      }
      Haptics.feedback("operationSucceeded");
      screen.nav.goBack();
    }, [dispatch, text, props.recipeId, setWaiting, screen.nav.goBack]);

    const header = useMemo<HeaderProps>(() => {
      return {
        type: "default",
        title: strings.screenTitle,
      };
    }, []);

    return (
      <ScreenView header={header} scrollView={false}>
        <ScrollView contentContainerStyle={{ flex: 1 }}>
          <TBody align="center" fontWeight="medium" numberOfLines={1}>
            {recipeTitle}
          </TBody>
          <Spacer vertical={1} />
          <TBody align="center" numberOfLines={2} adjustsFontSizeToFit>
            {strings.headline}
          </TBody>
          <Spacer vertical={2} />
          <ParagraphTextInput text={text} onChangeText={setText} placeholderText={strings.placeholderText} />
          <Spacer vertical={2} />
        </ScrollView>
        <BottomActionBar
          primaryAction={{
            actionText: strings.submit,
            onPressAction: submit,
            disabled: !text.trim(),
            waiting,
          }}
          containerBackgroundColor="transparent"
        />
      </ScreenView>
    );
  },
  {
    serializer: {
      recipeId: s => s,
    },
    parser: {
      recipeId: s => s as UserRecipeId,
    },
  }
);
