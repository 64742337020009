import React, { useCallback } from "react";
import { log } from "../Log";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { shareAuthedUserProfileLink } from "../lib/share/ShareThunks";
import { useSystemSetting, useIsAnonymousUser } from "../lib/system/SystemSelectors";
import { navToAnonymousSignin } from "../lib/util/AnonymousSignIn";
import { useScreen } from "../navigation/ScreenContainer";
import { globalStyleColors } from "./GlobalStyles";
import { TSecondary } from "./Typography";
import { useDispatch } from "../lib/redux/Redux";

const strings = {
  inviteFriends: "Invite your friends and family",
  inviteFriendsAction: "invite friends",
};

export const InviteFriendsTextButton = React.memo(() => {
  const screen = useScreen();
  const dispatch = useDispatch();
  const canInviteFriends = useSystemSetting("inviteFriends");
  const isAnonUser = useIsAnonymousUser();

  const onPressInviteFriends = useCallback(async () => {
    if (!canInviteFriends) {
      log.error("Invite friends callback invoked but canInviteFriends is false. Returning", { canInviteFriends });
      return;
    }

    if (isAnonUser) {
      navToAnonymousSignin(screen.nav, { mode: "action", userVisibleActionDescription: strings.inviteFriendsAction });
      return;
    }

    // External (web link) share
    try {
      await dispatch(shareAuthedUserProfileLink("Search Users Screen"));
    } catch (err) {
      displayUnexpectedErrorAndLog("Error dispatching shareAppLink in SearchUsersScreen", err);
    }
  }, [canInviteFriends, isAnonUser, dispatch]);

  return (
    <TSecondary
      onPress={onPressInviteFriends}
      fontWeight="medium"
      color={globalStyleColors.colorTextLink}
      suppressHighlighting
    >
      {strings.inviteFriends}
    </TSecondary>
  );
});
