import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo } from "react";
import { Insets, Platform, ScrollView, StyleSheet, View, ViewProps } from "react-native";
import { ContainerFadeIn } from "./Containers";
import { globalStyleColors, globalStyleConstants, Opacity } from "./GlobalStyles";
import { switchReturn } from "@eatbetter/common-shared";
import { useScreen } from "../navigation/ScreenContainer";
import {
  getDefaultHeaderHeight,
  HeaderBackButton as HeaderBackButtonInternal,
  HeaderBackButtonProps,
  HeaderButtonProps,
  HeaderTitle as HeaderTitleInternal,
} from "@react-navigation/elements";
import Animated, {
  cancelAnimation,
  clamp,
  Extrapolation,
  FadeIn,
  interpolate,
  SharedValue,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withSequence,
  withSpring,
  withTiming,
} from "react-native-reanimated";
import { NativeStackNavigationOptions } from "@react-navigation/native-stack";
import { HeaderRightProps, renderRightHeader } from "./ScreenHeaderRightButtons";
import { Spacer } from "./Spacer";
import { useNavigationState } from "@react-navigation/native";
import { useSafeAreaFrame, useSafeAreaInsets } from "react-native-safe-area-context";
import { TBody, THeading2, TSecondary, useFontFamilyMap } from "./Typography";
import { IconChevronLeft, IconClock, IconPauseFilled } from "./Icons";
import { useNextCookingTimerStatus, useShowTimerStatusBar } from "../lib/cooking/CookingTimerTick";
import { Pressable } from "./Pressable";
import { navTree } from "../navigation/NavTree";
import { BlurOverlay } from "./BlurOverlay/BlurOverlay";
import { WalkthroughStep, WalkthroughStepProps } from "./Walkthrough";
import { useServiceStatus } from "../lib/system/SystemSelectors.ts";
import { ActiveIssue } from "@eatbetter/composite-shared";

const constants = {
  timerBanner: {
    height: 38,
    button: 32,
    buttonHitSlop: { left: 24, right: 24, bottom: 12 } satisfies Insets,
    fadeInDelayMs: 0,
  },
  serviceAvailabilityBanner: {
    height: 38,
  },
  screenHeader: {
    heightAnimation: withSpring,
    heightAnimationConfig: {
      mass: 0.5,
    },
  },
};

/// Header Context - used to calculate header height measurements; subset of header props that affect header height.

type HeaderContext = Pick<CustomHeaderProps | NativeHeaderProps | NoHeaderProps, "type"> & {
  getSubHeaderHeight?: () => number;
};

export const ScreenHeaderContext = React.createContext<HeaderContext>({ type: "custom" });

type ScreenHeaderContextProviderProps = PropsWithChildren<CustomHeaderProps | NativeHeaderProps | NoHeaderProps>;

export const ScreenHeaderContextProvider = React.memo((props: ScreenHeaderContextProviderProps) => {
  const getSubHeaderHeight = props.type === "custom" ? props.subHeaderComponent?.getHeight : undefined;

  const context: HeaderContext = useMemo(() => {
    return {
      type: props.type,
      getSubHeaderHeight,
    };
  }, [props.type, getSubHeaderHeight]);

  return <ScreenHeaderContext.Provider value={context}>{props?.children}</ScreenHeaderContext.Provider>;
});

/**
 * Represents a global status banner in the header (e.g. timers, service status)
 */
interface StatusBanner {
  key: "timer" | "serviceAvailability";
  height: number;
  component: React.ComponentType<{ top: number; index: number }>;
}

/// Hooks

/**
 * Returns a list of global status banners to show in the header (e.g. timer, service status).
 */
function useActiveBanners(): StatusBanner[] {
  const showTimer = useShowTimerStatusBar();

  const serviceStatus = useServiceStatus();
  const showServiceStatusBanner = !!serviceStatus.activeIssue;

  const banners: StatusBanner[] = [];

  if (showServiceStatusBanner) {
    banners.push({
      key: "serviceAvailability",
      height: constants.serviceAvailabilityBanner.height,
      component: ServiceAvailabilityBanner,
    });
  }

  if (showTimer) {
    banners.push({
      key: "timer",
      height: constants.timerBanner.height,
      component: TimerStatusBanner,
    });
  }

  return banners;
}

export function useScreenHeaderDimensions() {
  const headerContext = useContext(ScreenHeaderContext);
  const nativeStatusBarHeight = useNativeStatusBarHeight();

  const activeBanners = useActiveBanners();
  // Banners only show for the custom header type
  const extraBannerHeight = headerContext.type === "custom" ? activeBanners.reduce((sum, b) => sum + b.height, 0) : 0;

  const statusBarHeight = nativeStatusBarHeight + extraBannerHeight;
  const defaultHeaderHeight = useDefaultHeaderHeight(statusBarHeight);
  const bareHeaderHeight = useBareHeaderHeight();

  let nativeStackHeaderHeight;
  try {
    // This hook would throw in certain occasions (last time checked) and so we fallback to a default here
    // We should revisit / consider patching

    // nativeStackHeaderHeight = useHeaderHeight();
    //
    // Incorrect status bar height returned for iPhones with dynamic islands:
    // https://github.com/react-navigation/react-navigation/commit/e4815c538536ddccf4207b87bf3e2f1603dedd84
    // Should be fixed in next major version of react-navigation (7.0)
    // In the meantime, we use the default header height that has the adjustment - see `useNativeStatusBarHeight()`
    nativeStackHeaderHeight = defaultHeaderHeight;
  } catch {
    nativeStackHeaderHeight = nativeStatusBarHeight;
  }

  const headerHeight = switchReturn(headerContext.type, {
    none: statusBarHeight,
    native: nativeStackHeaderHeight,
    custom: defaultHeaderHeight + (headerContext.getSubHeaderHeight?.() ?? 0),
  });

  // Native header height for modal is wrong, need to chase down issue in Github
  const modalHeaderHeight = Platform.OS === "web" ? headerHeight : bareHeaderHeight + globalStyleConstants.unitSize;

  const screenHeaderDimensions = useMemo(() => {
    return {
      statusBarHeight,
      headerHeight,
      modalHeaderHeight,
      bareHeaderHeight,
    };
  }, [statusBarHeight, headerHeight, modalHeaderHeight, bareHeaderHeight]);

  return screenHeaderDimensions;
}

/**
 * Provides animated values for header dimensions and animates value changes.
 */
export function useAnimatedScreenHeaderDimensions() {
  const header = useScreenHeaderDimensions();
  const heightAnimation = constants.screenHeader.heightAnimation;
  const heightAnimationConfig = constants.screenHeader.heightAnimationConfig;

  const statusBarHeight = useSharedValue(header.statusBarHeight);
  const headerHeight = useSharedValue(header.headerHeight);

  useEffect(() => {
    statusBarHeight.value = heightAnimation(header.statusBarHeight, heightAnimationConfig);
  }, [header.statusBarHeight]);

  useEffect(() => {
    headerHeight.value = heightAnimation(header.headerHeight, heightAnimationConfig);
  }, [header.headerHeight]);

  const animatedScreenHeaderDimensions = useMemo(() => {
    return {
      statusBarHeight,
      headerHeight,
    };
  }, [statusBarHeight, headerHeight]);

  return animatedScreenHeaderDimensions;
}

function useNativeStatusBarHeight() {
  const { top: nativeStatusBarHeight } = useSafeAreaInsets();
  // Incorrect status bar height returned for iPhones with dynamic islands:
  // https://github.com/react-navigation/react-navigation/commit/e4815c538536ddccf4207b87bf3e2f1603dedd84
  // Should be fixed in next major version of react-navigation (7.0)
  const hasDynamicIsland = Platform.OS === "ios" && nativeStatusBarHeight > 50;
  if (hasDynamicIsland) {
    return nativeStatusBarHeight - 5;
  }

  return nativeStatusBarHeight;
}

function useBareHeaderHeight() {
  const bareHeaderHeight = useDefaultHeaderHeight(0);
  return bareHeaderHeight;
}
function useDefaultHeaderHeight(statusBarHeight: number) {
  const safeArea = useSafeAreaFrame();
  const defaultHeaderHeight = getDefaultHeaderHeight(safeArea, false, statusBarHeight);

  return defaultHeaderHeight;
}

export function useHeaderOffset(): SharedValue<number> {
  const animationRef = useSharedValue(0);
  return animationRef;
}

export function useHeaderScrollAnimation(): [
  headerTranslateY: SharedValue<number>,
  onScroll: ReturnType<typeof useAnimatedScrollHandler>
] {
  const headerTranslateY = useSharedValue(0);

  const onScroll = useAnimatedScrollHandler({
    onScroll: e => {
      headerTranslateY.value = e.contentOffset.y;
    },
  });

  return [headerTranslateY, onScroll];
}

/// Custom + native header configuration / rendering

export function useDefaultHeaderProps(
  props?: DefaultHeaderProps | NativeHeaderProps | CustomHeaderProps | NoHeaderProps
): CustomHeaderProps | NativeHeaderProps | NoHeaderProps {
  const haveActiveBanners = useActiveBanners().length > 0;

  return useMemo(() => {
    if (!props || props.type === "none") {
      return { type: "none" };
    }

    // Our native header configuration is only supported on iOS, so we render a custom header elsewhere for now
    // If the timer status bar is showing, we need a custom header as well
    if (Platform.OS !== "ios" || (props.type === "default" && haveActiveBanners)) {
      return {
        ...props,
        type: "custom",
      };
    }

    if (props.type === "custom" || props.type === "native") {
      return props;
    }

    return {
      ...props,
      type: "native",
    };
  }, [props, haveActiveBanners]);
}

export function useNativeHeaderOptions(props: HeaderProps, isModal?: boolean): NativeStackNavigationOptions {
  const nativeHeaderOptions = useMemo(() => (props.type === "native" ? props : undefined), [props]);
  const isNativeHeader = !!nativeHeaderOptions;

  const fontFamily = useFontFamilyMap();
  const screen = useScreen();

  const onPressBackButton = useCallback(() => {
    screen.nav.goBack();
  }, [screen.nav.goBack]);

  const style = nativeHeaderOptions?.style ?? "default";

  const headerStyle = useMemo<NativeStackNavigationOptions["headerStyle"]>(() => {
    if (nativeHeaderOptions?.backgroundColor) {
      return { backgroundColor: nativeHeaderOptions.backgroundColor };
    }

    if (Platform.OS === "web") {
      return { backgroundColor: "white" };
    }

    return undefined;
  }, [nativeHeaderOptions?.backgroundColor]);

  const headerTitle = nativeHeaderOptions?.title ?? "";

  const headerTitleStyle = useMemo(() => {
    return { color: nativeHeaderOptions?.titleColor ?? "black", fontFamily: fontFamily.sansSerif };
  }, [nativeHeaderOptions?.titleColor, fontFamily.sansSerif]);

  const headerLeft = useMemo<NativeStackNavigationOptions["headerLeft"]>(() => {
    if (style === "tabRoot") {
      return () => <HeaderTitle title={headerTitle} headerStyle={style} />;
    }

    return (backButtonProps: HeaderBackButtonProps) =>
      !!backButtonProps.canGoBack &&
      !isModal && <HeaderBackButton context="nativeHeader" onPress={onPressBackButton} />;
  }, [style, isModal, headerTitle, onPressBackButton]);

  const headerRight = useCallback(
    ({ tintColor }: HeaderButtonProps) =>
      nativeHeaderOptions?.right ? renderRightHeader({ ...nativeHeaderOptions.right, tintColor }) : null,
    [nativeHeaderOptions?.right]
  );

  const headerBlurEffect = useMemo<NativeStackNavigationOptions["headerBlurEffect"]>(() => {
    if (nativeHeaderOptions?.backgroundColor === "transparent") {
      return undefined;
    }

    return "prominent";
  }, [nativeHeaderOptions?.backgroundColor]);

  const options = useMemo<NativeStackNavigationOptions>(() => {
    if (!isNativeHeader) {
      return {
        headerShown: false,
        // This must stay - this was 9 hours of my life (luckily on a plane, but still). There is a race condition
        // of some kind with rendering the right / left components at the native level, and several issues tracking
        // it in react-native-screens. Setting these to undefined when we hide the header seems to fix any issues.
        // Originally surfaced through the timer status bar showing / hiding.
        headerLeft: undefined,
        headerRight: undefined,
      };
    }

    return {
      headerShown: true,
      headerStyle,
      headerTitle,
      headerTitleStyle,
      headerLeft,
      headerRight,
      headerTintColor: nativeHeaderOptions.tintColor ?? globalStyleColors.colorNavigationTint,
      headerTransparent: true,
      headerBlurEffect,
      headerBackTitleVisible: false,
      headerBackVisible: false,
    };
  }, [
    isNativeHeader,
    headerStyle,
    headerTitle,
    headerTitleStyle,
    headerLeft,
    headerRight,
    nativeHeaderOptions?.tintColor,
    headerBlurEffect,
  ]);

  return options;
}

type HeaderStyle = "default" | "tabRoot";
type HeaderType = "none" | "default" | "native" | "custom";

interface HeaderPropsBase<TType extends HeaderType> {
  type: TType;
  title: string | (() => React.ReactElement);
  style?: HeaderStyle;
  backgroundColor?: string;
  titleColor?: string;
  tintColor?: string;
  right?: HeaderRightProps;
}

export type NativeHeaderProps = HeaderPropsBase<"native">;
type DefaultHeaderProps = HeaderPropsBase<"default">;
export type NoHeaderProps = Pick<HeaderPropsBase<"none">, "type">;

export interface HeaderAnimationConfig {
  /**
   * Provides a mechanism for binding the header to an onScroll event for varying animations (e.g. retraction)
   */
  animationProgress?: SharedValue<number>;
  /**
   * If `animationProgress` is less than this threshold the header will be transparent, if greater it will be blurred
   */
  blurBackgroundThreshold?: number;
  disableRetract?: boolean;
  /**
   * The locking point for the header when scrolling down (finger up). Defaults to `bareHeaderHeight`.
   */
  retractClampY?: number;
}

export interface CustomHeaderProps extends HeaderPropsBase<"custom"> {
  onPressBack?: () => void;
  disableBack?: boolean;
  backButtonWalkthrough?: WalkthroughStepProps;
  animationConfig?: HeaderAnimationConfig;
  subHeaderComponent?: { render: () => React.ReactNode; getHeight: () => number };
}

export type HeaderProps = DefaultHeaderProps | NativeHeaderProps | CustomHeaderProps | NoHeaderProps;

export const CustomHeader = React.memo((props: CustomHeaderProps) => {
  const bareHeaderHeight = useBareHeaderHeight();
  const { statusBarHeight } = useAnimatedScreenHeaderDimensions();

  const animationProgress = props.animationConfig?.animationProgress;
  const disableRetraction = props.animationConfig?.disableRetract;
  const blurBackgroundThreshold = props.animationConfig?.blurBackgroundThreshold;
  const retractClampY = props.animationConfig?.retractClampY ?? bareHeaderHeight;

  const hideShowHeader = useAnimatedStyle(() => {
    if (!animationProgress || !!disableRetraction) {
      return {};
    }

    return {
      transform: [
        {
          translateY: interpolate(
            animationProgress.value,
            [0, retractClampY],
            [0, -retractClampY],
            Extrapolation.CLAMP
          ),
        },
      ],
    };
  }, [animationProgress, retractClampY, disableRetraction]);

  const bareHeaderOpacity = useAnimatedStyle(() => {
    if (!animationProgress || disableRetraction) {
      return {};
    }

    return {
      opacity: interpolate(
        animationProgress.value,
        [0, bareHeaderHeight],
        [Opacity.opaque, Opacity.transparent],
        Extrapolation.CLAMP
      ),
    };
  }, [animationProgress, bareHeaderHeight, disableRetraction]);

  const subHeaderHeight = props.subHeaderComponent?.getHeight() ?? 0;

  const subHeaderOpacity = useAnimatedStyle(() => {
    if (!animationProgress || disableRetraction) {
      return {};
    }

    return {
      opacity: interpolate(
        animationProgress.value,
        [0, bareHeaderHeight, retractClampY],
        [Opacity.opaque, Opacity.opaque, Opacity.transparent],
        Extrapolation.CLAMP
      ),
    };
  }, [animationProgress, bareHeaderHeight, subHeaderHeight, disableRetraction]);

  const snapToStatusBar = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: statusBarHeight.value,
        },
      ],
    };
  }, [statusBarHeight]);

  const snapToBareHeader = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: bareHeaderHeight + statusBarHeight.value,
        },
      ],
    };
  }, [bareHeaderHeight, statusBarHeight]);

  const blurViewOpacity = useAnimatedStyle(() => {
    if (!animationProgress) {
      return {};
    }

    if (animationProgress.value < (blurBackgroundThreshold ?? bareHeaderHeight)) {
      return { opacity: Opacity.transparent };
    }

    return { opacity: Opacity.opaque };
  }, [animationProgress, blurBackgroundThreshold, bareHeaderHeight]);

  const height = useAnimatedStyle(() => {
    const baseHeight = bareHeaderHeight + statusBarHeight.value + subHeaderHeight;
    if (!animationProgress || !!disableRetraction) {
      return {
        height: baseHeight,
      };
    }

    return {
      height: baseHeight - clamp(animationProgress.value, 0, retractClampY),
    };
  }, [retractClampY, statusBarHeight, subHeaderHeight, animationProgress, disableRetraction]);

  const backgroundColor = useMemo(() => {
    if (props.backgroundColor) {
      return { backgroundColor: props.backgroundColor };
    }

    if (Platform.OS === "web") {
      return { backgroundColor: "white" };
    }

    return {};
  }, [props.backgroundColor]);

  const blurBackground = !props.backgroundColor && Platform.OS !== "web";

  return (
    <Animated.View style={[styles.header, backgroundColor, height]}>
      {blurBackground && (
        <Animated.View style={[StyleSheet.absoluteFill, blurViewOpacity]}>
          <BlurOverlay style="chrome" noLayoutAnimation />
        </Animated.View>
      )}
      <StatusBar />
      <Animated.View style={[styles.headerPart, backgroundColor, hideShowHeader]}>
        <Animated.View style={[bareHeaderOpacity, snapToStatusBar]}>
          <BareHeader
            style={props.style}
            title={props.title}
            titleColor={props.titleColor}
            right={props.right}
            onPressBack={props.onPressBack}
            disableBack={props.disableBack}
            backButtonWalkthrough={props.backButtonWalkthrough}
          />
        </Animated.View>
        {!!props.subHeaderComponent && (
          <Animated.View style={[styles.headerPart, snapToBareHeader, subHeaderOpacity]}>
            {props.subHeaderComponent.render()}
          </Animated.View>
        )}
      </Animated.View>
    </Animated.View>
  );
});

interface HeaderTitleProps {
  title: string | (() => React.ReactNode);
  textColor?: string;
  headerStyle: HeaderStyle;
  left?: number;
  right?: number;
}

const HeaderTitle = React.memo((props: HeaderTitleProps) => {
  const fontFamily = useFontFamilyMap();

  const titleStyle = useMemo(() => {
    const alignmentAndFont = switchReturn(props.headerStyle, {
      default: [{ textAlign: "center" as const, fontFamily: fontFamily.sansSerif }],
      tabRoot: [styles.leftLargeTitle, { fontFamily: fontFamily.serif }],
    });
    const color = props.textColor ? { color: props.textColor } : {};

    return [alignmentAndFont, color];
  }, [props.headerStyle, fontFamily.serif, fontFamily.sansSerif, props.textColor]);

  const alignStyle = useMemo(
    () =>
      switchReturn<HeaderStyle, ViewProps["style"]>(props.headerStyle, {
        tabRoot: {
          alignSelf: "center",
          left: 8,
        },
        default: () => {
          const left = props.left ? { left: props.left } : {};
          const right = props.right ? { right: props.right } : {};

          return [styles.centeredTitle, left, right];
        },
      }),
    [props.headerStyle, props.left, props.right]
  );

  return (
    <View style={alignStyle}>
      {typeof props.title === "string" && (
        <HeaderTitleInternal
          adjustsFontSizeToFit
          minimumFontScale={0.8}
          allowFontScaling={false}
          numberOfLines={2}
          ellipsizeMode={"tail"}
          style={titleStyle}
        >
          {props.title}
        </HeaderTitleInternal>
      )}
      {typeof props.title !== "string" && props.title()}
    </View>
  );
});

const HeaderBackButton = React.memo(
  (
    props: Pick<HeaderBackButtonProps, "onPress" | "disabled"> & { context: "nativeHeader" | "customHeader" } & {
      walkthrough?: WalkthroughStepProps;
    }
  ) => {
    const renderBackImage = useCallback(({ tintColor }: HeaderBackButtonProps) => {
      return <IconChevronLeft color={tintColor} opacity="opaque" size={32} strokeWidth={1} />;
    }, []);

    const style = useMemo<HeaderBackButtonProps["style"]>(
      () =>
        switchReturn(props.context, {
          nativeHeader: {
            transform: [
              {
                translateX: -4,
              },
            ],
          },
          customHeader: {
            paddingLeft: Platform.OS === "web" ? 0 : 12,
          },
        }),
      [props.context]
    );

    const backButton = (
      <ContainerFadeIn style={{ zIndex: 1 }}>
        <HeaderBackButtonInternal
          style={style}
          labelVisible={false}
          tintColor={props.walkthrough ? "white" : "black"}
          onPress={props.onPress}
          backImage={renderBackImage}
          disabled={props.disabled}
        />
      </ContainerFadeIn>
    );

    return props.walkthrough ? <WalkthroughStep {...props.walkthrough}>{backButton}</WalkthroughStep> : backButton;
  }
);

const BareHeader = React.memo(
  (
    props: Pick<
      CustomHeaderProps,
      "style" | "title" | "right" | "tintColor" | "titleColor" | "onPressBack" | "disableBack" | "backButtonWalkthrough"
    >
  ) => {
    const screen = useScreen();
    const canGoBack = useNavigationState(s => s.index) > 0 && screen.nav.focused;
    const bareHeaderHeight = useBareHeaderHeight();

    const onPressBackButton = useCallback(() => {
      if (props.onPressBack) {
        props.onPressBack();
        return;
      }
      screen.nav.goBack();
    }, [screen.nav.goBack, props.onPressBack]);

    const titleStyle = props.style ?? "default";
    const tintColor = props.tintColor ?? globalStyleColors.colorNavigationTint;

    const headerButtonWidth = 52;
    const titleLeftBoundary = headerButtonWidth;
    const titleRightBoundary =
      props.right?.type === "twoButtons"
        ? 2 * headerButtonWidth
        : props.right?.type === "threeButtons"
        ? 3 * headerButtonWidth
        : headerButtonWidth;
    const titleBoundary = Math.max(titleLeftBoundary, titleRightBoundary);

    const title = useMemo(
      () => (
        <HeaderTitle
          title={props.title}
          textColor={props.titleColor}
          headerStyle={titleStyle}
          left={titleBoundary}
          right={titleBoundary}
        />
      ),
      [props.title, props.titleColor, titleStyle, titleBoundary]
    );

    const headerLeft = useMemo(
      () => (
        <View style={{ position: "absolute", flexDirection: "row", alignItems: "center" }}>
          {titleStyle === "default" && canGoBack && (
            <HeaderBackButton
              context="customHeader"
              onPress={onPressBackButton}
              disabled={props.disableBack}
              walkthrough={props.backButtonWalkthrough}
            />
          )}
          {titleStyle === "tabRoot" && (
            <>
              {canGoBack && (
                <>
                  <HeaderBackButton
                    context="customHeader"
                    onPress={onPressBackButton}
                    disabled={props.disableBack}
                    walkthrough={props.backButtonWalkthrough}
                  />
                  {title}
                </>
              )}
              {!canGoBack && <View style={{ marginLeft: 12 }}>{title}</View>}
            </>
          )}
        </View>
      ),
      [titleStyle, canGoBack, onPressBackButton, props.disableBack, title, props.backButtonWalkthrough]
    );

    const headerRight = useMemo(
      () => (
        <>
          {!!props.right && (
            <View style={{ position: "absolute", right: 12 }}>{renderRightHeader({ ...props.right, tintColor })}</View>
          )}
        </>
      ),
      [props.right, tintColor]
    );

    return (
      <View style={[styles.bareHeader, { height: bareHeaderHeight }]}>
        {titleStyle === "default" && title}
        {headerLeft}
        {headerRight}
      </View>
    );
  }
);

/// Custom status bar + banners

/**
 * Displays a backend-triggered service availability message (e.g. outage)
 */
export const ServiceAvailabilityBanner = React.memo((props: { top: number; index: number }) => {
  const status = useServiceStatus().activeIssue;
  const { nav } = useScreen();

  const onPress = useCallback(() => {
    if (!status) {
      return;
    }

    nav.modal(navTree.get.screens.bottomSheet, {
      height: 400,
      content: <ServiceStatusDetail activeIssue={status} />,
    });
  }, [status]);

  return (
    <Pressable
      onPress={onPress}
      noFeedback
      style={{
        position: "absolute",
        top: props.top,
        left: 0,
        right: 0,
        height: constants.serviceAvailabilityBanner.height,
        zIndex: 4,
        backgroundColor: globalStyleColors.colorAccentWarmLight,
        justifyContent: "center",
        paddingHorizontal: globalStyleConstants.defaultPadding,
      }}
    >
      <TSecondary align="center">
        <TSecondary>{"🚨 We're fixing "}</TSecondary>
        <TSecondary color={globalStyleColors.colorTextLink} fontWeight="medium" underline>
          {"an issue "}
        </TSecondary>
        <TSecondary>{"right now, hang tight."}</TSecondary>
      </TSecondary>
    </Pressable>
  );
});

const ServiceStatusDetail = (props: { activeIssue: ActiveIssue }) => {
  return (
    <ScrollView style={{ flex: 1, padding: globalStyleConstants.defaultPadding }}>
      <THeading2>{props.activeIssue.title}</THeading2>
      <Spacer vertical={1} />
      <TBody>{props.activeIssue.body}</TBody>
      <Spacer vertical={2} />
      <TSecondary>Last updated: {new Date(props.activeIssue.lastUpdate).toLocaleString()}</TSecondary>
    </ScrollView>
  );
};

/**
 * Displays the next expiring active timer right under the native status bar (or top of screen for notchless devices). Height animates in and out.
 */
export const TimerStatusBanner = React.memo((props: { top: number; index: number }) => {
  const screen = useScreen();

  const { statusBarHeight } = useAnimatedScreenHeaderDimensions();

  const nextActiveTimer = useNextCookingTimerStatus();
  const timerAlerting = !!nextActiveTimer?.alerting;
  const hasActiveTimer = !!nextActiveTimer && nextActiveTimer.status === "running";

  const opacity = useSharedValue(0);
  const timerRotateZ = useSharedValue("0deg");

  useEffect(() => {
    if (!hasActiveTimer) {
      cancelAnimation(opacity);
      opacity.value = 0;
      return;
    }

    const targetValue = opacity.value === 1 ? 0 : 1;

    opacity.value = withRepeat(withTiming(targetValue, { duration: 2000 }), -1, true);
  }, [hasActiveTimer]);

  useEffect(() => {
    if (!timerAlerting) {
      cancelAnimation(timerRotateZ);
      timerRotateZ.value = "0deg";
      return;
    }

    timerRotateZ.value = withRepeat(
      withSequence(withTiming("10deg", { duration: 100 }), withSpring("0deg", { stiffness: 400 })),
      -1,
      false
    );
  }, [timerAlerting]);

  const onPressTimerStatusBar = useCallback(() => {
    screen.nav.modal(navTree.get.screens.timers);
  }, [screen.nav.modal]);

  const opacityAnimation = useAnimatedStyle(() => {
    if (timerAlerting) {
      return {
        opacity: 1,
      };
    }

    return {
      opacity: opacity.value,
    };
  }, [timerAlerting, opacity]);

  const alertingAnimation = useAnimatedStyle(() => {
    return {
      transform: [
        {
          rotateZ: timerRotateZ.value,
        },
      ],
    };
  }, [timerRotateZ]);

  const timerStatusBarBackgroundPosition = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: interpolate(
            statusBarHeight.value,
            [props.top, props.top + constants.timerBanner.height],
            // The extra height adjustment serves as background on the top side when the spring overshoots
            [props.top - constants.timerBanner.height - 8, props.top - 8]
          ),
        },
      ],
    };
  }, [statusBarHeight, props.top]);

  const pulseColor = globalStyleColors.rgba("colorAccentMid");
  const bannerSeparator = props.index > 0 ? 0.5 * globalStyleConstants.defaultPadding : 0;
  const height = nextActiveTimer ? props.top + constants.timerBanner.height + bannerSeparator : 0;

  if (!nextActiveTimer) {
    return null;
  }

  return (
    <>
      <Animated.View style={[styles.timerStatusBarBackground, timerStatusBarBackgroundPosition]} />
      <Animated.View
        entering={FadeIn.delay(constants.timerBanner.fadeInDelayMs)}
        style={[styles.timerStatusBar, { height }]}
      >
        <Animated.View style={[StyleSheet.absoluteFill, { backgroundColor: pulseColor }, opacityAnimation]} />
        <View style={styles.timerPulseContainer}>
          <Pressable
            hitSlop={constants.timerBanner.buttonHitSlop}
            onPress={onPressTimerStatusBar}
            style={[styles.timerButton, { backgroundColor: pulseColor }]}
          >
            <Animated.View
              style={[{ flexDirection: "row", justifyContent: "center", alignItems: "center" }, alertingAnimation]}
            >
              {(nextActiveTimer.status === "running" || nextActiveTimer.status === "complete") && (
                <View style={{ paddingRight: 0.25 * globalStyleConstants.unitSize }}>
                  <IconClock opacity="opaque" size={16} strokeWidth={2.5} />
                </View>
              )}
              {nextActiveTimer.status === "paused" && (
                <View>
                  <IconPauseFilled opacity="opaque" size={17} />
                </View>
              )}
              <Spacer horizontal={0.25} />
              <View>
                <TBody font="monospace">{nextActiveTimer.defaultDisplay}</TBody>
              </View>
            </Animated.View>
          </Pressable>
        </View>
      </Animated.View>
    </>
  );
});

export const StatusBar = React.memo(() => {
  const nativeStatusBarHeight = useNativeStatusBarHeight();
  const activeBanners = useActiveBanners();

  // Keeps track of stack ordering for the set of banners
  let top = nativeStatusBarHeight;

  return (
    <>
      <View style={[styles.statusBar, { height: nativeStatusBarHeight }]} />
      {activeBanners.map((banner, idx) => {
        const currentTop = top;
        top += banner.height;
        return React.createElement(banner.component, { key: banner.key, top: currentTop, index: idx });
      })}
    </>
  );
});

const styles = StyleSheet.create({
  header: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
  },
  headerPart: {
    position: "absolute",
    left: 0,
    right: 0,
    zIndex: 1,
  },
  bareHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  centeredTitle: {
    position: "absolute",
    left: "15%",
    right: "15%",
    alignItems: "center",
    alignSelf: "center",
  },
  leftLargeTitle: {
    fontSize: 28,
    fontWeight: "500",
  },
  statusBar: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2,
  },
  timerStatusBarBackground: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    height: constants.timerBanner.height + 8,
  },
  timerStatusBar: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 3,
  },
  timerPulseContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-end",
    paddingBottom: globalStyleConstants.unitSize / 2,
  },
  timerButton: {
    justifyContent: "center",
    alignItems: "center",
    height: constants.timerBanner.button,
    paddingHorizontal: globalStyleConstants.unitSize,
    borderRadius: constants.timerBanner.height / 2,
  },
});
