import {
  CommentRecipeInfo,
  GlobalFeedKey,
  isEntitySocialPost,
  isUserSocialPost,
  ModeratedSocialPost,
  RecipeSocialPost,
  SocialPost,
  TextPost,
} from "@eatbetter/posts-shared";
import { bottomNop, defaultTimeProvider, hoursBetween, UserId } from "@eatbetter/common-shared";
import { FlatList, StyleProp, useWindowDimensions, View, ViewStyle } from "react-native";
import { ButtonRectangle, Photo, Spacer, TBody, THeading2, TTertiary } from "@eatbetter/ui-shared";
import { ModeratedSocialPostWithFn, useAdminSocialFeed } from "../lib/social/UseAdminSocialFeed";
import { useState } from "react";
import { AdminRecipesScreenIdNav } from "../screens/AdminRecipesScreen";
import { AdminUsersScreenIdNav } from "../screens/AdminUsersScreen";
import { EntityDisplay } from "@eatbetter/photos-shared";

export const AdminSocialFeed = (props: { feed: UserId | GlobalFeedKey }) => {
  const { items, fetchNext } = useAdminSocialFeed(props.feed);
  const window = useWindowDimensions();

  return (
    <View style={{ height: window.height * 0.8 }}>
      <FlatList data={items} renderItem={renderItem} keyExtractor={i => i.post.id} onEndReached={fetchNext} />
    </View>
  );
};

function renderItem(item: { item: ModeratedSocialPost }) {
  return <ModeratedPost post={item.item} />;
}

export const ModeratedPost = (props: { post: ModeratedSocialPostWithFn }) => {
  const moderationResult = props.post.moderationResult?.["curated"];
  const approved = moderationResult?.approved;
  const moderatedBy = moderationResult?.by;
  const [waiting, setWaiting] = useState(false);
  const hoursAgo = hoursBetween(props.post.post.created, defaultTimeProvider()).toFixed(1);

  let borderStyle: StyleProp<ViewStyle> = {};
  if (approved === true) {
    borderStyle = { borderLeftWidth: 6, borderLeftColor: "lightgreen" };
  } else if (approved === false) {
    borderStyle = { borderLeftWidth: 6, borderLeftColor: "red" };
  }

  return (
    <View
      style={{
        backgroundColor: "white",
        borderColor: "black",
        borderWidth: 1,
        padding: 12,
        marginBottom: 12,
        ...borderStyle,
      }}
    >
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <TBody>{props.post.post.id} by </TBody>
        <Spacer horizontal={0.5} />
        <Photo
          source={isUserSocialPost(props.post.post) ? props.post.post.user.photo : props.post.post.entity.photo}
          style="avatarXsmall"
          sourceSize="w288"
        />
        <Spacer horizontal={0.5} />
        <TBody>
          {isUserSocialPost(props.post.post) && (
            <a href={AdminUsersScreenIdNav.getPath(props.post.post.user.userId)} target="_blank" rel="noreferrer">
              {props.post.post.user.name} (@{props.post.post.user.username}){" "}
            </a>
          )}
          {isEntitySocialPost(props.post.post) && <TBody>{props.post.post.entity.name}</TBody>} from {hoursAgo} hours
          ago
        </TBody>
      </View>

      <Spacer vertical={1} />
      <PostDetail post={props.post.post} />
      <Spacer vertical={2} />
      <SocialActivity post={props.post.post} />
      <Spacer vertical={2} />
      {props.post.approve && (
        <View style={{ flexDirection: "row", width: 300, alignItems: "center" }}>
          <ButtonRectangle
            type="cancel"
            title="Reject"
            onPress={() => props.post.approve?.(false, setWaiting)}
            disabled={approved === false || waiting}
            waiting={waiting}
          />
          <Spacer horizontal={1} />
          <ButtonRectangle
            type="submit"
            title="Approve"
            onPress={() => props.post.approve?.(true, setWaiting)}
            disabled={approved === true || waiting}
            waiting={waiting}
          />
          <Spacer horizontal={1} />
          {approved !== undefined && (
            <TTertiary>
              {approved ? "Approved" : "Rejected"} by {moderatedBy}
            </TTertiary>
          )}
        </View>
      )}
    </View>
  );
};

const SocialActivity = (props: { post: SocialPost }) => {
  if (props.post.comments.count === 0) {
    return <TBody>(No comments)</TBody>;
  }

  const maxCount = 5;
  return (
    <View>
      {props.post.comments.items.slice(0, maxCount).map(comment => {
        return (
          <View key={comment.id}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Photo source={comment.user.photo} style="avatarXsmall" sourceSize="w288" />
              <TTertiary>
                {" "}
                {comment.user.name} (@{comment.user.username})
              </TTertiary>
            </View>
            <Spacer vertical={1} />
            {!!comment.text && (
              <View style={{ flexDirection: "row" }}>
                <Spacer horizontal={2} />
                <TBody>{comment.text}</TBody>
              </View>
            )}
            {(comment.recipes ?? []).map(cr => {
              return <RecipeComment comment={cr} key={cr.sourceRecipeId} />;
            })}

            <Spacer vertical={2} />
          </View>
        );
      })}
      {props.post.comments.count > maxCount && <TBody>({props.post.comments.count - maxCount} more comment(s))</TBody>}
    </View>
  );
};

const RecipeComment = (props: { comment: CommentRecipeInfo }) => {
  return (
    <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 12 }}>
      <Spacer horizontal={2} />
      <Photo source={props.comment.recipeInfo.photo} style="thumbnailSmall" sourceSize="w288" />
      <Spacer horizontal={1} />
      <TBody>
        <a href={AdminRecipesScreenIdNav.getPath(props.comment.sourceRecipeId)} target="_blank" rel="noreferrer">
          {props.comment.recipeInfo.title}
        </a>
      </TBody>
    </View>
  );
};

const PostDetail = (props: { post: SocialPost }) => {
  switch (props.post.type) {
    case "userRecipeActionPost":
    case "newRecipePost":
      return <RecipePostDetail post={props.post} />;
    case "textPost":
      return <TextPostDetail post={props.post} />;
    default:
      bottomNop(props.post);
      return null;
  }
};

const Entity = (props: { entity: EntityDisplay; type: string; link?: string }) => {
  return (
    <View>
      <TTertiary>{props.type}</TTertiary>
      <Spacer vertical={1} />
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Photo source={props.entity.photo} style="avatarSmall" sourceSize="w288" />
        <Spacer horizontal={1} />
        <THeading2>
          {!props.link && props.entity.name}
          {!!props.link && (
            <a href={props.link} target="_blank" rel="noreferrer">
              {props.entity.name}
            </a>
          )}
        </THeading2>
      </View>
    </View>
  );
};

const RecipePostDetail = (props: { post: RecipeSocialPost }) => {
  const author = props.post.recipeInfo.author;
  const authorType = author?.type === "unknownAuthor" ? "Unknown Author" : "User Author";
  const authorLink = author?.type === "userAuthor" ? AdminUsersScreenIdNav.getPath(author.userId) : undefined;

  const rating = props.post.type === "userRecipeActionPost" && props.post.rating?.rating;
  const ratingColor = rating ? (rating < 4 ? "red" : rating === 5 ? "lightgreen" : undefined) : undefined;
  const cookCountColor =
    props.post.type === "userRecipeActionPost" && props.post.userRecipeStats.cooked > 1 ? "lightgreen" : undefined;

  const recipeId = props.post.type === "userRecipeActionPost" ? props.post.sourceRecipeId : props.post.recipeInfo.id;

  return (
    <View>
      <View style={{ flex: 1, flexDirection: "row" }}>
        {/*Photo*/}
        <View>
          <View style={{ width: 430, height: 256 }}>
            <Photo sourceSize="w1290" source={props.post.recipeInfo.photo} style="fullWidthLarge" />
          </View>
        </View>
        <Spacer horizontal={2} />
        {/*Publisher/book and author, stacked vertically */}
        <View>
          <View style={{ flex: 1 }}>
            {props.post.recipeInfo.publisher && <Entity entity={props.post.recipeInfo.publisher} type="Publisher" />}
            {props.post.recipeInfo.book && <Entity entity={props.post.recipeInfo.book} type="Book" />}
          </View>
          <View style={{ flex: 1 }}>
            {props.post.recipeInfo.author && (
              <Entity entity={props.post.recipeInfo.author} type={authorType} link={authorLink} />
            )}
          </View>
        </View>
      </View>
      <Spacer vertical={1} />
      <THeading2>
        <a href={AdminRecipesScreenIdNav.getPath(recipeId)} target="_blank" rel="noreferrer">
          {props.post.recipeInfo.title}
        </a>
      </THeading2>
      {props.post.recipeInfo.source.type === "url" && (
        <a href={props.post.recipeInfo.source.canonicalUrl} target="_blank" rel="noreferrer">
          {props.post.recipeInfo.source.canonicalUrl}
        </a>
      )}

      {props.post.type === "userRecipeActionPost" && (
        <>
          <Spacer vertical={1} />
          <TBody color={ratingColor} fontWeight={ratingColor ? "heavy" : undefined}>
            Rating: {props.post.rating?.rating ?? "-"}
          </TBody>
          <TBody color={cookCountColor} fontWeight={cookCountColor ? "heavy" : undefined}>
            Cooked count: {props.post.userRecipeStats.cooked}
          </TBody>
        </>
      )}
    </View>
  );
};

const TextPostDetail = (props: { post: TextPost }) => {
  return (
    <View>
      <TBody>{props.post.body}</TBody>
    </View>
  );
};
