import { combineReducers } from "@reduxjs/toolkit";
import { dataManagerReducer } from "./data-manager/DataManagerSlice";
import { adminSystemReducer } from "./system/AdminSystemSlice";

export const rootReducer = combineReducers({
  dataManager: dataManagerReducer,
  system: adminSystemReducer,
});

export type AdminRootState = ReturnType<typeof rootReducer>;
