import { UserRecipeId } from "@eatbetter/recipes-shared";
import { globalStyleColors } from "../components/GlobalStyles";
import { RecipeTimeEdit } from "../components/recipes/RecipeTimeEdit";
import { ScreenView } from "../components/ScreenView";
import { useRecipeTitle } from "../lib/recipes/RecipesSelectors";
import { withScreenContainer } from "../navigation/ScreenContainer";
import { RecipeEditFieldLocation, RecipeTimeEditScreenProps } from "../navigation/NavTree";

const strings = {
  time: "Time",
  screenTitle: "Recipe Time",
};

export const RecipeTimeEditScreen = withScreenContainer(
  "RecipeTimeEditScreen",
  (props: RecipeTimeEditScreenProps) => {
    const recipeTitle = useRecipeTitle(props.recipeId);

    return (
      <ScreenView
        backgroundColor={globalStyleColors.colorGreyLight}
        scrollView={false}
        paddingHorizontal={false}
        paddingVertical={false}
        header={{
          type: "custom",
          title: `${strings.time}: ${recipeTitle}` ?? strings.screenTitle,
        }}
      >
        <RecipeTimeEdit recipeId={props.recipeId} location={props.recipeEditFieldLocation} />
      </ScreenView>
    );
  },
  {
    serializer: {
      recipeId: s => s,
      recipeEditFieldLocation: s => s,
    },
    parser: {
      recipeId: s => s as UserRecipeId,
      recipeEditFieldLocation: s => s as RecipeEditFieldLocation,
    },
  }
);
