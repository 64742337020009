import { AdminCategoryOverrideArgs, AdminCategoryOverrideResult } from "@eatbetter/lists-shared";
import { ThunkAction } from "../AdminRedux";
import { SetWaitingHandler } from "@eatbetter/ui-shared/dist";

export const addIngredientCategoryOverride = (
  args: AdminCategoryOverrideArgs,
  setWaiting?: SetWaitingHandler
): ThunkAction<AdminCategoryOverrideResult> => {
  return async (_dispatch, _getState, deps) => {
    const result = await deps.api.withThrow(setWaiting).addCategoryOverride(args);
    return result.data;
  };
};
