import { toBasicLatin } from "@eatbetter/common-shared";

export const minNameLength = 3;
export const minUsernameLength = 3;
export const maxBioLength = 150;
const maxLength = 30;

export function isValidUsername(str: string) {
  // must be lowercase, must not end in a period
  // must be at least 3 characters
  // must be <= 30 characters
  if (str.length > maxLength) {
    return false;
  }

  // IF THIS REGEX CHANGES, THE REGEXES IN MENTIONS.TS LIKELY NEED TO CHANGE AS WELL
  // IF THE SET OF CHARACTERS THAT CAN BE THE FIRST CHARACTER CHANGES, SCAN USERS IN USERADMINAPI MUST BE UPDATED
  const r = /^[a-z0-9-_.]{2,29}[a-z0-9-_]$/;

  if (!r.test(str)) {
    return false;
  }

  if (containsBlockedWorkds(str)) {
    return false;
  }

  return true;
}

function containsBlockedWorkds(str: string): boolean {
  const s = str.toLowerCase().replace(/[^a-z]/g, "");
  return s.includes("deglaze");
}

/**
 * Attempt to modify the username candidate to make it a valid username
 */
export function makeValidUsername(candidate: string): string | undefined {
  const lc = toBasicLatin(candidate)
    .toLowerCase()
    .replace(/\s{1,}/g, ".")
    .trim();
  const r = /^[a-z0-9-_.]$/;
  const filtered = [...lc].filter(char => r.test(char)).join("");
  const c = filtered.endsWith(".") ? filtered.substring(0, filtered.length - 1) : filtered;
  const c2 = c.length > maxLength ? c.substring(0, maxLength) : c;

  if (c2.length === 0) {
    return undefined;
  }

  const c3 = c2.length < minUsernameLength ? c2.padEnd(minUsernameLength, "0") : c2;

  if (isValidUsername(c3)) {
    return c3;
  }

  return undefined;
}

export function isValidName(candidate: string): boolean {
  const trimmed = candidate.trim();

  if (trimmed.includes("@")) {
    return false;
  }

  if (trimmed.toLowerCase().split(/\s+/).includes("deglaze")) {
    return false;
  }

  return trimmed.length >= minNameLength && trimmed.length <= maxLength;
}
