import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { TBody, THeading2, TSecondary, useFontFamilyMap } from "../components/Typography";
import {
  useCookingSessionRecipeTitle,
  useCookingSessionSourceRecipe,
  useCookingTimer,
  useCookingTimerIds,
  useHaveCookingSession,
  useInstructionTextById,
} from "../lib/cooking/CookingSessionsSelectors";
import { Spacer } from "../components/Spacer";
import { FlatList, StyleSheet, View } from "react-native";
import { CookingTimerId, focusInstructionRequested, RecipeCookingTimer } from "../lib/cooking/CookingSessionsSlice";
import { CookingTimerStatus, useCookingTimerStatus } from "../lib/cooking/CookingTimerTick";
import { useDispatch } from "../lib/redux/Redux";
import { Pressable } from "../components/Pressable";
import {
  acknowledgeAlertingTimers,
  changeTimerEndTime,
  deleteAllTimers,
  deleteTimer,
  pauseTimer,
  restartTimer,
} from "../lib/cooking/CookingTimerThunks";
import {
  IconChevronRight,
  IconCircleEx,
  IconMinus,
  IconPauseCircle,
  IconPlayCircle,
  IconPlus,
  IconStopCircle,
} from "../components/Icons";
import {
  bottomThrow,
  getFormattedDuration,
  getFormattedTime,
  splitOnSentences,
  switchReturn,
  tryParseBool,
} from "@eatbetter/common-shared";
import Animated, {
  cancelAnimation,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from "react-native-reanimated";
import { globalStyleColors, globalStyleConstants, Opacity } from "../components/GlobalStyles";
import { navTree, TimerScreenProps } from "../navigation/NavTree";
import { PhotoRef } from "@eatbetter/photos-shared";
import { useScreenHeaderDimensions } from "../components/ScreenHeaders";
import { useBottomTabBarDimensions } from "../navigation/TabBar";
import { Alert } from "../components/Alert/Alert";
import { Separator } from "../components/Separator";
import { CurrentEnvironment } from "../CurrentEnvironment";
import { Haptics } from "../components/Haptics";

const strings = {
  clearAll: {
    title: "Delete all timers?",
    clearButton: "Delete",
  },
  removeTimer: {
    title: "Delete timer?",
    remove: "Delete",
  },
  elapsed: "Elapsed",
  doneAt: ", done at ",
};

export const TimersScreen = withScreenContainer(
  "TimersScreen",
  (props: TimerScreenProps) => {
    const { bottomNotchHeight } = useBottomTabBarDimensions();
    const timerIds = useCookingTimerIds();
    const screen = useScreen();
    const dispatch = useDispatch();

    useEffect(() => {
      if (timerIds.length > 0) {
        return;
      }

      screen.nav.goBack();
    }, [timerIds, screen.nav]);

    useEffect(() => {
      // with the current logic, if this screen was already focused, and then a user taps a notification, we will not nav to the "new" screen
      // with the updated props. This means that tapping a notification while the screen is focused (but the app is in the background) will
      // not ackknowledge the timers. We can live with this for now.
      if (props.acknowledgeTimers) {
        dispatch(acknowledgeAlertingTimers());
      }
    }, [props.acknowledgeTimers]);

    useEffect(() => {
      return () => {
        // if a user dismisses the timer screen when a timer is alerting, treat it as acknowledgement
        dispatch(acknowledgeAlertingTimers());
      };
    }, []);

    const clearAllTimers = useCallback(() => {
      dispatch(deleteAllTimers());
    }, [dispatch]);

    const onPressClearAll = useCallback(() => {
      Alert.alert(strings.clearAll.title, "", [
        { type: "discard", text: strings.clearAll.clearButton, onPress: clearAllTimers },
        { type: "cancel", onPress: () => {} },
      ]);
    }, [clearAllTimers]);

    return (
      <ScreenView
        scrollView={false}
        paddingHorizontal={false}
        paddingVertical={false}
        keepAwake
        backgroundColor={globalStyleColors.colorGrey}
        header={{
          type: "native",
          title: "Timers",
          right: { type: "deleteAll", onPress: onPressClearAll },
        }}
      >
        <View style={[StyleSheet.absoluteFill, { bottom: bottomNotchHeight }]}>
          <TimerList timerIds={timerIds} />
        </View>
      </ScreenView>
    );
  },
  {
    serializer: {
      acknowledgeTimers: { optional: true, fn: s => (s ? "1" : "0") },
    },
    parser: {
      acknowledgeTimers: { optional: true, fn: s => tryParseBool(s) ?? false },
    },
    nameRemapping: {
      acknowledgeTimers: "ack",
    },
  }
);

const TimerList = React.memo((props: { timerIds: CookingTimerId[] }) => {
  const { modalHeaderHeight } = useScreenHeaderDimensions();

  const render = (props: { item: CookingTimerId; index: number }) => {
    return (
      <>
        {props.index !== 0 && <Spacer vertical={1.5} />}
        <TimerDetail timerId={props.item} />
      </>
    );
  };

  const keyExtractor = useCallback((item: CookingTimerId) => {
    return item;
  }, []);

  return (
    <FlatList
      data={props.timerIds}
      renderItem={render}
      keyExtractor={keyExtractor}
      contentContainerStyle={{ paddingTop: modalHeaderHeight }}
      showsVerticalScrollIndicator={false}
    />
  );
});

const TimerDetail = React.memo((props: { timerId: CookingTimerId }) => {
  const screen = useScreen();
  const dispatch = useDispatch();
  const timer = useCookingTimer(props.timerId);
  const status = useCookingTimerStatus(props.timerId);
  const haveSession = useHaveCookingSession(timer?.cookingSessionId);
  const recipeTitle = useCookingSessionRecipeTitle(timer?.cookingSessionId);
  const recipePhoto = useCookingSessionSourceRecipe(timer?.cookingSessionId)?.photo;
  const instructionText = useInstructionTextById(timer?.cookingSessionId, timer?.sectionId, timer?.instructionId);
  const instructionParts = useMemo(
    () => getInstructionFragments(instructionText, timer?.substringRange),
    [instructionText, timer?.substringRange]
  );

  const navToCookingSession = useCallback(
    (opts?: { disableHapticConfirmation: boolean }) => {
      if (!timer) {
        return;
      }

      if (!opts?.disableHapticConfirmation) {
        Haptics.feedback("tapControl");
      }

      dispatch(
        focusInstructionRequested({
          cookingSessionId: timer.cookingSessionId,
          sectionId: timer.sectionId,
          instructionId: timer.instructionId,
        })
      );

      screen.nav.switchTab("recipesTab", navTree.get.screens.recipeInKitchen);
    },
    [timer, screen.nav, dispatch]
  );

  const hasTimer = !!timer;

  const removeTimer = useCallback(() => {
    if (hasTimer) {
      // if we have the title, it means the cooking session exists, so nav there.
      if (haveSession) {
        // We already trigger the succeeded haptic so no need for another one that races with that one
        navToCookingSession({ disableHapticConfirmation: true });
      }

      Haptics.feedback("operationSucceeded");
      dispatch(deleteTimer(props.timerId));
    }
  }, [props.timerId, hasTimer, dispatch, haveSession, navToCookingSession]);

  const onPressRemoveTimer = useCallback(() => {
    // completely arbitrary line of when to show the prompt vs not. When a timer is close enough to done, it's value
    // is lower than it is earlier in its life, so don't make the user parse the prompt near end of timer life.
    // the main reason this exists is for when the user tries to delete the timer in the last few seconds. If we pop
    // an alert at that point, we can end up in a state where the alert is visible and the the timer is alerting, which
    // just doesn't seem right.
    if ((status?.percentComplete ?? 0) < 85) {
      Alert.alert(strings.removeTimer.title, "", [
        { type: "discard", text: strings.removeTimer.remove, onPress: removeTimer },
        { type: "cancel", onPress: () => {} },
      ]);
    } else {
      removeTimer();
    }
  }, [removeTimer, status?.percentComplete]);

  const pause = useCallback(() => {
    if (hasTimer && status?.lastEvaluatedTick) {
      Haptics.feedback("tapControl");
      dispatch(pauseTimer(props.timerId, status.lastEvaluatedTick));
    }
  }, [props.timerId, hasTimer, dispatch, status?.lastEvaluatedTick]);

  const restart = useCallback(() => {
    if (hasTimer && status?.lastEvaluatedTick) {
      Haptics.feedback("tapControl");
      dispatch(restartTimer(props.timerId, status.lastEvaluatedTick));
    }
  }, [props.timerId, hasTimer, dispatch, status?.lastEvaluatedTick]);

  const acknowledgeAlert = useCallback(() => {
    if (hasTimer) {
      Haptics.feedback("tapControl");
      dispatch(acknowledgeAlertingTimers());
    }
  }, [hasTimer, dispatch]);

  const addTime = useCallback(
    (seconds: number) => {
      if (!status || !hasTimer) {
        return;
      }

      Haptics.feedback("tapControl");
      dispatch(changeTimerEndTime(props.timerId, seconds, status.lastEvaluatedTick));
    },
    [status, hasTimer, dispatch, props.timerId]
  );

  if (!timer || !status) {
    return null;
  }

  const color = switchReturn(status.status, {
    running: globalStyleColors.colorAccentCool,
    paused: globalStyleColors.colorTimerInactive,
    complete: globalStyleColors.colorTimerAction,
  });

  return (
    <View>
      <View style={{ backgroundColor: "white" }}>
        {haveSession && (
          <>
            <Spacer vertical={1.5} />
            <RecipeSummary
              onPress={navToCookingSession}
              recipeTitle={recipeTitle ?? ""}
              recipePhoto={recipePhoto}
              instructionParts={instructionParts}
            />
          </>
        )}

        <Spacer vertical={1} />
        <View style={{ zIndex: 2, backgroundColor: "white" }}>
          <TimerDisplay
            timeRemainingDisplay={status.timeRemainingDisplay}
            alerting={status.alerting}
            color={color}
            cookingTimerStatus={status}
            acknowledgeAlert={acknowledgeAlert}
            pause={pause}
            restart={restart}
            removeTimer={onPressRemoveTimer}
            cookingTimer={timer}
          />
        </View>
        <Spacer vertical={2} />
        <AdjustTimePanel cookingTimer={timer} cookingTimerStatus={status} addTime={addTime} />
        <Spacer vertical={1.5} />
      </View>
    </View>
  );
});

const RecipeSummary = React.memo(
  (props: {
    onPress: () => void;
    recipeTitle: string;
    recipePhoto?: PhotoRef;
    instructionParts: [string, string, string];
  }) => {
    return (
      <Pressable onPress={props.onPress} style={{ paddingHorizontal: 20 }}>
        <View style={{ flexShrink: 1 }}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <View style={{ flexShrink: 1 }}>
              <THeading2 numberOfLines={2}>{props.recipeTitle}</THeading2>
            </View>
          </View>
          <Spacer vertical={1} />
          <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <View style={{ flexShrink: 1 }}>
              <TBody>
                {props.instructionParts[0]}
                <TBody fontWeight="medium">{props.instructionParts[1]}</TBody>
                {props.instructionParts[2]}
              </TBody>
            </View>
            <View style={{ alignItems: "center" }}>
              <IconChevronRight />
            </View>
          </View>
        </View>
        <Spacer vertical={1} />
        <Separator orientation="row" />
      </Pressable>
    );
  }
);

const TimerDisplay = React.memo(
  (props: {
    timeRemainingDisplay: string;
    alerting: boolean;
    color: string;
    cookingTimer: RecipeCookingTimer;
    cookingTimerStatus: CookingTimerStatus;
    pause: () => void;
    restart: () => void;
    acknowledgeAlert: () => void;
    removeTimer: () => void;
  }) => {
    const textOpacity = useSharedValue(1);
    const textAnimation = useAnimatedStyle(() => {
      return {
        opacity: textOpacity.value,
      };
    });

    const onPressTimerDisplay = useCallback(() => {
      switch (props.cookingTimerStatus.status) {
        case "running": {
          props.pause();
          return;
        }
        case "paused": {
          props.restart();
          return;
        }
        case "complete": {
          props.acknowledgeAlert();
          return;
        }
        default:
          bottomThrow(props.cookingTimerStatus.status);
      }
    }, [props.cookingTimerStatus.status, props.pause, props.restart, props.acknowledgeAlert]);

    useEffect(() => {
      if (props.alerting) {
        textOpacity.value = withRepeat(withTiming(0, { duration: 250 }), -1, true);
      } else {
        cancelAnimation(textOpacity);
        textOpacity.value = 1;
      }
    }, [props.alerting, textOpacity]);

    const timeRemainingDisplayColor = props.alerting ? globalStyleColors.colorTimerAction : props.color;
    const doneAtText =
      props.cookingTimerStatus.status === "running"
        ? `${strings.doneAt}${getFormattedTime(props.cookingTimer.endTime)}`
        : "";

    const fontFamily = useFontFamilyMap();

    return (
      <View>
        <View style={{ alignItems: "flex-start" }}>
          <Pressable
            onPress={props.acknowledgeAlert}
            noFeedback
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
              paddingHorizontal: 20,
            }}
          >
            <Pressable onPress={onPressTimerDisplay} style={{ flexShrink: 1, alignItems: "flex-start" }}>
              <Animated.Text
                adjustsFontSizeToFit
                allowFontScaling={false}
                numberOfLines={1}
                style={[
                  textAnimation,
                  { fontFamily: fontFamily.monospace, flex: 3, fontSize: 64, color: timeRemainingDisplayColor },
                ]}
              >
                {props.timeRemainingDisplay}
              </Animated.Text>
            </Pressable>
            <Spacer horizontal={1} />
            <View style={{ flexGrow: 1, flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
              <TimerControlButton
                cookingTimerStatus={props.cookingTimerStatus}
                pause={props.pause}
                restart={props.restart}
                acknowledgeAlert={props.acknowledgeAlert}
                removeTimer={props.removeTimer}
                color={timeRemainingDisplayColor}
              />
              <Spacer horizontal={1} />
              <DeleteButton
                removeTimer={props.removeTimer}
                highlight={props.cookingTimerStatus.status === "complete"}
              />
            </View>
          </Pressable>
          <View style={{ width: "100%", paddingHorizontal: 20 }}>
            <View style={{ flexDirection: "row", alignSelf: "flex-start", alignItems: "flex-start" }}>
              <Spacer horizontal={0.25} />
              <TBody color={globalStyleColors.colorTimerInactive}>
                <TBody>{strings.elapsed}</TBody>
                <TBody> </TBody>
                <TBody font="monospace">{props.cookingTimerStatus.timeElapsedDisplay}</TBody>
                <TBody>{doneAtText}</TBody>
              </TBody>
            </View>
          </View>
        </View>
      </View>
    );
  }
);

const TimerControlButton = React.memo(
  (props: {
    cookingTimerStatus: CookingTimerStatus;
    pause: () => void;
    restart: () => void;
    removeTimer: () => void;
    acknowledgeAlert: () => void;
    color: string;
  }) => {
    const status = props.cookingTimerStatus;
    const iconSize = 48;
    const color = props.color;

    return (
      <View>
        {status.status === "running" && (
          <Pressable onPress={props.pause}>
            <IconPauseCircle opacity="opaque" size={iconSize} color={color} />
          </Pressable>
        )}
        {status.status === "paused" && (
          <Pressable onPress={props.restart}>
            <IconPlayCircle opacity="opaque" size={iconSize} color={color} />
          </Pressable>
        )}
        {status.alerting && (
          <Pressable onPress={props.acknowledgeAlert}>
            <IconStopCircle opacity="opaque" size={iconSize} color={color} />
          </Pressable>
        )}
        {!status.alerting && status.status === "complete" && (
          // this is so the delete button doesn't shift when there is no control action available
          <IconStopCircle opacity="transparent" size={iconSize} />
        )}
      </View>
    );
  }
);

const DeleteButton = React.memo((props: { removeTimer: () => void; highlight: boolean }) => {
  const iconSize = 48;
  const opacity = props.highlight ? Opacity.opaque : Opacity.medium;
  return (
    <Pressable onPress={props.removeTimer} style={{ opacity }}>
      <IconCircleEx size={iconSize} color={globalStyleColors.colorAccentWarm} />
    </Pressable>
  );
});

const AdjustTimePanel = React.memo(
  (props: {
    cookingTimer: RecipeCookingTimer;
    cookingTimerStatus: CookingTimerStatus;
    addTime: (seconds: number) => void;
  }) => {
    const timer = props.cookingTimer;
    const status = props.cookingTimerStatus;

    const [subtractTime, setSubtractTime] = useState(false);
    const onTogglePlusMinus = useCallback(() => {
      setSubtractTime(prev => !prev);
    }, [setSubtractTime]);

    // when the timer is complete, only adding time makes sense
    useEffect(() => {
      if (status.status === "complete") {
        setSubtractTime(false);
      }
    }, [status.status]);

    return (
      <View style={styles.adjustTimePanel}>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <PlusMinusToggle value={subtractTime} onPress={onTogglePlusMinus} />
          {getTimeAdjustments(timer).map(seconds => {
            const addTime = subtractTime ? -seconds : seconds;
            const disabled = subtractTime && seconds > (status?.secondsRemaining ?? 0);
            return (
              <TimeButton
                isNegative={subtractTime}
                seconds={seconds}
                disabled={disabled}
                onPress={() => props.addTime(addTime)}
                key={seconds.toString()}
              />
            );
          })}
        </View>
      </View>
    );
  }
);

const PlusMinusToggle = React.memo((props: { value: boolean; onPress: () => void }) => {
  const size = 24;
  const containerPadding = size / 8;

  const animationProgress = useSharedValue(+props.value);

  useEffect(() => {
    animationProgress.value = withTiming(+props.value);
  }, [animationProgress, props.value]);

  const circleAnimation = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateX: size * animationProgress.value,
        },
      ],
    };
  });

  const plusOpacityAnimation = useAnimatedStyle(() => {
    return {
      opacity: interpolate(animationProgress.value, [0, 1], [Opacity.opaque, Opacity.light]),
    };
  });

  const minusOpacityAnimation = useAnimatedStyle(() => {
    return {
      opacity: interpolate(animationProgress.value, [0, 1], [Opacity.light, Opacity.opaque]),
    };
  });

  return (
    <Pressable
      noFeedback
      onPress={props.onPress}
      style={{
        height: size + 2 * containerPadding,
        width: 2 * (size + containerPadding),
        borderRadius: (size + 2 * containerPadding) / 2,
        padding: containerPadding,
        backgroundColor: "lightgrey",
        justifyContent: "center",
      }}
    >
      <View
        style={[
          StyleSheet.absoluteFill,
          {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            paddingHorizontal: containerPadding,
            zIndex: 2,
          },
        ]}
      >
        <Animated.View
          style={[{ height: size, width: size, alignItems: "center", justifyContent: "center" }, plusOpacityAnimation]}
        >
          <IconPlus size={size / 1.75} opacity="opaque" strokeWidth={2} />
        </Animated.View>
        <Animated.View
          style={[{ height: size, width: size, alignItems: "center", justifyContent: "center" }, minusOpacityAnimation]}
        >
          <IconMinus size={size / 1.75} opacity="opaque" strokeWidth={2} />
        </Animated.View>
      </View>
      <Animated.View
        style={[{ width: size, height: size, borderRadius: size / 2, backgroundColor: "white" }, circleAnimation]}
      />
    </Pressable>
  );
});

const TimeButton = React.memo(
  (props: { seconds: number; onPress: () => void; isNegative?: boolean; disabled?: boolean }) => {
    const duration = getFormattedDuration({ seconds: props.seconds }, false);
    return (
      <Pressable onPress={props.onPress} disabled={props.disabled} style={styles.timeButton}>
        <View style={{ flexShrink: 1 }}>
          <TSecondary numberOfLines={1} adjustsFontSizeToFit>{`${props.isNegative ? "-" : "+"}${duration}`}</TSecondary>
        </View>
      </Pressable>
    );
  }
);

function getTimeAdjustments(t: RecipeCookingTimer): number[] {
  if (t.minSeconds <= 1800) {
    // 30 minutes or less
    // for testing, it's nice to not have to wait 30 seconds
    const firstValue = CurrentEnvironment.debugBuild() ? 5 : 30;
    return [firstValue, 60, 300, 600];
  } else {
    return [60, 300, 600, 1800];
  }
}

// return the preceding text, the timer text, and the after text.
// preceding/after could be empty string.
function getInstructionFragments(
  instruction: string | undefined,
  range: [number, number] | undefined
): [string, string, string] {
  if (!instruction || !range) {
    return ["", "", ""];
  }

  const sentences = splitOnSentences(instruction);

  // default to the entire instruction if we can't find a relevant sentence
  const relevant = sentences.find(s => range[0] >= s.indices[0] && range[0] <= s.indices[1]) ?? {
    sentence: instruction,
    indices: [0, instruction.length - 1],
  };
  return [
    instruction.substring(relevant.indices[0], range[0]),
    instruction.substring(range[0], range[1] + 1),
    instruction.substring(range[1] + 1, relevant.indices[1] + 1),
  ];
}

const styles = StyleSheet.create({
  adjustTimePanel: {
    backgroundColor: "white",
    paddingHorizontal: 1.5 * globalStyleConstants.unitSize,
    borderBottomRightRadius: globalStyleConstants.unitSize,
    borderBottomLeftRadius: globalStyleConstants.unitSize,
  },
  timeButton: {
    flex: 1,
    height: 32,
    borderRadius: 32 / 2,
    marginLeft: 0.5 * globalStyleConstants.unitSize,
    borderWidth: 1,
    borderColor: globalStyleColors.rgba("black", "light"),
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: globalStyleColors.white,
  },
});
