import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { useCallback, useMemo, useState } from "react";
import { ProfileEditFieldScreenComponent } from "../components/social/ProfileEditFieldScreenComponent";
import { useUsernameAvailability, useUsernameInput } from "../lib/system/UserProfileHooks";
import { updateUserProfile } from "../lib/system/SystemThunks";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { useDispatch } from "../lib/redux/Redux";
import { bottomThrow, Username } from "@eatbetter/common-shared";
import { log } from "../Log";
import { useRegisteredUser } from "../lib/system/SystemSelectors";

const strings = {
  screenHeader: "Username",
  label: "Username",
  usernameInvalid:
    "Usernames should be 3 to 30 characters and can include a-z, 0-0 and dashes, underscores, and periods.",
  usernameUnavailable: "A user with that username already exists.",
};

export const ProfileEditUsernameScreen = withNonNavigableScreenContainer("ProfileEditUsernameScreen", () => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const persistedUsername = useRegisteredUser()?.username ?? "";
  const usernameInput = useUsernameInput(persistedUsername);

  const [waiting, setWaiting] = useState(false);

  const isDirty = useMemo(
    () => persistedUsername !== usernameInput.username,
    [persistedUsername, usernameInput.username]
  );

  const onSave = useCallback(async () => {
    if (!isDirty) {
      return;
    }

    setWaiting(true);
    try {
      const result = await dispatch(updateUserProfile({ username: usernameInput.username as Username }));
      setWaiting(false);

      switch (result) {
        case "success":
        case "bioInvalid":
        case "linksInvalid":
        case "nameInvalid":
          break;
        case "usernameInvalid":
          usernameInput.setUsernameIsInvalid(true);
          break;
        case "usernameUnavailable":
          // this case should be extremely unlikely and probably isn't worth handling
          log.warn("Received usernameUnavailable in onSubmit call in ProfileEditUsernameScreen");
          break;
        default:
          bottomThrow(result);
      }

      screen.nav.goBack();
    } catch (err) {
      displayUnexpectedErrorAndLog("Unexpected error in ProfileEditUsernameScreen.onSubmit", err);
      setWaiting(false);
      return;
    }
  }, [isDirty, dispatch, usernameInput.username, usernameInput.setUsernameIsInvalid, setWaiting, screen.nav.goBack]);

  const usernameAvailable = useUsernameAvailability(usernameInput.username ?? "");

  return (
    <>
      <ProfileEditFieldScreenComponent
        screenHeader={strings.screenHeader}
        label={strings.label}
        value={usernameInput.username}
        onChangeText={usernameInput.onChange}
        isDirty={isDirty}
        onSubmit={onSave}
        showClearButton={false}
        validationState={
          isDirty
            ? usernameInput.usernameIsInvalid || usernameAvailable === "invalid" || usernameAvailable === "unavailable"
              ? "invalid"
              : usernameAvailable === "pending"
              ? "pending"
              : usernameAvailable === "available"
              ? "valid"
              : undefined
            : undefined
        }
        errorMessage={
          usernameInput.usernameIsInvalid || usernameAvailable === "invalid"
            ? strings.usernameInvalid
            : usernameAvailable === "unavailable"
            ? strings.usernameUnavailable
            : undefined
        }
        waiting={waiting}
        isPostMetadata
      />
    </>
  );
});
