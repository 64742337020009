import { ApiClient } from "./lib/ApiClient";
import { LogLine } from "@eatbetter/common-shared";
import { throttle } from "lodash";

// NO LOGGING IN THIS FILE!!!

const maxToSend = 100;

const logLevels: { [key in LogLine["level"]]: number } = {
  info: 0,
  warn: 1,
  error: 2,
};

export class RemoteLogger {
  private logLines: LogLine[] = [];
  private truncatedCount = 0;
  private timeout: ReturnType<typeof setTimeout> | undefined;
  private currentlySending = false;
  private sendFailures = 0;

  // default to errors and warnings
  private sendLevel = logLevels.warn;

  constructor(private api: ApiClient<"throwOnError">) {}

  /**
   * Set the level to be sent for server logging. Passing undefined uses the default.
   */
  setSendLogLevel(sendLevel?: LogLine["level"]) {
    this.sendLevel = sendLevel ? logLevels[sendLevel] : logLevels.warn;
  }

  log = (l: LogLine, ignoreLevel: boolean) => {
    if (logLevels[l.level] < this.sendLevel && !ignoreLevel) {
      return;
    }

    if (this.logLines.length < maxToSend) {
      this.logLines.push(l);
    } else {
      this.truncatedCount++;
    }

    const r = this.send();
    if (r) {
      // swallow the error - we can't log here
      r.catch(() => {});
    }
  };

  private send = throttle(async () => {
    if (this.currentlySending) {
      return;
    }

    // it's possible that lines get added while we're awaiting the http call,
    // so keep track of what we're actually sending.
    // We'll reset things if the call fails.
    const lines = this.logLines;
    this.logLines = [];
    const truncatedCount = this.truncatedCount;
    this.truncatedCount = 0;

    try {
      this.currentlySending = true;

      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = undefined;
      }

      if (truncatedCount > 0) {
        lines.push({
          level: "info",
          message: `REMOTE LOGGER: Truncated ${this.truncatedCount} messages.`,
        });
      }

      if (lines.length > 0) {
        // send
        await this.api.sendLogs({ lines });
        this.sendFailures = 0;

        // in high latency situations (api call > throttle delay), it's possible that we have new lines
        // waiting and the call to send short circuited because currentlySending was true
        if (this.logLines.length > 0) {
          this.timeout = setTimeout(this.send, 0);
        }
      }
    } catch (err) {
      // eat it and try again with an exponential backoff
      this.sendFailures++;

      // re-add what we tried to send
      this.logLines = [...lines, ...this.logLines].slice(0, maxToSend);
      this.truncatedCount += truncatedCount;

      this.timeout = setTimeout(this.send, this.getTimeout());
    } finally {
      this.currentlySending = false;
    }
  }, 1000);

  private getTimeout() {
    // 2^6 = 64, so a max of a one minute delay
    const failures = Math.min(6, this.sendFailures);
    return Math.pow(2, failures) * 1000;
  }
}
