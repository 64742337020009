import { AppRecipeBase, RecipeId } from "@eatbetter/recipes-shared";
import { useDispatch } from "../redux/Redux";
import { useEffect, useState } from "react";
import { getRecipe } from "./RecipesThunks";
import { displayUnexpectedErrorAndLogHandler } from "../Errors";
import { useLibraryRecipe } from "./RecipesSelectors";

export function useFetchRecipe(recipeId?: RecipeId) {
  const dispatch = useDispatch();
  const libraryRecipe = useLibraryRecipe(recipeId);

  const [fetchedRecipe, setFetchedRecipe] = useState<AppRecipeBase>();

  useEffect(() => {
    if (recipeId && !libraryRecipe) {
      dispatch(getRecipe({ recipeId }))
        .then(r => {
          setFetchedRecipe(r);
        })
        .catch(displayUnexpectedErrorAndLogHandler("Error fetching recipe in useFetchRecipe", { recipeId }));
    }
  }, [recipeId, !!libraryRecipe]);

  return { fetchedRecipe, libraryRecipe };
}
