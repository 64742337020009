import { globalStyleConstants, TBody } from "@eatbetter/ui-shared";
import { PropsWithChildren } from "react";
import { Dimensions, View } from "react-native";

export const NvpTable = (
  props: PropsWithChildren<{
    name: string;
    value?: string | number | boolean;
    backgroundColor?: string;
    opacity?: number;
  }>
) => {
  const screenWidth = Dimensions.get("window").width;

  const value = props.children ?? (
    <View style={{ flexShrink: 1, maxWidth: 0.5 * screenWidth }}>
      <TBody opacity="medium">{props.value}</TBody>
    </View>
  );

  return (
    <>
      <tr
        style={{
          marginBottom: globalStyleConstants.unitSize * 0.5,
          backgroundColor: props.backgroundColor,
          opacity: props.opacity,
        }}
      >
        <td style={{ verticalAlign: "top", paddingRight: globalStyleConstants.unitSize }}>
          <TBody>{props.name}</TBody>
        </td>
        <td>{value}</td>
      </tr>
    </>
  );
};
