import { shuffle } from "lodash";

interface SocialDiscoverySource {
  type: "social";
  key: "instagram" | "tikTok" | "reddit";
  displayText: string;
}

interface UncategorizedDiscoverySource {
  type: "uncategorized";
  key: "friendsFamily" | "google" | "appleAppStore";
  displayText: string;
}

interface OtherDiscoverySource {
  type: "other";
  key: "other";
  displayText: string;
}

type AppDiscoverySource = SocialDiscoverySource | UncategorizedDiscoverySource | OtherDiscoverySource;

const appDiscoverySources: AppDiscoverySource[] = [
  { type: "social", key: "instagram", displayText: "Instagram" },
  { type: "social", key: "tikTok", displayText: "TikTok" },
  { type: "social", key: "reddit", displayText: "Reddit" },
  { type: "uncategorized", key: "appleAppStore", displayText: "Apple App Store" },
  { type: "uncategorized", key: "friendsFamily", displayText: "Friends & Family" },
  { type: "uncategorized", key: "google", displayText: "Google" },
  { type: "other", key: "other", displayText: "Other" },
];

/**
 * Returns a list of randomized app discovery sources used to render the app discovery survey in
 * new user onboarding. The function randomizes items within group types (e.g. "social"), and then
 * randomizes the group clusters as well. This strikes a balance between convenience (keeping like
 * items together) and eliminating position bias.
 */
export function getRandomizedAppDiscoverySources(): AppDiscoverySource[] {
  const socialOptions = appDiscoverySources.filter(i => i.type === "social");
  const otherOption = appDiscoverySources.find(i => i.type === "other");
  const remainingOptions = appDiscoverySources.filter(i => i.type === "uncategorized");

  const randomizedSocialOptions = shuffle(socialOptions);
  const randomizedRemainingOptions = shuffle(remainingOptions);

  const insertionIndex = Math.floor(Math.random() * (randomizedRemainingOptions.length + 1));

  const optionsWithSocial = [
    ...randomizedRemainingOptions.slice(0, insertionIndex),
    ...randomizedSocialOptions,
    ...randomizedRemainingOptions.slice(insertionIndex),
  ];

  const finalOptions = otherOption ? [...optionsWithSocial, otherOption] : optionsWithSocial;

  return finalOptions;
}

/**
 * App discovery survey data used to render the app discovery survey in user onboarding
 */
export interface AppDiscoverySourceInfo {
  randomizedSources: AppDiscoverySource[];
}
