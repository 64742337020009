import React from "react";
import { StyleSheet } from "react-native";
import { SocialPostRecipeCard } from "./social/SocialPost";
import { Pressable } from "./Pressable";
import { RecipeInfo } from "@eatbetter/recipes-shared";

export const ShareRecipeCard = React.memo((props: { recipeInfo: RecipeInfo; onPress: () => void }) => {
  return (
    <Pressable style={styles.screenPaddingHorizontalMin} onPress={props.onPress} noFeedback>
      <SocialPostRecipeCard recipeInfo={props.recipeInfo} />
    </Pressable>
  );
});

const styles = StyleSheet.create({
  screenPaddingHorizontalMin: {
    paddingHorizontal: 8,
  },
});
