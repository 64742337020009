import { usePushLastPrompted, usePushPermission, useSafeForNonUserDrivenNavigation } from "../system/SystemSelectors";
import { useEffect, useState } from "react";
import { navActionRequested } from "../system/SystemSlice";
import { useDispatch } from "../redux/Redux";
import { useScreen } from "../../navigation/ScreenContainer";
import {
  NotificationPermission,
  NotificationPermissionProps,
  notificationPermissionConstants,
} from "../../components/recipes/NotificationPermission";
import React from "react";
import { BottomSheetScreenProps } from "../../navigation/NavTree";

/**
 * Prompts the user to enable notifications if they haven't been prompted yet.
 * This might not be immediate, based on other app state (tapped notifications, etc.)
 * returns { willPrompt: boolean }. willPrompt will be true if the hook will eventually
 * result in a nav to the request notification screen.
 */
export const usePromptForNotifications = (): { willPrompt: boolean } => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const permission = usePushPermission();
  const safeToNavigate = useSafeForNonUserDrivenNavigation();
  const lastPrompted = !!usePushLastPrompted();
  const [delayDone, setDelayDone] = useState(false);

  // add a small delay so it feels less frenetic
  useEffect(() => {
    setTimeout(() => setDelayDone(true), 1500);
  }, []);

  const willPrompt = !!permission && !lastPrompted && !permission.userHasBeenPrompted && !permission.havePermission;

  useEffect(() => {
    // don't prompt if userHasBeenPrompted is undefined (should only be true right at app start) or if they have already taken action
    if (!willPrompt || !safeToNavigate || !screen.nav.focused || !delayDone) {
      return;
    }

    const props: NotificationPermissionProps = { context: "firstTime" };
    const bottomSheetProps: BottomSheetScreenProps = {
      content: React.createElement(NotificationPermission, props),
      height: notificationPermissionConstants.height,
      disableGestureDismissal: true,
    };

    dispatch(
      navActionRequested({
        screenName: "bottomSheet",
        props: bottomSheetProps,
      })
    );
  }, [safeToNavigate, screen.nav.focused, willPrompt, delayDone]);

  return { willPrompt };
};
