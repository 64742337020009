import React, { useCallback, useEffect } from "react";
import { Photo } from "../components/Photo";
import { ScreenView } from "../components/ScreenView";
import { ViewPhotoScreenProps } from "../navigation/NavTree";
import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { Gesture, GestureDetector } from "react-native-gesture-handler";
import Animated, { useSharedValue, useAnimatedStyle, withTiming, runOnJS, withDelay } from "react-native-reanimated";
import { PhotoRef } from "@eatbetter/photos-shared";
import { Haptics } from "../components/Haptics";
import { useResponsiveDimensions } from "../components/Responsive";

const config = {
  boundaryFriction: 0.4, // Lower means more friction
  maxPhotoScale: 4,
  swipeDownThreshold: 50,
};

export const ViewPhotoScreen = withNonNavigableScreenContainer("ViewPhotoScreen", (props: ViewPhotoScreenProps) => (
  <ViewPhotoScreenComponent photo={props.photo} />
));

interface Props {
  photo: PhotoRef | string;
}

const ViewPhotoScreenComponent = React.memo((props: Props) => {
  const screen = useScreen();

  const onPressDone = useCallback(() => {
    screen.nav.goBack();
  }, [screen]);

  // Fade image in on mount to avoid flicker while the image lays out full screen
  const imageOpacity = useSharedValue(0);
  useEffect(() => {
    imageOpacity.value = withDelay(250, withTiming(1));
  }, []);

  // Shared values for scale and translation
  const scale = useSharedValue(1);
  const savedScale = useSharedValue(1);
  const translateX = useSharedValue(0);
  const translateY = useSharedValue(0);
  const savedTranslateX = useSharedValue(0);
  const savedTranslateY = useSharedValue(0);

  const { width: windowWidth, height: windowHeight } = useResponsiveDimensions();

  function applyBoundaryFriction(value: number, lowerBound: number, upperBound: number) {
    "worklet";
    if (value < lowerBound) {
      return lowerBound + (value - lowerBound) * config.boundaryFriction;
    } else if (value > upperBound) {
      return upperBound + (value - upperBound) * config.boundaryFriction;
    }
    return value;
  }

  function computePanBoundaries(scaleValue: number) {
    "worklet";
    const scaledWidth = windowWidth * scaleValue;
    const scaledHeight = windowHeight * scaleValue;

    const boundX = (scaledWidth - windowWidth) / 2;
    const boundY = (scaledHeight - windowHeight) / 2;

    const maxTranslateX = boundX > 0 ? boundX : 0;
    const minTranslateX = -maxTranslateX;
    const maxTranslateY = boundY > 0 ? boundY : 0;
    const minTranslateY = -maxTranslateY;

    return {
      maxTranslateX,
      minTranslateX,
      maxTranslateY,
      minTranslateY,
    };
  }

  // Pinch gesture handler
  const pinchGesture = Gesture.Pinch()
    .onUpdate(event => {
      const newScale = savedScale.value * event.scale;
      scale.value = applyBoundaryFriction(newScale, 1, config.maxPhotoScale);
    })
    .onEnd(() => {
      // Animate scale back to boundaries if exceeded
      if (scale.value < 1 || scale.value > config.maxPhotoScale) {
        runOnJS(Haptics.feedback)("itemStatusChanged");
      }
      const newScale = Math.min(Math.max(scale.value, 1), config.maxPhotoScale);
      scale.value = withTiming(newScale);
      savedScale.value = newScale;
    });

  // Pan gesture handler
  const panGesture = Gesture.Pan()
    .onUpdate(event => {
      const { maxTranslateX, minTranslateX, maxTranslateY, minTranslateY } = computePanBoundaries(scale.value);

      translateX.value = applyBoundaryFriction(
        savedTranslateX.value + event.translationX,
        minTranslateX,
        maxTranslateX
      );
      translateY.value = applyBoundaryFriction(
        savedTranslateY.value + event.translationY,
        minTranslateY,
        maxTranslateY
      );
    })
    .onEnd(() => {
      if (scale.value === 1 && translateY.value > config.swipeDownThreshold) {
        // If swiped down past the threshold, close the screen
        runOnJS(onPressDone)();
      } else {
        const { maxTranslateX, minTranslateX, maxTranslateY, minTranslateY } = computePanBoundaries(scale.value);

        // Animate back to boundaries if necessary
        const newTranslateX = Math.min(Math.max(translateX.value, minTranslateX), maxTranslateX);
        translateX.value = withTiming(newTranslateX);
        savedTranslateX.value = newTranslateX;

        const newTranslateY = Math.min(Math.max(translateY.value, minTranslateY), maxTranslateY);
        translateY.value = withTiming(newTranslateY);
        savedTranslateY.value = newTranslateY;
      }
    });

  // Double-tap gesture handler for zooming in/out
  const doubleTapGesture = Gesture.Tap()
    .numberOfTaps(2)
    .onEnd(() => {
      if (scale.value > 1) {
        // If already zoomed, reset scale and translation
        scale.value = withTiming(1);
        savedScale.value = 1;
        translateX.value = withTiming(0);
        savedTranslateX.value = 0;
        translateY.value = withTiming(0);
        savedTranslateY.value = 0;
      } else {
        // Zoom in
        scale.value = withTiming(2);
        savedScale.value = 2;
      }
    });

  // Gesture priority
  const composedGesture = Gesture.Simultaneous(panGesture, pinchGesture);
  const gesture = Gesture.Exclusive(composedGesture, doubleTapGesture);

  const panAndZoomAnimation = useAnimatedStyle(() => ({
    transform: [{ translateX: translateX.value }, { translateY: translateY.value }, { scale: scale.value }],
  }));

  const fadeInAnimation = useAnimatedStyle(() => ({
    opacity: imageOpacity.value,
  }));

  return (
    <ScreenView
      header={{
        type: "native",
        title: "",
        backgroundColor: "black",
        tintColor: "white",
        right: { type: "done", onPress: onPressDone },
      }}
      scrollView={false}
      paddingHorizontal={false}
      paddingVertical={false}
    >
      <GestureDetector gesture={gesture}>
        <Animated.View style={{ flex: 1, backgroundColor: "black", justifyContent: "center" }}>
          {typeof props.photo === "string" ? (
            <Animated.Image
              source={{ uri: props.photo }}
              resizeMode="contain"
              style={[{ width: "100%", height: "100%" }, fadeInAnimation, panAndZoomAnimation]}
            />
          ) : (
            <Animated.View style={[{ flex: 1 }, fadeInAnimation, panAndZoomAnimation]}>
              <Photo resizeMode="contain" style="flexed" source={props.photo} sourceSize="w1290" noBorderRadius />
            </Animated.View>
          )}
        </Animated.View>
      </GestureDetector>
    </ScreenView>
  );
});
