import { getDurationInMilliseconds } from "@eatbetter/common-shared";
import { RecipeTagCategoryList } from "@eatbetter/recipes-shared/src/RecipeTagTypes";
import React, { useCallback, useState } from "react";
import {
  addTagToLibraryFilter,
  AppRecipeTag,
  RecipeTimeTag,
  removeTagFromLibraryFilter,
} from "../../lib/recipes/RecipesSlice";
import { useFilterableTags, useFilterTags, useTagManifest } from "../../lib/recipes/RecipeTagSelectors";
import { useDispatch } from "../../lib/redux/Redux";
import { useScreen } from "../../navigation/ScreenContainer";
import { Haptics } from "../Haptics";
import { RecipeTimeTagCategory, RecipeTagSelect, getTagDisplayLabel } from "./RecipeTagSelect";
import { SearchSessionId } from "../../lib/search/SearchSlice";
import { addSearchTagFilter, removeSearchTagFilter } from "../../lib/search/SearchThunks";

const totalTimeTags = [
  { type: "totalTime", tag: "30 min", totalTime: getDurationInMilliseconds({ minutes: 30 }) },
  { type: "totalTime", tag: "45 min", totalTime: getDurationInMilliseconds({ minutes: 45 }) },
  { type: "totalTime", tag: "1 hour", totalTime: getDurationInMilliseconds({ hours: 1 }) },
  { type: "totalTime", tag: "2 hours", totalTime: getDurationInMilliseconds({ hours: 2 }) },
  { type: "totalTime", tag: "4 hours", totalTime: getDurationInMilliseconds({ hours: 4 }) },
  { type: "totalTime", tag: "1 day", totalTime: getDurationInMilliseconds({ days: 1 }) },
] as const;

const totalTimeFilterCategory = {
  category: "Total Time",
  tags: totalTimeTags.slice(),
} satisfies RecipeTimeTagCategory;

export function getTotalTimeTag(time: (typeof totalTimeTags)[number]["tag"]): RecipeTimeTag {
  return totalTimeFilterCategory.tags.find(i => i.tag === time)!;
}

export const RecipeFilterControl = React.memo((props: { searchSessionId: SearchSessionId | undefined }) => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const availableFilters = useFilterableTags(props.searchSessionId);
  const activeFilters = useFilterTags(props.searchSessionId);
  const tagManifest = useTagManifest();

  const [waiting, setWaiting] = useState<Record<string, boolean>>({});

  const toggleTag = useCallback(
    (tag: AppRecipeTag) => {
      Haptics.feedback("itemStatusChanged");

      const selected = activeFilters.some(
        activeFilter => activeFilter.type === tag.type && activeFilter.tag === tag.tag
      );

      if (selected) {
        if (props.searchSessionId) {
          dispatch(removeSearchTagFilter({ tag, sessionId: props.searchSessionId }));
        } else {
          dispatch(removeTagFromLibraryFilter(tag));
        }

        return;
      }

      if (props.searchSessionId) {
        dispatch(addSearchTagFilter({ tag, sessionId: props.searchSessionId }));
      } else {
        dispatch(addTagToLibraryFilter(tag));
      }

      // If there's only one filter, close and go back and toggle since there's no other action
      // to take here
      if (availableFilters.length === 1) {
        screen.nav.goBack();
      }
    },
    [activeFilters, dispatch, setWaiting, screen.nav.goBack, availableFilters.length]
  );

  const renderTagLabel = useCallback(
    (tag: AppRecipeTag) => {
      return getTagDisplayLabel(tag, tagManifest);
    },
    [tagManifest]
  );

  const userTags: string[] = availableFilters.filter(i => i.type === "user").map(i => i.tag);
  const systemTags: RecipeTagCategoryList = tagManifest.categoryList.map(i => ({
    category: i.category,
    tags: i.tags.filter(j => availableFilters.some(k => k.type === "system" && k.tag === j)),
  }));
  const timeFilterTags: RecipeTimeTagCategory[] = [totalTimeFilterCategory];

  return (
    <RecipeTagSelect
      userTags={userTags}
      timeTags={timeFilterTags}
      systemTags={systemTags}
      selectedTags={activeFilters}
      renderTagLabel={renderTagLabel}
      onPressTag={toggleTag}
      waiting={waiting}
    />
  );
});
