import React from "react";
import { withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { ViewWebPageScreenComponent, ViewWebPageScreenComponentProps } from "../components/ViewWebPageScreenComponent";
import { ViewWebPageScreenProps } from "../navigation/NavTree";

export const ViewWebPageScreen = withNonNavigableScreenContainer(
  "ViewWebPageScreen",
  (props: ViewWebPageScreenProps) => {
    return React.createElement<ViewWebPageScreenComponentProps>(ViewWebPageScreenComponent, {
      screenHeader: props.screenHeader,
      url: props.url,
    });
  }
);
