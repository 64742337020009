export const configEnvironments = ["local", "dev", "prod"] as const;
export type ConfigEnvironment = "local" | "dev" | "prod";

export interface CurrentEnvironmentApi {
  isCI(): boolean;
  isTest(): boolean;
  configEnvironment(): ConfigEnvironment;
  debugBuild(): boolean;
  firebaseDynamicLinkDomain(): string;
  appIosBundleId(): string;
  linkBaseUrl(): string;
  gitSha(): string | undefined;
}

/**
 * The parts of the CurrentEnvironmentApi that vary based on platform
 */
export type PlatformDependentCurrentEnvironmentApi = Omit<
  CurrentEnvironmentApi,
  "gitSha" | "firebaseDynamicLinkDomain" | "appIosBundleId" | "isCI" | "isTest"
>;

function isCI() {
  return process.env.CI === "true";
}

function isTest() {
  return process.env["NODE_ENV"] === "test";
}

// This is a kludge. Native and web each have their own implementation of this.
// They are expected to set it before it's ever called.
// Should move to a more baked service registry pattern.
// It is nice that the call style matches common-server
export let CurrentEnvironment: CurrentEnvironmentApi = {
  isCI(): boolean {
    return isCI();
  },

  isTest(): boolean {
    return isTest();
  },

  configEnvironment(): ConfigEnvironment {
    // if we're running in a unit testing environment, set to local
    // this is a hack for tests since we haven't set impl yet
    if (CurrentEnvironment.isTest() || CurrentEnvironment.isCI()) {
      return "local";
    }

    throw new Error("setCurrentEnvironmentImpl not called yet (configEnvironment)");
  },
  debugBuild(): boolean {
    // this is a hack for tests since we haven't set impl yet
    if (CurrentEnvironment.isTest() || CurrentEnvironment.isCI()) {
      return true;
    }

    throw new Error("setCurrentEnvironmentImpl not called yet (debugBuild)");
  },
  linkBaseUrl(): string {
    throw new Error("setCurrentEnvironmentImpl not called yet (linkBaseUrl)");
  },
  firebaseDynamicLinkDomain(): string {
    throw new Error("setCurrentEnvironmentImpl not called yet (firebaseDynamicLinkDomain)");
  },
  appIosBundleId(): string {
    throw new Error("setCurrentEnvironmengImpl not called yet (appIosBundleId)");
  },
  gitSha,
};

export function setCurrentEnvironmentImpl(impl: PlatformDependentCurrentEnvironmentApi) {
  CurrentEnvironment = {
    ...impl,
    isTest(): boolean {
      return isTest();
    },
    isCI(): boolean {
      return isCI();
    },
    firebaseDynamicLinkDomain(): string {
      return this.configEnvironment() === "prod" ? "web.deglaze.app" : "web.mooklab-dev.link";
    },
    appIosBundleId(): string {
      return this.configEnvironment() === "prod" ? "app.deglaze.prod" : "app.deglaze.dev";
    },
    gitSha,
  };
}

function gitSha(): string | undefined {
  return process.env.gitSha;
}
