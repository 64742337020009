import { NavApi } from "../../navigation/ScreenContainer";
import {
  useAppMetadata,
  useAuthedUser,
  useIsAnonymousUser,
  useNumericCheckpointValue,
  useSafeForNonUserDrivenNavigation,
} from "../system/SystemSelectors";
import { useRecipeIds } from "../recipes/RecipesSelectors";
import { daysBetween, defaultTimeProvider, EpochMs, minutesBetween } from "@eatbetter/common-shared";
import { AnonymousRegistrationScreenProps, navTree } from "../../navigation/NavTree";
import { useEffect } from "react";
import { CurrentEnvironment } from "../../CurrentEnvironment";

export const useAnonymousRegistrationPrompt = (nav: NavApi) => {
  const isAnonymousUser = useIsAnonymousUser();
  const attempts = useNumericCheckpointValue("anonymousRegistrationPromptCount");
  const lastShown = useNumericCheckpointValue("anonymousRegistrationPromptLastShown");
  const recipeCount = useRecipeIds().length;
  const thresholds = useAppMetadata()?.anonymousThresholds;
  const user = useAuthedUser();
  const safeToNav = useSafeForNonUserDrivenNavigation();

  useEffect(() => {
    if (!isAnonymousUser || !thresholds || !user || !safeToNav) {
      return;
    }

    const fn = CurrentEnvironment.configEnvironment() === "prod" ? daysBetween : minutesBetween;
    const sinceLastShown = fn((lastShown ?? 0) as EpochMs, defaultTimeProvider());
    const isRequired = (attempts ?? 0) > thresholds.promptCountBeforeRequired;
    const canShownBasedOnTime = isRequired || sinceLastShown > thresholds.minDaysBetweenPrompts;

    if (!canShownBasedOnTime) {
      return;
    }

    if (
      recipeCount >= thresholds.recipeCount ||
      (thresholds.days && user.created && daysBetween(defaultTimeProvider(), user.created) > thresholds.days)
    ) {
      const props: AnonymousRegistrationScreenProps = isRequired ? { mode: "required" } : { mode: "suggested" };
      nav.modal(navTree.get.screens.anonymousRegistration, props);
    }
  }, [isAnonymousUser, attempts, lastShown, recipeCount, thresholds, safeToNav]);
};
