import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type Theme = "dark" | "light";

export interface AdminSystemState {
  theme: Theme;
}

const initialState: AdminSystemState = {
  theme: "light",
};

const adminSystemSlice = createSlice({
  name: "system",
  initialState,

  reducers: create => ({
    setTheme: create.reducer((state, action: PayloadAction<Theme>) => {
      state.theme = action.payload;
    }),
  }),
});

export const { setTheme } = adminSystemSlice.actions;
export const adminSystemReducer = adminSystemSlice.reducer;
