import { View } from "react-native";
import { ScreenView } from "../../components/ScreenView";
import { withScreenContainer } from "../../navigation/ScreenContainer";
import { Spacer } from "../../components/Spacer";
import { TBody, TSecondary } from "../../components/Typography";
import { useMemo } from "react";
import { useScreenTimerWithScreenViewEvent } from "../../lib/util/UseScreenTimer";
import { VideoSource } from "../../components/Video";
import { getVideoUrl } from "../../components/Photo";
import { VideoWithPhoneBezel } from "../../components/VideoWithPhoneBezel";

const strings = {
  headline: "Save Recipes from Books",
  firstParagraph: [
    "Got a shelf full of amazing cookbooks that never get used? Snap a photo of that recipe you've been wanting to try to import it to your Deglaze recipe library.",
  ],
  secondParagraph: [
    'From your recipe library, tap the + button, then select "Add from recipe photos". Open the camera to snap a photo of your recipe, or select photos from your photo library. When you\'re ready, hit the "Add Recipe" button at the bottom of the screen.',
  ],
} as const;

export const HowToAddBookRecipesScreen = withScreenContainer("HowToAddBookRecipeScreen", () => {
  useScreenTimerWithScreenViewEvent();

  return (
    <ScreenView
      header={{
        type: "default",
        title: () => <TBody fontWeight="medium">{strings.headline}</TBody>,
        backgroundColor: "white",
      }}
    >
      <View>
        <TSecondary>
          <TSecondary>{strings.firstParagraph[0]}</TSecondary>
        </TSecondary>
        <Spacer vertical={1} />
        <TSecondary>
          <TSecondary>{strings.secondParagraph[0]}</TSecondary>
        </TSecondary>
      </View>
      <Spacer vertical={1} />
      <View style={{ height: 600 }}>
        <HowToVideo videoId="add_recipe_photos" />
      </View>
    </ScreenView>
  );
});

const HowToVideo = (props: { videoId: "add_recipe_photos" }) => {
  const videoSource = useMemo<VideoSource>(() => {
    return {
      type: "remote",
      url: getVideoUrl(`videos/${props.videoId}.mp4`),
    };
  }, [props.videoId]);

  return (
    <View style={{ height: 500, alignItems: "center" }}>
      <VideoWithPhoneBezel videoAnalyticsId={props.videoId} videoSource={videoSource} tapToExpand />
    </View>
  );
};
