import React, { useCallback } from "react";
import { UserRecipeId } from "@eatbetter/recipes-shared";
import { StyleSheet, View, ViewProps } from "react-native";
import { globalStyleConstants, globalStyles } from "../GlobalStyles";
import { IconChevronRight } from "../Icons";
import { Spacer } from "../Spacer";
import { useScreen } from "../../navigation/ScreenContainer";
import { navTree, RecipeEditFieldLocation } from "../../navigation/NavTree";
import { Pressable } from "../Pressable";
import { useRecipeTags, useTagManifest } from "../../lib/recipes/RecipeTagSelectors";
import { RecipeTag } from "@eatbetter/recipes-shared/src/RecipeTagTypes";
import { SelectableOval } from "../SelectableOval";
import { getTagDisplayLabel } from "./RecipeTagSelect";
import { TSecondary } from "../Typography";

const strings = {
  tags: "Tags",
  addNew: "Add tags",
  noTags: "No tags.",
};

interface Props {
  recipeId: UserRecipeId;
  style?: "default" | "compact";
  disabled?: boolean;
  location: RecipeEditFieldLocation;
  noShadow?: boolean;
}

export const RecipeTags = (props: Props) => {
  const screen = useScreen();
  const tags = useRecipeTags(props.recipeId);

  const onPress = useCallback(() => {
    screen.nav.goTo("push", navTree.get.screens.recipeTagsEdit, {
      recipeId: props.recipeId,
      recipeEditFieldLocation: props.location,
    });
  }, [screen.nav.goTo, props.location, props.recipeId]);

  return (
    <Pressable
      style={[styles.container, props.noShadow ? {} : globalStyles.shadowItem]}
      onPress={onPress}
      noFeedback
      disabled={props.disabled}
    >
      <View style={styles.heading}>
        <View>
          <TSecondary>{strings.tags}</TSecondary>
        </View>
        <View style={styles.headingContent}>
          {props.style === "compact" && (
            <>
              {tags.length === 0 && (
                <>
                  <TSecondary opacity="light">{strings.addNew}</TSecondary>
                  <Spacer horizontal={1} />
                </>
              )}
              {tags.length > 0 && (
                <>
                  <Spacer horizontal={2} />
                  <View style={{ flexShrink: 1 }}>
                    <TagsCompactDisplay tags={tags} />
                  </View>
                  <Spacer horizontal={1} />
                </>
              )}
            </>
          )}
          <IconChevronRight />
        </View>
      </View>
      {props.style !== "compact" && (
        <>
          <Spacer vertical={1} />
          <View style={{ marginRight: 1.5 * globalStyleConstants.unitSize }}>
            {tags.length === 0 && <TSecondary opacity="medium">{strings.noTags}</TSecondary>}
            {tags.length > 0 && <TagsPreview tags={tags} />}
          </View>
        </>
      )}
    </Pressable>
  );
};

const TagsCompactDisplay = React.memo((props: { tags: RecipeTag[] }) => {
  return <TagsDisplayText tags={props.tags} numberOfLines={1} />;
});

const TagsDisplayText = React.memo((props: { tags: RecipeTag[]; numberOfLines?: number }) => {
  const previewString = useRecipeTagsPreview(props.tags);

  return (
    <TSecondary numberOfLines={props.numberOfLines} opacity="medium">
      {previewString}
    </TSecondary>
  );
});

export const TagsPreview = React.memo((props: { tags: RecipeTag[] }) => {
  if (props.tags.length === 0) {
    return null;
  }

  return (
    <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
      {props.tags.map(i => {
        return (
          <TagPreview
            key={i.tag}
            tag={i}
            contentContainerStyle={{
              marginRight: 0.5 * globalStyleConstants.unitSize,
              marginBottom: 0.5 * globalStyleConstants.unitSize,
            }}
          />
        );
      })}
    </View>
  );
});

const TagPreview = React.memo((props: { tag: RecipeTag; contentContainerStyle: ViewProps["style"] }) => {
  const tagManifest = useTagManifest();

  return (
    <View
      style={{
        marginRight: 0.5 * globalStyleConstants.unitSize,
        marginBottom: 0.5 * globalStyleConstants.unitSize,
      }}
    >
      <SelectableOval text={getTagDisplayLabel(props.tag, tagManifest)} isSelected disabled onPress={() => {}} />
    </View>
  );
});

export function useRecipeTagsPreview(tags: RecipeTag[]): string {
  const tagManifest = useTagManifest();
  return tags.map(i => getTagDisplayLabel(i, tagManifest)).join(", ");
}

const styles = StyleSheet.create({
  container: {
    padding: globalStyleConstants.unitSize,
    backgroundColor: "white",
    borderRadius: 20,
  },
  heading: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: 32,
  },
  headingContent: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
});
