import { GlobalFeedKey, ModeratedSocialPost } from "@eatbetter/posts-shared";
import { UserId } from "@eatbetter/common-shared";
import { useCallback, useRef } from "react";
import { useDispatch } from "../AdminRedux";
import { getSocialFeed, moderateSocialPost } from "./AdminSocialThunks";
import { SetWaitingHandler } from "@eatbetter/ui-shared";
import { usePagedData } from "@eatbetter/ui-shared/src/lib/UsePagedData";

export interface ModeratedSocialPostWithFn extends ModeratedSocialPost {
  approve?: (approved: boolean, setWaiting?: SetWaitingHandler) => Promise<void>;
}

export const useAdminSocialFeed = (feed: UserId | GlobalFeedKey) => {
  const dispatch = useDispatch();
  const setPosts = useRef<(fn: (existing: ModeratedSocialPost[]) => ModeratedSocialPost[]) => void>();

  const addHandler = useCallback(
    (post: ModeratedSocialPost) => {
      if (feed !== "all" && feed !== "curated") {
        return post;
      }

      const approve = async (approved: boolean, setWaiting?: SetWaitingHandler) => {
        const result = await dispatch(
          moderateSocialPost(
            {
              approved,
              postId: post.post.id,
              postCreationTime: post.post.created,
              sourceFeed: "all",
              destinationFeed: "curated",
            },
            setWaiting
          )
        );

        setPosts.current?.(existing => {
          const indexOf = existing.findIndex(i => i.post.id === result.post.id);
          if (indexOf < 0) {
            return existing;
          }

          const r = [...existing];
          r[indexOf] = addHandler(result);
          return r;
        });
      };

      return {
        ...post,
        approve,
      };
    },
    [feed, setPosts, dispatch]
  );

  const fetch = useCallback(
    async (start?: string) => {
      const nextPage = await dispatch(
        getSocialFeed({
          feed,
          start,
        })
      );

      const newPosts = nextPage.posts.map<ModeratedSocialPostWithFn>(post => addHandler(post));
      return { items: newPosts, next: nextPage.next };
    },
    [dispatch, addHandler, feed]
  );

  const hook = usePagedData(fetch);
  setPosts.current = hook.modifyItems;
  return hook;
};
