import { ThunkAction } from "./redux/Redux";
import { AuthedUser, HouseholdInviteInfo, HouseholdInviteLink } from "@eatbetter/users-shared";
import { getAuthedUser } from "./system/SystemThunks";
import { sleep, UrlString, UserId } from "@eatbetter/common-shared";
import { log } from "../Log";
import { loadGroceryLists } from "./lists/ListsThunks";
import { SetWaitingHandler } from "./Types";
import { reportJoinHouseholdLinkCreated } from "./analytics/AnalyticsEvents";
import { analyticsEvent } from "./analytics/AnalyticsThunks";
import { Share } from "../components/Share";
import { navToAnonymousSigninIfAnon } from "./util/AnonymousSignIn";
import { NavApi } from "../navigation/ScreenContainer";

const strings = {
  share: "Join me on Deglaze by following this link",
  inviteAction: "add someone to your household",
};

export const shareHouseholdInviteLink = (nav: NavApi, setWaiting?: SetWaitingHandler): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: shareHouseholdInviteLink");
    try {
      if (navToAnonymousSigninIfAnon(getState(), nav, strings.inviteAction)) {
        return;
      }

      setWaiting?.(true);
      const resp = await deps.api.withThrow().getHouseholdInviteLink();

      const u = new URL(`${deps.env.linkBaseUrl()}${resp.data.path}`);
      Object.entries(resp.data.qs).forEach(p => {
        u.searchParams.append(p[0], p[1]);
      });

      const event = reportJoinHouseholdLinkCreated();
      dispatch(analyticsEvent(event));

      await Share.share({ message: strings.share, url: u.toString() as UrlString });
    } catch (err) {
      log.errorCaught("Unexpected error in shareHouseholdInviteLink", err);
    } finally {
      setWaiting?.(false);
    }
  };
};

export const getHouseholdInviteInfo = (
  link: HouseholdInviteLink,
  setWaiting?: SetWaitingHandler
): ThunkAction<HouseholdInviteInfo> => {
  return async (_dispatch, getState, deps) => {
    const state = getState();
    if (state.system.authStatus === "signedIn" && state.system.authedUser.data?.isRegistered) {
      const resp = await deps.api.withThrow(setWaiting).getHouseholdLinkInfoAuthed(link);
      return resp.data;
    }

    const resp = await deps.api.withThrow(setWaiting).getHouseholdLinkInfoNoAuth(link);
    return resp.data;
  };
};

export const completeHouseholdLink = (
  link: HouseholdInviteLink,
  newHouseholdUserId: UserId,
  setWaiting?: SetWaitingHandler
): ThunkAction<void> => {
  return async (dispatch, _state, deps) => {
    try {
      setWaiting?.(true);
      await deps.api.withThrow().completeHouseholdLink(link);
      await dispatch(getNewHouseholdUserBestEffort(newHouseholdUserId));
      await dispatch(loadGroceryLists());
    } finally {
      setWaiting?.(false);
    }
  };
};

/**
 * Attempt to refresh the authed user to pull down a new household member.
 * Will not throw
 */
export const getNewHouseholdUserBestEffort = (newHouseholdUserId: UserId): ThunkAction<void> => {
  return async (dispatch, getState, _deps) => {
    const haveUser = (d: AuthedUser | undefined) => !!d?.household.some(u => u.userId === newHouseholdUserId);

    if (haveUser(getState().system.authedUser.data)) {
      return;
    }

    try {
      for (let i = 0; i < 3; i++) {
        // the downside of eventually consistent GSIs in DynamoDB
        // This logic should be moved to the server (we can pass a flag for new user expected)
        if (i > 0) {
          log.info("New household user was not returned after completing link. Retrying.");
          await sleep(500);
        }

        const user = await dispatch(getAuthedUser({ throwOnError: false }));

        if (haveUser(user.data)) {
          return;
        }
      }
    } catch (err) {
      log.errorCaught("Unexpected error in getNewHouseholdUser", err);
    }
  };
};
