import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { log } from "../../Log";
import { TypedPrimitive, UrlString } from "@eatbetter/common-shared";

export type WebViewSessionId = TypedPrimitive<string, "webViewSessionId">;

export interface WebViewSessionState {
  initialUrl: UrlString;
  currentUrl: UrlString;
  canGoBack: boolean;
  canGoForward: boolean;
  loadingProgress: number;
  loadingFailed: boolean;

  // used to nav the webview to a specific URL
  requestedUrl?: UrlString;
}

export interface WebViewState {
  sessions: Record<string, WebViewSessionState>;
}

const initialState: WebViewState = {
  sessions: {},
};

export const selectWebViewSession = (
  state: WebViewState,
  sessionId: WebViewSessionId
): WebViewSessionState | undefined => {
  return state.sessions[sessionId];
};

const webViewSlice = createSlice({
  name: "webView",
  initialState,

  reducers: create => ({
    addWebViewSession: create.reducer(
      (state, action: PayloadAction<{ sessionId: WebViewSessionId; url: UrlString }>) => {
        const { sessionId, url } = action.payload;
        if (state.sessions.hasOwnProperty(sessionId)) {
          log.error(`Not adding web view session with id ${sessionId} because session already exists`);
          return;
        }

        state.sessions[sessionId] = {
          initialUrl: url,
          currentUrl: url,
          canGoBack: false,
          canGoForward: false,
          loadingFailed: false,
          loadingProgress: 0,
        };
      }
    ),

    removeWebViewSession: create.reducer((state, action: PayloadAction<{ sessionId: WebViewSessionId }>) => {
      const { sessionId } = action.payload;
      if (!state.sessions.hasOwnProperty(sessionId)) {
        log.error(`Can't remove session with id ${sessionId} because session does not exist`);
        return;
      }
      delete state.sessions[sessionId];
    }),

    webViewNavigationStateChanged: create.reducer(
      (
        state,
        action: PayloadAction<{
          sessionId: WebViewSessionId;
          url: UrlString;
          canGoBack: boolean;
          canGoForward: boolean;
        }>
      ) => {
        const { sessionId, url, canGoBack, canGoForward } = action.payload;
        const session = state.sessions[sessionId];

        if (!session) {
          log.error(`Can't update session with id ${sessionId} because session does not exist`);
          return;
        }

        session.currentUrl = url;
        session.canGoBack = canGoBack;
        session.canGoForward = canGoForward;
      }
    ),

    webViewLoadingProgressUpdated: create.reducer(
      (state, action: PayloadAction<{ sessionId: WebViewSessionId; loadingProgress: number }>) => {
        const { sessionId, loadingProgress } = action.payload;
        const session = state.sessions[sessionId];

        if (!session) {
          log.error(`Can't update session with id ${sessionId} because session does not exist`);
          return;
        }

        session.loadingProgress = loadingProgress;
      }
    ),

    webViewErrored: create.reducer((state, action: PayloadAction<{ sessionId: WebViewSessionId }>) => {
      const { sessionId } = action.payload;
      const session = state.sessions[sessionId];

      if (!session) {
        log.error(`Can't update session with id ${sessionId} because session does not exist`);
        return;
      }

      session.loadingFailed = true;
    }),
    /**
     * Used to navigate the webview. Note that this is not cleared after navigation, and that is by design.
     * There is no benefit to clearing it. See the logic in WebView.tsx to better understand why.
     */
    webViewNavRequested: create.reducer(
      (state, action: PayloadAction<{ sessionId: WebViewSessionId; url: UrlString }>) => {
        const { sessionId, url } = action.payload;
        const session = state.sessions[sessionId];

        if (session) {
          session.requestedUrl = url;
        }
      }
    ),
  }),
});

export const {
  addWebViewSession,
  removeWebViewSession,
  webViewNavigationStateChanged,
  webViewLoadingProgressUpdated,
  webViewErrored,
  webViewNavRequested,
} = webViewSlice.actions;

export const webViewReducer = webViewSlice.reducer;
