import { View } from "react-native";
import { TSecondary } from "./Typography";
import { Spacer } from "./Spacer";
import React from "react";
import { globalStyleColors } from "./GlobalStyles";

interface Props {
  message: string;
}

export const FormValidationError = React.memo((props: Props) => {
  return (
    <View>
      <Spacer vertical={0.5} />
      <TSecondary color={globalStyleColors.colorAccentWarm}>
        <TSecondary fontWeight="medium">{"(!)"}</TSecondary>
        <TSecondary> </TSecondary>
        <TSecondary fontWeight="medium">{props.message}</TSecondary>
      </TSecondary>
    </View>
  );
});
