export function filterOutFalsy<T>(a: Array<T | undefined | null>): Array<T> {
  return a.filter(i => !!i) as Array<T>;
}

export function dedup<T>(items: T[], idFn: (item: T) => string): T[] {
  const deduped: T[] = [];
  const seen = new Set<string>();

  items.forEach(i => {
    const id = idFn(i);
    if (!seen.has(id)) {
      seen.add(id);
      deduped.push(i);
    }
  });

  return deduped;
}
