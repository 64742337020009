import { switchReturn, UserId } from "@eatbetter/common-shared";
import { useQueryStringParam, useStringParam } from "../lib/ParamUtils";
import { AdminScreenView } from "../components/AdminScreenView";
import {
  displayUnexpectedErrorAndLog,
  FlexedSpinner,
  globalStyleColors,
  Photo,
  Spacer,
  TBody,
  THeading1,
} from "@eatbetter/ui-shared";
import { Link } from "react-router-dom";
import { AdminUsersScreenIdNav } from "./AdminUsersScreen";
import { useEffect, useState } from "react";
import { useDispatch } from "../lib/AdminRedux";
import {
  AdminUserSocialOverview,
  getSocialEntityId,
  isDeglazeUser,
  isKnownAuthor,
  isKnownPublisher,
  isUserId,
  NextFollowersStart,
  NextPostsStart,
  RecommendedFollowInfo,
  SocialEntity,
  SocialEntityId,
  SocialPost,
} from "@eatbetter/posts-shared";
import {
  getFollowers,
  getFollowing,
  getUserProfilePosts,
  getUserSocialOverview,
} from "../lib/social/AdminSocialThunks";
import { View } from "react-native";
import { ModeratedSocialPostWithFn } from "../lib/social/UseAdminSocialFeed";
import { AdminSocialFeed, ModeratedPost } from "../components/AdminSocialFeed";
import { AdminPagedDataList } from "../components/AdminPagedDataList";

const userIdParam = ":userId";
const selectedParam = "selected";

export type UserSocialScreenSelected =
  | "profileFeed"
  | "followingFeed"
  | "following"
  | "followers"
  | "recommendedFollows";

export const AdminUserSocialScreenNav = {
  getPath: (userId?: UserId, selected?: UserSocialScreenSelected) => {
    return `/users/${userId ?? userIdParam}/social${selected ? `?${selectedParam}=${selected}` : ""}`;
  },
};

export const AdminUserSocialScreen = () => {
  const userId = useStringParam<UserId>(userIdParam).required();
  const selected = useQueryStringParam<UserSocialScreenSelected>(selectedParam).optional() ?? "profileFeed";
  const [data, setData] = useState<AdminUserSocialOverview | undefined>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserSocialOverview({ userId }))
      .then(r => {
        setData(r);
      })
      .catch(err => {
        displayUnexpectedErrorAndLog("Error dispatching getUserSocialOverview on AdminUserSocialScreen", err);
      });
  }, [userId]);

  if (!data) {
    return <FlexedSpinner />;
  }

  return (
    <AdminScreenView>
      <THeading1>
        Social Overview for user{" "}
        <Link to={AdminUsersScreenIdNav.getPath(userId)}>{`@${data.user.username} ${data.user.userId}`}</Link>
      </THeading1>
      <Spacer vertical={2} />
      <DataSelector data={data} selected={selected} />
    </AdminScreenView>
  );
};

const DataSelector = (props: { data: AdminUserSocialOverview; selected: UserSocialScreenSelected }) => {
  const getText = (s: UserSocialScreenSelected) => {
    const copy = switchReturn(s, {
      profileFeed: "Profile Posts",
      followingFeed: "Following Feed",
      followers: `Followers (${props.data.followersCount})`,
      following: `Following (${props.data.followingCount})`,
      recommendedFollows: `Recommended User Follows (${props.data.recommendedFollows.entities.length})`,
    });

    if (s === props.selected) {
      return <TBody fontWeight="medium">{copy}</TBody>;
    } else {
      return (
        <Link to={AdminUserSocialScreenNav.getPath(props.data.user.userId, s)}>
          <TBody color={globalStyleColors.colorTextLink}>{copy}</TBody>
        </Link>
      );
    }
  };

  return (
    <View>
      <View style={{ flexDirection: "row" }}>
        {getText("profileFeed")}
        <Spacer horizontal={1} />
        {getText("followingFeed")}
        <Spacer horizontal={1} />
        {getText("followers")}
        <Spacer horizontal={1} />
        {getText("following")}
        <Spacer horizontal={1} />
        {getText("recommendedFollows")}
      </View>
      <Spacer vertical={2} />
      {props.selected === "profileFeed" && <ProfileFeed userId={props.data.user.userId} />}
      {props.selected === "followingFeed" && <AdminSocialFeed feed={props.data.user.userId} />}
      {props.selected === "followers" && <Followers entityId={props.data.user.userId} type="followers" />}
      {props.selected === "following" && <Followers entityId={props.data.user.userId} type="following" />}
      {props.selected === "recommendedFollows" && <RecommendedFollows data={props.data.recommendedFollows} />}
    </View>
  );
};

const ProfileFeed = (props: { userId: UserId }) => {
  const dispatch = useDispatch();

  const fetch = async (next?: NextPostsStart) => {
    const res = await dispatch(
      getUserProfilePosts({
        userId: props.userId,
        start: next,
        count: 10,
      })
    );

    return { items: res.posts, next: res.next };
  };

  const render = (item: SocialPost) => {
    // convert it to a moderated post, but we know it's never moderated
    const mp: ModeratedSocialPostWithFn = {
      post: item,
    };

    return <ModeratedPost post={mp} key={item.id} />;
  };

  return <AdminPagedDataList fetch={fetch} render={render} />;
};

const Followers = (props: { entityId: SocialEntityId; type: "followers" | "following" }) => {
  const dispatch = useDispatch();

  const fetch = async (
    next?: NextFollowersStart
  ): Promise<{ items: SocialEntity[]; next: NextFollowersStart | undefined }> => {
    if (props.type === "followers") {
      const res = await dispatch(
        getFollowers({
          type: "followers",
          entityId: props.entityId,
          count: 30,
          start: next,
        })
      );

      return { items: res.users, next: res.next };
    } else {
      if (!isUserId(props.entityId)) {
        return { items: [], next: undefined };
      }

      const res = await dispatch(
        getFollowing({
          type: "following",
          userId: props.entityId,
          count: 30,
          start: next,
        })
      );

      return { items: res.entities, next: res.next };
    }
  };

  const render = (item: SocialEntity) => {
    return (
      <View style={{ flexDirection: "row", marginBottom: 12 }}>
        <Photo source={item.photo} style="avatarSmall" sourceSize="w288" />
        <Spacer horizontal={1} />
        <TBody>
          {isDeglazeUser(item) && (
            <a href={AdminUsersScreenIdNav.getPath(item.userId)} target="_blank" rel="noreferrer">
              {item.name} @{item.username}
            </a>
          )}
          {isKnownAuthor(item) && <TBody>KA: {item.name}</TBody>}
          {isKnownPublisher(item) && <TBody>KP: {item.name}</TBody>}
        </TBody>
      </View>
    );
  };

  return <AdminPagedDataList fetch={fetch} render={render} />;
};

const RecommendedFollows = (props: { data: RecommendedFollowInfo }) => {
  return props.data.entities.length === 0 ? (
    <TBody>No recommended follows</TBody>
  ) : (
    <View style={{ maxWidth: 400 }}>
      <table>
        <thead>
          <tr>
            <th style={{ textAlign: "left" }}>User</th>
            <th style={{ textAlign: "left" }}>Score</th>
          </tr>
        </thead>
        <tbody>
          {props.data.entities.map(u => {
            return (
              <tr key={getSocialEntityId(u.entity)}>
                <td>
                  {u.type === "recommendedUser" && (
                    <a href={AdminUsersScreenIdNav.getPath(u.entity.userId)} target="_blank" rel="noreferrer">
                      {u.entity.name} @{u.entity.username}
                    </a>
                  )}
                  {u.type !== "recommendedUser" && (
                    <TBody>
                      {u.entity.name} {u.entity.id}
                    </TBody>
                  )}
                </td>
                <td>{u.score}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </View>
  );
};
