import { bottomLog } from "@eatbetter/common-shared";
import * as ExpoHaptics from "expo-haptics";
import { Platform } from "react-native";
import { log } from "../Log";

type Action = "itemAdded" | "itemDeleted" | "itemStatusChanged" | "operationSucceeded" | "tapControl";

export const Haptics = {
  feedback: (type: Action) => {
    feedbackInternal(type).catch(err => log.errorCaught(`Unexpected error in Haptics.feedback for type ${type}`, err));
  },
};

async function feedbackInternal(type: Action) {
  const logTriggered = () => log.debug(`BZZZ - Haptics triggered! Operation type: ${type}`);

  if (Platform.OS !== "web") {
    switch (type) {
      case "itemAdded":
      case "itemDeleted":
      case "itemStatusChanged":
      case "tapControl":
        await ExpoHaptics.impactAsync(ExpoHaptics.ImpactFeedbackStyle.Medium);
        logTriggered();
        break;
      case "operationSucceeded":
        await ExpoHaptics.notificationAsync(ExpoHaptics.NotificationFeedbackType.Success);
        logTriggered();
        break;
      default:
        bottomLog(type, "Haptics.feedback", log);
        break;
    }
  }
}
