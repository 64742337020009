import { ActionCreator, configureStore, Middleware, ThunkAction as ReduxThunkAction, Action } from "@reduxjs/toolkit";
import { getInitialState, rootReducer, RootState } from "./RootReducer";
import { Deps, PlatformDeps } from "../Deps";
import { defaultTimeProvider } from "@eatbetter/common-shared";
import { WebsocketManager } from "../WebsocketManager";
import { handleWebsocketMessage } from "../WebsocketHandler";
import { log } from "../../Log";
import { useDispatch as reduxUseDispatch, useSelector as reduxUseSelector } from "react-redux";
import { debounce } from "lodash";
import { RemoteLogger } from "../../RemoteLogger";
import { Platform } from "react-native";
import { CurrentEnvironment } from "../../CurrentEnvironment";
import { persistStore, persistReducer, createTransform } from "redux-persist";
import { PersistConfig, PersistedState, Persistor, TransformOutbound } from "redux-persist/es/types";
import AsyncStorage from "@react-native-async-storage/async-storage";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import { nativeBuildVersion } from "expo-application";
import { hashCode } from "@eatbetter/common-shared";
import { getUrlsForEnv } from "../ApiClientBase";
import { getAppApiClientFactory } from "../ApiClient";
import { getAppMetaHeaders } from "../MetaHeaders";
import { SecondTimer } from "../cooking/CookingTimerTick";
import { LiveActivityApi } from "../LiveActivities";
import { AsyncStorageStatic } from "@react-native-async-storage/async-storage/src/types";
import { produce } from "immer";
import { migrateSystemState, rehydrateSystemState, SystemState } from "../system/SystemSlice";
import { rehydrateRecipeState } from "../recipes/RecipesSlice";
import { rehydrateSocialState } from "../social/SocialSlice";
import { rehydrateCookingSessionsState } from "../cooking/CookingSessionsSlice";
import { rehydrateListsState } from "../lists/ListsSlice";
import { migrateSearchState, rehydrateSearchState, SearchState } from "../search/SearchSlice";

let cachedStore: ReturnType<typeof createReduxStore>["store"] | undefined;

export type StoreChangeHandler = (s: RootState, d: AppDispatch) => void;
export const subscribeToStoreChanges = (fn: StoreChangeHandler): (() => void) => {
  if (!cachedStore) {
    throw new Error("Store has not been created yet");
  }

  return cachedStore.subscribe(() => {
    const s = cachedStore!.getState();
    const d = cachedStore!.dispatch;
    fn(s, d);
  });
};

/**
 * Get dispatch outside the scope of a thunk or component
 */
export const getGlobalDispatch = (): AppDispatch | undefined => {
  return cachedStore?.dispatch;
};

export const reduxPersistKey = "redux-persist";

/**
 * The frequency at which a NOP action is dispatched so reactors/time based state displays can update
 */
export const idleTimeout = 10000;

const { setItem, ...other } = AsyncStorage;

const lastEnds: Record<string, number> = {};
const persistStorage: AsyncStorageStatic = {
  ...other,
  setItem: (key, value, callback) => {
    const start = Date.now();
    return setItem(key, value, callback).then(() => {
      const end = Date.now();
      // measure last end to current start
      const lastEnd = lastEnds[key] ?? 0;
      const delta = lastEnd ? start - lastEnd : "<first persist>";
      lastEnds[key] = end;
      const valueMb = (value.length / 1024 / 1024).toFixed(1);
      log.info(
        `Redux-persist: Persisted ${valueMb}mb for ${key} in ${
          end - start
        }ms. Time between last finish and start: ${delta}`
      );
    });
  },
};

export const createReduxStore = (platformDeps: PlatformDeps, opts: { middleware?: Middleware[] } = {}) => {
  const platform = Platform.OS;
  if (platform !== "ios" && platform !== "web") {
    throw new Error(`Unexpected platform ${platform}`);
  }

  const metaHeaders = getAppMetaHeaders({
    devBuild: platformDeps.env.debugBuild(),
    gitSha: CurrentEnvironment.gitSha(),
    platform,
    appVersion: platformDeps.appInfo?.version,
    deviceId: platformDeps.deviceInfo.getDeviceId(),
    deviceType: platformDeps.deviceInfo.getDeviceType(),
    deviceOsVersion: platformDeps.deviceInfo.getOsVersion(),
  });

  const urls = getUrlsForEnv(platformDeps.env.configEnvironment());

  const api = getAppApiClientFactory({
    urls,
    metaHeaders,
    // it is important that this function not log because of RemoteLogger - we
    // can't have any log statements in the api path that aren't controlled by
    // an option to disable logging.
    getToken: platformDeps.auth.getToken,
  });

  if (platformDeps.env.configEnvironment() !== "local" && !platformDeps.env.debugBuild()) {
    const remoteLogger = new RemoteLogger(api.withThrow());
    log.setRemoteLogger(remoteLogger);
  }

  // the websocket library doesn't respond well if this throws.
  // Just return undefined, and it will try to reconnect
  const safeGetToken = async () => {
    try {
      const t = await platformDeps.auth.getToken();
      return t;
    } catch (err) {
      log.errorCaught("Error while retrieving auth token", err);
      return undefined;
    }
  };

  const websocketConnection = new WebsocketManager({
    url: urls.gatewayWebsocket,
    getToken: safeGetToken,
  });

  const epochMs = defaultTimeProvider;

  const deps: Deps = {
    ...platformDeps,
    api,
    time: {
      epochMs,
    },
    websocketConnection,
    // there is a circular dependency for deps and the redux persistor
    // so leave it undefined for now it gets set after the store is created
    // below
    reduxPersistor: undefined as unknown as Persistor,
    asyncStorage: AsyncStorage,
    secondTimer: new SecondTimer(epochMs),
    liveActivities: new LiveActivityApi(),
  };

  const persistVersion = getPersistVersion();

  const transformReducers = [
    "system",
    "recipes",
    "social",
    "cookingSessions",
    "groceryLists",
    "search",
  ] as const satisfies Readonly<Array<keyof RootState>>;

  /**
   * We are using this to modify hydrated state - specifically, removing a value in some cases. We're using this instead of the blacklist property
   * because using that would require us to switch to nested redux-persist reducers, and I don't think there are other advantages for us right now.
   */
  const hydrateTransform: TransformOutbound<
    RootState[(typeof transformReducers)[number]],
    RootState[(typeof transformReducers)[number]],
    RootState
  > = (persistedState, key) => {
    // narrow the typing a bit to ensure that the key being transformed is actually passed to this function
    switch (key as (typeof transformReducers)[number]) {
      case "system": {
        return produce(persistedState, rehydrateSystemState);
      }
      case "recipes": {
        return produce(persistedState, rehydrateRecipeState);
      }
      case "social": {
        return produce(persistedState, rehydrateSocialState);
      }
      case "cookingSessions": {
        return produce(persistedState, rehydrateCookingSessionsState);
      }
      case "groceryLists": {
        return produce(persistedState, rehydrateListsState);
      }
      case "search": {
        return produce(persistedState, rehydrateSearchState);
      }
      default:
        return persistedState;
    }
  };

  const transfomer = createTransform(
    // transform state on its way to being serialized and persisted. We don't need this currently
    null,
    // transform state being rehydrated
    hydrateTransform,
    // define which reducers this transform gets called for.
    // it wants a mutable array, so we give it one.
    { whitelist: [...transformReducers] }
  );

  const persistConfig: PersistConfig<ReturnType<typeof rootReducer>> = {
    version: persistVersion,
    storage: platformDeps.reduxPersistStorage ?? persistStorage,
    key: reduxPersistKey,
    //throttle: 500,
    stateReconciler: hardSet,
    writeFailHandler: err => log.errorCaught("Error writing redux persist state", err),
    migrate: async (state, currentVersion): Promise<PersistedState> => {
      // nothing to migrate
      if (!state) {
        log.info("Persisted state is undefined. Returning undefined in migrate.");
        return undefined;
      }

      // should we preserve timers here?
      if (state._persist.version !== currentVersion) {
        log.info("Persisted state version mismatch. Running migrate", {
          currentVersion,
          stateVersion: state?._persist.version,
        });

        const initialState = getInitialState();

        const systemKey: keyof RootState = "system";
        const system: SystemState =
          systemKey in state
            ? produce(initialState.system, s => migrateSystemState(state[systemKey], s))
            : initialState.system;

        const searchKey: keyof RootState = "search";
        const search: SearchState =
          searchKey in state
            ? produce(initialState.search, s => migrateSearchState(state[searchKey], s))
            : initialState.search;

        const r: RootState = {
          ...initialState,
          system,
          search,
          // I don't really understand why they want this here, especially since it has the old version specified, but
          // everything seems to work fine.
          _persist: state._persist,
        };

        return r;
      }

      log.info("Persisted state is the correct version. Restoring state.");
      return Promise.resolve(state);
    },
    transforms: [transfomer],
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const augmentedPersistedReducer: typeof persistedReducer = (state, action) => {
    if (typeof action.type !== "string") {
      throw new Error(`Expecting action.type to be a string. Found ${action.type}`);
    }
    // we use hardSet for redux-persist. This essentially replaces the current state with the incoming state.
    // This is great as long as the application waits to alter state until rehydration is complete.
    // We accomplish this with the PersistGate in AppRoot.tsx.
    // This check will verify that we don't introduce any cases where we are dispatching actions and modifying
    // state before the rehydration is completed.
    if (!action.type.startsWith("@@redux") && !action.type.startsWith("@@INIT") && !action.type.startsWith("persist")) {
      const s = state as RootState;

      if (!s._persist?.rehydrated) {
        const msg = `Found application action ${action.type} before redux-persist rehydration. rehydrated is ${s._persist?.rehydrated}`;
        log.error(msg, { persistState: s._persist });

        if (CurrentEnvironment.configEnvironment() !== "prod") {
          //throw new Error(msg);
        }
      }
    }

    return persistedReducer(state, action);
  };

  const store = configureStore({
    reducer: augmentedPersistedReducer,
    middleware: getDefaultMiddleware => {
      const r = getDefaultMiddleware({
        thunk: {
          extraArgument: deps,
        },
        immutableCheck: false,
        serializableCheck: false,
      });
      r.push(...(opts.middleware ?? []));
      return r;
    },
  });

  deps.reduxPersistor = persistStore(store);

  websocketConnection.onReceive((msg: string) => {
    store
      .dispatch(handleWebsocketMessage(msg))
      .catch(err => log.errorCaught("Error dispatching handleWebsocketMessage", err, { msg }));
  });

  // dispatch an action at least every idleTimeout seconds to make sure the
  // time based reactors trigger even if the app is idle.
  const idleDispatcher = debounce(() => {
    log.info("Dispatching global/idle");
    store.dispatch({ type: "global/idle" });
  }, idleTimeout);

  const unsubscribe = store.subscribe(idleDispatcher);

  cachedStore = store;

  return { store, persistor: deps.reduxPersistor, unsubscribe };

  // // https://redux-toolkit.js.org/tutorials/advanced-tutorial
  // if (Config.debugBuild() && (module as unknown as any).hot) {
  //   (module as unknown as any).hot.accept('./RootReducer', () => {
  //     log.info("Hot reloading rootReducer");
  //     const newRootReducer = require('./RootReducer').rootReducer;
  //     store.replaceReducer(newRootReducer);
  //   })
  // }
};

// the goal here is to have the redux-persist version change when the app version changes
// to avoid any compatibility issues
// for ios, the build number is monotonically increasing
// for web, there is no build number, so use a hash of the git sha
// the local story is a bit trickier. For ios, gitSha isn't set (it is set during the appcenter build)
// and the build number won't change. In short, it's up to the developer to blow away the persisted state if they
// need to. The easiest way to do that is to sign out and back in.
function getPersistVersion(): number {
  if (nativeBuildVersion) {
    try {
      const version = parseInt(nativeBuildVersion);
      log.info(`Using nativeBuildVersion for redux-persist version: ${version}`);
      return version;
    } catch (err) {
      log.errorCaught("Error while parsing nativeBuildVersion to an int", err, { nativeBuildVersion });
    }
  }

  const gitSha = CurrentEnvironment.gitSha();
  if (gitSha) {
    const version = hashCode(gitSha);
    log.info(`Using hashed git sha for redux-persist version. SHA: ${gitSha}. Version: ${version}`);
    return version;
  }

  log.error("Falling back to timestamp for redux-persist version. This effectively makes redux-persist useless.");
  return Date.now();
}

export type ThunkAction<TReturn> = ReturnType<
  ActionCreator<ReduxThunkAction<Promise<TReturn>, RootState, Deps, Action>>
>;
export type SyncThunkAction<TReturn> = ReturnType<ActionCreator<ReduxThunkAction<TReturn, RootState, Deps, Action>>>;

export type StoreType = ReturnType<typeof createReduxStore>["store"];
export type AppDispatch = StoreType["dispatch"];

//https://redux-toolkit.js.org/tutorials/typescript#define-typed-hooks
export const useSelector = reduxUseSelector.withTypes<RootState>();
export const useDispatch = reduxUseDispatch.withTypes<AppDispatch>();
