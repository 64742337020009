import React, { useEffect, useRef } from "react";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { RecipeDetailScreenProps } from "../navigation/NavTree";
import { UserRecipeId, haveRecipeIngredients } from "@eatbetter/recipes-shared";
import { globalStyleConstants } from "../components/GlobalStyles";
import { useDispatch } from "../lib/redux/Redux";
import { reportLibraryRecipeDetailView } from "../lib/recipes/RecipesThunks";
import { log } from "../Log";
import { useRecipe } from "../lib/recipes/RecipesSelectors";
import { View } from "react-native";
import { useExplainerSheet } from "../components/ExplainerSheet";
import { THeading2, TBody } from "../components/Typography";
import { Spacer } from "../components/Spacer";
import { RecipeDetailScreenComponent } from "../components/recipes/RecipeDetailScreenComponent";
import { Separator } from "../components/Separator";
import { switchReturn } from "@eatbetter/common-shared";

const strings = {
  title: "Recipe",
  explainerSheet: {
    headline: "We couldn't find a written\nrecipe on this page",
    subhead: {
      instagramHead: "For Instagram / TikTok / etc: ",
      instagramBody: "check that there's a written recipe in the caption.",
      websiteHead: "For other sites: ",
      websiteBody: "check that there's a written recipe somewhere on the page.",
      reportIssue: "If there's a recipe here and we missed it, please report an issue.",
    },
    cta: "Got it",
  },
};

export const RecipeDetailScreen = withScreenContainer(
  "RecipeDetailScreen",
  (props: RecipeDetailScreenProps) => {
    const dispatch = useDispatch();
    const screen = useScreen();

    const recipe = useRecipe(props.recipeId);
    const haveIngredients = !!recipe && haveRecipeIngredients(recipe.ingredients);

    // Analytics: report recipe viewed
    useEffect(() => {
      if (recipe) {
        dispatch(reportLibraryRecipeDetailView(recipe));
      } else {
        log.error(`Recipe detail screen mounted but we have no recipe for id ${props.recipeId}`);
      }
    }, [!!recipe]);

    const initialArchived = useRef(recipe?.archived).current;

    // Go back if there's no recipe
    // we allow archived recipes to be viewed in case they are accessed from the grocery list
    // after the user archives them (i.e. they added it to the list before archiving), but only
    // if they were archived at load. Otherwise, the user might have just archived the recipe.
    useEffect(() => {
      if (!recipe || recipe.deleted || (!initialArchived && recipe.archived)) {
        screen.nav.goBack();
      }
    }, [recipe]);

    const sourceTypeNeedsExplainer =
      recipe &&
      switchReturn(recipe.source.type, {
        url: true,
        userPhoto: false,
        book: false,
        user: false,
      });

    // Explainer sheet for the first time a user sees a recipe that failed parsing
    useExplainerSheet({
      checkpoint: "recipeViewedWithParsingFailure",
      closeButtonText: strings.explainerSheet.cta,
      content: <RecipeParseFailedExplainerSheetContent />,
      height: 380,
      condition: screen.nav.focused && sourceTypeNeedsExplainer && recipe?.status === "complete" && !haveIngredients,
      disableGestureDismissal: true,
    });

    return <RecipeDetailScreenComponent recipe={recipe} context="library" />;
  },
  {
    serializer: {
      recipeId: s => s,
    },
    parser: {
      recipeId: s => s as UserRecipeId,
    },
  }
);

const RecipeParseFailedExplainerSheetContent = React.memo(() => {
  return (
    <View style={{ flex: 1, padding: 20 }}>
      <View style={{ height: 64, marginTop: globalStyleConstants.unitSize }}>
        <THeading2 align="center" numberOfLines={2} adjustsFontSizeToFit>
          {strings.explainerSheet.headline}
        </THeading2>
      </View>
      <Spacer vertical={0.5} />
      <Separator orientation="row" />
      <Spacer vertical={1.5} />
      <View>
        <TBody>
          <TBody fontWeight="medium">{strings.explainerSheet.subhead.instagramHead}</TBody>
          <TBody>{strings.explainerSheet.subhead.instagramBody}</TBody>
        </TBody>
        <Spacer vertical={1} />
        <TBody>
          <TBody fontWeight="medium">{strings.explainerSheet.subhead.websiteHead}</TBody>
          <TBody>{strings.explainerSheet.subhead.websiteBody}</TBody>
        </TBody>
        <Spacer vertical={1} />
        <TBody>{strings.explainerSheet.subhead.reportIssue}</TBody>
      </View>
    </View>
  );
});
