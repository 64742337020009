import { BrowserRouter, NavLink, Routes, Route } from "react-router-dom";
import { ContainerEnd, Spacer } from "@eatbetter/ui-shared";
import { TruthSetScreen, TruthSetScreenNav } from "../screens/TruthSetScreen";
import { View } from "react-native";
import { globalStyleConstants } from "@eatbetter/ui-shared";
import React from "react";
import { AdminIngredientScreen, AdminIngredientScreenNav } from "../screens/AdminIngredientScreen";
import { AdminRecipesScreen, AdminRecipesScreenEmptyNav, AdminRecipesScreenIdNav } from "../screens/AdminRecipesScreen";
import { AdminHomeScreen, AdminHomeScreenNav } from "../screens/AdminHomeScreen";
import { AdminRecipeEditScreen, AdminRecipeEditScreenNav } from "../screens/AdminRecipeEditScreen";
import { AdminStoreHtmlScreen, AdminStoreHtmlScreenNav } from "../screens/AdminStoreHtmlScreen";
import { AdminBooksScreen, AdminBooksScreenEmptyNav, AdminBooksScreenIdNav } from "../screens/AdminBooksScreen";
import { AdminBookAddRecipeScreen, AdminRecipeAddBookScreenNav } from "../screens/AdminBookAddRecipeScreen";
import { AdminBookEditScreen, AdminBookEditScreenNav } from "../screens/AdminBookEditScreen";
import { AdminBookCreateScreen, AdminBookCreateScreenNav } from "../screens/AdminBookCreateScreen";
import { Switch } from "antd";
import { useDispatch } from "../lib/AdminRedux";
import { setTheme } from "../lib/system/AdminSystemSlice";
import { AdminUsersScreen, AdminUsersScreenEmptyNav, AdminUsersScreenIdNav } from "../screens/AdminUsersScreen";
import { AdminUserRecipesScreen, AdminUserRecipesScreenNav } from "../screens/AdminUserRecipesScreen";
import { ParsedItemDebugScreen, ParsedItemDebugScreenNav } from "../screens/ParsedItemDebugScreen";
import { AdminRecipeOrphanScreen, AdminRecipeOrphanScreenNav } from "../screens/AdminRecipeOrphanScreen";
import { AdminUserSocialScreen, AdminUserSocialScreenNav } from "../screens/AdminUserSocialScreen";
import { AdminRecipeDocumentsScreen, AdminRecipeDocumentsScreenNav } from "../screens/AdminRecipeDocumentsScreen";
import { AdminKnownDataScreen, AdminKnownDataScreenNav } from "../screens/AdminKnownDataScreen";

export const isLocalHost = () => window.location.hostname.endsWith("localhost");

export const AdminNav = () => {
  return (
    <BrowserRouter>
      <NavHeader />
      <Routes>
        <Route path={AdminHomeScreenNav.getPath()} element={<AdminHomeScreen />} />
        <Route path={AdminRecipesScreenEmptyNav.getPath()} element={<AdminRecipesScreen />} />
        <Route path={AdminRecipesScreenIdNav.getPath()} element={<AdminRecipesScreen />} />
        <Route path={AdminRecipeEditScreenNav.getPath()} element={<AdminRecipeEditScreen />} />
        <Route path={AdminRecipeOrphanScreenNav.getPath()} element={<AdminRecipeOrphanScreen />} />
        <Route path={AdminRecipeDocumentsScreenNav.getPath()} element={<AdminRecipeDocumentsScreen />} />
        <Route path={AdminIngredientScreenNav.getPath()} element={<AdminIngredientScreen />} />
        <Route path={TruthSetScreenNav.getPath()} element={<TruthSetScreen />} />
        <Route path={ParsedItemDebugScreenNav.getPath()} element={<ParsedItemDebugScreen />} />
        <Route path={AdminStoreHtmlScreenNav.getPath()} element={<AdminStoreHtmlScreen />} />
        <Route path={AdminBooksScreenEmptyNav.getPath()} element={<AdminBooksScreen />} />
        <Route path={AdminBooksScreenIdNav.getPath()} element={<AdminBooksScreen />} />
        <Route path={AdminRecipeAddBookScreenNav.getPath()} element={<AdminBookAddRecipeScreen />} />
        <Route path={AdminBookEditScreenNav.getPath()} element={<AdminBookEditScreen />} />
        <Route path={AdminBookCreateScreenNav.getPath()} element={<AdminBookCreateScreen />} />
        <Route path={AdminUsersScreenEmptyNav.getPath()} element={<AdminUsersScreen />} />
        <Route path={AdminUsersScreenIdNav.getPath()} element={<AdminUsersScreen />} />
        <Route path={AdminUserRecipesScreenNav.getPath()} element={<AdminUserRecipesScreen />} />
        <Route path={AdminUserSocialScreenNav.getPath()} element={<AdminUserSocialScreen />} />
        <Route path={AdminKnownDataScreenNav.getPath()} element={<AdminKnownDataScreen />} />
      </Routes>
    </BrowserRouter>
  );
};

export const NavHeader = React.memo(() => {
  const dispatch = useDispatch();
  const onToggleTheme = (light: boolean) => {
    dispatch(setTheme(light ? "light" : "dark"));
  };

  return (
    <View style={{ flexDirection: "row", padding: 2 * globalStyleConstants.unitSize, backgroundColor: "lightGrey" }}>
      <Spacer horizontal={1} />
      <NavLink to={AdminHomeScreenNav.getPath()} style={navLinkStyle}>
        Home
      </NavLink>
      <Spacer horizontal={1} />
      <NavLink to={AdminUsersScreenEmptyNav.getPath()} style={navLinkStyle}>
        Users
      </NavLink>
      <Spacer horizontal={1} />
      <NavLink to={AdminRecipesScreenEmptyNav.getPath()} style={navLinkStyle}>
        Recipes
      </NavLink>
      <Spacer horizontal={1} />
      <NavLink to={AdminKnownDataScreenNav.getPath()} style={navLinkStyle}>
        Known Data
      </NavLink>
      <Spacer horizontal={1} />
      <NavLink to={AdminBooksScreenEmptyNav.getPath()} style={navLinkStyle}>
        Books
      </NavLink>
      <Spacer horizontal={1} />
      <NavLink to={TruthSetScreenNav.getPath()} style={navLinkStyle}>
        Truth
      </NavLink>
      <Spacer horizontal={1} />
      <NavLink to={AdminIngredientScreenNav.getPath()} style={navLinkStyle}>
        Ingredients
      </NavLink>
      <ContainerEnd horizontal>
        <Switch checkedChildren="Light" unCheckedChildren="Dark" defaultChecked onChange={onToggleTheme} />
      </ContainerEnd>
    </View>
  );
});

const navLinkStyle = (i: { isActive: boolean }) => {
  const base = {
    textDecoration: "none",
  };
  const fontWeight = i.isActive ? 900 : 500;
  const color = i.isActive ? "red" : "black";
  return { ...base, fontWeight, color };
};
