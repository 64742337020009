import { useMemo } from "react";
import { ScreenView } from "../components/ScreenView";
import { withScreenContainer } from "../navigation/ScreenContainer";
import { HeaderProps } from "../components/ScreenHeaders";
import { FollowersFollowing } from "../components/social/FollowersFollowing";
import { FollowersFollowingScreenProps } from "../navigation/NavTree";

const strings = {
  screenTitle: "My Profile",
};

export const FollowersFollowingScreen = withScreenContainer<FollowersFollowingScreenProps>(
  "FollowersFollowingScreen",
  props => {
    const header = useMemo<HeaderProps>(() => {
      return {
        type: "default",
        title: strings.screenTitle,
        backgroundColor: "transparent",
      };
    }, []);

    return (
      <ScreenView
        header={header}
        scrollView={false}
        paddingHorizontal={"none"}
        paddingVertical={"headerAndBottomTabBar"}
      >
        <FollowersFollowing userId={undefined} initialTab={props.initialView} />
      </ScreenView>
    );
  },
  {
    serializer: {
      initialView: {
        optional: true,
        fn: s => s,
      },
    },
    parser: {
      initialView: {
        optional: true,
        fn: s => s as "following" | "followers",
      },
    },
  }
);
