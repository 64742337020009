import React from "react";
import { View } from "react-native";
import { globalStyleConstants } from "./GlobalStyles";

const unitSize = globalStyleConstants.unitSize;

export const Spacer = React.memo((props: { horizontal?: number; vertical?: number; unit?: "pixels" | "units" }) => {
  const multiplier = props.unit === "pixels" ? 1 : unitSize;
  const width = props.horizontal ? props.horizontal * multiplier : undefined;
  const height = props.vertical ? props.vertical * multiplier : undefined;
  return <View style={{ width, height }} />;
});
