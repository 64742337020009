import React, { useCallback, useEffect, useMemo } from "react";
import { isDeglazeUser, parseMentions, shouldQueryForMentionUsers } from "@eatbetter/posts-shared";
import { useUserSearchUsers } from "../lib/users/UsersSelectors";
import { useDispatch } from "../lib/redux/Redux";
import { searchUsers, SearchUsersContext } from "../lib/users/UsersThunks";
import { log } from "../Log";
import { TextInput, TextInputHandle, TextInputProps } from "./TextInput";
import { UserId, Username } from "@eatbetter/common-shared";
import { NativeSyntheticEvent, TextInputSelectionChangeEventData } from "react-native";
import { SingleSelectEntity, SelectEntityType, SelectEntity } from "./SelectEntity";

export type AtMentionQuery = {
  prefix: "@";
  query: string;
  index: number;
};

type TextInputWithAtMentionsProps = Pick<
  TextInputProps,
  | "value"
  | "onChangeText"
  | "placeholderText"
  | "editable"
  | "onFocus"
  | "onBlur"
  | "onContentSizeChange"
  | "scrollEnabled"
  | "fontSize"
> & {
  cursorPosition: number;
  setCursorPosition: (value: number) => void;
  setAtMentionQuery: (value: AtMentionQuery | undefined) => void;
  searchUsersContext: SearchUsersContext;
};

export const TextInputWithAtMentions = React.forwardRef<TextInputHandle, TextInputWithAtMentionsProps>((props, ref) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(searchUsers("", props.searchUsersContext)).catch(err =>
      log.errorCaught("Unexpected error in useEffect searchUsers", err)
    );
  }, []);

  const atMentionSearchUsers = useCallback(
    async (text: string, cursorPosition: number) => {
      const activeAtMention = getAtMentionQuery({ text, cursorPosition });

      if (!activeAtMention) {
        props.setAtMentionQuery(undefined);
        return;
      }

      await dispatch(searchUsers(activeAtMention.query, props.searchUsersContext));
      props.setAtMentionQuery(activeAtMention);
    },
    [dispatch, props.setAtMentionQuery, props.searchUsersContext]
  );

  const onChangeText = useCallback(
    async (text: string) => {
      props.onChangeText?.(text);
      await atMentionSearchUsers(text, props.cursorPosition);
    },
    [props.onChangeText, atMentionSearchUsers, props.cursorPosition]
  );

  const onSelectionChange: TextInputProps["onSelectionChange"] = useCallback(
    ({
      nativeEvent: {
        selection: { start, end },
      },
    }: NativeSyntheticEvent<TextInputSelectionChangeEventData>) => {
      if (start !== end) {
        return;
      }
      props.setCursorPosition(start);
    },
    [props.setCursorPosition]
  );

  return (
    <TextInput
      ref={ref}
      value={props.value}
      onChangeText={onChangeText}
      keyboardType="twitter"
      noBorder
      backgroundColor="transparent"
      showClearButton={false}
      multiline
      placeholderText={props.placeholderText}
      editable={props.editable}
      onSelectionChange={onSelectionChange}
      onContentSizeChange={props.onContentSizeChange}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      noPadding
      fontSize={props.fontSize}
    />
  );
});

export interface AtMentionSelectUserProps {
  text: string;
  setText: (text: string) => void;
  atMentionQuery: AtMentionQuery | undefined;
  setAtMentionQuery: (atMentionQuery: AtMentionQuery | undefined) => void;
  cursorPosition: number;
  presentation: { type: "inline" } | { type: "fullScreenOverlay"; paddingTop: number; paddingBottom: number };
}

export const AtMentionSelectUser = React.memo((props: AtMentionSelectUserProps) => {
  const atMentionUsers = useUserSearchUsers();

  const onSelectUser = useCallback(
    (item: SelectEntityType) => {
      const user = item.entity;
      if (!props.atMentionQuery || !isDeglazeUser(user)) {
        return;
      }

      const expandedText = expandCurrentAtMention({
        text: props.text,
        cursorPosition: props.cursorPosition,
        atMentionQuery: props.atMentionQuery,
        expandedAtMention: user.username,
      });

      props.setText(expandedText);
      props.setAtMentionQuery(undefined);
    },
    [
      props.atMentionQuery,
      props.text,
      props.cursorPosition,
      props.atMentionQuery,
      props.setText,
      props.setAtMentionQuery,
    ]
  );

  const entities = useMemo(() => {
    return [{ entities: atMentionUsers.users.map<SingleSelectEntity>(u => ({ type: "singleSelect", entity: u })) }];
  }, [atMentionUsers.users]);

  return (
    <>
      {!!props.atMentionQuery && (
        <>
          {props.presentation.type === "fullScreenOverlay" && (
            <SelectEntity
              type="overlay"
              entityData={entities}
              onSelectEntity={onSelectUser}
              queryString={props.atMentionQuery.query}
              keyboardDismissMode="none"
              waitingForResults={atMentionUsers.pending}
              paddingTop={props.presentation.paddingTop}
              paddingBottom={props.presentation.paddingBottom}
            />
          )}
          {props.presentation.type === "inline" && (
            <SelectEntity
              type="inline"
              entityData={entities}
              onSelectEntity={onSelectUser}
              queryString={props.atMentionQuery.query}
              waitingForResults={atMentionUsers.pending}
              // Short term fix to address inline layout/UI bug. This makes it so that there's no scrolling / offscreen
              // results for now.
              maxCount={2}
            />
          )}
        </>
      )}
    </>
  );
});

export const TextWithAtMentions = React.memo(
  (props: {
    text: string;
    mentions: Record<Username, UserId>;
    renderText: (text: string, index: number) => React.ReactNode;
    renderAtMention: (atMention: string, userId: UserId, index: number) => React.ReactNode;
  }) => {
    const mentions = parseMentions(props.text);

    const commentText = mentions.tokens.map((i, idx) => {
      if (typeof i === "string") {
        return props.renderText(i, idx);
      }

      const userId = props.mentions[i.username as Username];

      // if we don't know the user ID, don't render it as an @mention
      if (!userId) {
        return props.renderText(i.mention, idx);
      }

      return props.renderAtMention(i.mention, userId, idx);
    });

    return <>{commentText}</>;
  }
);

function getAtMentionQuery(args: { text: string; cursorPosition: number }): AtMentionQuery | undefined {
  const atMentionPrefix: AtMentionQuery["prefix"] = "@";
  const currentContext = args.text.slice(0, args.cursorPosition);

  const lastAtIndex = currentContext.lastIndexOf(atMentionPrefix);
  const charBefore = currentContext[lastAtIndex - 1];
  const charBeforeIsWhitespace = charBefore?.match(/\s/);

  if (lastAtIndex < 0 || (lastAtIndex > 0 && !charBeforeIsWhitespace)) {
    return undefined;
  }

  const lastAtString = currentContext.slice(lastAtIndex);

  if (!shouldQueryForMentionUsers(lastAtString)) {
    return undefined;
  }

  return { prefix: atMentionPrefix, query: lastAtString.slice(1), index: lastAtIndex };
}

function expandCurrentAtMention(args: {
  text: string;
  cursorPosition: number;
  atMentionQuery: AtMentionQuery;
  expandedAtMention: string;
}) {
  const left = args.text.slice(0, args.atMentionQuery.index);
  const right = args.text.slice(args.cursorPosition);
  const expanded = left.concat(args.atMentionQuery.prefix, args.expandedAtMention, " ", right);

  return expanded;
}
