import { DECADE_UNIT_DIFF } from "../panels/DecadePanel/constant";
export var WEEK_DAY_COUNT = 7;
export function isNullEqual(value1, value2) {
  if (!value1 && !value2) {
    return true;
  }
  if (!value1 || !value2) {
    return false;
  }
  return undefined;
}
export function isSameDecade(generateConfig, decade1, decade2) {
  var equal = isNullEqual(decade1, decade2);
  if (typeof equal === 'boolean') {
    return equal;
  }
  var num1 = Math.floor(generateConfig.getYear(decade1) / 10);
  var num2 = Math.floor(generateConfig.getYear(decade2) / 10);
  return num1 === num2;
}
export function isSameYear(generateConfig, year1, year2) {
  var equal = isNullEqual(year1, year2);
  if (typeof equal === 'boolean') {
    return equal;
  }
  return generateConfig.getYear(year1) === generateConfig.getYear(year2);
}
export function getQuarter(generateConfig, date) {
  var quota = Math.floor(generateConfig.getMonth(date) / 3);
  return quota + 1;
}
export function isSameQuarter(generateConfig, quarter1, quarter2) {
  var equal = isNullEqual(quarter1, quarter2);
  if (typeof equal === 'boolean') {
    return equal;
  }
  return isSameYear(generateConfig, quarter1, quarter2) && getQuarter(generateConfig, quarter1) === getQuarter(generateConfig, quarter2);
}
export function isSameMonth(generateConfig, month1, month2) {
  var equal = isNullEqual(month1, month2);
  if (typeof equal === 'boolean') {
    return equal;
  }
  return isSameYear(generateConfig, month1, month2) && generateConfig.getMonth(month1) === generateConfig.getMonth(month2);
}
export function isSameDate(generateConfig, date1, date2) {
  var equal = isNullEqual(date1, date2);
  if (typeof equal === 'boolean') {
    return equal;
  }
  return generateConfig.getYear(date1) === generateConfig.getYear(date2) && generateConfig.getMonth(date1) === generateConfig.getMonth(date2) && generateConfig.getDate(date1) === generateConfig.getDate(date2);
}
export function isSameTime(generateConfig, time1, time2) {
  var equal = isNullEqual(time1, time2);
  if (typeof equal === 'boolean') {
    return equal;
  }
  return generateConfig.getHour(time1) === generateConfig.getHour(time2) && generateConfig.getMinute(time1) === generateConfig.getMinute(time2) && generateConfig.getSecond(time1) === generateConfig.getSecond(time2);
}
export function isSameWeek(generateConfig, locale, date1, date2) {
  var equal = isNullEqual(date1, date2);
  if (typeof equal === 'boolean') {
    return equal;
  }
  return isSameYear(generateConfig, date1, date2) && generateConfig.locale.getWeek(locale, date1) === generateConfig.locale.getWeek(locale, date2);
}
export function isEqual(generateConfig, value1, value2) {
  return isSameDate(generateConfig, value1, value2) && isSameTime(generateConfig, value1, value2);
}

/** Between in date but not equal of date */
export function isInRange(generateConfig, startDate, endDate, current) {
  if (!startDate || !endDate || !current) {
    return false;
  }
  return !isSameDate(generateConfig, startDate, current) && !isSameDate(generateConfig, endDate, current) && generateConfig.isAfter(current, startDate) && generateConfig.isAfter(endDate, current);
}
export function getWeekStartDate(locale, generateConfig, value) {
  var weekFirstDay = generateConfig.locale.getWeekFirstDay(locale);
  var monthStartDate = generateConfig.setDate(value, 1);
  var startDateWeekDay = generateConfig.getWeekDay(monthStartDate);
  var alignStartDate = generateConfig.addDate(monthStartDate, weekFirstDay - startDateWeekDay);
  if (generateConfig.getMonth(alignStartDate) === generateConfig.getMonth(value) && generateConfig.getDate(alignStartDate) > 1) {
    alignStartDate = generateConfig.addDate(alignStartDate, -7);
  }
  return alignStartDate;
}
export function getClosingViewDate(viewDate, picker, generateConfig) {
  var offset = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 1;
  switch (picker) {
    case 'year':
      return generateConfig.addYear(viewDate, offset * 10);
    case 'quarter':
    case 'month':
      return generateConfig.addYear(viewDate, offset);
    default:
      return generateConfig.addMonth(viewDate, offset);
  }
}
export function formatValue(value, _ref) {
  var generateConfig = _ref.generateConfig,
    locale = _ref.locale,
    format = _ref.format;
  return typeof format === 'function' ? format(value) : generateConfig.locale.format(locale.locale, value, format);
}
export function parseValue(value, _ref2) {
  var generateConfig = _ref2.generateConfig,
    locale = _ref2.locale,
    formatList = _ref2.formatList;
  if (!value || typeof formatList[0] === 'function') {
    return null;
  }
  return generateConfig.locale.parse(locale.locale, value, formatList);
}

// eslint-disable-next-line consistent-return
export function getCellDateDisabled(_ref3) {
  var cellDate = _ref3.cellDate,
    mode = _ref3.mode,
    disabledDate = _ref3.disabledDate,
    generateConfig = _ref3.generateConfig;
  if (!disabledDate) return false;
  // Whether cellDate is disabled in range
  var getDisabledFromRange = function getDisabledFromRange(currentMode, start, end) {
    var current = start;
    while (current <= end) {
      var _date = void 0;
      switch (currentMode) {
        case 'date':
          {
            _date = generateConfig.setDate(cellDate, current);
            if (!disabledDate(_date)) {
              return false;
            }
            break;
          }
        case 'month':
          {
            _date = generateConfig.setMonth(cellDate, current);
            if (!getCellDateDisabled({
              cellDate: _date,
              mode: 'month',
              generateConfig: generateConfig,
              disabledDate: disabledDate
            })) {
              return false;
            }
            break;
          }
        case 'year':
          {
            _date = generateConfig.setYear(cellDate, current);
            if (!getCellDateDisabled({
              cellDate: _date,
              mode: 'year',
              generateConfig: generateConfig,
              disabledDate: disabledDate
            })) {
              return false;
            }
            break;
          }
      }
      current += 1;
    }
    return true;
  };
  switch (mode) {
    case 'date':
    case 'week':
      {
        return disabledDate(cellDate);
      }
    case 'month':
      {
        var startDate = 1;
        var endDate = generateConfig.getDate(generateConfig.getEndDate(cellDate));
        return getDisabledFromRange('date', startDate, endDate);
      }
    case 'quarter':
      {
        var startMonth = Math.floor(generateConfig.getMonth(cellDate) / 3) * 3;
        var endMonth = startMonth + 2;
        return getDisabledFromRange('month', startMonth, endMonth);
      }
    case 'year':
      {
        return getDisabledFromRange('month', 0, 11);
      }
    case 'decade':
      {
        var year = generateConfig.getYear(cellDate);
        var startYear = Math.floor(year / DECADE_UNIT_DIFF) * DECADE_UNIT_DIFF;
        var endYear = startYear + DECADE_UNIT_DIFF - 1;
        return getDisabledFromRange('year', startYear, endYear);
      }
  }
}