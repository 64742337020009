import React, { useCallback, useEffect, useState } from "react";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import {
  EditUserRecipeOnSaveArgs,
  RecipeEditControl,
  useRecipeEditSaveHeader,
} from "../components/recipes/RecipeEditControl";
import { EditRecipeArgs, AppUserRecipe, UserRecipeId } from "@eatbetter/recipes-shared";
import { useDispatch } from "../lib/redux/Redux";
import { alertWebRecipePaywallIsUp, editRecipe, getAndStoreUserRecipe } from "../lib/recipes/RecipesThunks";
import { ScreenView } from "../components/ScreenView";
import { RecipeEditScreenProps } from "../navigation/NavTree";
import { useRecipe } from "../lib/recipes/RecipesSelectors";
import { FlexedSpinner } from "../components/Spinner";
import { log } from "../Log";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { addPhoto, deletePhoto } from "../lib/photos/PhotoThunks";
import { useScreenHeaderDimensions } from "../components/ScreenHeaders";
import { useBottomTabBarDimensions } from "../navigation/TabBar";
import { ScrollView } from "react-native";
import { useKeyboardLayoutAnimation } from "../components/Keyboard";
import { Spacer } from "../components/Spacer";
import { globalStyleConstants } from "../components/GlobalStyles";
import { usePaywallStatus } from "../components/PaywallDetector";

const strings = {
  title: "Edit Recipe",
};

export const RecipeEditScreen = withScreenContainer(
  "RecipeEditScreen",
  (props: RecipeEditScreenProps) => {
    const dispatch = useDispatch();
    const recipe = useRecipe(props.recipeId);
    const screen = useScreen();
    const [waiting, setWaiting] = useState(false);

    useEffect(() => {
      dispatch(getAndStoreUserRecipe({ recipeId: props.recipeId }, setWaiting)).catch(err => {
        displayUnexpectedErrorAndLog("Error retrieving recipe for edit", err, { recipeId: props.recipeId });
      });
    }, []);

    const onSave = useCallback(
      async (args: EditUserRecipeOnSaveArgs) => {
        if (!recipe) {
          return;
        }

        const newPhoto = args.newRecipePhoto ? await dispatch(addPhoto(args.newRecipePhoto)) : undefined;

        const editRecipeArgs: EditRecipeArgs = {
          description: args.description,
          ingredients: args.ingredients,
          instructions: args.instructions,
          title: args.title,
          id: props.recipeId,
          version: recipe.version,
          photo: newPhoto ?? args.photo,
          time: args.time,
          recipeYield: args.recipeYield,
        };

        await dispatch(editRecipe(editRecipeArgs));

        if (newPhoto && args.photo && args.photo.type === "internal") {
          // Clean up old photo in background
          dispatch(deletePhoto({ photo: args.photo })).catch(err =>
            log.errorCaught("Delete photo call failed", err, { photo: args.photo })
          );
        }

        // nav back on success
        // setImmediate prevents a react state update warning
        setImmediate(screen.nav.goBack);
      },
      [recipe, props.recipeId, dispatch, screen.nav.goBack]
    );

    const { paywallIsUp } = usePaywallStatus(recipe);

    // Paywall: honor paywall if detected
    useEffect(() => {
      if (paywallIsUp && recipe) {
        dispatch(alertWebRecipePaywallIsUp(recipe, "Edit Recipe", screen.nav.goBack));
      }
    }, [paywallIsUp]);

    return React.createElement(RecipeEditScreenComponent, {
      waiting,
      recipe,
      onSave,
    });
  },
  {
    serializer: {
      recipeId: s => s,
    },
    parser: {
      recipeId: s => s as UserRecipeId,
    },
  }
);

interface Props {
  waiting: boolean;
  recipe?: AppUserRecipe;
  onSave: (args: EditUserRecipeOnSaveArgs) => Promise<void>;
}

export const RecipeEditScreenComponent = (props: Props) => {
  const { headerHeight } = useScreenHeaderDimensions();
  const { bottomTabBarHeight } = useBottomTabBarDimensions();
  const keyboardHeight = useKeyboardLayoutAnimation();

  const { recipeEditRef, screenHeader } = useRecipeEditSaveHeader(strings.title);

  if (props.waiting || !props.recipe) {
    return <FlexedSpinner />;
  }

  return (
    <ScreenView scrollView={false} paddingVertical={false} paddingHorizontal={false} header={screenHeader}>
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{
          paddingHorizontal: 20,
          paddingTop: headerHeight + 1.5 * globalStyleConstants.unitSize,
          paddingBottom: (keyboardHeight > 0 ? keyboardHeight : bottomTabBarHeight) + 2 * globalStyleConstants.unitSize,
        }}
      >
        <RecipeEditControl
          ref={recipeEditRef}
          onSave={props.onSave}
          initialRecipe={props.recipe}
          type="user"
          showSaveButton={false}
        />
        <Spacer vertical={3} />
      </ScrollView>
    </ScreenView>
  );
};
