import {
  ScrollEvent,
  SharedValue,
  runOnJS,
  useAnimatedScrollHandler,
  useDerivedValue,
  useSharedValue,
} from "react-native-reanimated";
import { TabViewRoute } from "./TabView";
import { useCallback, useMemo } from "react";
import { OnScrollWorklet } from "./social/SocialFeed";

export type ScrollEventHandler = ReturnType<typeof useAnimatedScrollHandler>;
type Scrollable = { scrollTo: (y: number, animated?: boolean) => void };

export interface AnimatedTabList {
  route: TabViewRoute;
  scrollPosition: SharedValue<number>;
  isScrolling: SharedValue<boolean>;
  scrollDirection: SharedValue<"up" | "down">;
  onScroll: OnScrollWorklet;
  onEndDrag: OnScrollWorklet;
  onMomentumEnd: OnScrollWorklet;
}

export const useSyncedTabLists2 = <T extends Scrollable>(
  routes: [TabViewRoute, TabViewRoute],
  listRef1: React.RefObject<T>,
  listRef2: React.RefObject<T>,
  activeIndex: number,
  headerComponentHeight: number,
  autoScrollThreshold: number
): [AnimatedTabList, AnimatedTabList] => {
  const pauseIsScrollingUpdates = useSharedValue(false);

  const list1 = useAnimatedTabList(routes[0], pauseIsScrollingUpdates);
  const list2 = useAnimatedTabList(routes[1], pauseIsScrollingUpdates);
  const lists: [AnimatedTabList, AnimatedTabList] = useMemo(() => [list1, list2], [list1, list2]);
  const routeAndRefs = useMemo(
    () => lists.map((i, idx) => ({ ...i, ref: [listRef1, listRef2][idx] })),
    [listRef1, listRef2]
  );

  useAutoScrollInThreshold(routeAndRefs, activeIndex, autoScrollThreshold, pauseIsScrollingUpdates);
  useSyncScrollPositions(routeAndRefs, activeIndex, headerComponentHeight);

  return lists;
};

const useAnimatedTabList = (route: TabViewRoute, pauseIsScrollingUpdates: SharedValue<boolean>): AnimatedTabList => {
  const scrollPosition = useSharedValue(0);
  const scrollDirection = useSharedValue<"up" | "down">("down");
  const setScrollDirection = (currScrollPos: number) => {
    "worklet";
    if (currScrollPos !== scrollPosition.value) {
      scrollDirection.value = currScrollPos > scrollPosition.value ? "down" : "up";
    }
  };
  const isScrolling = useSharedValue(false);
  const setIsScrolling = (value: boolean) => {
    "worklet";
    if (!pauseIsScrollingUpdates.value) {
      isScrolling.value = value;
    }
  };

  const onScroll = (e: ScrollEvent) => {
    "worklet";
    setIsScrolling(true);
    setScrollDirection(e.contentOffset.y);
    scrollPosition.value = e.contentOffset.y;
  };

  const onEndDrag = (e: ScrollEvent) => {
    "worklet";
    if (e.velocity && e.velocity.y === 0) {
      setIsScrolling(false);
    }
  };

  const onMomentumEnd = (_e: ScrollEvent) => {
    "worklet";
    setIsScrolling(false);
  };

  return useMemo(
    () => ({
      route,
      scrollPosition,
      isScrolling,
      scrollDirection,
      onScroll,
      onEndDrag,
      onMomentumEnd,
    }),
    [route, scrollPosition, isScrolling]
  );
};

function useSyncScrollPositions<T extends Scrollable>(
  lists: Array<AnimatedTabList & { ref?: React.RefObject<T> }>,
  activeIndex: number,
  syncThreshold: number
) {
  const syncScrollPositions = (activeScrollY: number) => {
    lists
      .filter((_i, idx) => idx !== activeIndex)
      .forEach(i => {
        if (!i.ref?.current) {
          return;
        }
        if (activeScrollY < syncThreshold) {
          // If the tab bar is scrolled (i.e. not pinned to header) in the current tab, we need to make sure the other
          // views are synced so that it doesn't jump when you switch tabs
          i.ref.current.scrollTo(activeScrollY);
        } else if (activeScrollY >= syncThreshold && i.scrollPosition.value < syncThreshold) {
          // If the tab bar is pinned to the header but scrolled in other view (i.e. not pinned to the header), we need
          // to scroll the other views up to pin the tab bar so that it doesn't jump when you switch tabs
          i.ref.current.scrollTo(syncThreshold);
        }
      });
  };

  const activeScrollPosition = lists[activeIndex]?.scrollPosition;

  useDerivedValue(() => {
    if (!activeScrollPosition) {
      return;
    }

    runOnJS(syncScrollPositions)(activeScrollPosition.value);
  });
}

/**
 * Auto-scrolls out of a specified threshold at the beginning of a scroll view / list. This is used with headers that slide out and back in,
 * in order to prevent the half-showing state if the user stops scrolling there. This will automatically "complete" the transition by
 * scrolling until the header is fully hidden, or fully visible depending on the direction of scroll.
 */
function useAutoScrollInThreshold<T extends Scrollable>(
  lists: Array<AnimatedTabList & { ref?: React.RefObject<T> }>,
  activeIndex: number,
  scrollThresholdY: number,
  pauseIsScrollingUpdates: SharedValue<boolean>
) {
  const listRef = lists[activeIndex]?.ref?.current;
  const scrollY = lists[activeIndex]?.scrollPosition;
  const isScrolling = lists[activeIndex]?.isScrolling;
  const scrollDirection = lists[activeIndex]?.scrollDirection;

  const autoScroll = useCallback(
    (y: number) => {
      if (listRef && isScrolling) {
        // Pause isScrolling updates until we're done with autoScroll to avoid a side-effect loop
        pauseIsScrollingUpdates.value = true;
        isScrolling.value = true;
        listRef.scrollTo(y, true);
        // scrollTo is not synchronous. Wait to avoid too many updates when there is a lot of touch activity in the threshold.
        setTimeout(() => {
          isScrolling.value = false;
          pauseIsScrollingUpdates.value = false;
        }, 500);
      }
    },
    [listRef, isScrolling, activeIndex, pauseIsScrollingUpdates]
  );

  useDerivedValue(() => {
    if (isScrolling?.value) {
      return;
    }

    const inThreshold = !!scrollY?.value && scrollY.value > 0 && scrollY.value < scrollThresholdY;

    if (inThreshold && scrollDirection) {
      if (scrollDirection.value === "down") {
        runOnJS(autoScroll)(scrollThresholdY);
      } else {
        runOnJS(autoScroll)(0);
      }
    }
  });
}
