import { UrlString } from "@eatbetter/common-shared";

export function getAppStoreLink(opts?: { reviewPrompt: boolean }): UrlString {
  const url = "https://apps.apple.com/us/app/deglaze/id6443578246";

  if (opts?.reviewPrompt) {
    return `${url}?action=write-review` as UrlString;
  } else {
    return url as UrlString;
  }
}
