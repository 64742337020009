import { useCallback, useState } from "react";
import { newId } from "@eatbetter/common-shared";

export const useIdempotentId = <T extends string = string>(prefix?: string): [T, () => void] => {
  const [id, setId] = useState(newId(prefix));

  const refresh = useCallback(() => {
    setId(newId(prefix));
  }, [setId, prefix]);

  return [id as T, refresh];
};
