import { defaultTimeProvider, DurationMs } from "@eatbetter/common-shared";
import { useCallback, useEffect, useRef } from "react";
import { useIsFocused } from "@react-navigation/native";
import { useAppFocused } from "../system/SystemSelectors";
import { analyticsEvent } from "../analytics/AnalyticsThunks";
import { reportScreenView } from "../analytics/AnalyticsEvents";
import { useDispatch } from "../redux/Redux";
import { useScreen } from "../../navigation/ScreenContainer";

/**
 * @param opts If set, opts.nameOverride will be used in place of the screen name. If set, propsString will be added to the event property. It could be the json stringified props, or whatever representation makes sense for the screen.
 */
export const useScreenTimerWithScreenViewEvent = (opts?: { nameOverride?: string; propsString?: string }) => {
  const dispatch = useDispatch();
  const alreadyReported = useRef<number>(0);
  const lastReported = useRef<number>(0);
  const appFocused = useAppFocused();
  const getTime = useScreenTimer();

  const defaultScreenName = useScreen().navScreen.name;

  const report = useCallback(() => {
    const currentTime = getTime().activeTime;
    const newTime = (currentTime - alreadyReported.current) as DurationMs;
    if (newTime > 0 && defaultTimeProvider() - lastReported.current > 1000) {
      const screenName = opts?.nameOverride ?? defaultScreenName;
      dispatch(
        analyticsEvent(
          reportScreenView({
            screenName,
            instanceActiveTime: newTime,
            totalActiveTime: currentTime,
            screenProps: opts?.propsString,
          })
        )
      );
      alreadyReported.current = currentTime;
      lastReported.current = defaultTimeProvider();
    }
    // All of these *should* be stable. If they aren't we'd get some extraneous events, I think.
  }, [dispatch, alreadyReported, lastReported, getTime, defaultScreenName, opts?.nameOverride, opts?.propsString]);

  // report a view each time the app is backgrounded. This can result in multiple events for a single loading of a screen
  // when the user switches away from the app, but this should guarantee the event fires if a user switches away and the app is closed.
  // If the user closes the app, I'm unclear if this event will fire. It doesn't seem to on the sim.
  useEffect(() => {
    if (!appFocused) {
      report();
    }
    // we're counting on the refs not changing
  }, [appFocused, report]);

  // report a view when the hook (and presumably screen) is unmounted. If this and the backgrounding both fire within a second,
  // only 1 should be reported because of the check in the function.
  useEffect(() => {
    return () => {
      report();
    };
  }, [report]);
};

export const useScreenTimer = () => {
  const overallStartTime = useRef<number | undefined>();
  const interruptions = useRef(0);
  const currentStartTime = useRef<number | undefined>();
  const durations = useRef<number[]>([]);
  const focused = useIsFocused();
  const foregrounded = useAppFocused();

  useEffect(() => {
    if (focused && foregrounded) {
      const now = defaultTimeProvider();
      if (!overallStartTime.current) {
        overallStartTime.current = now;
      }
      currentStartTime.current = now;
    } else {
      if (currentStartTime.current) {
        durations.current.push(defaultTimeProvider() - currentStartTime.current);
        currentStartTime.current = undefined;
        interruptions.current++;
      }
    }
  }, [focused, foregrounded]);

  // this is used as a dep above (and possibly other places), so needs to be stable
  const getTimeInfo = useCallback(() => {
    const now = defaultTimeProvider();
    const current = currentStartTime.current ? now - currentStartTime.current : 0;
    const activeTime = durations.current.reduce((a, b) => a + b, 0) + current;
    const totalTime = overallStartTime.current ? now - overallStartTime.current : 0;

    return {
      activeTime: activeTime as DurationMs,
      totalTime: totalTime as DurationMs,
      interruptions: interruptions.current,
    };
  }, [overallStartTime, currentStartTime, interruptions, durations]);

  return getTimeInfo;
};
