import { AdminScreenView } from "../components/AdminScreenView";
import { displayUnexpectedErrorAndLog, FlexedSpinner, Photo, Spacer, THeading1, THeading2 } from "@eatbetter/ui-shared";
import { useEffect, useState } from "react";
import { AdminKnownAuthorAndPublisherData } from "@eatbetter/recipes-server";
import { useDispatch } from "../lib/AdminRedux";
import { getKnownData } from "../lib/recipes/AdminRecipesThunks";
import { View } from "react-native";
import { KnownAuthorInternal, NamesAndDomains } from "@eatbetter/recipes-server/dist/internal/KnownAuthorData";
import { KnownPublisherInternal } from "@eatbetter/recipes-server/dist/internal/KnownPublisherData";
import { EntityLinks } from "@eatbetter/recipes-shared";

export const AdminKnownDataScreenNav = {
  getPath: () => {
    return "/known-data";
  },
};

export const AdminKnownDataScreen = () => {
  const [data, setData] = useState<AdminKnownAuthorAndPublisherData | undefined>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getKnownData())
      .then(setData)
      .catch(err => displayUnexpectedErrorAndLog("Error dispatching getKnownData", err));
  }, []);

  return (
    <AdminScreenView>
      <THeading1>Known Data</THeading1>
      {!data && <FlexedSpinner />}
      {data && (
        <View>
          <Spacer vertical={3} />
          <THeading2>Authors</THeading2>
          <table>
            <tbody>
              {data.authors.map(a => (
                <Author author={a} key={a.id} />
              ))}
            </tbody>
          </table>
          <Spacer vertical={3} />
          <THeading2>Publishers</THeading2>
          <table>
            <tbody>
              {data.publishers.map(p => (
                <Publisher publisher={p} key={p.id} />
              ))}
            </tbody>
          </table>
        </View>
      )}
    </AdminScreenView>
  );
};

const Author = (props: { author: KnownAuthorInternal }) => {
  const a = props.author;
  return (
    <tr>
      <td style={{ paddingBottom: 12 }}>{a.entity.name}</td>
      <td style={{ paddingBottom: 12 }}>
        <Photo source={a.entity.photo} style="avatarSmall" sourceSize="w288"></Photo>
      </td>
      <td style={{ paddingBottom: 12 }}>{a.id}</td>
      <td style={{ paddingBottom: 12 }}>
        <Matches match={a.match} />
      </td>
      <td style={{ paddingBottom: 12 }}>
        <Links links={a.profileInfo?.links ?? {}} />
      </td>
    </tr>
  );
};

const Matches = (props: { match: NamesAndDomains[] }) => {
  return (
    <View>
      {props.match.map((nad, idx) => {
        return (
          <View key={idx}>
            <span>
              {nad.domains === "allTrustedDomains" ? nad.domains : nad.domains.join(", ")}: {nad.names.join(", ")}
            </span>
          </View>
        );
      })}
    </View>
  );
};

const Links = (props: { links: EntityLinks }) => {
  const l = props.links;
  return (
    <View>
      {!!l.website && (
        <a href={l.website} target="_blank" rel="noreferrer">
          {l.website}
        </a>
      )}
      {!!l.instagramHandle && (
        <a href={`https://www.instagram.com/${l.instagramHandle}`} target="_blank" rel="noreferrer">
          IG: {l.instagramHandle}
        </a>
      )}
      {!!l.substack && (
        <a href={l.substack} target="_blank" rel="noreferrer">
          {l.substack}
        </a>
      )}
    </View>
  );
};

const Publisher = (props: { publisher: KnownPublisherInternal }) => {
  const p = props.publisher;

  return (
    <tr>
      <td style={{ paddingBottom: 12 }}>{p.entity.name}</td>
      <td style={{ paddingBottom: 12 }}>
        <Photo source={p.entity.photo} style="avatarSmall" sourceSize="w288"></Photo>
      </td>
      <td style={{ paddingBottom: 12 }}>{p.id}</td>
      <td style={{ paddingBottom: 12 }}>
        <Domains domains={p.domains} />
      </td>
    </tr>
  );
};

const Domains = (props: { domains: string[] }) => {
  return (
    <View>
      {props.domains.map(d => (
        <a key={d} href={`https://${d}/`} target="_blank" rel="noreferrer">
          {d}
        </a>
      ))}
    </View>
  );
};
