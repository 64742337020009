import { RootState } from "./RootReducer";
import { createDraftSafeSelector, createDraftSafeSelectorCreator, lruMemoize } from "@reduxjs/toolkit";
import memoize from "fast-memoize";

/**
 * Returns a typed createSelector function with the specified cache size. Note that the input selectors for the resulting
 * function must be wrapped in an array for propert typing.
 *
 * Using a cache size of 1 matches the default reselect behavior. Any selector that takes an argument other than the
 * state (ids, for example) should generally have a cache size > 1.
 * @example
 * const selectExample = getCreateSelectorWithCacheSize(10)([
 *   (_s, id: string) => id,
 *   s => s.recipes.foo,
 *   s => s.system.bar,
 * ], (id, a1, a2) => {
 *   return { id, a1, a2 };
 * });
 *
 * selectExample(rootState, "someId");
 */
export const getCreateSelectorWithCacheSize = memoize((size: number) => {
  return createDraftSafeSelectorCreator({
    memoize: lruMemoize,
    memoizeOptions: {
      maxSize: size,
    },
  }).withTypes<RootState>();
});

type Selector<T = unknown> = (state: RootState, ...other: any[]) => T;

// createDraftSafeSelector make these safe to use in an immer-based reducer (eg: a reducer in createSlice)
/**
 * @deprecated use getCreateSelectorWithCacheSize
 */
export const createSelector1 = <T1, R>(s1: Selector<T1>, r: (t1: T1) => R) => createDraftSafeSelector(s1, r);
/**
 * @deprecated use getCreateSelectorWithCacheSize
 */
export const createSelector2 = <T1, T2, R>(s1: Selector<T1>, s2: Selector<T2>, r: (t1: T1, t2: T2) => R) =>
  createDraftSafeSelector(s1, s2, r);
/**
 * @deprecated use getCreateSelectorWithCacheSize
 */
export const createSelector3 = <T1, T2, T3, R>(
  s1: Selector<T1>,
  s2: Selector<T2>,
  s3: Selector<T3>,
  r: (t1: T1, t2: T2, t3: T3) => R
) => createDraftSafeSelector(s1, s2, s3, r);
/**
 * @deprecated use getCreateSelectorWithCacheSize
 */
export const createSelector4 = <T1, T2, T3, T4, R>(
  s1: Selector<T1>,
  s2: Selector<T2>,
  s3: Selector<T3>,
  s4: Selector<T4>,
  r: (t1: T1, t2: T2, t3: T3, t4: T4) => R
) => createDraftSafeSelector(s1, s2, s3, s4, r);
/**
 * @deprecated use getCreateSelectorWithCacheSize
 */
export const createSelector5 = <T1, T2, T3, T4, T5, R>(
  s1: Selector<T1>,
  s2: Selector<T2>,
  s3: Selector<T3>,
  s4: Selector<T4>,
  s5: Selector<T5>,
  r: (t1: T1, t2: T2, t3: T3, t4: T4, t5: T5) => R
) => createDraftSafeSelector(s1, s2, s3, s4, s5, r);
/**
 * @deprecated use getCreateSelectorWithCacheSize
 */
export const createSelector6 = <T1, T2, T3, T4, T5, T6, R>(
  s1: Selector<T1>,
  s2: Selector<T2>,
  s3: Selector<T3>,
  s4: Selector<T4>,
  s5: Selector<T5>,
  s6: Selector<T6>,
  r: (t1: T1, t2: T2, t3: T3, t4: T4, t5: T5, t6: T6) => R
) => createDraftSafeSelector(s1, s2, s3, s4, s5, s6, r);
