import { useScreen, withNonNavigableScreenContainer } from "../../navigation/ScreenContainer";
import { ScreenView } from "../../components/ScreenView";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "../../lib/redux/Redux";
import { navHome } from "../../navigation/NavThunks";
import { submitUserOnboarding } from "../../lib/system/SystemThunks";
import { log } from "../../Log";
import { OnboardingCtaButton } from "../../components/onboarding/OnboardingCtaButton";
import { THeading2, TSecondary } from "../../components/Typography";
import { Spacer } from "../../components/Spacer";
import { View, Image, StyleSheet } from "react-native";
import { bottomActionBarConstants } from "../../components/BottomActionBar";
import { globalStyleConstants } from "../../components/GlobalStyles";
import { useBottomTabBarDimensions } from "../../navigation/TabBar";
import { smallScreenBreakpoint, useResponsiveDimensions } from "../../components/Responsive";
import { Haptics } from "../../components/Haptics";

const bgImage = require("../../assets/onboarding/welcome.png");

const strings = {
  headline: "You're all set!",
  subhead:
    "Get ready to save hours every week. Based on your answers, we've compiled some tips for you to help you get started. Bon appétit!",
};

export const OnboardingFinishScreen = withNonNavigableScreenContainer("OnboardingFinishScreen", () => {
  const { nav } = useScreen();
  const dispatch = useDispatch();
  const [waiting, setWaiting] = useState(true);
  const { bottomNotchHeight } = useBottomTabBarDimensions();
  const { width: screenWidth, height: screenHeight, defaultFontScale } = useResponsiveDimensions();

  useEffect(() => {
    // the impact is minimal if this call fails - the user might not get notifications.
    // we can live with that.
    dispatch(submitUserOnboarding(setWaiting)).catch(err => {
      log.errorCaught("Error dispatching userOnboarding in OnboardingFinishScreen", err);
    });
  }, []);

  const onNext = useCallback(() => {
    Haptics.feedback("itemStatusChanged");
    dispatch(navHome(nav, "onboarding complete"));
  }, [dispatch, nav]);

  return (
    <ScreenView scrollView={false} paddingHorizontal={false} paddingVertical={false}>
      <Image
        source={bgImage}
        resizeMode="cover"
        style={[StyleSheet.absoluteFill, { width: screenWidth, height: screenHeight }]}
      />
      <View
        style={{
          flex: 1,
          justifyContent: "flex-end",
          paddingBottom: bottomActionBarConstants.height + bottomNotchHeight,
        }}
      >
        <View
          style={{
            paddingTop: globalStyleConstants.defaultPadding,
            paddingHorizontal: globalStyleConstants.defaultPadding,
            maxWidth: smallScreenBreakpoint,
            alignSelf: "center",
          }}
        >
          <THeading2 enableFontScaling="upOnly" scale={defaultFontScale} color="white" fontWeight="medium">
            {strings.headline}
          </THeading2>
          <Spacer vertical={0.5} />
          <TSecondary enableFontScaling="upOnly" scale={defaultFontScale} color="white">
            {strings.subhead}
          </TSecondary>
        </View>
      </View>
      <OnboardingCtaButton cta="done" background="transparent" waiting={waiting} onPress={onNext} />
    </ScreenView>
  );
});
