import { DocumentHeadEdit } from "../DocumentHeadEdit/DocumentHeadEdit";
import { withErrorBoundary } from "../ErrorBoundary";
import { DocumentHeadProviderComponent, DocumentHeadProviderProps } from "./DocumentHeadProviderInterfaces";
import { HelmetProvider } from "react-helmet-async";

const defaults = {
  title: "Deglaze",
};

export const DocumentHeadProvider: DocumentHeadProviderComponent = withErrorBoundary(
  "DocumentHeadProviderWeb",
  (props: DocumentHeadProviderProps) => {
    return (
      <HelmetProvider>
        <DocumentHeadEdit documentTitle={defaults.title} />
        {props.children}
      </HelmetProvider>
    );
  },
  props => <>{props.children}</>
);
