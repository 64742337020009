import { UserRecipeId } from "@eatbetter/recipes-shared";
import { RecipeNotesEdit } from "../components/recipes/RecipeNotesEdit";
import { ScreenView } from "../components/ScreenView";
import { useRecipeTitle } from "../lib/recipes/RecipesSelectors";
import { withScreenContainer } from "../navigation/ScreenContainer";
import { RecipeEditFieldLocation, RecipeNotesEditScreenProps } from "../navigation/NavTree";

const strings = {
  screenTitle: "Notes",
};

export const RecipeNotesEditScreen = withScreenContainer(
  "RecipeNotesEditScreen",
  (props: RecipeNotesEditScreenProps) => {
    const recipeTitle = useRecipeTitle(props.recipeId);

    return (
      <ScreenView
        header={{
          type: "custom",
          title: `${strings.screenTitle}: ${recipeTitle}` ?? strings.screenTitle,
        }}
        paddingHorizontal={false}
      >
        <RecipeNotesEdit recipeId={props.recipeId} location={props.recipeEditFieldLocation} autoFocusMode="delayed" />
      </ScreenView>
    );
  },
  {
    serializer: {
      recipeId: s => s,
      recipeEditFieldLocation: s => s,
    },
    parser: {
      recipeId: s => s as UserRecipeId,
      recipeEditFieldLocation: s => s as RecipeEditFieldLocation,
    },
  }
);
