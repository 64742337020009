import { Animated, StyleSheet, View } from "react-native";
import { globalStyleColors, globalStyleConstants } from "./GlobalStyles";
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { TextInput, TextInputHandle, TextInputProps } from "./TextInput";
import { FontSize } from "./Typography";
import { Pressable } from "./Pressable";
import { IconCheck } from "./Icons";
import { Spinner } from "./Spinner";
import { switchReturn } from "@eatbetter/common-shared";

const config = {
  height: 58,
};

export interface FormInputProps {
  label: string;
  value: string;
  paddingHorizontal?: number;
  onChangeText?: (text: string) => void;
  onSubmitEditing?: () => void;
  textContentType?: "name" | "emailAddress" | "password";
  keyboardType?: "default" | "email-address" | "url";
  autoCapitalize?: "none";
  isReadOnly?: boolean;
  showClearButton?: boolean;
  validationState?: "invalid" | "valid" | "pending" | "none";
  disableLabelAnimation?: boolean;
  showIsValidIcon?: boolean;
  multiline?: boolean;
}

export const FormInput = React.memo(
  React.forwardRef<TextInputHandle, FormInputProps>((props, ref) => {
    const inputRef = useRef<TextInputHandle>(null);
    const [isFocused, setIsFocused] = useState(false);
    const animatedIsFocused = useRef(
      new Animated.Value(props.disableLabelAnimation ? 1 : props.value === "" ? 0 : 1)
    ).current;

    const onFocus = useCallback(() => setIsFocused(true), [setIsFocused]);
    const onBlur = useCallback(() => setIsFocused(false), [setIsFocused]);

    const onPressTextInput = useCallback(() => {
      inputRef.current?.focus();
    }, [inputRef]);

    const height = config.height;
    const fontSizeKey = "body";
    const fontSize = FontSize[fontSizeKey];
    const paddingHorizontal = props.paddingHorizontal ?? 0;
    const paddingVertical = 0.5 * globalStyleConstants.unitSize;

    useEffect(() => {
      if (props.disableLabelAnimation) {
        return;
      }

      Animated.timing(animatedIsFocused, {
        toValue: isFocused || props.value !== "" ? 1 : 0,
        duration: 200,
        useNativeDriver: false,
      }).start();
    }, [isFocused, props.value]);

    const labelPositionStyle = useMemo(() => {
      return {
        position: "absolute" as const,
        left: paddingHorizontal,
        top: animatedIsFocused.interpolate({
          inputRange: [0, 1],
          outputRange: [height - 28, paddingVertical],
        }),
        flexDirection: "row" as const,
        alignItems: "center" as const,
        zIndex: 1,
      };
    }, [animatedIsFocused, height, paddingHorizontal, paddingVertical]);

    const labelTextStyle = useMemo(() => {
      return {
        color:
          props.validationState === "invalid"
            ? globalStyleColors.colorAccentWarm
            : globalStyleColors.rgba("black", "medium"),
        fontSize: animatedIsFocused.interpolate({
          inputRange: [0, 1],
          outputRange: [fontSize, FontSize.tertiary],
        }),
        marginRight: 4,
      };
    }, [animatedIsFocused, props.validationState, fontSize]);

    useImperativeHandle(ref, () => inputRef.current!);

    // https://github.com/necolas/react-native-web/issues/2281
    // The textContentType prop is for iOS, but Android and Web use the autocomplete prop for the same thing
    const autoCompleteType = props.textContentType
      ? switchReturn<NonNullable<FormInputProps["textContentType"]>, TextInputProps["autoComplete"]>(
          props.textContentType,
          {
            name: "name",
            emailAddress: "email",
            password: "password",
          }
        )
      : undefined;

    return (
      <Pressable
        onPress={onPressTextInput}
        style={[styles.textInputWrap, { paddingHorizontal, minHeight: height }]}
        noFeedback
      >
        <Animated.View style={labelPositionStyle}>
          <Animated.Text allowFontScaling={false} style={labelTextStyle}>
            {props.label}
          </Animated.Text>
        </Animated.View>
        <View
          style={[
            styles.textInput,
            { paddingBottom: paddingVertical },
            props.validationState === "invalid" ? styles.invalid : {},
          ]}
        >
          <View style={{ flex: 1 }}>
            <TextInput
              ref={inputRef}
              onFocus={onFocus}
              onBlur={onBlur}
              onSubmit={props.onSubmitEditing}
              onChangeText={props.onChangeText}
              fontSize={fontSizeKey}
              value={props.value}
              returnKeyType="next"
              backgroundColor="transparent"
              autoCapitalize={props.autoCapitalize}
              textContentType={props.textContentType}
              autoComplete={autoCompleteType}
              keyboardType={props.keyboardType}
              editable={!props.isReadOnly}
              showClearButton={props.showClearButton}
              noBorder
              noPadding
              secureTextEntry={props.textContentType === "password"}
              multiline={props.multiline}
            />
          </View>
          {props.showIsValidIcon !== false && props.validationState === "valid" && (
            <View style={{ marginLeft: globalStyleConstants.unitSize }}>
              <IconCheck opacity="opaque" size={18} strokeWidth={2.5} color={globalStyleColors.colorAccentCool} />
            </View>
          )}
          {props.validationState === "pending" && (
            <View style={{ marginLeft: globalStyleConstants.unitSize }}>
              <Spinner />
            </View>
          )}
        </View>
      </Pressable>
    );
  })
);

const styles = StyleSheet.create({
  textInputWrap: {
    paddingTop: 18,
    justifyContent: "flex-end",
  },
  textInput: {
    minHeight: 28,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottomColor: globalStyleColors.rgba("black", "xlight"),
    borderBottomWidth: 1,
  },
  invalid: {
    borderBottomColor: globalStyleColors.colorAccentWarm,
    borderBottomWidth: 1,
  },
});
