import React, { useCallback, useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { BottomActionBar, bottomActionBarConstants } from "../components/BottomActionBar";
import { ScreenView, useScreenElementDimensions } from "../components/ScreenView";
import { useDispatch } from "../lib/redux/Redux";
import { ShareCommentRecipesScreenProps } from "../navigation/NavTree";
import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { Haptics } from "../components/Haptics";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { differenceBy } from "lodash";
import { PartialCommentId, SocialPostId } from "@eatbetter/posts-shared";
import { RecipeSelectedHandler, SelectableRecipe, SelectRecipeOverlay } from "../components/SelectRecipeOverlay";
import { UserRecipeId } from "@eatbetter/recipes-shared";
import { addCommentToPost } from "../lib/social/SocialThunks";
import { useIdempotentId } from "../lib/util/UseIdempotentId";
import { InputAccessoryView } from "../components/InputAccessoryView";
import { getLocalRecipes } from "../lib/recipes/RecipesThunks";
import { SearchAndFilterBar, useSearchAndFilterBarHeight } from "../components/SearchBox";
import { globalStyleConstants } from "../components/GlobalStyles";
import { useKeyboardLayoutAnimation } from "../components/Keyboard";
import { HeaderProps } from "../components/ScreenHeaders";

const strings = {
  header: "Share recipes",
  submit: "Share",
  searchPlaceholder: "Search",
};

export const ShareCommentRecipesScreen = withNonNavigableScreenContainer(
  "ShareCommentRecipesScreen",
  (props: ShareCommentRecipesScreenProps) => {
    const dispatch = useDispatch();
    const screen = useScreen();
    const [id, refreshId] = useIdempotentId<PartialCommentId>();

    const [searchResults, setSearchResults] = useState<SelectableRecipe[]>([]);

    const [waitingSubmit, setWaitingSubmit] = useState(false);

    const [searchPhrase, setSearchPhrase] = useState("");
    const [refreshResults, setRefreshResults] = useState(0);

    const [selectedRecipes, setSelectedRecipes] = useState<SelectableRecipe[]>([]);
    const [sortedRecipes, setSortedRecipes] = useState<SelectableRecipe[]>([]);

    useEffect(() => {
      const recipeIds = dispatch(getLocalRecipes());
      const selectableRecipes = getSelectableRecipes(recipeIds, []);
      setSearchResults(selectableRecipes);
      setRefreshResults(prev => prev + 1);
    }, []);

    useEffect(() => {
      setSortedRecipes(prev =>
        getSelectableRecipes(
          prev.map(i => i.recipeId),
          selectedRecipes
        )
      );
    }, [selectedRecipes]);

    useEffect(() => {
      const selectableRecipes = getSelectableRecipes(
        searchResults.map(i => i.recipeId),
        selectedRecipes
      );
      const additionalRecipes = differenceBy(selectedRecipes, selectableRecipes, i => i.recipeId);
      const result = searchPhrase === "" ? additionalRecipes.concat(selectableRecipes) : selectableRecipes;
      setSortedRecipes(result.sort((a, b) => Number(b.isSelected) - Number(a.isSelected)).slice());
    }, [refreshResults]);

    const onChangeSearchPhrase = useCallback(
      async (text: string) => {
        setSearchPhrase(text);
        const results = dispatch(getLocalRecipes(text));
        setSearchResults(getSelectableRecipes(results, selectedRecipes));
        setRefreshResults(prev => prev + 1);
      },
      [dispatch, setSearchResults, selectedRecipes, setSearchPhrase, setRefreshResults]
    );

    const onSelectRecipe = useCallback<RecipeSelectedHandler>(
      async recipe => {
        setSelectedRecipes(prev => {
          const existing = prev.find(i => i.recipeId === recipe.recipeId);
          if (!existing) {
            return [{ ...recipe, isSelected: !recipe.isSelected }, ...prev];
          }
          return prev.map(i => (i.recipeId === recipe.recipeId ? { ...recipe, isSelected: !recipe.isSelected } : i));
        });
      },
      [setSelectedRecipes]
    );

    const onCancel = useCallback(() => {
      screen.nav.goBack();
    }, [screen]);

    const onSubmit = useCallback(async () => {
      const recipeIds = sortedRecipes.filter(r => r.isSelected).map(r => r.recipeId);
      try {
        await dispatch(addCommentToPost({ postId: props.postId, commentId: id, text: "", recipeIds }));
        refreshId();
        Haptics.feedback("operationSucceeded");
        screen.nav.goBack();
      } catch (err) {
        displayUnexpectedErrorAndLog("Error in onSubmit in ShareCommentRecipesScreen", err, { props });
      }
    }, [screen, dispatch, sortedRecipes, id, props, refreshId]);

    return React.createElement<Props>(ShareCommentRecipesComponent, {
      postId: props.postId,
      selectableRecipes: sortedRecipes,
      selectedRecipes,
      onSelectRecipe,
      waitingSubmit,
      setWaitingSubmit,
      waitingForSearchResults: false,
      onCancel,
      onSubmit,
      searchPhrase,
      onChangeSearchPhrase,
    });
  }
);

interface Props {
  postId: SocialPostId;
  selectableRecipes: SelectableRecipe[];
  selectedRecipes: SelectableRecipe[];
  waitingForSearchResults: boolean;
  waitingSubmit: boolean;
  setWaitingSubmit: (v: boolean) => void;
  onCancel: () => void;
  onSelectRecipe: RecipeSelectedHandler;
  onSubmit: () => void;
  searchPhrase: string;
  onChangeSearchPhrase: (v: string) => void;
}

const ShareCommentRecipesComponent = React.memo((props: Props) => {
  const { headerHeight, bottomNotchHeight } = useScreenElementDimensions();
  const keyboardHeight = useKeyboardLayoutAnimation();

  const renderSearchBox = useCallback(() => {
    return (
      <SearchAndFilterBar
        searchPhrase={props.searchPhrase}
        onChangeSearchPhrase={props.onChangeSearchPhrase}
        placeholderText={strings.searchPlaceholder}
      />
    );
  }, [props.searchPhrase, props.onChangeSearchPhrase]);

  const searchBoxHeight = useSearchAndFilterBarHeight();
  const getSearchBoxHeight = useCallback(() => {
    return searchBoxHeight;
  }, [searchBoxHeight]);

  const overlayPaddingTop = headerHeight + searchBoxHeight + globalStyleConstants.unitSize;

  const overlayPaddingBottom =
    (keyboardHeight > 0
      ? keyboardHeight + bottomActionBarConstants.height
      : bottomNotchHeight + bottomActionBarConstants.height) +
    2 * globalStyleConstants.unitSize;

  const header = useMemo<HeaderProps>(() => {
    return {
      type: "custom",
      title: strings.header,
      right: { type: "cancel", onPress: props.onCancel },
      subHeaderComponent: { render: renderSearchBox, getHeight: getSearchBoxHeight },
    };
  }, [props.onCancel, renderSearchBox, getSearchBoxHeight]);

  return (
    <ScreenView header={header} scrollView={false} paddingHorizontal={false} paddingVertical={false}>
      <SelectRecipeOverlay
        recipes={props.selectableRecipes}
        onSelectRecipe={props.onSelectRecipe}
        waitingForResults={props.waitingForSearchResults}
        queryString={props.searchPhrase}
        paddingTop={overlayPaddingTop}
        paddingBottom={overlayPaddingBottom}
      />
      <InputAccessoryView>
        <BottomActionBar
          primaryAction={{
            actionText: strings.submit,
            onPressAction: props.onSubmit,
            disabled: props.selectedRecipes.every(i => !i.isSelected),
            waiting: props.waitingSubmit,
          }}
          disableSnapToBottom
        />
      </InputAccessoryView>
    </ScreenView>
  );
});

function getSelectableRecipes(recipes: UserRecipeId[], selectedRecipes: SelectableRecipe[]) {
  return recipes.map<SelectableRecipe>(i => ({
    recipeId: i,
    isSelected: !!selectedRecipes.find(j => j.recipeId === i)?.isSelected,
  }));
}
