import React, { useCallback } from "react";
import { StyleSheet, View } from "react-native";
import { THeading2, TSecondary } from "./Typography";
import { Spacer } from "./Spacer";
import { globalStyleColors } from "./GlobalStyles";
import { useScreen } from "../navigation/ScreenContainer";
import { navTree } from "../navigation/NavTree";
import { useOpenInternalWebPage } from "../lib/util/WebUtil";

const strings = {
  deglaze: "deglaze",
  help: "Help",
  privacy: "Privacy",
  terms: "Terms",
};

interface Props {
  showLogo?: boolean;
}

export const ScreenFooter = React.memo((props: Props) => {
  const screen = useScreen();

  const onPressHelp = useCallback(() => {
    screen.nav.modal(navTree.get.screens.unauthedSupport);
  }, [screen.nav.modal]);

  const onPressPrivacy = useOpenInternalWebPage("privacyPolicy");
  const onPressTerms = useOpenInternalWebPage("termsOfService");

  return (
    <View style={styles.wrap}>
      {!!props.showLogo && <Logo />}
      <View style={styles.menuItems}>
        <FooterItem text={strings.help} onPress={onPressHelp} isFirst />
        <FooterItem text={strings.privacy} onPress={onPressPrivacy} />
        <FooterItem text={strings.terms} onPress={onPressTerms} />
      </View>
    </View>
  );
});

const FooterItem = React.memo((props: { text: string; onPress: () => void; isFirst?: boolean }) => {
  return (
    <>
      {!props.isFirst && <Spacer horizontal={2} />}
      <TSecondary color={globalStyleColors.blackSoft} opacity={"dark"} onPress={props.onPress} suppressHighlighting>
        {props.text}
      </TSecondary>
    </>
  );
});

const Logo = React.memo(() => {
  return (
    <>
      <THeading2 font="serif" color={globalStyleColors.blackSoft}>
        {strings.deglaze}
      </THeading2>
      <Spacer vertical={1} />
    </>
  );
});

const styles = StyleSheet.create({
  wrap: {
    width: "100%",
    alignItems: "center",
    padding: 20,
  },
  menuItems: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
});
