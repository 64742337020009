import { AdminRecipeFeedType } from "@eatbetter/recipes-shared";
import { useCallback } from "react";
import { useDispatch } from "../AdminRedux.ts";
import { getRecipeFeed } from "./AdminRecipesThunks.ts";
import { usePagedData } from "@eatbetter/ui-shared/src/lib/UsePagedData";

export const useAdminRecipeFeed = (feed: AdminRecipeFeedType) => {
  const dispatch = useDispatch();
  const fetch = useCallback(
    async (start?: string) => {
      const nextPage = await dispatch(
        getRecipeFeed({
          type: feed,
          count: 10,
          start,
        })
      );
      return { items: nextPage.recipes, next: nextPage.next };
    },
    [feed]
  );

  return usePagedData(fetch);
};
