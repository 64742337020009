import { View } from "react-native";
import { ScreenView } from "../../components/ScreenView";
import { Spacer } from "../../components/Spacer";
import { TBody, THeading2, TSecondary } from "../../components/Typography";
import { withScreenContainer } from "../../navigation/ScreenContainer";
import { Separator } from "../../components/Separator";
import { PropsWithChildren, useMemo } from "react";
import { globalStyleConstants } from "../../components/GlobalStyles";
import { VideoWithPhoneBezel } from "../../components/VideoWithPhoneBezel";
import { VideoSource } from "../../components/Video";
import { getVideoUrl } from "../../components/Photo";
import { useScreenTimerWithScreenViewEvent } from "../../lib/util/UseScreenTimer";

const strings = {
  screenHeader: "Deglaze Grocery List",
  intro: [
    "Deglaze users report that the grocery list is one of the features that helps them cook more by making a tedious task significantly easier.",
    "Here's why it's better.",
  ],
  sections: [
    {
      key: "addRecipe",
      headline: "Add a recipe to the list",
      video: "grocery_add_recipe",
    },
    {
      key: "singleItem",
      headline: "Or a single item",
      subheadline: "We'll remember previous items to save you time.",
      video: "grocery_add_single_item",
    },
    {
      key: "categorize",
      headline: "We categorize items by grocery aisle",
      subheadline: "You'll be in and out of the store in no time.",
      video: "grocery_categorize",
    },
    {
      key: "groupItems",
      headline: "And group items for you",
      subheadline: "Making 3 recipes that need green onions? We make it easier.",
      video: "grocery_group_items",
    },
    {
      key: "sortViews",
      headline: "View the list by aisle, date, or recipe",
      video: "grocery_sort_views",
    },
    {
      key: "household",
      headline: "Share it with your family",
      subheadline: "They can add items or do the shopping.",
    },
    {
      key: "itemSource",
      headline: "And see how an item ended up on the list",
      video: "grocery_item_source",
    },
  ],
  more: [
    "And more...",
    "The list works offline if there's no signal in the store",
    "Swiping items makes it harder to accidentally complete an item",
    "Your screen stays on for convenience",
  ],
};

export const HowToGroceryOverviewScreen = withScreenContainer("HowToGroceryOverviewScreen", () => {
  useScreenTimerWithScreenViewEvent();

  return (
    <ScreenView header={{ type: "default", title: () => <TBody fontWeight="medium">{strings.screenHeader}</TBody> }}>
      <TSecondary>{strings.intro[0]}</TSecondary>
      <Spacer vertical={1} />
      <TSecondary>{strings.intro[1]}</TSecondary>
      {strings.sections.map(i => {
        return <HowToGrocerySection key={i.key} headline={i.headline} subhead={i.subheadline} videoId={i.video} />;
      })}
      <SectionDivider />
      <SectionHeader>{strings.more[0]}</SectionHeader>
      <BulletedList items={strings.more.slice(1)} />
    </ScreenView>
  );
});

const HowToGrocerySection = (props: { headline: string; subhead?: string; videoId?: string }) => {
  const videoSource = useMemo<VideoSource | undefined>(() => {
    if (!props.videoId) {
      return undefined;
    }

    return {
      type: "remote",
      url: getVideoUrl(`videos/${props.videoId}.mp4`),
    };
  }, [props.videoId]);

  return (
    <>
      <SectionDivider />
      <SectionHeader subhead={props.subhead}>{props.headline}</SectionHeader>
      {!!videoSource && (
        <View style={{ height: 600, alignItems: "center" }}>
          <VideoWithPhoneBezel videoAnalyticsId={props.videoId} videoSource={videoSource} tapToExpand />
        </View>
      )}
    </>
  );
};

const SectionHeader = (props: PropsWithChildren<{ subhead?: string }>) => (
  <>
    <THeading2 fontWeight="medium">{props.children}</THeading2>
    {!!props.subhead && (
      <>
        <Spacer vertical={0.5} />
        <TSecondary>{props.subhead}</TSecondary>
      </>
    )}
  </>
);

const SectionDivider = () => (
  <>
    <Spacer vertical={1.5} />
    <Separator orientation="row" />
    <Spacer vertical={1.5} />
  </>
);

const BulletedList = (props: { items: string[] }) => {
  return (
    <View style={{ paddingTop: 0.5 * globalStyleConstants.unitSize, paddingLeft: 0.5 * globalStyleConstants.unitSize }}>
      {props.items.map(i => {
        return (
          <View key={i}>
            <Spacer vertical={0.5} />
            <View style={{ flexDirection: "row" }}>
              <TSecondary>{"•"}</TSecondary>
              <Spacer horizontal={0.5} />
              <TSecondary>{i}</TSecondary>
            </View>
          </View>
        );
      })}
    </View>
  );
};
