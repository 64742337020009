import { switchReturn } from "@eatbetter/common-shared";
import { PhotoRef } from "@eatbetter/photos-shared";
import React from "react";
import { StyleSheet, View } from "react-native";
import { Photo, PhotoSizeName } from "./Photo";
import { Spacer } from "./Spacer";
import { Spinner } from "./Spinner";
import { TBody, TSecondary, TTertiary } from "./Typography";
import { RecipeRatingEdit } from "./recipes/RecipeRating";
import { RecipeRating } from "@eatbetter/recipes-shared";
import { VerifiedBadge } from "./social/VerifiedBadge";
import { globalStyleColors, globalStyleConstants } from "./GlobalStyles";

export const AvatarAndHeading = React.memo(
  (props: {
    horizontal?: boolean;
    avatar?: { photo?: PhotoRef; size?: PhotoSizeName };
    heading?: {
      text: string;
      size?: "body" | "secondary";
      opacity?: "opaque" | "medium";
      badge?: "verified";
      numberOfLines?: number;
    };
    subHeading?: {
      text: string;
      size?: "secondary" | "tertiary";
      badge?: boolean;
      rating?: RecipeRating;
      opacity?: "opaque" | "medium" | "dark";
      fontWeight?: "normal" | "medium";
    };
    waiting?: { message: string };
  }) => {
    const avatar = (
      <>
        {props.waiting && <Spinner />}
        {!props.waiting && (
          <Photo
            style={props.avatar?.size ?? (props.horizontal ? "avatarLarge" : "avatarMedium")}
            source={props.avatar?.photo}
            sourceSize="w1290"
          />
        )}
      </>
    );

    const headingNumberOfLines = props.heading?.numberOfLines ?? (props.horizontal ? 2 : 1);
    const headingOpacity = props.waiting ? "medium" : props.heading?.opacity ?? "opaque";
    const headingText = (
      <>
        {props.waiting && props.waiting.message}
        {!props.waiting && !!props.heading && props.heading.text}
      </>
    );
    const heading = props.heading
      ? switchReturn(props.heading.size ?? "body", {
          body: (
            <TBody
              opacity={headingOpacity}
              numberOfLines={headingNumberOfLines}
              align={props.horizontal ? "center" : "left"}
            >
              {headingText}
            </TBody>
          ),
          secondary: (
            <TSecondary
              fontWeight="medium"
              opacity={headingOpacity}
              numberOfLines={props.horizontal ? 2 : 1}
              align={props.horizontal ? "center" : "left"}
            >
              {headingText}
            </TSecondary>
          ),
        })
      : null;
    const headingBadge = props.heading?.badge
      ? switchReturn(props.heading.badge, {
          verified: <VerifiedBadge />,
        })
      : null;

    const subHeadingOpacity = props.subHeading?.opacity ?? "dark";
    const subHeadingFontWeight = props.subHeading?.fontWeight ?? (props.subHeading?.badge ? "medium" : "normal");
    const subHeadingText = props.subHeading
      ? switchReturn(props.subHeading.size ?? "secondary", {
          secondary: (
            <TSecondary
              opacity={subHeadingOpacity}
              fontWeight={subHeadingFontWeight}
              numberOfLines={1}
              align={props.horizontal ? "center" : "left"}
            >
              {props.subHeading.text}
            </TSecondary>
          ),
          tertiary: (
            <TTertiary
              opacity={"opaque"}
              fontWeight={subHeadingFontWeight}
              numberOfLines={1}
              align={props.horizontal ? "center" : "left"}
              color={props.subHeading.badge ? globalStyleColors.colorAccentCool : undefined}
            >
              {props.subHeading.text}
            </TTertiary>
          ),
        })
      : null;
    const subHeading = props.subHeading?.badge ? (
      <View style={styles.subheadingBadge}>{subHeadingText}</View>
    ) : (
      subHeadingText
    );

    const headingAndBadge = (
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {heading}
        {!!headingBadge && (
          <>
            <Spacer horizontal={0.5} />
            {headingBadge}
          </>
        )}
      </View>
    );

    const rating = (
      <>
        {!!props.subHeading?.rating && (
          <View>
            <Spacer vertical={0.4} />
            <RecipeRatingEdit rating={props.subHeading.rating} />
          </View>
        )}
      </>
    );

    return (
      <View style={props.horizontal ? styles.containerHorizontal : styles.container}>
        {avatar}
        <Spacer vertical={props.horizontal ? 1 : 0} horizontal={props.horizontal ? 0 : 1} />
        <View style={{ flexShrink: 1, alignItems: props.horizontal ? "center" : "flex-start" }}>
          {headingAndBadge}
          {subHeading}
          {rating}
        </View>
      </View>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    flexShrink: 1,
  },
  containerHorizontal: {
    alignItems: "center",
  },
  subheadingBadge: {
    paddingHorizontal: globalStyleConstants.unitSize,
    paddingVertical: 0.2 * globalStyleConstants.unitSize,
    borderRadius: globalStyleConstants.defaultBorderRadius,
    backgroundColor: globalStyleColors.rgba("colorAccentCool", "xlight"),
    marginTop: 0.4 * globalStyleConstants.unitSize,
  },
});
