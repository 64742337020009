import { ScalingQuantity, ScalingQuantityUnit, UnitConversion } from "@eatbetter/items-shared";
import { PreciseUnit } from "./PreciseUnit";
import { getBaseUnit, getUnitConversionMetadata, getUnitMetadata } from "./UnitMetadata";
import { bottomThrow } from "@eatbetter/common-shared";
import { ConvertedQuantityUnit, convertToUnit, convertUnit } from "./ConvertUnits";
import { formatQuantity } from "./Format";

export interface ConvertedUnit {
  s: string;
  p: string;
  unit?: PreciseUnit;
  insert?: boolean;
  range: [number, number];
}

export interface ConvertedScalingQuantityUnit {
  q: Array<ScalingQuantity>;
  scale: number;
  conversion: UnitConversion;
  u?: ConvertedUnit;
  originalUnit?: PreciseUnit;
}

/**
 * Returns a `ConvertedScalingQuantityUnit` for the given inputs. The output of this function is fed into the
 * formatters (see `Format.ts`) to produce UI-friendly strings.
 */
export function scaleAndConvertQuantityUnit(args: {
  qu: ScalingQuantityUnit;
  scale: number;
  conversion: UnitConversion;
  conversionType: "best" | "base";
}): ConvertedScalingQuantityUnit {
  const scaled = scaleQuantityUnit(args.qu, args.scale);

  return convertQuantityUnit({
    qu: { ...args.qu, q: scaled.q },
    scale: args.scale,
    conversion: args.conversion,
    conversionType: args.conversionType,
  });
}

/**
 * For a given `ScalingQuantityUnit`, returns the same but with the given `scale` value applied
 * to the quantities.
 */
function scaleQuantityUnit(qu: ScalingQuantityUnit, scale: number): ScalingQuantityUnit {
  const qScaled: ScalingQuantity[] = qu.q
    .map(i => {
      const scaled = scale * i.value;
      return {
        ...i,
        value: scaled,
      };
    })
    // If `insert` property is `true`, it's an implicit quantity. If `scale` is 1 (not scaled), then we
    // omit it so that it does not end up getting displayed. But if it is scaled, then we will scale and return it,
    // and it will then get inserted into the final output.
    .filter(i => !(i.insert && scale === 1));

  return {
    ...qu,
    q: qScaled,
  };
}

/**
 * Converts the provided `SxalingQuantityUnit` to the specified `UnitConversion` value. The `conversionType` property
 * specifies what type of unit is desired in the target system and measurement type: `base` returns the smallest unit
 * (e.g. grams for metric + mass), and `best` returns the "best fit" unit for the given quantity, system, and measurement type.
 * The `scale` property is attached as additional context for the formatters in the output (see `Format.ts`).
 */
export function convertQuantityUnit(args: {
  qu: ScalingQuantityUnit;
  scale: number;
  conversion: UnitConversion;
  conversionType: "best" | "base";
}): ConvertedScalingQuantityUnit {
  const q = args.qu.q;

  // If there's no unit, then we've got nothing to do here
  if (!args.qu.u || q.length === 0) {
    return { q, scale: args.scale, conversion: args.conversion };
  }

  const originalUnit = getUnitConversionMetadata(args.qu.u?.unit);

  // If there's no precise unit but there is a unit (e.g. "bunch", "piece", etc.), return the unit object
  // that contains the p and s values, but without a precise unit (undefined)
  if (!originalUnit) {
    return { q, u: { ...args.qu.u, unit: undefined }, scale: args.scale, conversion: args.conversion };
  }

  const convertTo = args.conversion === "original" ? originalUnit.system : args.conversion;

  // If the unit is implicit insert and we're not converting, drop it and return
  if (args.qu.u.insert && convertTo === originalUnit.system) {
    return { q, scale: args.scale, conversion: args.conversion };
  }

  switch (args.conversionType) {
    case "base": {
      const targetUnit = getBaseUnit(originalUnit.metadata.measurementType, convertTo);

      const convertedQuantity: ConvertedScalingQuantityUnit["q"] = q.map(i => {
        return {
          ...i,
          value: convertToUnit(i.value, originalUnit.unit, targetUnit),
        };
      });

      return {
        q: convertedQuantity,
        scale: args.scale,
        u: args.qu.u
          ? {
              ...args.qu.u,
              unit: targetUnit,
            }
          : undefined,
        originalUnit: originalUnit.unit,
        conversion: args.conversion,
      };
    }
    case "best": {
      let bestUnit: PreciseUnit = getBaseUnit(originalUnit.metadata.measurementType, convertTo);

      const convertedQuantity: ConvertedScalingQuantityUnit["q"] = q
        .sort((a, b) => a.value - b.value)
        .map((i, idx) => {
          // Convert the first first quantity to find the best unit and then use that unit to explicitly
          // convert the remaining quantities
          if (idx === 0) {
            const converted = convertUnit(i.value, originalUnit.unit, convertTo);
            const bestConverted = getBestConvertedQuantityUnit(converted);

            bestUnit = bestConverted.unit;

            return {
              ...i,
              value: bestConverted.quantity,
            };
          }

          return {
            ...i,
            value: convertToUnit(i.value, originalUnit.unit, bestUnit),
          };
        });

      return {
        q: convertedQuantity,
        scale: args.scale,
        u: args.qu.u
          ? {
              ...args.qu.u,
              unit: bestUnit,
            }
          : undefined,
        originalUnit: originalUnit.unit,
        conversion: args.conversion,
      };
    }
    default:
      bottomThrow(args.conversionType);
  }
}

/**
 * Applies unit-specific logic to QU conversion.
 */
function getBestConvertedQuantityUnit(converted: ConvertedQuantityUnit): ConvertedQuantityUnit {
  // Get the normalized quantity (i.e. how we will display it for a given QU) in order to determine
  // what changes, if any to make
  const unitMetadata = getUnitMetadata(converted.unit);
  const qNormalized = formatQuantity({ ...unitMetadata, quantity: converted.quantity }).value;

  switch (converted.unit) {
    case "tablespoon": {
      if (qNormalized === 4) {
        const cupConverted = convertToUnit(converted.quantity, converted.unit, "cup");
        return { quantity: cupConverted, unit: "cup" };
      }

      const integerPart = Math.trunc(qNormalized);
      const fractionPart = Number((qNormalized - integerPart).toFixed(2));

      if (fractionPart > 0 && fractionPart !== 0.5) {
        // Tablespoons only come in half sizes, so prefer teaspoons for anything other than half
        const tspConverted = convertToUnit(converted.quantity, converted.unit, "teaspoon");
        const tspNormalized = formatQuantity({ ...getUnitMetadata("teaspoon"), quantity: tspConverted }).value;
        if (tspNormalized === 3) {
          // Make sure we don't drop to teaspoons if the new normalized quantity is exactly 1 tablespoon
          return { quantity: converted.quantity, unit: "tablespoon" };
        }
        return { quantity: tspConverted, unit: "teaspoon" };
      }

      return converted;
    }
    case "teaspoon": {
      if (qNormalized === 3) {
        const tbspConverted = convertToUnit(converted.quantity, converted.unit, "tablespoon");
        return { quantity: tbspConverted, unit: "tablespoon" };
      }
      return converted;
    }
    case "gram": {
      if (qNormalized > 990) {
        // Round up to kilogram if we're this close
        const kgConverted = convertToUnit(converted.quantity, converted.unit, "kilogram");
        return { quantity: kgConverted, unit: "kilogram" };
      }
      return converted;
    }
    case "ounce": {
      // Round up to pounds when within half an ounce
      if (qNormalized > 15.5) {
        const poundConverted = convertToUnit(converted.quantity, converted.unit, "pound");
        return { quantity: poundConverted, unit: "pound" };
      }
      return converted;
    }
    default: {
      return converted;
    }
  }
}
