import { useScreen, withScreenContainer } from "../../navigation/ScreenContainer";
import { ScreenView, useScreenElementDimensions } from "../../components/ScreenView";
import { useCallback, useMemo, useRef } from "react";
import { navTree } from "../../navigation/NavTree";
import { TBody, THeading2, TSecondary } from "../../components/Typography";
import { FlatList, ListRenderItem, View } from "react-native";
import { TextLink } from "../../components/TextLink";
import { Spacer } from "../../components/Spacer";
import { globalStyleConstants } from "../../components/GlobalStyles";
import { Separator } from "../../components/Separator";
import { VideoSource } from "../../components/Video";
import { VideoWithPhoneBezel } from "../../components/VideoWithPhoneBezel";
import { getVideoUrl } from "../../components/Photo";
import { useScreenTimerWithScreenViewEvent } from "../../lib/util/UseScreenTimer";

const strings = {
  headline: "Save Recipes from Social Apps",
  intro: {
    firstParagraph: [
      "Just like ",
      "adding recipes from websites",
      ", you can use the share icon from many social apps to add recipes to Deglaze. Examples for ",
      "Instagram",
      ", ",
      "TikTok",
      ", ",
      "Facebook",
      ", and ",
      "Pinterest",
      " are below.",
    ],
    secondParagraph: ["If you don't see Deglaze, do this ", "one-time 30-second setup", "."],
    thirdParagraph: [
      "One thing to keep in mind: We can only provide X-Ray mode for posts that contain a recipe in the caption, or that have a link to a recipe in the caption. We can’t currently process recipes in videos or comments.",
    ],
  } as const,
  sections: [
    {
      key: "instagram",
      title: "Instagram",
      videoId: "add_recipe_instagram",
    },
    {
      key: "tikTok",
      title: "TikTok",
      videoId: "add_recipe_tiktok",
    },
    {
      key: "facebook",
      title: "Facebook",
      videoId: "add_recipe_facebook",
    },
    {
      key: "pinterest",
      title: "Pinterest",
      videoId: "add_recipe_pinterest",
    },
  ] as const,
};

type RenderListPart = (props: { highlighted?: boolean; leadingItem?: string[] }) => React.ReactElement;
type RenderItem = ListRenderItem<(typeof strings)["sections"][number]>;
type KeyExtractor = (item: (typeof strings)["sections"][number], index: number) => string;

export const HowToAddSocialRecipesScreen = withScreenContainer("HowToAddSocialRecipeScreen", () => {
  const screen = useScreen();
  const { headerHeight, bottomTabBarHeight } = useScreenElementDimensions();

  useScreenTimerWithScreenViewEvent();

  const listRef = useRef<FlatList>(null);

  const onPressWebsites = useCallback(() => {
    screen.nav.goTo("push", navTree.get.screens.howToAddBrowserRecipe);
  }, [screen.nav.goTo]);

  const onPressShareExtensionSetup = useCallback(() => {
    screen.nav.modal(navTree.get.screens.onboardShareExtensionFirstTime);
  }, [screen.nav.modal]);

  const onPressSectionLink = useCallback(
    (key: (typeof strings)["sections"][number]["key"]) => {
      const index = strings.sections.findIndex(i => i.key === key);
      listRef.current?.scrollToIndex({ index, viewOffset: headerHeight });
    },
    [listRef, headerHeight]
  );

  const renderHeader: RenderListPart = useCallback(() => {
    return (
      <View>
        <TSecondary>
          <TSecondary>{strings.intro.firstParagraph[0]}</TSecondary>
          <TextLink text={strings.intro.firstParagraph[1]} onPress={onPressWebsites} />
          <TSecondary>{strings.intro.firstParagraph[2]}</TSecondary>
          <TextLink text={strings.intro.firstParagraph[3]} onPress={() => onPressSectionLink("instagram")} />
          <TSecondary>{strings.intro.firstParagraph[4]}</TSecondary>
          <TextLink text={strings.intro.firstParagraph[5]} onPress={() => onPressSectionLink("tikTok")} />
          <TSecondary>{strings.intro.firstParagraph[6]}</TSecondary>
          <TextLink text={strings.intro.firstParagraph[7]} onPress={() => onPressSectionLink("facebook")} />
          <TSecondary>{strings.intro.firstParagraph[8]}</TSecondary>
          <TextLink text={strings.intro.firstParagraph[9]} onPress={() => onPressSectionLink("pinterest")} />
          <TSecondary>{strings.intro.firstParagraph[10]}</TSecondary>
        </TSecondary>
        <Spacer vertical={1} />
        <TSecondary>
          <TSecondary>{strings.intro.secondParagraph[0]}</TSecondary>
          <TextLink text={strings.intro.secondParagraph[1]} onPress={onPressShareExtensionSetup} />
          <TSecondary>{strings.intro.secondParagraph[2]}</TSecondary>
        </TSecondary>
        <Spacer vertical={1} />
        <TSecondary>{strings.intro.thirdParagraph[0]}</TSecondary>
        <Spacer vertical={1.5} />
        <Separator orientation="row" />
        <Spacer vertical={1} />
      </View>
    );
  }, [onPressWebsites, onPressSectionLink]);

  const renderItem: RenderItem = useCallback(({ item }) => {
    return <HowToAddRecipesSection sectionKey={item.key} />;
  }, []);

  const keyExtractor: KeyExtractor = useCallback(item => {
    return item.key;
  }, []);

  const renderItemSeparator: RenderListPart = useCallback(() => {
    return (
      <>
        <Spacer vertical={1} />
        <Separator orientation="row" />
        <Spacer vertical={1} />
      </>
    );
  }, []);

  return (
    <ScreenView
      header={{
        type: "default",
        title: () => <TBody fontWeight="medium">{strings.headline}</TBody>,
        backgroundColor: "white",
      }}
      scrollView={false}
      paddingHorizontal={false}
      paddingVertical={false}
    >
      <FlatList
        ref={listRef}
        data={strings.sections}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        ListHeaderComponent={renderHeader}
        ItemSeparatorComponent={renderItemSeparator}
        contentContainerStyle={{
          paddingTop: headerHeight + globalStyleConstants.unitSize,
          paddingBottom: bottomTabBarHeight + 6 * globalStyleConstants.unitSize,
          paddingHorizontal: globalStyleConstants.defaultPadding,
        }}
      />
    </ScreenView>
  );
});

const HowToAddRecipesSection = (props: { sectionKey: (typeof strings)["sections"][number]["key"] }) => {
  const section = strings.sections.find(i => i.key === props.sectionKey)!;

  const videoSource = useMemo<VideoSource>(() => {
    return {
      type: "remote",
      url: getVideoUrl(`videos/${section.videoId}.mp4`),
    };
  }, [section]);

  return (
    <View style={{ height: 600, alignItems: "center" }}>
      <THeading2 fontWeight="medium">{section.title}</THeading2>
      <VideoWithPhoneBezel videoAnalyticsId={section.videoId} videoSource={videoSource} tapToExpand />
    </View>
  );
};
