import React, { useEffect } from "react";
import Animated, { useAnimatedStyle, useDerivedValue, useSharedValue, withTiming } from "react-native-reanimated";
import { IconChevronRight } from "./Icons";
import { StyleSheet, View } from "react-native";
import { switchReturn } from "@eatbetter/common-shared";

interface Props {
  isRotated: boolean;
  color?: string;
  size?: number;
  startPosition?: "up" | "down" | "right";
}

export const AnimatedChevron = React.memo((props: Props) => {
  const rotateConfig = switchReturn(props.startPosition ?? "right", {
    right: {
      initialPositionStyle: {},
      rotateAmount: 90,
    },
    up: {
      initialPositionStyle: styles.up,
      rotateAmount: 180,
    },
    down: {
      initialPositionStyle: styles.down,
      rotateAmount: -180,
    },
  });

  const isRotated = useSharedValue(props.isRotated);

  useEffect(() => {
    isRotated.value = props.isRotated;
  }, [isRotated, props.isRotated]);

  const value = props.isRotated;

  const progress = useDerivedValue(() => {
    if (value) {
      return withTiming(1, undefined);
    }

    return withTiming(0);
  }, [value]);

  const chevronAnimation = useAnimatedStyle(() => {
    return {
      transform: [{ rotate: `${progress.value * rotateConfig.rotateAmount}deg` }],
    };
  }, [progress, rotateConfig.rotateAmount]);

  return (
    <Animated.View style={chevronAnimation}>
      <View style={rotateConfig.initialPositionStyle}>
        <IconChevronRight size={props.size} color={props.color} opacity="opaque" />
      </View>
    </Animated.View>
  );
});

const styles = StyleSheet.create({
  up: { transform: [{ rotate: "270deg" }] },
  down: { transform: [{ rotate: "90deg" }] },
});
