import { AdminBook, BookId } from "@eatbetter/recipes-shared";
import { TSecondary } from "@eatbetter/ui-shared";
import { Select, Spin } from "antd";
import { debounce } from "lodash";
import React, { useState, useRef, useMemo, useCallback, useEffect } from "react";
import { searchUsers } from "../lib/users/AdminUserThunks";
import { useDispatch } from "../lib/AdminRedux";
import { AnonymousUser, DeglazeUser, isAnonymousUser } from "@eatbetter/users-shared";
import { UserId } from "@eatbetter/common-shared";
import { findBooks } from "../lib/books/AdminBooksThunks";

interface BaseProps {
  onClearSearch?: () => void;
}

interface SearchSingleSelectProps<T> extends BaseProps {
  mode: "single";
  selected: T | undefined;
  onChange: (selected: T | undefined) => void;
}

interface SearchMultiSelectProps<T> extends BaseProps {
  mode: "multi";
  selected: T[];
  onChange: (selected: T[]) => void;
}

type SearchSelectProps<T> = SearchSingleSelectProps<T> | SearchMultiSelectProps<T>;

/**
 *  USER SELECT
 */
const getUserOption = (u: DeglazeUser | AnonymousUser): SearchSelectItem<UserId> => {
  if (isAnonymousUser(u)) {
    return {
      label: `Anonymous (${u.userId})`,
      value: u.userId,
    };
  } else {
    return {
      label: `${u.name} (${u.username})`,
      value: u.userId,
    };
  }
};

const getUserId = (u: DeglazeUser | AnonymousUser) => u.userId;

export const UserSearchSelect = React.memo((props: SearchSelectProps<DeglazeUser | AnonymousUser>) => {
  const dispatch = useDispatch();

  const fetchUserList = useCallback(
    async (query: string) => {
      if (!query) {
        return [];
      }

      const resp = await dispatch(searchUsers({ query, context: undefined }));
      return resp.data?.users ?? [];
    },
    [dispatch]
  );

  return (
    <SearchSelect
      fetchItems={fetchUserList}
      childProps={props}
      placeholder="Search Users"
      getId={getUserId}
      getOption={getUserOption}
    />
  );
});

/**
 * BOOK SELECT
 */

const getBookOption = (b: AdminBook): SearchSelectItem<BookId> => ({
  label: `${b.title} (${b.primaryAuthor?.name ?? "<no author>"})${b.archived ? " ARCHIVED" : ""}`,
  value: b.id,
});
const getBookId = (b: AdminBook) => b.id;

type BookSearchSelectProps = SearchSelectProps<AdminBook> & { searchArchived?: boolean };

export const BookSearchSelect = React.memo((props: BookSearchSelectProps) => {
  const dispatch = useDispatch();
  const fetchBooks = useCallback(
    async (query: string) => {
      if (!query) {
        return [];
      }

      const results = await dispatch(findBooks({ query }));

      return results.data?.books.filter(i => !!i.archived === !!props.searchArchived) ?? [];
    },
    [dispatch, props.searchArchived]
  );

  return (
    <SearchSelect
      childProps={props}
      placeholder="Search Books"
      fetchItems={fetchBooks}
      getId={getBookId}
      getOption={getBookOption}
    />
  );
});

interface SearchSelectItem<T> {
  key?: string;
  label: React.ReactNode;
  value: T;
}

interface InternalSearchSelectProps<TItem, TItemId extends string> {
  childProps: SearchSelectProps<TItem>;
  getOption: (i: TItem) => SearchSelectItem<TItemId>;
  getId: (i: TItem) => TItemId;
  fetchItems: (search: string) => Promise<TItem[]>;
  debounceTimeout?: number;
  placeholder: string;
}

function SearchSelect<TItem, TItemId extends string>(props: InternalSearchSelectProps<TItem, TItemId>) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<SearchSelectItem<TItemId>[]>([]);
  const fetchRef = useRef(0);
  const itemMap = useRef<Record<TItemId, TItem>>({} as Record<TItemId, TItem>).current;

  useEffect(() => {
    // if the caller initially passes in one or more items, initialize the map with them
    getSelectedItems(props.childProps.selected).forEach(i => (itemMap[props.getId(i)] = i));
  }, []);

  const debounceTimeout = props.debounceTimeout ?? 200;

  const debounceFetcher = useMemo(() => {
    const loadOptions = async (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      const newItems = await props.fetchItems(value);
      if (fetchId !== fetchRef.current) {
        // for fetch callback order
        return;
      }

      newItems.forEach(i => (itemMap[props.getId(i)] = i));

      const newOptions = newItems.map(props.getOption);
      setOptions(newOptions);
      setFetching(false);
    };

    return debounce(loadOptions, debounceTimeout);
  }, [props.fetchItems, debounceTimeout]);

  const onChange = useCallback(
    (updated: SearchSelectItem<TItemId> | SearchSelectItem<TItemId>[] | undefined) => {
      if (props.childProps.mode === "single") {
        if (Array.isArray(updated)) {
          throw new Error("Shouldn't get array in single mode");
        }
        const item = updated ? itemMap[updated.value] : undefined;

        if (updated && !item) {
          throw new Error(`No item in map for ${updated.value}. This shouldn't be possible`);
        }
        props.childProps.onChange(item);
      } else {
        if (!Array.isArray(updated)) {
          throw new Error("Expected array in multi mode");
        }

        const items = updated
          .map(i => i.value)
          .map(itemId => {
            const item = itemMap[itemId];
            if (!item) {
              throw new Error(`No item in map for ${itemId}. This shouldn't be possible`);
            }
            return item;
          });

        props.childProps.onChange(items);
      }
    },
    [props.childProps.mode, props.childProps.onChange]
  );

  const value = useMemo(() => {
    return getSelectedItems(props.childProps.selected).map(props.getOption);
  }, [props.childProps.selected]);

  return (
    <Select
      value={value}
      onChange={onChange}
      onClear={props.childProps.onClearSearch}
      labelInValue
      showSearch
      allowClear
      showArrow={false}
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : <TSecondary>{"No results."}</TSecondary>}
      mode={props.childProps.mode === "single" ? undefined : "multiple"}
      options={options}
      placeholder={props.placeholder}
      style={{ width: "100%" }}
    />
  );
}

function getSelectedItems<TItem>(s: TItem | TItem[] | undefined): TItem[] {
  if (s === undefined) {
    return [];
  }

  if (Array.isArray(s)) {
    return s;
  }

  return [s];
}
