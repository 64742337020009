import React, { useCallback, useImperativeHandle, useRef } from "react";
import SliderInternal from "@react-native-community/slider";
import { StyleSheet } from "react-native";
import { Haptics } from "./Haptics";
import { globalStyleColors } from "./GlobalStyles";

interface SliderProps {
  initialValue?: number;
  minValue: number;
  maxValue: number;
  stepCount: number;
  onValueChange: (value: number) => void;
}

export interface SliderImperativeHandle {
  /**
   * The slider component is NOT a controlled component. To programmatically set the value of the slider,
   * call this function.
   */
  setSliderValue: (value: number) => void;
}

export const Slider = React.forwardRef<SliderImperativeHandle, SliderProps>((props, ref) => {
  // Types unfortunately seem busted on the package (or are more complex than I comprehend)
  const sliderRef = useRef<any>(null);

  const onValueChange = useCallback(
    (value: number) => {
      Haptics.feedback("itemStatusChanged");
      props.onValueChange(value);
    },
    [props.onValueChange]
  );

  useImperativeHandle(ref, () => ({
    setSliderValue: (value: number) => {
      if (sliderRef.current && sliderRef.current.setNativeProps) {
        sliderRef.current.setNativeProps({ value });
      }
    },
  }));

  return (
    <SliderInternal
      ref={sliderRef}
      style={styles.slider}
      value={props.initialValue ?? props.minValue}
      step={(props.maxValue - props.minValue) / props.stepCount}
      minimumValue={props.minValue}
      maximumValue={props.maxValue}
      tapToSeek
      minimumTrackTintColor={globalStyleColors.colorAccentCool}
      maximumTrackTintColor={globalStyleColors.colorGreyDark}
      onValueChange={onValueChange}
    />
  );
});

const styles = StyleSheet.create({
  slider: {
    flex: 1,
    height: 48,
  },
});
