import { Platform, StyleSheet } from "react-native";
import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { globalStyleColors, globalStyleConstants } from "../components/GlobalStyles";
import { TextInputHandle } from "../components/TextInput";
import { useCallback, useEffect, useRef, useState } from "react";
import { Spacer } from "../components/Spacer";
import { ScreenView } from "../components/ScreenView";
import { InputAccessoryView } from "../components/InputAccessoryView";
import { BottomActionBar, bottomActionBarConstants } from "../components/BottomActionBar";
import { useScreenHeaderDimensions } from "../components/ScreenHeaders";
import { ScrollView } from "react-native-gesture-handler";
import { EnterPasswordScreenProps } from "../navigation/NavTree";
import { signInWithEmailAndPassword } from "../lib/system/SystemThunks";
import { useDispatch } from "../lib/redux/Redux";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { useKeyboardLayoutAnimation } from "../components/Keyboard";
import { FormValidationError } from "../components/FormValidationError";
import { navHome } from "../navigation/NavThunks";
import { FormInput } from "../components/FormInput";

const strings = {
  screenHeader: "Enter password",
  submit: "Continue",
  passwordLabel: "Password",
  incorrect: "Email or password is incorrect",
};

export const EnterPasswordScreen = withNonNavigableScreenContainer(
  "EnterPasswordScreen",
  (props: EnterPasswordScreenProps) => {
    const screen = useScreen();
    const dispatch = useDispatch();
    const { headerHeight } = useScreenHeaderDimensions();
    const keyboardHeight = useKeyboardLayoutAnimation();
    const textInputRef = useRef<TextInputHandle>(null);

    const [waiting, setWaiting] = useState(false);
    const [password, setPassword] = useState("");
    const [incorrect, setIncorrect] = useState(false);

    const onSubmit = useCallback(() => {
      textInputRef.current?.blur();

      setWaiting(true);
      dispatch(signInWithEmailAndPassword(props.email, password))
        .then(res => {
          if (res) {
            navHome(screen.nav, "EnterPasswordScreen");
            return;
          } else {
            setIncorrect(true);
          }
        })
        .catch(err => {
          displayUnexpectedErrorAndLog("Unexpected error in EnterPasswordScreen.onSubmit", err);
        })
        .finally(() => setWaiting(false));
    }, [password, screen.nav, dispatch]);

    const onChangePassword = useCallback(
      (value: string) => {
        setIncorrect(false);
        setPassword(value);
      },
      [setIncorrect, setPassword]
    );

    useEffect(() => {
      if (Platform.OS === "web") {
        // Safari + Chrome disable focus requests without user interaction, so it ends up in a
        // 'half' state where some animations trigger but there's no keyboard.
        return;
      }

      if (screen.nav.focused) {
        setTimeout(() => textInputRef.current?.focus(), 650);
        return;
      }

      textInputRef.current?.blur();
    }, [screen.nav.focused]);

    return (
      <ScreenView
        scrollView={false}
        paddingVertical={false}
        paddingHorizontal={false}
        header={{ type: "default", title: strings.screenHeader, backgroundColor: "transparent" }}
      >
        <ScrollView
          keyboardShouldPersistTaps="always"
          contentContainerStyle={[
            styles.contentWrap,
            {
              paddingTop: headerHeight + globalStyleConstants.unitSize,
              paddingBottom: keyboardHeight + bottomActionBarConstants.height + 2 * globalStyleConstants.unitSize,
            },
          ]}
        >
          <FormInput
            ref={textInputRef}
            label={strings.passwordLabel}
            value={password}
            onChangeText={onChangePassword}
            textContentType="password"
            keyboardType="default"
            autoCapitalize="none"
            isReadOnly={waiting}
            showClearButton={!waiting}
            onSubmitEditing={onSubmit}
          />
          <Spacer vertical={1.5} />
          {incorrect && <FormValidationError message={strings.incorrect} />}
        </ScrollView>
        <InputAccessoryView backgroundColor="transparent">
          <BottomActionBar
            primaryAction={{
              actionText: strings.submit,
              onPressAction: onSubmit,
              disabled: password.length < 7,
              waiting,
              singlePress: true,
            }}
            containerBackgroundColor="transparent"
            disableSnapToBottom
          />
        </InputAccessoryView>
      </ScreenView>
    );
  }
);

const styles = StyleSheet.create({
  contentWrap: {
    paddingHorizontal: 20,
  },
  emailInput: {
    paddingTop: 8,
    paddingBottom: 8,
    borderBottomColor: globalStyleColors.rgba("black", "xlight"),
    borderBottomWidth: 1,
  },
  emailInvalid: {
    borderBottomColor: globalStyleColors.colorAccentWarm,
  },
});
