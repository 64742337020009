import { bottomThrow } from "@eatbetter/common-shared";
import { log } from "../../../Log";

/**
 * Represents a known precise unit, e.g. "gram"
 */
export type PreciseUnit =
  // mass
  | "milligram"
  | "gram"
  | "kilogram"
  | "ounce"
  | "pound"

  // volume
  | "milliliter"
  | "deciliter"
  | "liter"
  | "US fluid ounce"
  | "teaspoon"
  | "tablespoon"
  | "cup"
  | "pint"
  | "quart"
  | "gallon"

  // length
  | "inch"
  | "foot"
  | "yard"
  | "millimeter"
  | "centimeter"
  | "meter"

  // temperature
  | "celsius"
  | "fahrenheit";

/**
 * Parses the given raw unit string and returns a `PreciseUnit` if parsed, otherwise `undefined`.
 */
export function toPreciseUnit(rawUnit: string): PreciseUnit | undefined {
  switch (rawUnit) {
    case "mg":
    case "milligram":
      return "milligram";
    case "g":
    case "gram":
      return "gram";
    case "kg":
    case "kilogram":
      return "kilogram";
    case "oz":
    case "ounce":
      return "ounce";
    case "lb":
    case "pound":
      return "pound";
    case "mL":
    case "milliliter":
      return "milliliter";
    case "L":
    case "liter":
      return "liter";
    case "fl oz":
    case "fluid ounce":
    case "US fluid ounce":
      return "US fluid ounce";
    case "tsp":
    case "teaspoon":
      return "teaspoon";
    case "tbsp":
    case "tablespoon":
      return "tablespoon";
    case "cup":
      return "cup";
    case "pt":
    case "pint":
      return "pint";
    case "qt":
    case "quart":
      return "quart";
    case "gal":
    case "gallon":
      return "gallon";
    case "in":
    case "inch":
      return "inch";
    case "ft":
    case "foot":
      return "foot";
    case "yd":
    case "yard":
      return "yard";
    case "mm":
    case "millimeter":
      return "millimeter";
    case "cm":
    case "centimeter":
      return "centimeter";
    case "m":
    case "meter":
    case "metre":
      return "meter";
    case "C":
    case "celsius":
      return "celsius";
    case "F":
    case "fahrenheit":
      return "fahrenheit";
    default: {
      log.error("getUnitDisplayMetadata(): unexpected unit", { unit: rawUnit });
      return undefined;
    }
  }
}

/**
 * Returns a UI-friendly representation of the given `PreciseUnit`. Some units require a space between the quantity
 * and unit to display correctly (e.g. 1 pound) - this is what the `quHasSpaceBetween` is for: if the source text
 * already has a space, then no space is added. If it doesn't have a space already, a space is added if the specified
 * unit requires one.
 */
export function getPreciseUnitDisplay(args: {
  unit: PreciseUnit;
  quantity: number;
  quHasSpaceBetween?: boolean;
}): string {
  const isPlural = args.quantity > 1;
  const quHasSpaceBetween = !!args.quHasSpaceBetween;

  switch (args.unit) {
    case "milligram":
      return "mg";
    case "gram":
      return "g";
    case "kilogram":
      return "kg";
    case "ounce":
      return "oz";
    case "pound":
      return `${quHasSpaceBetween ? "" : " "}${isPlural ? "pounds" : "pound"}`;
    case "milliliter":
      return "mL";
    case "deciliter":
      return "dL";
    case "liter":
      return "L";
    case "US fluid ounce":
      return `${quHasSpaceBetween ? "" : " "}fl oz`;
    case "teaspoon":
      return `${quHasSpaceBetween ? "" : " "}tsp`;
    case "tablespoon":
      return `${quHasSpaceBetween ? "" : " "}tbsp`;
    case "cup":
      return `${quHasSpaceBetween ? "" : " "}${isPlural ? "cups" : "cup"}`;
    case "pint":
      return `${quHasSpaceBetween ? "" : " "}${isPlural ? "pints" : "pint"}`;
    case "quart":
      return `${quHasSpaceBetween ? "" : " "}${isPlural ? "quarts" : "quart"}`;
    case "gallon":
      return `${quHasSpaceBetween ? "" : " "}${isPlural ? "gallons" : "gallon"}`;
    case "inch":
      return "in";
    case "foot":
      return `${quHasSpaceBetween ? "" : " "}${isPlural ? "feet" : "foot"}`;
    case "yard":
      return `${quHasSpaceBetween ? "" : " "}${isPlural ? "yards" : "yard"}`;
    case "millimeter":
      return "mm";
    case "centimeter":
      return "cm";
    case "meter":
      return "m";
    case "celsius":
      return "°C";
    case "fahrenheit":
      return "°F";
    default: {
      bottomThrow(args.unit);
    }
  }
}
