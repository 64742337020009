import React, { PropsWithChildren, ReactElement, ReactNode, useCallback } from "react";
import { globalStyleColors } from "./GlobalStyles";
import { Linking, Text, TextStyle } from "react-native";
import { log } from "../Log";
import { useDispatch } from "../lib/redux/Redux";
import { ExternalLinkLocation, reportExternalLinkOpened } from "../lib/analytics/AnalyticsEvents";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import linkifyit from "linkify-it";

export const AutoLink = React.memo((props: PropsWithChildren<{ location: ExternalLinkLocation }>) => {
  const dispatch = useDispatch();
  const handle = useCallback(
    (url: string) => {
      const event = reportExternalLinkOpened({ url, location: props.location });
      dispatch(analyticsEvent(event));
      Linking.openURL(url).catch(err => {
        log.errorCaught(`Encountered error calling Linking.openURL for ${url}`, err);
      });
    },
    [dispatch, props.location]
  );

  return (
    <Hyperlink linkStyle={{ color: globalStyleColors.colorTextLink }} linkText={shortenLink} onPress={handle}>
      {props.children}
    </Hyperlink>
  );
});

type HyperlinkProps = {
  children: ReactNode;
  onPress: (url: string) => void;
  linkStyle?: TextStyle;
  linkText?: (url: string) => string;
};

const linkify = linkifyit();

export const Hyperlink = (props: HyperlinkProps) => {
  const parseText = (node: ReactNode): ReactNode => {
    if (typeof node === "string") {
      return linkifyText(node);
    }

    if (React.isValidElement(node) && node.type === Text) {
      const element = node as ReactElement<{ children: ReactNode }>;
      return React.cloneElement(element, element.props, linkifyText(element.props.children));
    }

    if (isReactElementWithChildren(node)) {
      return React.cloneElement(node, node.props, React.Children.map(node.props.children, parseText));
    }

    return node;
  };

  const linkifyText = (text: string | ReactNode): ReactNode => {
    if (typeof text !== "string") {
      return text;
    }

    const elements: ReactNode[] = [];
    let lastIndex = 0;

    linkify.match(text)?.forEach(match => {
      const { index, lastIndex: matchLastIndex, url } = match;
      elements.push(text.slice(lastIndex, index));
      elements.push(
        <Text
          style={props.linkStyle}
          onPress={() => props.onPress(url)}
          key={url + index}
          suppressHighlighting
          allowFontScaling={false}
        >
          {props.linkText ? props.linkText(url) : url}
        </Text>
      );
      lastIndex = matchLastIndex;
    });

    elements.push(text.slice(lastIndex));
    return elements.length === 1 ? elements[0] : elements;
  };

  return <>{React.Children.map(props.children, parseText)}</>;
};

function isReactElementWithChildren(node: any): node is ReactElement<{ children: ReactNode }> {
  return React.isValidElement(node) && !!node.props && typeof node.props === "object" && "children" in node.props;
}

function shortenLink(url: string) {
  try {
    const u = new URL(url);
    const queryIndex = url.indexOf("?");
    const searchAndHashLength = queryIndex > 0 ? url.length - queryIndex : 0;
    const ellipsize = searchAndHashLength > 6;
    const shortened = `${u.host.toLowerCase()}${u.pathname.length > 1 ? u.pathname : ""}${ellipsize ? "..." : ""}`;
    return shortened;
  } catch (err) {
    return url;
  }
}
