import { useLocation, useParams } from "react-router-dom";

export interface OptionalOrRequired<T> {
  optional(): T | undefined;
  required(): T;
}

export const useStringParam = <T extends string = string>(param: string): OptionalOrRequired<T> => {
  if (!param.startsWith(":")) {
    throw new Error("Expecting param name including preceding :");
  }

  const key = param.substring(1);
  const params = useParams();

  const value = params[key] as T;

  return {
    optional(): T | undefined {
      return value;
    },

    required(): T {
      if (value === undefined) {
        throw new Error(`Param ${key} not present in ${JSON.stringify(params)}`);
      }

      return value;
    },
  };
};

export const useQueryStringParam = <T extends string = string>(paramName: string): OptionalOrRequired<T> => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const value = params.get(paramName) as T;
  return {
    optional(): T | undefined {
      return value ? value : undefined;
    },

    required(): T {
      if (!value) {
        throw new Error(`Param ${paramName} not present in ${location.search}`);
      }

      return value;
    },
  };
};
