import { PropsWithChildren } from "react";
import { ContainerPadded } from "@eatbetter/ui-shared";

export const AdminScreenView = (props: PropsWithChildren<{}>) => {
  return (
    <ContainerPadded all={3} top={0} style={{ flex: 1 }}>
      {props.children}
    </ContainerPadded>
  );
};
