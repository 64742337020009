import { View, Image } from "react-native";
import { defaultScreenWidth, useResponsiveDimensions } from "./Responsive";
import { useScreenHeaderDimensions } from "./ScreenHeaders";
import { ContainerFadeIn } from "./Containers";
import { Pressable } from "./Pressable";
import { IconChevronLeft } from "./Icons";
import { globalStyleColors } from "./GlobalStyles";

const deglazeLogo = require("../assets/images/deglaze_logo_overlay.png");

const constants = {
  defaultHeight: 64,
};

export function getDeglazeLogoOverlayHeight(screenWidth: number): number {
  return Math.min(96, (screenWidth / defaultScreenWidth) * constants.defaultHeight);
}

export const DeglazeLogoOverlay = (props: { onPressBack?: () => void }) => {
  const { width: screenWidth } = useResponsiveDimensions();
  const { statusBarHeight } = useScreenHeaderDimensions();

  const height = getDeglazeLogoOverlayHeight(screenWidth);

  return (
    <View style={{ position: "absolute", top: statusBarHeight, left: 0, right: 0, height, zIndex: 100 }}>
      <Image resizeMode="contain" source={deglazeLogo} style={{ width: "100%", height: "100%" }} />
      {!!props.onPressBack && (
        <ContainerFadeIn
          style={{ position: "absolute", top: 0, bottom: 0, left: 0, width: 48, justifyContent: "center" }}
        >
          <BackButton onPress={props.onPressBack} />
        </ContainerFadeIn>
      )}
    </View>
  );
};

const BackButton = (props: { onPress: () => void }) => {
  const { width: screenWidth } = useResponsiveDimensions();

  const defaultSize = 36;
  const scale = Math.min(1.5, screenWidth / defaultScreenWidth);
  const size = scale * defaultSize;

  return (
    <Pressable onPress={props.onPress} singlePress>
      <IconChevronLeft size={size} color={globalStyleColors.white} opacity="opaque" />
    </Pressable>
  );
};
