import {
  defaultTimeProvider,
  EpochMs,
  getDurationInSeconds,
  getFormattedDuration,
  TimeProvider,
} from "@eatbetter/common-shared";
import { CookingTimerId, RecipeCookingTimer } from "./CookingSessionsSlice";
import { useCookingTimer, useNextCookingTimerId } from "./CookingSessionsSelectors";
import { log } from "../../Log";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "../redux/Redux";
import { timerMounted, timerUnmounted } from "./CookingTimerThunks";

export interface CookingTimerStatus {
  cookingTimerId: CookingTimerId;
  defaultDisplay: string;
  timeRemainingDisplay: string;
  timeElapsedDisplay: string;
  secondsRemaining: number;
  lastEvaluatedTick: EpochMs;
  status: CookingTimerState;
  alerting: boolean;

  /**
   * A number between 0 and 100 representing the percentage of time that has elapsed
   * between the timer start time and the complete time.
   */
  percentComplete: number;
}

export type CookingTimerState = "running" | "paused" | "complete";
export const useNextCookingTimerStatus = (): CookingTimerStatus | undefined => {
  const cookingTimerId = useNextCookingTimerId();
  return useCookingTimerStatus(cookingTimerId);
};

/**
 * Shows the timer status bar whenever there is a timer
 */
export const useShowTimerStatusBar = () => useSelector(s => s.cookingSessions.timers.ids.length > 0);

export function getCookingTimerStatus(timer: RecipeCookingTimer, tick: EpochMs): CookingTimerStatus {
  // if the timer is paused, we show the duration remaining
  const msRemaining = Math.max(timer.endTime - (timer.pausedTime ?? tick), 0);
  const msElapsed = tick - timer.startTime;

  const duration = { milliseconds: msRemaining };
  const secondsRemaining = getDurationInSeconds(duration);
  const timeRemainingDisplay = getFormattedDuration(duration);
  const timeElapsedDisplay = getFormattedDuration({ milliseconds: msElapsed });

  const status = msRemaining === 0 ? "complete" : timer.pausedTime ? "paused" : "running";

  const alerting = status === "complete" && !timer.alertAcknowledged;

  const percentComplete =
    status === "complete" ? 100 : Math.floor((msElapsed * 100) / (timer.endTime - timer.startTime));

  return {
    cookingTimerId: timer.id,
    timeRemainingDisplay,
    timeElapsedDisplay,
    status,
    alerting,
    defaultDisplay: timeRemainingDisplay,
    lastEvaluatedTick: tick,
    secondsRemaining,
    percentComplete,
  };
}

export const useCookingTimerStatus = (cookingTimerId?: CookingTimerId): CookingTimerStatus | undefined => {
  const dispatch = useDispatch();
  const timer = useCookingTimer(cookingTimerId);

  // this just forces a re-render
  const [tick, setTick] = useState(0 as EpochMs);

  useEffect(() => {
    if (cookingTimerId) {
      const lastTick = dispatch(timerMounted(setTick));
      setTick(lastTick);
    }

    return () => dispatch(timerUnmounted(setTick));
  }, [setTick, cookingTimerId]);

  if (!timer || !tick) {
    return undefined;
  }

  // use the tick time and not the actual time to prevent the clock from jumping when being reevaluated
  // because of the timer being started/paused. in other words, we don't want the time remaining evaluated
  // every render, only every tick. This might cause problems if the tick isn't steady.
  return getCookingTimerStatus(timer, tick);
};

export class SecondTimer {
  private currentAdjustment = 0;
  private lastDeltas = [0];
  private isRunning = false;
  private nextExpected = 0;
  private handle: ReturnType<typeof setTimeout> | undefined;
  private readonly sampleCount = 5;
  private readonly timeProvider: TimeProvider;
  private callbacks: Array<(n: EpochMs) => void> = [];

  constructor(timeProvider?: TimeProvider) {
    this.timeProvider = timeProvider ?? defaultTimeProvider;
  }

  start(): void {
    if (this.isRunning) {
      return;
    }

    this.isRunning = true;
    const time = this.timeProvider();
    const nextExpected = time - (time % 1000) + 1000;

    // if the next expected is < 100 ms in the future, just hit the next second after that.
    this.nextExpected = nextExpected - time < 100 ? nextExpected + 1000 : nextExpected;
    this.handle = setTimeout(this.tick, nextExpected - time);
  }

  /**
   * Currently, this should probably not be used. As it stands, if the timer is stopped and a new timer is added, the registerCallback
   * will return the wrong nextExpected value and everything will be wonky, especially in the case nextExpected is zero.
   * Leaving this here for the moment as we might need to call this when the app reloads during development to avoid
   * a bunch of duplicate timers
   */
  stop(): void {
    this.isRunning = false;
    if (this.handle) {
      clearTimeout(this.handle);
    }
  }

  registerCallback(cb: (n: EpochMs) => void): EpochMs {
    if (!this.callbacks.includes(cb)) {
      this.callbacks.push(cb);
    }

    // next expected needs to be set for everything to work correctly
    if (!this.isRunning) {
      this.start();
    }

    // return the last tick
    // This assumes that the timer is running and
    // nextExpected is set.
    return (this.nextExpected - 1000) as EpochMs;
  }

  unregisterCallback(cb: (n: EpochMs) => void): void {
    this.callbacks = this.callbacks.filter(c => c !== cb);
  }

  private tick = (): void => {
    if (!this.isRunning) {
      return;
    }

    this.callbacks.forEach(cb => cb(this.nextExpected as EpochMs));

    const now = defaultTimeProvider();
    const delta = now - this.nextExpected;

    if (Math.abs(delta) < 100) {
      // add the last delta we used to the delta to get the "true" delta
      // We need this for the calculation of the next adjustment
      const adjustedDelta = delta - this.currentAdjustment;
      this.lastDeltas.push(adjustedDelta);

      if (this.lastDeltas.length > this.sampleCount) {
        this.lastDeltas.splice(0, this.lastDeltas.length - this.sampleCount);
      }
    }

    const avgDelta = Math.floor(this.lastDeltas.reduce((a, b) => a + b) / this.lastDeltas.length);
    this.currentAdjustment = avgDelta * -1;

    // find the next second in the future. In the normal case, this loop should run once.
    do {
      this.nextExpected += 1000;
    } while (this.nextExpected < this.timeProvider());

    const timeout = Math.max(this.nextExpected - now + this.currentAdjustment, 0);
    setTimeout(this.tick, timeout);
  };
}

export function getSecondTimer(cb?: () => void): () => void {
  const time = defaultTimeProvider();
  let nextExpected = time - (time % 1000) + 1000;
  let currentAdjustment = 0;
  const lastDeltas: [number, number, number] = [0, 0, 0];
  let run = false;

  let cumulativeDelta = 0;
  let seconds = 0;

  function tick() {
    cb?.();
    const now = defaultTimeProvider();
    const delta = now - nextExpected;

    if (Math.abs(delta) < 100) {
      cumulativeDelta += delta;
      seconds++;
      // add the last delta we used to the delta to get the "true" delta
      // We need this for the calculation of the next adjustment
      const adjustedDelta = delta - currentAdjustment;
      lastDeltas.push(adjustedDelta);
      lastDeltas.splice(0, 1);
    }

    const avgDelta = Math.floor((lastDeltas[0] + lastDeltas[1] + lastDeltas[2]) / 3);
    currentAdjustment = avgDelta * -1;

    // find the next second in the future. In the normal case, this loop should run once.
    do {
      nextExpected += 1000;
    } while (nextExpected < defaultTimeProvider());

    const timeout = Math.max(nextExpected - now + currentAdjustment, 0);

    const debug = false;
    if (debug) {
      log.info(`Tick Avg Delta Adj ${seconds} ${Math.floor(cumulativeDelta / seconds)} ${delta} ${currentAdjustment}`);
    }

    if (run) {
      setTimeout(tick, timeout);
    }
  }

  if (!run) {
    run = true;
    tick();
  }

  return () => {
    run = false;
  };
}
