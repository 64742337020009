import { switchReturn } from "@eatbetter/common-shared";
import React, { useCallback } from "react";
import { View } from "react-native";
import { usePostIds } from "../../lib/social/SocialSelectors";
import { exploreFeed } from "../../lib/social/SocialSlice";
import { SocialPostComponent } from "./SocialPost";
import { Spacer } from "../Spacer";
import { globalStyleConstants } from "../GlobalStyles";
import { TBody } from "../Typography";
import { ButtonRectangle } from "../Buttons";
import { analyticsEvent } from "../../lib/analytics/AnalyticsThunks";
import { useDispatch } from "../../lib/redux/Redux";
import { ContainerFadeIn } from "../Containers";
import { reportRecommendedPostsViewMoreButtonPressed } from "../../lib/analytics/AnalyticsEvents";

const strings = {
  exploreFeedHeadline: "Need inspiration? Check out these recent posts from other Deglazers:",
  exploreFeedButton: "See more posts in Explore",
};

const config = {
  postCount: 3,
};

type Props = {
  type: "exploreFeedTeaser";
  onPressViewMore: () => void;
};

export const RecommendedPosts = React.memo((props: Props) => {
  const dispatch = useDispatch();
  const exploreFeedPosts = usePostIds(exploreFeed).postIds.slice(0, config.postCount);

  const onPressViewMore = useCallback(() => {
    dispatch(analyticsEvent(reportRecommendedPostsViewMoreButtonPressed()));
    props.onPressViewMore();
  }, [props.onPressViewMore]);

  const headline = switchReturn(props.type, {
    exploreFeedTeaser: strings.exploreFeedHeadline,
  });

  // if we don't have posts yet, don't render so that we prevent thebutton
  // from jumping around when the posts come in and a headline with no posts.
  if (exploreFeedPosts.length === 0) {
    return null;
  }

  return (
    <ContainerFadeIn>
      <Spacer vertical={1.5} />
      <View style={{ paddingHorizontal: globalStyleConstants.defaultPadding }}>
        <TBody fontWeight="medium">{headline}</TBody>
      </View>
      <Spacer vertical={0.5} />
      {exploreFeedPosts.map((i, idx) => {
        return (
          <View key={i}>
            {idx !== 0 && <Spacer vertical={0.5} />}
            <SocialPostComponent postOrId={i} />
          </View>
        );
      })}
      <Spacer vertical={2} />
      <View style={{ paddingHorizontal: globalStyleConstants.defaultPadding }}>
        <ButtonRectangle
          type="submit"
          title={strings.exploreFeedButton}
          onPress={onPressViewMore}
          size="large"
          singlePress
        />
      </View>
      <Spacer vertical={4} />
    </ContainerFadeIn>
  );
});
