export function setDiff<T>(before: T[] | Set<T>, after: T[] | Set<T>): { added: T[]; removed: T[]; intersection: T[] } {
  const beforeSet = Array.isArray(before) ? new Set(before) : before;
  const afterSet = Array.isArray(after) ? new Set(after) : after;

  const added: T[] = [];
  const removed: T[] = [];
  const intersection: T[] = [];

  beforeSet.forEach(i => {
    if (!afterSet.has(i)) {
      removed.push(i);
    }
  });

  afterSet.forEach(i => {
    if (!beforeSet.has(i)) {
      added.push(i);
    } else {
      intersection.push(i);
    }
  });

  return {
    added,
    removed,
    intersection,
  };
}
