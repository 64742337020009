import React, { useState, useEffect, useRef, PropsWithChildren, useCallback, useMemo } from "react";
import { View, StyleSheet, Animated, LayoutAnimation, LayoutRectangle } from "react-native";
import { globalStyleColors } from "./GlobalStyles";
import { useWobbleAnimation } from "./AttentionGrabbers";
import { useResponsiveDimensions } from "./Responsive";
import { switchReturn } from "@eatbetter/common-shared";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { RootSiblingPortal } from "react-native-root-siblings";
import { Pressable } from "./Pressable";
import { ContainerFadeIn } from "./Containers";
import { Tooltip } from "./Tooltip";
import { Haptics } from "./Haptics";
import { useDispatch } from "../lib/redux/Redux";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { reportWalkthroughCompleted, reportWalkthroughStarted } from "../lib/analytics/AnalyticsEvents";

const strings = {
  gotIt: "Got it",
  next: "Next",
};

/**
 * Hook to manage state for a multi-step walkthrough. Render steps using the `WalkthroughStep` component.
 *
 * @param steps - The ordered list of step keys.
 * @param active - Whether the walkthrough should start/be active now.
 * @param onComplete - Optional callback invoked when the user passes the last step.
 */
export function useWalkthrough<T extends string>(
  analyticsId: string,
  steps: readonly T[],
  active: boolean,
  onComplete?: () => void
) {
  const dispatch = useDispatch();
  const [stepIndex, setStepIndex] = useState<number>();

  // If the walkthrough is active and has never started, kick it off at step 0
  useEffect(() => {
    if (active && stepIndex === undefined) {
      dispatch(analyticsEvent(reportWalkthroughStarted(analyticsId)));
      setStepIndex(0);
    }
  }, [active, stepIndex]);

  const currentStep = stepIndex !== undefined ? steps[stepIndex] : undefined;

  const goToNextStep = useCallback(() => {
    if (stepIndex === undefined) {
      // Not started or already undefined, do nothing
      return;
    }

    Haptics.feedback("itemStatusChanged");

    const nextIndex = stepIndex + 1;
    if (nextIndex >= steps.length) {
      onComplete?.();
      dispatch(analyticsEvent(reportWalkthroughCompleted(analyticsId)));
    }
    setStepIndex(nextIndex);
  }, [stepIndex, steps.length, onComplete, dispatch, analyticsId]);

  return useMemo(
    () => ({
      currentStep,
      goToNextStep,
    }),
    [currentStep, goToNextStep]
  );
}

export interface WalkthroughStepProps {
  show: boolean;
  message: string | React.ReactElement;
  buttonText: "gotIt" | "next" | (() => string) | undefined;
  onPressButton?: () => void;
  /**
   * For components that snap to a navigation element (e.g. bottom tab bar), we need to provide position hints because navigation
   * context isn't available when rendering a walkthrough overlay.
   */
  positionHint?: Partial<LayoutRectangle>;
  /**
   * Wobble the component being highlighted? Defaults to true.
   */
  wobble?: boolean;
  /**
   * Constrains the tooltip message container width as percentage of screen width. Note that in center alignment, this value is
   * the absolute width. Defaults to 80%.
   */
  maxWidth?: "50%" | "60%" | "70%" | "80%";
  onPressChildComponent?: () => void;
}

export const WalkthroughStep = (props: PropsWithChildren<WalkthroughStepProps>) => {
  const wobble = useWobbleAnimation({ loop: true, wobbleAmount: "large" });
  const childRef = useRef<View>(null);

  const [childRect, setChildRect] = useState<LayoutRectangle | null>(null);

  // Coalesce position hints with measured values, allowing caller to provide only some of the values
  const targetRect: LayoutRectangle | undefined = useMemo(() => {
    const x = props.positionHint?.x ?? childRect?.x;
    const y = props.positionHint?.y ?? childRect?.y;
    const width = props.positionHint?.width ?? childRect?.width;
    const height = props.positionHint?.height ?? childRect?.height;

    if (x !== undefined && y !== undefined && width !== undefined && height !== undefined) {
      return { x, y, width, height };
    }
    return undefined;
  }, [childRect, props.positionHint]);

  const { width: screenWidth, height: screenHeight } = useResponsiveDimensions();

  // Measures and sets the screen position and dimensions of the wrapped child
  const measureTarget = useCallback(() => {
    if (childRef.current) {
      // There is fluctuation here while the layout engine does multiple passes and we want to avoid re-rendering there, as
      // sometimes it leads to a stale value persisting. This repros on iPad rotations and when the sim is slow. This delay
      // is minor and does not affect the UX, as we typically include a delay intentionally.
      setTimeout(
        () =>
          requestAnimationFrame(() =>
            childRef.current?.measureInWindow((x, y, width, height) => {
              if (width && height) {
                setChildRect({ x, y, width, height });
              }
            })
          ),
        100
      );
    }
  }, []);

  // Re-measure target when dimensions change, i.e. on iPad rotation
  useEffect(() => {
    if (props.show) {
      measureTarget();
    }
  }, [props.show, measureTarget, screenWidth, screenHeight]);

  // Start or stop the wobble animation
  useEffect(() => {
    if (props.show && props.wobble !== false) {
      wobble.startAnimation();
    } else {
      wobble.stopAnimation();
    }
  }, [props.show, wobble]);

  const buttonText =
    typeof props.buttonText === "string"
      ? switchReturn(props.buttonText, {
          gotIt: strings.gotIt,
          next: strings.next,
        })
      : props.buttonText?.();

  // If we're not showing, don't render anything to avoid unnecessary renders + layout measurement
  if (!props.show) {
    return props.children;
  }

  // We hide the child instance behind the overlay so we don't get "double vision" with the one in the foreground.
  // We set opacity to a very small value instead of zero to avoid layout optimizations that may lead to incorrect
  // values when we measure its size/position.
  const childOpacity = props.show && childRect ? 0.01 : 1;

  return (
    <>
      <View ref={childRef} onLayout={measureTarget} style={{ opacity: childOpacity }}>
        {props.children}
      </View>
      {props.show && !!targetRect && (
        <RootSiblingPortal>
          <OverlayWithTooltip
            targetRect={targetRect}
            hints={props.positionHint}
            message={props.message}
            buttonText={buttonText}
            onPressButton={props.onPressButton}
            wobbleStyle={wobble.animatedStyle}
            child={props.children}
            tooltipMaxWidth={props.maxWidth}
            onPressChildComponent={props.onPressChildComponent}
          />
        </RootSiblingPortal>
      )}
    </>
  );
};

const OverlayWithTooltip = (props: {
  targetRect: LayoutRectangle;
  hints?: Partial<LayoutRectangle>;
  message: string | React.ReactElement;
  buttonText?: string;
  onPressButton?: () => void;
  wobbleStyle: ReturnType<typeof useWobbleAnimation>["animatedStyle"];
  child: React.ReactNode;
  tooltipMaxWidth?: WalkthroughStepProps["maxWidth"];
  onPressChildComponent?: () => void;
}) => {
  useEffect(() => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  }, [props.targetRect]);

  return (
    <View style={styles.overlay}>
      <View
        style={{
          position: "absolute",
          left: props.targetRect?.x,
          top: props.targetRect?.y,
          width: props.targetRect?.width,
          height: props.targetRect?.height,
        }}
      >
        <Animated.View style={[StyleSheet.absoluteFill, props.wobbleStyle]}>
          <SafeAreaProvider>{props.child}</SafeAreaProvider>
        </Animated.View>
        {!!props.onPressChildComponent && (
          <Pressable style={StyleSheet.absoluteFill} onPress={props.onPressChildComponent}></Pressable>
        )}
      </View>
      <ContainerFadeIn delay={300}>
        <Tooltip
          target={props.targetRect}
          message={props.message}
          buttonText={props.buttonText}
          onPressButton={props.onPressButton}
          maxWidth={props.tooltipMaxWidth}
        />
      </ContainerFadeIn>
    </View>
  );
};

const styles = StyleSheet.create({
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: globalStyleColors.rgba("black", "medium"),
  },
});
