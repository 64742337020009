import { useCallback, useEffect, useState } from "react";
import { ScreenView } from "../components/ScreenView";
import { PhotoIngesetionTipsScreenProps } from "../navigation/NavTree";
import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import React from "react";
import { ImageSourcePropType, StyleSheet, View } from "react-native";
import { useScreenHeaderDimensions } from "../components/ScreenHeaders";
import { Pressable } from "../components/Pressable";
import { IconCheck, IconChevronLeft, IconEx } from "../components/Icons";
import { globalStyleColors, globalStyleConstants, Opacity } from "../components/GlobalStyles";
import { TabView, TabViewRoute } from "../components/TabView";
import { defaultScreenAspectRatio, maxContentWidth, useResponsiveDimensions } from "../components/Responsive";
import { switchReturn } from "@eatbetter/common-shared";
import { BottomActionBar, bottomActionBarConstants } from "../components/BottomActionBar";
import { Haptics } from "../components/Haptics";
import { TabViewProgress } from "../components/TabViewProgress";
import { useBottomTabBarDimensions } from "../navigation/TabBar";
import { Image } from "react-native";
import { TBody, THeading1 } from "../components/Typography";
import { Spacer } from "../components/Spacer";
import { SlideTimeTrackerCompletedHandler, useSlideTimeTracker } from "../lib/util/UseSlideTimeTracker";
import { useDispatch } from "../lib/redux/Redux";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import {
  reportPhotoIngestionTipsCarouselCanceled,
  reportPhotoIngestionTipsCarouselCompleted,
  reportPhotoIngestionTipsCarouselStarted,
} from "../lib/analytics/AnalyticsEvents";

const strings = {
  intro: {
    headline: "Photo taking tips to get the best results ✨",
    subheadline: ["Keep the page flat", "Take photo from directly overhead", "Make sure all text is legible"],
    bullet: "✓",
  },
  next: "Next",
  good: "Flat, overhead, legible",
  badArched: "Page not flat",
  badFront: "Not directly overhead",
  badIllegible: "Illegible text",
  done: "Got it!",
};

// Images
const intro = require("../assets/photo_ingestion_first_run/photo_ingestion_intro.png");
const goodExample = require("../assets/photo_ingestion_first_run/good_example.jpg");
const badArched = require("../assets/photo_ingestion_first_run/bad_arched.jpg");
const badFrontView = require("../assets/photo_ingestion_first_run/bad_front_view.jpg");
const badIllegible = require("../assets/photo_ingestion_first_run/bad_illegible.jpg");

const constants = {
  introPhotoAspectRatio: 2556 / 1179,
  examplePhotoAspectRatio: 4032 / 3024,
};

const routeKeys = ["intro", "goodPhoto", "badPhotoArched", "badFrontView", "badPhotoIllegible"] as const;
type RouteKey = (typeof routeKeys)[number];
const isRouteKey = (str: string): str is RouteKey => routeKeys.some(i => i === str);

const routes: TabViewRoute<RouteKey>[] = [
  { key: "intro", title: "Intro" },
  { key: "badPhotoArched", title: "Bad Photo Arched" },
  { key: "badFrontView", title: "Bad Photo Front View" },
  { key: "badPhotoIllegible", title: "Bad Photo Illegible" },
  { key: "goodPhoto", title: "Good Photo" },
];

export const PhotoIngestionTipsScreen = withNonNavigableScreenContainer(
  "PhotoIngestionTipsScreen",
  (props: PhotoIngesetionTipsScreenProps) => {
    const dispatch = useDispatch();
    const screen = useScreen();

    const [tabIndex, setTabIndex] = useState(0);

    const onSlideTimeTrackerCompleted = useCallback<SlideTimeTrackerCompletedHandler>(
      results => {
        dispatch(
          analyticsEvent(
            reportPhotoIngestionTipsCarouselCompleted({
              durations: results.durations,
              backActions: results.backActions,
            })
          )
        );
      },
      [dispatch]
    );

    const completeSlideTimeTracker = useSlideTimeTracker(tabIndex, onSlideTimeTrackerCompleted);

    useEffect(() => {
      dispatch(analyticsEvent(reportPhotoIngestionTipsCarouselStarted()));
    }, []);

    const onCancel = useCallback(() => {
      dispatch(analyticsEvent(reportPhotoIngestionTipsCarouselCanceled()));
      completeSlideTimeTracker();
      props.onCancel();
      screen.nav.goBack();
    }, [dispatch, completeSlideTimeTracker, props.onCancel, screen.nav.goBack]);

    const onBack = useCallback(() => {
      setTabIndex(prev => Math.max(0, prev - 1));
    }, [setTabIndex]);

    const onNext = useCallback(() => {
      Haptics.feedback("itemStatusChanged");
      if (tabIndex >= routes.length - 1) {
        completeSlideTimeTracker();
        props.onComplete();
        screen.nav.goBack();
        return;
      }
      setTabIndex(prev => prev + 1);
    }, [tabIndex, setTabIndex, completeSlideTimeTracker, props.onComplete, screen.nav.goBack]);

    const renderRoute = useCallback(
      (routeProps: { route: TabViewRoute }) => {
        const routeKey = routeProps.route.key;
        if (!isRouteKey(routeKey)) {
          throw new Error("Unexpected route key: " + routeKey);
        }

        return <CarouselRoute type={routeKey} />;
      },
      [tabIndex]
    );

    return (
      <ScreenView scrollView={false} paddingHorizontal={false} paddingVertical={"none"} backgroundColor="black" isModal>
        <Header tabIndex={tabIndex} onPressCancel={onCancel} onPressBack={onBack} />
        <TabView
          routes={routes}
          index={tabIndex}
          onIndexChange={setTabIndex}
          tabBar={"none"}
          renderRoute={renderRoute}
        />
        <Progress routeCount={routes.length} tabIndex={tabIndex} />
        <BottomActionBar
          primaryAction={{
            actionText: tabIndex === routes.length - 1 ? strings.done : strings.next,
            onPressAction: onNext,
          }}
          containerBackgroundColor="transparent"
        />
      </ScreenView>
    );
  }
);

const textShadow = { color: globalStyleColors.rgba("black", "medium"), offset: { width: 0, height: 1 }, radius: 2 };

const CarouselRoute = React.memo((props: { type: RouteKey }) => {
  const { bottomTabBarHeight } = useBottomTabBarDimensions();

  if (props.type === "intro") {
    return (
      <View style={{ flex: 1 }}>
        <IntroPhoto source={intro} />
        <View
          style={{
            position: "absolute",
            bottom: getProgressBarTop(bottomTabBarHeight) + 5 * globalStyleConstants.unitSize,
            maxWidth: maxContentWidth,
            alignSelf: "center",
            paddingHorizontal: globalStyleConstants.defaultPadding,
          }}
        >
          <View style={{ width: "100%" }}>
            <THeading1 fontWeight="medium" color="white" textShadow={textShadow} lineHeight={40}>
              {strings.intro.headline}
            </THeading1>
            {strings.intro.subheadline.map(i => {
              return (
                <React.Fragment key={i}>
                  <Spacer vertical={1} />
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <TBody color="white" textShadow={textShadow}>
                      {strings.intro.bullet}
                    </TBody>
                    <TBody> </TBody>
                    <TBody color="white" textShadow={textShadow}>
                      {i}
                    </TBody>
                  </View>
                </React.Fragment>
              );
            })}
          </View>
        </View>
      </View>
    );
  }

  const { type, photo, text } = switchReturn(props.type, {
    goodPhoto: { type: "good" as const, photo: goodExample, text: strings.good },
    badPhotoArched: { type: "bad" as const, photo: badArched, text: strings.badArched },
    badFrontView: { type: "bad" as const, photo: badFrontView, text: strings.badFront },
    badPhotoIllegible: { type: "bad" as const, photo: badIllegible, text: strings.badIllegible },
  });

  return (
    <View style={{ flex: 1 }}>
      <ExamplePhoto source={photo} />
      <TextOverlay type={type} text={text} />
    </View>
  );
});

const getHeaderHeight = (statusBarHeight: number) => {
  return statusBarHeight + 18 + 24 + 18;
};

const Header = React.memo((props: { tabIndex: number; onPressBack: () => void; onPressCancel: () => void }) => {
  const { statusBarHeight } = useScreenHeaderDimensions();

  return (
    <View
      style={[
        styles.header,
        {
          paddingTop: statusBarHeight + 18,
          height: getHeaderHeight(statusBarHeight),
          justifyContent: props.tabIndex === 0 ? "flex-end" : "space-between",
        },
      ]}
    >
      {props.tabIndex > 0 && <BackButton onPress={props.onPressBack} />}
      <CancelButton onPress={props.onPressCancel} />
    </View>
  );
});

const CancelButton = React.memo((props: { onPress: () => void }) => {
  return (
    <Pressable noFeedback onPress={props.onPress}>
      <IconEx opacity="opaque" color={"white"} strokeWidth={2} size={24} />
    </Pressable>
  );
});

const BackButton = React.memo((props: { onPress: () => void }) => {
  return (
    <Pressable noFeedback onPress={props.onPress}>
      <IconChevronLeft opacity="opaque" color={"white"} strokeWidth={2} size={28} />
    </Pressable>
  );
});

const getProgressBarTop = (bottomTabBarHeight: number) => {
  return bottomTabBarHeight + bottomActionBarConstants.height + 6 + 24;
};

const Progress = React.memo((props: { tabIndex: number; routeCount: number }) => {
  const { bottomTabBarHeight } = useBottomTabBarDimensions();

  return (
    <View style={[styles.progress, { bottom: bottomTabBarHeight + bottomActionBarConstants.height + 6 }]}>
      <TabViewProgress count={props.routeCount} currentIndex={props.tabIndex} style="overlay" />
    </View>
  );
});

const IntroPhoto = (props: { source: ImageSourcePropType }) => {
  const { width: screenWidth, screenAspectRatio } = useResponsiveDimensions();

  const bgAspectRatio = (constants.introPhotoAspectRatio / defaultScreenAspectRatio) * screenAspectRatio;
  const width = screenWidth;
  const height = width * bgAspectRatio;

  return (
    <View>
      <Image resizeMode="cover" source={props.source} style={{ width, height }} />
    </View>
  );
};

const ExamplePhoto = (props: { source: ImageSourcePropType }) => {
  const { statusBarHeight } = useScreenHeaderDimensions();
  const { width: screenWidth, height: screenHeight, screenAspectRatio } = useResponsiveDimensions();

  const bgAspectRatio = (constants.examplePhotoAspectRatio / defaultScreenAspectRatio) * screenAspectRatio;
  const width = Math.min(maxContentWidth, screenWidth);
  const height = screenAspectRatio < defaultScreenAspectRatio ? 0.65 * screenHeight : width * bgAspectRatio;
  const resizeMode = screenAspectRatio < defaultScreenAspectRatio ? "contain" : "cover";

  return (
    <View
      style={{
        position: "absolute",
        alignItems: "center",
        justifyContent: "center",
        top: getHeaderHeight(statusBarHeight),
        left: 0,
        right: 0,
      }}
    >
      <Image resizeMode={resizeMode} source={props.source} style={{ width, height }} />
    </View>
  );
};

const TextOverlay = React.memo((props: { type: "good" | "bad"; text: string }) => {
  const { bottomTabBarHeight } = useBottomTabBarDimensions();

  const bottom = getProgressBarTop(bottomTabBarHeight) + 2 * globalStyleConstants.unitSize;

  return (
    <View
      style={{
        position: "absolute",
        bottom,
        alignSelf: "center",
        paddingHorizontal: globalStyleConstants.defaultPadding,
      }}
    >
      <View style={{ alignSelf: "center", flexWrap: "wrap" }}>
        <View
          style={[
            styles.overlay,
            {
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              paddingHorizontal: globalStyleConstants.unitSize,
              paddingBottom: 0,
            },
          ]}
        >
          {props.type === "bad" && <IconEx color={"red"} opacity="opaque" size={32} strokeWidth={4} />}
          {props.type === "good" && <IconCheck color={"green"} opacity="opaque" size={32} strokeWidth={4} />}
        </View>
      </View>
      <View style={styles.overlay}>
        <THeading1 fontWeight="medium">{props.text}</THeading1>
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  header: {
    position: "absolute",
    width: "100%",
    alignSelf: "center",
    flexDirection: "row",
    paddingHorizontal: globalStyleConstants.defaultPadding,
    maxWidth: maxContentWidth,
    zIndex: 100,
  },
  progress: {
    position: "absolute",
    alignSelf: "center",
    opacity: Opacity.light,
  },
  overlay: {
    backgroundColor: "white",
    paddingHorizontal: 1.5 * globalStyleConstants.unitSize,
    paddingVertical: 0.5 * globalStyleConstants.unitSize,
    borderRadius: 6,
  },
});
