import React, { ErrorInfo, PropsWithChildren } from "react";
import { log } from "../Log";
import { View, Text, Pressable, Platform } from "react-native";
import { LogLevel, newId } from "@eatbetter/common-shared";
import { getGlobalDispatch } from "../lib/redux/Redux";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { reportUnexpectedErrorDisplayed } from "../lib/analytics/AnalyticsEvents";

interface State {
  hasError: boolean;
}

export class AppErrorBoundary extends React.Component<PropsWithChildren<{ componentName: string }>, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(err: Error, errorInfo: ErrorInfo) {
    const errorId = newId();
    log.errorCaught(`APP ERROR BOUNDARY for ${this.props.componentName} with error id ${errorId}`, err, {
      stack: errorInfo.componentStack,
      component: this.props.componentName,
      errorId,
    });

    try {
      const dispatch = getGlobalDispatch();
      if (dispatch) {
        dispatch(
          analyticsEvent(
            reportUnexpectedErrorDisplayed({
              errorId,
              err,
              appBoundaryErrorStack: errorInfo.componentStack,
              context: "errorBoundary",
              description: `Error rendering ${this.props.componentName}`,
            })
          )
        );
      }
    } catch (err) {
      log.errorCaught("Error while dispatching reportUnexpectedErrorDisplayed", err);
    }
  }

  // IMPORTANT
  // To keep this code as safe as possible, we should not use any of our own components here,
  // in the off chance that they are the components causing the error...
  render() {
    if (this.state.hasError) {
      return (
        <View style={{ flex: 1, justifyContent: "center", alignItems: "center", padding: 24 }}>
          <Pressable onPress={() => this.setState({ hasError: false })} hitSlop={100}>
            <Text style={{ fontSize: 16 }}>
              Whoops. Sorry about that.{" "}
              {Platform.OS === "web" ? "Please try refreshing the page. " : "Tap here to reload."}
            </Text>
          </Pressable>
        </View>
      );
    }

    return this.props.children;
  }
}

export function withErrorBoundary<TProps>(
  componentName: string,
  HappyPathComponent: React.ComponentType<TProps>,
  ErrorComponent: React.ComponentType<TProps>,
  level?: LogLevel
): React.ComponentType<TProps> {
  return class extends React.PureComponent<TProps, State> {
    constructor(props: any) {
      super(props);
      this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
      return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
      log.errorCaught(
        `COMPONENT ERROR BOUNDARY for ${componentName}`,
        error,
        {
          stack: errorInfo.componentStack,
          props: this.props,
        },
        level
      );
    }

    render() {
      if (this.state.hasError) {
        return <ErrorComponent {...this.props} />;
      }

      return <HappyPathComponent {...this.props} />;
    }
  };
}
