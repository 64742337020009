import { withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { TBody, THeading2 } from "../components/Typography";
import { AppUpgradeScreenProps } from "../navigation/NavTree";
import { Spacer } from "../components/Spacer";
import { ContainerEnd } from "../components/Containers";
import { ButtonRectangle } from "../components/Buttons";
import { Linking, Platform } from "react-native";
import { useEffect, useRef } from "react";
import { useDispatch } from "../lib/redux/Redux";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { reportAppUpdateScreenDisplayed } from "../lib/analytics/AnalyticsEvents";
import { log } from "../Log";
import { isBetaInstall } from "../lib/system/SystemThunks";
import { getAppStoreLink } from "../lib/util/AppStoreLink";

const strings = {
  headerTitle: "Deglaze",
  title: "It's time for an update",
  body: (store: string) =>
    `This version of Deglaze is no longer supported. Please open ${
      store === strings.testFlight ? "" : "the "
    }${store} for the latest version, and you'll be cooking in no time.`,
  testFlight: "TestFlight",
  appStore: "App Store",
  googlePlay: "Google Play Store",
  open: (store: string) => `Open ${store}`,
};

type AppStore = "testFlight" | "appStore" | "googlePlay";

const storeLinks = {
  testFlight: "https://beta.itunes.apple.com/v1/app/6443578246",
  appStore: getAppStoreLink(),
  googlePlay: "",
};

export const AppUpgradeScreen = withNonNavigableScreenContainer("AppUpgradeScreen", (props: AppUpgradeScreenProps) => {
  const dispatch = useDispatch();
  const isTestFlight = useRef(dispatch(isBetaInstall())).current;

  useEffect(() => {
    log.logRemote("Displaying AppUpgradeScreen");
    dispatch(analyticsEvent(reportAppUpdateScreenDisplayed()));
  }, []);

  const store: AppStore =
    Platform.OS === "ios" ? (isTestFlight && props.allowBetaUpgrade ? "testFlight" : "appStore") : "googlePlay";
  const storeName = strings[store];
  const link = storeLinks[store];

  return (
    <ScreenView header={{ type: "custom", style: "tabRoot", title: "Deglaze" }} isModal>
      <Spacer vertical={2} />
      <THeading2>{strings.title}</THeading2>
      <Spacer vertical={2} />
      <TBody>{strings.body(storeName)}</TBody>
      <Spacer vertical={1} />
      <ContainerEnd vertical>
        <ButtonRectangle type="submit" title={strings.open(storeName)} onPress={() => Linking.openURL(link)} />
      </ContainerEnd>
    </ScreenView>
  );
});
