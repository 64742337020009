import { RootState } from "../redux/RootReducer";
import { notificationsAdapterSelectors } from "./NotificationsSlice";
import { useSelector } from "../redux/Redux";
import { NotificationId, Notification } from "@eatbetter/users-shared";

const selectNotificationIds = (s: RootState) =>
  notificationsAdapterSelectors.selectIds(s.notifications) as NotificationId[];
export const useNotificationIds = () => useSelector(selectNotificationIds);
export const useNotification = (id: NotificationId): Notification | undefined =>
  useSelector(s => notificationsAdapterSelectors.selectById(s.notifications, id));

const selectNotificationIsRead = (s: RootState, n: NotificationId): boolean => {
  const notification = s.notifications.entities[n];
  const lastRead = s.notifications.lastReadTime;

  if (!notification || !lastRead) {
    return false;
  }

  return notification.ts <= lastRead || s.notifications.read.includes(n);
};

export const useNotificationIsRead = (n: NotificationId) => useSelector(s => selectNotificationIsRead(s, n));

export const selectNotificationUnreadCount = (s: RootState): number => {
  const lastRead = s.notifications.lastReadTime;

  if (!lastRead) {
    return s.notifications.ids.length;
  }

  return Object.values(s.notifications.entities).filter(
    n => n!.ts > lastRead && !s.notifications.read.includes(n!.idempotencyId)
  ).length;
};

export const useNotificationUnreadCount = () => useSelector(s => selectNotificationUnreadCount(s));
