import {
  configureStore,
  createDraftSafeSelector,
  Action,
  ActionCreator,
  ThunkAction as ReduxThunkAction,
} from "@reduxjs/toolkit";
import { AdminRootState, rootReducer } from "./AdminRootReducer";
import { DataManagerApiClient } from "./DataManagerApiClient";
import { useSelector as reduxUseSelector, useDispatch as reduxUseDispatch } from "react-redux";
import { ApiClientFactory, getApiClientFactory, getUrlsForEnv } from "@eatbetter/ui-shared";
import { AdminApiClient } from "./AdminApiClient";
import { getToken } from "./AdminAuth";
import { SetWaitingHandler } from "@eatbetter/ui-shared";
import { CurrentEnvironment, getAppMetaHeaders } from "@eatbetter/ui-shared";

export const createReduxStore = () => {
  const urls = getUrlsForEnv(CurrentEnvironment.configEnvironment());
  const devBuild = CurrentEnvironment.debugBuild() ? true : undefined;

  const metaHeaders = getAppMetaHeaders({
    devBuild,
    gitSha: CurrentEnvironment.gitSha(),
    platform: "web-admin",
  });

  const api = getApiClientFactory<AdminApiClient<"returnErrorEnvelope">, AdminApiClient<"throwOnError">>({
    returnFactory: (waitingHandler?: SetWaitingHandler) => {
      return new AdminApiClient({
        urls,
        getToken: getToken,
        metaHeaders,
        errorEnvelopeStrategy: "returnErrorEnvelope",
        waitingHandler,
      });
    },
    throwFactory: (waitingHandler?: SetWaitingHandler) => {
      return new AdminApiClient({
        urls,
        getToken: getToken,
        metaHeaders,
        errorEnvelopeStrategy: "throwOnError",
        waitingHandler,
      });
    },
  });

  const deps: AdminThunkDeps = {
    api,
    dataManagerClient: new DataManagerApiClient("http://localhost:8002"),
  };

  return configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => {
      return getDefaultMiddleware({
        thunk: {
          extraArgument: deps,
        },
        immutableCheck: {
          warnAfter: 500, // Debug only: overrides default of 32ms to avoid warning overload in debug mode
        },
        serializableCheck: {
          warnAfter: 500, // Debug only: overrides default of 32ms to avoid warning overload in debug mode
        },
      });
    },
  });
};

export interface AdminThunkDeps {
  api: ApiClientFactory<AdminApiClient<"returnErrorEnvelope">, AdminApiClient<"throwOnError">>;
  dataManagerClient: DataManagerApiClient;
}

export type StoreType = ReturnType<typeof createReduxStore>;
export type AdminDispatch = ReturnType<typeof createReduxStore>["dispatch"];

export const useSelector = reduxUseSelector.withTypes<AdminRootState>();
export const useDispatch = reduxUseDispatch.withTypes<AdminDispatch>();

export type ThunkAction<TReturn> = ReturnType<
  ActionCreator<ReduxThunkAction<Promise<TReturn>, AdminRootState, AdminThunkDeps, Action>>
>;
export type SyncThunkAction<TReturn> = ReturnType<
  ActionCreator<ReduxThunkAction<TReturn, AdminRootState, AdminThunkDeps, Action>>
>;

type AdminSelector<T = unknown> = (state: AdminRootState, ...other: any[]) => T;

// createDraftSafeSelector make these safe to use in an immer-based reducer (eg: a reducer in createSlice)
export const createSelector1 = <T1, R>(s1: AdminSelector<T1>, r: (t1: T1) => R) => createDraftSafeSelector(s1, r);
export const createSelector2 = <T1, T2, R>(s1: AdminSelector<T1>, s2: AdminSelector<T2>, r: (t1: T1, t2: T2) => R) =>
  createDraftSafeSelector(s1, s2, r);
export const createSelector3 = <T1, T2, T3, R>(
  s1: AdminSelector<T1>,
  s2: AdminSelector<T2>,
  s3: AdminSelector<T3>,
  r: (t1: T1, t2: T2, t3: T3) => R
) => createDraftSafeSelector(s1, s2, s3, r);
export const createSelector4 = <T1, T2, T3, T4, R>(
  s1: AdminSelector<T1>,
  s2: AdminSelector<T2>,
  s3: AdminSelector<T3>,
  s4: AdminSelector<T4>,
  r: (t1: T1, t2: T2, t3: T3, t4: T4) => R
) => createDraftSafeSelector(s1, s2, s3, s4, r);
