import { nowOrLaterBackoff } from "@eatbetter/common-shared";
import { Reactor, ReactorResponse } from "../redux/Reactors";
import { likeUnlikePost, loadFollowingInfo, loadNewHomeFeedPosts, loadNewProfilePosts } from "./SocialThunks";
import { exploreFeed, followingFeed, profileFeed, selectSocialFeed } from "./SocialSlice";
import { shouldFetch } from "../redux/ShouldFetch";

const fetchFollowingFeedReactor: Reactor = state => {
  const fetchResult = shouldFetch(
    "followingFeed.newPostsMeta",
    state,
    s => selectSocialFeed(s.social, followingFeed)!.newPostsMeta,
    {
      staleThresholdSeconds: 300,
    }
  );

  if (fetchResult.now) {
    return {
      dispatch: loadNewHomeFeedPosts("followingFeed"),
    };
  }

  if (fetchResult.laterIn) {
    return {
      kickInMs: fetchResult.laterIn,
    };
  }

  return undefined;
};

const fetchExploreFeedReactor: Reactor = state => {
  const fetchResult = shouldFetch(
    "exploreFeed.newPostsMeta",
    state,
    s => selectSocialFeed(s.social, exploreFeed)!.newPostsMeta,
    {
      staleThresholdSeconds: 300,
    }
  );

  if (fetchResult.now) {
    return {
      dispatch: loadNewHomeFeedPosts("exploreFeed"),
    };
  }

  if (fetchResult.laterIn) {
    return {
      kickInMs: fetchResult.laterIn,
    };
  }

  return undefined;
};

const fetchProfileFeedReactor: Reactor = state => {
  const fetchResult = shouldFetch(
    "profileFeed.newPostsMeta",
    state,
    s => selectSocialFeed(s.social, profileFeed)!.newPostsMeta,
    {
      staleThresholdSeconds: 300,
      fetchForAnonymous: false,
    }
  );

  if (fetchResult.now) {
    return {
      dispatch: loadNewProfilePosts(),
    };
  }

  if (fetchResult.laterIn) {
    return {
      kickInMs: fetchResult.laterIn,
    };
  }

  return undefined;
};

const loadFollowingInfoReactor: Reactor = state => {
  const fetchResult = shouldFetch("social.followingInfo", state, s => s.social.followingInfo, {
    staleThresholdSeconds: 3600 * 4,
    fetchForAnonymous: false,
  });

  if (fetchResult.now) {
    return {
      dispatch: loadFollowingInfo(),
    };
  }

  if (fetchResult.laterIn) {
    return {
      kickInMs: fetchResult.laterIn,
    };
  }

  return undefined;
};

const persistLikeReactor: Reactor = state => {
  let action: ReactorResponse | undefined;
  let kickInMs: number | undefined;

  for (const item of Object.values(state.social.pendingLikes)) {
    if (!item.pending && item.queued) {
      if (item.queued.errorCount && item.queued.lastAttempt) {
        const nol = nowOrLaterBackoff({
          lastAttempt: item.queued.lastAttempt,
          failureCount: item.queued.errorCount,
          timeNow: state.system.time,
        });

        if (nol.laterIn) {
          if (!kickInMs || nol.laterIn < kickInMs) {
            kickInMs = nol.laterIn;
          }
          continue;
        }
      }

      action = { dispatch: likeUnlikePost(item.queued.like.postId) };
      break;
    }
  }

  if (action) {
    return action;
  }

  if (kickInMs) {
    return { kickInMs };
  }

  return undefined;
};

export const socialReactors = [
  fetchFollowingFeedReactor,
  fetchExploreFeedReactor,
  fetchProfileFeedReactor,
  persistLikeReactor,
  loadFollowingInfoReactor,
];
