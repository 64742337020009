import React, { useRef, useState } from "react";
import {
  globalStyleConstants,
  Photo,
  PhotoSizeName,
  Pressable,
  SetWaitingHandler,
  Spacer,
  Spinner,
  TBody,
  THeading2,
  TSecondary,
} from "@eatbetter/ui-shared";
import { View } from "react-native";
import { ParsedIngredientSummary, standardCategories, StandardPrimaryCategory } from "@eatbetter/items-shared";
import groupBy from "lodash/groupBy";
import {
  Author,
  Book,
  ExternalUrlRecipe,
  Publisher,
  RawRecipeTime,
  RawRecipeYield,
  RecipeIngredients,
  RecipeIngredientSection,
  RecipeInstructions,
  RecipeInstructionSection,
  RecipeTag,
  RecipeTagManifest,
  RecipeTime,
  RecipeYield,
  ServerRecipe,
  ShoppableRecipeIngredient,
} from "@eatbetter/recipes-shared";
import { NvpTable } from "./NvpTable";
import { daysBetween, defaultTimeProvider, emptyToUndefined, getFormattedDuration } from "@eatbetter/common-shared";
import { ColorCodedPhrase } from "./ColorCodedPhase";
import { useDispatch } from "../lib/AdminRedux";
import { addTruthSetPhrase } from "../lib/data-manager/DataManagerThunks";
import { PhotoRef } from "@eatbetter/photos-shared";
import { Divider, Select } from "antd";
import { getTagDisplayLabel, SelectableTagGroup } from "@eatbetter/ui-shared/src/components/recipes/RecipeTagSelect";
import { IngredientCategoryOverride } from "@eatbetter/lists-shared";
import { getCurrentUserEmail } from "../lib/AdminAuth";
import { Link } from "react-router-dom";
import { AdminBooksScreenIdNav } from "../screens/AdminBooksScreen";
import { PhraseItemType } from "@eatbetter/items-data";

export const Ingredients = (props: { ingredients: RecipeIngredients }) => {
  return (
    <View>
      {props.ingredients.sections.map(section => {
        return <IngredientSection section={section} key={section.id} />;
      })}
    </View>
  );
};

export const IngredientSection = (props: { section: RecipeIngredientSection }) => {
  return (
    <View>
      <TBody fontWeight="heavy">{props.section.title ?? "(unnamed section)"}</TBody>
      <Spacer vertical={1} />
      {props.section.items.map(item => {
        return (
          <View style={{ paddingBottom: globalStyleConstants.unitSize }} key={item.id}>
            <TBody>{item.text}</TBody>
            <ShoppableIngredients shoppable={item.shoppable} />
          </View>
        );
      })}
    </View>
  );
};

export const ShoppableIngredients = (props: { shoppable?: ShoppableRecipeIngredient[] }) => {
  if (!props.shoppable || props.shoppable.length === 0) {
    return null;
  }

  return (
    <View style={{ paddingLeft: globalStyleConstants.unitSize * 2 }}>
      {props.shoppable.map(si => {
        return <TBody key={si.id}>{si.text}</TBody>;
      })}
    </View>
  );
};

export const CategorizedIngredients = React.memo(
  (props: {
    items: ParsedIngredientSummary[];
    overrides?: IngredientCategoryOverride[];
    onOverride?: (text: string, category: StandardPrimaryCategory | null, setWaiting: SetWaitingHandler) => void;
  }) => {
    const grouped = groupBy(props.items, item => item.category ?? "uncategorized");
    return (
      <View>
        {Object.entries(grouped).map(entry => {
          return (
            <CategorizedGroup
              category={entry[0]}
              items={entry[1]}
              key={entry[0]}
              overrides={props.overrides}
              onOverride={props.onOverride}
            />
          );
        })}
      </View>
    );
  }
);

const categoryOptions = standardCategories.map(s => {
  return { label: s, value: s };
});

export const CategorizedGroup = React.memo(
  (props: {
    category: string;
    items: ParsedIngredientSummary[];
    overrides?: IngredientCategoryOverride[];
    onOverride?: (text: string, category: StandardPrimaryCategory | null, setWaiting: SetWaitingHandler) => void;
  }) => {
    const showTruth = useRef(getCurrentUserEmail() === "jacob@deglaze.app").current;

    return (
      <View>
        <TBody fontWeight="heavy">{props.category}</TBody>
        <Spacer vertical={1} />
        {props.items.map((item, idx) => {
          return (
            <CategorizedItem
              item={item}
              key={`${idx}-${item.phrase}`}
              overrides={props.overrides}
              onOverride={props.onOverride}
              showTruth={showTruth}
            />
          );
        })}
      </View>
    );
  }
);

export const CategorizedItem = (props: {
  item: ParsedIngredientSummary;
  showTruth: boolean;
  overrides?: IngredientCategoryOverride[];
  onOverride?: (text: string, category: StandardPrimaryCategory | null, setWaiting: SetWaitingHandler) => void;
}) => {
  const { item, showTruth } = props;
  const shoppable = emptyToUndefined(item.shoppablePhrase);
  const normalized = shoppable ? ` (${shoppable})` : "";
  const override = props.overrides?.find(o => o.text.toLowerCase() === item.phrase.toLowerCase());
  const [waiting, setWaiting] = useState(false);
  const selectBackground = override ? "yellow" : undefined;
  const dispatch = useDispatch();

  const addToTruth = (p: string, type: PhraseItemType) => {
    dispatch(addTruthSetPhrase(p, type)).catch(err => alert(`Error adding phrase to truth set: ${err}`));
  };

  const onCategoryChange = (selected: StandardPrimaryCategory | undefined) => {
    props.onOverride?.(item.phrase, selected ? selected : null, setWaiting);
  };

  return (
    <View style={{ paddingBottom: globalStyleConstants.unitSize, flexDirection: "row", alignItems: "center" }}>
      <ColorCodedPhrase tags={item.tags} phrase={item.phrase} />
      <TBody color="gray">{normalized}</TBody>
      <Spacer horizontal={2} />
      {props.onOverride && !waiting && (
        <Select
          value={override?.category}
          onChange={onCategoryChange}
          allowClear
          placeholder="Category Override"
          options={categoryOptions}
          style={{ backgroundColor: selectBackground }}
          bordered={false}
        />
      )}
      {waiting && <Spinner />}
      <Spacer horizontal={1} />
      {showTruth && (
        <Pressable onPress={() => addToTruth(item.phrase, "ingredient")}>
          <TSecondary color="gray" opacity="light">
            Add to truth set
          </TSecondary>
        </Pressable>
      )}
    </View>
  );
};

export const Instructions = (props: { instructions: RecipeInstructions }) => {
  return (
    <View>
      {props.instructions.sections.map(section => {
        return <InstructionSection section={section} key={section.id} />;
      })}
    </View>
  );
};

export const InstructionSection = (props: { section: RecipeInstructionSection }) => {
  const showTruth = useRef(getCurrentUserEmail() === "jacob@deglaze.app").current;
  const dispatch = useDispatch();

  const addToTruth = (p: string) => {
    dispatch(addTruthSetPhrase(p, "instruction")).catch(err => alert(`Error adding phrase to truth set: ${err}`));
  };

  return (
    <View>
      <TBody fontWeight="heavy">{props.section.title ?? "(unnamed section)"}</TBody>
      <Spacer vertical={1} />
      {props.section.items.map(item => {
        return (
          <View style={{ paddingBottom: globalStyleConstants.unitSize, flexDirection: "row" }} key={item.id}>
            <TBody>{item.text}</TBody>
            {showTruth && (
              <Pressable onPress={() => addToTruth(item.text)}>
                <TSecondary color="gray" opacity="light">
                  Add to truth set
                </TSecondary>
              </Pressable>
            )}
          </View>
        );
      })}
    </View>
  );
};

export const PublisherComponent = (props: { publisher?: Publisher }) => {
  if (!props.publisher) {
    return null;
  }

  const publisherType =
    props.publisher.type === "unknownPublisher" && props.publisher.id
      ? "unknownPublisher (with ID)"
      : props.publisher.type;

  return (
    <table>
      <tbody>
        <NvpTable name="Type" value={publisherType} />
        <NvpTable name="Name">
          <THeading2>{props.publisher.name}</THeading2>
        </NvpTable>
        {props.publisher.type !== "knownPublisher" && !!props.publisher.url && (
          <NvpTable name="Url">
            <TBody>
              <a href={props.publisher.url} target="_blank" rel="noreferrer">
                {props.publisher.url}
              </a>
            </TBody>
          </NvpTable>
        )}
        {!!props.publisher.photo && (
          <NvpTable name="Photo">
            <PhotoComponent photo={props.publisher.photo} />
          </NvpTable>
        )}
      </tbody>
    </table>
  );
};

export const AuthorComponent = (props: { author?: Author; parsedAuthor?: ExternalUrlRecipe["parsedAuthor"] }) => {
  if (!props.author) {
    return null;
  }

  const authorType =
    props.author.type === "unknownAuthor" && props.author.id ? "unknownAuthor (with ID)" : props.author.type;

  return (
    <table>
      <tbody>
        <NvpTable name="Type" value={authorType} />
        <NvpTable name="Name">
          <THeading2>{props.author.name}</THeading2>
        </NvpTable>
        {props.author?.type === "unknownAuthor" && props.author.url && (
          <NvpTable name="Url">
            <TBody>
              <a href={props.author.url} target="_blank" rel="noreferrer">
                {props.author.url}
              </a>
            </TBody>
          </NvpTable>
        )}
        {!!props.author.photo && (
          <NvpTable name="Photo">
            <PhotoComponent photo={props.author.photo} />
          </NvpTable>
        )}
        {props.author.type === "knownAuthor" && props.parsedAuthor && (
          <NvpTable name="Parsed Author" value={`${props.parsedAuthor.name} ${props.parsedAuthor.url ?? ""}`} />
        )}
      </tbody>
    </table>
  );
};

export const BookComponent = (props: { book?: Book }) => {
  if (!props.book) {
    return null;
  }

  const book = props.book;
  return (
    <table>
      <tbody>
        <tr>
          <td>
            <TBody fontWeight="heavy">Book</TBody>
          </td>
        </tr>
        <NvpTable name="ID">
          <Link to={AdminBooksScreenIdNav.getPath(book.id)}>{book.id}</Link>
        </NvpTable>
        <NvpTable name="Title" value={book.name} />
        <NvpTable name="Purchase Link" value={book.purchaseLink} />
        <NvpTable name="Cover Photo">
          <View>
            <PhotoComponent photo={book.photo} size="large" resizeMode="contain" />
          </View>
        </NvpTable>
      </tbody>
    </table>
  );
};

export const TimeComponent = (props: { time?: RecipeTime; timeRaw?: RawRecipeTime }) => {
  if (!props.time && !props.timeRaw) {
    return null;
  }

  return (
    <table>
      <tbody>
        <NvpTable name="Total">
          <TBody fontWeight="medium">
            {getFormattedDuration({ milliseconds: props.time?.total[0] }, false, "XXh:XXm")}
          </TBody>
        </NvpTable>
        {!!props.timeRaw && (
          <>
            <Divider />
            <NvpTable name="Raw Time">
              <table>
                <tbody>
                  <NvpTable name="Total" value={props.timeRaw?.total} />
                  <NvpTable name="Prep" value={props.timeRaw?.prepTime} />
                  <NvpTable name="Cook" value={props.timeRaw?.cookTime} />
                </tbody>
              </table>
            </NvpTable>
          </>
        )}
      </tbody>
    </table>
  );
};

export const YieldComponent = (props: { yield?: RecipeYield; yieldRaw?: RawRecipeYield }) => {
  if (!props.yield) {
    return null;
  }

  return (
    <table>
      <tbody>
        <NvpTable name="Yield">
          <TBody fontWeight="medium">{props.yield.text}</TBody>
        </NvpTable>
        {!!props.yieldRaw && (
          <>
            <Divider />
            <NvpTable name="Raw Yield" value={props.yieldRaw?.values.toString()} />
          </>
        )}
      </tbody>
    </table>
  );
};

export const TagsComponent = (props: { tags: RecipeTag[]; manifest: RecipeTagManifest }) => {
  return (
    <SelectableTagGroup
      renderTagLabel={tag => getTagDisplayLabel(tag, props.manifest)}
      onPressTag={() => {}}
      tags={props.tags.filter(i => !("deleted" in i))}
      selectedTags={props.tags}
    />
  );
};

export const PhotoComponent = (props: {
  photo?: PhotoRef;
  size?: "small" | "large";
  resizeMode?: "cover" | "contain";
}) => {
  if (!props.photo) {
    return null;
  }

  const photoSize: PhotoSizeName = props.size === "large" ? "thumbnailXlarge" : "thumbnailXsmall";

  return (
    <>
      <View style={{ width: globalStyleConstants.unitSize * 24 }}>
        <Photo source={props.photo} style={photoSize} resizeMode={props.resizeMode ?? "cover"} sourceSize="w1290" />
      </View>
      {!!props.photo && <NvpTable name="Photo type" value={props.photo.type} />}
      {props.photo?.type === "external" && <NvpTable name="Direct" value={!!props.photo.direct} />}
      {props.photo?.type === "external" && <NvpTable name="Photo URL" value={props.photo.url} />}
      {props.photo?.type === "internal" && <NvpTable name="Photo ID" value={props.photo.id} />}
    </>
  );
};

export const IndexComponent = (props: { recipe: ServerRecipe }) => {
  const lastAction = props.recipe.indexLastAction;
  const override = props.recipe.indexOverride;

  const laStr = lastAction
    ? `${lastAction.action}, ${lastAction.ts} (${daysBetween(lastAction.ts, defaultTimeProvider()).toFixed(
        1
      )} days ago), ${lastAction.note ?? "-"} `
    : "-";
  const oStr = override
    ? `${override.override}, ${override.ts} (${daysBetween(override.ts, defaultTimeProvider()).toFixed(1)} days ago), ${
        emptyToUndefined(override.note) ? override.note : "-"
      }`
    : "-";
  return (
    <table>
      <tbody>
        <NvpTable name="Last Action" value={laStr} />
        <NvpTable name="Override" value={oStr} />
      </tbody>
    </table>
  );
};
