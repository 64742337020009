import { switchReturn } from "@eatbetter/common-shared";
import React, { PropsWithChildren } from "react";
import { StyleSheet, View } from "react-native";
import { globalStyleColors, globalStyleConstants } from "./GlobalStyles";
import {
  IconAdd,
  IconOpenLink,
  IconCart,
  IconCookPotOpen,
  IconEditSquare,
  IconLink,
  IconShare,
  IconArchive,
  IconDelete,
  IconText,
  IconClock,
  IconNote,
  IconTag,
  IconFlag,
  IconSpeakerMuted,
  IconSpeakerOn,
  IconOpenExternal,
  IconStar,
  IconCamera,
  IconImage,
  IconBook,
} from "./Icons";
import { Pressable } from "./Pressable";
import { Spacer } from "./Spacer";
import { Spinner } from "./Spinner";
import { TBody, TSecondary } from "./Typography";
import { Separator } from "./Separator";
import { smallScreenBreakpoint } from "./Responsive";
import { NewBadge } from "./Badges";

const constants = {
  itemMarginTop: 2 * globalStyleConstants.unitSize,
  itemHeight: 28,
  menuPaddingBottom: 3.5 * globalStyleConstants.unitSize,
};

export function getOptionsMenuHeight(itemCount: number): number {
  const unitSize = globalStyleConstants.unitSize;
  const itemHeight = constants.itemHeight;
  const spacerHeight = 1.3 * unitSize;
  const separatorHeight = 1;
  const menuPaddingBottom = constants.menuPaddingBottom;

  const firstItemHeight = spacerHeight + itemHeight;
  const subsequentItemHeight = spacerHeight + separatorHeight + spacerHeight + itemHeight;
  const totalHeight = firstItemHeight + (itemCount - 1) * subsequentItemHeight + menuPaddingBottom;

  return totalHeight;
}

export const OptionsMenu = React.memo((props: PropsWithChildren<{}>) => {
  return <View style={styles.navMenu}>{props.children}</View>;
});

type OptionIcon =
  | "archive"
  | "add"
  | "audioOn"
  | "audioOff"
  | "camera"
  | "cart"
  | "cook"
  | "delete"
  | "edit"
  | "flag"
  | "goTo"
  | "image"
  | "library"
  | "link"
  | "note"
  | "openExternal"
  | "share"
  | "rating"
  | "tag"
  | "text"
  | "time"
  | (() => React.ReactElement);

interface OptionsMenuItemProps {
  icon: OptionIcon;
  text: string;
  preview?: string | React.ReactElement;
  onPress: () => void;
  waiting?: boolean;
  disabled?: boolean;
  isFirst?: boolean;
  badge?: "new";
}

export const OptionsMenuItem = React.memo((props: OptionsMenuItemProps) => (
  <>
    {!props.isFirst && (
      <>
        <Spacer vertical={1.3} />
        <Separator orientation="row" />
      </>
    )}
    <Spacer vertical={1.3} />
    <Pressable
      style={styles.itemContainer}
      disabled={props.disabled || props.waiting}
      singlePress
      onPress={props.onPress}
    >
      <View style={styles.iconContainer}>{renderOptionIcon({ type: props.icon, waiting: props.waiting })}</View>
      <Spacer horizontal={1} />
      <View style={styles.textContainer}>
        <View>
          <TBody errorText={props.icon === "archive" || props.icon === "delete"} numberOfLines={1} adjustsFontSizeToFit>
            {props.text}
          </TBody>
        </View>
        {!!props.preview && (
          <>
            <Spacer horizontal={1} />
            {typeof props.preview === "string" && (
              <View
                style={{
                  flexShrink: 1,
                  maxWidth: smallScreenBreakpoint,
                  alignSelf: "flex-end",
                }}
              >
                <TSecondary opacity="light" numberOfLines={1}>
                  {props.preview}
                </TSecondary>
              </View>
            )}
            {typeof props.preview !== "string" && props.preview}
          </>
        )}
      </View>
      {props.badge === "new" && (
        <View style={{ paddingLeft: globalStyleConstants.unitSize }}>
          <NewBadge />
        </View>
      )}
    </Pressable>
  </>
));

const renderOptionIcon = (props: { type: OptionIcon; waiting?: boolean }) => {
  if (props.waiting) {
    return <Spinner />;
  }

  if (typeof props.type !== "string") {
    return props.type();
  }

  const optionIcon = switchReturn(props.type, {
    add: <IconAdd opacity="opaque" />,
    archive: <IconArchive opacity="opaque" color={globalStyleColors.colorAccentWarm} />,
    audioOff: <IconSpeakerMuted opacity="opaque" />,
    audioOn: <IconSpeakerOn opacity="opaque" color={globalStyleColors.colorTimerAction} />,
    camera: <IconCamera opacity="opaque" />,
    cart: <IconCart opacity={"opaque"} />,
    cook: <IconCookPotOpen opacity={"opaque"} />,
    delete: <IconDelete opacity="opaque" color={globalStyleColors.colorAccentWarm} />,
    edit: <IconEditSquare opacity={"opaque"} />,
    flag: <IconFlag opacity={"opaque"} />,
    goTo: <IconOpenLink opacity="opaque" />,
    image: <IconImage opacity="opaque" />,
    library: <IconBook opacity="opaque" />,
    link: <IconLink opacity="opaque" />,
    note: <IconNote opacity="opaque" />,
    openExternal: <IconOpenExternal opacity="opaque" />,
    share: <IconShare opacity="opaque" />,
    rating: <IconStar opacity="opaque" />,
    tag: <IconTag opacity="opaque" />,
    text: <IconText opacity="opaque" />,
    time: <IconClock opacity="opaque" />,
  });

  return optionIcon;
};

const styles = StyleSheet.create({
  navMenu: {
    paddingHorizontal: 2 * globalStyleConstants.unitSize,
    paddingBottom: constants.menuPaddingBottom,
  },
  itemContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  iconContainer: {
    width: constants.itemHeight,
    height: constants.itemHeight,
  },
  textContainer: {
    flex: 1,
    flexDirection: "row",
  },
});
