import { useCallback, useEffect, useRef } from "react";
import { ScreenView } from "../components/ScreenView";
import { SearchAndFilterBar } from "../components/SearchBox";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { useDispatch } from "../lib/redux/Redux";
import {
  addSearchTagFilter,
  fetchNextSearchResults,
  querySuggestionPressed,
  removeSearchTagFilter,
  searchAddFilterButtonPressed,
  searchCancelButtonPressed,
  searchSubmitted,
  setSearchQueryFilter,
} from "../lib/search/SearchThunks";
import {
  useSearchInputText,
  useSearchIsLoading,
  useSearchQuerySubmitted,
  useServerRecipeResultCount,
  useServerSearchResults,
} from "../lib/search/SearchSelectors";
import { SearchResults } from "../components/SearchResults";
import { LayoutAnimation } from "react-native";
import { SearchQueryScreenProps, navTree } from "../navigation/NavTree";
import { AppRecipeTag } from "../lib/recipes/RecipesSlice";
import { useFilterTags } from "../lib/recipes/RecipeTagSelectors";
import { Haptics } from "../components/Haptics";
import { QuerySuggestionPressedHandler, SearchQuerySuggestions } from "../components/SearchQuerySuggestions";
import { useFilteredLibraryRecipes } from "../lib/composite/RecipeListSelectors";
import { useSearchSession } from "../lib/search/SearchHooks";
import { KnownAuthorId, KnownPublisherId } from "@eatbetter/recipes-shared";
import { useOrFetchKnownAuthorProfile, useOrFetchKnownPublisherProfile } from "../lib/social/SocialHooks";
import { TextInputHandle } from "../components/TextInput";
import { Spacer } from "../components/Spacer";
import { SearchQueryStartedFrom, reportSearchQueryStarted } from "../lib/analytics/AnalyticsEvents";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { clearQuerySubmitted } from "../lib/search/SearchSlice";
import { getEntityDisplayText } from "@eatbetter/posts-shared";

const strings = {
  searchPlaceholder: "What do you want to eat?",
};

export const SearchQueryScreen = withScreenContainer(
  "SearchQueryScreen",
  (props: SearchQueryScreenProps) => {
    const dispatch = useDispatch();
    const screen = useScreen();
    const textInputRef = useRef<TextInputHandle>(null);
    // special value for the main screen search results
    const searchSessionId = useSearchSession({
      initialLoad: false,
      authorId: props.knownAuthorId,
      publisherId: props.knownPublisherId,
    });

    const knownAuthorProfile = useOrFetchKnownAuthorProfile(props.knownAuthorId);
    const knownPublisherProfile = useOrFetchKnownPublisherProfile(props.knownPublisherId);
    const entityId = props.knownAuthorId ?? props.knownPublisherId;
    const entityProfile = knownAuthorProfile ?? knownPublisherProfile;
    const entity = knownAuthorProfile?.author ?? knownPublisherProfile?.publisher;

    const searchPlaceholderText = entity ? getEntityDisplayText(entity).headline : strings.searchPlaceholder;

    const libraryResults = useFilteredLibraryRecipes(searchSessionId);
    const { recipes: serverRecipeResults, entities: entityResults } = useServerSearchResults(searchSessionId);
    const serverCount = useServerRecipeResultCount(searchSessionId);
    const loadingServerResults = useSearchIsLoading(searchSessionId);

    const activeTagFilters = useFilterTags(searchSessionId);
    const haveActiveTags = activeTagFilters.length > 0;

    const query = useSearchInputText(searchSessionId);
    const querySubmitted = useSearchQuerySubmitted(searchSessionId);
    const prevSubmitted = useRef(querySubmitted);

    const onFocusTextInput = useCallback(() => {
      dispatch(clearQuerySubmitted(searchSessionId));
    }, [dispatch, searchSessionId]);

    const alreadyReportedView = useRef(false);

    useEffect(() => {
      if (!alreadyReportedView.current && screen.nav.focused) {
        // Since we fetch known author/publisher here, we may not have it when this is first called. But if we have entityId,
        // we know that it's fetching and we will be called again. So if there's an entity ID but no entity, we don't report anything.
        // If there's no entity ID, then we know this is not an entity-scoped search and we report without the entity ID.
        if (entityId) {
          if (entityProfile) {
            dispatch(
              analyticsEvent(
                reportSearchQueryStarted({
                  from: props.analyticsContext,
                  sessionId: searchSessionId,
                  knownEntity: knownAuthorProfile?.author ?? knownPublisherProfile?.publisher,
                })
              )
            );
            alreadyReportedView.current = true;
          }
        } else {
          dispatch(
            analyticsEvent(
              reportSearchQueryStarted({
                from: props.analyticsContext,
                sessionId: searchSessionId,
              })
            )
          );
          alreadyReportedView.current = true;
        }
      }
    }, [!!entityProfile, !!entityId, screen.nav.focused]);

    useEffect(() => {
      if (querySubmitted && !prevSubmitted.current) {
        textInputRef.current?.blur();
      }
      prevSubmitted.current = querySubmitted;
    }, [querySubmitted]);

    const onSubmitBase = useCallback(() => {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    }, []);

    const onSubmitTextInput = useCallback(() => {
      const trimmed = query?.trim() ?? "";

      if (!trimmed && !haveActiveTags) {
        textInputRef.current?.blur();
        return;
      }

      dispatch(setSearchQueryFilter(searchSessionId, trimmed));
      dispatch(searchSubmitted(searchSessionId));

      onSubmitBase();
    }, [query, textInputRef, haveActiveTags, searchSessionId, onSubmitBase]);

    const onPressAddFilterButton = useCallback(() => {
      textInputRef.current?.blur();
      dispatch(searchAddFilterButtonPressed(searchSessionId));
      screen.nav.modal(navTree.get.screens.recipesFilter, { searchSessionId });
    }, [screen.nav.modal, textInputRef, searchSessionId]);

    const onPressTagFilterSuggestion = useCallback(
      (tag: AppRecipeTag) => {
        Haptics.feedback("itemStatusChanged");
        dispatch(addSearchTagFilter({ tag, sessionId: searchSessionId }));
        onSubmitBase();
      },
      [dispatch, onSubmitBase, searchSessionId]
    );

    const onPressRemoveTagFilter = useCallback(
      (tag: AppRecipeTag) => {
        Haptics.feedback("itemDeleted");
        dispatch(removeSearchTagFilter({ tag, sessionId: searchSessionId }));
        onSubmitBase();
      },
      [dispatch, onSubmitBase, searchSessionId]
    );

    useEffect(() => {
      onSubmitBase();
    }, [activeTagFilters]);

    const onCancel = useCallback(() => {
      dispatch(searchCancelButtonPressed(searchSessionId));
      screen.nav.goBack();
    }, [dispatch, screen.nav.goBack]);

    const loadNextBatch = useCallback(async () => {
      // Only fetch if we're not at the end of the list to avoid multiple refreshes in zero / low result count scenarios
      if (serverCount && serverRecipeResults.length < serverCount.count) {
        await dispatch(fetchNextSearchResults(searchSessionId));
      }
    }, [dispatch, serverCount, serverRecipeResults.length]);

    const onPressQuerySuggestion = useCallback<QuerySuggestionPressedHandler>(
      ({ value, index, type }) => {
        dispatch(
          querySuggestionPressed({
            sessionId: searchSessionId,
            suggestionValue: value,
            suggestionIndex: index,
            suggestionType: type,
          })
        );
        onSubmitBase();
      },
      [dispatch, searchSessionId, onSubmitBase]
    );

    const onChangeSearchInputText = useCallback(
      (text: string) => {
        dispatch(setSearchQueryFilter(searchSessionId, text));
      },
      [dispatch, searchSessionId, query]
    );

    return (
      <ScreenView scrollView={false} paddingHorizontal="none" paddingVertical="headerAndBottomTabBar">
        <SearchAndFilterBar
          ref={textInputRef}
          searchPhrase={query}
          keyboardReturnKeyType="search"
          placeholderText={searchPlaceholderText}
          onChangeSearchPhrase={onChangeSearchInputText}
          onPressSubmit={onSubmitTextInput}
          showCancelButton
          onPressCancel={onCancel}
          onFocus={onFocusTextInput}
          autoFocus
          activeTagFilters={activeTagFilters}
          onPressAddFilterButton={onPressAddFilterButton}
          onPressFilterSuggestion={onPressTagFilterSuggestion}
          onRemoveFilter={onPressRemoveTagFilter}
        />
        <Spacer vertical={1} />
        {!querySubmitted && (
          <SearchQuerySuggestions searchSessionId={searchSessionId} onPressItem={onPressQuerySuggestion} />
        )}
        {querySubmitted && (
          <SearchResults
            entities={entityProfile ? undefined : entityResults}
            libraryRecipes={entityProfile ? undefined : libraryResults}
            serverRecipes={serverRecipeResults}
            serverRecipeCount={serverCount?.count}
            serverRecipeCountMaxExceeded={serverCount?.maxExceeded}
            onEndReached={loadNextBatch}
            serverResultsLoading={loadingServerResults}
            searchSessionId={searchSessionId}
          />
        )}
      </ScreenView>
    );
  },
  {
    serializer: {
      analyticsContext: s => s,
      knownAuthorId: {
        fn: s => s,
        optional: true,
      },
      knownPublisherId: {
        fn: s => s,
        optional: true,
      },
    },
    parser: {
      analyticsContext: s => s as SearchQueryStartedFrom,
      knownAuthorId: {
        fn: s => s as KnownAuthorId,
        optional: true,
      },
      knownPublisherId: {
        fn: s => s as KnownPublisherId,
        optional: true,
      },
    },
  }
);
