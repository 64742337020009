import React from "react";
import { useAuthedUserId } from "../lib/system/SystemSelectors";
import { useCallback } from "react";
import { useDispatch } from "../lib/redux/Redux";
import { getEncodedSupportInfo } from "../lib/system/SystemThunks";
import { log } from "../Log";
import { TBody } from "./Typography";
import { Linking, View } from "react-native";
import { globalStyleColors } from "./GlobalStyles";

const strings = {
  supportInstructions: "For help, or to report an issue, please email us at ",
  subject: "Help with Deglaze",
  divider: "\n\n\n---- Please do not edit below this line ----\n",
};

export const SupportLink = React.memo(() => {
  const dispatch = useDispatch();
  const userId = useAuthedUserId();
  const emailAddress = userId ? `help+${userId}@deglaze.app` : "help@deglaze.app";

  const openEmail = useCallback(async () => {
    let link = `mailto:${emailAddress}?`;
    link += `subject=${encodeURIComponent(strings.subject)}`;
    try {
      const info = await dispatch(getEncodedSupportInfo());
      link += `&body=${encodeURIComponent(strings.divider)}${encodeURIComponent(info)}`;
    } catch (err) {
      log.errorCaught("Error generating support link. Opening link with no info.", err);
    } finally {
      Linking.openURL(link).catch(err => log.errorCaught("Error opening support mailto link", err));
    }
  }, [dispatch, emailAddress]);

  return (
    <TBody onPress={openEmail} color={globalStyleColors.colorTextLink} fontWeight="medium">
      help@deglaze.app
    </TBody>
  );
});

export const SupportInstructions = React.memo(() => {
  return (
    <View>
      <TBody>
        {strings.supportInstructions}
        <SupportLink />
        <TBody>.</TBody>
      </TBody>
    </View>
  );
});
