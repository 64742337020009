import React, { PropsWithChildren, useEffect, useMemo } from "react";
import { StyleSheet, View, useWindowDimensions } from "react-native";
import { Gesture, GestureDetector } from "react-native-gesture-handler";
import Animated, {
  Extrapolation,
  SharedValue,
  interpolate,
  runOnJS,
  useAnimatedProps,
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withSequence,
  withSpring,
  withTiming,
} from "react-native-reanimated";
import Svg, { Path } from "react-native-svg";
import { globalStyleColors, Opacity, RgbaColor } from "./GlobalStyles";
import { Haptics } from "./Haptics";

const config = {
  /**
   * How far you need to swipe to trigger action, as percentage of screen width
   * */
  swipeThreshold: 0.2,
  /**
   * Duration of the swipe animation once released past the threshold
   */
  swipeCompletionDurationMs: 75,
  /**
   * Size of action icon when the swipe animation begins and completes
   * */
  actionIconStartSize: 24,
  actionIconEndSize: 56,
  /**
   * Distance from edge of screen (right, left) of action icons
   * */
  actionIconOffsetX: "5%",
  /**
   * Colors for icon displayed when a swipe occurs
   * */
  completedIconFillColor: globalStyleColors.rgba("colorAccentCool"),
  completedIconStrokeColor: globalStyleColors.rgba("black", "medium"),
  completedIconStrokeWidth: 0.5,
  /**
   * Defines the minimum horizontal movement required to trigger the swipe gesture. This
   * setting fine tunes behavior with simultaneous gesture handlers, i.e. embedding in
   * a ScrollView. See https://docs.swmansion.com/react-native-gesture-handler/docs/1.10.3/api/gesture-handlers/pan-gh/#activeoffsetx.
   */
  minSwipeXtoActivate: [-10, 10],
};

interface Props {
  onSwipedLeft: () => void;
  onSwipedRight: () => void;
  actionStyle: ActionStyle;
  minHeight: number;
  disabled?: boolean;
  showSwipeHint?: boolean;
}

type ActionStyle = "completeAction" | "undoAction";

function useSwipeThresholds() {
  const windowDimensions = useWindowDimensions();

  const screenWidth = windowDimensions.width;
  const swipeLeftThreshold = config.swipeThreshold * -screenWidth;
  const swipeRightThreshold = config.swipeThreshold * screenWidth;

  return { screenWidth, swipeLeftThreshold, swipeRightThreshold };
}

export const SwipeableRow = React.memo((props: PropsWithChildren<Props>) => {
  const { screenWidth, swipeLeftThreshold, swipeRightThreshold } = useSwipeThresholds();
  const translateX = useSharedValue(0);

  const isItemDismissed = useSharedValue<-1 | 0 | 1>(0);

  const { onSwipedLeft, onSwipedRight, disabled } = props;

  const panGesture = useMemo(
    () =>
      Gesture.Pan()
        .enabled(!disabled)
        .activeOffsetX(config.minSwipeXtoActivate)
        .onUpdate(e => {
          translateX.value = e.translationX;

          // Check if a threshold has been crossed and update animation state + trigger haptic feedback
          const isLeftSwipe = translateX.value < 0;
          if (
            (isLeftSwipe && translateX.value < swipeLeftThreshold) ||
            (!isLeftSwipe && translateX.value > swipeRightThreshold)
          ) {
            if (Math.abs(isItemDismissed.value) !== 1) {
              runOnJS(Haptics.feedback)("itemStatusChanged");
              isItemDismissed.value = isLeftSwipe ? -1 : 1;
            }
          } else {
            isItemDismissed.value = 0;
          }
        })
        .onEnd(() => {
          // If we're passed a threshold when the finger goes up, trigger the completion
          if (Math.abs(isItemDismissed.value) === 1) {
            const isLeftSwipe = isItemDismissed.value < 0;
            const screenBoundaryX = isLeftSwipe ? -screenWidth : screenWidth;
            const onSwiped = isLeftSwipe ? onSwipedLeft : onSwipedRight;

            translateX.value = withTiming(screenBoundaryX, { duration: config.swipeCompletionDurationMs }, () => {
              runOnJS(onSwiped)();
            });
          } else {
            translateX.value = withTiming(0);
          }
        }),
    [
      screenWidth,
      isItemDismissed,
      translateX,
      swipeLeftThreshold,
      swipeRightThreshold,
      onSwipedLeft,
      onSwipedRight,
      disabled,
    ]
  );

  // This style controls the horizontal movement of the component as the user swipes
  const childContainerAnimatedStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateX: translateX.value,
        },
      ],
    };
  });

  // Resets the shared values when the actionStyle prop toggles (status change). Without this, the component remains
  // in the final animated state until it's unmounted. The timeout prevents conflict with the onSwipe call and a
  // subsequent re-render, which can cause jank if this runs first
  useEffect(() => {
    setTimeout(() => {
      translateX.value = 0;
      isItemDismissed.value = 0;
    }, 0);
  }, [props.actionStyle]);

  useEffect(() => {
    if (props.showSwipeHint) {
      translateX.value = withDelay(
        750,
        withSequence(
          withSpring(swipeLeftThreshold, { stiffness: 30, damping: 10, mass: 1, restDisplacementThreshold: 0.5 }),
          withTiming(0)
        )
      );
    }
  }, [props.showSwipeHint]);

  return (
    <View style={{ minHeight: props.minHeight }}>
      <ActionIcon
        position="left"
        minHeight={props.minHeight}
        translateX={translateX}
        isItemDismissed={isItemDismissed}
      />
      <ActionIcon
        position="right"
        minHeight={props.minHeight}
        translateX={translateX}
        isItemDismissed={isItemDismissed}
      />
      <GestureDetector gesture={panGesture}>
        <Animated.View style={childContainerAnimatedStyle}>{props.children}</Animated.View>
      </GestureDetector>
    </View>
  );
});

const ActionIcon = React.memo(
  (props: {
    position: "right" | "left";
    minHeight: number;
    translateX: SharedValue<number>;
    isItemDismissed: SharedValue<-1 | 0 | 1>;
  }) => {
    const { swipeLeftThreshold, swipeRightThreshold } = useSwipeThresholds();
    const { position, translateX, isItemDismissed } = props;

    // This style animates the size of the action icon as the user swipes right or left,
    // and also ensures that the icon opposite is not shown.
    const iconAnimatedStyle = useAnimatedStyle(() => {
      if (translateX.value === 0) {
        return {
          opacity: Opacity.transparent,
        };
      }

      if ((position === "left" && translateX.value < 0) || (position === "right" && translateX.value > 0)) {
        return {
          opacity: Opacity.transparent,
        };
      }

      const swipeThreshold = position === "left" ? swipeRightThreshold : swipeLeftThreshold;
      const scaleTo = config.actionIconEndSize / config.actionIconStartSize;

      const scaleInterpolation = interpolate(translateX.value, [0, swipeThreshold], [1, scaleTo], Extrapolation.CLAMP);

      return {
        opacity: Opacity.opaque,
        transform: [
          {
            scale: scaleInterpolation,
          },
        ],
      };
    }, [translateX, position, swipeLeftThreshold, swipeRightThreshold]);

    return (
      <View
        style={[{ [props.position]: config.actionIconOffsetX }, styles.iconContainer, { minHeight: props.minHeight }]}
      >
        <Animated.View style={[styles.icon, iconAnimatedStyle]}>
          <IconCheck
            animationProgress={isItemDismissed}
            fillColor={config.completedIconFillColor}
            strokeColor={config.completedIconStrokeColor}
            strokeWidth={config.completedIconStrokeWidth}
          />
        </Animated.View>
      </View>
    );
  }
);

const AnimatedPath = Animated.createAnimatedComponent(Path);

// This is an SVG icon component that supports animation of fill and stroke via Reanimated. The animationProgress
// drives the transitions of the animated properties.
const IconCheck = React.memo(
  (props: {
    fillColor: RgbaColor;
    strokeColor: RgbaColor;
    strokeWidth: number;
    animationProgress: SharedValue<number>;
  }) => {
    const { animationProgress } = props;

    const animatedPathProps = useAnimatedProps(() => {
      return {
        fillOpacity: Math.abs(animationProgress.value),
        strokeOpacity: interpolate(
          animationProgress.value,
          [-1, 0, 1],
          [Opacity.transparent, Opacity.medium, Opacity.transparent],
          Extrapolation.CLAMP
        ),
      };
    }, [animationProgress]);

    return (
      <Svg width={"100%"} height={"100%"} viewBox={[0, 0, 24, 24].join(" ")} fill="none">
        <AnimatedPath
          animatedProps={animatedPathProps}
          fill={props.fillColor}
          stroke={props.strokeColor}
          strokeWidth={props.strokeWidth}
          d="M16.218 8.306a1.044 1.044 0 0 1 1.577 1.36l-.1.116-6.608 6.607c-.37.37-.95.405-1.36.102l-.116-.101-3.305-3.304a1.044 1.044 0 0 1 1.36-1.578l.116.101 2.566 2.565 5.87-5.868Z"
        />
      </Svg>
    );
  }
);

const styles = StyleSheet.create({
  iconContainer: {
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    width: config.actionIconStartSize,
    height: config.actionIconStartSize,
  },
});
