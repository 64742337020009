import { withScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { StyleSheet, View } from "react-native";
import { SignIn } from "../components/SignIn";
import { globalStyleColors, globalStyleConstants } from "../components/GlobalStyles";
import { SignInScreenProps } from "../navigation/NavTree";
import { tryParseBool } from "@eatbetter/common-shared";
import { useMemo } from "react";
import { HeaderProps } from "../components/ScreenHeaders";
import { DocumentHeadEditProps } from "../components/DocumentHeadEdit/DocumentHeadInterfaces";
import { useBottomTabBarDimensions } from "../navigation/TabBar";
import { Splash } from "../components/Splash";

const backgroundColor = globalStyleColors.colorAccentMidDark;

export const SignInScreen = withScreenContainer(
  "SignInScreen",
  (props: SignInScreenProps) => {
    const header = useMemo<HeaderProps | undefined>(() => {
      if (props.showBackButton) {
        return {
          type: "default",
          title: "",
          backgroundColor: "transparent",
        };
      }

      return undefined;
    }, [props.showBackButton]);

    const webMeta = useMemo<DocumentHeadEditProps>(() => {
      return {
        themeColor: backgroundColor,
      };
    }, []);

    const { bottomTabBarHeight } = useBottomTabBarDimensions();

    const paddingBottom =
      bottomTabBarHeight > 0 ? bottomTabBarHeight + globalStyleConstants.unitSize : 2 * globalStyleConstants.unitSize;

    return (
      <ScreenView
        paddingHorizontal={false}
        paddingVertical={false}
        scrollView={false}
        header={header}
        webMeta={webMeta}
        backgroundColor={backgroundColor}
      >
        <Splash />
        <View style={[styles.container, { marginBottom: paddingBottom }]}>
          <SignIn redirectPath={props.redirectPath} allowAnonymousIfAvailable />
        </View>
      </ScreenView>
    );
  },
  {
    parser: {
      redirectPath: { optional: true, fn: s => s },
      showBackButton: { optional: true, fn: s => tryParseBool(s) ?? false },
    },
    serializer: {
      redirectPath: { optional: true, fn: s => s },
      showBackButton: { optional: true, fn: s => (s ? "1" : "0") },
    },
    nameRemapping: {
      redirectPath: "r",
      showBackButton: "b",
    },
  }
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-end",
    paddingHorizontal: 20,
  },
});
