import { EditRecipeArgs, UserRecipe } from "@eatbetter/recipes-shared";
import { useDispatch } from "../lib/AdminRedux";
import { EditUserRecipeOnSaveArgs, RecipeEditControl } from "@eatbetter/ui-shared";
import { addPhoto } from "../lib/photos/AdminPhotoThunks";
import { editUserRecipeOnBehalfOfUser } from "../lib/recipes/AdminRecipesThunks";
import { View } from "react-native";

export const EditUserRecipe = (props: { initialRecipe: UserRecipe; onDone: () => void }) => {
  const dispatch = useDispatch();

  const onSave = async (args: EditUserRecipeOnSaveArgs) => {
    const photo = args.newRecipePhoto ? await dispatch(addPhoto(args.newRecipePhoto)) : props.initialRecipe.photo;

    const editArgs: EditRecipeArgs = {
      id: props.initialRecipe.id,
      version: props.initialRecipe.version,
      description: args.description,
      ingredients: args.ingredients,
      instructions: args.instructions,
      title: args.title,
      photo,
      time: args.time,
      recipeYield: args.recipeYield,
    };

    await dispatch(editUserRecipeOnBehalfOfUser(editArgs));
    props.onDone();
  };

  return (
    <View>
      <RecipeEditControl type="adminUser" initialRecipe={props.initialRecipe} onSave={onSave} />
    </View>
  );
};
