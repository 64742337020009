import React, { useCallback, useMemo, useState } from "react";
import { globalStyleColors, globalStyleConstants } from "./GlobalStyles";
import { Pressable } from "./Pressable";
import { TBody } from "./Typography";
import { Spacer } from "./Spacer";
import { StyleSheet } from "react-native";
import { Haptics } from "./Haptics";
import { bottomThrow } from "@eatbetter/common-shared";
import { SelectedCircle } from "./SelectedCircle";
import { smallScreenBreakpoint, useResponsiveDimensions } from "./Responsive";

export type SurveySelectMode = "singleSelect" | "multiSelect";
export type SurveyData<TKey> = Array<{ key: TKey; displayText: string | (() => React.ReactElement) }>;
type SelectedOption<TKey> = { key: TKey; index: number };

export function useSurveyOptions<TKey extends string>(type: SurveySelectMode, surveyData: SurveyData<TKey>) {
  const [selectedOptions, setSelectedOptions] = useState<Array<SelectedOption<TKey>>>([]);

  const onPressOption = useCallback(
    ({ key, index }: SelectedOption<string>) => {
      setSelectedOptions(prev => {
        switch (type) {
          case "singleSelect": {
            if (prev[0]?.index === index) {
              return prev;
            }
            return [{ key: key as TKey, index }];
          }
          case "multiSelect": {
            if (prev.some(i => i.index === index)) {
              return prev.filter(i => i.index !== index);
            }
            return [...prev, { index, key: key as TKey }];
          }
          default:
            bottomThrow(type);
        }
      });
      Haptics.feedback("itemStatusChanged");
    },
    [type, setSelectedOptions]
  );

  const renderSurveyOptions = useCallback(() => {
    return (
      <SurveyOptions
        options={surveyData}
        onPressOption={onPressOption}
        selectedIndexes={selectedOptions.map(i => i.index)}
        multiselect={type === "multiSelect"}
      />
    );
  }, [surveyData, onPressOption, selectedOptions]);

  return useMemo(
    () => ({
      selected: selectedOptions,
      renderSurveyOptions,
    }),
    [selectedOptions, renderSurveyOptions]
  );
}

const SurveyOptions = React.memo(
  (props: {
    options: SurveyData<string>;
    selectedIndexes?: number[];
    onPressOption: (option: SelectedOption<string>) => void;
    multiselect?: boolean;
  }) => {
    return (
      <>
        {props.options.map((i, idx) => {
          return (
            <SelectableOption
              key={i.key}
              index={idx}
              isSelected={!!props.selectedIndexes?.some(i => i === idx)}
              optionKey={i.key}
              displayText={i.displayText}
              onPress={props.onPressOption}
              showSelectedCircle={props.multiselect}
            />
          );
        })}
      </>
    );
  }
);

const SelectableOption = React.memo(
  (props: {
    onPress: (option: SelectedOption<string>) => void;
    optionKey: string;
    displayText: string | (() => React.ReactElement);
    index: number;
    isSelected: boolean;
    showSelectedCircle?: boolean;
  }) => {
    const fontWeight = props.isSelected ? "medium" : "normal";
    const fontColor = props.isSelected ? globalStyleColors.colorAccentCool : undefined;
    const borderColor = props.isSelected ? { borderColor: globalStyleColors.colorAccentCool } : {};
    const backgroundColor = props.isSelected
      ? { backgroundColor: globalStyleColors.rgba("colorAccentCool", 0.05) }
      : {};

    const { defaultFontScale } = useResponsiveDimensions();

    const paddingVertical = { paddingVertical: defaultFontScale * 1.1 * globalStyleConstants.unitSize };

    const onPress = useCallback(() => {
      props.onPress({ index: props.index, key: props.optionKey });
    }, [props.index, props.optionKey, props.displayText, props.onPress]);

    return (
      <>
        {props.index !== 0 && <Spacer vertical={1} />}
        <Pressable
          onPress={onPress}
          style={[styles.optionButton, borderColor, backgroundColor, paddingVertical]}
          noFeedback
        >
          {typeof props.displayText === "string" && (
            <TBody
              color={fontColor}
              enableFontScaling="upOnly"
              scale={defaultFontScale}
              fontWeight={fontWeight}
              numberOfLines={1}
              adjustsFontSizeToFit
            >
              {props.displayText}
            </TBody>
          )}
          {typeof props.displayText !== "string" && props.displayText()}
          {!!props.showSelectedCircle && <SelectedCircle isSelected={props.isSelected} />}
        </Pressable>
      </>
    );
  }
);

const styles = StyleSheet.create({
  optionButton: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    maxWidth: smallScreenBreakpoint,
    alignSelf: "center",
    borderRadius: 12,
    borderWidth: 1,
    borderColor: globalStyleColors.rgba("colorAccentCool", "light"),
    paddingHorizontal: globalStyleConstants.defaultPadding,
    overflow: "hidden",
  },
});
