import { defaultTimeProvider } from "@eatbetter/common-shared";
import { useEffect, useState } from "react";

// this is a bit hacky, but should work fine.
// a better pattern might be to have the home screen
// set some state once it is done rendering and then for this
// hook to key off of that.
export const appStartTime = defaultTimeProvider();

/**
 * If the app starts up with persisted data, the initial nav render can
 * be slow because we do not lazy load the tabs. Using this hook gives screens
 * an easy way to render only minimal content (for example, a spinner) until
 * the start page has a chance to load. The goal here is to find the right balance
 * of quickly rendering the start page and not having too long of a delay when switching
 * tabs.
 */
export const useTabRenderDelay = (delayInMs: number, isFocused: boolean, timeProvider = defaultTimeProvider) => {
  // delayDone should never be set to false outside of the initializer
  // so, if the screen is focused initially, always render initially
  // if it's not focused initially, and not enough time has elapsed since startup, set delayDone to false
  const [delayDone, setDelayDone] = useState(isFocused || timeProvider() - appStartTime > delayInMs);

  // if we start with delayDone false, set a timeout to update delayDone to true
  useEffect(() => {
    if (!delayDone) {
      const delay = appStartTime + delayInMs - timeProvider();
      if (delay >= 0) {
        const h = setTimeout(() => {
          setDelayDone(true);
        }, delay);
        return () => clearTimeout(h);
      }
    }

    return () => {};
  }, [delayDone]);

  // as soon as the screen is focused, we don't care about the delay anymore
  useEffect(() => {
    if (!delayDone && isFocused) {
      setDelayDone(true);
    }
  }, [delayDone, isFocused]);

  return delayDone;
};
