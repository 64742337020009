import {
  KnownAuthorProfileInfo,
  KnownPublisherProfileInfo,
  NextFollowersStart,
  SocialEntity,
  SocialPostId,
} from "@eatbetter/posts-shared";
import { useDispatch, useSelector } from "../redux/Redux";
import { useCallback, useEffect } from "react";
import {
  knownAuthorMounted,
  knownAuthorUnmounted,
  knownPublisherMounted,
  knownPublisherUnmounted,
  postDetailMounted,
  postDetailUnmounted,
} from "./SocialSlice";
import {
  loadFollowers,
  loadFollowing,
  loadKnownAuthorProfileInfo,
  loadKnownPublisherProfileInfo,
  loadPost,
} from "./SocialThunks";
import { log } from "../../Log";
import { usePost } from "./SocialSelectors";
import { UserId } from "@eatbetter/common-shared";
import { useAuthedUserId } from "../system/SystemSelectors";
import { usePagedData } from "../UsePagedData";
import { KnownAuthorId, KnownPublisherId } from "@eatbetter/recipes-shared";
import { displayUnexpectedErrorAndLog } from "../Errors";
import { SetWaitingHandler } from "../Types";

/**
 * For use on post detail screens. Fetches the psot and ensures the post is not deleted from redux by the periodic fetching logic
 * while the hook is mounted.
 * @param id
 */
export const usePostForDetailScreen = (id: SocialPostId) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(postDetailMounted(id));
    dispatch(loadPost(id)).catch(err =>
      log.errorCaught("Unexpected error loading post in usePostForDetailScreen", err, { id })
    );
    return () => {
      dispatch(postDetailUnmounted(id));
    };
  }, []);

  return usePost(id);
};

export const useFollowers = (userId: UserId | undefined, type: "following" | "followers") => {
  const authedUserId = useAuthedUserId();
  const uid = userId ?? authedUserId;
  const dispatch = useDispatch();

  const fetch = useCallback(
    async (
      start?: NextFollowersStart
    ): Promise<{ items: SocialEntity[]; next: NextFollowersStart | undefined } | undefined> => {
      if (!uid) {
        return undefined;
      }

      if (type === "followers" && uid !== authedUserId) {
        // Don't fetch followers for other users. This allows us to use a single component for followers/following and abide by the
        // without breaking the rules of hooks.
        return undefined;
      }

      // Load enough on first load that it fills the viewport (if there are enough results)
      const fetchCount = !start ? 20 : 10;

      if (type === "followers") {
        const res = await dispatch(loadFollowers({ type, entityId: uid, count: fetchCount, start }));
        return { items: res.users, next: res.next };
      } else {
        const res = await dispatch(loadFollowing({ type, userId: uid, count: fetchCount, start }));
        return { items: res.entities, next: res.next };
      }
    },
    [uid, authedUserId, type, dispatch]
  );

  return usePagedData(fetch);
};

export const useOrFetchKnownAuthorProfile = (
  authorId: KnownAuthorId | undefined,
  setWaiting?: SetWaitingHandler
): KnownAuthorProfileInfo | undefined => {
  const dispatch = useDispatch();
  const profile = useSelector(s => {
    if (!authorId) {
      return undefined;
    }
    return s.social.knownAuthors[authorId];
  });

  useEffect(() => {
    if (!authorId) {
      return;
    }
    dispatch(knownAuthorMounted(authorId));
    return () => {
      dispatch(knownAuthorUnmounted(authorId));
    };
  }, [authorId]);

  useEffect(() => {
    if (!profile && authorId) {
      dispatch(loadKnownAuthorProfileInfo(authorId, setWaiting)).catch(err => {
        displayUnexpectedErrorAndLog(`Error loading known author profile for ${authorId}`, err);
      });
    }
  }, [profile, authorId]);

  return profile;
};

export const useOrFetchKnownPublisherProfile = (
  publisherId: KnownPublisherId | undefined,
  setWaiting?: SetWaitingHandler
): KnownPublisherProfileInfo | undefined => {
  const dispatch = useDispatch();
  const profile = useSelector(s => {
    if (!publisherId) {
      return undefined;
    }
    return s.social.knownPublishers[publisherId];
  });

  useEffect(() => {
    if (!publisherId) {
      return;
    }
    dispatch(knownPublisherMounted(publisherId));
    return () => {
      dispatch(knownPublisherUnmounted(publisherId));
    };
  }, [publisherId]);

  useEffect(() => {
    if (!profile && publisherId) {
      dispatch(loadKnownPublisherProfileInfo(publisherId, setWaiting)).catch(err => {
        displayUnexpectedErrorAndLog(`Error loading known publisher profile for ${publisherId}`, err);
      });
    }
  }, [profile, publisherId]);

  return profile;
};
