import { KnownPublisher, UnknownPublisher } from "@eatbetter/recipes-shared";
import { View } from "react-native";
import { TBody } from "../Typography";
import { TextInput } from "../TextInput";
import { Spacer } from "../Spacer";
import { emptyToUndefined, isUrl, UrlString } from "@eatbetter/common-shared";
import { AppAddPhotoArgs } from "../../lib/Types";
import { PhotoEditControl } from "./PhotoEditControl";
import { pickedPhotoToRef } from "../PhotoPicker";
import { PhotoRefExternal } from "@eatbetter/photos-shared";

export interface PartialUnknownPublisher extends Partial<UnknownPublisher> {
  type: "unknownPublisher";
}

export function getPublisher(initialPublisherValue: PartialUnknownPublisher | undefined): UnknownPublisher | undefined {
  if (initialPublisherValue === undefined) {
    return undefined;
  }

  if (!emptyToUndefined(initialPublisherValue.name)) {
    return undefined;
  }

  const ua: UnknownPublisher = {
    // splat this to prevent accidentally blowing away any properties if this control
    // doesn't get updated
    ...initialPublisherValue,
    type: "unknownPublisher",
    name: initialPublisherValue.name!.trim(),
    url: emptyToUndefined(initialPublisherValue.url) as UrlString,
    photo: initialPublisherValue.photo,
  };

  return ua;
}

interface Props {
  publisher?: PartialUnknownPublisher | KnownPublisher;
  disabled?: boolean;
  onChange: (a: PartialUnknownPublisher | undefined) => void;
  pickedPublisherPhoto?: AppAddPhotoArgs;
  setPickedPublisherPhoto: (v: AppAddPhotoArgs | undefined) => void;
}

export const PublisherEditControl = (props: Props) => {
  const onChange = (field: keyof PartialUnknownPublisher, value: string) => {
    const updated: PartialUnknownPublisher = {
      ...(props.publisher ?? {}),
      type: "unknownPublisher",
      [field]: value,
    };

    if (emptyToUndefined(updated.name) && emptyToUndefined(updated.url)) {
      props.onChange(undefined);
    }

    props.onChange(updated);
  };

  const onChangePhotoExternalUrl = (value: string) => {
    const photo: PhotoRefExternal | undefined = isUrl(value)
      ? {
          type: "external",
          url: value as UrlString,
        }
      : undefined;

    const updated: PartialUnknownPublisher = {
      ...(props.publisher ?? {}),
      type: "unknownPublisher",
      photo,
    };

    props.setPickedPublisherPhoto(undefined);
    props.onChange(updated);
  };

  const onPhotoPicked = (args: AppAddPhotoArgs) => {
    onChangePhotoExternalUrl("");
    props.setPickedPublisherPhoto(args);
  };

  const onPhotoCleared = () => {
    props.setPickedPublisherPhoto(undefined);
    onChangePhotoExternalUrl("");
  };

  return (
    <View>
      <TBody fontWeight="heavy">Type</TBody>
      <TBody>Unknown Publisher</TBody>
      <Spacer vertical={1} />
      <TBody fontWeight="heavy">Name</TBody>
      <TextInput
        editable={!props.disabled}
        value={props.publisher?.name}
        onChangeText={(s: string) => onChange("name", s)}
      />
      <Spacer vertical={1} />
      <TBody fontWeight="heavy">URL</TBody>
      {props.publisher?.type !== "knownPublisher" && (
        <TextInput
          editable={!props.disabled}
          value={props.publisher?.url}
          onChangeText={(s: string) => onChange("url", s)}
        />
      )}
      <Spacer vertical={2} />
      <PhotoEditControl
        admin
        disabled={props.disabled}
        align="left"
        sectionTitle="Photo"
        photo={{
          photoRef: pickedPhotoToRef(props.pickedPublisherPhoto) ?? props.publisher?.photo,
          style: "avatarSmall",
        }}
        onPhotoPicked={onPhotoPicked}
        onChangePhotoExternalUrl={onChangePhotoExternalUrl}
        onClear={onPhotoCleared}
      />
      <Spacer vertical={2} />
    </View>
  );
};
