import { useSelector } from "../redux/Redux";
import { RecipeTag, RecipeTagManifest } from "@eatbetter/recipes-shared/dist/RecipeTagTypes";
import { createSelector1, getCreateSelectorWithCacheSize } from "../redux/CreateSelector";
import { UserRecipeId } from "@eatbetter/recipes-shared";
import { AppRecipeTag } from "./RecipesSlice";
import { SearchSessionId, selectSearchSession } from "../search/SearchSlice";

/**
 * The manifest defines the tags for each category. manifest.categoryList
 * contains a category name and a list of tag IDs. The tag display text
 * is available in manifest.tagDisplay.
 */
export const useTagManifest = (): RecipeTagManifest =>
  useSelector(s => {
    const manifest = s.recipes.tagManifest;

    if (!manifest) {
      return {
        tagDisplay: {},
        categoryList: [],
      };
    }

    return manifest;
  });

const selectUserTags = createSelector1(
  s => s.recipes.entities,
  recipes => {
    const userTags = new Set<string>();
    Object.values(recipes).forEach(r => {
      if (!r || r.deleted || r.archived) {
        return;
      }

      r.tags.forEach(t => {
        if (t.type === "user") {
          userTags.add(t.tag);
        }
      });
    });
    return [...userTags.values()].sort();
  }
);

/**
 * The list of user tags (tags the user created themselves) that are used
 * on the user's non-deleted and non-archived recipes. This can be used
 * to populate the list of selectable user tags on the edit tag screen.
 */
export const useUserTags = () => useSelector(selectUserTags);

const selectFilterableTags = getCreateSelectorWithCacheSize(10)(
  [
    (_s, searchSessionId: SearchSessionId | undefined) => searchSessionId,
    s => s.recipes.tagManifest,
    s => s.recipes.entities,
  ],
  (sessionId, manifest, recipes) => {
    const tags: RecipeTag[] = [];
    const seen = new Set<string>();

    Object.values(recipes).forEach(r => {
      if (!r || r.deleted || r.archived) {
        return;
      }

      r.tags.forEach(t => {
        const key = `${t.type}:${t.tag}`;
        if (!seen.has(key)) {
          seen.add(key);
          tags.push(t);
        }
      });
    });

    // for server search, we want all tags available
    if (sessionId) {
      const tagIds = manifest.categoryList.flatMap(cl => cl.tags);
      const systemTags = tagIds.map<RecipeTag>(tagId => ({ tag: tagId, type: "system" }));
      const userTags = tags.filter(t => t.type === "user");
      return [...systemTags, ...userTags];
    } else {
      return tags;
    }
  }
);

/**
 * Return all tags that are used on any non-deleted/archived user recipe.
 * This is used to produce the list of tags on the filter screen, so we
 * only show the user tags with results.
 */
export const useFilterableTags = (searchSessionId: SearchSessionId | undefined) =>
  useSelector(s => selectFilterableTags(s, searchSessionId));

/**
 * The set of tags that are currently being used to filter results
 */
export const useFilterTags = (searchSessionId: SearchSessionId | undefined): AppRecipeTag[] =>
  useSelector(s => selectFilterTags(s, searchSessionId));

const selectFilterTags = getCreateSelectorWithCacheSize(10)(
  (_s, searchSessionId: SearchSessionId | undefined) => searchSessionId,
  (s, searchSessionId: SearchSessionId | undefined) =>
    searchSessionId ? selectSearchSession(s.search, searchSessionId)?.filters?.tags : undefined,
  (s, searchSessionId: SearchSessionId | undefined) =>
    searchSessionId ? selectSearchSession(s.search, searchSessionId)?.filters?.time : undefined,
  s => s.recipes.filters,
  (searchSessionId, searchTags, searchTime, libraryFilters) => {
    const { tags, time } = searchSessionId ? { tags: searchTags, time: searchTime } : libraryFilters;
    const tagFilters = tags ?? [];
    const timeFilters = time ?? [];
    return [...tagFilters, ...timeFilters];
  }
);

const selectRecipeTags = getCreateSelectorWithCacheSize(10)(
  [(s, recipeId: UserRecipeId) => s.recipes.entities[recipeId], s => s.recipes.entities],
  recipe => {
    const result =
      recipe?.tags.slice().sort((a, b) => {
        if (a.type === "user" && b.type === "system") {
          return -1;
        } else if (a.type === "system" && b.type === "user") {
          return 1;
        } else {
          return 0;
        }
      }) ?? [];

    return result;
  }
);

/**
 * The tags for a given recipe ID
 */
export const useRecipeTags = (recipeId: UserRecipeId) => useSelector(s => selectRecipeTags(s, recipeId));
