import { View } from "react-native";
import { globalStyleColors } from "./GlobalStyles";

interface Props {
  orientation: "row" | "column";
  lineWidth?: number;
  color?: string;
}

export const Separator = (props: Props) => {
  const lineWidth = props.lineWidth ? props.lineWidth : 1;
  const borderStartWidth = props.orientation === "column" ? lineWidth : undefined;
  const borderTopWidth = props.orientation === "row" ? lineWidth : undefined;
  return (
    <View
      style={{
        borderStartWidth,
        height: borderStartWidth ? "100%" : undefined,
        borderTopWidth,
        width: borderTopWidth ? "100%" : undefined,
        borderColor: props.color ? props.color : globalStyleColors.rgba("black", "xlight"),
      }}
    />
  );
};
