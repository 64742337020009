import { StoreType } from "../lib/redux/Redux";
import { Provider } from "react-redux";
import { NavigationRoot } from "../navigation/NavigationRoot";
import { navTree } from "../navigation/NavTree";
import { getNavTreeWithNames } from "../navigation/Screens";
import { GetNavStackComponents } from "../navigation/NavigationTypes";
import { AppErrorBoundary } from "./ErrorBoundary";
import { PersistGate } from "redux-persist/lib/integration/react";
import { Persistor } from "redux-persist/es/types";
import { FlexedSpinner } from "./Spinner";
import { setupURLPolyfill } from "react-native-url-polyfill";
import { Platform } from "react-native";
import { LoadCustomFonts } from "./Typography";
import { DocumentHeadProvider } from "./DocumentHeadProvider/DocumentHeadProvider";
import { OverlayProvider } from "./OverlayProvider";
import { PaywallDetectorProvider } from "./PaywallDetector";

navTree.setAndThrowIfAlreadySet(getNavTreeWithNames());

// react native doesn't fully implement URL, so use a polyfill
// without this, things like (new URL(...).search) fail
if (Platform.OS !== "web") {
  setupURLPolyfill();
}

export const AppRoot = (props: { store: StoreType; getStack?: GetNavStackComponents; persistor: Persistor }) => {
  return (
    <AppErrorBoundary componentName="AppRoot">
      <Provider store={props.store}>
        <LoadCustomFonts loading={<FlexedSpinner debugText="LoadCustomFonts" />}>
          <PersistGate persistor={props.persistor} loading={<FlexedSpinner debugText="PersistGate" />}>
            <DocumentHeadProvider>
              <OverlayProvider>
                <PaywallDetectorProvider>
                  <NavigationRoot getStack={props.getStack} />
                </PaywallDetectorProvider>
              </OverlayProvider>
            </DocumentHeadProvider>
          </PersistGate>
        </LoadCustomFonts>
      </Provider>
    </AppErrorBoundary>
  );
};
