import { useScreen, withScreenContainer } from "../navigation/ScreenContainer.tsx";
import { BookEditScreenProps } from "../navigation/NavTree.ts";
import { useCallback, useMemo, useState } from "react";
import { HeaderProps } from "../components/ScreenHeaders.tsx";
import { globalStyleColors } from "../components/GlobalStyles.ts";
import { ScreenView } from "../components/ScreenView.tsx";
import { EditUserRecipeAttributionArgs, UserEnteredBookAttribution, UserRecipeId } from "@eatbetter/recipes-shared";
import { THeading2 } from "../components/Typography.tsx";
import { View } from "react-native";
import { TextInput } from "../components/TextInput.tsx";
import { useLibraryRecipe } from "../lib/recipes/RecipesSelectors.ts";
import { ButtonRectangle } from "../components/Buttons.tsx";
import { emptyToUndefined } from "@eatbetter/common-shared";
import { PhotoEditControl } from "../components/recipes/PhotoEditControl.tsx";
import { pickedPhotoToRef } from "../components/PhotoPicker.tsx";
import { AppAddPhotoArgs } from "../lib/Types.ts";
import { Spacer } from "../components/Spacer.tsx";
import { addPhoto } from "../lib/photos/PhotoThunks.ts";
import { useDispatch } from "../lib/redux/Redux.ts";
import { displayUnexpectedErrorAndLog } from "../lib/Errors.ts";
import { editRecipeAttribution } from "../lib/recipes/RecipesThunks.ts";

export const BookEditScreen = withScreenContainer(
  "BookEditScreen",
  (props: BookEditScreenProps) => {
    const screen = useScreen();
    const dispatch = useDispatch();
    const recipe = useLibraryRecipe(props.recipeId);
    const [title, setTitle] = useState(
      props.info?.name ??
        recipe?.book?.name ??
        (recipe?.userEnteredAttribution?.type === "book" ? recipe.userEnteredAttribution.name : "")
    );
    const [author, setAuthor] = useState(
      props.info?.author ??
        recipe?.author?.name ??
        (recipe?.userEnteredAttribution?.type === "book" ? recipe.userEnteredAttribution.author : "")
    );
    const [pickedCoverPhoto, setPickedCoverPhoto] = useState<AppAddPhotoArgs | undefined>();
    const [photoCleared, setPhotoCleared] = useState(false);
    const [waiting, setWaiting] = useState(false);

    const existingPhoto =
      props.info?.photo ??
      recipe?.book?.photo ??
      (recipe?.userEnteredAttribution?.type === "book" ? recipe.userEnteredAttribution.photo : undefined);

    const onSave = useCallback(async () => {
      if (!recipe || title.trim() === "") {
        return;
      }

      try {
        // if we're editing an existing book, or editing attribution that was originally from a book, keep the ID around
        const editedBookId =
          recipe?.book?.id ??
          (recipe?.userEnteredAttribution?.type === "book" ? recipe.userEnteredAttribution.editedBookId : undefined);

        setWaiting(true);
        let photo = photoCleared ? undefined : existingPhoto;
        if (pickedCoverPhoto) {
          photo = await dispatch(addPhoto(pickedCoverPhoto));
        }

        const attribution: UserEnteredBookAttribution = {
          type: "book",
          name: title,
          author: author,
          editedBookId,
          photo,
        };

        const args: EditUserRecipeAttributionArgs = {
          recipeId: recipe.id,
          version: recipe.version,
          type: "userEntered",
          attribution,
        };

        await dispatch(editRecipeAttribution(args));
      } catch (err) {
        displayUnexpectedErrorAndLog("Error in BookEditScreen onSave", err, { props });
      } finally {
        setWaiting(false);
      }
    }, [recipe, title, author, existingPhoto]);

    const header = useMemo<HeaderProps>(() => {
      return {
        type: "native",
        backgroundColor: globalStyleColors.colorGreyLight,
        title: "BOOK EDIT SCREEN", //attribution-todo
        right: { type: "cancel", onPress: () => screen.nav.pop(props.popCount) },
      };
    }, [screen.nav.goBack]);

    return (
      <ScreenView header={header}>
        <THeading2>Book Details</THeading2>
        <View style={{ width: "100%" }}>
          <TextInput placeholderText="Title" value={title} onChangeText={setTitle} />
          <TextInput placeholderText="Author" value={author} onChangeText={setAuthor} />
        </View>
        <Spacer vertical={1} />
        <View>
          <PhotoEditControl
            admin={false}
            sectionTitle="Cover"
            photo={{ photoRef: pickedPhotoToRef(pickedCoverPhoto) ?? existingPhoto, style: "thumbnailXlarge" }}
            onPhotoPicked={setPickedCoverPhoto}
            onClear={() => setPhotoCleared(true)}
          />
        </View>
        <Spacer vertical={1} />
        <ButtonRectangle
          type="submit"
          title="Save"
          onPress={onSave}
          waiting={waiting}
          disabled={!emptyToUndefined(title)}
        />
      </ScreenView>
    );
  },
  {
    serializer: {
      recipeId: s => s,
      popCount: n => n.toString(),
      info: { optional: true, fn: s => JSON.stringify(s) },
    },
    parser: {
      recipeId: s => s as UserRecipeId,
      popCount: s => Number.parseInt(s),
      info: { optional: true, fn: s => JSON.parse(s) as Required<BookEditScreenProps>["info"] },
    },
  }
);
