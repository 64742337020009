export type FractionDisplayKey = "1/8" | "1/4" | "1/3" | "1/2" | "2/3" | "3/4";
export type FractionDisplay = { numerator: number; denominator: number; display: string };

/**
 * Structured fraction value and display string
 */
const fractions: { [K in FractionDisplayKey]: FractionDisplay } = {
  "1/8": { numerator: 1, denominator: 8, display: "⅛" },
  "1/4": { numerator: 1, denominator: 4, display: "¼" },
  "1/3": { numerator: 1, denominator: 3, display: "⅓" },
  "1/2": { numerator: 1, denominator: 2, display: "½" },
  "2/3": { numerator: 2, denominator: 3, display: "⅔" },
  "3/4": { numerator: 3, denominator: 4, display: "¾" },
} as const;

/**
 * Gets the `FractionDisplay` for the given key
 */
export function getFraction(key: FractionDisplayKey) {
  return fractions[key];
}

/**
 * Returns common fraction sets (e.g. quarters: 1/4, 1/2, 3/4) specified by the given type
 */
export function getFractionSet(type: "all" | "standard" | "quarters"): FractionDisplayKey[] {
  switch (type) {
    case "all": {
      return Object.keys(fractions) as FractionDisplayKey[];
    }
    case "standard": {
      return Object.keys(fractions).filter((i): i is FractionDisplayKey => i !== ("1/8" satisfies FractionDisplayKey));
    }
    case "quarters": {
      return ["1/4", "1/2", "3/4"] satisfies FractionDisplayKey[];
    }
  }
}

/**
 * Finds the closest fraction in the list of `allowedFractions` representing the specified `fractionalPart` value.
 * The `threshold` property specifies how close to a given fraction the value needs to be in order to trigger a match.
 */
export function findClosestFraction(
  fractionalPart: number,
  allowedFractions: FractionDisplayKey[],
  threshold: number
): FractionDisplay | undefined {
  // Find the fraction closest to the fractional part
  let closestFraction: FractionDisplay | undefined = undefined;
  let smallestDifference = Number.MAX_VALUE;

  allowedFractions.forEach(fractionKey => {
    const fraction = getFraction(fractionKey);
    const fractionValue = fraction.numerator / fraction.denominator;
    const difference = Math.abs(fractionalPart - fractionValue);

    if (difference < smallestDifference) {
      smallestDifference = difference;
      closestFraction = fraction;
    }
  });

  // Set a maximum acceptable difference for fraction matching
  const maxDifferencePercent = threshold ?? 0.05;

  if (closestFraction && smallestDifference <= maxDifferencePercent) {
    return closestFraction;
  }

  return undefined;
}
