import { useCallback, useEffect, useState } from "react";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { SettingsItem } from "../components/SettingsItem";
import { useDispatch, useSelector } from "../lib/redux/Redux";
import { TBody, TSecondary } from "../components/Typography";
import { log } from "../Log";
import { newId } from "@eatbetter/common-shared";
import { useAuthedUser } from "../lib/system/SystemSelectors";
import { Switch, View } from "react-native";
import Clipboard from "@react-native-clipboard/clipboard";
import { Spacer } from "../components/Spacer";
import {
  getReduxPersistValue,
  purgeReduxPersist,
  websocketDebugInfo,
  websocketPushTest,
} from "../lib/debug/DebugThunks";
import { CurrentEnvironment } from "../CurrentEnvironment";
import { ApiClient } from "../lib/ApiClient";
import { AppUpgradeScreenProps, navTree } from "../navigation/NavTree";
import {
  DEBUG_clearLaunchCarouselCompleted,
  DEBUG_clearUserPromptedForPush,
  homeScreenOnboardingShouldBeShown,
  navActionRequested,
} from "../lib/system/SystemSlice";
import { _DEBUG_ONLY_MEM_LEAK } from "../lib/util/DebugUtil";
import { globalStyleColors, globalStyleConstants } from "../components/GlobalStyles";
import { SocialTestHooks } from "../components/social/SocialTestHooks";
import { ContainerEnd } from "../components/Containers";
import { setDebugFeedScroll } from "../lib/social/SocialSlice";
import { useDebugFeedScrollText } from "../lib/social/SocialSelectors";
import { setShowPaywallStatusPill } from "../lib/debug/DebugSlice";
import { useShowPaywallStatusPill } from "../lib/debug/DebugSelectors";
import { clearShowNewFeatureAlertDismissed } from "../lib/system/SystemThunks";

const betaUpdateProps: AppUpgradeScreenProps = { allowBetaUpgrade: true };
const noBetaUpdateProps: AppUpgradeScreenProps = { allowBetaUpgrade: false };

export const DebugScreen = withScreenContainer("DebugScreen", () => {
  const screen = useScreen();
  const authedUser = useAuthedUser();
  const [logErrorId, setLogErrorId] = useState("");
  const dispatch = useDispatch();
  const websocketTest = useSelector(s => s.debug.tempWebsocketTest);
  const [saveLogsText, setSaveLogsText] = useState(getSaveLogsText(log.getSaveLogs()));
  const [lastPing, setLastPing] = useState({ time: "-", latency: "-" });
  const debugFeedScroll = !!useDebugFeedScrollText();
  const showPaywallStatusPill = useShowPaywallStatusPill();

  useEffect(() => {
    const getLastPing = () => {
      const websocketInfo = dispatch(websocketDebugInfo());
      const time = websocketInfo.lastPingResponse
        ? Math.floor((Date.now() - websocketInfo.lastPingResponse) / 1000).toString()
        : "-";
      const latency = `${websocketInfo.lastPingLatency?.toFixed(0) ?? "-"}ms`;
      setLastPing({ time, latency });
    };
    const handle = setInterval(getLastPing, 1000);
    return () => clearInterval(handle);
  }, []);

  const logError = useCallback(() => {
    try {
      throwError();
    } catch (err) {
      const id = newId();
      log.errorCaught(`Test error message from Debug screen ${id}`, err, { id });
      setLogErrorId(id);
    }
  }, []);

  const regionInfo = ApiClient.requestRegionStats;

  const savingLogs = log.getSaveLogs();
  const setSavingLogs = (b: boolean) => {
    log.setSaveLogs(b);
    setSaveLogsText(getSaveLogsText(log.getSaveLogs()));
  };

  return (
    <ScreenView header={{ type: "default", title: "Debug" }}>
      <SettingsItem
        title="Checkpoints"
        onPress={() => screen.nav.goTo("push", navTree.get.screens.debugCheckpoints)}
        navAction
      />
      {CurrentEnvironment.configEnvironment() !== "prod" && (
        <SettingsItem
          title="Social Test Hooks"
          onPress={() =>
            screen.nav.modal(navTree.get.screens.bottomSheet, {
              height: 300,
              content: <SocialTestHooks />,
            })
          }
          navAction
        />
      )}

      <SettingsItem
        title="Notifications"
        onPress={() => screen.nav.goTo("push", navTree.get.screens.debugNotifications)}
        navAction
      />

      <Spacer vertical={1.5} />
      <Toggle text="Debug Feed Scroll" value={debugFeedScroll} onToggle={b => dispatch(setDebugFeedScroll(b))} />

      <Spacer vertical={1.5} />
      <Toggle
        text="Show Paywall Status Pill"
        value={showPaywallStatusPill}
        onToggle={b => dispatch(setShowPaywallStatusPill({ enabled: b }))}
      />

      <SettingsItem title={saveLogsText} onPress={() => setSavingLogs(!savingLogs)} />
      <SettingsItem title={"View saved logs"} onPress={() => screen.nav.goTo("push", navTree.get.screens.debugLogs)} />
      <SettingsItem title="Clear userLastPromptedForPush" onPress={() => dispatch(DEBUG_clearUserPromptedForPush())} />
      <SettingsItem
        title="Clear system.launchCarouselCompleted"
        onPress={() => dispatch(DEBUG_clearLaunchCarouselCompleted())}
      />
      <SettingsItem title="Show home screen onboarding" onPress={() => dispatch(homeScreenOnboardingShouldBeShown())} />
      <SettingsItem
        title="Show new feature alert"
        onPress={() => dispatch(clearShowNewFeatureAlertDismissed("scaling"))}
      />
      <SettingsItem
        title="Open update screen (allow TestFlight)"
        onPress={() => {
          dispatch(navActionRequested({ screenName: "appUpgrade", props: betaUpdateProps }));
        }}
      />
      <SettingsItem
        title="Open update screen (no TestFlight)"
        onPress={() => {
          dispatch(navActionRequested({ screenName: "appUpgrade", props: noBetaUpdateProps }));
        }}
      />
      <CopyKVPair name="Anonymous" value={(!authedUser?.isRegistered).toString()} />
      {authedUser?.isRegistered && <CopyKVPair name="User Email" value={authedUser?.email?.address ?? "(no email)"} />}
      <CopyKVPair name="User ID" value={authedUser?.userId} />
      <CopyKVPair name="Household ID" value={authedUser?.householdId} />
      <CopyKVPair name="Git SHA" value={CurrentEnvironment.gitSha()} />
      <CopyKVPair name="Config Env" value={CurrentEnvironment.configEnvironment()} />
      <CopyKVPair name="Debug Build" value={CurrentEnvironment.debugBuild().toString()} />
      <CopyKVPair name="Hermes" value={(!!(global as any).HermesInternal).toString()} />
      <CopyKVPair name="Hermes GC" value={(global as any).HermesInternal?.getRuntimeProperties?.().GC} />

      <SettingsItem title="Volume screen" onPress={() => screen.nav.modal(navTree.get.screens.timerVolume)} />

      <SettingsItem title="Websocket Test" onPress={() => dispatch(websocketPushTest())} />
      <CopyKVPair name="Websocket push" value={websocketTest} />
      <CopyKVPair name="Websocket last ping received" value={`${lastPing.time}s ago`} />
      <CopyKVPair name="Websocket last ping latency" value={lastPing.latency} />
      <SettingsItem title="Log Error" onPress={logError} />
      <CopyKVPair name="Error ID to Search" value={logErrorId} />
      <Spacer vertical={1} />
      <SettingsItem title="Start Mem Leak" onPress={() => _DEBUG_ONLY_MEM_LEAK()} />
      <CopyKVPair
        name="Region info for responses"
        value={Object.entries(regionInfo)
          .map(e => {
            return `${e[0]}: ${e[1].toString()}`;
          })
          .join("\n")}
      />
      <SettingsItem
        title="Copy redux-persist asyncstorage key"
        onPress={() => {
          dispatch(getReduxPersistValue())
            .then(v => Clipboard.setString(v ?? "<null>"))
            .catch(err => log.errorCaught("Error getting redux persist value", err));
        }}
      />
      <SettingsItem title="Purge redux-persist" onPress={() => dispatch(purgeReduxPersist())} />
      <SettingsItem title="Throw error" onPress={throwError} />
    </ScreenView>
  );
});

const Toggle = (props: { text: string; value: boolean; onToggle: (b: boolean) => void }) => {
  return (
    <View style={{ flexDirection: "row", paddingBottom: globalStyleConstants.unitSize }}>
      <TBody>{props.text}</TBody>
      <ContainerEnd horizontal>
        <Switch value={props.value} onValueChange={props.onToggle} />
      </ContainerEnd>
    </View>
  );
};

function getSaveLogsText(saveLogs: boolean): string {
  return saveLogs ? "Stop saving logs" : "Save logs";
}

function throwError() {
  throw new Error("Threw an error for debugging!");
}

export const CopyKVPair = (props: { name: string; value: string | undefined }) => {
  return (
    <View
      style={{
        flexDirection: "row",
        padding: globalStyleConstants.minPadding,
        backgroundColor: globalStyleColors.rgba("colorGreyDark", "light"),
      }}
    >
      <TSecondary>{props.name}: </TSecondary>
      <TSecondary onPress={() => Clipboard.setString(props.value ?? "")}>{props.value}</TSecondary>
    </View>
  );
};
