import { AnalyticsEventAndProperties } from "@eatbetter/composite-shared";
import { log } from "../../Log";
import { AppsFlyerDeps, FirebaseAnalyticsDeps, MixpanelDeps } from "../Deps";
import { filterUndefinedFromObject } from "@eatbetter/common-shared";

export function processAnalyticsEvent(mixpanel: MixpanelDeps, event: AnalyticsEventAndProperties) {
  try {
    if (event.superProperties) {
      const props = filterUndefinedFromObject(event.superProperties);
      mixpanel.setSuperProperties(props);
    }

    event.events?.forEach(e => {
      log.debug(`Sending mixpanel event ${e.name}`, { properties: e.properties });
      mixpanel.sendEvent(e.name, e.properties);
    });

    if (event.setProperties) {
      log.debug("Setting mixpanel user properties", { properties: event.setProperties });
      const props = filterUndefinedFromObject(event.setProperties);
      mixpanel.setProperties(props);
    }

    if (event.incrementProperties) {
      log.debug("Incrementing mixpanel properties", { properties: event.incrementProperties });
      const props = filterUndefinedFromObject(event.incrementProperties);
      mixpanel.incrementProperties(props);
    }
  } catch (err) {
    log.errorCaught("Error in processAnalyticsEvent", err, { event });
  }
}

export function processAttributionEvent(
  firebase: FirebaseAnalyticsDeps | undefined,
  appsFlyer: AppsFlyerDeps | undefined,
  events: AnalyticsEventAndProperties["attributionEvents"]
) {
  if (firebase) {
    try {
      if (!events) {
        return;
      }

      events.forEach(e => {
        // firebase has annoying name requirements. Rather than changing everything, just convert here.
        const name = sanitizeNameForFirebase(e.name);
        const properties = Object.fromEntries(
          Object.entries(e.properties ?? {}).map(e => [sanitizeNameForFirebase(e[0]), e[1]])
        );
        firebase.sendEvent(name, properties);
      });
    } catch (err) {
      log.errorCaught("Error while sending firebase events", err, { events });
    }
  }

  if (appsFlyer) {
    try {
      if (!events) {
        return;
      }

      events.forEach(e => {
        appsFlyer.sendEvent(e.name, e.properties ?? {});
      });
    } catch (err) {
      log.errorCaught("Error while sending appsflyer events", err, { events });
    }
  }
}

function sanitizeNameForFirebase(s: string): string {
  return s.toLowerCase().replaceAll(" ", "_");
}
