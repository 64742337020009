import { LayoutAnimation, StyleSheet, View } from "react-native";
import { globalStyleColors, globalStyleConstants } from "./GlobalStyles";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { THeading2, TSecondary } from "./Typography";
import { Pressable } from "./Pressable";
import { IconEx } from "./Icons";
import { useAllCompletedCheckpoints } from "../lib/system/SystemSelectors";
import { UserCheckpoint } from "@eatbetter/users-shared";
import { log } from "../Log";
import { Spacer } from "./Spacer";
import { checkpointsCompleted } from "../lib/system/SystemSlice";
import { reportSurveyCompleted, reportSurveyDismissed, reportSurveyDisplayed } from "../lib/analytics/AnalyticsEvents";
import { useDispatch } from "../lib/redux/Redux";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { useScreen } from "../navigation/ScreenContainer";
import { navTree } from "../navigation/NavTree";
import { useToast } from "./Toast";
import { Haptics } from "./Haptics";
import { useFeedIsScrolled } from "../lib/social/SocialSelectors";
import { followingFeed } from "../lib/social/SocialSlice";

const strings = {
  otherOptionHeadline: "What would make Deglaze better for you?",
  otherOptionTextInputPlaceholder: "Make a wish here...",
  toast: "Thank you for your input!",
};

const config = {
  // The extra 10ms ensures that the how to video comes first and eliminates a race in conditional rendering when both components delay
  // render by the same amount (which was messing up our mixpanel events).
  delayRenderMs: 510,
};

interface SurveyContent {
  name: string;
  triggerCheckpoint?: UserCheckpoint;
  completedCheckpoint: UserCheckpoint;
  headline: string;
  options: string[];
  hasOtherOption?: boolean;
}

const surveys: SurveyContent[] = [
  {
    name: "Feature Survey v1.3",
    triggerCheckpoint: "featureSurvey_v1_3_eligible",
    completedCheckpoint: "featureSurvey_v1_3_completed",
    headline: "How can we make Deglaze better for you?",
    options: [
      "Grocery delivery integration",
      "Meal planning",
      "Add grocery list items with Alexa/Siri",
      "Search + browse all your friends' recipes",
      "Custom home feed with your friends and interests",
      "Scale recipes up or down",
      "Other",
    ],
    hasOtherOption: true,
  },
];

export function useSurveyCard() {
  const dispatch = useDispatch();
  const screen = useScreen();
  const toast = useToast();

  const [shouldRender, setShouldRender] = useState(false);

  const allCheckpoints = useAllCompletedCheckpoints();
  const isListScrolled = useFeedIsScrolled(followingFeed);

  const activeSurveys = useMemo(() => {
    return surveys.filter(s => {
      const eligible = s.triggerCheckpoint === undefined || allCheckpoints.includes(s.triggerCheckpoint);
      const completed = allCheckpoints.includes(s.completedCheckpoint);
      return eligible && !completed;
    });
  }, [allCheckpoints]);

  const survey = useMemo(() => {
    if (activeSurveys.length < 1) {
      return undefined;
    }

    if (activeSurveys.length === 1) {
      return activeSurveys[0];
    }

    log.error("useSurvey(): Expected only one survey but received multiple. Using last index", {
      survey: activeSurveys,
    });
    return activeSurveys.at(-1);
  }, [activeSurveys]);

  // Delay and animate entry of the survey
  useEffect(() => {
    let delayRenderTimer: ReturnType<typeof setTimeout>;

    if (!shouldRender && screen.nav.focused && survey && !isListScrolled) {
      delayRenderTimer = setTimeout(() => {
        // Delay render the survey and animate entry
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        setShouldRender(true);
      }, config.delayRenderMs);
    } else if (shouldRender && !survey) {
      // Animate exit of the survey when dismissed
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      setShouldRender(false);
    }

    return () => {
      if (delayRenderTimer) {
        // Clear timeout if user navigates away before it fires
        clearTimeout(delayRenderTimer);
      }
    };
  }, [survey, screen.nav.focused, isListScrolled]);

  const surveyKey = useRef(survey?.completedCheckpoint);

  const completeCheckpoint = useCallback(() => {
    if (!survey) {
      return;
    }
    surveyKey.current = survey.completedCheckpoint;
    dispatch(checkpointsCompleted([survey.completedCheckpoint]));
  }, [survey, dispatch]);

  // Uncomment this to support undo with toast
  // const clearCheckPoint = useCallback(() => {
  //   if (!surveyKey.current) {
  //     return;
  //   }
  //   dispatch(checkpointsCleared([surveyKey.current]));
  //   toast.hide();
  // }, [surveyKey.current, dispatch])

  const onSurveyCompleted = useCallback(
    (index: number, optionText: string, otherText?: string) => {
      if (!survey) {
        return;
      }

      const event = reportSurveyCompleted({
        surveyName: survey.name,
        surveyOptions: survey.options,
        surveySelectionIndex: index,
        surveySelection: optionText,
        surveyOtherText: otherText,
      });
      dispatch(analyticsEvent(event));
      completeCheckpoint();
      toast.show({ type: "infoToast", message: strings.toast });
    },
    [survey, dispatch, completeCheckpoint]
  );

  const onSubmitOtherOption = useCallback(
    (text: string) => {
      if (!survey) {
        return;
      }

      const otherOptionIndex = survey.options.length - 1;
      const otherOptionText = survey.options[otherOptionIndex]!;
      onSurveyCompleted(otherOptionIndex, otherOptionText, text);
      Haptics.feedback("operationSucceeded");
    },
    [survey, onSurveyCompleted]
  );

  const onPressOption = useCallback(
    (index: number, optionText: string) => {
      if (!survey) {
        return;
      }

      if (survey.hasOtherOption && index === survey.options.length - 1) {
        Haptics.feedback("itemStatusChanged");
        screen.nav.modal(navTree.get.screens.surveyOtherOptionForm, {
          label: strings.otherOptionHeadline,
          textInputPlaceholder: strings.otherOptionTextInputPlaceholder,
          onSubmit: onSubmitOtherOption,
        });
        return;
      }

      onSurveyCompleted(index, optionText);
      Haptics.feedback("operationSucceeded");
    },
    [survey, dispatch, completeCheckpoint]
  );

  const onDismissSurvey = useCallback(() => {
    if (!survey) {
      return;
    }

    const event = reportSurveyDismissed({
      surveyName: survey.name,
      surveyOptions: survey.options,
    });
    dispatch(analyticsEvent(event));
    completeCheckpoint();
    Haptics.feedback("itemStatusChanged");
  }, [survey, dispatch, completeCheckpoint]);

  const surveyCard = useMemo(() => {
    if (!shouldRender || !survey) {
      return;
    }

    return <SurveyCard surveyContent={survey} onPressOption={onPressOption} onPressClose={onDismissSurvey} />;
  }, [shouldRender, survey, onPressOption, onDismissSurvey]);

  return surveyCard;
}

const SurveyCard = React.memo(
  (props: {
    surveyContent: SurveyContent;
    onPressOption: (index: number, optionText: string) => void;
    onPressClose: () => void;
  }) => {
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(analyticsEvent(reportSurveyDisplayed({ surveyName: props.surveyContent.name })));
    }, [dispatch, props.surveyContent.name]);

    const shuffledOptions = useMemo(() => {
      const options = props.surveyContent.options;
      const shuffle = (items: string[]) => items.sort(() => Math.random() - 0.5);

      if (props.surveyContent.hasOtherOption) {
        const optionsExcludingOther = options.slice(0, -1);
        return [...shuffle(optionsExcludingOther), options[options.length - 1]!];
      }

      return shuffle(options);
    }, [props.surveyContent]);

    return (
      <View style={styles.surveyCard}>
        <View style={styles.survey}>
          <View style={styles.headline}>
            <THeading2 align="center">{props.surveyContent.headline}</THeading2>
            <CloseButton onPress={props.onPressClose} />
          </View>
          <Spacer vertical={1} />
          <Options options={shuffledOptions} onPressOption={props.onPressOption} />
        </View>
      </View>
    );
  }
);

const CloseButton = React.memo((props: { onPress: () => void }) => {
  return (
    <Pressable onPress={props.onPress}>
      <IconEx opacity="opaque" />
    </Pressable>
  );
});

const Options = React.memo((props: { options: string[]; onPressOption: (index: number, text: string) => void }) => {
  const onPressOption = useCallback(
    (index: number, text: string) => {
      props.onPressOption(index, text);
    },
    [props.onPressOption]
  );

  return (
    <View style={{ flex: 1 }}>
      {props.options.map((i, idx) => {
        return <Option key={i} index={idx} text={i} onPress={onPressOption} />;
      })}
    </View>
  );
});

const Option = React.memo(
  (props: { index: number; text: string; onPress: (index: number, text: string) => void; disabled?: boolean }) => {
    const onPress = useCallback(() => {
      props.onPress(props.index, props.text);
    }, [props.onPress, props.index, props.text]);

    return (
      <>
        {props.index !== 0 && <Spacer vertical={1} />}
        <Pressable style={styles.option} disabled={props.disabled} onPress={onPress}>
          <TSecondary align="center" adjustsFontSizeToFit numberOfLines={1}>
            {props.text}
          </TSecondary>
        </Pressable>
      </>
    );
  }
);

const styles = StyleSheet.create({
  surveyCard: {
    backgroundColor: globalStyleColors.colorAccentWarmLight,
    alignItems: "center",
    marginHorizontal: 8,
    marginTop: 20,
    marginBottom: 20,
    borderRadius: 20,
  },
  survey: {
    width: "100%",
    padding: globalStyleConstants.unitSize,
    borderRadius: 20,
    overflow: "hidden",
    borderWidth: 1,
    borderColor: globalStyleColors.rgba("blackSoft", "xlight"),
  },
  headline: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: globalStyleConstants.unitSize,
  },
  option: {
    width: "100%",
    backgroundColor: "white",
    padding: globalStyleConstants.unitSize,
    borderRadius: 24,
    borderWidth: 1,
    borderColor: globalStyleColors.rgba("blackSoft", "light"),
  },
});
