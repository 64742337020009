import React, { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { LayoutAnimation, StyleSheet, View } from "react-native";
import { Pressable } from "./Pressable";
import { globalStyleConstants } from "./GlobalStyles";
import { AnimatedChevron } from "./AnimatedChevron";
import Animated, { useAnimatedStyle, useSharedValue, withDelay, withTiming } from "react-native-reanimated";

interface Props {
  isOpen: boolean;
  onPress: () => void;
  disableLazyRender?: boolean;
  chevronStyle?: {
    align?: "center" | "flex-end";
    color?: string;
    size?: number;
  };
}

export const CollapsibleList = React.memo((props: PropsWithChildren<Props> & { headerComponent: React.ReactNode }) => {
  const [lazy, setLazy] = useState(!props.disableLazyRender && !props.isOpen);
  const opacity = useSharedValue(0);

  const onPress = useCallback(() => {
    setLazy(false);
    props.onPress();
  }, [setLazy, props.onPress]);

  useEffect(() => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    if (props.isOpen) {
      opacity.value = withTiming(1);
      return;
    }
    opacity.value = withDelay(300, withTiming(0, { duration: 0 }));
  }, [props.isOpen, opacity]);

  const opacityAnimation = useAnimatedStyle(() => {
    return {
      opacity: opacity.value,
    };
  });

  const chevron = (
    <View style={[styles.chevronIcon, props.chevronStyle?.align ? { alignSelf: props.chevronStyle.align } : {}]}>
      <AnimatedChevron
        color={props.chevronStyle?.color}
        size={props.chevronStyle?.size}
        startPosition="down"
        isRotated={props.isOpen}
      />
    </View>
  );

  return (
    <>
      <Pressable onPress={onPress} style={styles.headerContainer}>
        {chevron}
        {props.headerComponent}
      </Pressable>
      {lazy ? null : (
        <Animated.View style={[props.isOpen ? {} : { height: 0, overflow: "hidden" }, opacityAnimation]}>
          {props.children}
        </Animated.View>
      )}
    </>
  );
});

const styles = StyleSheet.create({
  headerContainer: {
    width: "auto",
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: globalStyleConstants.unitSize / 2,
  },
  chevronIcon: {
    alignSelf: "center",
  },
});
