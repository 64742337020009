import React, { useCallback, useMemo } from "react";
import { OnScrollWorklet, SocialFeed, SocialFeedImperativeHandle } from "./SocialFeed";
import { exploreFeed } from "../../lib/social/SocialSlice";
import { useDispatch } from "../../lib/redux/Redux";
import { loadNewHomeFeedPosts, loadOlderHomeFeedPosts } from "../../lib/social/SocialThunks";
import { log } from "../../Log";
import { FlexedSpinner } from "../Spinner";

interface ExploreFeedProps {
  listHeaderComponent?: React.ReactElement;
  onScroll: OnScrollWorklet;
  onEndDrag?: OnScrollWorklet;
  onMomentumEnd?: OnScrollWorklet;
  headerHeightScrolled?: number;
}

export const ExploreFeed = React.memo(
  React.forwardRef<SocialFeedImperativeHandle, ExploreFeedProps>((props, ref) => {
    const dispatch = useDispatch();

    // This feed should never be empty unless it's loading
    const emptyState = useMemo(() => <FlexedSpinner />, []);

    const onEndReached = useCallback(() => {
      // fail silently here - the user will likely just try to scroll again. Displaying an error
      // seems like overkill.
      dispatch(loadOlderHomeFeedPosts("exploreFeed")).catch(err =>
        log.errorCaught("Error dispatching loadOlderPosts for explore feed", err)
      );
    }, [dispatch]);

    const onPullToRefresh = useCallback(() => {
      return Promise.all([
        dispatch(loadNewHomeFeedPosts("exploreFeed")).catch(err =>
          log.errorCaught("Error calling loadNewPosts for explore feed in pull to refresh", err)
        ),
      ]);
    }, [dispatch]);

    return (
      <SocialFeed
        ref={ref}
        feed={exploreFeed}
        feedEmptyStateComponent={emptyState}
        onEndReached={onEndReached}
        onPullToRefresh={onPullToRefresh}
        onScroll={props.onScroll}
        onEndDrag={props.onEndDrag}
        onMomentumEnd={props.onMomentumEnd}
        headerComponent={props.listHeaderComponent}
        paddingTop={"headerHeight"}
        paddingBottom={"bottomTabBarHeight"}
        paddingTopScrolled={props.headerHeightScrolled}
      />
    );
  })
);
