import { RecipeId } from "@eatbetter/recipes-shared";
import { useEffect, useState } from "react";
import { useDispatch } from "./AdminRedux";
import { displayUnexpectedErrorAndLogHandler, SetWaitingHandler } from "@eatbetter/ui-shared";
import { getRecipeOrThrow } from "./recipes/AdminRecipesThunks";
import { AdminGetRecipeResult } from "@eatbetter/composite-shared";

export function useAdminFetchRecipe(recipeId?: RecipeId, setWaiting?: SetWaitingHandler, refreshToken?: string) {
  const dispatch = useDispatch();
  const [recipe, setRecipe] = useState<AdminGetRecipeResult | undefined>(undefined);

  useEffect(() => {
    if (recipeId) {
      dispatch(getRecipeOrThrow(recipeId, setWaiting))
        .then(setRecipe)
        .catch(displayUnexpectedErrorAndLogHandler("Error fetching recipe in useAdminFetchRecipe", { recipeId }));
    } else {
      setRecipe(undefined);
    }
  }, [recipeId, refreshToken]);

  return recipe;
}
