import { FlatList, useWindowDimensions, View } from "react-native";
import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { usePagedData } from "@eatbetter/ui-shared/src/lib/UsePagedData";

interface Props<TItems, TNextToken> {
  fetch: (next?: TNextToken) => Promise<{ items: TItems[]; next: TNextToken | undefined } | undefined>;
  render: (item: TItems, idx: number) => React.ReactElement | null;
}

export const AdminPagedDataList = <TItems, TNextToken>(props: Props<TItems, TNextToken>) => {
  const { items, fetchNext } = usePagedData(props.fetch);
  const [height, setHeight] = useState<number | undefined>();
  const viewRef = useRef<View>(null);
  const window = useWindowDimensions();

  const renderItem = useCallback(
    (info: { item: TItems; index: number }) => {
      return props.render(info.item, info.index);
    },
    [props.render]
  );

  // we assume this is the last thing on the page, so take up the full height between the top and the bottom of the page
  // we need a set height for the scroll/onEndReached to work correctly.
  const calculateHeight = useCallback(
    (windowHeight: number) => {
      viewRef.current?.measureInWindow((_x, y, _width, _height) => {
        setHeight(windowHeight - y);
      });
    },
    [viewRef, setHeight]
  );

  useEffect(() => {
    calculateHeight(window.height);
  }, [window.height, viewRef.current]);

  return (
    <View ref={viewRef} style={{ height }}>
      <FlatList data={items} renderItem={renderItem} onEndReached={fetchNext} />
    </View>
  );
};
