import React from "react";
import { View } from "react-native";
import { IconChevronDown, IconChevronUp } from "../Icons";
import { Spacer } from "../Spacer";
import { globalStyleColors } from "../GlobalStyles";
import { Pressable } from "../Pressable";

interface Props {
  isFirst?: boolean;
  isLast?: boolean;
  onPressMoveUp: () => void;
  onPressMoveDown: () => void;
}

export const RecipeEditItemReorder = React.memo((props: Props) => {
  return (
    <View>
      <Pressable onPress={props.onPressMoveUp} disabled={props.isFirst}>
        <IconChevronUp color={globalStyleColors.colorAccentCool} strokeWidth={1} />
      </Pressable>
      <Spacer vertical={0.5} />
      <Pressable onPress={props.onPressMoveDown} disabled={props.isLast}>
        <IconChevronDown color={globalStyleColors.colorAccentCool} strokeWidth={1} />
      </Pressable>
    </View>
  );
});
