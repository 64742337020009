import { ScreenView, useScreenElementDimensions } from "./ScreenView";
import React, { useCallback, useMemo, useRef } from "react";
import {
  WebView,
  WebViewImperativeHandle,
  WebViewLoadingProgressBar,
  WebViewNavBar,
  webViewConstants,
} from "./WebView";
import { HeaderProps } from "./ScreenHeaders";
import { BottomNotch } from "./BottomNotch";
import { useScreen } from "../navigation/ScreenContainer";
import { UrlString } from "@eatbetter/common-shared";
import { View } from "react-native";
import { useWebViewSession } from "../lib/webview/WebViewHooks";

export interface ViewWebPageScreenComponentProps {
  screenHeader: string;
  url: UrlString;
}

export const ViewWebPageScreenComponent = React.memo((props: ViewWebPageScreenComponentProps) => {
  const webViewRef = useRef<WebViewImperativeHandle>(null);
  const webViewSessionId = useWebViewSession(props.url);
  const screen = useScreen();
  const { headerHeight, bottomNotchHeight } = useScreenElementDimensions();

  const onPressGoBack = useCallback(() => {
    webViewRef.current?.goBack();
  }, [webViewRef]);

  const onPressGoForward = useCallback(() => {
    webViewRef.current?.goForward();
  }, [webViewRef]);

  const onPressRefresh = useCallback(() => {
    webViewRef.current?.reload();
  }, [webViewRef]);

  const header = useMemo<HeaderProps>(() => {
    return {
      type: "native",
      style: "default",
      title: props.screenHeader,
      right: { type: "done", onPress: () => screen.nav.goBack() },
    };
  }, [props.screenHeader, screen.nav.goBack]);

  const webviewContentInsets = useMemo(() => {
    return { top: headerHeight, bottom: bottomNotchHeight };
  }, [headerHeight, bottomNotchHeight]);

  return (
    <ScreenView header={header} scrollView={false} paddingHorizontal={false} paddingVertical={false}>
      <View
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          top: headerHeight,
          height: webViewConstants.progressBarHeight,
          zIndex: 2,
        }}
      >
        <WebViewLoadingProgressBar sessionId={webViewSessionId} />
      </View>
      <WebView ref={webViewRef} sessionId={webViewSessionId} contentInsets={webviewContentInsets} />
      <WebViewNavBar
        sessionId={webViewSessionId}
        onPressGoBack={onPressGoBack}
        onPressGoForward={onPressGoForward}
        onPressRefresh={onPressRefresh}
      />
      <BottomNotch />
    </ScreenView>
  );
});
