import { UserRecipeId } from "@eatbetter/recipes-shared";
import { navTree, ReportRecipeIssueScreenProps } from "../navigation/NavTree";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { useCallback, useMemo } from "react";
import { useSurveyOptions } from "../components/SurveyOptions";
import { HeaderProps } from "../components/ScreenHeaders";
import { View } from "react-native";
import { TBody, TextProps, TSecondary } from "../components/Typography";
import { Spacer } from "../components/Spacer";
import { ReportSocialMediaRecipeMissingIssue } from "@eatbetter/composite-shared";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { bottomThrow, switchReturn } from "@eatbetter/common-shared";
import { smallScreenBreakpoint, useResponsiveDimensions } from "../components/Responsive";
import { ScrollViewWithSubmit } from "./ScrollViewWithSubmit";
import React from "react";
import { useBottomSheet } from "./BottomSheetScreen";
import { ButtonRectangle } from "../components/Buttons";
import { globalStyleColors, globalStyleConstants } from "../components/GlobalStyles";
import { Haptics } from "../components/Haptics";

const strings = {
  screenTitle: "Report a Recipe Issue",
  headline: "If there is a recipe here and we missed it, we'll look into it.",
  surveylabel: "Where is the recipe located?",
  options: [
    "Written recipe in the caption",
    "Link to recipe in the caption",
    "Link in bio",
    "In the comments section",
    "In video: audio",
    "In video: subtitles / captions",
  ] as const,
  next: "Next",
  unsupportedBottomSheet: {
    title: {
      commentsSection: "Recipe in Comments",
      linkInBio: "Recipe Link in Bio",
      videoAudio: "Recipe in Video Audio",
      videoCaptions: "Recipe in Video Subtitles / Captions",
    } satisfies Record<SocialMediaNonIssueType, string>,
    body: {
      commentsSection: [
        "Deglaze doesn't look for recipes in the comments section. Tip: you can take a screenshot of the recipe and ",
        "import it as a photo",
        ".",
      ],
      linkInBio: [
        "Deglaze doesn't follow links in bio. To add the recipe, open the link in bio and ",
        "share to Deglaze",
        ".",
      ],
      videoAudio: [
        "Deglaze doesn't import recipes from audio. If this is a feature you'd like to see, ",
        "send us a feature request",
        ".",
      ],
      videoCaptions: [
        "Deglaze doesn't import recipes from video substitles / captions. If this is a feature you'd like to see, ",
        "send us a feature request",
        ".",
      ],
    } satisfies Record<SocialMediaNonIssueType, [string, string, string]>,
    done: "Got it",
  },
};

type SocialMediaNonIssueType = "linkInBio" | "videoAudio" | "videoCaptions" | "commentsSection";

const surveyData: Array<{
  key: Exclude<ReportSocialMediaRecipeMissingIssue["issue"], "other"> | SocialMediaNonIssueType;
  displayText: string;
}> = [
  {
    key: "writtenRecipeInCaption",
    displayText: strings.options[0],
  },
  {
    key: "recipeLinkInCaption",
    displayText: strings.options[1],
  },
  {
    key: "linkInBio",
    displayText: strings.options[2],
  },
  {
    key: "commentsSection",
    displayText: strings.options[3],
  },
  {
    key: "videoAudio",
    displayText: strings.options[4],
  },
  {
    key: "videoCaptions",
    displayText: strings.options[5],
  },
];

export const ReportSocialMediaRecipeMissingIssueScreen = withScreenContainer<ReportRecipeIssueScreenProps>(
  "ReportSocialMediaRecipeMissingIssueScreen",
  props => {
    const screen = useScreen();

    const surveyOptions = useSurveyOptions("singleSelect", surveyData);
    const selectedOption = surveyOptions.selected[0];
    const renderSurveyOptions = surveyOptions.renderSurveyOptions;

    const onNext = useCallback(() => {
      if (!selectedOption) {
        displayUnexpectedErrorAndLog("onNext called but selectedOption is falsy", { selectedOption, surveyData });
        return;
      }

      switch (selectedOption.key) {
        case "writtenRecipeInCaption":
        case "recipeLinkInCaption": {
          const issueType = switchReturn(selectedOption.key, {
            recipeLinkInCaption: "linkInCaption" as const,
            writtenRecipeInCaption: "recipeInCaption" as const,
          });
          screen.nav.goTo("push", navTree.get.screens.reportSocialMediaCaptionIssue, {
            recipeId: props.recipeId,
            issueType,
          });
          break;
        }
        case "linkInBio":
        case "commentsSection":
        case "videoAudio":
        case "videoCaptions": {
          screen.nav.modal(navTree.get.screens.bottomSheet, {
            content: <UnsupportedOptionsSheet type={selectedOption.key} />,
            height: 350,
            backgroundColor: "white",
          });
          break;
        }
        default:
          bottomThrow(selectedOption.key);
      }
    }, [selectedOption, props.recipeId]);

    const header = useMemo<HeaderProps>(() => {
      return {
        type: "default",
        title: strings.screenTitle,
      };
    }, []);

    const { defaultFontScale } = useResponsiveDimensions();

    const commonTextProps: Partial<TextProps> = {
      align: "center",
      enableFontScaling: "upOnly",
      scale: defaultFontScale,
    };

    return (
      <ScreenView header={header} scrollView={false} paddingVertical={"none"}>
        <ScrollViewWithSubmit onPressSubmit={onNext} submitText={strings.next} submitDisabled={!selectedOption}>
          <Spacer vertical={1} />
          <View style={{ alignSelf: "center" }}>
            <TBody {...commonTextProps} fontWeight="medium">
              {strings.surveylabel}
            </TBody>
            <View style={{ maxWidth: "90%" }}>
              <TSecondary {...commonTextProps} opacity="dark">
                {strings.headline}
              </TSecondary>
            </View>
          </View>
          <Spacer vertical={2} />
          {renderSurveyOptions()}
          <Spacer vertical={2} />
        </ScrollViewWithSubmit>
      </ScreenView>
    );
  },
  {
    serializer: {
      recipeId: s => s,
    },
    parser: {
      recipeId: s => s as UserRecipeId,
    },
  }
);

interface UnsupportedOptionsSheetProps {
  type: SocialMediaNonIssueType;
}

const UnsupportedOptionsSheet = React.memo((props: UnsupportedOptionsSheetProps) => {
  const screen = useScreen();
  const bottomSheet = useBottomSheet();
  const dimensions = useResponsiveDimensions();

  const onPressDone = useCallback(() => {
    Haptics.feedback("itemStatusChanged");
    bottomSheet?.closeSheetAndGoBack();
  }, [bottomSheet]);

  const title = strings.unsupportedBottomSheet.title[props.type];
  const commonTextProps: Partial<TextProps> = {
    enableFontScaling: "upOnly",
    scale: dimensions.defaultFontScale,
  };

  const onPressLink = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
    switch (props.type) {
      case "commentsSection": {
        Haptics.feedback("itemStatusChanged");
        setTimeout(() => screen.nav.goTo("replace", navTree.get.screens.recipeAddFromPhotos), 300);
        return;
      }
      case "linkInBio": {
        Haptics.feedback("itemStatusChanged");
        setTimeout(() => screen.nav.switchTab("profileTab", navTree.get.screens.howToAddBrowserRecipe), 300);
        return;
      }
      case "videoAudio": {
        // MIXPANEL-TODO
        Haptics.feedback("operationSucceeded");
        return;
      }
      case "videoCaptions": {
        // MIXPANEL-TODO
        Haptics.feedback("operationSucceeded");
        return;
      }
      default:
        bottomThrow(props.type);
    }
  }, [bottomSheet]);

  const [first, link, period] = strings.unsupportedBottomSheet.body[props.type];

  const body = (
    <TBody {...commonTextProps}>
      <TBody {...commonTextProps}>{first}</TBody>
      <TBody
        {...commonTextProps}
        onPress={onPressLink}
        suppressHighlighting
        color={globalStyleColors.colorTextLink}
        fontWeight="medium"
      >
        {link}
      </TBody>
      <TBody {...commonTextProps}>{period}</TBody>
    </TBody>
  );

  return (
    <View
      style={{
        height: 350,
        maxWidth: smallScreenBreakpoint,
        width: "100%",
        alignSelf: "center",
        alignItems: "center",
        padding: globalStyleConstants.defaultPadding,
        justifyContent: "space-between",
      }}
    >
      <TBody {...commonTextProps} fontWeight="medium">
        {title}
      </TBody>
      {body}
      <ButtonRectangle type="submit" size="large" onPress={onPressDone} title={strings.unsupportedBottomSheet.done} />
    </View>
  );
});
