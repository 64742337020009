import { useScreen, withNonNavigableScreenContainer } from "../../navigation/ScreenContainer";
import { ScreenView } from "../../components/ScreenView";
import { useCallback } from "react";
import { useDispatch } from "../../lib/redux/Redux";
import { onboardingQuestionAnswered } from "../../lib/system/SystemSlice";
import { onboardingScreenHeader } from "../../components/onboarding/OnboardingScreenHeader";
import { OnboardingScreenHeadline } from "../../components/onboarding/OnboardingHeadline";
import { useSurveyOptions } from "../../components/SurveyOptions";
import { OnboardingCtaButton } from "../../components/onboarding/OnboardingCtaButton";
import { View } from "react-native";
import { bottomActionBarConstants } from "../../components/BottomActionBar";
import { navToNextOnboardingScreen } from "../../navigation/NavThunks";
import { Haptics } from "../../components/Haptics";

const strings = {
  headline: "Do you share cooking responsibilities at home?",
  subhead: "Deglaze makes it easy to share a grocery list and recipe library with everyone at home.",
  surveyOptions: {
    yes: "Yes, we team up",
    no: "No, I shop + cook solo",
  } satisfies Record<"yes" | "no", string>,
};

export const OnboardingHouseholdScreen = withNonNavigableScreenContainer("OnboardingHouseholdScreen", () => {
  const { nav } = useScreen();
  const dispatch = useDispatch();
  const surveyOptions = useSurveyOptions(
    "singleSelect",
    Object.entries(strings.surveyOptions).map(([key, displayText]) => ({ key, displayText }))
  );

  const onNext = useCallback(() => {
    Haptics.feedback("itemStatusChanged");
    dispatch(
      onboardingQuestionAnswered({
        household: surveyOptions.selected[0]?.key === ("yes" satisfies keyof typeof strings.surveyOptions),
      })
    );
    dispatch(navToNextOnboardingScreen(nav, "replace"));
  }, [dispatch, surveyOptions.selected, nav.goTo]);

  return (
    <ScreenView scrollView={false} header={onboardingScreenHeader}>
      <View style={{ flex: 1, paddingBottom: bottomActionBarConstants.height, justifyContent: "space-between" }}>
        <View>
          <OnboardingScreenHeadline headline={strings.headline} subHeadline={strings.subhead} />
        </View>
        <View>{surveyOptions.renderSurveyOptions()}</View>
      </View>
      <OnboardingCtaButton
        cta="next"
        background="transparent"
        disabled={surveyOptions.selected.length === 0}
        onPress={onNext}
      />
    </ScreenView>
  );
});
