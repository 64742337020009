import React, { useCallback, useEffect, useMemo } from "react";
import { FlatList, ListRenderItem, StyleSheet, View } from "react-native";
import { globalStyleColors, globalStyleConstants, globalStyles } from "../components/GlobalStyles";
import { Pressable } from "../components/Pressable";
import { ScreenView, useScreenElementDimensions } from "../components/ScreenView";
import { Spacer } from "../components/Spacer";
import { TSecondary } from "../components/Typography";
import {
  useNotification,
  useNotificationIds,
  useNotificationIsRead,
} from "../lib/notifications/NotificationsSelectors";
import { useAppFocused, useRegisteredUser } from "../lib/system/SystemSelectors";
import { getDateDisplayString } from "../lib/util/DateUtilities";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { NotificationId, Notification } from "@eatbetter/users-shared";
import { useDispatch } from "../lib/redux/Redux";
import {
  loadNewNotifications,
  loadOlderNotifications,
  markAllNotificationsRead,
  notificationScreenMounted,
} from "../lib/notifications/NotificationsThunks";
import { getPullToRefresh } from "../components/PullToRefresh";
import { Photo } from "../components/Photo";
import { NotificationContext, handleNotificationCenterItemTapped } from "../lib/NotificationHandlers";
import { FollowEntityButton } from "../components/Buttons";
import { navTree } from "../navigation/NavTree";
import { PushNewFollower } from "@eatbetter/posts-shared";

const strings = {
  title: "Notifications",
};

export const NotificationCenterScreen = withScreenContainer("NotificationCenterScreen", () => {
  const notificationIds = useNotificationIds();
  const dispatch = useDispatch();

  useEffect(() => {
    // send analytics
    dispatch(notificationScreenMounted());
  }, []);

  const onPullToRefresh = useCallback(() => {
    return dispatch(loadNewNotifications());
  }, [dispatch]);

  const onEndReached = useCallback(() => dispatch(loadOlderNotifications()), [dispatch]);

  return React.createElement<Props>(NotificationCenterScreenComponent, {
    notificationIds,
    onEndReached,
    onPullToRefresh,
  });
});

interface Props {
  notificationIds: NotificationId[];
  onPullToRefresh: () => Promise<unknown>;
  onEndReached: () => void;
}

const NotificationCenterScreenComponent = (props: Props) => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const appFocused = useAppFocused();

  const onPressItem = useCallback(
    (n: Notification) => handleNotificationCenterItemTapped(n, dispatch, screen.nav),
    [screen, dispatch, screen.nav]
  );

  useEffect(() => {
    // Mark all notifications as read once the screen loses focus, backgrounds, or unmounts
    if (!screen.nav.focused || !appFocused) {
      dispatch(markAllNotificationsRead());
    }
    return () => dispatch(markAllNotificationsRead());
  }, [screen.nav.focused, appFocused]);

  return (
    <ScreenView
      header={{ type: "default", title: strings.title }}
      scrollView={false}
      paddingHorizontal={false}
      paddingVertical={false}
    >
      <NotificationsList
        ids={props.notificationIds}
        onEndReached={props.onEndReached}
        onPressItem={onPressItem}
        onPullToRefresh={props.onPullToRefresh}
      />
    </ScreenView>
  );
};

interface NotificationListProps {
  ids: NotificationId[];
  onPressItem: (n: Notification) => void;
  onPullToRefresh: () => Promise<unknown>;
  onEndReached: () => void;
}

const NotificationsList = React.memo((props: NotificationListProps) => {
  const { bottomTabBarHeight: paddingBottom, headerHeight: paddingTop } = useScreenElementDimensions();

  const renderItem: ListRenderItem<NotificationId> = useCallback(
    ({ item, index }) => {
      return <NotificationItem id={item} isFirstItem={index === 0} onPress={props.onPressItem} />;
    },
    [props.onPressItem]
  );

  const keyExtractor = useCallback((item: NotificationId) => {
    return item;
  }, []);

  const refreshControl = useMemo(() => {
    return getPullToRefresh(props.onPullToRefresh, paddingTop);
  }, [props.onPullToRefresh, paddingTop]);

  return (
    <FlatList
      data={props.ids}
      keyExtractor={keyExtractor}
      contentContainerStyle={{ paddingBottom, paddingTop }}
      renderItem={renderItem}
      refreshControl={refreshControl}
      onEndReached={props.onEndReached}
      onEndReachedThreshold={2}
      showsVerticalScrollIndicator={false}
    />
  );
});

const NotificationItem = React.memo(
  (props: { id: NotificationId; onPress: (context: Notification) => void; isFirstItem: boolean }) => {
    const notification = useNotification(props.id);
    const authedUser = useRegisteredUser();
    const isRead = useNotificationIsRead(props.id);
    const { nav } = useScreen();

    const onPress = useCallback(() => {
      if (!notification) {
        return;
      }

      props.onPress(notification);
    }, [notification]);

    // the Deglaze user for notifications has an ID of "deglaze", but that is not a real ID
    const haveActor = !!notification?.actor && notification.actor.userId !== "deglaze";

    const onPressActor = useCallback(() => {
      if (!haveActor || !notification.actor) {
        return;
      }

      nav.goTo("push", navTree.get.screens.otherUserProfile, { userId: notification.actor.userId });
    }, [haveActor, notification?.actor, nav]);

    if (!notification) {
      return null;
    }

    const context = notification.context as NotificationContext;
    const newFollowerType: PushNewFollower["type"] = "social/newFollower";
    const newFollowerId = context.type === newFollowerType ? context.data.userId : undefined;

    const textLineSpacer = <Spacer vertical={0.5} />;

    const notificationBody = !!notification.body && (
      <>
        <View>
          <TSecondary>{notification.body}</TSecondary>
          {newFollowerId !== undefined && (
            <>
              {textLineSpacer}
              <View style={{ width: "50%" }}>
                {textLineSpacer}
                <FollowEntityButton entityId={newFollowerId} context="newFollowerNotification" width="contained" />
                {textLineSpacer}
              </View>
            </>
          )}
        </View>
        {textLineSpacer}
      </>
    );

    return (
      <Pressable style={[styles.item, isRead ? {} : styles.unRead]} singlePress noFeedback onPress={onPress}>
        <View>
          <Pressable onPress={onPressActor} noFeedback={!haveActor}>
            <Photo
              style="avatarMedium"
              source={notification.actor ? notification.actor.photo : authedUser?.photo}
              sourceSize="w288"
            />
          </Pressable>
        </View>
        <Spacer horizontal={2} />
        <View style={[styles.itemContentRight, props.isFirstItem ? {} : globalStyles.separator]}>
          <TSecondary fontWeight="medium">{notification.title}</TSecondary>
          {textLineSpacer}
          {notificationBody}
          <TSecondary opacity="medium">{getDateDisplayString(notification.ts)}</TSecondary>
        </View>
      </Pressable>
    );
  }
);

const styles = StyleSheet.create({
  item: {
    paddingLeft: 20,
    flexDirection: "row",
    backgroundColor: "white",
    alignItems: "center",
  },
  itemContentRight: {
    flex: 1,
    paddingVertical: 1.5 * globalStyleConstants.unitSize,
    paddingRight: 20,
  },
  unRead: {
    backgroundColor: globalStyleColors.colorGrey,
  },
});
