import { bottomNop, bottomThrow, switchReturn } from "@eatbetter/common-shared";
import React from "react";
import { globalStyleColors } from "../GlobalStyles";
import { TBody, TSecondary } from "../Typography";
import { HiddenRecipeInstructionsAccess } from "../../lib/recipes/UseRecipeInstructionsAccess";

const strings = {
  hiddenBookInstructions: "Please refer to the book for cooking instructions.",
  paywalledWebInstructions: ["Please ", "sign in to ", " to see the full recipe."],
  restrictedWebInstructions: ["Please ", "go to ", " for recipe instructions."],
  defaultPublisherName: "the recipe site",
};

export const HiddenWebInstructionsMessage = React.memo(
  (props: {
    hiddenReason: HiddenRecipeInstructionsAccess["reason"];
    onPressLink: () => void;
    publisherName: string | undefined;
    context: "cookingSession" | "xRay";
  }) => {
    const renderWebRecipeMessage = (hiddenReason: "urlPaywall" | "urlRestricted") => {
      const message: string[] = switchReturn(hiddenReason, {
        urlPaywall: strings.paywalledWebInstructions,
        urlRestricted: strings.restrictedWebInstructions,
      });

      const publisherName = props.publisherName ?? strings.defaultPublisherName;

      const defaultSize = (
        <TSecondary>
          <TSecondary>{message[0]}</TSecondary>
          <TSecondary
            onPress={props.onPressLink}
            suppressHighlighting
            color={globalStyleColors.colorTextLink}
            underline
          >
            <TSecondary fontWeight="medium">{message[1]}</TSecondary>
            <TSecondary fontWeight="medium">{publisherName}</TSecondary>
          </TSecondary>
          <TSecondary>{message[2]}</TSecondary>
        </TSecondary>
      );

      switch (props.context) {
        case "cookingSession": {
          const lineHeight = 28;
          return (
            <TBody>
              <TBody lineHeight={lineHeight}>{message[0]}</TBody>
              <TBody
                lineHeight={lineHeight}
                onPress={props.onPressLink}
                suppressHighlighting
                color={globalStyleColors.colorTextLink}
                underline
              >
                <TBody lineHeight={lineHeight} fontWeight="medium">
                  {message[1]}
                </TBody>
                <TBody lineHeight={lineHeight} fontWeight="medium">
                  {publisherName}
                </TBody>
              </TBody>
              <TBody lineHeight={lineHeight}>{message[2]}</TBody>
            </TBody>
          );
        }
        case "xRay": {
          return defaultSize;
        }
        default: {
          bottomNop(props.context);
          return defaultSize;
        }
      }
    };

    const renderBookRecipeMessage = () => {
      const message = strings.hiddenBookInstructions;

      switch (props.context) {
        case "cookingSession": {
          return <TBody lineHeight={28}>{message}</TBody>;
        }
        case "xRay": {
          return <TSecondary>{message}</TSecondary>;
        }
        default: {
          bottomNop(props.context);
          return <TSecondary>{message}</TSecondary>;
        }
      }
    };

    switch (props.hiddenReason) {
      case "bookRestricted": {
        return renderBookRecipeMessage();
      }
      case "urlRestricted":
      case "urlPaywall": {
        return renderWebRecipeMessage(props.hiddenReason);
      }
      default: {
        bottomThrow(props.hiddenReason);
      }
    }
  }
);
