import { UserId } from "@eatbetter/common-shared";
import { withScreenContainer } from "../../navigation/ScreenContainer";
import { RecipeId } from "@eatbetter/recipes-shared";
import { ExternalSharedRecipeScreenProps } from "../../navigation/NavTree";
import { ExternalSharedRecipeScreenComponent } from "./ExternalSharedRecipeScreenComponent";
import { SocialPostId } from "@eatbetter/posts-shared";

export const ExternalSharedRecipeScreen = withScreenContainer(
  "ExternalSharedRecipeScreen",
  (props: ExternalSharedRecipeScreenProps) => {
    return <ExternalSharedRecipeScreenComponent {...props} />;
  },
  {
    serializer: {
      userId: s => s,
      sharedRecipeId: { optional: true, fn: s => s },
      sourceRecipeId: { optional: true, fn: s => s },
      postId: { optional: true, fn: s => s },
      action: { optional: true, fn: s => s },
    },
    parser: {
      userId: s => s as UserId,
      sharedRecipeId: { optional: true, fn: s => s as RecipeId },
      sourceRecipeId: { optional: true, fn: s => s as RecipeId },
      postId: { optional: true, fn: s => s as SocialPostId },
      action: { optional: true, fn: s => s as "save" },
    },
    nameRemapping: {
      action: "a",
    },
  }
);
