import { UserRecipeId } from "@eatbetter/recipes-shared";
import { useCallback } from "react";
import { RecipeTagsEdit } from "../components/recipes/RecipeTagsEdit";
import { ScreenView } from "../components/ScreenView";
import { navTree, RecipeEditFieldLocation, RecipeTagsEditScreenProps } from "../navigation/NavTree";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { useRecipeTitle } from "../lib/recipes/RecipesSelectors";

const strings = {
  screenTitle: "Tags",
};

export const RecipeTagsEditScreen = withScreenContainer(
  "RecipeTagsEditScreen",
  (props: RecipeTagsEditScreenProps) => {
    const screen = useScreen();
    const recipeTitle = useRecipeTitle(props.recipeId);

    const onPressAdd = useCallback(() => {
      screen.nav.modal(navTree.get.screens.recipeTagAddNew, {
        recipeId: props.recipeId,
        recipeEditFieldLocation: props.recipeEditFieldLocation,
      });
    }, [screen.nav.modal, props.recipeEditFieldLocation, props.recipeId]);

    return (
      <ScreenView
        header={{
          type: "default",
          title: `${strings.screenTitle}: ${recipeTitle}` ?? strings.screenTitle,
        }}
      >
        <RecipeTagsEdit
          recipeId={props.recipeId}
          onPressAddUserTag={onPressAdd}
          location={props.recipeEditFieldLocation}
        />
      </ScreenView>
    );
  },
  {
    serializer: {
      recipeId: s => s,
      recipeEditFieldLocation: s => s,
    },
    parser: {
      recipeId: s => s as UserRecipeId,
      recipeEditFieldLocation: s => s as RecipeEditFieldLocation,
    },
  }
);
