import { useSelector } from "../redux/Redux";
import { BookAttribution, ReaderModeEnabledInstanceId, recipeAdapterSelectors } from "./RecipesSlice";
import {
  AppRecipe,
  AppUserRecipe,
  RecipeId,
  RecipeInfo,
  UserRecipeId,
  isFullRecipe,
  isUserRecipe,
  isUserRecipeId,
} from "@eatbetter/recipes-shared";
import { RootState } from "../redux/RootReducer";
import { getCreateSelectorWithCacheSize } from "../redux/CreateSelector";
import {
  daysBetween,
  defaultTimeProvider,
  EpochMs,
  getSocialMediaDomain,
  hoursBetween,
  SocialMediaDomainKey,
  ValueCounter,
} from "@eatbetter/common-shared";

export const useInitialRecipeLoadComplete = () =>
  useSelector(s => {
    return s.recipes.ids.length > 0 || s.recipes.meta.lastUpdated;
  });

export const selectRecipe = (s: RootState, id: UserRecipeId): AppUserRecipe | undefined =>
  recipeAdapterSelectors.selectById(s.recipes, id);
export const selectRecipesById: (s: RootState) => Record<UserRecipeId, AppUserRecipe> = s =>
  s.recipes.entities as Record<UserRecipeId, AppUserRecipe>;
export const selectRecipeIds = (s: RootState) => recipeAdapterSelectors.selectIds(s.recipes) as UserRecipeId[];
export const selectRecipeViewTimeOverrides = (s: RootState) => s.recipes.lastViewsBeforeSession;
export const useRecipeIds = () => useSelector(selectRecipeIds);
export const useRecipe = (id: UserRecipeId) => useSelector(s => selectRecipe(s, id));

const selectNonArchivedLibraryRecipes = getCreateSelectorWithCacheSize(1)(
  [(s: RootState) => s.recipes.entities],
  (recipes): Record<UserRecipeId, AppUserRecipe> => {
    return Object.fromEntries(
      Object.values(recipes)
        .filter((i): i is AppUserRecipe => !!i && !(i.archived || i.deleted))
        .map(i => [i.id, i])
    );
  }
);

export const useLibraryRecipeCount = () => useSelector(s => Object.keys(selectNonArchivedLibraryRecipes(s)).length);

export const selectLibraryRecipesBySourceId = getCreateSelectorWithCacheSize(1)(
  [(s: RootState) => selectNonArchivedLibraryRecipes(s)],
  (recipes): Record<RecipeId, AppUserRecipe> => {
    return Object.fromEntries(
      Object.values(recipes)
        .filter((i): i is AppUserRecipe => !!i.sourceRecipeId)
        .map(i => [i.sourceRecipeId, i])
    );
  }
);

export const selectLibraryRecipe = (state: RootState, id: RecipeId | undefined): AppUserRecipe | undefined => {
  if (!id) {
    return undefined;
  }

  const libraryRecipe = selectNonArchivedLibraryRecipes(state)[id as UserRecipeId];
  if (libraryRecipe && !(libraryRecipe.archived || libraryRecipe.deleted)) {
    // It's a UserRecipeId and already in the library
    return libraryRecipe;
  }

  // Check if there's a library recipe with a sourceRecipeId that matches
  return selectLibraryRecipesBySourceId(state)[id];
};

export const useLibraryRecipe = (id: RecipeId | undefined) => {
  return useSelector(s => selectLibraryRecipe(s, id));
};

export const selectRecipeStats = getCreateSelectorWithCacheSize(1)(
  [
    (s: RootState) => s.recipes.ids,
    (s: RootState) => s.recipes.entities,
    (s: RootState) => s.system.authedUser.data?.userId,
  ],
  (ids, entities, userId) => {
    let recipeCount = 0;
    let cookedCount = 0;
    let addedToListCount = 0;

    ids.forEach(id => {
      const r = entities[id];
      // only count recipes this user added and that aren't deleted/archived.
      // note that the cook/grocery counts could have been performed by either household member, but that's fine
      if (r && !r.archived && !r.deleted && r.userId === userId) {
        recipeCount++;

        if (r.stats.cooked) {
          cookedCount += r.stats.cooked;
        }

        if (r.stats.addedToList) {
          addedToListCount += r.stats.addedToList;
        }
      }
    });

    return { recipeCount, cookedCount, addedToListCount };
  }
);

const selectUserOrExternalRecipe = getCreateSelectorWithCacheSize(10)(
  [
    (state: RootState, recipeOrUserRecipeId: AppRecipe | RecipeInfo | UserRecipeId | undefined) =>
      typeof recipeOrUserRecipeId === "string" ? selectRecipe(state, recipeOrUserRecipeId) : undefined,
    (_state: RootState, recipeOrUserRecipeId: AppRecipe | RecipeInfo | UserRecipeId | undefined) =>
      typeof recipeOrUserRecipeId !== "string" ? recipeOrUserRecipeId : undefined,
  ],
  (stateRecipe, recipe) => {
    const recipeBase = (recipe && isFullRecipe(recipe) ? recipe : undefined) ?? stateRecipe;
    const userRecipe = recipe && isFullRecipe(recipe) && isUserRecipe(recipe) ? recipe : stateRecipe;

    return { recipeBase, userRecipe };
  }
);

export const useUserOrExternalRecipe = (recipeOrUserRecipeId: AppRecipe | UserRecipeId | undefined) => {
  return useSelector(s => selectUserOrExternalRecipe(s, recipeOrUserRecipeId));
};

const selectRecipeProperty = <T>(
  state: RootState,
  recipeOrUserRecipeId: AppRecipe | RecipeInfo | UserRecipeId | undefined,
  selector: (recipe: AppRecipe | undefined, userRecipe: AppUserRecipe | undefined) => T
) => {
  const expanded = selectUserOrExternalRecipe(state, recipeOrUserRecipeId);
  return selector(expanded.recipeBase, expanded.userRecipe);
};

const selectRecipeSource = (s: RootState, recipeOrUserRecipeId?: AppRecipe | RecipeInfo | UserRecipeId) =>
  selectRecipeProperty(s, recipeOrUserRecipeId, recipe => recipe?.source);

export const useRecipeSourceUrl = (recipeOrUserRecipeId?: AppRecipe | UserRecipeId) => {
  return useSelector(s => {
    const source = selectRecipeSource(s, recipeOrUserRecipeId);
    if (!source || source.type !== "url") {
      return undefined;
    }
    return source.canonicalUrl ?? source.url;
  });
};

export const useGetSocialMediaDomainType = (
  recipeOrUserRecipeId?: AppRecipe | UserRecipeId
): SocialMediaDomainKey | undefined => {
  const url = useRecipeSourceUrl(recipeOrUserRecipeId);
  if (!url) {
    return undefined;
  }
  return getSocialMediaDomain(url)?.key;
};

export const useIsOwnUserRecipe = (recipeOrUserRecipeId?: AppRecipe | UserRecipeId) => {
  return useSelector(s => {
    const authedUserId = s.system.authedUser.data?.userId;
    const recipeSource = selectRecipeSource(s, recipeOrUserRecipeId);
    return recipeSource?.type === "user" && recipeSource.userId === authedUserId;
  });
};

const selectIsRestrictedBookRecipe = getCreateSelectorWithCacheSize(10)(
  [
    (state: RootState, recipeOrUserRecipeId: AppRecipe | RecipeInfo | UserRecipeId | undefined) =>
      !!(typeof recipeOrUserRecipeId === "string"
        ? selectLibraryRecipe(state, recipeOrUserRecipeId)
        : selectLibraryRecipe(state, recipeOrUserRecipeId?.id)),
    (state: RootState, recipeOrUserRecipeId: AppRecipe | UserRecipeId | RecipeInfo | undefined) =>
      selectRecipeSource(state, recipeOrUserRecipeId),
  ],
  (inLibrary, source) => {
    return source?.type === "userPhoto" && !!source.bookId && !inLibrary;
  }
);

export const useIsRestrictedBookPhotoRecipe = (recipeOrUserRecipeId?: AppRecipe | RecipeInfo | UserRecipeId) => {
  return useSelector(s => selectIsRestrictedBookRecipe(s, recipeOrUserRecipeId));
};

export const useRecipeFullAccess = (recipeOrUserRecipeId?: AppRecipe | UserRecipeId): boolean => {
  return useSelector(s => selectRecipeProperty(s, recipeOrUserRecipeId, (_, userRecipe) => !!userRecipe?.fa));
};

export const useRecipeSourceType = (recipeOrUserRecipeId?: AppRecipe | UserRecipeId) => {
  return useSelector(s => selectRecipeProperty(s, recipeOrUserRecipeId, recipe => recipe?.source.type));
};

export const useSourceRecipeId = (id?: UserRecipeId) => {
  return useSelector(s => {
    if (id) {
      const recipe = selectRecipe(s, id);
      return recipe?.sourceRecipeId;
    }
    return undefined;
  });
};

export const useRecipeRating = (id?: UserRecipeId) => {
  return useSelector(s => {
    if (id) {
      const recipe = selectRecipe(s, id);
      return recipe?.rating;
    }
    return undefined;
  });
};

export const useRecipeDescription = (id: UserRecipeId) => {
  return useSelector(s => {
    const recipe = selectRecipe(s, id);
    return recipe?.description;
  });
};

export const useRecipeIngredients = (id: UserRecipeId) => {
  return useSelector(s => {
    const recipe = selectRecipe(s, id);
    return recipe?.ingredients;
  });
};

export const useRecipePhoto = (id: UserRecipeId | undefined) => {
  return useSelector(s => {
    if (!id) {
      return undefined;
    }

    const recipe = selectRecipe(s, id);
    return recipe?.photo;
  });
};

const selectRecipeInfo = getCreateSelectorWithCacheSize(20)(
  [(s: RootState, id: UserRecipeId | undefined) => (id ? selectRecipe(s, id) : undefined)],
  (recipe): RecipeInfo | undefined => {
    if (!recipe) {
      return undefined;
    }

    return {
      id: recipe.id,
      source: recipe.source,
      title: recipe.title,
      author: recipe.author,
      book: recipe.book,
      photo: recipe.photo,
      publisher: recipe.publisher,
      rifa: recipe.fa ? (true as const) : undefined,
      time: recipe.time,
    };
  }
);

export const useRecipeInfo = (id: UserRecipeId | undefined) => useSelector(s => selectRecipeInfo(s, id));

export const useRecipeNotes = (id?: UserRecipeId) => {
  return useSelector(s => {
    if (!id) {
      return undefined;
    }
    const recipe = selectRecipe(s, id);
    return recipe?.notes?.text;
  });
};

export const useRecipeTitle = (id: UserRecipeId) => {
  return useSelector(s => {
    const recipe = selectRecipe(s, id);
    return recipe?.title;
  });
};

export const useRecipeTime = (id: UserRecipeId) => {
  return useSelector(s => {
    const recipe = selectRecipe(s, id);
    return recipe?.time;
  });
};

export const useRecipeStatus = (id?: UserRecipeId) => {
  return useSelector(s => {
    const defaultStatus = "complete";
    if (!id) {
      return defaultStatus;
    }
    const recipe = selectRecipe(s, id);
    return recipe?.status ?? defaultStatus;
  });
};

export const useRecipeProcessingTimedOut = (id?: RecipeId) => {
  return useSelector(s => {
    if (!id || !isUserRecipeId(id)) {
      return false;
    }
    const recipe = selectRecipe(s, id);
    const processingTimedOut =
      recipe?.status === "processing" && !!recipe?.created && hoursBetween(recipe.created, defaultTimeProvider()) > 120; // 5 days
    return processingTimedOut;
  });
};

export const useRecipeStats = (id: UserRecipeId) => {
  return useSelector(s => {
    const recipe = selectRecipe(s, id);
    return recipe?.stats;
  });
};

export const useRecipePublisher = (id: UserRecipeId) => {
  return useSelector(s => {
    const recipe = selectRecipe(s, id);
    return recipe?.publisher;
  });
};

export const useRecipeAuthor = (id: UserRecipeId) => {
  return useSelector(s => {
    const recipe = selectRecipe(s, id);
    return recipe?.author;
  });
};

export const useIsReaderModeEnabled = (instanceId: ReaderModeEnabledInstanceId): boolean => {
  return useSelector(s => {
    const readerMode = s.recipes.readerModeEnabled[instanceId];
    return !!readerMode;
  });
};

export const useRecipesScript = (): string | undefined => useSelector(state => state.recipes.script.data?.script);

export const selectExistingBooks = getCreateSelectorWithCacheSize(1)(
  [(s: RootState) => s.recipes.entities, (s: RootState) => s.recipes.lastAttributionSet],
  (recipesById, lastAddedById) => {
    const recipes = Object.values(recipesById);
    const booksById: Record<string, BookAttribution> = {};
    const vc = new ValueCounter({ getKey: s => s });
    const timeById: Record<string, EpochMs> = {};
    const days = 7;
    const now = defaultTimeProvider();
    recipes.forEach(r => {
      if (r.archived || r.deleted) {
        return;
      }

      if (r.book) {
        if (!booksById[r.book.id]) {
          booksById[r.book.id] = { type: "book", book: r.book, author: r.author?.name };
        }
        vc.addToCount(r.book.id);

        // if the recipe was created in the last N days, add a timestamp if there is a book - it might have been auto-resolved
        // and there's a decent chance the user is adding another recipe from this book
        if (daysBetween(r.created, now) < days) {
          timeById[r.book.id] = r.created;
        }
      } else if (r.userEnteredAttribution && r.userEnteredAttribution.type === "book") {
        const key = JSON.stringify(r.userEnteredAttribution);
        if (!booksById[key]) {
          booksById[key] = { type: "userEnteredBook", attribution: r.userEnteredAttribution };
        }
        vc.addToCount(key);
      }
    });

    const allBooks = Object.values(booksById);

    const counts = vc.getSortedValues();
    const getSortValues = (a: BookAttribution): { ts: number; count: number } => {
      const key = a.type === "book" ? a.book.id : JSON.stringify(a.attribution);
      const tsRaw = Math.max(timeById[key] ?? 0, lastAddedById[key] ?? 0) as EpochMs;
      // ignore the timestamps if it's been too long
      const ts = daysBetween(tsRaw, now) < days ? tsRaw : 0;
      const count = counts[key] ?? 0;
      return { ts, count };
    };

    // sort first by most recently used and then by counts
    return allBooks.sort((a, b) => {
      const aValues = getSortValues(a);
      const bValues = getSortValues(b);

      if (aValues.ts !== bValues.ts) {
        return bValues.ts - aValues.ts;
      }

      return bValues.count - aValues.count;
    });
  }
);

export const useExistingBookAttribution = () => useSelector(selectExistingBooks);
