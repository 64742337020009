import axiosLib, { AxiosInstance } from "axios";
import { log } from "@eatbetter/ui-shared";
import {
  DataManagerRequest,
  GetTruthSetPhrases,
  GetTruthSetPhrasesResponse,
  GetIngredients,
  GetIngredientsResponse,
  ItemWithPhrases,
  AddIngredient,
  AddIngredientResponse,
  UpdateIngredient,
  DeleteIngredientResponse,
  DeleteIngredient,
  UpdateIngredientResponse,
  AddTruthSetPhrase,
  AddTruthSetPhraseResponse,
  GetParsedPhrase,
  FieldToVerify,
  VerifyTruthSetFields,
  VerifyTruthSetFieldsResponse,
  PhraseItemType,
} from "@eatbetter/items-data";
import { Envelope } from "@eatbetter/common-shared";
import { DisambiguationItemId, ShoppableItemId } from "@eatbetter/items-shared";
import { ParsedIngredient } from "@eatbetter/items-server";

export class DataManagerApiClient {
  private readonly axios: AxiosInstance;
  constructor(private readonly endpoint: string) {
    this.axios = axiosLib.create({
      timeout: 10 * 1000,
      validateStatus: () => true,
    });
  }

  async getParsedPhrase(phrase: string, type: PhraseItemType) {
    const req: GetParsedPhrase = {
      type: "getParsedPhrase",
      data: { phrase, type },
    };

    return this.makeRequest<ParsedIngredient>(req);
  }

  async getTruthSetPhrases() {
    const req: GetTruthSetPhrases = {
      type: "getTruthSetPhrases",
      data: {},
    };

    return this.makeRequest<GetTruthSetPhrasesResponse>(req);
  }

  async addTruthSetPhrase(phrase: string, itemType: PhraseItemType) {
    const req: AddTruthSetPhrase = {
      type: "addTruthSetPhrase",
      data: { phrase, itemType },
    };

    return this.makeRequest<AddTruthSetPhraseResponse>(req);
  }

  async verifyTruthSetPhraseFields(args: { phrase: string; type: PhraseItemType; fields: FieldToVerify[] }) {
    const req: VerifyTruthSetFields = {
      type: "verifyTruthSetFields",
      data: {
        phrase: args.phrase,
        fields: args.fields,
        type: args.type,
      },
    };

    return this.makeRequest<VerifyTruthSetFieldsResponse>(req);
  }

  async getIngredients() {
    const req: GetIngredients = {
      type: "getIngredients",
      data: {},
    };

    return this.makeRequest<GetIngredientsResponse>(req);
  }

  async addIngredient(args: { ingredient: ItemWithPhrases }) {
    const req: AddIngredient = {
      type: "addIngredient",
      data: {
        item: args.ingredient,
      },
    };

    return this.makeRequest<AddIngredientResponse>(req);
  }

  async updateIngredient(args: { id: ShoppableItemId | DisambiguationItemId; ingredient: ItemWithPhrases }) {
    const req: UpdateIngredient = {
      type: "updateIngredient",
      data: {
        id: args.id,
        item: args.ingredient,
      },
    };

    return this.makeRequest<UpdateIngredientResponse>(req);
  }

  async deleteIngredient(args: { id: ShoppableItemId | DisambiguationItemId }) {
    const req: DeleteIngredient = {
      type: "deleteIngredient",
      data: {
        id: args.id,
      },
    };

    return this.makeRequest<DeleteIngredientResponse>(req);
  }

  private async makeRequest<TData>(req: DataManagerRequest) {
    log.info(`Making DataManager request ${req.type}`);

    const resp = await this.axios.request({
      url: this.endpoint,
      method: "POST",
      data: req,
      headers: {
        "Content-Type": "application/json",
      },
    });

    return resp.data as Envelope<TData>;
  }
}
