import React, { useContext, useEffect, useRef, useState } from "react";
import { ScreenView } from "../components/ScreenView";
import { withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { BottomSheet, BottomSheetImperativeHandle } from "../components/BottomSheet";
import { BottomSheetScreenProps } from "../navigation/NavTree";

export const BottomSheetContext = React.createContext<BottomSheetImperativeHandle | null>(null);

export function useBottomSheet() {
  return useContext(BottomSheetContext);
}

export const BottomSheetScreen = withNonNavigableScreenContainer<BottomSheetScreenProps>("BottomSheetScreen", props => {
  const { content, ...bottomSheetProps } = props;

  const bottomSheetRef = useRef<BottomSheetImperativeHandle>(null);
  const [contextValue, setContextValue] = useState<BottomSheetImperativeHandle | null>(bottomSheetRef.current);

  useEffect(() => {
    setContextValue(bottomSheetRef.current);
  }, [bottomSheetRef.current]);

  return (
    <ScreenView
      scrollView={false}
      paddingHorizontal={false}
      paddingVertical={false}
      sceneBackgroundColor="transparent"
      backgroundColor="transparent"
    >
      <BottomSheet {...bottomSheetProps} ref={bottomSheetRef}>
        <BottomSheetContext.Provider value={contextValue}>{props.content}</BottomSheetContext.Provider>
      </BottomSheet>
    </ScreenView>
  );
});
