import { ScalingQuantity, ScalingUnit, UnitConversion } from "@eatbetter/items-shared";
import { getPreciseUnitDisplay, PreciseUnit } from "./PreciseUnit";
import { ConvertedScalingQuantityUnit } from "./ScaleAndConvert";
import { fractionConfigs, getUnitConversionMetadata, getUnitMetadata } from "./UnitMetadata";
import { findClosestFraction, FractionDisplayKey } from "./Fractions";

/**
 * Represents an updated string token. The `text` property contains the updated value and the `indices` property
 * contains the start/end index of the original text in the source string. This is then used to replace the original
 * token with the updated one in the source string. The `insert` property is piped through from the source, indicating that it
 * is an implicit quantity or unit, and gets inserted only if scaled or converted, respectively.
 */
export interface ChangedString {
  indices: [number, number];
  text: string;
  insert?: boolean;
  /** The scale applied to the changed token (scale = 1 means no scale applied) */
  scale: number;
  /** The unit conversion applied to the changed token, or `undefined` if no unit conversion applied */
  conversion?: Exclude<UnitConversion, "original">;
}

/**
 * Returns UI-friendly display strings for quantity and unit. This is the final step of the pipeline
 * in scaling and converting a quantity unit.
 */
export function formatQuantityUnit(
  qu: ConvertedScalingQuantityUnit,
  originalUnit?: PreciseUnit
): { q: ChangedString[]; u?: ChangedString } {
  const qDefaultFormatted: ChangedString[] = formatQuantityRange({
    ...fractionConfigs.standardFractions,
    quantity: qu.q,
    scale: qu.scale,
  }).map(i => {
    const { value, ...changedString } = i;
    return changedString;
  });

  if (!qu.u) {
    // If there's no unit, return the formatted quantity and nothing else
    return { q: qDefaultFormatted };
  }

  if (!qu.u.unit) {
    // If there's a unit but no precise unit, return the appropriate `s` or `p` value if present
    return {
      q: qDefaultFormatted,
      u: {
        text: getScalingUnitString(qu.u, qu.q),
        indices: qu.u.range,
        insert: qu.u.insert,
        scale: qu.scale,
      },
    };
  }

  const metadata = getUnitMetadata(qu.u.unit);

  /**
   * Returns formatted values for quantity and unit for the given conversion type, i.e. original unit vs a newly converted to unit
   */
  const getFormatted = (args: {
    q: ScalingQuantity[];
    scale: number;
    conversion: UnitConversion;
    u:
      | { type: "originalUnit"; u: ScalingUnit }
      | { type: "convertedUnit"; u: { unit: PreciseUnit; range: [number, number]; insert?: boolean } };
  }): { q: ChangedString[]; u?: ChangedString } => {
    const { q, scale, u, conversion } = args;

    const qFormatted: Array<ChangedString & { value: number }> = formatQuantityRange({
      ...metadata,
      quantity: q,
      scale,
    });
    const qMax: number = qFormatted.reduce((max, curr) => (curr.value > max ? curr.value : max), 0);

    let qResult: ChangedString[] = qFormatted.map<ChangedString>(i => ({
      text: i.text,
      indices: i.indices,
      scale: i.scale,
    }));
    let uResult: ChangedString | undefined;

    if (u.type === "originalUnit") {
      // If the conversion is to the same unit as the original and scale is 1 (default), then the unit is unchanged, so we omit it.
      // If it's scaled (scale !== 1), then use the p + s values for display.
      uResult =
        scale !== 1
          ? {
              text: getScalingUnitString(u.u, qMax),
              indices: u.u.range,
              insert: u.u.insert,
              scale,
            }
          : undefined;
    } else {
      // u.type === convertedUnit
      const quHasSpace = quantityUnitHasSpaceBetween(
        qFormatted.map(i => i.indices),
        u.u.range
      );

      const unitText = getPreciseUnitDisplay({ unit: u.u.unit, quantity: qMax, quHasSpaceBetween: quHasSpace });
      const conversionResult = getConversionResult(originalUnit, conversion);

      uResult = {
        text: unitText,
        indices: u.u.range,
        insert: u.u.insert,
        scale,
        conversion: conversionResult,
      };

      // Unit-specific formatting
      const formatUnitSpecificQuantityRange = (
        quantityRange: typeof qFormatted,
        formatQuantity: (q: (typeof qFormatted)[number]) => ChangedString & { value: number }
      ) => {
        const formatted = quantityRange.map(i => formatQuantity(i));
        const merged = mergeDuplicateQuantities(formatted).map(i => {
          const { value, ...changedString } = i;
          return changedString;
        });

        return merged;
      };

      switch (u.u.unit) {
        case "teaspoon": {
          qResult = formatUnitSpecificQuantityRange(qFormatted, i => {
            const { integerPart, fractionPart } = getNumberParts(i.value);

            return {
              ...i,
              // Avoid overly granular fractions when it doesn't make sense to show them
              text: integerPart > 10 || (integerPart > 0 && fractionPart < 0.25) ? integerPart.toString() : i.text,
            };
          });
          break;
        }
        case "gram": {
          qResult = formatUnitSpecificQuantityRange(qFormatted, i => {
            const { integerPart } = getNumberParts(i.value);

            return {
              ...i,
              // Show a single decimal point for less than 1 values, otherwise round to the nearest integrer
              text: integerPart < 1 ? i.value.toFixed(1) : Math.round(i.value).toString(),
            };
          });
          break;
        }
        case "milliliter": {
          qResult = formatUnitSpecificQuantityRange(qFormatted, i => {
            const { integerPart } = getNumberParts(i.value);

            return {
              ...i,
              // Show a single decimal point for less than 1 values, otherwise round to the nearest integrer
              text: integerPart < 1 ? i.value.toFixed(1) : Math.round(i.value).toString(),
            };
          });
          break;
        }
        case "millimeter": {
          qResult = formatUnitSpecificQuantityRange(qFormatted, i => {
            return {
              ...i,
              // Round to the nearest integrer because it's already a tiny measurement
              text: Math.round(i.value).toString(),
            };
          });
          break;
        }
        case "centimeter": {
          qResult = formatUnitSpecificQuantityRange(qFormatted, i => {
            const { integerPart } = getNumberParts(i.value);

            return {
              ...i,
              // Show a single decimal point for values less than 10, otherwise round to the nearest integrer
              text: integerPart < 10 ? Number(i.value.toFixed(1)).toString() : Math.round(i.value).toString(),
            };
          });
          break;
        }
      }
    }

    return { q: qResult, u: uResult };
  };

  if (qu.u.unit === originalUnit) {
    return getFormatted({ q: qu.q, scale: qu.scale, u: { type: "originalUnit", u: qu.u }, conversion: qu.conversion });
  }

  return getFormatted({
    q: qu.q,
    scale: qu.scale,
    u: { type: "convertedUnit", u: { unit: qu.u.unit, range: qu.u.range, insert: qu.u.insert } },
    conversion: qu.conversion,
  });
}

/**
 * Checks if there is a space separating the quantity and unit in the source string. This is used to determine
 * the best display format for a given unit.
 */
function quantityUnitHasSpaceBetween(qIndices: Array<[number, number]>, uIndices: [number, number]): boolean {
  const qLast = qIndices.sort((a, b) => a[0] - b[1]).at(-1);

  if (!qLast) {
    return false;
  }

  return uIndices[0] - qLast[1] > 1;
}

/**
 * Helper to format a quantity range into an array of `ChangedString` with the numerical value for post processing
 */
function formatQuantityRange(args: {
  quantity: ScalingQuantity[];
  scale: number;
  roundingThreshold?: number | [zero: number, one: number];
  allowedFractions?: Array<FractionDisplayKey>;
  fractionMatchThreshold?: number;
}): Array<ChangedString & { value: number }> {
  const formatted = args.quantity.map(i => {
    const qFormatted = formatQuantity({ ...args, quantity: i.value });
    return {
      text: qFormatted.text,
      value: qFormatted.value,
      indices: i.range,
      insert: i.insert,
      scale: args.scale,
    };
  });

  return mergeDuplicateQuantities(formatted);
}

/**
 * Merge consecutive quantity items that have identical display text so that we don't end up with ranges of the exact same quantity
 */
function mergeDuplicateQuantities(formatted: Array<ChangedString & { value: number }>) {
  return formatted.reduce((acc, curr) => {
    const last = acc.at(-1);
    if (!last) {
      return [curr];
    }

    if (curr.text === last.text) {
      // Replace the last item with a merged indices item
      const result = acc.slice();
      result.splice(-1, 1, {
        ...last,
        indices: [Math.min(last.indices[0], curr.indices[0]), Math.max(last.indices[1], curr.indices[1])],
      });
      return result;
    }

    return [...acc, curr];
  }, [] as Array<ChangedString & { value: number }>);
}

/**
 * Returns a display string for the given scale value, trying to match unicode fractions for the fractional part
 * to a unicode fraction value if possible. Otherwise, 2 decimal places if integer part is single digit, 1 decimal
 * place if it's 2 digits, and zero decimals if 3 or more.
 */
export function formatQuantity(args: {
  quantity: number;
  roundingThreshold?: number | [zero: number, one: number];
  allowedFractions?: Array<FractionDisplayKey>;
  fractionMatchThreshold?: number;
}): { value: number; text: string } {
  // Split integer and fractional parts
  const integerPart = Math.trunc(args.quantity);
  const fractionalPart = args.quantity - integerPart;

  const [roundingThresholdZero, roundingThresholdOne] = args.roundingThreshold
    ? Array.isArray(args.roundingThreshold)
      ? args.roundingThreshold
      : [args.roundingThreshold, args.roundingThreshold]
    : [0.05, 0.05];

  // If the quantity is close to zero, provide higher precision
  if (args.quantity < roundingThresholdZero) {
    return { value: args.quantity, text: Number(args.quantity.toFixed(2)).toString() };
  }

  // Round quantity if fractional part is close to 0
  if (Math.abs(fractionalPart) < roundingThresholdZero) {
    return { value: integerPart, text: integerPart.toString() };
  }

  // Round quantity if fractional part is close to 1
  if (Math.abs(1 - fractionalPart) < roundingThresholdOne) {
    const roundedUpQuantity = integerPart + 1;
    return { value: roundedUpQuantity, text: roundedUpQuantity.toString() };
  }

  // Match decimal part to fractions if specified for given unit
  if (args.allowedFractions) {
    const closestFraction = findClosestFraction(
      fractionalPart,
      args.allowedFractions,
      args.fractionMatchThreshold ?? 0.05
    );

    if (closestFraction) {
      const value = integerPart + closestFraction.numerator / closestFraction.denominator;
      const text = `${integerPart !== 0 ? integerPart : ""}${closestFraction.display}`;
      return { value, text };
    }
  }

  // Determine decimal places based on integer length
  const integerDigitsLength = Math.abs(integerPart).toString().length;
  const decimalPlaces = integerDigitsLength >= 3 ? 0 : integerDigitsLength === 2 ? 1 : 2;

  // Remove trailing zeros via Number constructor
  const text = Number(args.quantity.toFixed(decimalPlaces)).toString();
  const value = parseFloat(text);
  return { text, value };
}

/**
 * Checks if unit conversion was applied to this token and if so, return the conversion value so that
 * the tooltip can display the changed units. If the original system and converted system are the same,
 * return `undefined` to indicate that no unit conversion was applied to this token (only scale applied, if !== 1)
 */
export function getConversionResult(originalUnit: string | undefined, conversion: UnitConversion) {
  const originalSystem = getUnitConversionMetadata(originalUnit)?.system;
  const isConverted = conversion !== "original" && originalSystem && originalSystem !== conversion;
  const conversionResult = isConverted ? conversion : undefined;
  return conversionResult;
}

/**
 * Returns the "s" or "p" value on the ScalingUnit based on the quantity value provided
 */
function getScalingUnitString(s: ScalingUnit, q: ScalingQuantity[] | number): string {
  if (typeof q === "number") {
    return q > 1 ? s.p : s.s;
  }

  const lastQuantityPart = q.at(-1) ?? q[0];

  if (lastQuantityPart && lastQuantityPart.value > 1) {
    return s.p;
  }

  return s.s;
}

function getNumberParts(n: number): { integerPart: number; fractionPart: number } {
  const integerPart = Math.trunc(n);
  const fractionPart = n - integerPart;

  return { integerPart, fractionPart };
}
