import { useCallback, useEffect, useRef, useState } from "react";
import { displayUnexpectedErrorAndLog } from "./Errors";
import { defaultTimeProvider } from "@eatbetter/common-shared";

/**
 * Note: Results will be refreshed when the fetch function changes, so it must be stable.
 * initialData is only used when the hook is created and any changes will not be subsequently reflected.
 * @param fetch
 * @param initialData
 */
export const usePagedData = <TItem, TNextToken>(
  fetch: (next?: TNextToken) => Promise<{ items: TItem[]; next: TNextToken | undefined } | undefined>
) => {
  const [items, setItems] = useState<TItem[]>([]);
  const next = useRef<TNextToken | undefined>(undefined);

  // we keep track of when fetch changes and refresh data whenever it changes (including the initial fetch)
  const currentFetch =
    useRef<(next?: TNextToken) => Promise<{ items: TItem[]; next: TNextToken | undefined } | undefined>>();

  // we track when refresh is called so we can discard any data tha returns from previous fetches.
  // e.g. user scrolls down and the next page is slow, and then pulls to refresh. In this case, we want the slow fetch
  // data to be discarded, especially if it comes back before the first page of data after refresh is called.
  const refreshLastCalled = useRef(0);

  const refreshValueForResults = useRef(refreshLastCalled.current);

  // we can have multiple outstanding requests, such as if a user pulls to refresh
  // while a fetch is outstanding.
  // So we track the number of outstanding requests and return true for waiting
  // whenever it is > 0
  const [waiting, setWaiting] = useState(0);

  const fetchResults = useCallback(async () => {
    try {
      // no more data, unless fetch has changed (which clears next.current in the useEfect below and sets refreshLastCalled)
      if (items.length > 0 && !next.current && refreshLastCalled.current === refreshValueForResults.current) {
        // nothing else to fetch
        return;
      }

      setWaiting(cur => cur + 1);
      const capturedRlc = refreshLastCalled.current;
      const capturedNext = next.current;
      const res = await fetch(next.current);

      if (capturedRlc === refreshLastCalled.current && res) {
        // if this is not the first page, append, otherwise replace
        if (capturedNext) {
          setItems(cur => [...cur, ...res.items]);
        } else {
          setItems(res.items);
          // associate the results with the refresh value at the time of fetching
          // we need this to know when refresh was called (we set refreshLastCalled value and clear
          // next when this happens
          refreshValueForResults.current = capturedRlc;
        }

        next.current = res.next;
      }
    } catch (err) {
      displayUnexpectedErrorAndLog("Error in usePagedData", err);
    } finally {
      setWaiting(cur => Math.max(0, cur - 1));
    }
  }, [fetch, setWaiting, setItems, items, currentFetch, refreshLastCalled, refreshValueForResults]);

  const refresh = useCallback(async () => {
    refreshLastCalled.current = defaultTimeProvider();
    next.current = undefined;
    await fetchResults();
  }, [refreshLastCalled, next, fetchResults]);

  useEffect(() => {
    // when the fetch function changes, refresh the results.
    // fetchResults handles logging/errors
    if (fetch !== currentFetch.current) {
      currentFetch.current = fetch;
      refresh().catch(() => {});
    }
  }, [fetch, refresh]);

  const fetchNext = useCallback(() => {
    // it never throws - we catch in log there
    fetchResults().catch(() => {});
  }, [fetchResults]);

  const modifyItems = useCallback(
    (fn: (items: TItem[]) => TItem[]) => {
      setItems(fn);
    },
    [setItems]
  );

  return {
    items,
    refresh,
    fetchNext,
    waiting: waiting > 0,
    moreToFetch: !!next.current,

    /**
     * Should only be used if the caller needs to mutate an existing item in the list.
     */
    modifyItems,
  };
};
