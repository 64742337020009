import { defaultTimeProvider, EpochMs, nowOrLaterBackoff, Versioned } from "@eatbetter/common-shared";
import { log } from "../../Log";
import { ReactorResponse } from "./Reactors";
import { Draft } from "immer";

type ItemState = "createNeeded" | "updateNeeded" | "pendingCreate" | "pendingUpdate" | "persisted";

export interface ItemWithUpdates<TItem, TUpdate extends Partial<TItem> = Partial<TItem>> {
  item: TItem;
  itemState: ItemState;
  lastAttempt?: EpochMs;
  errorCount?: number;
  updates: Array<{
    update: TUpdate;
    pending?: boolean;
  }>;
}

/**
 * if the app shuts down while a create/update is pending, we need to reset the state
 * so the reactors restart the operation if necessary. Error counts will be incremented for anything
 * in the pending state.
 */
export function rehydrateItemWithUpdates<T>(i: Draft<ItemWithUpdates<T>>): void {
  if (i.itemState === "pendingCreate") {
    log.info("Found pendingCreate state for ItemWithUpdates in rehydrateItemWithUpdates", { item: i });
    persistErrored(i, "rehydrate");
  }

  if (i.itemState === "pendingUpdate") {
    log.info("Found pendingUpdate state for ItemWithUpdates in rehydrateItemWithUpdates", { item: i });
    updateErrored(i, "rehydrate");
  }
}

export function mergeItemWithUpdates<TUpdate, TItem>(item: { item: TItem; updates: { update: TUpdate }[] }): TItem {
  if (item.updates.length === 0) {
    return item.item;
  }

  const mergedUpdate = mergeUpdates(item.updates);

  return {
    ...item.item,
    ...mergedUpdate,
  };
}

export function mergeUpdates<T>(updates: Array<{ update: T }>): T {
  // Returns value of latest update in the case of booleans (e.g. completed/pending, selected/default).
  const mergedUpdate = updates
    .map(u => u.update)
    .reduce((o, u) => {
      return { ...o, ...u };
    });
  return mergedUpdate;
}

export function getReactorResponse(
  item: ItemWithUpdates<any>,
  timeNow: EpochMs,
  createAndUpdate: {
    create: () => ReactorResponse["dispatch"] | undefined;
    update: () => ReactorResponse["dispatch"] | undefined;
  }
): ReactorResponse | undefined {
  // if we have an error count, it means something isn't done and we need to calculate the kick time
  let later: ReactorResponse | undefined;

  if (item.errorCount && item.lastAttempt) {
    const nol = nowOrLaterBackoff({
      lastAttempt: item.lastAttempt,
      failureCount: item.errorCount,
      timeNow,
    });
    later = nol.now ? undefined : { kickInMs: nol.laterIn };
  }

  switch (item.itemState) {
    case "persisted":
    case "pendingCreate":
    case "pendingUpdate":
      // nop - action remains undefined
      return undefined;
    case "createNeeded":
      return later ?? { dispatch: createAndUpdate.create() };
    case "updateNeeded":
      return later ?? { dispatch: createAndUpdate.update() };
  }
}

export function addUpdate<T, TUpdate extends Partial<T>>(
  itemAndUpdates: ItemWithUpdates<T, TUpdate>,
  update: TUpdate
): void {
  itemAndUpdates.updates.push({ update });

  if (itemAndUpdates.itemState === "persisted") {
    // if the status is createNeeded/pending/updateNeeded, no state update is needed
    itemAndUpdates.itemState = "updateNeeded";
  }
}

export function persistStarted(itemAndUpdates: ItemWithUpdates<any>, _context: string): void {
  itemAndUpdates.itemState = "pendingCreate";
  itemAndUpdates.lastAttempt = defaultTimeProvider();
}

export function persistErrored(itemAndUpdates: ItemWithUpdates<any>, _context: string): void {
  itemAndUpdates.itemState = "createNeeded";
  itemAndUpdates.errorCount = (itemAndUpdates.errorCount ?? 0) + 1;
}

export function persistSucceeded(itemAndUpdates: ItemWithUpdates<any>, _context: string): void {
  itemAndUpdates.errorCount = undefined;
  itemAndUpdates.lastAttempt = undefined;
  setItemStatePersistedOrUpdateNeeded(itemAndUpdates);
}

export function updateStarted(itemAndUpdates: ItemWithUpdates<any>, context: string): void {
  if (itemAndUpdates.itemState !== "updateNeeded") {
    log.warn(`Unexpected itemState in startUpdate for ${context}; ${itemAndUpdates.itemState}`, { itemAndUpdates });
  }

  itemAndUpdates.updates.forEach(u => {
    u.pending = true;
  });
  itemAndUpdates.itemState = "pendingUpdate";
  itemAndUpdates.lastAttempt = defaultTimeProvider();
}

export function updateErrored(itemAndUpdates: ItemWithUpdates<any>, context: string): void {
  // Handle race conditions. Example here was pasted from ListsSlice, but there are likely others.
  // ---
  // One possible error is a conflict because 2 people are acting on the list at the same time.
  // In this case, it's possible there's a race condition where we get the updated item via
  // websocket or retrieving the full list while the call is pending. In that code, we clear
  // any pending updates if we have a newer version and set the status to persisted.
  // Because of this, we need to make sure there are still pending updates when setting the status.
  if (itemAndUpdates.itemState !== "pendingUpdate") {
    log.warn(
      `updateErrored for ${context} called on an item with status ${itemAndUpdates.itemState}. Expecting pending.`,
      { itemAndUpdates }
    );
    return;
  }

  itemAndUpdates.updates.forEach(u => {
    u.pending = false;
  });

  if (itemAndUpdates.updates.length > 0) {
    // expected state when we don't hit the race condition described above
    itemAndUpdates.itemState = "updateNeeded";
    itemAndUpdates.errorCount = (itemAndUpdates.errorCount ?? 0) + 1;
  } else {
    // I don't think we should ever really hit this. In the race condition described above, we'll hit the check for the
    // itemState. Handling just in case.
    log.warn("updateErrored for ${context} called on an item that has no updates.", { itemAndUpdates });
    itemAndUpdates.itemState = "persisted";
    itemAndUpdates.errorCount = undefined;
  }
}

export function updateSucceeded<T, TUpdate extends Partial<T>>(
  itemAndUpdates: ItemWithUpdates<T, TUpdate>,
  updatedItem: T,
  context: string
): void {
  // update if version is the same to allow for server logic that has changed without the persisted
  // version changing. For example, adding a new property.
  if (isVersioned(updatedItem) && isVersioned(itemAndUpdates.item)) {
    if (updatedItem.version >= itemAndUpdates.item.version) {
      itemAndUpdates.item = updatedItem;
    } else {
      log.warn(
        `Not updating item in updateSucceeded for ${context} because current version ${itemAndUpdates.item.version} > updated item version ${updatedItem.version}`,
        { updatedItem, existingItem: itemAndUpdates.item }
      );
    }
  } else {
    // not versioned. Just set.
    itemAndUpdates.item = updatedItem;
  }

  itemAndUpdates.errorCount = undefined;
  itemAndUpdates.lastAttempt = undefined;

  // remove updates that just got applied
  itemAndUpdates.updates = itemAndUpdates.updates.filter(u => !u.pending);
  setItemStatePersistedOrUpdateNeeded(itemAndUpdates);
}

function isVersioned(t: any): t is Versioned {
  const key: keyof Versioned = "version";
  return typeof key[t] === "number";
}

/**
 * Sets an item to persisted if there are no updates needed. updateNeeded otherwise.
 */
function setItemStatePersistedOrUpdateNeeded<T>(item: ItemWithUpdates<T>) {
  item.itemState = item.updates.length > 0 ? "updateNeeded" : "persisted";
}
