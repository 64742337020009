import { useEffect, useState } from "react";
import { KeyboardEvent } from "react-native";
import { Keyboard } from "react-native";

export function useKeyboardLayoutAnimation(
  onKeyboardWillShow?: (e: KeyboardEvent) => void,
  onKeyboardWillHide?: (e: KeyboardEvent) => void
): number {
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  useEffect(() => {
    const keyboardWillShowListener = Keyboard.addListener("keyboardWillShow", e => {
      setKeyboardHeight(e.endCoordinates.height);
      onKeyboardWillShow?.(e);
      Keyboard.scheduleLayoutAnimation(e);
    });
    const keyboardWillHideListener = Keyboard.addListener("keyboardWillHide", e => {
      setKeyboardHeight(0);
      onKeyboardWillHide?.(e);
      Keyboard.scheduleLayoutAnimation(e);
    });
    return () => {
      keyboardWillShowListener.remove();
      keyboardWillHideListener.remove();
    };
  }, []);

  return keyboardHeight;
}
