import { useScreen, withScreenContainer } from "../navigation/ScreenContainer.tsx";
import { BookCoverPhotoScreenProps } from "../navigation/NavTree.ts";
import { UserRecipeId } from "@eatbetter/recipes-shared";
import { useMemo } from "react";
import { HeaderProps } from "../components/ScreenHeaders.tsx";
import { globalStyleColors } from "../components/GlobalStyles.ts";
import { ScreenView } from "../components/ScreenView.tsx";
import { TBody } from "../components/Typography.tsx";

export const BookCoverPhotoScreen = withScreenContainer(
  "BookCoverPhotoScreen",
  (props: BookCoverPhotoScreenProps) => {
    const screen = useScreen();
    const header = useMemo<HeaderProps>(() => {
      return {
        type: "native",
        backgroundColor: globalStyleColors.colorGreyLight,
        title: "BOOK COVER PHOTO SCREEN", //attribution-todo
        right: { type: "done", onPress: screen.nav.goBack },
      };
    }, [screen.nav.goBack]);

    return (
      <ScreenView header={header}>
        <TBody>Book Cover Photo screen for {props.recipeId}</TBody>
      </ScreenView>
    );
  },
  {
    serializer: {
      recipeId: s => s,
      popCount: n => n.toString(),
    },
    parser: {
      recipeId: s => s as UserRecipeId,
      popCount: s => Number.parseInt(s),
    },
  }
);
