import { withScreenContainer } from "../../navigation/ScreenContainer";
import { ScreenView } from "../../components/ScreenView";
import { TBody, TSecondary } from "../../components/Typography";
import { useScreenTimerWithScreenViewEvent } from "../../lib/util/UseScreenTimer";
import { View } from "react-native";
import { Spacer } from "../../components/Spacer";
import { useMemo } from "react";
import { VideoSource } from "../../components/Video";
import { getVideoUrl } from "../../components/Photo";
import { VideoWithPhoneBezel } from "../../components/VideoWithPhoneBezel";

const strings = {
  headline: "Tags and Filtering",
  body: [
    "Tags provide an easy way to organize your recipes. We use AI to auto-apply some tags for you, and you can also add your own tags.",
    "To edit the tags for a recipe, open the context menu for any recipe in your library and tap “My Tags”. You can also edit tags when you end a cooking session.",
    "To use tags to filter your library, tap the filter icon and then select the tags you are looking for. If you select multiple tags, we'll show recipes that match all of the tags first, followed by recipes that match at least one of the tags. ",
  ],
} as const;

export const HowToOrganizeScreen = withScreenContainer("HowToOrganizeScreen", () => {
  useScreenTimerWithScreenViewEvent();

  return (
    <ScreenView
      header={{
        type: "default",
        title: () => <TBody fontWeight="medium">{strings.headline}</TBody>,
        backgroundColor: "white",
      }}
    >
      <TSecondary>{strings.body[0]}</TSecondary>
      <Spacer vertical={1} />
      <TSecondary>{strings.body[1]}</TSecondary>
      <Spacer vertical={1} />
      <HowToVideo videoId="howto_add_tags" />
      <Spacer vertical={1} />
      <TSecondary>{strings.body[2]}</TSecondary>
      <Spacer vertical={1} />
      <HowToVideo videoId="howto_filter_tags" />
    </ScreenView>
  );
});

const HowToVideo = (props: { videoId: "howto_filter_tags" | "howto_add_tags" }) => {
  const videoSource = useMemo<VideoSource>(() => {
    return {
      type: "remote",
      url: getVideoUrl(`videos/${props.videoId}.mp4`),
    };
  }, [props.videoId]);

  return (
    <View style={{ height: 500, alignItems: "center" }}>
      <VideoWithPhoneBezel videoAnalyticsId={props.videoId} videoSource={videoSource} tapToExpand />
    </View>
  );
};
