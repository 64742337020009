import React from "react";
import { BottomActionBar } from "../BottomActionBar";

const strings = {
  cta: {
    next: "Next",
    done: "Let's Go",
  },
};

export const OnboardingCtaButton = React.memo(
  (props: {
    cta: keyof (typeof strings)["cta"];
    onPress: () => void;
    background: "white" | "transparent";
    disabled?: boolean;
    waiting?: boolean;
  }) => {
    return (
      <BottomActionBar
        containerBackgroundColor={props.background}
        primaryAction={{
          actionText: strings.cta[props.cta],
          onPressAction: props.onPress,
          disabled: props.disabled,
          waiting: props.waiting,
        }}
      />
    );
  }
);
